import type { DialogProps as RDialogProps } from '@radix-ui/react-dialog'
import * as RDialog from '@radix-ui/react-dialog'
import { forwardRef } from 'react'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { DrawerContext } from './context'
import DrawerBody from './drawer-body'
import DrawerClose from './drawer-close'
import DrawerContent from './drawer-content'
import DrawerFooter from './drawer-footer'
import DrawerHeader from './drawer-header'
import DrawerHeaderTitle from './drawer-header-title'
import DrawerIconClose from './drawer-icon-close'
import DrawerTrigger from './drawer-trigger'
import { DrawerPlacement, DrawerPlacements, DrawerSize, DrawerSizes } from './types'
import { useDrawerProvider } from './use-drawer'

export type DrawerElement = typeof RDialog.Root

export interface DrawerOnOpenChangeOptions {
  waitAnimation: (callback: () => void) => void
}

export interface DrawerProps extends Omit<RDialogProps, 'modal' | 'onOpenChange'> {
  placement?: DrawerPlacement
  size?: DrawerSize
  onOpenChange?: (open: boolean, options: DrawerOnOpenChangeOptions) => void
}

const DrawerRoot = forwardRef<DrawerElement, DrawerProps>(function Drawer(props, forwardedRef) {
  const {
    children,
    defaultOpen: _,
    placement: placementProp,
    size: sizeProp,
    onOpenChange: __,
    ...rest
  } = props

  const placement = ensurePropOf<DrawerPlacement>(DrawerPlacements, placementProp, 'right')
  const size = ensurePropOf<DrawerSize>(DrawerSizes, sizeProp, 'sm')

  const context = useDrawerProvider({ ...props, placement, size })
  const { open, onOpenChange } = context

  return (
    <DrawerContext.Provider value={context}>
      <RDialog.Root {...rest} modal open={open} onOpenChange={onOpenChange}>
        {children}
      </RDialog.Root>
    </DrawerContext.Provider>
  )
})

export const Drawer = assignSubComponents('Drawer', DrawerRoot, {
  Body: DrawerBody,
  Close: DrawerClose,
  Content: DrawerContent,
  Footer: DrawerFooter,
  Header: DrawerHeader,
  HeaderTitle: DrawerHeaderTitle,
  IconClose: DrawerIconClose,
  Trigger: DrawerTrigger,
})

export * from './drawer-body'
export * from './drawer-close'
export * from './drawer-content'
export * from './drawer-footer'
export * from './drawer-header'
export * from './drawer-header-title'
export * from './drawer-icon-close'
export * from './drawer-trigger'

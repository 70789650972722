import type { NormalizedCacheObject } from '@apollo/client'
import { ApolloClientOptions, initializeApollo } from '@jusbrasil-web/shared-apollo-client'
import { useMemo } from 'react'

export function useApollo(
  initialState: NormalizedCacheObject = {},
  options: ApolloClientOptions = {}
) {
  const client = useMemo(
    () => initializeApollo(initialState, undefined, options),
    [initialState, options]
  )
  return client
}

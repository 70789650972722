import { RadioGroup, RadioGroupProps } from '@radix-ui/react-radio-group'
import { useState } from 'react'

import { RadioCardGroupContext } from './context'

export type RadioCardGroupProps = RadioGroupProps

export default function RadioCardGroup(props: RadioCardGroupProps) {
  const { value: valueProp, defaultValue = valueProp, onValueChange, ...rest } = props
  const [_value, setValue] = useState(defaultValue)
  const value = valueProp ?? _value

  function handleValueChange(value: string) {
    setValue(value)
    onValueChange?.(value)
  }

  return (
    <RadioCardGroupContext.Provider value={{ value, setValue: handleValueChange }}>
      <RadioGroup
        {...rest}
        value={value}
        defaultValue={defaultValue}
        onValueChange={handleValueChange}
      />
    </RadioCardGroupContext.Provider>
  )
}

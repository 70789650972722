import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgQuestion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5a1 1 0 0 1-.998-.938 8.004 8.004 0 0 0-6.94 6.94 1 1 0 0 1 0 1.996 8.004 8.004 0 0 0 6.94 6.94 1 1 0 0 1 1.996 0 8.004 8.004 0 0 0 6.94-6.94 1 1 0 0 1 0-1.996 8.004 8.004 0 0 0-6.94-6.94A1 1 0 0 1 12 5Zm1-3v.05A10.003 10.003 0 0 1 21.95 11H22a1 1 0 1 1 0 2h-.05A10.003 10.003 0 0 1 13 21.95V22a1 1 0 1 1-2 0v-.05A10.003 10.003 0 0 1 2.05 13H2a1 1 0 1 1 0-2h.05A10.003 10.003 0 0 1 11 2.05V2a1 1 0 1 1 2 0Zm-1 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-1-7a1 1 0 1 1 1.479.878c-.31.17-.659.413-.94.741-.286.334-.539.8-.539 1.381a1 1 0 0 0 2 .006.3.3 0 0 1 .057-.085c.077-.09.208-.193.382-.288A3 3 0 1 0 9 10a1 1 0 1 0 2 0Zm1.999 3.011v-.004.005Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgQuestion)

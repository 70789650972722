import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgVolumeUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.383 4.076A1 1 0 0 1 12 5v14a1 1 0 0 1-1.707.707L6.586 16H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2.586l3.707-3.707a1 1 0 0 1 1.09-.217ZM10 7.414 7.707 9.707A1 1 0 0 1 7 10H4v4h3a1 1 0 0 1 .707.293L10 16.586V7.414Zm4.829.343a1 1 0 0 1 1.414 0A5.985 5.985 0 0 1 18 12a5.985 5.985 0 0 1-1.757 4.242 1 1 0 1 1-1.414-1.414A3.984 3.984 0 0 0 16 12 3.984 3.984 0 0 0 14.83 9.17a1 1 0 0 1 0-1.414ZM19.07 4.93a1 1 0 1 0-1.414 1.414A7.972 7.972 0 0 1 20 12c0 2.21-.895 4.208-2.343 5.657a1 1 0 1 0 1.414 1.414A9.972 9.972 0 0 0 22 12a9.972 9.972 0 0 0-2.93-7.071Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgVolumeUp)

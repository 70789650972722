import { gql } from '@apollo/client'
import {
  ArrowRightIcon,
  BodyText,
  Button,
  Card,
  CourtIcon,
  Detail,
  EyeIcon,
  Flex_unstable as Flex,
  Heading,
  IaSkillLegalResearchIcon,
  ScalesIcon,
  ShieldIcon,
} from '@farol-ds/react'
import classNames from 'classnames'

import { formatKind, formatLawsuitTitle, getLawsuitCourt } from '../../utils/'
import { LawsuitSummaryGeneratingModal } from '../lawsuit-card-third-party/lawsuit-summary-modal'
import { LawsuitProgressChip } from '../lawsuit-progress-chip'
import { LawsuitCard_LawsuitFragment } from './__generated__/lawsuit-card.graphql'
import styles from './lawsuit-card.module.scss'

export interface LawsuitCardProps {
  lawsuit: LawsuitCard_LawsuitFragment
  isMobile?: boolean
  className?: string
  label?: string
  subtitle?: string | React.ReactNode
  showLawsuitNumber?: boolean
  showOldButton?: boolean
  showKind?: boolean
  showParts?: boolean
  showProgress?: boolean
  isTheCardInJPV?: boolean
  showRestrictLawsuitDisclaimer?: boolean
  truncateText?: boolean
  fromComponent?: string
  onClick?: () => void
  onSubmitSummarizationSurvey?: (surveyData: any) => void
  onSendEvent?: (event: any, metadata: any) => void
  summarizationExperiment?: {
    isParticipating?: boolean
    shouldSeeSummaryButton?: boolean
    variant?: string
  }
}
export type LawsuitCardType = LawsuitCardProps['lawsuit']

const getLawsuitUrl = (url: string | null): string => {
  if (!url) {
    return '#'
  }
  return url
}

export function LawsuitCard(props: LawsuitCardProps) {
  const {
    lawsuit,
    isMobile,
    className,
    label,
    subtitle,
    showLawsuitNumber,
    showOldButton,
    showKind = true,
    showParts = true,
    showRestrictLawsuitDisclaimer = false,
    showProgress,
    truncateText,
    onClick,
    isTheCardInJPV = false,
    onSubmitSummarizationSurvey,
    onSendEvent,
    summarizationExperiment,
  } = props

  const { number, subject, title, kind, cnjNumber, url, restrictLawsuit, canViewRestrictLawsuit } =
    lawsuit || {}

  const extendedCourt = getLawsuitCourt(cnjNumber, restrictLawsuit, canViewRestrictLawsuit)
  const formattedTitle = formatLawsuitTitle(
    title,
    number,
    showParts,
    restrictLawsuit,
    canViewRestrictLawsuit
  )
  const ariaLabelledbyDescription = `Exibir ${
    title?.toLowerCase().includes('processo') ? title : `processo ${title}`
  }`

  const labelNumber = label || `nº ${number}`

  const area = formatKind(subject, kind)

  const showArea = area && showKind

  const onCardClick = () => onClick?.()

  function renderButton() {
    return (
      (showOldButton && (
        <Button
          className={classNames(
            styles.lawsuitListAction,
            summarizationExperiment?.shouldSeeSummaryButton && styles.lawsuitCardButton
          )}
          data-testid="lawsuit-card-old-action-btn"
          kind="tertiary"
          leftIcon={<EyeIcon size="sm" />}
          asChild
        >
          <span>Ver processo</span>
        </Button>
      )) || (
        <Button
          className={styles.action}
          data-testid="lawsuit-card-action"
          kind="plain"
          rightIcon={<ArrowRightIcon size="sm" />}
          asChild
        >
          <span>Exibir processo</span>
        </Button>
      )
    )
  }

  function LinkOrDiv({
    className,
    useDiv,
    children,
    ...rest
  }: {
    className?: string
    useDiv?: boolean
    children: React.ReactNode
  }) {
    if (useDiv) {
      return (
        <div className={className} {...rest} data-testid="lawsuit-card-third-party-div">
          {children}
        </div>
      )
    }
    return (
      <a
        className={className}
        href={getLawsuitUrl(url)}
        aria-labelledby={ariaLabelledbyDescription}
        onClick={onCardClick}
        {...rest}
      >
        {children}
      </a>
    )
  }

  function getLawsuitSummarizationButtonLabel() {
    switch (summarizationExperiment?.variant) {
      case 'variantA':
        return 'Sobre o que é o processo?'
      case 'variantB':
        return 'Ver resumo'
      case 'variantC':
        return 'Resumo rápido'
      case 'variantD':
        return 'Resumo simplificado'
    }
  }

  return (
    <Card className={classNames(styles.card, className)} data-testid="lawsuit-card" asChild>
      <LinkOrDiv useDiv={summarizationExperiment?.isParticipating}>
        {showProgress && <LawsuitProgressChip lawsuit={lawsuit} className={styles.progress} />}
        {showLawsuitNumber && (
          <BodyText size="md" className={styles.number} data-testid="lawsuit-card-number">
            {labelNumber}
          </BodyText>
        )}

        <Heading size="md" asChild>
          <h2
            className={classNames(styles.title, {
              [styles.truncate]: truncateText,
            })}
            data-testid="lawsuit-card-title"
          >
            {formattedTitle}
          </h2>
        </Heading>

        <Flex
          className={styles.flexDetails}
          direction="column"
          gap={!isTheCardInJPV ? '4xs' : '6xs'}
        >
          {subtitle && (
            <Detail inline data-testid="lawsuit-card-details-subtitle">
              <Detail.Value>{subtitle}</Detail.Value>
            </Detail>
          )}
          <Detail
            inline
            className={classNames(styles.details, {
              [styles.truncate]: truncateText,
            })}
            data-testid="lawsuit-card-details-court"
          >
            <Detail.Icon>
              <CourtIcon size="sm" />
            </Detail.Icon>
            <Detail.Value className={styles.details}>{extendedCourt}</Detail.Value>
          </Detail>

          {showArea && (
            <Detail
              inline
              className={classNames(styles.details, styles.truncate)}
              data-testid="lawsuit-card-details-area"
            >
              <Detail.Icon>
                <ScalesIcon size="sm" />
              </Detail.Icon>
              <Detail.Value>{area}</Detail.Value>
            </Detail>
          )}

          {showRestrictLawsuitDisclaimer && restrictLawsuit && (
            <Detail
              inline
              className={classNames(styles.details, styles.truncate)}
              data-testid="lawsuit-card-details-restrict"
            >
              <Detail.Icon>
                <ShieldIcon size="sm" />
              </Detail.Icon>
              <Detail.Value>
                <strong>Processo restrito</strong> · visível só para você
              </Detail.Value>
            </Detail>
          )}
        </Flex>
        <div className={styles.buttonContainer}>
          <LinkOrDiv
            useDiv={!summarizationExperiment?.isParticipating}
            className={styles.lawsuitCardButton}
          >
            {renderButton()}
          </LinkOrDiv>
          {summarizationExperiment?.shouldSeeSummaryButton && (
            <LawsuitSummaryGeneratingModal
              lawsuit={lawsuit}
              isMobile={isMobile}
              formattedTitle={formattedTitle}
              onSubmitSummarizationSurvey={onSubmitSummarizationSurvey}
              onSendEvent={onSendEvent}
              TriggerButtonComponent={
                <Button
                  className={styles.lawsuitCardButton}
                  kind="tertiary"
                  leftIcon={<IaSkillLegalResearchIcon size="sm" />}
                  data-testid="lawsuit-card-summary-button"
                >
                  {getLawsuitSummarizationButtonLabel()}
                </Button>
              }
            />
          )}
        </div>
      </LinkOrDiv>
    </Card>
  )
}

LawsuitCard.fragments = {
  lawsuit: gql`
    fragment LawsuitCard_lawsuit on CRMLawsuit {
      title
      url
      subject
      number
      kind
      topicSourceId
      justiceSecret
      restrictLawsuit
      canViewRestrictLawsuit
      courtSection {
        rawValue
      }
      cnjNumber {
        court {
          name
          acronym
        }
        source {
          type
          district
        }
      }

      ...LawsuitProgressChip_lawsuit
      ...LawsuitSummaryGeneratingModal_lawsuit
    }

    ${LawsuitProgressChip.fragments.lawsuit}
    ${LawsuitSummaryGeneratingModal.fragments.lawsuit}
  `,
}

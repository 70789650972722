import { type MouseEvent, forwardRef, HTMLAttributes, ReactNode, useId, useState } from 'react'

import { type ButtonElement } from '../button/button'
import { CollapseControl, CollapseControlProps } from '../collapse-control/collapse-control'
import { assignSubComponents } from '../utilities/internal'
import CollapseContent from './collapse-content'
import { CollapseContext } from './context'

export type CollapseElement = HTMLDivElement

export interface CollapseProps extends HTMLAttributes<CollapseElement> {
  children: ReactNode
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean, evt: MouseEvent<ButtonElement>) => void
  collapseControlProps?: Omit<CollapseControlProps, 'open' | 'onClick'>
}

const CollapseRoot = forwardRef<CollapseElement, CollapseProps>(function Collapse(
  props,
  forwardedRef
) {
  const {
    defaultOpen = false,
    children,
    open: openProp,
    onOpenChange,
    collapseControlProps = {},
    ...rest
  } = props

  const id = useId()
  const [_open, setOpen] = useState(openProp ?? defaultOpen)
  const open = openProp ?? _open

  return (
    <CollapseContext.Provider value={{ id, open }}>
      <div {...rest} ref={forwardedRef}>
        {children}
      </div>

      <CollapseControl
        {...collapseControlProps}
        open={open}
        aria-controls={id}
        onClick={(evt) => {
          setOpen(!open)
          onOpenChange?.(!open, evt)
        }}
      />
    </CollapseContext.Provider>
  )
})

export const Collapse = assignSubComponents('Collapse', CollapseRoot, {
  Content: CollapseContent,
})

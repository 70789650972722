import { NextApiHandler } from 'next'

import { getPath, isGooglebotAgent, logOrThrowErrorForInvalidPath } from './utils'

export const withPrometheusMetricsApiRoute = (
  filename: string,
  handler: NextApiHandler
): NextApiHandler => {
  const path = getPath('pages/api', filename)
  if (path === null) {
    logOrThrowErrorForInvalidPath('withPrometheusMetricsApiRoute should be used only in API routes')
    return handler
  }

  return async (req, res) => {
    const start = process.hrtime()
    const result = await handler(req, res)
    const end = process.hrtime(start)
    const duration = end[0] + end[1] / 1e9

    const labels = {
      method: req.method,
      path,
      code: res.statusCode,
      is_googlebot_user_agent: isGooglebotAgent(req.headers).toString(),
    }

    if (process.env.NEXT_RUNTIME === 'nodejs') {
      const { PrometheusMetrics } = await import('../register')
      PrometheusMetrics.instance.registerMetrics(labels, duration)
    }

    return result
  }
}

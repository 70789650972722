import classNames from 'classnames'
import { cloneElement, HTMLAttributes } from 'react'

import { isValidIconElement } from '../icon/icon'
import { useSelectionCardContext } from './context'
import styles from './selection-card-body-icon.module.scss'

export type SelectionCardBodyIconElement = HTMLDivElement

export type SelectionCardBodyIconProps = HTMLAttributes<SelectionCardBodyIconElement>

const SelectionCardBodyIcon: React.FC<SelectionCardBodyIconProps> = (props) => {
  const { className, children, ...rest } = props
  const { orientation } = useSelectionCardContext()

  const classes = classNames(
    styles.root,
    {
      [styles.vertical]: orientation === 'vertical',
    },
    className
  )

  return (
    <div {...rest} className={classes}>
      {isValidIconElement(children) ? cloneElement(children, { size: 'sm' }) : children}
    </div>
  )
}

export default SelectionCardBodyIcon

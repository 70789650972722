import classNames from 'classnames'
import { forwardRef } from 'react'

import { BodyTextElement, BodyTextProps } from '../body-text/body-text'
import sharedStyles from './shared-styles.module.scss'

export type SnippetCaptionSeparatorElement = BodyTextElement

export type SnippetCaptionSeparatorProps = Omit<BodyTextProps, 'children'>

const SnippetCaptionSeparator = forwardRef<
  SnippetCaptionSeparatorElement,
  SnippetCaptionSeparatorProps
>(function SnippetCaptionSeparator(props, forwardedRef) {
  const { className, ...rest } = props

  const classes = classNames(sharedStyles.captionSeparator, className)

  return <span {...rest} className={classes} ref={forwardedRef} />
})

export default SnippetCaptionSeparator

/**
 * @param cpf A cpf number in string format
 * @returns Boolean indicating if the string contains a valid cnpj number
 */
export function validateCpf(cpf: string): boolean {
  // Remove any non-numeric characters
  cpf = cpf.replace(/[^\d]+/g, '')

  // Check the length
  if (cpf.length !== 11) return false

  // Check for repeated digits
  if (/(\d)\1{10}/.test(cpf)) return false

  // Validate first 9 digits
  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }

  let result = 11 - (sum % 11)
  if (result === 10 || result === 11) {
    result = 0
  }
  if (result !== parseInt(cpf.charAt(9))) return false

  // Validate last 2 digits
  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }

  result = 11 - (sum % 11)
  if (result === 10 || result === 11) {
    result = 0
  }

  if (result !== parseInt(cpf.charAt(10))) return false

  return true
}

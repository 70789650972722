import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgArchive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.293 9.707A1 1 0 0 0 3 10v7.8C3 19.475 4.256 21 6 21h12c1.744 0 3-1.524 3-3.2V10a1 1 0 0 0 1-1V6a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v3a1 1 0 0 0 .293.707ZM19 10H9v7.8a3.377 3.377 0 0 1-.22 1.2H18c.465 0 1-.445 1-1.2V10Zm1-2H8V6.5c0-.51-.102-1.026-.293-1.5H19a1 1 0 0 1 1 1v2ZM7 10H5v7.8c0 .755.535 1.2 1 1.2.465 0 1-.445 1-1.2V10ZM4 6v2h2V6.5C6 5.453 5.357 5 5.001 5H5a1 1 0 0 0-1 1Zm9 7a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgArchive)

import { BodyText, Button, Heading, Link, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect } from 'react'

import emailImgSrc from '../../../public/identity-validation-modal/email.svg'
import { EventData } from '../../../utils'
import { SUPPORT_CONTACT_URL } from '../constants'
import styles from './remove-cpf-email.module.scss'

interface RemoveCpfEmailStepProps {
  eventProps: {
    viewerPid: number
  } & EventData
  profileInUse?: {
    email?: string | null
  }
  onContinue: () => void
}

export function RemoveCpfEmailStep(props: RemoveCpfEmailStepProps) {
  const { onContinue, eventProps, profileInUse } = props
  const { viewerPid, ...eventData } = eventProps

  const pid = viewerPid?.toString()

  useEffect(() => {
    sendMetrics('UserAccount.IdValidationChangeAccountViewed', pid, { ...eventData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinueClick = () => {
    sendMetrics('UserAccount.IdValidationChangeAccountSubmitted', pid, {
      ...eventData,
      button: 'Finish',
    })
    onContinue()
  }

  const onSupportClick = () => {
    sendMetrics('UserAccount.IdValidationChangeAccountSubmitted', pid, {
      ...eventData,
      button: 'SupportHelp',
    })
  }

  const title = 'E-mail de verificação enviado'

  return (
    <>
      <Modal.Body className={styles.body} data-testid="remove-cpf-email-step">
        <Image alt={title} src={emailImgSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {title}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          Acesse o e-mail que enviamos para <strong>{profileInUse?.email}</strong> para cadastrar o
          CPF à conta atual.
        </BodyText>
        <BodyText size="lg" className={styles.text}>
          Entre em{' '}
          <Link href={SUPPORT_CONTACT_URL} target="_blank" onClick={onSupportClick}>
            contato com nosso suporte
          </Link>{' '}
          caso não reconheça este e-mail.
        </BodyText>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button kind="primary" onClick={onContinueClick}>
          Concluir
        </Button>
      </Modal.Footer>
    </>
  )
}

import * as RSeparator from '@radix-ui/react-separator'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './separator.module.scss'

export type SeparatorElement = HTMLDivElement
export const SeparatorOrientations = ['horizontal', 'vertical'] as const
export type SeparatorOrientation = (typeof SeparatorOrientations)[number]

export interface SeparatorProps extends HTMLAttributes<SeparatorElement> {
  decorative?: boolean
  orientation?: SeparatorOrientation
  className?: string
}

export const Separator = forwardRef<SeparatorElement, SeparatorProps>(function Separator(
  props,
  forwardedRef
) {
  const { className, decorative, orientation: orientationProp, ...rest } = props

  const orientation = ensurePropOf<SeparatorOrientation>(
    SeparatorOrientations,
    orientationProp,
    'horizontal'
  )

  const classes = classNames(
    styles.root,
    {
      [styles[`orientation${orientation}`]]: !!orientation,
    },
    className
  )

  return (
    <RSeparator.Root
      decorative={decorative}
      orientation={orientation}
      {...rest}
      className={classes}
      ref={forwardedRef}
    />
  )
})

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCourt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.486 2.126a1 1 0 0 0-.972 0l-9 5a1 1 0 0 0-.408 1.321l1 2A1 1 0 0 0 4 11h16a1 1 0 0 0 .895-.553l1-2a1 1 0 0 0-.41-1.321l-9-5ZM4.323 8.409 12 4.144l7.677 4.265-.295.591H4.618l-.295-.59ZM5 13a1 1 0 0 1 1 1v6h3v-4a3 3 0 1 1 6 0v4h3v-6a1 1 0 1 1 2 0v6h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-6a1 1 0 0 1 1-1Zm6 7h2v-4a1 1 0 1 0-2 0v4Zm2-13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCourt)

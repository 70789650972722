export interface HiddenFieldProps {
  name: string
  value: string | string[] | undefined
}

export function HiddenField({ name, value, ...rest }: HiddenFieldProps) {
  return (
    value &&
    (Array.isArray(value) ? value : [value]).map((v) => (
      <input key={v} type="hidden" name={name} value={v} {...rest} />
    ))
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgReportError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 11a7 7 0 1 1 4.746 6.63 1 1 0 0 0-1.03.24L8 18.585v-1.34a1 1 0 0 0-.375-.78A6.985 6.985 0 0 1 5 11Zm7-9a9 9 0 0 0-6 15.708V21a1 1 0 0 0 1.707.707l2.002-2.002A9 9 0 1 0 12 2Zm0 10a1 1 0 0 1-1-1V7a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1Zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgReportError)

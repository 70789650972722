import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import { cloneElement, isValidElement } from 'react'

import { MenuItemProps } from '../menu-item/menu-item'

export type DropdownMenuCheckboxItemElement = typeof RDropdownMenu.Item
export type DropdownMenuCheckboxItemProps = RDropdownMenu.DropdownMenuCheckboxItemProps

const DropdownMenuCheckboxItem: React.FC<DropdownMenuCheckboxItemProps> = (props) => {
  const { children, disabled, checked, ...rest } = props
  return (
    <RDropdownMenu.CheckboxItem {...rest} checked={checked} disabled={disabled} asChild>
      {isValidElement<MenuItemProps>(children)
        ? cloneElement(children, {
            current: !!checked,
            disabled,
          })
        : children}
    </RDropdownMenu.CheckboxItem>
  )
}

export default DropdownMenuCheckboxItem

import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { assignSubComponents } from '../utilities/internal'
import QuoteCite from './quote-cite'

export type QuoteElement = HTMLQuoteElement

export interface QuoteProps extends HTMLAttributes<QuoteElement> {
  children: ReactNode
  className?: string
}

const QuoteRoot = forwardRef<QuoteElement, QuoteProps>(function Quote(props, forwardedRef) {
  const { children, ...rest } = props
  return (
    <blockquote {...rest} ref={forwardedRef}>
      {children}
    </blockquote>
  )
})

export const Quote = assignSubComponents('Quote', QuoteRoot, {
  Cite: QuoteCite,
})

export * from './quote-cite'

import { Chip } from '@farol-ds/react'

interface LawsuitDateChipProps {
  date: number | null
  year?: string | null
  className?: string
}

export function LawsuitDateChip({ date, year, className }: LawsuitDateChipProps) {
  const formatDate = (date: number | null): string | null => {
    if (!date) return null
    return new Date(date)
      .toLocaleDateString('pt-BR', { month: 'short', year: 'numeric' })
      .replace('. de ', '/')
      .replace(/^(.)/, (match) => match.toUpperCase())
  }

  return (
    <Chip kind="tertiary" size="sm" data-testid="lawsuit-date-chip" className={className}>
      Aberto em {formatDate(date) || year}
    </Chip>
  )
}

import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './brand.module.scss'
import { BrandElement, BrandProps } from './common'
import { SvgJusbrasil as Logo } from './svg/jusbrasil'

export const Brand = forwardRef<BrandElement, BrandProps>(function Brand(props, forwardedRef) {
  const { className, onlySymbol = false, version = 'positive', ...rest } = props

  const classes = classNames(
    styles.root,
    {
      [styles.positive]: version === 'positive',
      [styles.negative]: version === 'negative',
    },
    className
  )

  return (
    <Logo onlySymbol={onlySymbol} role="img" {...rest} className={classes} ref={forwardedRef} />
  )
})

export * from './brand-jus-ia'
export * from './brand-jus-solucoes'
export type * from './common'

import classNames from 'classnames'
import { cloneElement, forwardRef, Fragment, isValidElement, Key, ReactNode } from 'react'

import { IconProps } from '../icon/types'
import { List, ListItemElement, ListItemProps } from '../list/list'
import { Slot, Slottable } from '../utilities'
import styles from './icon-list-item.module.scss'

export type IconListItemElement = ListItemElement

export interface IconListItemProps extends ListItemProps {
  icon: ReactNode
}

const IconListItem = forwardRef<IconListItemElement, IconListItemProps>(function IconListItem(
  props,
  forwardedRef
) {
  const { children, className, icon, asChild, ...rest } = props

  const Component = asChild ? Slot : Fragment

  const classes = classNames(styles.root, className)

  return (
    <List.Item {...rest} className={classes} ref={forwardedRef} asChild={asChild}>
      <Component>
        <span className={styles.icon} data-fds-iconlist-icon>
          {isValidElement<IconProps>(icon) ? cloneElement(icon, { size: 'md' }) : null}
        </span>
        <Slottable>{renderChildren(props)}</Slottable>
      </Component>
    </List.Item>
  )
})

function renderChildren(props: IconListItemProps): ReactNode {
  const { asChild, children } = props
  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return cloneElement(children, {}, renderText(children.props.children, children.key))
  }
  return renderText(children, null)
}

function renderText(children: ReactNode, key: Key | null): ReactNode {
  return (
    <span className={styles.text} key={key}>
      {children}
    </span>
  )
}

export default IconListItem

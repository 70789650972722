import {
  AutocompleteOption as AutocompleteOptionBase,
  defaultFilterOptions,
  HistoryIcon,
  Search,
  SearchAutocomplete,
  SearchAutocompleteElement,
  SearchAutocompleteProps,
  SearchAutocompleteRootElement,
  SearchElement,
  SearchIcon,
  useDebounceCallback,
} from '@farol-ds/react'
import {
  attachBrowserBackButtonListener,
  cleanupHistoryState,
} from '@jusbrasil-web/shared-browser-back-button-listener'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  storeComponentOriginForNextPage,
} from '@jusbrasil-web/shared-ga4-events'
import { HistoryStorage, SnowpiercerStorage } from '@jusbrasil-web/shared-history-storage'
import { getCookie } from '@jusbrasil-web/shared-utils-cookies'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { getJusbrasilBaseURL } from '@jusbrasil-web/shared-utils-url'
import { composeRefs } from '@radix-ui/react-compose-refs'
import { useRouter } from 'next/router'
import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { HiddenField } from './components'
import { filterOptions } from './constants'

export type SERPAutocomplete = HTMLFormElement
export type SERPAutocompleteProps = {
  className?: string
  autocompleteProps?: Omit<SearchAutocompleteProps, 'children'>
  autocompleteRef?: RefObject<SearchAutocompleteRootElement>
  searchRef?: RefObject<SearchElement>
  q?: string
  section?: string
  disableSectionFilter?: boolean
  pid?: string
  placeholder?: string
  onSubmit?: (evt: React.FormEvent<HTMLFormElement> | null, query: string) => void
}

const SEARCH_URL = `${getJusbrasilBaseURL()}/busca`
const FROM_COMPONENT = 'SERPAutocomplete'

const sectionName = (section: string) => (section === '' ? 'general' : section)

type SuggestionItem = {
  suggestion: string
}

type ResponseAPI = {
  suggestions: SuggestionItem[]
  metadata: Record<string, string>
}

interface AutocompleteOption extends AutocompleteOptionBase {
  metadata: Record<string, string>
}

export const SERPAutocomplete = forwardRef<SERPAutocomplete, SERPAutocompleteProps>(
  function SERPAutocomplete(props, forwardedRef) {
    const { query = {} } = useRouter() || {}
    const {
      className,
      autocompleteProps,
      autocompleteRef,
      searchRef: searchRefProp,
      q,
      section = '',
      disableSectionFilter,
      pid,
      placeholder = 'Pesquisar no Jusbrasil',
      onSubmit: onSubmitCallback,
    } = props

    const modalOpenStateName = 'searchAutocompleteModalOpen'
    const formRef = useRef<HTMLFormElement>(null)
    const searchRef = useRef<SearchElement>(null)
    const searchAutoCompleteRef = useRef<SearchAutocompleteElement>(null)

    const history = useMemo(() => new HistoryStorage(new SnowpiercerStorage()), [])

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<AutocompleteOption[]>([])
    const [filters, setFilters] = useState(query)

    // ToDo: remove this when the person SERP is rolled out (or not). @castrillon7
    const [showPersonSERP, setShowPersonSERP] = useState(false)
    useEffect(() => {
      setShowPersonSERP(getCookie(null, 'feature_enable_person_serp') === 'true')
    }, [])

    const extendedFilterOptions = [...filterOptions]
    if (showPersonSERP) extendedFilterOptions.push({ label: 'Pessoa', value: 'pessoa' })

    const mountHistoryItems = useCallback(async () => {
      return history.all().then((items) => {
        const data: AutocompleteOption[] = items.map((item) => ({
          id: (item.id + 1) * -1, // negative ids for history items (just to avoid conflicts)
          label: item.text,
          value: item.raw,
          leftIcon: <HistoryIcon />,
          history: true,
          metadata: {},
        }))
        data.reverse()
        return data
      })
    }, [history])

    const request = useDebounceCallback((t: string) => {
      fetch(`https://autocomplete-br-endpoint.jusbrasil.com.br/v2/_autocomplete?q=${t}`)
        .then((res) => res.json())
        .then(async (res: ResponseAPI) => {
          const data: AutocompleteOption[] = res.suggestions.map((item, idx) => ({
            id: idx,
            label: item.suggestion,
            value: item.suggestion.replace(/<\/?[^>]+(>|$)/g, ''),
            metadata: res.metadata,
            leftIcon: <SearchIcon />,
          }))
          const historyItems = await mountHistoryItems()
          if (historyItems.length === 0) {
            return data
          }
          const items = [...historyItems, ...data]
          const uniqueItems = items.filter(
            (item, idx, self) => self.findIndex((i) => i.value === item.value) === idx
          )
          return uniqueItems
        })
        .then(setOptions)
        .catch((err) =>
          console.error(
            `Error calling https://autocomplete-br-endpoint.jusbrasil.com.br/v2/_autocomplete?q=${t}`,
            err
          )
        )
        .finally(() => setLoading(false))
    }, 250)

    const onValueChange = (value: string) => {
      request.cancel()
      if (value === '' || value.length <= 3) {
        request.flush()
        setLoading(false)
        // only show history items when input is empty or has less than 3 characters
        setOptions(options.filter((option) => option.history))
        return
      }
      setLoading(true)
      request(value)
    }

    const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
      cleanupHistoryState(modalOpenStateName)

      const value = searchRef.current?.value
      if (!value) {
        evt.preventDefault()
        return
      }
      sendMetrics('Topbar.SearchWithArtifact', pid, {
        artifact: sectionName(section),
        from_component: FROM_COMPONENT,
        user_id: pid,
      })
      history.putTerm(value).catch((err) => {
        console.error(err)
      })

      onSubmitCallback?.(evt, value)
    }

    const onFilterChange = useCallback(
      (value: string) => {
        if (!formRef.current) return
        setFilters(value === section ? query : {})
        let nextAction
        switch (value) {
          case '':
            nextAction = SEARCH_URL
            break
          default: {
            const parts = SEARCH_URL.split('/')
            parts.splice(3, 0, value)
            nextAction = parts.join('/')
            break
          }
        }
        formRef.current.action = nextAction
      },
      [section, query]
    )

    const onFocus = (focused: boolean) => {
      if (focused) {
        attachBrowserBackButtonListener(modalOpenStateName, () => {
          searchAutoCompleteRef.current?.close()
        })
      } else {
        cleanupHistoryState(modalOpenStateName)
      }
    }

    const sendSuggestionClickedEvent = (option: AutocompleteOption) => {
      const selectedIndex = options.findIndex(({ value }) => value === option.value)
      const props = {
        click_position: selectedIndex,
        click_suggestion: option.value,
        artifact: section,
        is_pasted: false,
        from_component: FROM_COMPONENT,
        user_id: pid,
        metadata: JSON.stringify(option.metadata),
      }
      const eventName = option.history ? 'LocalSuggestionClicked' : 'SuggestionClicked'
      sendMetrics(`Autocomplete.${eventName}`, pid, props)
    }

    useEffect(() => setFilters(query), [query])
    useEffect(() => onFilterChange(section), [section, onFilterChange])
    useEffect(() => {
      history
        .onReady()
        .then(() => mountHistoryItems().then(setOptions))
        .catch((err) => {
          console.log('History not ready', err)
        })
    }, [history, mountHistoryItems])

    return (
      <form
        action={SEARCH_URL}
        method="GET"
        onSubmit={onSubmit}
        className={className}
        data-testid="search-autocomplete"
        role="search"
        ref={composeRefs(formRef, forwardedRef)}
      >
        {/* ref: https://github.com/jusbrasil/ui-topbar/blob/fbe82de5561ccdf1c5ba3092a077d0524631080c/src/tags/autocomplete/index.js#L114-L126 */}
        <HiddenField name="idtopico" value={filters.idtopico} />
        <HiddenField name="l" value={filters.l} data-testid="hidden-input-l" />
        <HiddenField name="o" value={filters.o} />
        <HiddenField name="dateFrom" value={filters.dateFrom} />
        <HiddenField name="dateTo" value={filters.dateTo} />
        <HiddenField name="degree" value={filters.degree} />
        <HiddenField name="hasDecisionFacts" value={filters.hasDecisionFacts} />
        <HiddenField name="jurisType" value={filters.jurisType} />
        <HiddenField name="tribunal" value={filters.tribunal} />
        <HiddenField name="tipoDoutrina" value={filters.tipoDoutrina} />
        <HiddenField name="areasDireito" value={filters.areasDireito} />
        <HiddenField name="journalType" value={filters.journalType} />
        <HiddenField name="uf" value={filters.uf} />
        {/* TODO: We can remove theses depending on the result of the case search filters AB result. @ag @lr-core-exp */}
        <HiddenField name="orgaoJulgador" value={filters.orgaoJulgador} />
        <HiddenField name="magistrado" value={filters.magistrado} />

        <SearchAutocomplete
          {...autocompleteProps}
          onFocusedChange={onFocus}
          ref={searchAutoCompleteRef}
          rootRef={autocompleteRef}
        >
          <SearchAutocomplete.Trigger
            name="q"
            defaultValue={q}
            loading={loading}
            onValueChange={(_, value) => onValueChange(value)}
            placeholder={placeholder}
            ref={composeRefs(searchRef, searchRefProp)}
          >
            {!disableSectionFilter && (
              <Search.Filter
                defaultValue={section}
                options={extendedFilterOptions}
                onValueChange={(value: string) => {
                  onFilterChange(value)
                  sendMetrics('Topbar.ArtifactFiltered', pid, {
                    artifact: sectionName(value),
                    from_component: FROM_COMPONENT,
                    user_id: pid,
                  })
                }}
                data-testid="topbar-search-filter"
              />
            )}
          </SearchAutocomplete.Trigger>
          <SearchAutocomplete.Menu
            dangerouslyLabelAsHTML
            options={options}
            filterOptions={(options, input) => {
              const escapedInput = input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
              return options.filter((option) => {
                // do not try to filter or highlight from api autocomplete
                if (!option.history) return true

                // update label with highlighted input
                option.label = option.value.replace(
                  new RegExp(`(${escapedInput})`, 'gi'),
                  '<strong>$1</strong>'
                )

                // filter history items based on input using default criteria
                return defaultFilterOptions([option], input).length > 0
              })
            }}
            onOptionRemove={(option) => {
              const item = option.history && history.getByTerm(option.value)
              if (!item) return
              history.remove(item).catch((err) => {
                console.error(err)
              })
            }}
            onOptionSelect={(option) => {
              if (!searchRef.current) return
              storeComponentOriginForNextPage({
                componentClass: COMPONENT_CLASS.AUTOCOMPLETE,
                componentName: option.history
                  ? COMPONENT_NAME.AUTOCOMPLETE_HISTORY
                  : COMPONENT_NAME.AUTOCOMPLETE_RESULT,
              })
              sendSuggestionClickedEvent(option)
              searchRef.current.value = option.value
              formRef.current?.requestSubmit()
            }}
          />
        </SearchAutocomplete>
      </form>
    )
  }
)

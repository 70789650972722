import { useMemo } from 'react'

import { JUSBRASIL_BASE_URL } from '../../../constants'
import { TopbarContextType } from '../../../types'
import { TOPBAR_USER_MENU_ITEM_SYMBOLS } from './constants'
import { getMenuItems, TopbarUserMenuItemKey } from './items'
import { mountItems } from './mount'
import { TopbarUserMenuCtx, TopbarUserMenuItem, TopbarUserMenuItemSymbol } from './types'

type UserMenuItemMixed = TopbarUserMenuItemKey | TopbarUserMenuItem
export type UseMenuItemsMountFn = (
  items: TopbarUserMenuItemKey[],
  ctx: TopbarUserMenuCtx
) => UserMenuItemMixed[]

export function useMenuItems(
  ctx: TopbarContextType,
  mount?: UseMenuItemsMountFn
): TopbarUserMenuItem[] {
  return useMemo(() => {
    const { user } = ctx

    const baseUrl = JUSBRASIL_BASE_URL
    const userUrl = user?.url || null

    const menuCtx: TopbarUserMenuCtx = {
      baseUrl,
      userUrl,
    }
    const config = getMenuItems(ctx, menuCtx)
    const items = mountItems(ctx)
    const mixedItems = mount ? mount(items, menuCtx) : items

    return mixedItems.map((item) => {
      if (typeof item === 'string') {
        if (TOPBAR_USER_MENU_ITEM_SYMBOLS.includes(item as TopbarUserMenuItemSymbol)) {
          return item as TopbarUserMenuItemSymbol
        }
        return config[item as Exclude<TopbarUserMenuItemKey, TopbarUserMenuItemSymbol>]
      }
      return item
    })
  }, [ctx, mount])
}

import * as RAccordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { assignSubComponents } from '../utilities/internal'
import styles from './accordion.module.scss'
import AccordionContent from './accordion-content'
import AccordionHeader from './accordion-header'
import AccordionHelpText from './accordion-help-text'
import AccordionIcon from './accordion-icon'
import AccordionItem from './accordion-item'
import AccordionText from './accordion-text'
import AccordionTitle from './accordion-title'
import { AccordionContext } from './context'
import { AccordionElement, AccordionProps } from './types'
import { useAccordionProvider } from './use-accordion'

const AccordionRoot = forwardRef<AccordionElement, AccordionProps>(function Accordion(
  props,
  forwardedRef
) {
  const { className, size: sizeProp, children, ...rest } = props

  const context = useAccordionProvider(props)

  const classes = classNames(styles.root, className)

  return (
    <AccordionContext.Provider value={context}>
      <RAccordion.Root {...rest} className={classes} ref={forwardedRef}>
        {children}
      </RAccordion.Root>
    </AccordionContext.Provider>
  )
})

export const Accordion = assignSubComponents('Accordion', AccordionRoot, {
  Item: AccordionItem,
  Header: AccordionHeader,
  Content: AccordionContent,
  Text: AccordionText,
  Title: AccordionTitle,
  HelpText: AccordionHelpText,
  Icon: AccordionIcon,
})

export * from './accordion-content'
export * from './accordion-header'
export * from './accordion-help-text'
export * from './accordion-icon'
export * from './accordion-item'
export * from './accordion-text'
export * from './accordion-title'
export * from './types'

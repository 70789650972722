import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  useContext,
} from 'react'

import { Box_unstable as Box, BoxProps } from '../box/box'
import { CarouselItemProps } from './carousel-item'
import styles from './carousel-list.module.scss'
import { CarouselContext } from './context'

export type CarouselListElement = HTMLUListElement

export type CarouselListProps = BoxProps & HTMLAttributes<CarouselListElement>

const CarouselList = forwardRef<CarouselListElement, CarouselListProps>(function CarouselList(
  props,
  forwardedRef
) {
  const { children, className, ...rest } = props
  const classes = classNames(styles.root, className)

  const ctx = useContext(CarouselContext)

  const refs = useComposedRefs(forwardedRef, ctx.scrollRef)
  const childrenArray = Children.toArray(children)

  return (
    <Box {...rest} className={classes} asChild>
      <ul ref={refs}>
        {childrenArray.map((child, idx) =>
          isValidElement<CarouselItemProps>(child) && ctx.isSnapPoint(idx)
            ? cloneElement(child, {
                snapPoint: idx === childrenArray.length - 1 ? 'end' : 'start',
              })
            : child
        )}
      </ul>
    </Box>
  )
})

export default CarouselList

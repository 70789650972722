import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './file-label.module.scss'
import { useFile } from './use-file'

export type FileLabelElement = HTMLParagraphElement

export interface FileLabelProps extends HTMLAttributes<FileLabelElement> {
  children: ReactNode
}

const FileLabel = forwardRef<FileLabelElement, FileLabelProps>(function FileLabel(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { selected } = useFile()
  const classes = classNames(
    styles.root,
    {
      [styles.selected]: selected,
    },
    className
  )

  return (
    <p {...rest} className={classes} ref={forwardedRef}>
      {children}
    </p>
  )
})

export default FileLabel

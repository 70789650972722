import classNames from 'classnames'
import { forwardRef, RefObject, useImperativeHandle, useRef } from 'react'

import { Button, ButtonElement, ButtonProps } from '../button/button'
import { ChevronDownIcon } from '../icon/icon'
import styles from './collapse-control.module.scss'

export type CollapseControlElement = {
  animateToggle: (nextOpen: boolean) => void
  buttonRef: RefObject<ButtonElement>
}

export type CollapseControlProps = Omit<ButtonProps, 'children'> & {
  open?: boolean
  containerClassName?: string
  showMoreLabel?: string
  showLessLabel?: string
}

export const CollapseControl = forwardRef<CollapseControlElement, CollapseControlProps>(
  function CollapseControl(props, forwardedRef) {
    const {
      open = false,
      className,
      containerClassName,
      showMoreLabel = 'Mostrar mais',
      showLessLabel = 'Mostrar menos',
      ...rest
    } = props

    const buttonRef = useRef<ButtonElement>(null)

    const classes = classNames(styles.button, className)
    const containerClasses = classNames(
      styles.root,
      {
        [styles.rootClamp]: !open,
      },
      containerClassName
    )

    // toggle button without changing the state (re-rendering)
    // it's used to animate the button before changing the "open" state
    function animateToggle(nextOpen: boolean) {
      const el = buttonRef.current
      const container = el?.parentElement
      const label = el?.querySelector('[data-label]')
      const icon = el?.querySelector('[data-icon]')
      if (!container || !label || !icon) return
      label.innerHTML = nextOpen ? showLessLabel : showMoreLabel
      icon.classList.toggle(styles.iconOpen, nextOpen)
      container.classList.toggle(styles.rootClamp, !nextOpen)
    }

    useImperativeHandle(forwardedRef, () => ({
      animateToggle,
      buttonRef,
    }))

    return (
      <div className={containerClasses}>
        <Button
          aria-expanded={open}
          {...rest}
          kind="plain"
          rightIcon={
            <ChevronDownIcon
              className={classNames(styles.icon, {
                [styles.iconOpen]: open,
              })}
              data-icon
            />
          }
          className={classes}
          ref={buttonRef}
        >
          <span data-label>{open ? showLessLabel : showMoreLabel}</span>
        </Button>
      </div>
    )
  }
)

import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import styles from './snippet-footnote-container.module.scss'

export type SnippetFootnoteContainerElement = HTMLDivElement

export type SnippetFootnoteContainerProps = HTMLAttributes<SnippetFootnoteContainerElement> & {
  asChild?: boolean
}

const SnippetFootnoteContainer = forwardRef<
  SnippetFootnoteContainerElement,
  SnippetFootnoteContainerProps
>(function SnippetFootnoteContainer(props, forwardedRef) {
  const { children, className, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default SnippetFootnoteContainer

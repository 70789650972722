import {
  createRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import { ToastContextType, ToastPushOptions } from './context'
import { Toast, ToastElementRoot } from './toast'
import { ToastViewportElement } from './toast-viewport'
import { ToastMessage } from './types'

type ToastKey = string
type ToastItem = {
  message: ToastMessage
  opts: ToastPushOptions
  nodeRef: RefObject<ToastElementRoot>
}
type ToastMap = Map<ToastKey, ToastItem>

export type ToastStoreElement = ToastContextType

export interface ToastStoreProps {
  viewportRef: RefObject<ToastViewportElement>
  defaultItems?: ToastMap
}

export const ToastStore = forwardRef<ToastStoreElement, ToastStoreProps>(function ToastStore(
  props,
  forwardedRef
) {
  const { viewportRef, defaultItems } = props
  const [items, setItems] = useState<ToastMap>(defaultItems ?? new Map())

  const handlePushToast = useCallback<ToastContextType>(
    (message, opts = {}) => {
      setItems((prev) => {
        const newMap: ToastMap = new Map(prev)
        const key: ToastKey = opts?.id || String(Date.now())
        const nodeRef = createRef<ToastElementRoot>()
        newMap.set(key, { message, opts, nodeRef })
        return newMap
      })
    },
    [setItems]
  )

  const handlePullToast = useCallback((key: ToastKey) => {
    setItems((prev) => {
      const newMap: ToastMap = new Map(prev)
      newMap.delete(key)
      return newMap
    })
  }, [])

  useImperativeHandle(forwardedRef, () => handlePushToast)

  useEffect(() => {
    if (!viewportRef.current) return
    viewportRef.current.onStoreUpdate(items.size)
  }, [items, viewportRef])

  return (
    <TransitionGroup>
      {Array.from(items).map(([key, { message, opts, nodeRef }]) => (
        <CSSTransition key={key} timeout={240} nodeRef={nodeRef}>
          {/* set timeout value above based on $animation-duration in toast-viewport.module.scss */}
          <Toast
            {...opts}
            onOpenChange={(open) => {
              if (!open) {
                handlePullToast(key)
              }
            }}
            rootRef={nodeRef}
          >
            {message}
          </Toast>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
})

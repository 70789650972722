import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgHistory(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.533 9.482A7.005 7.005 0 0 1 19 12a7 7 0 1 1-14 0 1 1 0 1 0-2 0 9 9 0 1 0 6.134-8.534 8.951 8.951 0 0 0-3.205 1.89v-.427a1 1 0 1 0-2 0v2.828a1 1 0 0 0 1 1h2.828a.996.996 0 0 0 1-1 1 1 0 0 0-1-1h-.396A7.002 7.002 0 0 1 9.253 5.56 7 7 0 0 1 12 5a6.982 6.982 0 0 1 5.034 2.136 7.006 7.006 0 0 1 1.5 2.346ZM12 7a1 1 0 0 1 1 1v2.057l1.919-1.37a1 1 0 1 1 1.162 1.627l-3.5 2.5A1 1 0 0 1 11 12V8a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgHistory)

import { Checkbox, TreeView } from '@farol-ds/react'
import classNames from 'classnames'

import { Option } from '../types'
import {
  ALL_NODE_ID,
  calculateTreeviewIndeterminatesInDepth2,
  getOptionValues,
  getOptionValuesEnum,
} from '../utils'
import styles from './treeview-checkbox-list-expanded.module.scss'

export interface TreeViewCheckboxListExpandedProps {
  options: Option[]
  filteredOptions: Option[]
  initialValue: string[]
  onSelectedChange: (nodeid: string, selected: string[]) => void
}

export function TreeViewCheckboxListExpanded(props: TreeViewCheckboxListExpandedProps) {
  const { options, filteredOptions, initialValue, onSelectedChange } = props
  const values = getOptionValues(options).map(
    (value) => getOptionValuesEnum(options).get(value) || ''
  )

  return (
    <TreeView.Checkbox
      defaultExpanded={[ALL_NODE_ID, ...values]}
      defaultSelected={initialValue}
      defaultIndeterminate={calculateTreeviewIndeterminatesInDepth2(initialValue, ALL_NODE_ID)}
      onSelectedChange={onSelectedChange}
    >
      <TreeView.Item
        label={
          <Checkbox.Item>
            <Checkbox id={ALL_NODE_ID} />
            <Checkbox.Content>
              <Checkbox.Name>Todas as Opções</Checkbox.Name>
            </Checkbox.Content>
          </Checkbox.Item>
        }
        nodeId={ALL_NODE_ID}
      >
        {options.map((option) => (
          <TreeView.Item
            className={classNames({
              [styles.hidden]: !filteredOptions.some(({ nodeId }) => nodeId === option.nodeId),
            })}
            label={
              <Checkbox.Item>
                <Checkbox id={option.nodeId} />
                <Checkbox.Content>
                  <Checkbox.Name>{option.label}</Checkbox.Name>
                </Checkbox.Content>
              </Checkbox.Item>
            }
            nodeId={option.nodeId}
            key={option.value}
          />
        ))}
      </TreeView.Item>
    </TreeView.Checkbox>
  )
}

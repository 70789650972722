import NextImage, { ImageProps as NextImageProps } from 'next/image'

import { imageLoader } from '../../image-loader'

type ImageProps = NextImageProps
type ImageSrc = ImageProps['src']

function imageSrc(srcProp: ImageSrc): string | null {
  if (typeof srcProp === 'string') {
    return srcProp
  }

  if ('src' in srcProp && typeof srcProp.src === 'string') {
    return srcProp.src
  }

  return null
}

function isSvg(srcProp: ImageSrc): boolean {
  const srcString = imageSrc(srcProp)
  return typeof srcString === 'string' && /\.svg$/i.test(srcString)
}

export function Image(props: ImageProps) {
  const unoptimized = isSvg(props.src)

  return <NextImage loader={imageLoader} {...props} unoptimized={unoptimized} />
}

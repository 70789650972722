import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFlag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 16v6H3V3h9.382a1 1 0 0 1 .894.553L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.382a1 1 0 0 1-.894-.553L12 16H5ZM5 5v9h8.236l1 2H19V7h-6.236l-1-2H5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFlag)

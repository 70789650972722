import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { IncomingMessage } from 'http'

import { ApolloClientOptions, createApolloClient } from './client'

const isSSR = typeof window === 'undefined'
const csrGlobalApolloClientCache: Record<string, ApolloClient<NormalizedCacheObject>> = {}

function objectHash(obj: Record<string, any>) {
  return JSON.stringify(obj, Object.keys(obj).sort())
}

function instantiateSSR(req?: IncomingMessage, options: ApolloClientOptions = {}) {
  // always create a new instance on the server
  const client = createApolloClient(req, options)
  return client
}

function instantiateCSR(req?: IncomingMessage, options: ApolloClientOptions = {}) {
  const cacheKey = objectHash(options)
  const clientFromCache = csrGlobalApolloClientCache[cacheKey]

  if (clientFromCache) {
    return clientFromCache
  }

  const client = createApolloClient(req, options)
  csrGlobalApolloClientCache[cacheKey] = client

  return client
}

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  req?: IncomingMessage,
  options: ApolloClientOptions = {}
) {
  const instantiate = isSSR ? instantiateSSR : instantiateCSR
  const apolloClient = instantiate(req, options)

  if (initialState) {
    apolloClient.cache.restore(initialState)
  }

  return apolloClient
}

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { IconProps } from '../icon/icon'
import styles from './tabs-trigger-leading.module.scss'

export type TabsTriggerLeadingElement = HTMLSpanElement

export interface TabsTriggerLeadingProps extends HTMLAttributes<TabsTriggerLeadingElement> {
  children: ReactNode
}

const TabsTriggerLeading = forwardRef<TabsTriggerLeadingElement, TabsTriggerLeadingProps>(
  function TabsTriggerLeading(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)

    return (
      <span {...rest} className={classes} ref={forwardedRef}>
        {isValidElement<IconProps>(children) ? cloneElement(children, { size: 'sm' }) : children}
      </span>
    )
  }
)

export default TabsTriggerLeading

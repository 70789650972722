import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgTagAdd(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0V7h2a1 1 0 1 0 0-2h-2V3ZM5.707 5.707a4.658 4.658 0 0 1 4.94-1.064 1 1 0 1 0 .706-1.872 6.658 6.658 0 0 0-7.06 10.936l7.586 7.586a3 3 0 0 0 4.242 0l5.172-5.172a3 3 0 0 0 .477-3.621 1 1 0 0 0-1.732 1 1 1 0 0 1-.16 1.207l-5.17 5.172a1 1 0 0 1-1.415 0l-7.586-7.586a4.657 4.657 0 0 1 0-6.586Zm11 7.586a1 1 0 0 0-1.414 0l-2 2a1 1 0 0 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414ZM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgTagAdd)

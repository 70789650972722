import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgArrowRightDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 17a1 1 0 0 1-1 1H8.5a1 1 0 1 1 0-2h6.086L6.293 7.707a1 1 0 0 1 1.414-1.414L16 14.586V8.5a1 1 0 1 1 2 0V17Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgArrowRightDown)

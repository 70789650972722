import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'
import { CheckoutMethod, NextPlanId, TriggerFeature } from '../types'

export interface Properties {
  checkoutMethod: CheckoutMethod
  nextPlansIds: NextPlanId[]
  triggerFeature: TriggerFeature
}

export class GA4ViewItemEvent extends GA4Event {
  ga4_checkout_method: Properties['checkoutMethod']
  ga4_next_plan_id: { item_id: string; item_name: 'next_plan_id' }[]
  ga4_trigger_feature: Properties['triggerFeature']

  constructor({ checkoutMethod, nextPlansIds, triggerFeature }: Properties) {
    super({ event: EVENT.VIEW_ITEM })

    this.ga4_checkout_method = checkoutMethod
    this.ga4_trigger_feature = triggerFeature
    this.ga4_next_plan_id = nextPlansIds
      .filter((nextPlanId): nextPlanId is number => typeof nextPlanId === 'number')
      .map((nextPlanId) => ({
        item_id: `${nextPlanId}`,
        item_name: 'next_plan_id',
      }))
  }
}

import * as Collapsible from '@radix-ui/react-collapsible'
import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import {
  cloneElement,
  forwardRef,
  isValidElement,
  LiHTMLAttributes,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react'

import { BodyText } from '../body-text/body-text'
import { TreeViewContext } from './context'
import styles from './tree-view-item.module.scss'
import TreeViewList, { TreeViewListProps } from './tree-view-list'
import TreeViewToggler, { TreeViewTogglerProps } from './tree-view-toggler'
import { useTreeViewItemA11y } from './use-tree-view-item-a11y'

export type TreeViewItemElement = HTMLLIElement

export type TreeViewItemElementRenderLabelProps<K extends HTMLElement> = {
  ref: React.Ref<K>
  setSelected: () => void
}

export interface TreeViewItemProps extends LiHTMLAttributes<TreeViewItemElement> {
  nodeId: string
  label: ReactNode
  loading?: boolean
  expanded?: boolean
  children?: ReactNode
  togglerProps?: TreeViewTogglerProps
  listProps?: Omit<TreeViewListProps, 'children'>
  onExpandedChange?: (expanded: boolean) => void
}

const TreeViewItem = forwardRef<TreeViewItemElement, TreeViewItemProps>(function TreeViewItem(
  props,
  forwardedRef
) {
  const {
    nodeId,
    className,
    loading,
    expanded: expandedProp,
    children,
    label,
    togglerProps: togglerPropsFromProp,
    listProps,
    onExpandedChange,
    ...rest
  } = props

  const ref = useRef<TreeViewItemElement>(null)

  const context = useContext(TreeViewContext)
  const [expandedState, setExpanded] = useState(context.expanded.includes(nodeId))
  const expanded = expandedProp ?? expandedState
  const isSelected = context.isSelected(nodeId)
  const isVisualSelected = context.isVisualSelected(nodeId)

  const togglerProps: TreeViewTogglerProps = {
    ...togglerPropsFromProp,
    loading,
    expanded,
    selected: isVisualSelected,
    onClick() {
      setExpanded(!expanded)
    },
  }

  const keyRef = useTreeViewItemA11y<TreeViewItemElement>({
    setExpanded: onOpenChange,
    setSelected() {
      context.select(nodeId)
    },
  })
  const refs = useComposedRefs(forwardedRef, keyRef, ref)

  function onOpenChange(open: boolean) {
    if (loading) return
    setExpanded(open)
    onExpandedChange?.(open)
    if (open) context.expand(nodeId)
    else context.collapse(nodeId)
  }

  const classes = classNames(styles.root, className)
  const listClasses = classNames(styles.list, listProps?.className)
  const contentClasses = classNames(styles.content, {
    [styles.contentPadded]: !children,
  })

  return (
    <li
      tabIndex={-1}
      {...rest}
      className={classes}
      ref={refs}
      role="treeitem"
      aria-selected={isSelected}
      aria-expanded={children ? expanded : undefined}
    >
      <Collapsible.Collapsible open={expanded} onOpenChange={onOpenChange} asChild>
        <>
          <span className={contentClasses}>
            {children && (
              <Collapsible.Trigger asChild>
                <TreeViewToggler
                  {...context.getTogglerProps?.(nodeId, togglerProps)}
                  {...togglerProps}
                />
              </Collapsible.Trigger>
            )}
            {isValidElement<unknown>(label) ? (
              cloneElement(label, context.getItemProps?.(nodeId, label.props) || {})
            ) : (
              <BodyText asChild>
                <span>{label}</span>
              </BodyText>
            )}
          </span>

          {children && (
            <Collapsible.Content asChild>
              <TreeViewList {...listProps} className={listClasses} role="group">
                {children}
              </TreeViewList>
            </Collapsible.Content>
          )}
        </>
      </Collapsible.Collapsible>
    </li>
  )
})

export default TreeViewItem

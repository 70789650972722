import { Button, Modal, Radio } from '@farol-ds/react'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect, useState } from 'react'

import { EventData } from '../../../utils'

export enum EntityRelationAnswers {
  PARTY = 'party',
  RELATIVE = 'relative',
  OTHER = 'other',
}

const QUESTIONS = [
  { value: EntityRelationAnswers.PARTY, label: 'Sou o titular deste CPF' },
  { value: EntityRelationAnswers.RELATIVE, label: 'Este CPF pertence a um familiar' },
  { value: EntityRelationAnswers.OTHER, label: 'Este CPF pertence a outra pessoa' },
]

interface EntityRelationQuestionStepProps {
  onSubmit: (answer: EntityRelationAnswers) => void
  eventProps: {
    viewerPid: number
    entityEntityid: string
  } & EventData
}

export function EntityRelationQuestionStep(props: EntityRelationQuestionStepProps) {
  const { onSubmit, eventProps } = props
  const { entityEntityid, viewerPid, ...eventData } = eventProps

  const [answer, setAnswer] = useState<EntityRelationAnswers | undefined>()

  useEffect(() => {
    sendMetrics('UserAccount.ChoosePersonRelationshipViewed', viewerPid?.toString(), {
      from_component: 'MonitorPerson',
      ...eventData,
      metadata: {
        ...eventData.metadata,
        entityId: entityEntityid,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitChoice = () => {
    if (!answer) return

    sendMetrics('UserAccount.ChoosePersonRelationshipSubmitted', viewerPid?.toString(), {
      from_component: 'MonitorPerson',
      ...eventData,
      metadata: {
        ...eventData.metadata,
        relationship: answer,
        entityId: entityEntityid,
      },
    })

    onSubmit(answer)
  }

  return (
    <>
      <Modal.Header>
        <Modal.HeaderTitle>Como você está vinculado a este CPF?</Modal.HeaderTitle>
      </Modal.Header>
      <Modal.Body data-testid="entity-relation-question-step">
        <Radio.Group
          orientation="vertical"
          defaultValue={answer as string}
          onValueChange={(value) => setAnswer(value as EntityRelationAnswers)}
        >
          {QUESTIONS.map(({ label, value }) => (
            <Radio.Item key={value}>
              <Radio value={value} checked={answer === value} data-testid={`${value}-radio`} />
              <Radio.Content>
                <Radio.Name>{label}</Radio.Name>
              </Radio.Content>
            </Radio.Item>
          ))}
        </Radio.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button kind="primary" disabled={!answer} onClick={onSubmitChoice}>
          Avançar
        </Button>
      </Modal.Footer>
    </>
  )
}

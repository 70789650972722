import { Banner, FormControl, Search, WarningIcon } from '@farol-ds/react'
import { useState } from 'react'

import { TreeViewCheckboxListExpanded } from '../treeview-checkbox-list-expanded'
import { Option } from '../types'
import styles from './treeview-checkbox-filter.module.scss'

export interface TreeViewCheckboxFilterProps {
  placeholder: string
  initialValue: string[]
  options: Option[]
  errorMessage?: string
  className?: string
  onSelectedChange: (nodeid: string, selected: string[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

export function TreeViewCheckboxFilter({
  initialValue,
  placeholder,
  options,
  errorMessage,
  onSelectedChange,
  onSearchChange,
  dataTestId,
  className,
}: TreeViewCheckboxFilterProps) {
  const [value, setValue] = useState('')

  const normalizeLabels = (label: string) =>
    label
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  let filteredOptions = options

  if (value) {
    const normalizedValue = normalizeLabels(value)
    filteredOptions = filteredOptions.filter((option) => {
      return normalizeLabels(option.label).includes(normalizedValue)
    })
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target?.value || '')
    onSearchChange(event)
  }

  return (
    <FormControl className={className} data-testid={dataTestId}>
      <div className={styles.treeviewCheckboxFilterSearchInput}>
        <Search
          onValueChange={onChange}
          placeholder={placeholder}
          defaultValue={value}
          size="md"
          type="secondary"
        />
      </div>
      {filteredOptions.length === 0 && (
        <Banner defaultVisible type="critical">
          <Banner.Content>
            <Banner.Title>
              <Banner.Icon>
                <WarningIcon />
              </Banner.Icon>
              Nenhum resultado encontrado
            </Banner.Title>
          </Banner.Content>
        </Banner>
      )}
      {filteredOptions.length > 0 && (
        <TreeViewCheckboxListExpanded
          initialValue={initialValue}
          options={options}
          filteredOptions={filteredOptions}
          onSelectedChange={onSelectedChange}
        />
      )}
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl>
  )
}

/* eslint-disable react/jsx-no-target-blank */
import { BodyText } from '@farol-ds/react'

import { SubscribeLink } from '../../types'
import { TopbarJusIAButtonPopover } from '../topbar-jus-ia-button-popover'
import styles from './topbar-jus-ia-button.module.scss'

interface TopbarJusIAButtonProps {
  subscribeLink?: SubscribeLink
  onLinkClick?: (e: React.MouseEvent) => void
}

const JusIAIcon = () => (
  <svg
    className={styles.icon}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6774 0.468293C17.4238 0.165854 17.053 0 16.6628 0H8.73109C8.08718 0 7.54084 0.468293 7.42377 1.10244L5.83352 10.1756H1.88231C1.41401 10.1756 0.984744 10.4195 0.740842 10.8195C0.496939 11.2195 0.487183 11.7171 0.711573 12.1268L4.52621 19.3366C4.69206 19.639 4.97499 19.8634 5.32621 19.9512C5.38474 19.9707 5.54084 20 5.7067 20H13.6384C14.1555 20 14.7799 19.639 14.9165 18.9659L17.9799 1.56098C18.0482 1.17073 17.9408 0.780488 17.6872 0.478049L17.6774 0.468293ZM5.58962 11.5902L4.70182 16.6439L2.02865 11.5902H5.58962ZM7.28718 10.1756L8.81889 1.42439H16.5652L13.9311 16.4293L10.9945 10.8878C10.7604 10.4585 10.3116 10.1854 9.82377 10.1854H7.28718V10.1756ZM13.4628 18.5854H5.80426L7.03352 11.6H9.76523L13.4628 18.5854Z"
      fill="white"
    />
  </svg>
)

export const TopbarJusIAButton = ({ subscribeLink, onLinkClick }: TopbarJusIAButtonProps) => {
  return (
    <div className={styles.container}>
      <a
        href={`${subscribeLink?.url}?fromComponent=topbar-subscribe-cta`}
        target="_blank"
        data-testid="topbar-subscribe-cta-link"
        onClick={onLinkClick}
        className={styles.button}
      >
        <JusIAIcon />
        <BodyText size="md" className={styles.text}>
          {subscribeLink?.label}
        </BodyText>
      </a>
      <TopbarJusIAButtonPopover>
        <button className={styles.hiddenTrigger}></button>
      </TopbarJusIAButtonPopover>
    </div>
  )
}

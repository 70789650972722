import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgChevronUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.293 9.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L12 11.414l-3.293 3.293a1 1 0 0 1-1.414-1.414l4-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgChevronUp)

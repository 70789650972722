import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEdit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.607 4.828a2 2 0 1 1 2.828 2.829l-1.34 1.34-2.828-2.83 1.34-1.339Zm4.242-1.414a4 4 0 0 0-5.656 0L13.15 5.456l-.013.012-9.067 9.068c-1.357 1.357-1.822 3.087-1.965 4.397a10.064 10.064 0 0 0 0 2.166c.008.06.014.11.02.145l.006.042.002.014v.006l.001.001.986-.165-.986.166a1 1 0 0 0 .82.82l.166-.984-.165.985h.008l.013.003.043.006.144.019a10.067 10.067 0 0 0 2.166 0c1.31-.143 3.04-.608 4.398-1.964l9.062-9.063.013-.012 2.046-2.047a4 4 0 0 0 0-5.657ZM5.112 20.17c-.4.044-.765.052-1.063.045a8.05 8.05 0 0 1 .045-1.063c.117-1.073.481-2.292 1.391-3.202l8.36-8.36 2.829 2.828-8.36 8.36c-.91.91-2.13 1.274-3.202 1.392Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEdit)

import { NextWebVitalsMetric } from 'next/app'

import { reportWebVitalsToConsole } from './report-webvitals-to-console'
import { reportWebVitalsToGA } from './report-webvitals-to-ga'

export interface ConfigureReportWebVitalsConfig {
  debug?: boolean
}

export function configureReportWebVitals(
  config: ConfigureReportWebVitalsConfig = { debug: false }
) {
  return function reportWebVitals(metric: NextWebVitalsMetric): void {
    reportWebVitalsToGA(metric)

    if (config.debug) {
      reportWebVitalsToConsole(metric)
    }
  }
}

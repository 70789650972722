import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgVideo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9a3 3 0 0 1 3-3h9c1.41 0 2.594.973 2.914 2.285l2.057-1.234c1.333-.8 3.029.16 3.029 1.715v6.468c0 1.554-1.696 2.515-3.029 1.715l-2.057-1.234A3.001 3.001 0 0 1 14 18H5a3 3 0 0 1-3-3V9Zm15 4.434v-2.868l3-1.8v6.468l-3-1.8ZM15 9v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1Zm-8 1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgVideo)

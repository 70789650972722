import classNames from 'classnames'
import { AriaRole, forwardRef, HTMLAttributes, ReactNode } from 'react'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './banner.module.scss'
import BannerAction from './banner-action'
import BannerClose from './banner-close'
import BannerContent from './banner-content'
import BannerDescription from './banner-description'
import BannerIcon from './banner-icon'
import BannerIconClose from './banner-icon-close'
import BannerTitle from './banner-title'
import { BannerContext } from './context'
import { useBannerProvider } from './use-banner'

export type BannerElement = HTMLDivElement
export const BannerTypes = ['info', 'positive', 'warning', 'critical', 'discovery'] as const
export type BannerType = (typeof BannerTypes)[number]
export const BannerLevels = ['standard', 'app'] as const
export type BannerLevel = (typeof BannerLevels)[number]

export interface OnVisibleChangeOptions {
  waitAnimation: (callback: () => void) => void
}

export interface BannerProps extends HTMLAttributes<BannerElement> {
  children: ReactNode
  visible?: boolean
  defaultVisible?: boolean
  onVisibleChange?: (visible: boolean, options: OnVisibleChangeOptions) => void
  type?: BannerType
  level?: BannerLevel
  className?: string
}

const roleMapping: Record<BannerType, AriaRole> = {
  info: 'status',
  positive: 'status',
  critical: 'alert',
  warning: 'alert',
  discovery: 'info',
}

const BannerRoot = forwardRef<BannerElement, BannerProps>(function Banner(props, forwardedRef) {
  const {
    className,
    children,
    type: typeProp,
    level: levelProp,
    visible: _,
    defaultVisible: __,
    onVisibleChange: ___,
    ...rest
  } = props

  const type = ensurePropOf<BannerType>(BannerTypes, typeProp, 'info')
  const level = ensurePropOf<BannerLevel>(BannerLevels, levelProp, 'standard')

  const context = useBannerProvider({
    ...props,
    type,
    level,
  })

  if (!context.visible) return null

  const { isMount, hasIconClose } = context

  const classes = classNames(
    styles.root,
    {
      [styles[`type${type}`]]: type,
      [styles[`level${level}`]]: level !== 'standard',
      [styles.hasIconClose]: hasIconClose,
      [styles.isHidding]: !isMount,
    },
    className
  )

  return (
    <BannerContext.Provider value={context}>
      <div {...rest} className={classes} ref={forwardedRef} role={roleMapping[type]}>
        {children}
      </div>
    </BannerContext.Provider>
  )
})

export const Banner = assignSubComponents('Banner', BannerRoot, {
  Action: BannerAction,
  Close: BannerClose,
  Content: BannerContent,
  Description: BannerDescription,
  Icon: BannerIcon,
  IconClose: BannerIconClose,
  Title: BannerTitle,
})

export * from './banner-action'
export * from './banner-close'
export * from './banner-content'
export * from './banner-description'
export * from './banner-icon'
export * from './banner-icon-close'
export * from './banner-title'

import * as RDialog from '@radix-ui/react-dialog'
import { forwardRef } from 'react'

import { Heading, HeadingElement, HeadingProps } from '../heading/heading'

export type ModalHeaderTitleElement = HeadingElement
export type ModalHeaderTitleProps = HeadingProps

const ModalHeaderTitle = forwardRef<ModalHeaderTitleElement, ModalHeaderTitleProps>(
  function ModalHeaderTitle(props, forwardedRef) {
    return (
      <RDialog.Title asChild>
        <Heading {...props} size="md" ref={forwardedRef} />
      </RDialog.Title>
    )
  }
)

export default ModalHeaderTitle

import * as RCheckbox from '@radix-ui/react-checkbox'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { CheckIcon, SubtractIcon } from '../icon/icon'
import { assignSubComponents } from '../utilities/internal'
import styles from './checkbox.module.scss'
import CheckboxContent from './checkbox-content'
import CheckboxDescription from './checkbox-description'
import CheckboxGroup from './checkbox-group'
import CheckboxItem from './checkbox-item'
import CheckboxName from './checkbox-name'

export type CheckboxElement = HTMLButtonElement

export interface CheckboxProps extends Omit<RCheckbox.CheckboxProps, 'children'> {
  className?: string
  indeterminate?: boolean
}

const CheckboxRoot = forwardRef<CheckboxElement, CheckboxProps>(function Checkbox(
  props,
  forwardedRef
) {
  const { indeterminate = false, className, ...rest } = props

  const Icon = indeterminate ? SubtractIcon : CheckIcon

  const classes = classNames(styles.root, className)

  return (
    <RCheckbox.Root {...rest} className={classes} ref={forwardedRef}>
      <RCheckbox.Indicator className={styles.indicator}>
        <Icon size="xs" className={styles.icon} />
      </RCheckbox.Indicator>
    </RCheckbox.Root>
  )
})

export const Checkbox = assignSubComponents('Checkbox', CheckboxRoot, {
  Name: CheckboxName,
  Content: CheckboxContent,
  Description: CheckboxDescription,
  Item: CheckboxItem,
  Group: CheckboxGroup,
})

export * from './checkbox-content'
export * from './checkbox-description'
export * from './checkbox-group'
export * from './checkbox-item'
export * from './checkbox-name'

import { RefObject } from 'react'

import { useKeyPress } from '../utilities'

export interface UseTreeViewItemA11yProps {
  setExpanded: (expanded: boolean) => void
  setSelected: () => void
}

export function useTreeViewItemA11y<Item extends HTMLElement>(
  props: UseTreeViewItemA11yProps
): RefObject<Item> {
  const { setExpanded, setSelected } = props

  const { ref } = useKeyPress<Item>([], (e) => {
    if (document.activeElement !== ref.current) return

    switch (e.key) {
      case 'ArrowLeft': {
        setExpanded(false)
        break
      }

      case 'ArrowRight': {
        setExpanded(true)
        break
      }

      case 'Enter':
      case ' ': {
        setSelected()
        break
      }
    }
  })

  return ref
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { PrimitiveText } from '../primitive-text/primitive-text'
import { ensurePropOf } from '../utilities'
import styles from './badge.module.scss'
import { useBadge } from './use-badge'

export const BadgeTypes = ['tertiary', 'primary', 'secondary'] as const
export const BadgeVariants = ['label', 'dot'] as const
export const BadgeOverlaps = ['circular', 'rectangular'] as const
export type BadgeElement = HTMLSpanElement
export type BadgeType = (typeof BadgeTypes)[number]
export type BadgeOverlap = (typeof BadgeOverlaps)[number]
export type BadgeVariant = (typeof BadgeVariants)[number]
export type BadgeCount = number

export type BadgeProps = HTMLAttributes<BadgeElement> & {
  type?: BadgeType
  overlap?: BadgeOverlap
  children?: ReactNode
  showZero?: boolean
} & (
    | {
        variant?: 'label'
        count: BadgeCount
      }
    | {
        variant: 'dot'
        count?: BadgeCount
      }
  )

const MAX_COUNT = 99

export const Badge = forwardRef<BadgeElement, BadgeProps>(function Badge(props, forwardedRef) {
  const {
    children,
    className,
    type: typeProp,
    overlap: overlapProp,
    variant: variantProp,
    count = variantProp === 'dot' ? 1 : 0,
    showZero = false,
    ...rest
  } = props

  const type = ensurePropOf<BadgeType>(BadgeTypes, typeProp, 'tertiary')
  const variant = ensurePropOf<BadgeVariant>(BadgeVariants, variantProp, 'label')
  const overlap = ensurePropOf<BadgeOverlap>(BadgeOverlaps, overlapProp, 'rectangular')

  const { invisible, displayValue } = useBadge({
    showZero,
    count,
    max: MAX_COUNT,
  })

  if (invisible) {
    if (!children) return null
    return <span ref={forwardedRef}>{children}</span>
  }

  const classes = classNames(
    styles.container,
    {
      [styles.containerOverhead]: !!children,
      [styles.circular]: overlap === 'circular',
    },
    className
  )

  return (
    <span {...rest} className={classes} ref={forwardedRef}>
      {children}

      <span className={classNames(styles.root, styles[type], styles[variant])}>
        {variant === 'label' && (
          <PrimitiveText className={styles.labelText} asChild>
            <span>{displayValue}</span>
          </PrimitiveText>
        )}
      </span>
    </span>
  )
})

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './checkbox-content.module.scss'
import itemStyles from './checkbox-item.module.scss'

export type CheckboxContentElement = HTMLSpanElement

export interface CheckboxContentProps extends HTMLAttributes<CheckboxContentElement> {
  children: ReactNode
}

const CheckboxContent = forwardRef<CheckboxContentElement, CheckboxContentProps>(
  function CheckboxContent(props, forwardedRef) {
    const { className, children, ...rest } = props

    const classes = classNames(styles.root, itemStyles.itemContent, className)

    return (
      <span ref={forwardedRef} {...rest} className={classes}>
        {children}
      </span>
    )
  }
)

export default CheckboxContent

import { createContext, RefObject } from 'react'

import { AutocompleteMenuElement } from './autocomplete-menu'
import { type AutocompleteTriggerElement } from './autocomplete-trigger'
import { AutocompleteOption } from './types'

export type AutocompleteContextValue<K extends AutocompleteOption = AutocompleteOption> = {
  menuRef: RefObject<AutocompleteMenuElement<K>>
  triggerRef: RefObject<AutocompleteTriggerElement>
}

export const AutocompleteContext = createContext<AutocompleteContextValue>(
  {} as AutocompleteContextValue
)

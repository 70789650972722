import { ABTestExperiment } from '@jusbrasil-web/shared-ab-test'
import { createContext, useContext } from 'react'

interface ExperimentsContextInterface {
  isParticipating: (experimentName: string, variant: string) => boolean
  getExperiment: (experimentName: string) => ABTestExperiment | undefined
}

interface ExperimentsProviderProps {
  children: React.ReactNode
  experiments: ABTestExperiment[]
}

export const ExperimentsContext = createContext<ExperimentsContextInterface | null>(null)

export function useExperiments() {
  const context = useContext(ExperimentsContext)
  if (!context) {
    throw new Error('useExperiments must be used within an ExperimentsProvider')
  }
  return context
}

export const ExperimentsProvider = (props: ExperimentsProviderProps) => {
  const { children, experiments } = props

  const getExperiment = (experimentName: string): ABTestExperiment | undefined =>
    experiments.find((experiment: ABTestExperiment) => experiment?.experiment === experimentName)

  const isParticipating = (experimentName: string, variant: string): boolean => {
    const experiment = getExperiment(experimentName)
    return Boolean(experiment?.participating && experiment?.alternative === variant)
  }

  return (
    <ExperimentsContext.Provider value={{ isParticipating, getExperiment }}>
      {children}
    </ExperimentsContext.Provider>
  )
}

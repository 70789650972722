import Script from 'next/script'
import { useEffect } from 'react'

import { useGTMDataLayer } from '../use-gtm-data-layer'

export interface GTMProvider {
  appId: string
  gtmId?: string
  contentGroup?: string
  section?: string
}

export function GTMProvider(props: GTMProvider) {
  const { appId, gtmId = process.env.NEXT_PUBLIC_GTM_ID, contentGroup, section } = props
  const isProd = process.env.NODE_ENV === 'production'
  const isEnabled = gtmId !== undefined && isProd

  const dataLayer = useGTMDataLayer({
    ga4_app_id: appId,
    ga4_content_group: contentGroup,
    ga4_section: section,
  })

  useEffect(() => {
    if (!isEnabled) {
      if (isProd) {
        console.error('[gtm] Error: not gtmId entered properly')
      } else {
        console.warn('[gtm] disabled in development mode')
      }
    }
  }, [isEnabled, gtmId, isProd])

  return isEnabled ? (
    <>
      <noscript>
        <iframe
          title="Google Tag Manager"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <Script
        id="google-tag-manager-datalayer"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(${JSON.stringify(dataLayer)});
        `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');
        `,
        }}
      />
    </>
  ) : null
}

import classNames from 'classnames'
import { forwardRef } from 'react'

import { BodyText, BodyTextElement, BodyTextProps } from '../body-text/body-text'
import styles from './popover-content-description.module.scss'
import { usePopover } from './use-popover'

export type PopoverContentDescriptionElement = BodyTextElement
export type PopoverContentDescriptionProps = BodyTextProps

const PopoverContentDescription = forwardRef<
  PopoverContentDescriptionElement,
  PopoverContentDescriptionProps
>(function PopoverContentDescription(props, forwardedRef) {
  const { className, ...rest } = props
  const { type } = usePopover()
  const classes = classNames(
    {
      [styles.highlight]: type === 'highlight',
    },
    className
  )
  return <BodyText {...rest} className={classes} ref={forwardedRef} />
})

export default PopoverContentDescription

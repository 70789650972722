import classNames from 'classnames'
import { forwardRef } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './icon.module.scss'
import {
  IconComponent,
  IconComponentType,
  IconElement,
  IconProps,
  IconSize,
  IconSizes,
} from './types'

export function wrapIcon(
  Icon: React.FunctionComponent<IconProps>,
  priorityProps?: Partial<IconProps>
): IconComponent {
  const BaseIcon = forwardRef<IconElement, IconProps>(function BaseIcon(props, forwardedRef) {
    const { className, size: sizeProp, ...rest } = props
    const size = ensurePropOf<IconSize>(IconSizes, sizeProp, 'md')
    const classes = classNames(styles.root, styles[`size${size}`], className)
    return (
      <Icon
        {...rest}
        className={classes}
        width="100%"
        height="100%"
        focusable={false}
        aria-hidden
        ref={forwardedRef}
        {...priorityProps}
      />
    )
  })
  BaseIcon.displayName = `${Icon.name.replace(/^Svg/, '')}Icon`
  ;(BaseIcon as unknown as IconComponentType).__wrappedIcon = true
  return BaseIcon
}

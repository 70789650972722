import { NextApiHandler, NextApiResponse } from 'next'

import { healthCheckMiddleware } from './check'
import { graphqlMiddleware } from './graphql'

function notFoundResponse(res: NextApiResponse) {
  res.status(404).send(null)
}

export const configureHandler = (queryName: string): NextApiHandler => {
  return async (req, res) => {
    const path = req.query[queryName] || ''
    const paths = typeof path === 'string' ? [path] : path
    const urlPath = paths.join('/')

    switch (urlPath) {
      case 'check':
        return healthCheckMiddleware(req, res)
      case 'graphql':
      case 'graphql/apollo-batch':
        return graphqlMiddleware(req, res)
      case 'metrics':
        if (process.env.NEXT_RUNTIME === 'nodejs') {
          const { prometheusMiddleware } = await import('../prometheus/middleware')
          return prometheusMiddleware(req, res)
        }
        notFoundResponse(res)
        break
      default:
        notFoundResponse(res)
    }
  }
}

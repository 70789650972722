import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgIaSkillDocReview(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5a1 1 0 0 0-1-1h-5v4a2 2 0 0 1-2 2H5v9a1 1 0 0 0 1 1h5a1 1 0 1 1 0 2H6a3 3 0 0 1-3-3V8.828a3 3 0 0 1 .879-2.12l3.828-3.83A3 3 0 0 1 9.828 2H16a3 3 0 0 1 3 3v2a1 1 0 1 1-2 0V5ZM5.414 8H9V4.414L5.414 8ZM7 17a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1Zm1-5a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H8Zm11.611 2.138-.35 1.066a.275.275 0 0 1-.522 0l-.35-1.066a.825.825 0 0 0-.527-.527l-1.066-.35a.275.275 0 0 1 0-.522l1.066-.35a.825.825 0 0 0 .527-.527l.35-1.066a.275.275 0 0 1 .522 0l.35 1.066c.082.25.277.445.527.527l1.066.35a.275.275 0 0 1 0 .522l-1.066.35a.825.825 0 0 0-.527.527Zm-3.35 7.666.35-1.066a.825.825 0 0 1 .527-.527l1.066-.35a.275.275 0 0 0 0-.522l-1.066-.35a.825.825 0 0 1-.527-.527l-.35-1.066a.275.275 0 0 0-.522 0l-.35 1.066a.825.825 0 0 1-.527.527l-1.066.35a.275.275 0 0 0 0 .522l1.066.35c.25.082.445.277.527.527l.35 1.066c.082.252.44.252.522 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgIaSkillDocReview)

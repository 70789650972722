import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgJusbrasil(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.025 4a1.867 1.867 0 0 0-.953.248 1.836 1.836 0 0 0-.679 2.516L12 9.534l1.607-2.77c.51-.88.209-2.005-.68-2.516A1.857 1.857 0 0 0 12.026 4Zm1.131 7.527 2.142-3.694 5.454 9.403c.51.88.209 2.005-.679 2.515a1.863 1.863 0 0 1-2.539-.675l-4.378-7.549ZM12 13.52l3.804 6.56a3.864 3.864 0 0 0 5.266 1.406 3.836 3.836 0 0 0 1.412-5.253L15.34 3.92a3.844 3.844 0 0 0-2.463-1.82 3.849 3.849 0 0 0-1.756 0 3.844 3.844 0 0 0-2.463 1.82L1.52 16.233a3.836 3.836 0 0 0 1.411 5.253 3.864 3.864 0 0 0 5.266-1.407L12 13.52Zm-1.156-1.993L8.702 7.833l-5.454 9.403c-.51.88-.209 2.005.679 2.515.89.512 2.027.207 2.539-.675l4.378-7.549Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.338 3.922.003-.002a3.844 3.844 0 0 0-2.463-1.82 3.849 3.849 0 0 0-1.756 0 3.844 3.844 0 0 0-2.463 1.82l.003.002a3.83 3.83 0 0 0 .001 3.845L12 13.52l3.337-5.753a3.83 3.83 0 0 0 .001-3.845ZM12 4c-.315 0-.635.08-.928.248a1.836 1.836 0 0 0-.679 2.516L12 9.534l1.607-2.77c.51-.88.209-2.005-.68-2.516A1.857 1.857 0 0 0 12 4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgJusbrasil)

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Caption, CaptionElement } from '../caption/caption'
import styles from './radio-description.module.scss'
import itemStyles from './radio-item.module.scss'

export type RadioDescriptionElement = CaptionElement

export interface RadioDescriptionProps extends HTMLAttributes<RadioDescriptionElement> {
  children: ReactNode
  className?: string
}

const RadioDescription = forwardRef<RadioDescriptionElement, RadioDescriptionProps>(
  function RadioDescription(props, forwardedRef) {
    const { className, children, ...rest } = props

    const classes = classNames(styles.root, itemStyles.itemDescription, className)

    return (
      <Caption ref={forwardedRef} {...rest} className={classes} asChild>
        <span>{children}</span>
      </Caption>
    )
  }
)

export default RadioDescription

import { initializeApollo } from '@jusbrasil-web/shared-apollo-client'

import { APOLLO_INTERNAL_CSR_URI, APOLLO_INTERNAL_STATE } from '../constants'
import { AppPropsWithApollo, WithApollo } from '../types'

const noop = () => undefined

export const withApollo: WithApollo = (ctx, options = {}) => {
  const { initialState, clientOverrides = {} } = options
  const client = initializeApollo(initialState, ctx.req, clientOverrides)
  return {
    client,
    withApolloProps() {
      const clientCacheExtract = client.cache.extract()
      client
        .clearStore()
        .then(noop)
        .catch((rejectionReason) =>
          console.error(
            'Error fire-and-forget clearing Apollo client store after request',
            rejectionReason
          )
        )

      const props: AppPropsWithApollo = {
        [APOLLO_INTERNAL_STATE]: clientCacheExtract,
      }

      if (clientOverrides.csrUri) {
        props[APOLLO_INTERNAL_CSR_URI] = clientOverrides.csrUri
      }

      return props
    },
  }
}

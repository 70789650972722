/*
 * IMPORTANT
 * This file is a copy from the SOURCE.
 * Please ensure that any changes made there are also updated in this file.
 *
 * Why are we copying this file?
 * Because of a bug in `@radix-ui/react-dropdown-menu` v2.0.6 that uses `onPointerDown` mouse event
 * to handle user click interaction. This implies in an unexpected behavior that triggers dropdown
 * menu opening when user is scrolling.
 *
 * See the `radix-ui` issue below for more info:
 * https://github.com/radix-ui/primitives/issues/1912
 *
 * SOURCE: libs/shared/farol-ds/react/src/lib/dropdown/utils.ts
 *
 * [TODO] @gabrielrtakeda.
 */

/*
 * This function injects an id attribute to the current open portal.
 * It's required to support customization in the radix-ui popper
 * for our dropdown mobile version, without interfering with other poppers.
 */
export function __identifyRadixDropdownPortal(open: boolean, retry = 5) {
  if (!open || retry <= 0) return
  setTimeout(() => {
    const portal = document.querySelector<HTMLElement>('[data-radix-popper-content-wrapper]')
    if (portal) {
      portal.dataset.fdsDropdownPortal = 'true'
    } else {
      __identifyRadixDropdownPortal(open, retry - 1)
    }
  }, 0)
}

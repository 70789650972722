import type { RadioGroupProps as RRadioGroupProps } from '@radix-ui/react-radio-group'
import * as RRadioGroup from '@radix-ui/react-radio-group'
import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement } from 'react'

import { ensurePropOf } from '../utilities'
import type { RadioProps } from './radio'
import styles from './radio-group.module.scss'
import type { RadioItemProps } from './radio-item'

export type RadioGroupElement = HTMLDivElement
export const RadioGroupOrientations = ['vertical', 'horizontal'] as const
export type RadioGroupOrientation = (typeof RadioGroupOrientations)[number]

export interface RadioGroupProps extends RRadioGroupProps {
  orientation?: RadioGroupOrientation
}

const RadioGroup = forwardRef<RadioGroupElement, RadioGroupProps>(function RadioGroup(
  props,
  forwardedRef
) {
  const { className, children, disabled = undefined, orientation: orientationProp, ...rest } = props

  const orientation = ensurePropOf<RadioGroupOrientation>(
    RadioGroupOrientations,
    orientationProp,
    'vertical'
  )

  const classes = classNames(
    styles.root,
    {
      [styles[`orientation${orientation}`]]: !!orientation,
      [styles.disabled]: !!disabled,
    },
    className
  )

  return (
    <RRadioGroup.Root {...rest} disabled={disabled} className={classes} ref={forwardedRef}>
      {disabled !== undefined
        ? Children.map(children, (child) => {
            if (!isValidElement<RadioItemProps | RadioProps>(child)) {
              return child
            }
            return cloneElement(child, { disabled: child.props.disabled ?? disabled })
          })
        : children}
    </RRadioGroup.Root>
  )
})

export default RadioGroup

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgAi(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.164 12.069c-.485-.138-.485-.826 0-.963l2.662-.75a5.2 5.2 0 0 0 3.593-3.594l.75-2.661c.137-.486.826-.486.963 0l.75 2.661a5.2 5.2 0 0 0 3.594 3.593l2.66.751c.486.137.486.825 0 .963l-2.66.75a5.2 5.2 0 0 0-3.594 3.593l-.75 2.662c-.137.485-.826.485-.963 0l-.75-2.662a5.2 5.2 0 0 0-3.593-3.593l-2.662-.75Zm7.486 2.157a7.205 7.205 0 0 0-2.638-2.639A7.204 7.204 0 0 0 9.65 8.95a7.204 7.204 0 0 0 2.639 2.638 7.204 7.204 0 0 0-2.639 2.639ZM17.369 15.456a2.4 2.4 0 0 1-1.046.613l-.992.28c-.291.082-.291.495 0 .577l.992.28a2.406 2.406 0 0 1 1.526 1.3c.053.115.098.235.133.358l.28.992c.082.292.495.292.577 0l.28-.992a2.402 2.402 0 0 1 1.658-1.658l.992-.28c.292-.082.292-.495 0-.577l-.992-.28a2.395 2.395 0 0 1-1.658-1.658l-.28-.992c-.082-.292-.495-.292-.577 0l-.28.992M17.369 5.256a2.399 2.399 0 0 1-1.046.613l-.992.28c-.291.082-.291.495 0 .577l.992.28a2.403 2.403 0 0 1 1.659 1.658l.28.992c.082.292.495.292.577 0l.28-.992a2.403 2.403 0 0 1 1.658-1.658l.992-.28c.292-.082.292-.495 0-.577l-.992-.28a2.403 2.403 0 0 1-1.658-1.658l-.28-.992c-.082-.292-.495-.292-.577 0l-.28.992"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgAi)

import { NextFarolFont, NextFarolFontProps } from './font'
import { NextFarolProgressLoader, NextFarolProgressLoaderProps } from './progress-loader'

export type NextFarolProviderProps = {
  font?: Partial<NextFarolFontProps>
  progressLoader?: Partial<NextFarolProgressLoaderProps>
}

export function NextFarolProvider(props: NextFarolProviderProps) {
  const { font, progressLoader } = props

  return (
    <>
      <NextFarolFont {...font} />
      <NextFarolProgressLoader {...progressLoader} />
    </>
  )
}

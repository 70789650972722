export { default as AddIcon } from './Add'
export { default as AddRoundIcon } from './AddRound'
export { default as AiIcon } from './Ai'
export { default as AlertIcon } from './Alert'
export { default as AppsIcon } from './Apps'
export { default as ArchiveIcon } from './Archive'
export { default as ArrowDownIcon } from './ArrowDown'
export { default as ArrowLeftIcon } from './ArrowLeft'
export { default as ArrowLeftDownIcon } from './ArrowLeftDown'
export { default as ArrowLeftUpIcon } from './ArrowLeftUp'
export { default as ArrowRightIcon } from './ArrowRight'
export { default as ArrowRightDownIcon } from './ArrowRightDown'
export { default as ArrowRightUpIcon } from './ArrowRightUp'
export { default as ArrowUpIcon } from './ArrowUp'
export { default as AttachmentIcon } from './Attachment'
export { default as BagIcon } from './Bag'
export { default as BookIcon } from './Book'
export { default as BookmarkIcon } from './Bookmark'
export { default as BookmarkActiveIcon } from './BookmarkActive'
export { default as CalendarIcon } from './Calendar'
export { default as CalendarEventIcon } from './CalendarEvent'
export { default as CameraIcon } from './Camera'
export { default as CardIcon } from './Card'
export { default as CaseIcon } from './Case'
export { default as CheckIcon } from './Check'
export { default as CheckDoubleIcon } from './CheckDouble'
export { default as CheckRoundIcon } from './CheckRound'
export { default as ChevronDownIcon } from './ChevronDown'
export { default as ChevronLeftIcon } from './ChevronLeft'
export { default as ChevronRightIcon } from './ChevronRight'
export { default as ChevronUpIcon } from './ChevronUp'
export { default as ClockIcon } from './Clock'
export { default as CloseIcon } from './Close'
export { default as CloseSidebarIcon } from './CloseSidebar'
export { default as CollectionIcon } from './Collection'
export { default as CommentIcon } from './Comment'
export { default as ComputerIcon } from './Computer'
export { default as ContactsIcon } from './Contacts'
export { default as CopyIcon } from './Copy'
export { default as CouponIcon } from './Coupon'
export { default as CourtIcon } from './Court'
export { default as DarkModeIcon } from './DarkMode'
export { default as DeleteIcon } from './Delete'
export { default as DislikeIcon } from './Dislike'
export { default as DislikeActiveIcon } from './DislikeActive'
export { default as DocumentIcon } from './Document'
export { default as DownloadIcon } from './Download'
export { default as DragIcon } from './Drag'
export { default as EarthIcon } from './Earth'
export { default as EditIcon } from './Edit'
export { default as EmailIcon } from './Email'
export { default as EmailOpenIcon } from './EmailOpen'
export { default as EyeIcon } from './Eye'
export { default as EyeOffIcon } from './EyeOff'
export { default as FacebookIcon } from './Facebook'
export { default as FeedIcon } from './Feed'
export { default as FileSearchIcon } from './FileSearch'
export { default as FileZipIcon } from './FileZip'
export { default as FilterIcon } from './Filter'
export { default as FireIcon } from './Fire'
export { default as FlagIcon } from './Flag'
export { default as FolderIcon } from './Folder'
export { default as FullscreenIcon } from './Fullscreen'
export { default as FullscreenActiveIcon } from './FullscreenActive'
export { default as GiftIcon } from './Gift'
export { default as HammerIcon } from './Hammer'
export { default as HeartIcon } from './Heart'
export { default as HeartActiveIcon } from './HeartActive'
export { default as HistoryIcon } from './History'
export { default as HomeIcon } from './Home'
export { default as IaSkillDocDraftingIcon } from './IaSkillDocDrafting'
export { default as IaSkillDocReviewIcon } from './IaSkillDocReview'
export { default as IaSkillLegalResearchIcon } from './IaSkillLegalResearch'
export { default as IaSubscribedIcon } from './IaSubscribed'
export { default as ImageIcon } from './Image'
export { default as InboxIcon } from './Inbox'
export { default as InformationIcon } from './Information'
export { default as InstagramIcon } from './Instagram'
export { default as JudgeIcon } from './Judge'
export { default as JudicialBranchIcon } from './JudicialBranch'
export { default as JusbrasilIcon } from './Jusbrasil'
export { default as LaptopIcon } from './Laptop'
export { default as LawyerDirectoryIcon } from './LawyerDirectory'
export { default as LightbulbIcon } from './Lightbulb'
export { default as LightModeIcon } from './LightMode'
export { default as LikeIcon } from './Like'
export { default as LikeActiveIcon } from './LikeActive'
export { default as LinkIcon } from './Link'
export { default as LinkedinIcon } from './Linkedin'
export { default as ListOrderedIcon } from './ListOrdered'
export { default as ListUnorderedIcon } from './ListUnordered'
export { default as LocationIcon } from './Location'
export { default as LockIcon } from './Lock'
export { default as LogoutIcon } from './Logout'
export { default as MenuIcon } from './Menu'
export { default as MessageIcon } from './Message'
export { default as MicIcon } from './Mic'
export { default as MicOffIcon } from './MicOff'
export { default as MoneyIcon } from './Money'
export { default as MoreHorizontalconIcon } from './MoreHorizontalcon'
export { default as MoreVerticalIcon } from './MoreVertical'
export { default as NewDocumentIcon } from './NewDocument'
export { default as NewsIcon } from './News'
export { default as NewspaperIcon } from './Newspaper'
export { default as NewTabIcon } from './NewTab'
export { default as NoteIcon } from './Note'
export { default as NoteMultipleIcon } from './NoteMultiple'
export { default as NotificationIcon } from './Notification'
export { default as NotificationOffIcon } from './NotificationOff'
export { default as NotificationOnIcon } from './NotificationOn'
export { default as OpenSidebarIcon } from './OpenSidebar'
export { default as PauseIcon } from './Pause'
export { default as PdfIcon } from './Pdf'
export { default as PhoneIcon } from './Phone'
export { default as PixIcon } from './Pix'
export { default as PlaceholderIcon } from './Placeholder'
export { default as PlayIcon } from './Play'
export { default as PrintIcon } from './Print'
export { default as PushpinIcon } from './Pushpin'
export { default as PuzzleIcon } from './Puzzle'
export { default as QrCodeIcon } from './QrCode'
export { default as QuestionIcon } from './Question'
export { default as QuestionAnswerIcon } from './QuestionAnswer'
export { default as QuotesIcon } from './Quotes'
export { default as RedoIcon } from './Redo'
export { default as RefreshIcon } from './Refresh'
export { default as RepeatIcon } from './Repeat'
export { default as ReplyIcon } from './Reply'
export { default as ReplyAllIcon } from './ReplyAll'
export { default as ReportIcon } from './Report'
export { default as ReportErrorIcon } from './ReportError'
export { default as RssIcon } from './Rss'
export { default as ScalesIcon } from './Scales'
export { default as SearchIcon } from './Search'
export { default as SearchBookIcon } from './SearchBook'
export { default as SendIcon } from './Send'
export { default as SettingsIcon } from './Settings'
export { default as ShareIcon } from './Share'
export { default as ShieldIcon } from './Shield'
export { default as SmartphoneIcon } from './Smartphone'
export { default as SortIcon } from './Sort'
export { default as SortAscIcon } from './SortAsc'
export { default as SortDescIcon } from './SortDesc'
export { default as StarIcon } from './Star'
export { default as StarActiveIcon } from './StarActive'
export { default as StarHalfIcon } from './StarHalf'
export { default as StatisticIcon } from './Statistic'
export { default as StatusCompleteIcon } from './StatusComplete'
export { default as StatusIncompleteIcon } from './StatusIncomplete'
export { default as StatusPartiallyIcon } from './StatusPartially'
export { default as StopIcon } from './Stop'
export { default as SubtractIcon } from './Subtract'
export { default as SubtractRoundIcon } from './SubtractRound'
export { default as TabletIcon } from './Tablet'
export { default as TagIcon } from './Tag'
export { default as TagAddIcon } from './TagAdd'
export { default as TagMultiIcon } from './TagMulti'
export { default as TimerIcon } from './Timer'
export { default as UndoIcon } from './Undo'
export { default as UnlockIcon } from './Unlock'
export { default as UploadIcon } from './Upload'
export { default as UserIcon } from './User'
export { default as UserAddIcon } from './UserAdd'
export { default as UserHistoryIcon } from './UserHistory'
export { default as UserInstitutionalIcon } from './UserInstitutional'
export { default as UserMultiIcon } from './UserMulti'
export { default as UserVoiceIcon } from './UserVoice'
export { default as VerifiedIcon } from './Verified'
export { default as VideoIcon } from './Video'
export { default as VolumeDownIcon } from './VolumeDown'
export { default as VolumeMuteIcon } from './VolumeMute'
export { default as VolumeUpIcon } from './VolumeUp'
export { default as WarningIcon } from './Warning'
export { default as WhatsappIcon } from './Whatsapp'
export { default as WordIcon } from './Word'
export { default as WriteIcon } from './Write'
export { default as XIcon } from './X'
export { default as YoutubeIcon } from './Youtube'

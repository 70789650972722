import { Children, cloneElement, Fragment, isValidElement, ReactElement, ReactNode } from 'react'

import { BookCover, BookCoverProps } from '../book-cover/book-cover'
import { Link, LinkProps } from '../link/link'
import { Slottable } from '../utilities'

export function renderTitleChildren<P extends { asChild?: boolean; children?: ReactNode }>(
  props: P
): ReactNode {
  const { asChild, children } = props

  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return cloneElement(children, {}, renderTitleChildren(children.props))
  }

  if (isValidElement<LinkProps>(children) && children.type === Link) {
    return cloneElement(children, { type: 'primary' })
  }

  return children
}

export function renderCoverChildren<P extends { asChild?: boolean; children?: ReactNode }>(
  props: P,
  overrideProps?: Partial<BookCoverProps>
): ReactNode {
  const { asChild, children } = props
  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return (
      <Slottable>
        {cloneElement(children, {}, renderCoverChildren(children.props, overrideProps))}
      </Slottable>
    )
  }

  if (isValidElement(children)) {
    switch (children.type) {
      case BookCover: {
        return cloneElement(children as ReactElement<BookCoverProps>, overrideProps)
      }
    }
  }

  return children
}

export function renderCaptionItemChildren<P extends { children?: ReactNode }>(props: P): ReactNode {
  const { children } = props

  if (isValidElement<LinkProps>(children)) {
    switch (children.type) {
      case Link: {
        return cloneElement(children, { type: 'tertiary' })
      }
      case Fragment: {
        return cloneElement(children, {
          children: Children.map(children.props.children, (child) => {
            return renderCaptionItemChildren({ children: child })
          }),
        })
      }
    }
  }

  return children
}

function _joinCaptionChildren(children: ReactNode, Separator: React.ComponentType): ReactNode[] {
  return Children.toArray(children).reduce<ReactNode[]>((acc, child, index) => {
    if (index === 0) return [child]
    return [...acc, <Separator key={`separator-${index}`} />, child]
  }, [])
}
export function renderCaptionChildren<P extends { asChild?: boolean; children?: ReactNode }>(
  props: P,
  Separator: React.ComponentType
): ReactNode {
  const { asChild, children } = props
  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return (
      <Slottable>
        {cloneElement(children, {}, _joinCaptionChildren(children.props.children, Separator))}
      </Slottable>
    )
  }
  return _joinCaptionChildren(children, Separator)
}

import classNames from 'classnames'
import { forwardRef, LiHTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './autocomplete-item.module.scss'

export type AutocompleteItemElement = HTMLLIElement
export type AutocompleteItemProps = Omit<LiHTMLAttributes<AutocompleteItemElement>, 'onSelect'> & {
  children: ReactNode
  history?: boolean
  active?: boolean
  asChild?: boolean
}

const AutocompleteItem = forwardRef<AutocompleteItemElement, AutocompleteItemProps>(
  function AutocompleteItem(props, forwardedRef) {
    const { className, active, children, history, asChild, ...rest } = props

    const classes = classNames(
      styles.root,
      {
        [styles.active]: active,
        [styles.history]: history,
      },
      className
    )

    const Component = asChild ? Slot : 'li'

    return (
      <Component {...rest} role="option" className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default AutocompleteItem

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgIaSkillDocDrafting(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4a1 1 0 0 1 1 1v2a1 1 0 1 0 2 0V5a3 3 0 0 0-3-3H8.828a3 3 0 0 0-2.12.879l-3.83 3.828A3 3 0 0 0 2 8.828V19a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1v-9h4a2 2 0 0 0 2-2V4h5Zm-7 .414L4.414 8H8V4.414Zm4.261 10.79.35-1.066a.825.825 0 0 1 .527-.527l1.066-.35a.275.275 0 0 0 0-.522l-1.066-.35a.825.825 0 0 1-.527-.527l-.35-1.066a.275.275 0 0 0-.522 0l-.35 1.066a.825.825 0 0 1-.527.527l-1.066.35a.275.275 0 0 0 0 .522l1.066.35c.25.082.445.277.527.527l.35 1.066c.082.252.44.252.522 0Zm2.59 1.22c.22-.132.422-.29.602-.47l.803-1.37.366-1.298c.108-.381.648-.381.756 0l.366 1.299a3.148 3.148 0 0 0 1.703 1.997c.15.07.306.128.468.174l1.299.366c.381.108.381.648 0 .756l-1.299.366a3.148 3.148 0 0 0-1.997 1.703c-.07.15-.128.306-.174.468l-.366 1.299c-.108.381-.648.381-.756 0l-.366-1.299a3.148 3.148 0 0 0-1.703-1.997 3.148 3.148 0 0 0-.468-.174l-1.299-.366c-.381-.108-.381-.648 0-.756l1.299-.366a3.148 3.148 0 0 0 .767-.332Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgIaSkillDocDrafting)

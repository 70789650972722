import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import {
  ButtonHTMLAttributes,
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
  KeyboardEvent as RKeyboardEvent,
  MouseEvent as RMouseEvent,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { useFormControl } from '../form-control/use-form-control'
import { CloseIcon, IconSize, SearchIcon } from '../icon/icon'
import { Spinner } from '../spinner/spinner'
import { ensurePropOf, useKeyPress } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { SearchAddonContext } from './context'
import styles from './search.module.scss'
import SearchFilter, { SearchFilterProps } from './search-filter'
import SearchIconButton from './search-icon-button'

export type SearchElement = HTMLInputElement
export const SearchTypes = ['primary', 'secondary'] as const
export type SearchType = (typeof SearchTypes)[number]
export const SearchSizes = ['md', 'lg'] as const
export type SearchSize = (typeof SearchSizes)[number]

export type SearchImperativeRef = {
  clear: (opts?: { autoFocus?: boolean }) => void
}

export interface SearchProps extends Omit<InputHTMLAttributes<SearchElement>, 'size'> {
  type?: SearchType
  size?: SearchSize
  disabled?: boolean
  loading?: boolean
  className?: string
  containerClassName?: string
  iconsClassName?: string
  onSearch?: (
    evt:
      | RMouseEvent<HTMLButtonElement, MouseEvent>
      | RKeyboardEvent<HTMLButtonElement>
      | KeyboardEvent,
    value: string
  ) => void
  onValueChange?: (evt: ChangeEvent<HTMLInputElement>, value: string) => void
  onValueClear?: (
    evt: RMouseEvent<HTMLButtonElement, MouseEvent> | RKeyboardEvent<HTMLButtonElement>
  ) => void
  clearButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
  searchButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
  __trailing?: ReactNode
  /**
   * [TODO] In future, refactor SearchElement (`ref` prop) to be the imperative one,
   * exposing the "input" DOM element and remove the imperativeRef prop.
   * This will be a breaking change.
   * @andersonba
   */
  imperativeRef?: React.Ref<SearchImperativeRef>
}

const iconSizeMapping: Record<SearchSize, IconSize> = {
  md: 'sm',
  lg: 'md',
}

const SearchRoot = forwardRef<SearchElement, SearchProps>(function Search(props, forwardedRef) {
  const {
    className,
    containerClassName,
    iconsClassName,
    type: typeProp,
    size: sizeProp,
    onValueChange,
    onValueClear,
    onSearch,
    clearButtonProps,
    searchButtonProps,
    loading: loadingProp = false,
    disabled: disabledProp = false,
    __trailing: trailing,
    children,
    role,
    'aria-expanded': ariaExpanded,
    imperativeRef,
    ...rest
  } = props

  const [loading, setLoading] = useState<boolean>(loadingProp)
  const [disabled, setDisabled] = useState<boolean>(disabledProp)
  const [clearable, setClearable] = useState<boolean>(!!(rest.value ?? rest.defaultValue))

  useEffect(() => setLoading(loadingProp), [loadingProp])
  useEffect(() => setDisabled(disabledProp), [disabledProp])

  const containerRef = useRef<HTMLSpanElement>(null)
  const focusRef = useRef<boolean>(false)
  const { ref: inputRef } = useKeyPress<SearchElement>(['Enter'], (evt) => {
    switch (evt.key) {
      case 'Enter':
        onSearch?.(evt, inputRef.current?.value ?? '')
        break
    }
  })

  const refs = useComposedRefs(forwardedRef, inputRef)

  const type = ensurePropOf<SearchType>(SearchTypes, typeProp, 'primary')
  const size = ensurePropOf<SearchSize>(SearchSizes, sizeProp, 'md')

  const iconSize: IconSize = iconSizeMapping[size]
  const filterable = isValidElement<SearchFilterProps>(children) && children.type === SearchFilter

  const formControlClass = classNames(styles.formControl, {
    [styles.formControlLg]: size === 'lg' && type === 'primary',
  })
  const formControl = useFormControl({
    labelProps: { className: formControlClass },
    descriptionProps: { className: formControlClass },
    errorMessageProps: { className: formControlClass },
  })

  const containerClasses = classNames(
    styles.container,
    {
      [styles[`type${type}`]]: type,
      [styles[`size${size}`]]: size,
      [styles.clearable]: clearable,
      [styles.disabled]: disabled,
      [styles.containerFocus]: focusRef.current,
    },
    containerClassName
  )
  const inputClasses = classNames(styles.input, className)
  const iconsClasses = classNames(styles.icons, iconsClassName)

  function handleInput(value: string) {
    setClearable(value !== '')
  }

  function setFocus(state: boolean) {
    focusRef.current = state
    if (state) containerRef.current?.classList.add(styles.containerFocus)
    else containerRef.current?.classList.remove(styles.containerFocus)
  }

  const clear: SearchImperativeRef['clear'] = (opts = {}) => {
    const { autoFocus = true } = opts
    if (inputRef.current) {
      inputRef.current.value = ''
      if (autoFocus) {
        inputRef.current.focus()
      }
      // emit change event with empty value
      onValueChange?.(new CustomEvent('change') as unknown as ChangeEvent<HTMLInputElement>, '')
    }
    setClearable(false)
  }

  useImperativeHandle(imperativeRef, () => ({
    clear,
  }))

  return (
    <span className={containerClasses} ref={containerRef} role={role} aria-expanded={ariaExpanded}>
      {filterable ? (
        <SearchAddonContext.Provider value={{ size, disabled }}>
          <span className={styles.filter}>{children}</span>
        </SearchAddonContext.Provider>
      ) : null}

      <input
        disabled={disabled}
        data-testid="search-input"
        {...formControl.getFieldProps?.()}
        {...rest}
        onChange={(evt) => {
          handleInput(evt.target.value)
          onValueChange?.(evt, evt.target.value)
        }}
        onFocus={(evt) => {
          setFocus(true)
          rest.onFocus?.(evt)
        }}
        onBlur={(evt) => {
          setFocus(false)
          rest.onBlur?.(evt)
        }}
        className={inputClasses}
        enterKeyHint="search"
        type="search"
        ref={refs}
      />

      <span className={iconsClasses}>
        {clearable && (
          <SearchIconButton
            type="button"
            onClick={(evt) => {
              evt.preventDefault()
              clear()
              onValueClear?.(evt)
            }}
            disabled={disabled}
            aria-label="limpar campo"
            data-testid="search-clear-input"
            {...clearButtonProps}
          >
            <CloseIcon size={iconSize} />
          </SearchIconButton>
        )}

        {!loading && (
          <SearchIconButton
            disabled={disabled}
            aria-label="pesquisar"
            onClick={(evt) => {
              onSearch?.(evt, inputRef.current?.value ?? '')
            }}
            data-testid="search-submit-input"
            {...searchButtonProps}
          >
            <SearchIcon size={iconSize} />
          </SearchIconButton>
        )}

        {loading && (
          <span className={styles.spinner}>
            <Spinner size={iconSize} />
          </span>
        )}
      </span>

      {trailing}
    </span>
  )
})

export const Search = assignSubComponents('Search', SearchRoot, {
  Filter: SearchFilter,
})

export * from './search-filter'

import { LawsuitProgressStatus } from './enum'

const MAX_DAYS_SINCE_LAWSUIT_UPDATE = 90
const MAX_DAYS_SINCE_LAWSUIT_LAST_ACTIVITY = 180
const MAX_DAYS_SINCE_LAWSUIT_DISTRIBUTION = 1825
const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24

export const CLOSED_VALUES = [
  'arquivado',
  'arquivado definitivamente',
  'encerrado',
  'extinto',
  'archived',
  'definitive_archived',
]

export const getLawsuitProgressStatus = (
  lawsuitStatus?: string | null,
  lastUpdate?: number | null,
  lastActivityDate?: number | null,
  distributionDate?: number | null
): LawsuitProgressStatus | null => {
  const status = lawsuitStatus?.toLowerCase() || ''
  if (CLOSED_VALUES.includes(status)) return LawsuitProgressStatus.CLOSED

  if (!lastUpdate || !lastActivityDate || !distributionDate) return null

  const todayDate = Date.now()
  const timePassedSinceUpdate = todayDate - lastUpdate
  const timePassedSinceLastActivity = todayDate - lastActivityDate
  const timePassedSinceDistribution = todayDate - distributionDate
  const daysSinceUpdate = Math.floor(timePassedSinceUpdate / ONE_DAY_IN_MILLISECONDS)
  const daysSinceLastActivity = Math.floor(timePassedSinceLastActivity / ONE_DAY_IN_MILLISECONDS)
  const daysSinceDistribution = Math.floor(timePassedSinceDistribution / ONE_DAY_IN_MILLISECONDS)

  if (
    daysSinceUpdate < MAX_DAYS_SINCE_LAWSUIT_UPDATE &&
    daysSinceLastActivity <= MAX_DAYS_SINCE_LAWSUIT_LAST_ACTIVITY &&
    daysSinceDistribution < MAX_DAYS_SINCE_LAWSUIT_DISTRIBUTION
  ) {
    return LawsuitProgressStatus.IN_PROGRESS
  }

  return null
}

import { ReactNode } from 'react'

export const experimentalConfig = {
  theme: { values: Boolean },
} as const satisfies Config

export const defaultExperimentalFlags = {} as const satisfies ExperimentalConfigMap

/* ----------------------------------- */

/* DO NOT EDIT BELOW THIS LINE */
/* useful typings */
type Config = Record<string, { values: BooleanConstructor | string[] }>
type ConfigType = typeof experimentalConfig
export type ExperimentalConfigMap = Partial<{
  [K in ExperimentFlag]: ConfigType[K]['values'] extends Array<infer T> ? T : boolean
}>
export type ExperimentFlag = keyof ConfigType
export type ExperimentalProviderProps = {
  children: ReactNode
  flags?: ExperimentalConfigMap
}
export type ExperimentalContextType = {
  flags: ExperimentalConfigMap
}

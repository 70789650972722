import { gql, useLazyQuery, useMutation } from '@apollo/client'

import {
  CreateSummatizationMutation,
  GetSummatizationQuery,
} from './__generated__/summarizationQueries.graphql'

export const summarizationMutation = gql`
  mutation CreateSummatization($lawsuitId: String!, $court: String!) {
    createLawsuitSummarization(input: { lawsuitId: $lawsuitId, court: $court }) {
      summarization {
        summarization
        createdAt
      }
    }
  }
`

export const summarizationQuery = gql`
  query GetSummatization($lawsuitId: String!) {
    root {
      crmLawsuit(lawsuitId: $lawsuitId) {
        summarization {
          summarization
        }
      }
    }
  }
`

interface UseSummarizationMutationProps {
  lawsuitId: string | null
  courtName?: string | null
}

interface UseSummarizationQueryProps {
  lawsuitId: string | null
}

export function useSummarizationMutation(props: UseSummarizationMutationProps) {
  const variables = {
    lawsuitId: props.lawsuitId,
    court: props.courtName,
  }

  const [fetchData, _] = useMutation<CreateSummatizationMutation>(summarizationMutation, {
    variables,
  })
  return fetchData
}

export function useSummarizationQuery(props: UseSummarizationQueryProps) {
  const variables = {
    lawsuitId: props.lawsuitId,
  }
  const [fetchData, _] = useLazyQuery<GetSummatizationQuery>(summarizationQuery, { variables })
  return fetchData
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgScales(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3a1 1 0 1 0-2 0v1H5a1 1 0 0 0-.968.748l-.08.281a319.52 319.52 0 0 0-.917 3.303c-.25.924-.503 1.878-.694 2.652-.094.385-.176.734-.234 1.014-.05.24-.107.536-.107.752V13a3 3 0 1 0 6 0v-.25c0-.216-.057-.512-.107-.752-.058-.28-.14-.629-.234-1.014-.19-.774-.443-1.728-.694-2.652A296.02 296.02 0 0 0 6.322 6H11v14h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1V6h4.678c-.181.649-.412 1.48-.643 2.332-.25.924-.503 1.878-.694 2.652-.095.385-.176.734-.234 1.014-.05.24-.107.536-.107.752V13a3 3 0 1 0 6 0v-.25c0-.216-.057-.512-.107-.752-.058-.28-.14-.629-.235-1.014a123.92 123.92 0 0 0-.693-2.652 298.387 298.387 0 0 0-.917-3.303l-.08-.281A1 1 0 0 0 19 4h-6V3ZM5 8.727l-.035.128c-.25.92-.497 1.856-.681 2.607-.093.376-.168.697-.219.943a6.263 6.263 0 0 0-.06.328C4 12.756 4 12.76 4 12.75V13a1 1 0 1 0 2 0v-.25c0 .01 0 .006-.004-.017a6.28 6.28 0 0 0-.061-.328 28.034 28.034 0 0 0-.219-.943 122.07 122.07 0 0 0-.681-2.607L5 8.727Zm14 0-.035.128c-.25.92-.497 1.856-.681 2.607-.093.376-.168.697-.219.943a6.283 6.283 0 0 0-.065.345V13a1 1 0 1 0 2 0v-.25c0 .01 0 .006-.004-.017a6.267 6.267 0 0 0-.061-.328 28.162 28.162 0 0 0-.218-.943c-.185-.75-.433-1.687-.682-2.607L19 8.727Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgScales)

import { isValidElement, ReactNode, useEffect, useRef } from 'react'

import TreeViewItem, { TreeViewItemProps } from '../tree-view-item'

type NodeData = Record<string, unknown>
export type UseTreeDataProps = {
  children: ReactNode
}
export type TreeIndex<K extends NodeData> = Record<string, Node<K>>
export type TreeNode<K extends NodeData> = Node<K>
export type TreeInfo<K extends NodeData> = {
  node: Node<K>
  index: TreeIndex<K>
}

class Node<K extends NodeData> {
  public id: string | null
  public parent: Node<K> | null
  public children: Node<K>[]
  public data: K = {} as K

  constructor(id: string | null = null, parent: Node<K> | null = null, children: Node<K>[] = []) {
    this.id = id
    this.parent = parent
    this.children = children
  }
}

function registerNode<K extends NodeData>(info: TreeInfo<K>, children: ReactNode) {
  const { node: parent, index } = info
  if (children instanceof Array) {
    children.forEach((child) => registerNode(info, child))
  } else if (isValidElement<TreeViewItemProps>(children) && children.type === TreeViewItem) {
    const { nodeId } = children.props
    const node = new Node(nodeId, parent)
    parent.children.push(node)
    index[nodeId] = node
    if (children.props.children) {
      registerNode({ ...info, node }, children.props.children)
    }
  }
}

export function useTreeData<K extends NodeData>(props: UseTreeDataProps): TreeInfo<K> {
  const ref = useRef<TreeInfo<K>>({
    node: new Node(),
    index: {},
  })

  useEffect(() => {
    registerNode(ref.current, props.children)
  }, [props])

  return ref.current
}

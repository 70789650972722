import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPdf(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4a1 1 0 0 0-1 1v14c0 .35-.06.687-.17 1H19a1 1 0 0 0 1-1v-9h-3a3 3 0 0 1-3-3V4H9ZM5 20a1 1 0 0 0 1-1v-3H4v3a1 1 0 0 0 1 1Zm1-6H4a2 2 0 0 0-2 2v3a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V8.828a3 3 0 0 0-.879-2.12l-3.828-3.83A3 3 0 0 0 15.172 2H9a3 3 0 0 0-3 3v9Zm10-9.586L19.586 8H17a1 1 0 0 1-1-1V4.414Zm-1.798 6.279a1 1 0 0 0-1.95.252c-.061 1.107-.205 1.914-.5 2.704-.301.8-.778 1.636-1.573 2.78a1 1 0 0 0 1.392 1.392c1.157-.804 1.953-1.249 2.692-1.45.7-.19 1.429-.183 2.48.096a1 1 0 0 0 .809-1.801c-1.099-.726-1.756-1.234-2.23-1.786-.457-.532-.788-1.157-1.12-2.187Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPdf)

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4SerpResultSelectedEventProps {
  componentClass: string
  componentName: string
  resourceId?: string | null
  resultPosition?: number | null
  resultPage?: number | null
  selectedOption?: string | null
}

export class GA4SerpResultSelectedEvent extends GA4Event {
  ga4_component_name: string | null
  ga4_component_class: string | null
  ga4_resource_id: string | null
  ga4_selected_option: string | null
  ga4_result_position: number | null
  ga4_result_page: number | null

  constructor({
    componentClass,
    componentName,
    resourceId = null,
    resultPosition = null,
    resultPage = null,
    selectedOption = null,
  }: GA4SerpResultSelectedEventProps) {
    super({ event: EVENT.SERP_RESULT_SELECTED })

    this.ga4_component_class = componentClass
    this.ga4_component_name = componentName
    this.ga4_resource_id = resourceId
    this.ga4_result_position = resultPosition
    this.ga4_result_page = resultPage
    this.ga4_selected_option = selectedOption
  }
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { assignSubComponents } from '../utilities/internal'
import { FormControlContext } from './context'
import styles from './form-control.module.scss'
import FormControlDescription from './form-control-description'
import FormControlErrorMessage from './form-control-error-message'
import FormControlLabel from './form-control-label'
import { useFormControlProvider } from './use-form-control'

export type FormControlElement = HTMLDivElement

export interface FormControlProps extends HTMLAttributes<FormControlElement> {
  children: ReactNode
  id?: string
  required?: boolean
  error?: boolean
  disabled?: boolean
  readOnly?: boolean
  className?: string
}

const FormControlRoot = forwardRef<FormControlElement, FormControlProps>(function FormControl(
  props,
  forwardedRef
) {
  const { id, children, required, error, disabled, readOnly, className, ...rest } = props

  const context = useFormControlProvider({
    id,
    error,
    readOnly,
    required,
    disabled,
  })

  const classes = classNames(styles.root, className)

  return (
    <FormControlContext.Provider value={context}>
      <div {...rest} className={classes} ref={forwardedRef} role="group">
        {children}
      </div>
    </FormControlContext.Provider>
  )
})

export const FormControl = assignSubComponents('FormControl', FormControlRoot, {
  Label: FormControlLabel,
  Description: FormControlDescription,
  ErrorMessage: FormControlErrorMessage,
})

export * from './form-control-description'
export * from './form-control-error-message'
export * from './form-control-label'

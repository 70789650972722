import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgDelete(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1ZM3 7a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2h-1v11a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V8H4a1 1 0 0 1-1-1Zm4 1v11a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8h-2v9a1 1 0 1 1-2 0V8h-2v9a1 1 0 1 1-2 0V8H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgDelete)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSettings(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.999 6.804c1.128-.651 1.734-1.616 1.93-2.732a8.081 8.081 0 0 1 2.14 0c.192 1.113.794 2.079 1.924 2.727 1.127.648 2.267.698 3.337.309a8.077 8.077 0 0 1 1.072 1.856c-.873.732-1.399 1.745-1.402 3.044-.003 1.31.538 2.308 1.402 3.03a8.09 8.09 0 0 1-1.071 1.855c-1.064-.39-2.203-.349-3.332.303-1.132.654-1.733 1.62-1.927 2.734a8.088 8.088 0 0 1-2.143 0c-.192-1.11-.79-2.078-1.923-2.729-1.127-.646-2.267-.698-3.338-.308a8.082 8.082 0 0 1-1.071-1.856c.872-.733 1.398-1.745 1.4-3.044.004-1.306-.534-2.307-1.4-3.03A8.082 8.082 0 0 1 5.67 7.108c1.063.387 2.2.349 3.33-.304Zm3-4.803c-.761 0-1.505.085-2.22.248a1 1 0 0 0-.78.974c0 .864-.3 1.445-1 1.849-.699.403-1.35.373-2.103-.06a1 1 0 0 0-1.231.189 10.03 10.03 0 0 0-2.222 3.847A1 1 0 0 0 2.9 10.21c.75.434 1.1.978 1.099 1.778-.002.817-.355 1.373-1.1 1.803a1 1 0 0 0-.455 1.162A10.033 10.033 0 0 0 4.664 18.8a1 1 0 0 0 1.234.187c.745-.43 1.403-.458 2.112-.05.694.397.99.973.99 1.841a1 1 0 0 0 .779.975 10.024 10.024 0 0 0 4.443 0A1 1 0 0 0 15 20.78c.002-.869.3-1.448.999-1.852.7-.403 1.353-.373 2.102.06a1 1 0 0 0 1.234-.187 10.03 10.03 0 0 0 2.22-3.848 1 1 0 0 0-.452-1.16c-.754-.439-1.105-.98-1.103-1.78.002-.817.355-1.373 1.1-1.803a1 1 0 0 0 .456-1.162 10.034 10.034 0 0 0-2.222-3.847 1 1 0 0 0-1.233-.187c-.746.43-1.403.458-2.112.051-.694-.399-.992-.976-.99-1.838a1 1 0 0 0-.779-.978A10.088 10.088 0 0 0 12 2ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSettings)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSortAsc(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6.414V20a1 1 0 1 1-2 0V6.414l-1.293 1.293a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L19 6.414ZM3 20a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2H3Zm-1-8a1 1 0 0 0 1 1h9a1 1 0 1 0 0-2H3a1 1 0 0 0-1 1Zm1-6a1 1 0 1 1 0-2h7a1 1 0 1 1 0 2H3Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSortAsc)

import { forwardRef } from 'react'

import { type FlexElement, type FlexProps, Flex_unstable as Flex } from './flex'

export type SpacerElement = FlexElement
export type SpacerProps = Omit<FlexProps, 'flex'>

export const Spacer_unstable = forwardRef<SpacerElement, SpacerProps>(function Spacer(
  props,
  forwardedRef
) {
  return <Flex {...props} grow={1} ref={forwardedRef} />
})

import { spacing6xs } from '@farol-ds/tokens'
import * as RTooltip from '@radix-ui/react-tooltip'
import { cloneElement, forwardRef, isValidElement, ReactElement, ReactNode } from 'react'

import { PrimitiveText } from '../primitive-text/primitive-text'
import { ensurePropOf } from '../utilities'
import styles from './tooltip.module.scss'

export const TooltipPlacements = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
] as const
export type TooltipElement = HTMLDivElement
export type TooltipPlacement = (typeof TooltipPlacements)[number]

export interface TooltipProps {
  label: string
  children: ReactNode
  placement?: TooltipPlacement
  open?: boolean
}

export const Tooltip = forwardRef<TooltipElement, TooltipProps>(function Tooltip(
  props,
  forwadedRef
) {
  const { label, children, open = undefined, placement: placementProp, ...rest } = props

  const placement = ensurePropOf<TooltipPlacement>(TooltipPlacements, placementProp, 'top')

  const [side = 'top', align = 'center'] = placement.split('-') as [
    RTooltip.TooltipContentProps['side'],
    RTooltip.TooltipContentProps['align']
  ]

  const rootProps = open !== undefined ? { open } : {}

  return (
    <RTooltip.Root {...rootProps}>
      <RTooltip.Trigger asChild>
        {isValidElement(children) ? cloneElement(children as ReactElement, rest) : children}
      </RTooltip.Trigger>

      <RTooltip.Portal>
        <RTooltip.Content
          side={side}
          align={align}
          sideOffset={spacing6xs}
          className={styles.root}
          ref={forwadedRef}
        >
          <PrimitiveText className={styles.label}>{label}</PrimitiveText>
        </RTooltip.Content>
      </RTooltip.Portal>
    </RTooltip.Root>
  )
})

export * from './tooltip-provider'

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './menu-item-help-text.module.scss'

export type MenuItemHelpTextElement = HTMLDivElement

export interface MenuItemHelpTextProps extends HTMLAttributes<MenuItemHelpTextElement> {
  children: ReactNode
}

const MenuItemHelpText = forwardRef<MenuItemHelpTextElement, MenuItemHelpTextProps>(
  function MenuItemHelpText(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)
    return (
      <div {...rest} data-fds-menu-item-help-text className={classes} ref={forwardedRef}>
        {children}
      </div>
    )
  }
)

export default MenuItemHelpText

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './modal-body.module.scss'

export type ModalBodyElement = HTMLDivElement

export interface ModalBodyProps extends HTMLAttributes<ModalBodyElement> {
  disablePadding?: boolean
  children: ReactNode
}

const ModalBody = forwardRef<ModalBodyElement, ModalBodyProps>(function ModalBody(
  props,
  forwardedRef
) {
  const { className, children, disablePadding, ...rest } = props
  const classes = classNames(
    styles.root,
    {
      [styles.disablePadding]: disablePadding,
    },
    className
  )
  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      {children}
    </div>
  )
})

export default ModalBody

interface IGA4Event {
  event: string
}

export class GA4Event {
  event: string

  constructor({ event }: IGA4Event) {
    this.event = event
  }
}

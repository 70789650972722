import { ReactNode } from 'react'

import { UserSessionContext } from '../context/context'
import { getUserSession } from '../get-user-session/get-user-session'
import { WithUserSessionReturn } from '../types'

export interface UserSessionProviderProps {
  children: ReactNode
  pageProps: WithUserSessionReturn
}

// TODO: remove Provider when AppRootProvider replaces it completely. @felipemsantana
export function UserSessionProvider(props: UserSessionProviderProps) {
  const { pageProps, children } = props
  const value = getUserSession(pageProps)
  return <UserSessionContext.Provider value={value}>{children}</UserSessionContext.Provider>
}

import { ComponentType, Context } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Component = ComponentType<any> | Context<any>

export function assignSubComponents<K extends Component, Y extends Record<string, Component>>(
  name: string,
  root: K,
  mapping: Y
) {
  const output = Object.assign(root, mapping)
  Object.keys(mapping).forEach((key) => {
    output[key].displayName = `${name}.${key}`
  })
  root.displayName = name
  return output
}

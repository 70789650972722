import { createContext, useContext } from 'react'

import { SelectionCardOrientation } from './types'

export type SelectionCardContextValue = {
  orientation: SelectionCardOrientation
  disabled?: boolean
}

export const SelectionCardContext = createContext<SelectionCardContextValue>({
  orientation: 'horizontal',
})

export const useSelectionCardContext = () => useContext(SelectionCardContext)

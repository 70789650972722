import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, useContext, useEffect, useRef, useState } from 'react'

import { Dropdown } from '../dropdown/dropdown'
import { ChevronDownIcon } from '../icon/icon'
import { MenuItem } from '../menu-item/menu-item'
import { Separator } from '../separator/separator'
import { ensurePropOf } from '../utilities'
import { SearchAddonContext } from './context'
import styles from './search-filter.module.scss'

export const SearchFilterSizes = ['md', 'lg'] as const
export type SearchFilterSize = (typeof SearchFilterSizes)[number]

export type SearchFilterElement = HTMLButtonElement

export type SearchFilterOption = {
  label?: string
  value: string
}

export type SearchFilterProps = ButtonHTMLAttributes<SearchFilterElement> & {
  placeholder?: string
  defaultValue?: string
  options: SearchFilterOption[]
  onValueChange?: (value: string) => void
  onOptionSelect?: (option: SearchFilterOption) => void
  size?: 'md' | 'lg'
  disabled?: boolean
}

const SearchFilter = forwardRef<SearchFilterElement, SearchFilterProps>(function SearchFilter(
  props,
  forwardedRef
) {
  const {
    placeholder,
    defaultValue,
    options,
    onValueChange,
    onOptionSelect,
    className,
    size: sizeProp,
    disabled: disabledProp,
    ...rest
  } = props

  const ctx = useContext(SearchAddonContext)
  const size = sizeProp
    ? ensurePropOf<SearchFilterSize>(SearchFilterSizes, sizeProp, 'md')
    : ctx.size
  const disabled = disabledProp ?? ctx.disabled

  const [value, setValue] = useState<string | null>(defaultValue ?? null)
  const prevValueRef = useRef(defaultValue)

  const currentOption = options.find((option) => option.value === value)

  useEffect(() => {
    if (value === null) return
    if (value !== prevValueRef.current) {
      onValueChange?.(value)
      prevValueRef.current = value
    }
  }, [value, onValueChange])

  if (options.length === 0) return null

  const classes = classNames(
    styles.root,
    {
      [styles.rootLg]: size === 'lg',
    },
    className
  )

  return (
    <>
      <Dropdown>
        <Dropdown.Trigger asChild>
          <button {...rest} disabled={disabled} className={classes} ref={forwardedRef}>
            {currentOption?.label || currentOption?.value || placeholder}
            <ChevronDownIcon size={size === 'md' ? 'xs' : 'sm'} className={styles.icon} />
          </button>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          {options.map((option) => (
            <Dropdown.MenuItem
              key={option.value}
              onSelect={() => {
                setValue(option.value)
                onOptionSelect?.(option)
              }}
            >
              <MenuItem>
                <MenuItem.Content>{option.label || option.value}</MenuItem.Content>
              </MenuItem>
            </Dropdown.MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Separator
        orientation="vertical"
        className={classNames(styles.separator, {
          [styles.separatorLg]: size === 'lg',
        })}
      />
    </>
  )
})

export default SearchFilter

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgMessage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H5a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v1.586l2.293-2.293A1 1 0 0 1 11 16h8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM5 2h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-7.586l-3.707 3.707A1 1 0 0 1 6 21v-3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3Zm3 6a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H9Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgMessage)

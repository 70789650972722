import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, ReactNode } from 'react'

import { IconProps, isValidIconElement } from '../icon/icon'
import styles from './menu-item-trailing.module.scss'

export type MenuItemTrailingElement = HTMLSpanElement

export interface MenuItemTrailingProps extends HTMLAttributes<MenuItemTrailingElement> {
  children: ReactNode
}

const iconProps: Partial<IconProps> = {
  size: 'sm',
  className: styles.icon,
}

const MenuItemTrailing = forwardRef<MenuItemTrailingElement, MenuItemTrailingProps>(
  function MenuItemTrailing(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)

    const isIcon = isValidIconElement(children)

    return (
      <span {...rest} className={classes} ref={forwardedRef}>
        {isIcon ? cloneElement(children, iconProps) : children}
      </span>
    )
  }
)

export default MenuItemTrailing

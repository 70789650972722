import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgReport(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5a1 1 0 0 1 1-1h5v4a2 2 0 0 0 2 2h4v9a1 1 0 0 1-1 1H7.83c.11-.313.17-.65.17-1V5Zm-2 9V5a3 3 0 0 1 3-3h6.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 22 8.828V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-3a2 2 0 0 1 2-2h2Zm0 2H4v3a1 1 0 1 0 2 0v-3Zm10-8V4.414L19.586 8H16Zm-6 10a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2Zm4 1a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1Zm2-1a1 1 0 1 0 2 0v-4a1 1 0 1 0-2 0v4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgReport)

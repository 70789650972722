import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { IS_ALLOWED_ENVIRONMENT, IS_SILENCED } from './constants'
import { AppRootContext } from './context'
import { AppRootContextLoaded, AppRootProviderProps, PageProps } from './types'

export function AppRootProvider<P extends PageProps>(props: AppRootProviderProps<P>) {
  const router = useRouter()
  const context = useContext(AppRootContext)

  if (context.loaded && IS_ALLOWED_ENVIRONMENT) {
    throw new Error(
      '[shared-app-root-provider] development only error: only one provider must be loaded'
    )
  }

  const { pageProps, children } = props
  const isNextJsErrorPage = router.pathname === '/_error'
  const enableFieldValidation = !IS_SILENCED && !isNextJsErrorPage && IS_ALLOWED_ENVIRONMENT

  const [pagePropsCached, setPagePropsCached] = useState<P>(pageProps)
  useEffect(() => {
    if (JSON.stringify(pageProps) !== JSON.stringify(pagePropsCached)) {
      setPagePropsCached(pageProps)
    }
  }, [pageProps, pagePropsCached])

  const getPageProp: AppRootContextLoaded<P>['getPageProp'] = useCallback(
    (key) => {
      if (enableFieldValidation && !(key in pagePropsCached)) {
        console.warn(
          `[shared-app-root-provider] development only warning: pageProps should have the key "${key}"`
        )
      }
      return pagePropsCached[key]
    },
    [enableFieldValidation, pagePropsCached]
  )

  const getPagePropRequired: AppRootContextLoaded<P>['getPagePropRequired'] = useCallback(
    (key) => {
      if (enableFieldValidation && !(key in pagePropsCached)) {
        throw new Error(
          `[shared-app-root-provider] development only error: pageProps must have the key "${key}"`
        )
      }
      return pagePropsCached[key]
    },
    [enableFieldValidation, pagePropsCached]
  )

  const value: AppRootContextLoaded<P> = useMemo(
    () => ({ loaded: true, getPageProp, getPagePropRequired }),
    [getPageProp, getPagePropRequired]
  )

  return <AppRootContext.Provider value={value}>{children}</AppRootContext.Provider>
}

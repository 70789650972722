import Snowpiercer from '@jusbrasil/snowpiercer'

// https://github.com/jusbrasil/ui-topbar/blob/1e37799e4fe55baf2946057b9ee9c5d1f1656d7f/src/tags/autocomplete/jusbrasil.js#L24
const HISTORY_STORAGE_KEY = 'jusbrasil/queries-history'
// https://github.com/jusbrasil/ui-topbar/blob/1e37799e4fe55baf2946057b9ee9c5d1f1656d7f/src/tags/autocomplete/index.js#L384
const HISTORY_MAX_ITEMS = 12

export interface StorageOptions {
  key?: string
  maxItems?: number
}

export abstract class HistoryStorageInterface {
  abstract getItem(key: string): Promise<string | null>
  abstract setItem(key: string, value: string): Promise<void>
  abstract onReady(): Promise<void>
}

export class SnowpiercerStorage extends Snowpiercer implements HistoryStorageInterface {
  public async onReady() {
    await this.iframePromise
  }
}

export class HistoryStorage<
  StorageClass extends HistoryStorageInterface,
  StorageItem extends Record<string, string>,
  Item extends { id: number; text: string; raw: string }
> {
  private _storage: StorageClass | undefined
  private memory: Record<string, Item> = {}
  private key: string
  private maxItems: number
  public available = false
  static readonly target = 'suggestion'
  static readonly previousTarget = 'titulo'

  constructor(storage: StorageClass, opts: StorageOptions = {}) {
    const { key = HISTORY_STORAGE_KEY, maxItems = HISTORY_MAX_ITEMS } = opts
    this.key = key
    this.maxItems = maxItems
    if (typeof window === 'undefined') return
    try {
      this._storage = storage
      this._storage.onReady().finally(() => {
        this.available = true
      })
    } catch (err) {
      console.error('Error initializing the storage for history', err)
    }
  }

  private get storage() {
    if (!this.available) return
    return this._storage
  }

  private async fetch(): Promise<Item[]> {
    const items: StorageItem[] | undefined = await this.storage
      ?.getItem(this.key)
      .then((raw) => JSON.parse(raw ?? '[]'))
      .catch(() => [])

    if (!items) return []

    return items
      .filter((sItem) => !!sItem[HistoryStorage.target] || !!sItem[HistoryStorage.previousTarget])
      .map((sItem, idx) => {
        const originalText = sItem[HistoryStorage.target] || sItem[HistoryStorage.previousTarget]
        const item = {
          id: idx,
          raw: originalText,
          text: this.stripHTML(originalText),
        } as Item
        this.memory[originalText] = item
        return item
      })
  }

  public async onReady() {
    if (this.available) return
    await this._storage?.onReady()
  }

  public async all(): Promise<Item[]> {
    if (Object.keys(this.memory).length === 0) {
      return this.fetch()
    }
    return Object.values(this.memory)
  }

  public async save(items: Item[]): Promise<void> {
    this.memory = items.reduce((acc, item) => {
      acc[item.raw] = item
      return acc
    }, {} as Record<string, Item>)
    return this.storage?.setItem(
      this.key,
      JSON.stringify(items.slice(0, this.maxItems).map(this.sanitize))
    )
  }

  public async remove(item: Item): Promise<Item[]> {
    const items = await this.all()
    const filtered = items.filter((t) => t.id !== item.id)
    await this.save(filtered)
    return filtered
  }

  public async putTerm(term: string): Promise<Item[]> {
    const items = await this.all()
    const nextId = items.length + 1
    const currIdx = items.findIndex((t) => t.raw === term || t.text === term)
    if (currIdx > -1) {
      items.splice(currIdx, 1)
    }
    const item = {
      id: nextId,
      raw: term,
      text: this.stripHTML(term),
    } as Item
    const newTerms = [...items, item]
    await this.save(newTerms)
    return newTerms
  }

  public getByTerm(term: string): Item | undefined {
    return this.memory[term]
  }

  private sanitize(item: Item): StorageItem {
    return {
      'id-perfil': null,
      'id-topico': null,
      imagem: '',
      [HistoryStorage.target]: item.raw,
    } as unknown as StorageItem
  }

  private stripHTML(html: string | undefined) {
    return html?.replace(/<\/?[A-z]*>/g, '') || ''
  }
}

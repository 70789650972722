import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLike(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6a1 1 0 1 1 2 0v3a1 1 0 0 0 1 1h4.039a1 1 0 0 1 .975 1.223l-1.197 6.984a1 1 0 0 1-.978.793H8v-8c1.388 0 2.428-.715 3.082-1.695C11.716 8.354 12 7.148 12 6ZM7 9h1c.612 0 1.072-.285 1.418-.805C9.784 7.646 10 6.852 10 6a3 3 0 1 1 6 0v2h3.039a3 3 0 0 1 2.944 3.573l-1.197 6.988A3 3 0 0 1 17.839 21H5a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h2ZM6 19H5a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h1v8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLike)

import { createContext, useContext, useMemo } from 'react'

import {
  defaultExperimentalFlags,
  ExperimentalContextType,
  ExperimentalProviderProps,
} from './config'
import { useFlagsAttributes } from './use-flags-attributes'

export const defaultExperimentalProviderProps = {} as const satisfies Omit<
  ExperimentalProviderProps,
  'children'
>

const ExperimentalContext = createContext<ExperimentalContextType>({ flags: {} })

export function ExperimentalProvider(props: ExperimentalProviderProps) {
  const { children, flags: flagsProp = {} } = props

  const ctx: ExperimentalContextType = useMemo(() => {
    const flags = {
      ...defaultExperimentalFlags,
      ...flagsProp,
    }

    return { flags }
  }, [flagsProp])

  useFlagsAttributes(ctx.flags)

  return <ExperimentalContext.Provider value={ctx}>{children}</ExperimentalContext.Provider>
}

export const useFarolExperimental = () => {
  const ctx = useContext(ExperimentalContext)
  return ctx
}

export * from './config'

import { gql } from '@apollo/client'
import { useCallback } from 'react'

import { useGTM } from '../use-gtm'
import { UseGtmDataLayerWithViewer_ViewerFragment } from './__generated__/use-gtm-data-layer-with-viewer.graphql'

const isDevelopment = process.env.NODE_ENV === 'development'

export const useGTMDataLayerWithViewer = (
  viewer: UseGtmDataLayerWithViewer_ViewerFragment | null
) => {
  const gtm = useGTM()

  const enrichGTMUserData = useCallback(() => {
    if (!viewer?.user?.email) return

    const { email } = viewer.user || {}

    gtm.push({
      'user.email': email,
    })
    if (isDevelopment) {
      console.log(`GTM: push extra props to data-layer ['user.email':${email}]`)
    }
  }, [gtm, viewer])

  return { enrichGTMUserData }
}

useGTMDataLayerWithViewer.fragments = {
  viewer: gql`
    fragment useGTMDataLayerWithViewer_viewer on Profile {
      user {
        email
      }
    }
  `,
}

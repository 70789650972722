import * as RAlertDialog from '@radix-ui/react-alert-dialog'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useRef } from 'react'

import { BodyText } from '../../body-text/body-text'
import { Button, ButtonProps } from '../../button/button'
import { Heading } from '../../heading/heading'
import { Overlay } from '../../overlay/overlay'
import styles from './alert.module.scss'

export type AlertElement = HTMLDivElement

export interface AlertProps extends Omit<HTMLAttributes<AlertElement>, 'title'> {
  className?: string
  title?: ReactNode
  description?: ReactNode
  confirmLabel?: string
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  onConfirm?: ButtonProps['onClick']
  onEscapeKeyDown?: (event: KeyboardEvent) => void
}

export const Alert = forwardRef<AlertElement, AlertProps>(function Alert(props, forwardedRef) {
  const {
    children,
    className,
    title,
    description,
    confirmLabel = 'Ok',
    defaultOpen,
    onConfirm,
    onEscapeKeyDown,
    ...rest
  } = props

  const classes = classNames(styles.root, className)

  const confirmButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <RAlertDialog.Root defaultOpen={defaultOpen} {...rest}>
      <RAlertDialog.Trigger asChild data-testid="trigger-button">
        {children}
      </RAlertDialog.Trigger>
      <RAlertDialog.Portal>
        <RAlertDialog.Overlay asChild>
          <Overlay />
        </RAlertDialog.Overlay>
        <RAlertDialog.Content
          data-testid="content"
          className={classes}
          onOpenAutoFocus={() => confirmButtonRef.current?.focus()}
          onEscapeKeyDown={onEscapeKeyDown}
          ref={forwardedRef}
        >
          <RAlertDialog.Title asChild>
            <Heading>{title}</Heading>
          </RAlertDialog.Title>
          <RAlertDialog.Description asChild>
            <BodyText className={styles.description}>{description}</BodyText>
          </RAlertDialog.Description>
          <RAlertDialog.Action asChild>
            <Button
              kind="primary"
              onClick={onConfirm}
              className={styles.button}
              ref={confirmButtonRef}
            >
              {confirmLabel}
            </Button>
          </RAlertDialog.Action>
        </RAlertDialog.Content>
      </RAlertDialog.Portal>
    </RAlertDialog.Root>
  )
})

export enum Steps {
  ACCESS_LIMIT_REACHED = 'access-limit-reached',
  VALIDATE_IDENTITY = 'validate-identity',
  VALIDATING_IDENTITY = 'validating-identity',
  IDENTITY_ERROR = 'identity-error',
  IDENTITY_SUCCESS = 'identity-success',
  CPF_IN_USE = 'cpf-in-use',
  REMOVAL_CPF_EMAIL = 'removal-cpf-email',
  REPLACING_CPF = 'replacing-cpf',
  REPLACEMENT_SUCCESS = 'replacement-success',
  REPLACEMENT_ERROR = 'replacement-error',
}

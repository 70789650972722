import { useEffect, useState } from 'react'

export type UseMountTransitionType = 'forwards' | 'backwards' | 'both'

export type UseMountTransitionOptions = {
  controlledState?: boolean
  skipInitialTransition?: boolean
}

export function useMountTransition(
  delay: number,
  defaultStateProp = false,
  transitionType: UseMountTransitionType = 'both',
  options: UseMountTransitionOptions = {}
): [boolean, (open: boolean) => void, boolean] {
  const { controlledState, skipInitialTransition } = options
  const defaultState = controlledState !== undefined ? controlledState : defaultStateProp
  const [state, setState] = useState(defaultState)
  const [done, setDone] = useState(
    skipInitialTransition ? defaultState : controlledState !== undefined ? controlledState : false
  )

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (state && !done) {
      if (transitionType === 'backwards') {
        setDone(true)
      } else {
        timeoutId = setTimeout(() => setDone(true), delay)
      }
    } else if (!state && done) {
      if (transitionType === 'forwards') {
        /* istanbul ignore next */
        setDone(false)
      } else {
        timeoutId = setTimeout(() => setDone(false), delay)
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [delay, state, done, transitionType])

  useEffect(() => {
    if (controlledState === undefined) return
    setState(controlledState)
  }, [controlledState])

  const currentState = state || done
  const stateDone = state && done
  function setStateCallback(newState: boolean) {
    if (controlledState !== undefined) return
    setState(newState)
  }

  return [currentState, setStateCallback, stateDone]
}

import { EVENT_PROPERTIES_STORAGE_KEY, snowpiercerClient } from '../constants'
import { namespacesStorageObjectPromise } from './namespaces-storage-object-promise'

export async function restoreEventPropertiesForNextPage() {
  const lastProperties = await namespacesStorageObjectPromise

  lastProperties.timestamp = Date.now()

  await snowpiercerClient.setItem(EVENT_PROPERTIES_STORAGE_KEY, JSON.stringify(lastProperties))
}

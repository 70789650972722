import { createContext } from 'react'

import type { UsePopoverProviderReturn } from './use-popover'

export type PopoverContext = UsePopoverProviderReturn

export const PopoverContext = createContext<PopoverContext>({
  placement: 'top',
  type: 'light',
})

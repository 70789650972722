import { ServerResponse } from 'http'

import { buildCookiesContext } from './build-cookies-context'
import { DEFAULT_JUSBRASIL_COOKIE_DOMAIN, DEFAULT_JUSBRASIL_COOKIE_PATH } from './constants'
import { JusbrasilCookieOptions } from './types'

/**
 * @description Function that removed an existing cookie from the Jusbrasil domain.
 * @param response If you are running this function in the server side, send the response object. If you are using the function in the client side, use null.
 * @param name Name of the cookie you want to destroy.
 * @param options Any additional cookie options you need to pass.
 * @returns
 */
export function destroyJusbrasilCookie(
  response: ServerResponse | null,
  name: string,
  options?: JusbrasilCookieOptions
): void {
  const cookies = buildCookiesContext({ response })

  return cookies.remove(name, {
    path: DEFAULT_JUSBRASIL_COOKIE_PATH,
    domain: DEFAULT_JUSBRASIL_COOKIE_DOMAIN,
    ...options,
  })
}

import { gql } from '@apollo/client'
import { Modal } from '@farol-ds/react'
import React, { useEffect, useState } from 'react'

import { LawsuitSummaryGeneratingModal_LawsuitFragment } from './__generated__/lawsuit-summary-modal.graphql'
import styles from './lawsuit-summary-modal.module.scss'
import {
  GeneratingSummary,
  getNextStep,
  INITIAL_STEP,
  LAWSUIT_SUMMARIZATION_STEPS,
  NegativeFeedback,
  PositiveFeedback,
  STEPS,
  SummaryView,
} from './steps'
import { useSummarizationMutation, useSummarizationQuery } from './summarizationQueries'

export interface LawsuitSummaryGeneratingModalProps {
  lawsuit: LawsuitSummaryGeneratingModal_LawsuitFragment
  isMobile?: boolean
  formattedTitle: React.ReactNode
  onSubmitSummarizationSurvey?: (surveyData: any) => void
  onSendEvent?: (event: any, metadata: any) => void
  TriggerButtonComponent: React.ReactElement
}

export function LawsuitSummaryGeneratingModal(props: LawsuitSummaryGeneratingModalProps) {
  const {
    lawsuit,
    isMobile,
    formattedTitle,
    onSubmitSummarizationSurvey,
    onSendEvent,
    TriggerButtonComponent,
  } = props
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP)

  const [isLoading, setIsLoading] = useState(false)
  const [generatingDuration, setGeneratingDuration] = useState<number | null>(null)
  const [summary, setSummary] = useState<string | undefined>(undefined)

  const metadata = {
    lawsuitId: lawsuit.lawsuitId,
    formattedTitle,
  }

  const createSummarization = useSummarizationMutation({
    lawsuitId: lawsuit.lawsuitId,
    courtName: lawsuit.cnjNumber?.court?.name,
  })
  const getSummarization = useSummarizationQuery({
    lawsuitId: lawsuit.lawsuitId,
  })

  const reloadSummarization = async (attempts = 10, interval = 1500) => {
    let summaryData = undefined

    const { data } = await getSummarization()
    summaryData = data?.root?.crmLawsuit?.summarization?.summarization

    if (!summaryData) {
      if (attempts > 1) {
        await new Promise((resolve) => setTimeout(resolve, interval))
        return reloadSummarization(attempts - 1, interval)
      }
      throw new Error('summarization query did not return a summary')
    }
    return summaryData
  }

  const handleCreateSummarization = async () => {
    const { data, errors } = await createSummarization()
    if (errors) {
      throw new Error(`error when creating summary by mutation: ${JSON.stringify(errors)}`)
    }

    const summaryData = data?.createLawsuitSummarization?.summarization?.summarization
    if (summaryData) {
      return summaryData
    }
    return reloadSummarization()
  }

  const generateSummary = async () => {
    if (!isLoading) {
      setIsLoading(true)
      setSummary(undefined)

      const startTime = Date.now()
      setGeneratingDuration(null)

      setCurrentStep(STEPS.GENERATING_SUMMARY)

      try {
        const summaryData = await handleCreateSummarization()
        setSummary(summaryData)
        setGeneratingDuration(Date.now() - startTime)
      } catch (e) {
        console.error('Error generating summary:', e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (currentStep === STEPS.IDLE) return

    const stepData = LAWSUIT_SUMMARIZATION_STEPS[currentStep]
    const nextStep = getNextStep(currentStep, isLoading, summary)

    const timer = setTimeout(() => {
      if (!nextStep || nextStep == currentStep) {
        return
      }
      setCurrentStep(nextStep)
    }, stepData.delay)

    return () => clearTimeout(timer)
  }, [currentStep, isLoading, summary])

  const errorButtonHandler = async () => {
    onSendEvent?.('Lawsuit.LawsuitSummaryGeneratingModalFailedTryAgainClick', { metadata })
    generateSummary()
  }

  const generateSummaryButtonHandler = async () => {
    onSendEvent?.('Lawsuit.LawsuitCardThirdPartySummarizationClick', { metadata })
    generateSummary()
  }

  const onOpenChangeHandler = (isOpen: boolean) => {
    if (!isOpen) {
      switch (currentStep) {
        case STEPS.FAIL:
          return onSendEvent?.('Lawsuit.LawsuitSummaryGeneratingModalFailedCloseClick', {
            metadata,
          })
        case STEPS.SUMMARY_VIEW:
          return onSendEvent?.('Lawsuit.LawsuitSummaryGeneratingModalSuccessCloseClick', {
            metadata,
          })
      }
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.IDLE:
        return <></>
      case STEPS.GENERATING_SUMMARY:
        return (
          <GeneratingSummary
            onSendEvent={() =>
              onSendEvent?.('Lawsuit.LawsuitSummaryGeneratingModalRequesting', { metadata })
            }
          />
        )
      case STEPS.SUCCESS:
        return <PositiveFeedback />
      case STEPS.FAIL:
        return (
          <NegativeFeedback
            errorButtonHandler={errorButtonHandler}
            onSendEvent={() =>
              onSendEvent?.('Lawsuit.LawsuitSummaryGeneratingModalFailed', { metadata })
            }
          />
        )
      case STEPS.SUMMARY_VIEW:
        return (
          <SummaryView
            lawsuitId={lawsuit.lawsuitId || ''}
            isMobile={isMobile}
            onSubmitSummarizationSurvey={onSubmitSummarizationSurvey}
            title={formattedTitle}
            summary={summary}
            onSendEvent={(event: any) =>
              onSendEvent?.(event, { metadata: { ...metadata, generatingDuration } })
            }
          />
        )
    }
  }

  return (
    <Modal size="md" onOpenChange={onOpenChangeHandler}>
      <Modal.Trigger>
        <TriggerButtonComponent.type
          {...TriggerButtonComponent.props}
          onClick={generateSummaryButtonHandler}
        />
      </Modal.Trigger>
      <Modal.Content data-testid="lawsuit-summary-modal">
        <span className={styles.content}>{renderStep()}</span>
      </Modal.Content>
    </Modal>
  )
}

LawsuitSummaryGeneratingModal.fragments = {
  lawsuit: gql`
    fragment LawsuitSummaryGeneratingModal_lawsuit on CRMLawsuit {
      lawsuitId
      cnjNumber {
        court {
          name
        }
      }
    }
  `,
}

import {
  DarkModeIcon,
  IconButton,
  LightModeIcon,
  useFarolExperimental,
  useTheme,
} from '@farol-ds/react'
import classNames from 'classnames'

import styles from './topbar-header.module.scss'

export function ThemeToggle() {
  const { flags } = useFarolExperimental()
  const [theme, setTheme] = useTheme()

  if (!flags.theme) {
    return null
  }

  return (
    <IconButton
      label={
        theme === 'dark' ? 'Ativar modo claro (admin-only)' : 'Ativar modo escuro (admin-only)'
      }
      icon={theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      kind="plain"
      size="md"
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
      className={classNames(styles.themeSwitch, {
        [styles.themeSwitchVisible]: theme !== null,
      })}
    />
  )
}

import { Nullable } from '../../types'

type NormalizedSubject = {
  items: Array<
    Array<{
      text: string
    }>
  >
}

function concatNormalizedSubject(
  normalizedSubject: Nullable<NormalizedSubject[]>
): Nullable<string> {
  if (!normalizedSubject || normalizedSubject.length === 0) {
    return null
  }

  const firstItem = normalizedSubject[0]
  const texts = firstItem.items[0].map((item: { text: string }) => item.text)
  texts.pop()
  const concatenatedText = texts.join(', ')
  return concatenatedText
}

export function formatThirdPartyCardTitle(
  title: Nullable<string>,
  number: Nullable<string>,
  typification: Nullable<string>,
  kind: Nullable<string>,
  normalizedSubject: Nullable<NormalizedSubject[]>,
  restrictLawsuit?: boolean | null,
  canViewRestrictLawsuit?: boolean | null
) {
  if (restrictLawsuit && !canViewRestrictLawsuit && title) return title

  const lawsuitKind = kind?.toLocaleLowerCase()
  const lawsuitTypification = typification?.toLocaleLowerCase()
  const normalizedSubjectText = concatNormalizedSubject(normalizedSubject)

  if (lawsuitKind?.includes('criminal') && lawsuitTypification) {
    return `Processo por ${lawsuitTypification}`
  }

  if (lawsuitKind?.includes('trabalhista')) {
    return `Processo por Reclamações Trabalhistas`
  }

  if (normalizedSubjectText) {
    return lawsuitKind?.includes('criminal')
      ? `Processo por ${normalizedSubjectText}`
      : `Processo sobre ${normalizedSubjectText}`
  }

  return title || `Processo nº ${number}`
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCase(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4V5ZM8 6V5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h3a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h3ZM4 9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9Zm10 6v-1h-4v1a1 1 0 1 1-2 0v-1H5c-.35 0-.687-.06-1-.17V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-5.17c-.313.11-.65.17-1 .17h-3v1a1 1 0 1 1-2 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCase)

function normalize(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function fuzzySearch<K>(items: K[], query: string, getter: (item: K) => string): K[] {
  const matchingItems: K[] = []

  for (const item of items) {
    const value = getter(item)
    const itemValue = normalize(value).toLowerCase()
    const queryValue = normalize(query).toLowerCase()

    if (itemValue.includes(queryValue)) {
      matchingItems.push(item)
    } else {
      const queryChars = queryValue.split('')
      const itemChars = itemValue.split('')
      const matchPositions: number[] = []

      for (const queryChar of queryChars) {
        const matchIndex = itemChars.findIndex((c) => c === queryChar)
        if (matchIndex === -1) {
          break
        }
        matchPositions.push(matchIndex)
        itemChars.splice(matchIndex, 1)
      }

      if (matchPositions.length === queryChars.length) {
        matchingItems.push(item)
      }
    }
  }

  return matchingItems
}

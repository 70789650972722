import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { forwardRef, ReactNode, Ref, useContext, useImperativeHandle, useRef } from 'react'

import AutocompleteItem from './autocomplete-item'
import AutocompleteItemContent from './autocomplete-item-content'
import AutocompleteItemRemove from './autocomplete-item-remove'
import AutocompleteList, {
  AutocompleteListElement,
  AutocompleteListProps,
} from './autocomplete-list'
import { AutocompleteContext } from './context'
import { fuzzySearch } from './fuzzy-search'
import { AutocompleteOption } from './types'
import { useAutocompleteMenu, UseAutocompleteMenuReturn } from './use-autocomplete-menu'

export type AutocompleteMenuElement<T extends AutocompleteOption> = UseAutocompleteMenuReturn<T>

export type AutocompleteMenuProps<T extends AutocompleteOption> = {
  options: T[]
  filterOptions?: ((options: T[], input: string) => T[]) | null
  onOptionSelect?: (option: T) => void
  onOptionRemove?: (option: T) => void
  onOpenChange?: (open: boolean) => void
  onCurrentOptionChange?: (option: T | null) => void
  defaultOpen?: boolean
  listProps?: Omit<AutocompleteListProps, 'children'>
  dangerouslyLabelAsHTML?: boolean
  children?: ReactNode
}

export function defaultFilterOptions<T extends AutocompleteOption>(
  options: T[],
  input: string
): T[] {
  return fuzzySearch(options, input, (option) => option.value)
}

function AutocompleteMenuInner<T extends AutocompleteOption>(
  props: AutocompleteMenuProps<T>,
  forwardedRef: Ref<AutocompleteMenuElement<T>>
) {
  const {
    listProps,
    dangerouslyLabelAsHTML = false,
    filterOptions = defaultFilterOptions,
    children,
  } = props

  const ctx = useContext(AutocompleteContext)

  const listRef = useRef<AutocompleteListElement>(null)
  const refs = useComposedRefs(forwardedRef, ctx.menuRef)

  const forceOpen = !!children
  const ctrl = useAutocompleteMenu({
    ...props,
    listRef,
    filterOptions,
    forceOpen,
  })
  useImperativeHandle(refs, () => ctrl)

  const options = ctrl.options
  const isOpen = ctrl.open && (forceOpen || options.length > 0)

  return (
    <AutocompleteList open={isOpen} {...listProps} ref={listRef}>
      {children ??
        options.map((option) => {
          const id = option.id ?? option.value
          const label = option.label ?? option.value
          const labelAsHTML = dangerouslyLabelAsHTML && typeof label === 'string'

          return (
            <AutocompleteItem history={option.history} key={id}>
              <AutocompleteItemContent
                itemId={id}
                leftIcon={option.leftIcon}
                onClick={(evt) => ctrl.selectOption(evt, option)}
                dangerouslySetInnerHTML={labelAsHTML ? { __html: label } : undefined}
              >
                {!labelAsHTML ? label : undefined}
              </AutocompleteItemContent>

              {option.history ? (
                <AutocompleteItemRemove
                  itemId={id}
                  onClick={(evt) => {
                    ctrl.removeOption(evt, option)
                    ctx.triggerRef.current?.input?.focus()
                  }}
                />
              ) : null}
            </AutocompleteItem>
          )
        })}
    </AutocompleteList>
  )
}

const AutocompleteMenu = forwardRef(AutocompleteMenuInner) as <T extends AutocompleteOption>(
  props: AutocompleteMenuProps<T> & { ref?: React.ForwardedRef<AutocompleteMenuElement<T>> }
) => ReturnType<typeof AutocompleteMenuInner>

export default AutocompleteMenu

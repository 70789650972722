import { useCallback, useEffect } from 'react'

import { bindEvents, triggerEvent } from './internal'
import { ListenersBase } from './types'

export type UseFrameBusHostProps<HostListeners> = {
  id: string
  autoConnect?: boolean
  listeners?: HostListeners
}

export function useFrameBusHost<
  ClientListeners extends ListenersBase,
  HostListeners extends ListenersBase = ListenersBase
>(props: UseFrameBusHostProps<HostListeners>) {
  const { id, autoConnect = true, listeners = {} } = props

  const trigger = useCallback(
    <K extends keyof ClientListeners>(name: K, detail?: Parameters<ClientListeners[K]>[0]) => {
      if (!window.frameElement) return
      triggerEvent(window.frameElement as HTMLIFrameElement, id, name as string, detail)
    },
    [id]
  )

  const connect = useCallback(() => trigger('connect'), [trigger])

  // auto connect
  useEffect(() => {
    autoConnect && connect()
  }, [autoConnect, connect])

  // ensure base tag to open links in parent
  useEffect(() => {
    const existing = document.head.querySelector('base')
    if (existing?.target === '_parent') {
      return
    } else if (existing) {
      document.head.removeChild(existing)
    }

    const base = document.createElement('base')
    base.target = '_parent'
    document.head.appendChild(base)
    return () => {
      document.head.removeChild(base)
      if (existing) {
        document.head.appendChild(existing)
      }
    }
  }, [])

  // bind internal listeners
  useEffect(() => {
    if (!window.frameElement) return
    const unbindUserListenerEvents = bindEvents(id, window.frameElement as HTMLIFrameElement, {
      ping() {
        trigger('pong')
      },
    })
    return () => unbindUserListenerEvents()
  }, [trigger, id])

  // bind listeners
  useEffect(() => {
    if (!window.frameElement) return
    const unbindUserListenerEvents = bindEvents(
      id,
      window.frameElement as HTMLIFrameElement,
      listeners
    )
    return () => unbindUserListenerEvents()
  }, [id, listeners])

  return {
    connect,
    trigger,
    open() {
      trigger('open')
    },
    close() {
      trigger('close')
    },
    toggle(nextOpen?: boolean) {
      trigger('toggle', nextOpen)
    },
  }
}

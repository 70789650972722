import { useRouter } from 'next/router'
import { useEffect } from 'react'

// useBrowserBackButtonListener is a custom hook used to prevent the  NextJs
// router from refreshing the page when the browser back button is clicked and
// the previous url is the same of the current one. It occurs when we're using
// attachBrowserBackButtonListener function to add a new history entry to make
// possible close a modal when the back button is clicked.
export function useBrowserBackButtonListener() {
  const router = useRouter()

  useEffect(() => {
    router.beforePopState(({ url }) => {
      const currentUrl = `${window.location.pathname}${window.location.search}`
      if (currentUrl === url) {
        return false
      }

      return true
    })
  }, [router])
}

import { Popover, PopoverElement } from '@farol-ds/react'
import { getCookie, setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import { forwardRef, ReactNode, useState } from 'react'

import { ONE_YEAR_IN_SECONDS } from '../../constants'
import styles from './topbar-jus-ia-button-popover.module.scss'

const JUS_IA_TOPBAR_SEEN_COOKIE = 'jus-ia-topbar-seen'

interface TopbarJusIAButtonPopoverProps {
  children: ReactNode
}

export const TopbarJusIAButtonPopover = forwardRef<PopoverElement, TopbarJusIAButtonPopoverProps>(
  function TopbarJusIAButtonPopover({ children }, forwardedRef) {
    const hasAlreadyBeenSeen = getCookie(null, JUS_IA_TOPBAR_SEEN_COOKIE) === 'true'
    const [isOpen, setIsOpen] = useState(!hasAlreadyBeenSeen)
    const onOpenChange = (open: boolean) => {
      setIsOpen(open)
      const hasAlreadyBeenSeen = getCookie(null, JUS_IA_TOPBAR_SEEN_COOKIE) === 'true'
      if (!open && !hasAlreadyBeenSeen) {
        setJusbrasilCookie(null, JUS_IA_TOPBAR_SEEN_COOKIE, 'true', {
          maxAge: ONE_YEAR_IN_SECONDS,
        })
      }
    }

    return (
      <Popover open={isOpen} onOpenChange={onOpenChange} placement="bottom" type="highlight">
        <Popover.Trigger asChild>{children}</Popover.Trigger>
        <Popover.Content
          style={{
            zIndex: 3,
            maxWidth: '256px',
          }}
          className={styles.content}
          ref={forwardedRef}
        >
          <Popover.ContentClose />
          <Popover.ContentDescription>
            Chegou o Jus IA: o assistente jurídico que pensa com você!
          </Popover.ContentDescription>
        </Popover.Content>
      </Popover>
    )
  }
)

import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import { Caption } from '../caption/caption'
import styles from './selection-card-body-content-description.module.scss'

export type SelectionCardBodyContentDescriptionElement = HTMLDivElement

export type SelectionCardBodyContentDescriptionProps =
  HTMLAttributes<SelectionCardBodyContentDescriptionElement>

const SelectionCardBodyContentDescription: React.FC<SelectionCardBodyContentDescriptionProps> = (
  props
) => {
  const { className, children, ...rest } = props
  const classes = classNames(styles.root, className)

  return (
    <Caption {...rest} className={classes} asChild>
      <div>{children}</div>
    </Caption>
  )
}

export default SelectionCardBodyContentDescription

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { CloseIcon } from '../icon/icons'
import { usePress, VisuallyHidden } from '../utilities'
import styles from './autocomplete-item-remove.module.scss'
import { AutocompleteOption } from './types'

export type AutocompleteItemRemoveElement = HTMLSpanElement

export type AutocompleteItemRemoveProps = HTMLAttributes<AutocompleteItemRemoveElement> & {
  itemId: NonNullable<AutocompleteOption['id']>
}

const AutocompleteItemRemove = forwardRef<
  AutocompleteItemRemoveElement,
  AutocompleteItemRemoveProps
>(function AutocompleteItemRemove(props, forwardedRef) {
  const { itemId, onClick, className, onKeyDown, ...rest } = props

  const { ref } = usePress<HTMLSpanElement>({
    onPress(evt) {
      onClick?.(evt as any)
    },
  })
  const refs = useComposedRefs(forwardedRef, ref)

  const classes = classNames(styles.root, className)

  return (
    <>
      <span
        data-testid="autocomplete-item-remove"
        {...rest}
        role="button"
        aria-labelledby={`${itemId}close1 ${itemId}text ${itemId}close2`}
        tabIndex={-1}
        className={classes}
        ref={refs}
      >
        <CloseIcon className={styles.icon} size="sm" />
      </span>
      <VisuallyHidden id={`${itemId}close1`}>Remover</VisuallyHidden>
      <VisuallyHidden id={`${itemId}close2`}>da lista do histórico</VisuallyHidden>
    </>
  )
})

export default AutocompleteItemRemove

import type { SxProp } from './types'

// Map of sx props to their corresponding class names in shorthand notation (less HTML output)
// Remember to sync it with sx.module.scss
export const classMap: { [key in SxProp]: string } = {
  alignContent: 'ac',
  alignItems: 'ai',
  alignSelf: 'as',
  backgroundColor: 'bg',
  color: 'c',
  display: 'd',
  fill: 'f',
  flexBasis: 'fb',
  flexDirection: 'fd',
  flexGrow: 'fg',
  flexShrink: 'fs',
  flexWrap: 'fw',
  gap: 'g',
  height: 'h',
  justifyContent: 'jc',
  justifyItems: 'ji',
  justifySelf: 'js',
  margin: 'm',
  marginBottom: 'mb',
  marginLeft: 'ml',
  marginRight: 'mr',
  marginTop: 'mt',
  marginX: 'mx',
  marginY: 'my',
  padding: 'p',
  paddingBottom: 'pb',
  paddingLeft: 'pl',
  paddingRight: 'pr',
  paddingTop: 'pt',
  paddingX: 'px',
  paddingY: 'py',
  width: 'w',
}

/* constants */
export const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const

export const spacingTokens = [
  '6xs',
  '5xs',
  '4xs',
  '3xs',
  '2xs',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
] as const

export const colorTokens = [
  'base-low-pure',
  'base-low-1',
  'base-low-2',
  'base-low-3',
  'base-low-4',
  'base-high-pure',
  'base-high-1',
  'base-high-2',
  'base-high-3',
  'base-high-4',
  'brand-primary-pure',
  'brand-primary-1',
  'brand-primary-2',
  'brand-primary-3',
  'brand-primary-4',
  'brand-secondary-pure',
  'brand-secondary-1',
  'brand-secondary-2',
  'brand-secondary-3',
  'brand-secondary-4',
  'brand-tertiary-pure',
  'brand-tertiary-1',
  'brand-tertiary-2',
  'brand-tertiary-3',
  'brand-tertiary-4',
  'feedback-negative-pure',
  'feedback-negative-1',
  'feedback-negative-2',
  'feedback-negative-3',
  'feedback-negative-4',
  'feedback-positive-pure',
  'feedback-positive-1',
  'feedback-positive-2',
  'feedback-positive-3',
  'feedback-positive-4',
  'feedback-info-pure',
  'feedback-info-1',
  'feedback-info-2',
  'feedback-info-3',
  'feedback-info-4',
  'feedback-warning-pure',
  'feedback-warning-1',
  'feedback-warning-2',
  'feedback-warning-3',
  'feedback-warning-4',
]

export const allowedSxProps = Object.keys(classMap)

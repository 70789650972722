import styles from './rich-text.module.scss'

/**
 * ATTENTION:
 *
 * This file is intended to export the CSS styles of the RichText component
 * so they can be used independently of the React component, allowing the styles
 * to be applied to any other element.
 *
 * Why was it necessary?
 * The RichText component has a role that can become complex when requiring direct usage
 * within React. Some cases, such as the Tiptap editor, expect a `class` prop to apply styles
 * to an internal container, making it impossible to wrap it with the RichText component.
 *
 * Additionally, there are scenarios where we need to render the RichText styles in
 * a more isolated manner for advanced use cases.
 *
 * This flexibility does not mean that the React component is deprecated. On the contrary,
 * this approach should be used only in specific cases where necessary.
 */

export const richTextStyles = styles as {
  richtext: string
}

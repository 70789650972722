import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUserAdd(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C13.416 8.186 14 7.161 14 6a2 2 0 0 0-2-2Zm.514 7.857a.995.995 0 0 0 .055-.034l.003-.003.035-.024a8.379 8.379 0 0 0 .502-.387 9.75 9.75 0 0 0 1.15-1.133C15.085 9.313 16 7.838 16 6a4 4 0 0 0-8 0c0 1.838.915 3.313 1.74 4.276.186.216.371.412.547.585L7.4 12.595A7 7 0 0 0 4 18.597V19a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H7a1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287l4.086-2.453ZM18 13a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUserAdd)

import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'
import { Switch, SwitchProps } from './switch'
import styles from './switch-item.module.scss'
import SwitchLabel from './switch-label'

export type SwitchItemElement = HTMLSpanElement

export interface SwitchItemProps extends SwitchProps {
  children: ReactNode
  group?: boolean
  asChild?: boolean
}

const SwitchItem = forwardRef<SwitchItemElement, SwitchItemProps>(function SwitchItem(
  props,
  forwardedRef
) {
  const { className, group = false, children, asChild, size, ...rest } = props

  const Component = asChild ? Slot : 'span'

  const classes = classNames(styles.root, className)

  const content = (
    <Component className={classes} ref={forwardedRef}>
      {Children.map(children, (child) => {
        if (!isValidElement<SwitchProps>(child)) return child
        if (child.type === SwitchLabel) return cloneElement(child, { size })
        if (child.type === Switch) return cloneElement(child, { size, ...rest })
        return child
      })}
    </Component>
  )

  if (group) return content
  return <span className={styles.inline}>{content}</span>
})

export default SwitchItem

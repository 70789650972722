import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { IconProps, IconSize } from '../icon/types'
import styles from './banner-icon.module.scss'

export type BannerIconElement = HTMLDivElement

export interface BannerIconProps extends HTMLAttributes<BannerIconElement> {
  children: ReactNode
  className?: string
}

const iconSize: IconSize = 'sm'

const BannerIcon = forwardRef<BannerIconElement, BannerIconProps>(function BannerIcon(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <span {...rest} data-fds-banner-icon className={classes} ref={forwardedRef} aria-hidden>
      {isValidElement<IconProps>(children) ? cloneElement(children, { size: iconSize }) : children}
    </span>
  )
})

export default BannerIcon

/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useRef } from 'react'

export type DebounceCallbackReturn<K extends (...args: any[]) => void> = K & {
  cancel(): void
  flush(): void
}

export function useDebounceCallback<K extends (...args: any[]) => void>(
  fn: K,
  wait = 0,
  { maxWait = Infinity } = {}
): DebounceCallbackReturn<K> {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const startTimeRef = useRef<number>(0)
  const runningRef = useRef<boolean>(false)
  const pendingParamsRef = useRef<any[]>([])

  const execute = useCallback(() => {
    if (runningRef.current) {
      runningRef.current = false
      fn(...pendingParamsRef.current)
    }
  }, [fn])

  const result: any = useCallback(
    (...params: any[]) => {
      pendingParamsRef.current = params

      if (runningRef.current && Date.now() - startTimeRef.current > maxWait) {
        execute()
      } else {
        if (!runningRef.current) {
          startTimeRef.current = Date.now()
        }
        runningRef.current = true
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(
        execute,
        Math.min(maxWait - (Date.now() - startTimeRef.current), wait)
      )
    },
    [execute, wait, maxWait]
  )

  result.flush = useCallback(() => {
    if (runningRef.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      execute()
    }
  }, [execute])

  result.cancel = useCallback(() => {
    runningRef.current = false
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return result
}

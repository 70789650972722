import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4FeatureUseEventProps {
  featureClass: string
  componentClass: string
  featureName?: string | null
  successfulAttempt?: boolean
  componentName?: string | null
  resourceId?: string | null
  selectedOption?: string | null
  customInput?: string | null
  requiredUserStatus?: string | null
}

export class GA4FeatureUseEvent extends GA4Event {
  ga4_feature_class: string
  ga4_component_class: string
  ga4_feature_name: string | null
  ga4_successful_attempt: boolean
  ga4_component_name: string | null
  ga4_resource_id: string | null
  ga4_selected_option: string | null
  ga4_custom_input: string | null
  ga4_required_user_status: string | null

  constructor({
    featureClass,
    componentClass,
    successfulAttempt = true,
    featureName = null,
    resourceId = null,
    customInput = null,
    selectedOption = null,
    componentName = null,
    requiredUserStatus = null,
  }: GA4FeatureUseEventProps) {
    super({ event: EVENT.FEATURE_USE })

    this.ga4_feature_name = featureName
    this.ga4_feature_class = featureClass
    this.ga4_component_class = componentClass
    this.ga4_resource_id = resourceId
    this.ga4_selected_option = selectedOption
    this.ga4_custom_input = customInput
    this.ga4_successful_attempt = successfulAttempt
    this.ga4_component_name = componentName
    this.ga4_required_user_status = requiredUserStatus
  }
}

import { assignSubComponents } from '../utilities/internal'
import AutocompleteItem from './autocomplete-item'
import AutocompleteItemContent from './autocomplete-item-content'
import AutocompleteItemRemove from './autocomplete-item-remove'
import AutocompleteList from './autocomplete-list'
import AutocompleteMenu from './autocomplete-menu'
import AutocompleteRoot from './autocomplete-root'
import AutocompleteTrigger from './autocomplete-trigger'

export interface AutocompleteRootProps {
  children: React.ReactNode
  className?: string
}

export const Autocomplete = assignSubComponents('Autocomplete', AutocompleteRoot, {
  Trigger: AutocompleteTrigger,
  Menu: AutocompleteMenu,
  // for customizations
  List: AutocompleteList,
  Item: AutocompleteItem,
  ItemContent: AutocompleteItemContent,
  ItemRemove: AutocompleteItemRemove,
})

export * from './autocomplete-item-content'
export * from './autocomplete-item-remove'
export * from './autocomplete-list'
export * from './autocomplete-menu'
export * from './autocomplete-root'
export * from './autocomplete-trigger'
export * from './types'

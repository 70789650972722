import { MutationFunction } from '@apollo/client'

import { DAY_IN_MS } from '../data-format'
import { getItemSessionStorage, setItemSessionStorage } from '../storage'

export const handleBounceUserActivity = (
  topicId: string,
  sourceType: string,
  utmSource: string,
  createBounceUserActivity: MutationFunction
) => {
  const bounceActivityStorage = getItemSessionStorage(`createBounceUserActivity-${topicId}`)

  if (bounceActivityStorage) {
    return
  }

  setItemSessionStorage(`createBounceUserActivity-${topicId}`, true, DAY_IN_MS)

  createBounceUserActivity({
    variables: {
      input: {
        sourceId: topicId,
        sourceType: sourceType,
        utmSource: utmSource,
      },
    },
  })
}

import classNames from 'classnames'
import { DragEvent, KeyboardEvent, useState } from 'react'

import styles from './drop-file-zone.module.scss'

export interface DropFileZoneProps {
  children: React.ReactNode
  isFileValid: boolean
  className?: string
  onFileDropped?: (file: File) => void
  onDropZoneClick?: () => void
}

export function DropFileZone(props: DropFileZoneProps) {
  const { children, className, isFileValid, onDropZoneClick, onFileDropped } = props
  const [dragOver, setDragOver] = useState(false)

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => event.preventDefault()

  const handleDropZoneClick = () => onDropZoneClick?.()

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const droppedFile = event.dataTransfer.files[0]
    if (!!droppedFile && onFileDropped) {
      onFileDropped(droppedFile)
    }

    setDragOver(false)
  }

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const currentTarget = event.currentTarget
    const relatedTarget = event.relatedTarget as Node | null

    if (!relatedTarget || !currentTarget.contains(relatedTarget)) {
      setDragOver(false)
    }
  }

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDropZoneKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onDropZoneClick?.()
    }
  }

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.dragOver]: dragOver,
        [styles.error]: !isFileValid,
      })}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onClick={handleDropZoneClick}
      onKeyDown={handleDropZoneKeyDown}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  )
}

import { gql } from '@apollo/client'
import { Link, Snippet } from '@farol-ds/react'

import { capitalizeFullName } from '../../utils/data-format/capitalize-full-name'
import { PersonSummary_Party_EntityFragment } from './__generated__/person-summary.graphql'
import styles from './person-summary.module.scss'
import { buildLawsuitStates } from './util'

export interface PersonSummaryProps {
  partyEntity: NonNullable<PersonSummary_Party_EntityFragment>
  onClick: () => void
}

export function PersonSummary(props: PersonSummaryProps) {
  const { partyEntity, onClick } = props

  const capitalizedName = capitalizeFullName(partyEntity.identificationName || '')
  const lawsuitStatesArray =
    partyEntity.aggregations?.counterByState?.map((cbs) => cbs?.state || '') || []
  const lawsuitStates = buildLawsuitStates(lawsuitStatesArray)

  const hasAge = !!partyEntity.age
  const hasStates = lawsuitStatesArray.length > 0

  return (
    <Snippet>
      <Snippet.Container>
        <Snippet.Header>
          <Snippet.Title>
            <Link
              data-testid="person-entity-page-link"
              href={partyEntity.url || ''}
              onClick={() => onClick()}
            >
              {capitalizedName}
            </Link>
          </Snippet.Title>
        </Snippet.Header>
        <Snippet.Body>
          <Snippet.Content className={styles.content}>
            {hasAge && <span className={styles.content__age}>{partyEntity.age} anos</span>}
            {hasAge && hasStates && <span>•</span>}
            {hasStates && <span className={styles.content__states}>{lawsuitStates}</span>}
          </Snippet.Content>
        </Snippet.Body>
      </Snippet.Container>
    </Snippet>
  )
}

PersonSummary.fragments = {
  partyEntity: gql`
    fragment PersonSummary_party_entity on CRMLawsuitPartyEntity {
      age
      identificationName(unlockName: true)
      url
      aggregations {
        counterByState {
          counter
          state
        }
      }
    }
  `,
}

'use client'

import { useEffect, useRef } from 'react'

type Callback = () => void

/**
 * A hook that provides an interval timer.
 * @returns a tuple containing the start and clear functions
 */
export const useInterval = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const clear = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  const start = (callback: Callback, delay?: number | null) => {
    if (delay !== null && delay !== undefined) {
      clear()
      intervalRef.current = setInterval(callback, delay)
    }
  }

  useEffect(() => () => clear(), [])

  return [start, clear] as const
}

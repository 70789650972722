import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './autocomplete-list.module.scss'

export const AutocompleteListTypes = ['primary', 'secondary'] as const
export type AutocompleteListType = (typeof AutocompleteListTypes)[number]
export type AutocompleteListElement = HTMLUListElement

export interface AutocompleteListProps extends HTMLAttributes<AutocompleteListElement> {
  children: ReactNode
  type?: AutocompleteListType
  className?: string
  open?: boolean
  asChild?: boolean
}

const AutocompleteList = forwardRef<AutocompleteListElement, AutocompleteListProps>(
  function AutocompleteList(props, forwardedRef) {
    const { open = true, className, children, type: typeProp, asChild, ...rest } = props

    if (!open) return null

    const type = ensurePropOf<AutocompleteListType>(AutocompleteListTypes, typeProp, 'secondary')
    const classes = classNames(
      styles.root,
      {
        [styles[`type${type}`]]: type,
      },
      className
    )

    return (
      <ul {...rest} role="listbox" tabIndex={-1} className={classes} ref={forwardedRef}>
        {children}
      </ul>
    )
  }
)

export default AutocompleteList

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { IconProps } from '../icon/types'
import { usePress } from '../utilities'
import styles from './autocomplete-item-content.module.scss'
import { AutocompleteOption } from './types'

export type AutocompleteItemContentElement = HTMLSpanElement

export interface AutocompleteItemContentProps
  extends HTMLAttributes<AutocompleteItemContentElement> {
  itemId: NonNullable<AutocompleteOption['id']>
  children?: ReactNode
  leftIcon?: ReactNode
}

const AutocompleteItemContent = forwardRef<
  AutocompleteItemContentElement,
  AutocompleteItemContentProps
>(function AutocompleteItemContent(props, forwardedRef) {
  const { itemId, onClick, className, leftIcon, children, dangerouslySetInnerHTML, ...rest } = props

  const { ref } = usePress<HTMLElement>({
    onPress(evt) {
      onClick?.(evt as any)
    },
  })
  const refs = useComposedRefs(forwardedRef, ref)

  const classes = classNames(styles.root, className)

  return (
    <span data-testid="autocomplete-item-content" {...rest} className={classes} ref={refs}>
      {isValidElement<IconProps>(leftIcon)
        ? cloneElement(leftIcon, { size: 'sm', className: styles.leftIcon })
        : null}

      <span
        className={styles.text}
        id={`${itemId}text`}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {children}
      </span>
    </span>
  )
})

export default AutocompleteItemContent

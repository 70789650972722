import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCollection(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM2.225 13.299A3 3 0 0 1 5.21 10h13.58a3 3 0 0 1 2.985 3.299l-.6 6A3 3 0 0 1 18.19 22H5.81a3 3 0 0 1-2.985-2.701l-.6-6ZM5.21 12a1 1 0 0 0-.995 1.1l.6 6a1 1 0 0 0 .995.9h12.38a1 1 0 0 0 .995-.9l.6-6a1 1 0 0 0-.995-1.1H5.21ZM5 6a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCollection)

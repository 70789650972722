import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgListOrdered(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3a.5.5 0 0 0 0 1v2h-.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H6V3.5a.5.5 0 0 0-.5-.5H5Zm4 2a1 1 0 0 0 1 1h9a1 1 0 1 0 0-2h-9a1 1 0 0 0-1 1Zm1 8a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2h-9Zm0 7a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2h-9Zm-6-9.5a.5.5 0 0 1 .5-.5h1.25a1.25 1.25 0 1 1 0 2.5h-.5a.25.25 0 1 0 0 .5H6.5a.5.5 0 0 1 0 1H5.25a1.25 1.25 0 1 1 0-2.5h.5a.25.25 0 1 0 0-.5H4.5a.5.5 0 0 1-.5-.5Zm.5 6.5a.5.5 0 0 0 0 1h1.25a.25.25 0 1 1 0 .5H4.5a.5.5 0 0 0 0 1h1.25a.25.25 0 1 1 0 .5H4.5a.5.5 0 0 0 0 1h1.25a1.25 1.25 0 0 0 1-2 1.25 1.25 0 0 0-1-2H4.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgListOrdered)

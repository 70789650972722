import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './banner-description.module.scss'
import { useBanner } from './use-banner'

export type BannerDescriptionElement = HTMLParagraphElement

export interface BannerDescriptionProps extends HTMLAttributes<BannerDescriptionElement> {
  children: ReactNode
  className?: string
  asChild?: boolean
}

const BannerDescription = forwardRef<BannerDescriptionElement, BannerDescriptionProps>(
  function BannerDescription(props, forwardedRef) {
    const { className, children, asChild, ...rest } = props

    const { level } = useBanner()
    if (level === 'app') {
      return null
    }

    const Component = asChild ? Slot : 'p'

    const classes = classNames(styles.root, className)

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default BannerDescription

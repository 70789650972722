import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8 16.494 7.968-4.71L8 7.388v9.106Zm-2-10.8v12.553a1 1 0 0 0 1.509.861l10.984-6.493a1 1 0 0 0-.026-1.736L7.483 4.819A1 1 0 0 0 6 5.693Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPlay)

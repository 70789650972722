import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEarth(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.582 9A8 8 0 0 0 12 20v-4a1 1 0 0 1 1-1h3c1.116 0 2.09.61 2.606 1.513A7.963 7.963 0 0 0 20 12c0-1.06-.206-2.074-.581-3H15a3 3 0 0 0-3 3 1 1 0 0 1-1 1 5.002 5.002 0 0 1-4.9-4H4.582Zm1.173-2H7a1 1 0 0 1 1 1c0 1.354.896 2.498 2.128 2.871A5.002 5.002 0 0 1 15 7h3.245A7.985 7.985 0 0 0 12 4a7.993 7.993 0 0 0-6.245 3ZM14 19.748a7.974 7.974 0 0 0 3-1.503V18a1 1 0 0 0-1-1h-2v2.748ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEarth)

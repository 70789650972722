import { useEffect, useRef, useState } from 'react'

import type { DropdownMenuCheckboxItemProps } from './dropdown-menu-checkbox-item'
import type { DropdownMenuRadioGroupProps } from './dropdown-menu-radio-group'

export type DropdownStateBase = Record<string, unknown>

export type UseDropdownStateInput<State> = {
  defaultValue?: State
}

export type UseDropdownStateReturn<State> = {
  state: State
  setState: (state: State) => void
  getCheckboxItemProps: (
    key: keyof State
  ) => Pick<DropdownMenuCheckboxItemProps, 'checked' | 'onCheckedChange'>
  getRadioGroupProps: (
    key: keyof State
  ) => Pick<DropdownMenuRadioGroupProps, 'value' | 'onValueChange'>
  touched: boolean
}

export function useDropdownState<State extends DropdownStateBase>(
  props?: UseDropdownStateInput<State>
): UseDropdownStateReturn<State> {
  const { defaultValue } = props || {}
  const touched = useRef(false)
  const [state, setState] = useState<State>(defaultValue || ({} as State))

  useEffect(() => {
    touched.current = true
  }, [state])

  return {
    state,
    setState,
    touched: touched.current,
    getCheckboxItemProps(key) {
      return {
        checked: !!state[key],
        onCheckedChange(checked) {
          setState((prevState) => ({ ...prevState, [key]: checked }))
        },
      }
    },
    getRadioGroupProps(key) {
      return {
        value: state[key] as string,
        onValueChange(value) {
          setState((prevState) => ({ ...prevState, [key]: value }))
        },
      }
    },
  }
}

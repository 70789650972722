import { lazy, Suspense, useContext } from 'react'

import { TopbarContext } from '../../context'
import { TopbarA11YMenuProps } from './types'

const TopbarA11YMenuLazy = lazy(() => import('./topbar-a11y-menu'))

export const TopbarA11YMenu: React.FC<TopbarA11YMenuProps> = (props) => {
  const { mount } = useContext(TopbarContext)
  if (!mount) return null
  return (
    <Suspense fallback={null}>
      <TopbarA11YMenuLazy {...props} />
    </Suspense>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { EVENT_PROPERTIES_STORAGE_KEY, snowpiercerClient } from '../constants'
import { EventProperties } from '../types'
import { NamespacesStorageObject } from '../types/namespaces-storage-object'
import { getNamespacesObjectFromStorage } from './get-namespace-object-from-storage'

export async function storeEventPropertiesForNextPage(
  namespace: string,
  properties: EventProperties
) {
  const currentProperties: NamespacesStorageObject = await getNamespacesObjectFromStorage()

  currentProperties.timestamp = Date.now()
  currentProperties.namespaces = currentProperties.namespaces || {}
  currentProperties.namespaces[namespace] = properties

  await snowpiercerClient.setItem(EVENT_PROPERTIES_STORAGE_KEY, JSON.stringify(currentProperties))
}

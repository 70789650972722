import { ApolloError } from '@apollo/client'
import { BodyText, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useEffect, useState } from 'react'

import { useGetProfileByCPF, useValidateCPF } from '../../../hooks'
import validatingImgSrc from '../../../public/identity-validation-modal/validation.svg'
import { EventData } from '../../../utils'
import styles from './validating-identity.module.scss'

interface ValidatingIdentityStepProps {
  cpf: string
  birthDate: Date
  eventProps: {
    viewerPid: number
  } & EventData
  onSuccess: () => void
  onCpfInUse: (profileInUse: { pid: number; email: string | null | undefined }) => void
  onError: () => void
}

type CustomError = ApolloError & {
  graphQLErrors: ReadonlyArray<{
    info: {
      formattedKey: string
    }
  }>
}

export function ValidatingIdentityStep(props: ValidatingIdentityStepProps) {
  const { cpf, birthDate, eventProps, onSuccess, onCpfInUse, onError } = props

  const [loading, setLoading] = useState(false)

  const validateCPF = useValidateCPF()
  const getProfileByCPF = useGetProfileByCPF()

  useEffect(() => {
    const { viewerPid, ...eventData } = eventProps
    sendMetrics('UserAccount.ValidatingProfileViewed', viewerPid.toString(), {
      ...eventData,
      metadata: {
        ...eventData?.metadata,
        cpf: cpf,
        birthDate: formatDate(birthDate?.getTime() || ''),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && cpf && birthDate) {
      setLoading(true)
      validateCPF({ cpf, birthDate })
        .then(() => onSuccess())
        .catch(async (error: CustomError) => {
          const cpfInUse = error?.graphQLErrors?.some((e: { info: { formattedKey: string } }) => {
            return e.info.formattedKey === 'CPFInUse'
          })

          if (cpfInUse && cpf) {
            const response = await getProfileByCPF(cpf)
            const profile = response?.data?.root?.profileByValidatedCpf

            if (profile) {
              const profileInUse = {
                pid: profile.pid,
                email: profile.user?.obfuscatedEmail,
              }

              return onCpfInUse(profileInUse)
            }

            console.error('Error getting current profile using the cpf')
          }

          onError()
        })
    }
  }, [validateCPF, loading, getProfileByCPF, onSuccess, onError, onCpfInUse, cpf, birthDate])

  return (
    <>
      <Modal.Body className={styles.body} data-testid="validating-identity-step">
        <Image
          alt="Verificando identidade..."
          src={validatingImgSrc}
          height={200}
          width={200}
          className={styles.image}
        />
        <Heading size="lg" className={styles.title}>
          Verificando identidade...
        </Heading>
        <BodyText size="lg" className={styles.text}>
          Aguarde enquanto validamos as informações
        </BodyText>
      </Modal.Body>
    </>
  )
}

import { IncomingMessage } from 'http'
import { Cookie } from 'universal-cookie'

import { buildCookiesContext } from './build-cookies-context'

/**
 *
 * @param request If you are running this function in the server side, send the request object. If you are using the function in the client side, use null.
 * @param name Name of the cookie you want to access.
 * @returns The value of the cookie.
 *
 * @example
 *   getCookie(null, 'cookieName') // will return the cookie value as string
 *
 *   getCookies<number>(null, 'cookieName') // will return the cookie value as number
 *
 *  getCookies(req, 'cookieName') // will return the server side cookies as string
 */
export const getCookie = <K = Cookie>(request: IncomingMessage | null = null, name: string): K => {
  const cookies = buildCookiesContext({ request })

  return cookies.get<K>(name)
}

import { BodyText, Button, List, Spinner } from '@farol-ds/react'
import classNames from 'classnames'
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react'

import styles from './infinite-list.module.scss'

interface ItemProps {
  id: string | number
}

interface RetryConfigProps {
  show: boolean
  text?: string
  btnText?: string
}

interface IntermediateItemProps {
  renderComponent: (position: number) => ReactNode
  afterIndex: number
}

export interface InfiniteListProps<Item extends ItemProps> {
  items: Item[]
  hasNextPage: boolean
  loading: boolean
  retry: RetryConfigProps
  itemsThreshold?: number
  className?: string
  renderItem: (item: Item, idx: number) => ReactNode
  renderEmpty: () => ReactNode
  onLoadMore: () => void
  disableObserver?: boolean
  enableSpinner?: boolean
  intermediateItem?: IntermediateItemProps
}

const cx = classNames.bind(styles)

const DEFAULT_THRESHOLD = 1
const DEFAULT_RETRY_TEXT = 'Não foi possível carregar mais itens, tente novamente!'
const DEFAULT_RETRY_BUTTON_TEXT = 'CARREGAR MAIS'

export function InfiniteList<Item extends ItemProps>(props: InfiniteListProps<Item>) {
  const {
    items,
    hasNextPage,
    loading,
    retry,
    itemsThreshold,
    className,
    renderItem,
    renderEmpty,
    onLoadMore,
    disableObserver,
    enableSpinner,
    intermediateItem,
  } = props

  const [lastItem, setLastItem] = useState<Element | null>(null)
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    if (disableObserver) return

    observer.current = new IntersectionObserver((entries) => {
      const first = entries[0]
      if (first.isIntersecting) {
        onLoadMore()
      }
    })

    const currentObserver = observer.current
    const currentElement = lastItem

    if (currentElement) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastItem, onLoadMore, disableObserver])

  function isToShowEmpty() {
    return (!items || items.length === 0) && !loading
  }

  return (
    <div className={cx(styles.container, className)}>
      {!!items && items.length > 0 && (
        <List className={styles.list}>
          {items.map((item, idx) => {
            const threshold = itemsThreshold ? itemsThreshold : DEFAULT_THRESHOLD
            const last = items[items.length - threshold]
            const setReference =
              hasNextPage && last && last.id === item.id ? setLastItem : undefined

            const shouldRenderIntermediateItem =
              intermediateItem &&
              (intermediateItem.afterIndex === idx ||
                (items.length <= intermediateItem.afterIndex && idx === items.length - 1))

            return (
              <Fragment key={`${item.id}-${idx}`}>
                <List.Item ref={setReference}>{renderItem(item, idx)}</List.Item>
                {shouldRenderIntermediateItem && (
                  <List.Item>{intermediateItem.renderComponent(idx + 2)}</List.Item>
                )}
              </Fragment>
            )
          })}
        </List>
      )}

      {isToShowEmpty() && renderEmpty()}

      {enableSpinner && loading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}

      {retry.show && !loading && hasNextPage && (
        <div className={styles.retry}>
          <BodyText size="md" className={styles.retryText}>
            {retry.text ? retry.text : DEFAULT_RETRY_TEXT}
          </BodyText>
          <Button kind="tertiary" block onClick={onLoadMore} data-testid="retry-button">
            {retry.btnText ? retry.btnText : DEFAULT_RETRY_BUTTON_TEXT}
          </Button>
        </div>
      )}
    </div>
  )
}

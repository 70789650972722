import classNames from 'classnames'
import { forwardRef, LabelHTMLAttributes, ReactNode } from 'react'

import styles from './form-control-label.module.scss'
import { useFormControl } from './use-form-control'

export type FormControlLabelElement = HTMLLabelElement

export interface FormControlLabelProps extends LabelHTMLAttributes<FormControlLabelElement> {
  children: ReactNode
  className?: string
}

const FormControlLabel = forwardRef<FormControlLabelElement, FormControlLabelProps>(
  function FormControlLabel(props, forwardedRef) {
    const { className, children, ...rest } = props
    const formControl = useFormControl()

    const propsFromFormControl = formControl.getLabelProps?.(forwardedRef)
    const classes = classNames(styles.root, propsFromFormControl?.className, className)

    return (
      <label ref={forwardedRef} {...propsFromFormControl} {...rest} className={classes}>
        {children}
        {formControl?.required ? (
          <span className={styles.required} role="presentation" aria-hidden>
            *
          </span>
        ) : null}
      </label>
    )
  }
)

export default FormControlLabel

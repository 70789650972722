import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'
import { Checkbox, CheckboxProps } from './checkbox'
import styles from './checkbox-item.module.scss'

export type CheckboxItemElement = HTMLLabelElement

export interface CheckboxItemProps extends Partial<CheckboxProps> {
  children: ReactNode
  asChild?: boolean
  __group?: boolean
}

const CheckboxItem = forwardRef<CheckboxItemElement, CheckboxItemProps>(function CheckboxItem(
  props,
  forwardedRef
) {
  const { className, __group = false, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'label'

  const classes = classNames(
    styles.root,
    {
      [styles.disabled]: rest.disabled,
    },
    className
  )

  const content = (
    <Component className={classes} ref={forwardedRef}>
      {Children.map(children, (child) => {
        if (!isValidElement<CheckboxProps>(child)) return child
        if (child.type === Checkbox) {
          return cloneElement(child, rest)
        }
        return child
      })}
    </Component>
  )

  if (__group) return content
  return <span className={styles.inline}>{content}</span>
})

export default CheckboxItem

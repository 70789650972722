import { useAppRootContext } from '@jusbrasil-web/shared-app-root-provider'
import { FC, ReactNode } from 'react'

import { TOPBAR_NEXT_INTERNAL_KEY } from '../constants'
import { TopbarNextContext } from '../next-context'
import { WithTopbarPropsReturn } from '../types'

export type TopbarNextProviderProps = {
  children: ReactNode
  // TODO: remove pageProps when AppRootProvider replaces it completely. @felipemsantana
  pageProps: WithTopbarPropsReturn
}

export const TopbarNextProvider: FC<TopbarNextProviderProps> = (props) => {
  const { children, pageProps } = props

  const { getPageProp } = useAppRootContext<WithTopbarPropsReturn>()
  const value = getPageProp(TOPBAR_NEXT_INTERNAL_KEY) ||
    pageProps[TOPBAR_NEXT_INTERNAL_KEY] || { ctx: {} }
  value.provider = true

  return <TopbarNextContext.Provider value={value}>{children}</TopbarNextContext.Provider>
}

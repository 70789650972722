import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSort(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.707 3.293a1 1 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414L6 6.414V19a1 1 0 1 0 2 0V6.414l1.293 1.293a1 1 0 0 0 1.414-1.414l-3-3Zm13 14.414-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L16 17.586V5a1 1 0 1 1 2 0v12.586l1.293-1.293a1 1 0 0 1 1.414 1.414Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSort)

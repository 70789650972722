import * as RContextMenu from '@radix-ui/react-context-menu'
import { cloneElement, forwardRef, Fragment, isValidElement, ReactNode } from 'react'

import { MenuItem, MenuItemElement, MenuItemProps } from '../menu-item/menu-item'
import { Slot } from '../utilities'

export type ContextMenuItemElement = MenuItemElement

export type ContextMenuItemProps = Omit<MenuItemProps, 'type' | 'current' | 'block'> & {
  leftIcon?: ReactNode
}

const ContextMenuItem = forwardRef<ContextMenuItemElement, ContextMenuItemProps>(
  function ContextMenuItem(props, forwardedRef) {
    const { children, disabled, leftIcon, asChild, ...rest } = props

    const Component = asChild ? Slot : Fragment

    return (
      <RContextMenu.Item asChild>
        <MenuItem {...rest} block disabled={disabled} ref={forwardedRef} asChild={asChild}>
          <Component>{renderChildren(props)}</Component>
        </MenuItem>
      </RContextMenu.Item>
    )
  }
)

function renderChildren(props: ContextMenuItemProps): ReactNode {
  const { asChild, children } = props
  if (asChild && isValidElement<{ children: ReactNode }>(children)) {
    return cloneElement(children, {}, renderChild(children.props.children, props))
  }
  return renderChild(children, props)
}

function renderChild(children: ReactNode, props: ContextMenuItemProps) {
  const { leftIcon } = props
  return (
    <MenuItem.Content>
      {leftIcon && <MenuItem.Leading>{leftIcon}</MenuItem.Leading>}
      {children}
    </MenuItem.Content>
  )
}

export default ContextMenuItem

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import itemStyles from './radio-item.module.scss'
import styles from './radio-name.module.scss'

export type RadioNameElement = HTMLSpanElement

export interface RadioNameProps extends HTMLAttributes<RadioNameElement> {
  children: ReactNode
}

const RadioName = forwardRef<RadioNameElement, RadioNameProps>(function RadioName(
  props,
  forwardedRef
) {
  const { className, ...rest } = props

  const classes = classNames(styles.root, itemStyles.itemName, className)

  return <span {...rest} className={classes} ref={forwardedRef} />
})

export default RadioName

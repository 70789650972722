import { forwardRef, useEffect, useState } from 'react'

import { CheckboxItemProps as TCheckboxItemProps } from '../checkbox/checkbox'
import { TreeNode, useTreeData } from './helpers'
import TreeView, { TreeViewElement, TreeViewProps } from './tree-view-root'

type CheckboxItemProps = Omit<TCheckboxItemProps, 'children'>

export type TreeViewCheckboxValueType = string[]
export type TreeViewCheckboxElement = TreeViewElement
export type TreeViewCheckboxProps = TreeViewProps<TreeViewCheckboxValueType, CheckboxItemProps> & {
  defaultIndeterminate?: string[]
}

type NodeData = {
  indeterminate: boolean
}

const TreeViewCheckbox = forwardRef<TreeViewCheckboxElement, TreeViewCheckboxProps>(
  function TreeViewCheckbox(props, forwardedRef) {
    const { defaultSelected = [], defaultIndeterminate = [], ...rest } = props
    const [selected, setSelected] = useState<TreeViewCheckboxValueType>(defaultSelected)
    const tree = useTreeData<NodeData>(props)

    function isSelected(nodeId: string): boolean {
      return selected.includes(nodeId)
    }

    function isIndeterminate(nodeId: string): boolean {
      const node = tree.index[nodeId]
      return node?.data.indeterminate ?? defaultIndeterminate.includes(nodeId)
    }

    function isVisualSelected(nodeId: string): boolean {
      const indeterminate = isIndeterminate(nodeId)
      return indeterminate || isSelected(nodeId)
    }

    function onSelectedChange(nodeId: string): void {
      const node = tree.index[nodeId]
      if (!node) return
      const nextSelected = Array.from(evaluateNodeIDs(node, new Set(selected)))
      setSelected(nextSelected)
      props.onSelectedChange?.(nodeId, nextSelected)
    }

    useEffect(() => {
      const hasChanged = rehydrateBranchNodes(tree.node, defaultSelected)
      if (hasChanged) {
        setSelected([...defaultSelected])
      }
    }, [tree.node, defaultSelected])

    return (
      <TreeView<TreeViewCheckboxValueType, CheckboxItemProps>
        {...rest}
        isSelected={isSelected}
        isVisualSelected={isVisualSelected}
        onSelectedChange={onSelectedChange}
        getItemProps={(nodeId, prevProps) => ({
          checked: isVisualSelected(nodeId),
          indeterminate: isIndeterminate(nodeId),
          onClick: (event) => {
            onSelectedChange(nodeId)
            prevProps.onClick?.(event)
          },
        })}
        ref={forwardedRef}
      />
    )
  }
)

function rehydrateBranchNodes(node: TreeNode<NodeData>, ids: string[]): boolean {
  if (node.children.length === 0) return false

  node.children.forEach((child) => {
    rehydrateBranchNodes(child, ids)
  })

  let hasIndeterminateChildren = false
  const selecteds = node.children.filter((child) => {
    if (child.data.indeterminate) {
      hasIndeterminateChildren = true
    }
    if (child.id === null) return true
    return ids.includes(child.id)
  })
  const allSelected = selecteds.length === node.children.length
  const allUnselected = selecteds.length === 0
  const prevIndeterminate = node.data.indeterminate

  node.data.indeterminate = (!allSelected && !allUnselected) || hasIndeterminateChildren

  return prevIndeterminate !== node.data.indeterminate
}

function evaluateNodeIDs(node: TreeNode<NodeData>, ids: Set<string>): Set<string> {
  if (node.id === null) return ids

  // reset
  node.data.indeterminate = false

  // self-check or self-uncheck
  const toAdd = !ids.has(node.id)
  if (toAdd) {
    ids.add(node.id)
  } else {
    ids.delete(node.id)
  }

  // auto-check or auto-uncheck children deeply
  function autoDeepChildren(node: TreeNode<NodeData>, toAdd: boolean): void {
    node.children.forEach((child) => {
      if (!child.id) return
      // reset
      child.data.indeterminate = false
      if (toAdd) {
        ids.add(child.id)
      } else {
        ids.delete(child.id)
      }
      if (child.children.length > 0) {
        autoDeepChildren(child, toAdd)
      }
    })
  }
  autoDeepChildren(node, toAdd)

  // auto-check or auto-uncheck parent based on children selection
  function autoParent(node: TreeNode<NodeData>) {
    if (node !== null && node.id !== null && node.children.length > 0) {
      let hasIndeterminateChildren = false
      const selecteds = node.children.filter((child) => {
        if (child.data.indeterminate) {
          hasIndeterminateChildren = true
        }
        if (child.id === null) return true
        return ids.has(child.id)
      })
      const allSelected = selecteds.length === node.children.length
      const allUnselected = selecteds.length === 0
      if (allSelected) {
        ids.add(node.id)
      } else {
        ids.delete(node.id)
      }
      node.data.indeterminate = hasIndeterminateChildren || (!allSelected && !allUnselected)
    }
    if (node.parent !== null) {
      autoParent(node.parent)
    }
  }
  autoParent(node)

  return ids
}

export default TreeViewCheckbox

import { Button, Textarea, TextareaElement } from '@farol-ds/react'
import { useEffect, useRef, useState } from 'react'

import styles from './comment.module.scss'

export type CommentStepProps = {
  placeholder: string
  onSubmit: (comment: string) => void
  isMobile?: boolean
}

export const CommentStep = ({ placeholder, onSubmit, isMobile }: CommentStepProps) => {
  const [comment, setComment] = useState('')

  const handleCommentChange = (_: unknown, value: string) => {
    setComment(value)
  }

  const handleCommentSubmit = () => {
    onSubmit(comment)
  }

  const inputRef = useRef<TextareaElement | null>(null)

  useEffect(() => {
    if (isMobile) {
      inputRef?.current?.focus()
    }
  }, [inputRef, isMobile])

  return (
    <>
      <div className={styles.comment}>
        <Textarea ref={inputRef} onValueChange={handleCommentChange} placeholder={placeholder} />
      </div>
      <Button
        block
        className={styles.button}
        onClick={handleCommentSubmit}
        disabled={comment.trim().length === 0}
        size="md"
      >
        Enviar
      </Button>
    </>
  )
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './radio-content.module.scss'
import itemStyles from './radio-item.module.scss'

export type RadioContentElement = HTMLSpanElement

export interface RadioContentProps extends HTMLAttributes<RadioContentElement> {
  children: ReactNode
}

const RadioContent = forwardRef<RadioContentElement, RadioContentProps>(function RadioContent(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, itemStyles.itemContent, className)

  return (
    <span ref={forwardedRef} {...rest} className={classes}>
      {children}
    </span>
  )
})

export default RadioContent

import { RefObject, useCallback, useRef } from 'react'

import { useIsomorphicLayoutEffect } from '../../functions'
import { attachArgosListener, handleMetricEvent } from '../../utils'

export type SendMetrics = (
  name: string,
  userId?: string | null,
  properties?: Record<string, unknown>
) => void

export type UseDispatcherReturnType<T extends HTMLElement> = {
  ref: RefObject<T>
  sendMetrics: SendMetrics
}

export function useDispatcher<T extends HTMLElement>(): UseDispatcherReturnType<T> {
  const ref = useRef<T>(null)

  const sendMetrics = useCallback<SendMetrics>((name, userId, properties) => {
    handleMetricEvent(name, userId, properties, ref.current)
  }, [])

  // must use "useLayoutEffect" hook to add listener before all dispatches
  useIsomorphicLayoutEffect(() => {
    attachArgosListener()
  }, [])

  return { sendMetrics, ref }
}

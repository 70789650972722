import classNames from 'classnames'
import { forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { isValidLinkElement } from '../utilities/internal'
import { Slot } from '../utilities/slot/slot'
import styles from './card.module.scss'

export type CardElement = HTMLDivElement

export interface CardProps extends HTMLAttributes<CardElement> {
  children: ReactNode
  disablePadding?: boolean
  disabled?: boolean
  highlight?: boolean
  className?: string
  asChild?: boolean
}

export const Card = forwardRef<CardElement, CardProps>(function Card(props, forwardedRef) {
  const {
    className,
    children,
    disablePadding = false,
    disabled = false,
    highlight = false,
    asChild: asChildProp,
    ...rest
  } = props

  const asChild = asChildProp && isValidElement(children)
  const isLink = asChild && isValidLinkElement(children)
  const Component = asChild ? Slot : 'div'

  const clickable = !!props.onClick || isLink

  const isButtonRole = clickable && !isLink

  const classes = classNames(
    styles.root,
    {
      [styles.highlight]: highlight,
      [styles.disablePadding]: disablePadding,
      [styles.clickable]: clickable,
      [styles.disabled]: disabled,
    },
    className
  )

  const disabledProp = !isButtonRole && disabled ? { disabled: true } : {}

  return (
    <Component
      ref={forwardedRef}
      role={isButtonRole ? 'button' : undefined}
      tabIndex={isButtonRole ? 0 : undefined}
      aria-disabled={(isButtonRole && disabled) || undefined}
      {...disabledProp}
      {...rest}
      className={classes}
    >
      {children}
    </Component>
  )
})

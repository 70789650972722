import classNames from 'classnames'
import { forwardRef } from 'react'
import { SetOptional } from 'type-fest'

import { CloseIcon } from '../icon/icon'
import { IconSize } from '../icon/types'
import { IconButton, IconButtonElement, IconButtonProps } from '../icon-button/icon-button'
import BannerClose from './banner-close'
import styles from './banner-icon-close.module.scss'
import { useBanner } from './use-banner'

export type BannerIconCloseElement = IconButtonElement

export type BannerIconCloseProps = Omit<
  SetOptional<IconButtonProps, 'label'>,
  'icon' | 'asChild' | 'children'
>

const iconSize: IconSize = 'md'

const BannerIconClose = forwardRef<BannerIconCloseElement, BannerIconCloseProps>(
  function BannerIconClose(props, forwardedRef) {
    const { className, ...rest } = props

    const { getIconCloseProps, level } = useBanner()

    const classes = classNames(
      styles.root,
      {
        [styles[`level${level}`]]: level !== 'standard',
      },
      className
    )

    return (
      <BannerClose>
        <IconButton
          ref={forwardedRef}
          label="Fechar"
          {...rest}
          {...getIconCloseProps?.(forwardedRef)}
          icon={<CloseIcon />}
          size={iconSize}
          kind="plain"
          className={classes}
        />
      </BannerClose>
    )
  }
)

export default BannerIconClose

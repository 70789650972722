import { ResponsiveValue, SxProp } from './types'

type Config = {
  styles: Record<string, string>
  classMap: Record<string, string>
  breakpoints: readonly string[]
}

export const transformPropToClass = <K extends string>(
  propValue: ResponsiveValue<K>,
  propName: SxProp,
  { styles, classMap, breakpoints }: Config
): string[] => {
  const shortPropName = classMap[propName] || propName
  if (Array.isArray(propValue)) {
    return propValue
      .map((value, index) => {
        if (value === undefined) return ''
        const bp = index !== 0 ? `-${breakpoints[index]}` : ''
        return styles[`${shortPropName}-${value}` + bp]
      })
      .filter(Boolean)
  } else if (typeof propValue === 'object') {
    return Object.entries(propValue).map(([breakpoint, value]) => {
      const bp = breakpoint !== 'xs' ? `-${breakpoint}` : ''
      return styles[`${shortPropName}-${value}${bp}`]
    })
  } else {
    return [styles[`${shortPropName}-${propValue}`]]
  }
}

import { spacing5xs } from '@farol-ds/tokens'
import type { DropdownMenuContentProps as RDropdownMenuContentProps } from '@radix-ui/react-dropdown-menu'
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { Heading } from '../heading/heading'
import { CloseIcon } from '../icon/icon'
import { IconButton } from '../icon-button/icon-button'
import { Overlay } from '../overlay/overlay'
import { Separator } from '../separator/separator'
import { ensurePropOf } from '../utilities'
import styles from './dropdown-menu.module.scss'
import DropdownMenuItem from './dropdown-menu-item'

export type DropdownMenuElement = HTMLDivElement
export const DropdownPlacements = ['start', 'center', 'end'] as const
export type DropdownPlacement = (typeof DropdownPlacements)[number]
export interface DropdownMenuProps extends Omit<RDropdownMenuContentProps, 'align'> {
  placement?: DropdownPlacement
  title?: string
}

const DropdownMenu = forwardRef<DropdownMenuElement, DropdownMenuProps>(function DropdownMenu(
  props,
  forwardedRef
) {
  const { title, className, children, placement: placementProp, ...rest } = props
  const placement = ensurePropOf<DropdownPlacement>(DropdownPlacements, placementProp, 'center')
  const classes = classNames(styles.root, className)
  return (
    <RDropdownMenu.Portal>
      <>
        <Overlay data-fds-dropdown-menu-overlay />

        <RDropdownMenu.Content
          data-testid="dropdown-menu"
          {...rest}
          align={placement}
          sideOffset={spacing5xs}
          className={classes}
          ref={forwardedRef}
        >
          <div className={styles.header}>
            {title ? (
              <Heading
                data-testid="dropdown-menu-header-title"
                size="sm"
                className={styles.headerTitle}
              >
                {title}
              </Heading>
            ) : null}

            <DropdownMenuItem>
              <IconButton
                label="Fechar"
                icon={<CloseIcon />}
                size="sm"
                tooltipProps={{ open: false }}
              />
            </DropdownMenuItem>
          </div>

          <Separator className={styles.headerSeparator} />

          <div className={styles.container}>{children}</div>
        </RDropdownMenu.Content>
      </>
    </RDropdownMenu.Portal>
  )
})

export default DropdownMenu

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgAttachment(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.596 4.743a2.536 2.536 0 0 0-3.586 0l-7.75 7.75a4.303 4.303 0 1 0 6.086 6.085l4.25-4.25a1 1 0 0 1 1.414 1.415l-4.25 4.25a6.303 6.303 0 1 1-8.914-8.915l7.75-7.75a4.536 4.536 0 1 1 6.414 6.415l-7.75 7.75a2.768 2.768 0 1 1-3.914-3.915l6.247-6.245a1 1 0 1 1 1.414 1.414L8.76 14.993a.768.768 0 0 0 1.086 1.085l7.75-7.75c.99-.99.99-2.595 0-3.585Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgAttachment)

import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import { Slot } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { SelectionCardContext } from './context'
import styles from './selection-card.module.scss'
import SelectionCardBody from './selection-card-body'
import SelectionCardBodyContent from './selection-card-body-content'
import SelectionCardBodyContentDescription from './selection-card-body-content-description'
import SelectionCardBodyContentTitle from './selection-card-body-content-title'
import SelectionCardBodyIcon from './selection-card-body-icon'
import SelectionCardControl from './selection-card-control'
import { SelectionCardOrientation } from './types'

export type SelectionCardElement = HTMLButtonElement

export interface SelectionCardProps extends ButtonHTMLAttributes<SelectionCardElement> {
  checked?: boolean
  orientation?: SelectionCardOrientation
  onCheckedChange?: (checked: boolean) => void
  asChild?: boolean
}

const SelectionCardRoot = forwardRef<SelectionCardElement, SelectionCardProps>(
  function SelectionCard(props, forwardedRef) {
    const {
      className,
      checked,
      disabled,
      children,
      asChild,
      onClick,
      onCheckedChange,
      orientation = 'horizontal',
      ...rest
    } = props

    const classes = classNames(
      styles.root,
      styles[orientation],
      { [styles.checked]: checked, [styles.disabled]: disabled },
      className
    )

    const Component = asChild ? Slot : 'button'

    function handleClick(event: React.MouseEvent<SelectionCardElement>) {
      onClick?.(event)
      onCheckedChange?.(!checked)
    }

    return (
      <SelectionCardContext.Provider value={{ orientation, disabled }}>
        <Component {...rest} onClick={handleClick} className={classes} ref={forwardedRef}>
          {children}
        </Component>
      </SelectionCardContext.Provider>
    )
  }
)

export const SelectionCard = assignSubComponents('SelectionCard', SelectionCardRoot, {
  Body: SelectionCardBody,
  BodyIcon: SelectionCardBodyIcon,
  BodyContent: SelectionCardBodyContent,
  BodyContentTitle: SelectionCardBodyContentTitle,
  BodyContentDescription: SelectionCardBodyContentDescription,
  Control: SelectionCardControl,
})

export * from './context'
export * from './selection-card-body'
export * from './selection-card-body-content'
export * from './selection-card-body-content-description'
export * from './selection-card-body-content-title'
export * from './selection-card-body-icon'
export * from './selection-card-control'
export * from './types'

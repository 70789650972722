type CommonProps = {
  cancelProps?: {
    disabled?: boolean
  }
}

export type PreventEscapeKeyDownCondition<P extends CommonProps> = (props: P) => boolean

export function commonPreventEscapeKeyDownCondition<P extends CommonProps>(props: P): boolean {
  const { cancelProps } = props
  if (cancelProps?.disabled) {
    return true
  }
  return false
}

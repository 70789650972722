import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUndo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9a1 1 0 0 1 1-1h9.5c1.954 0 3.464.564 4.597 1.48 1.12.905 1.801 2.095 2.218 3.24.416 1.146.584 2.29.65 3.165a14.63 14.63 0 0 1 .039 1.46l-.003.111v.034L21 17.5V20a1 1 0 1 1-2 0v-2.5c0-.027 0-.066.002-.117.012-.527.05-2.28-.567-3.979-.333-.916-.84-1.758-1.595-2.368C16.1 10.436 15.046 10 13.5 10H4a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.707 3.293a1 1 0 0 1 0 1.414L5.414 9l4.293 4.293a1 1 0 0 1-1.414 1.414l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 0 1 1.414 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUndo)

import {
  ChevronDownIcon,
  Col,
  Container,
  Dropdown,
  Link,
  Menu,
  MenuElement,
  MenuItem,
  MenuItemElement,
  Row,
} from '@farol-ds/react'
import { LineScroll, useLineScroll } from '@jusbrasil-web/shared-line-scroll'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import classNames from 'classnames'
import { FC, ReactNode, useContext, useRef } from 'react'

import { TopbarContext } from '../../context'
import { mountNavItems } from './constants'
import styles from './topbar-nav.module.scss'

export interface TopbarNavProps {
  children?: ReactNode
}

export const TopbarNav: FC<TopbarNavProps> = (props) => {
  const { children } = props
  const { variant, currentNavKey = 'HOME', logged, flags, user } = useContext(TopbarContext)
  const lineScrollCurrentItemRef = useRef<MenuItemElement>(null)
  const lineScrollListRef = useRef<MenuElement>(null)

  const {
    boundaryRef: lineScrollBoundaryRef,
    arrowLeftRef: lineScrollArrowLeftRef,
    arrowRightRef: lineScrollArrowRightRef,
    scrollToDirection,
    captureTargetRef,
  } = useLineScroll({
    listRef: lineScrollListRef,
    currentItemRef: lineScrollCurrentItemRef,
  })
  const navItems = mountNavItems(logged, flags)

  if (variant === 'slim') {
    return null
  }

  const onClick = (e: React.MouseEvent) => {
    const anchorElement = e.target as HTMLAnchorElement
    sendMetrics('Topbar.NavbarLinkClick', user?.pid?.toString(), {
      title: anchorElement && anchorElement.textContent,
      design_system: 'farol',
    })
  }

  return (
    <Container unstable_spacing={{ xs: 'full' }}>
      <Row>
        <Col className={classNames(styles.root)}>
          {children || (
            <LineScroll>
              <LineScroll.ArrowLeft
                ref={lineScrollArrowLeftRef}
                scrollToDirection={scrollToDirection}
              />
              <LineScroll.Boundary ref={lineScrollBoundaryRef}>
                <Menu className={styles.menu} orientation="horizontal" ref={lineScrollListRef}>
                  {navItems.map((item) => {
                    const current = currentNavKey === item.key

                    return item.submenu ? (
                      <LineScroll.DropdownGuard>
                        <Dropdown>
                          <Dropdown.Trigger>
                            <MenuItem
                              current={current}
                              ref={captureTargetRef}
                              key={item.url}
                              block={false}
                              onClick={onClick}
                            >
                              <MenuItem.Content>
                                {item.title}
                                <MenuItem.Trailing>
                                  <ChevronDownIcon />
                                </MenuItem.Trailing>
                              </MenuItem.Content>
                            </MenuItem>
                          </Dropdown.Trigger>
                          <Dropdown.Menu title={item.title}>
                            {item.submenu.map((subitem, key) => (
                              <Dropdown.MenuItem key={key}>
                                <MenuItem>
                                  <Link
                                    type="ghost"
                                    href={subitem.url || ''}
                                    className={styles.dropdownItemLink}
                                    onClick={onClick}
                                  >
                                    <MenuItem.Content>{subitem.title}</MenuItem.Content>
                                  </Link>
                                </MenuItem>
                              </Dropdown.MenuItem>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </LineScroll.DropdownGuard>
                    ) : (
                      <MenuItem current={current} ref={captureTargetRef} key={item.url} asChild>
                        <a href={item.url} onClick={onClick}>
                          <MenuItem.Content>{item.title}</MenuItem.Content>
                        </a>
                      </MenuItem>
                    )
                  })}
                </Menu>
              </LineScroll.Boundary>
              <LineScroll.ArrowRight
                ref={lineScrollArrowRightRef}
                scrollToDirection={scrollToDirection}
              />
            </LineScroll>
          )}
        </Col>
      </Row>
    </Container>
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Zm3-1a1 1 0 0 0-1 1v14c0 .298.13.566.337.749l9.542-9.542a3 3 0 0 1 4.242 0L20 12.086V5a1 1 0 0 0-1-1H5Zm14 16H6.914l8.379-8.379a1 1 0 0 1 1.414 0L20 14.914V19a1 1 0 0 1-1 1ZM8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgImage)

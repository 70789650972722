import classNames from 'classnames'
import { forwardRef } from 'react'

import { Autocomplete, AutocompleteTriggerProps } from '../autocomplete/autocomplete'
import { ArrowLeftIcon } from '../icon/icons'
import { Search, SearchElement, SearchProps } from '../search/search'
import type { SearchAutocompleteType } from './search-autocomplete'
import styles from './search-autocomplete-trigger.module.scss'

export type SearchAutocompleteTriggerElement = SearchElement
export type SearchAutocompleteTriggerProps = Partial<AutocompleteTriggerProps> & {
  type?: SearchAutocompleteType
  searchProps?: Partial<SearchProps>
  onBackClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  backIconRef?: React.Ref<HTMLButtonElement>
  __leftIconClassName?: string
}

const SearchAutocompleteTrigger = forwardRef<
  SearchAutocompleteTriggerElement,
  SearchAutocompleteTriggerProps
>(function SearchAutocompleteTrigger(props, forwardedRef) {
  const {
    type,
    searchProps,
    onBackClick,
    backIconRef,
    __leftIconClassName: leftIconClassName,
    children,
    ...rest
  } = props
  return (
    <Autocomplete.Trigger {...rest} ref={forwardedRef}>
      <Search
        autoComplete="off"
        {...searchProps}
        __trailing={
          type === 'primary' ? (
            <div className={classNames(styles.backIconArea, leftIconClassName)}>
              <button
                type="button"
                aria-label="Fechar pesquisa"
                className={styles.backIcon}
                onClick={onBackClick}
                ref={backIconRef}
              >
                <ArrowLeftIcon size="sm" />
              </button>
            </div>
          ) : null
        }
        type={type}
      >
        {children}
      </Search>
    </Autocomplete.Trigger>
  )
})

export default SearchAutocompleteTrigger

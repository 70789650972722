import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSortDesc(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4a1 1 0 1 0-2 0v13.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414L19 17.586V4ZM3 4a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2H3Zm-1 8a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm1 6a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2H3Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSortDesc)

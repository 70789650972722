import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNotificationOff(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.07V3a1 1 0 1 0-2 0v1.07A7.002 7.002 0 0 0 5 11v6.586l-.707.707a1 1 0 1 0 1.414 1.414l1-1L17.42 7.994l.001-.001 2.286-2.286a1 1 0 0 0-1.414-1.414l-1.555 1.554A6.98 6.98 0 0 0 13 4.071Zm2.322 3.193A5 5 0 0 0 7 11v4.586l8.322-8.323ZM14 21a1 1 0 0 1-1 1h-2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1Zm4-10a1 1 0 0 1 1 1v2.664c0 .348.103.688.296.977l.536.804A1 1 0 0 1 19 18h-6a1 1 0 1 1 0-2h4.245A3.76 3.76 0 0 1 17 14.664V12a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNotificationOff)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgQuestionAnswer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-5a1 1 0 0 0-.707.293L8 14.586V14a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1Zm10-2H5a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h1v2a1 1 0 0 0 1.707.707L10.414 15H15a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm5 5a1 1 0 1 1 2 0v9a3 3 0 0 1-3 3h-1v2a1 1 0 0 1-1.707.707l-3-3a1 1 0 0 1 1.414-1.414L16 18.586V18a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgQuestionAnswer)

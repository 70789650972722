import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, useState } from 'react'

import { Caption } from '../caption/caption'
import { InformationIcon } from '../icon/icon'
import { Popover } from '../popover/popover'
import { ensurePropOf, useDebounceCallback } from '../utilities'
import styles from './information.module.scss'

export const InformationPlacements = ['top', 'right', 'bottom', 'left', 'none'] as const
export type InformationPlacement = (typeof InformationPlacements)[number]
export const InformationSizes = ['xs', 'sm'] as const
export type InformationSize = (typeof InformationSizes)[number]
export type InformationElement = HTMLButtonElement

export interface InformationProps extends ButtonHTMLAttributes<InformationElement> {
  label: string
  size?: InformationSize
  onColor?: boolean
  placement?: InformationPlacement
  // popover
  open?: boolean
  onOpenChange?: (open: boolean) => void
  defaultOpen?: boolean
  modal?: boolean
}

export const Information = forwardRef<InformationElement, InformationProps>(function Information(
  props,
  forwardedRef
) {
  const {
    className,
    label,
    size: sizeProp,
    placement: placementProp,
    onColor = false,
    open: openProp,
    onOpenChange: onOpenChangeProp,
    defaultOpen,
    modal,
    ...rest
  } = props

  const placement = ensurePropOf<InformationPlacement>(
    InformationPlacements,
    placementProp,
    'right'
  )
  const size = ensurePropOf<InformationSize>(InformationSizes, sizeProp, 'xs')

  const [_open, _setOpen] = useState(defaultOpen ?? false)
  const open = openProp ?? _open

  const setOpen = (open: boolean) => {
    _setOpen(open)
    onOpenChangeProp?.(open)
  }

  const handleMouseEnter = useDebounceCallback(() => {
    setOpen(true)
  }, 200)

  const handleMouseLeave = () => {
    handleMouseEnter.cancel()
    setOpen(false)
  }

  const classes = classNames(
    styles.root,
    {
      [styles.sm]: size === 'sm',
      [styles.onColor]: onColor,
      [styles[placement]]: placement !== 'none',
    },
    className
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <button {...rest} className={classes} ref={forwardedRef}>
          <InformationIcon className={styles.icon} size={size} />
        </button>
      </Popover.Trigger>
      <Popover.Content className={styles.popover}>
        <Caption>{label}</Caption>
      </Popover.Content>
    </Popover>
  )
})

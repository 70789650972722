import { gql } from '@apollo/client'
import {
  Button,
  Card,
  CourtIcon,
  Detail,
  EyeIcon,
  Flex_unstable as Flex,
  Heading,
  IaSkillLegalResearchIcon,
  ScalesIcon,
  ShieldIcon,
} from '@farol-ds/react'
import classNames from 'classnames'

import { formatKind, getLawsuitCourt } from '../../utils/'
import { formatThirdPartyCardTitle } from '../../utils/data-format/format-third-party-card-title/format-third-party-card-title'
import { LawsuitDateChip } from '../lawsuit-date-chip'
import { LawsuitProgressChip } from '../lawsuit-progress-chip'
import { LawsuitCardThirdParty_LawsuitFragment } from './__generated__/lawsuit-card-third-party.graphql'
import styles from './lawsuit-card-third-party.module.scss'
import { LawsuitSummaryGeneratingModal } from './lawsuit-summary-modal'
import { ModalRelatedLawsuit } from './modal-related-lawsuit'

export interface LawsuitCardThirdPartyProps {
  lawsuit: LawsuitCardThirdParty_LawsuitFragment
  isMobile?: boolean
  className?: string
  label?: string
  subtitle?: string | React.ReactNode
  onClick?: () => void
  onSubmitSummarizationSurvey?: (surveyData: any) => void
  showProgress?: boolean
  showDate?: boolean
  showArea?: boolean
  showRestrictLawsuitDisclaimer?: boolean
  summarizationExperiment?: {
    isParticipating?: boolean
    shouldSeeSummaryButton?: boolean
    variant?: string
  }
  onSendEvent?: (event: any, metadata: any) => void
}
export type LawsuitCardThirdPartyType = LawsuitCardThirdPartyProps['lawsuit']

const getLawsuitUrl = (url: string | null): string => {
  if (!url) {
    return '#'
  }
  return url
}

export function LawsuitCardThirdParty(props: LawsuitCardThirdPartyProps) {
  const {
    lawsuit,
    isMobile,
    className,
    subtitle,
    onClick,
    onSubmitSummarizationSurvey,
    showProgress = true,
    showDate = true,
    showArea = true,
    showRestrictLawsuitDisclaimer = false,
    summarizationExperiment,
    onSendEvent,
  } = props

  const {
    number,
    subject,
    title,
    classifiedKindByNumber,
    cnjNumber,
    url,
    distributionDate,
    typification,
    normalizedSubject,
    restrictLawsuit,
    canViewRestrictLawsuit,
  } = lawsuit || {}
  const year = cnjNumber?.data?.year

  const titleTypification = typification?.[0]?.tipo_de_ocorrencia || ''

  const lawsuitUrl = getLawsuitUrl(url)
  const extendedCourt = getLawsuitCourt(cnjNumber, restrictLawsuit, canViewRestrictLawsuit)

  const formattedTitle = formatThirdPartyCardTitle(
    title,
    number,
    titleTypification,
    classifiedKindByNumber,
    normalizedSubject,
    restrictLawsuit,
    canViewRestrictLawsuit
  )

  const ariaLabelledbyDescription = `Exibir ${
    title?.toLowerCase().includes('processo') ? title : `processo ${title}`
  }`

  const area = formatKind(subject, classifiedKindByNumber)

  const onCardClick = () => {
    onClick?.()
  }

  function LinkOrDiv({
    className,
    useDiv,
    lawsuitUrl,
    ariaLabelledbyDescription,
    onCardClick,
    children,
    ...rest
  }: {
    className?: string
    useDiv?: boolean
    lawsuitUrl: string
    ariaLabelledbyDescription: string
    onCardClick: () => void
    children: React.ReactNode
  }) {
    if (useDiv) {
      return (
        <div className={className} {...rest} data-testid="lawsuit-card-third-party-div">
          {children}
        </div>
      )
    }
    return (
      <a
        className={className}
        href={lawsuitUrl}
        aria-labelledby={ariaLabelledbyDescription}
        onClick={onCardClick}
        {...rest}
      >
        {children}
      </a>
    )
  }

  function getLawsuitSummarizationButtonLabel() {
    switch (summarizationExperiment?.variant) {
      case 'variantA':
        return 'Sobre o que é o processo?'
      case 'variantB':
        return 'Ver resumo'
      case 'variantC':
        return 'Resumo rápido'
      case 'variantD':
        return 'Resumo simplificado'
    }
  }

  return (
    <Card
      className={classNames(styles.card, className)}
      data-testid="lawsuit-card-third-party"
      asChild
    >
      <LinkOrDiv
        useDiv={summarizationExperiment?.isParticipating}
        lawsuitUrl={lawsuitUrl}
        ariaLabelledbyDescription={ariaLabelledbyDescription}
        onCardClick={onCardClick}
      >
        {showProgress && (
          <LawsuitProgressChip lawsuit={lawsuit} className={styles.progress} thirdPartySearch />
        )}
        {showDate && (
          <LawsuitDateChip date={distributionDate} year={year} className={styles.chipDate} />
        )}

        <Heading size="md" asChild>
          <h2 className={styles.title} data-testid="lawsuit-card-title-third-party">
            {formattedTitle}
          </h2>
        </Heading>

        <Flex className={styles.flexDetails} direction="column" gap="4xs">
          {subtitle && (
            <Detail inline data-testid="lawsuit-card-details-subtitle-third-party">
              <Detail.Value>{subtitle}</Detail.Value>
            </Detail>
          )}

          {showArea && area && (
            <Detail
              inline
              className={classNames(styles.details, styles.truncate)}
              data-testid="lawsuit-card-details-area-third-party"
            >
              <Detail.Icon>
                <ScalesIcon size="sm" />
              </Detail.Icon>
              <Detail.Value>{area}</Detail.Value>
            </Detail>
          )}

          <Detail
            inline
            className={styles.details}
            data-testid="lawsuit-card-details-court-third-party"
          >
            <Detail.Icon>
              <CourtIcon size="sm" />
            </Detail.Icon>
            <Detail.Value className={styles.details}>{extendedCourt}</Detail.Value>
          </Detail>
          {showRestrictLawsuitDisclaimer && restrictLawsuit && (
            <Detail
              inline
              className={classNames(styles.details, styles.truncate)}
              data-testid="lawsuit-card-details-restrict"
            >
              <Detail.Icon>
                <ShieldIcon size="sm" />
              </Detail.Icon>
              <Detail.Value>
                <strong>Processo restrito</strong> · visível só para você
              </Detail.Value>
            </Detail>
          )}
        </Flex>
        <div className={styles.buttonContainer}>
          <LinkOrDiv
            className={styles.lawsuitCardButton}
            useDiv={!summarizationExperiment?.isParticipating}
            lawsuitUrl={lawsuitUrl}
            ariaLabelledbyDescription={ariaLabelledbyDescription}
            onCardClick={onCardClick}
          >
            <Button
              className={classNames(styles.lawsuitCardButton, styles.lawsuitListAction)}
              data-testid="lawsuit-card-old-action-btn"
              kind="tertiary"
              leftIcon={<EyeIcon size="sm" />}
            >
              Ver processo
            </Button>
          </LinkOrDiv>
          {summarizationExperiment?.shouldSeeSummaryButton && (
            <LawsuitSummaryGeneratingModal
              lawsuit={lawsuit}
              isMobile={isMobile}
              formattedTitle={formattedTitle}
              onSubmitSummarizationSurvey={onSubmitSummarizationSurvey}
              onSendEvent={onSendEvent}
              TriggerButtonComponent={
                <Button
                  className={styles.lawsuitCardButton}
                  kind="tertiary"
                  leftIcon={<IaSkillLegalResearchIcon size="sm" />}
                  data-testid="lawsuit-card-third-party-summary-button"
                >
                  {getLawsuitSummarizationButtonLabel()}
                </Button>
              }
            />
          )}
        </div>
      </LinkOrDiv>
    </Card>
  )
}

LawsuitCardThirdParty.fragments = {
  lawsuit: gql`
    fragment LawsuitCardThirdParty_lawsuit on CRMLawsuit {
      lawsuitId
      title
      url
      subject
      number
      classifiedKindByNumber
      topicSourceId
      distributionDate
      typification
      normalizedSubject
      restrictLawsuit
      canViewRestrictLawsuit
      courtSection {
        rawValue
      }
      cnjNumber {
        court {
          name
          acronym
        }
        source {
          type
          district
        }
        data {
          year
        }
      }

      ...LawsuitProgressChip_lawsuit
      ...ModalRelatedLawsuit_lawsuit
    }

    ${LawsuitProgressChip.fragments.lawsuit}
    ${ModalRelatedLawsuit.fragments.lawsuit}
  `,
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPuzzle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.354 1.942C8.99 1.304 9.802 1 10.703 1c.9 0 1.712.304 2.35.942.636.637.941 1.448.941 2.349v.303h2.586c.767 0 1.463.267 2.011.815.548.548.815 1.244.815 2.01v2.587h.303c.901 0 1.712.304 2.35.942.636.637.941 1.448.941 2.349 0 .9-.305 1.712-.942 2.35-.637.636-1.448.94-2.349.94h-.303v2.587c0 .767-.267 1.463-.815 2.011-.548.548-1.244.815-2.01.815h-4.476v-2.379c-.021-.422-.163-.738-.422-1.005-.235-.243-.532-.38-.98-.38-.448 0-.745.137-.98.38-.259.267-.4.583-.422 1.005V22H4.826c-.767 0-1.463-.267-2.011-.815-.548-.548-.815-1.244-.815-2.01v-4.476h2.379c.422-.021.739-.163 1.005-.422.243-.235.38-.532.38-.98 0-.448-.137-.745-.38-.98-.266-.259-.583-.4-1.005-.422H2V7.42c0-.767.267-1.463.815-2.011.548-.548 1.244-.815 2.01-.815h2.587v-.303c0-.901.305-1.712.942-2.35ZM10.703 3.1c-.396 0-.656.118-.864.327-.209.208-.327.468-.327.864v2.403H4.826c-.248 0-.398.071-.526.2-.129.128-.2.278-.2.526v2.375H4.447c.921.039 1.74.376 2.4 1.015.687.667 1.016 1.526 1.016 2.487 0 .961-.329 1.82-1.017 2.487-.659.64-1.478.976-2.4 1.014l-.021.001H4.1v2.375c0 .248.071.398.2.526.128.129.278.2.526.2H7.2V19.553c.039-.921.376-1.74 1.015-2.4.667-.687 1.526-1.016 2.487-1.016.961 0 1.82.329 2.487 1.017.64.659.976 1.478 1.014 2.4l.001.021v.325h2.375c.248 0 .398-.071.526-.2.129-.128.2-.278.2-.526v-4.686h2.403c.396 0 .656-.118.864-.327.209-.208.327-.468.327-.864 0-.396-.118-.656-.327-.864-.208-.209-.468-.327-.864-.327h-2.403V7.42c0-.248-.071-.398-.2-.526-.128-.129-.278-.2-.526-.2h-4.686V4.291c0-.396-.118-.656-.327-.864-.208-.209-.468-.327-.864-.327Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPuzzle)

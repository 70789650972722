import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUserVoice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.856 2.483a1 1 0 0 0-1.712 1.034C19.695 4.43 20 5.688 20 7c0 1.312-.305 2.57-.856 3.483a1 1 0 0 0 1.712 1.034C21.638 10.22 22 8.584 22 7c0-1.584-.362-3.221-1.144-4.517ZM10 4a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C11.416 8.186 12 7.161 12 6a2 2 0 0 0-2-2Zm0 8.166L6.428 14.31A5 5 0 0 0 4 18.597V19a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-.403a5 5 0 0 0-2.428-4.287L10 12.166Zm-1.713-1.305a9.554 9.554 0 0 1-.546-.585C6.915 9.313 6 7.838 6 6a4 4 0 1 1 8 0c0 1.838-.915 3.313-1.74 4.276-.186.216-.371.412-.547.585l2.889 1.734A7 7 0 0 1 18 18.597V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-.403a7 7 0 0 1 3.399-6.002l2.888-1.734Zm7.506-6.568a1 1 0 0 1 1.414 0c.73.73 1.043 1.759 1.043 2.707s-.313 1.977-1.043 2.707a1 1 0 0 1-1.414-1.414c.27-.27.457-.741.457-1.293 0-.552-.187-1.023-.457-1.293a1 1 0 0 1 0-1.414Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUserVoice)

/**
 * @param query The query value to be encoded.
 * @param options (Optional) Indicates if the encoded query should be in lowercase.
 * @returns The encoded query.
 */
export const encodeQuery = (query: string, options?: { lowercase: boolean }): string => {
  const { lowercase = true } = options || {}
  const queryFormatted = lowercase ? query.toLowerCase() : query

  return encodeURIComponent(queryFormatted.replace(/\s+/g, ' ').trim()).replace(/%2[0B]/g, '+')
}

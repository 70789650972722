import * as RSwitch from '@radix-ui/react-switch'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { CheckIcon, CloseIcon } from '../icon/icon'
import { Spinner } from '../spinner/spinner'
import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './switch.module.scss'
import SwitchItem from './switch-item'
import SwitchLabel from './switch-label'

export const SwitchSizes = ['sm', 'md'] as const
export type SwitchSize = (typeof SwitchSizes)[number]

export type SwitchElement = HTMLButtonElement

export interface SwitchProps extends Omit<RSwitch.SwitchProps, 'children'> {
  className?: string
  loading?: boolean
  size?: SwitchSize
}

const SwitchRoot = forwardRef<SwitchElement, SwitchProps>(function Switch(props, forwardedRef) {
  const { className, disabled = false, loading = false, size: sizeProp, ...rest } = props

  const size = ensurePropOf<SwitchSize>(SwitchSizes, sizeProp, 'md')

  const noInteractive = disabled || loading
  const classes = classNames(
    styles.root,
    {
      [styles.nointeractive]: noInteractive,
      [styles.disabled]: disabled,
      [styles.loading]: loading,
      [styles[size]]: size,
    },
    className
  )

  return (
    <RSwitch.Root {...rest} disabled={noInteractive} className={classes} ref={forwardedRef}>
      <RSwitch.Thumb className={styles.thumb}>
        {size !== 'sm' && (
          <>
            <CloseIcon className={styles.closeIcon} size="xs" />
            <CheckIcon className={styles.checkIcon} size="xs" />
          </>
        )}
        <Spinner className={styles.spinnerIcon} size="xs" />
      </RSwitch.Thumb>
    </RSwitch.Root>
  )
})

export const Switch = assignSubComponents('Switch', SwitchRoot, {
  Label: SwitchLabel,
  Item: SwitchItem,
})

export * from './switch-item'
export * from './switch-label'

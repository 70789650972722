import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import commonStyles from './common.module.scss'
import styles from './modal-header.module.scss'

export type ModalHeaderElement = HTMLDivElement

export interface ModalHeaderProps extends HTMLAttributes<ModalHeaderElement> {
  children: ReactNode
}

const ModalHeader = forwardRef<ModalHeaderElement, ModalHeaderProps>(function ModalHeader(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, commonStyles.header, className)
  const containerClasses = classNames(styles.container, commonStyles.headerContainer)

  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      <div className={containerClasses}>{children}</div>
    </div>
  )
})

export default ModalHeader

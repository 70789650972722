import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement, ReactNode } from 'react'

import { ensurePropOf, Slot } from '../utilities'
import styles from './checkbox-group.module.scss'
import { CheckboxItemProps } from './checkbox-item'

export type CheckboxGroupElement = HTMLDivElement
export const CheckboxGroupOrientations = ['vertical', 'horizontal'] as const
export type CheckboxGroupOrientation = (typeof CheckboxGroupOrientations)[number]

export interface CheckboxGroupProps {
  children: ReactNode
  orientation?: CheckboxGroupOrientation
  className?: string
  asChild?: boolean
}

const CheckboxGroup = forwardRef<CheckboxGroupElement, CheckboxGroupProps>(function CheckboxGroup(
  props,
  forwardedRef
) {
  const { className, children, orientation: orientationProp, asChild, ...rest } = props

  const Component = asChild ? Slot : 'div'

  const orientation = ensurePropOf<CheckboxGroupOrientation>(
    CheckboxGroupOrientations,
    orientationProp,
    'vertical'
  )

  const classes = classNames(
    styles.root,
    {
      [styles[`orientation${orientation}`]]: !!orientation,
    },
    className
  )

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {Children.map(children, (child) => {
        if (!isValidElement<CheckboxItemProps>(child)) return child
        return cloneElement(child, { __group: true })
      })}
    </Component>
  )
})

export default CheckboxGroup

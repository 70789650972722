import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'

import { TopbarA11YMenu, TopbarA11YMenuProps } from '../topbar-a11y-menu'
import {
  TopbarContainer,
  TopbarContainerElement,
  TopbarContainerProps,
} from '../topbar-container/topbar-container'
import { TopbarGoogleOneTap } from '../topbar-google-one-tap/topbar-google-one-tap'
import { TopbarHeader, TopbarHeaderProps } from '../topbar-header/topbar-header'
import { TopbarLGPD } from '../topbar-lgpd/topbar-lgpd'
import { TopbarNav, TopbarNavProps } from '../topbar-nav/topbar-nav'

export type TopbarRootElement = TopbarContainerElement

export type TopbarRootProps = {
  containerProps?: TopbarContainerProps
  a11yMenuProps?: TopbarA11YMenuProps
  headerProps?: TopbarHeaderProps
  navProps?: TopbarNavProps
  children?: ReactNode
  className?: string
}

export const TopbarRoot = forwardRef<TopbarContainerElement, TopbarRootProps>(function TopbarRoot(
  props,
  forwardedRef
) {
  const { className, containerProps, a11yMenuProps, headerProps, navProps, children } = props
  return (
    <TopbarContainer
      {...containerProps}
      className={classNames(className, containerProps?.className)}
      ref={forwardedRef}
    >
      {children || (
        <>
          <TopbarA11YMenu {...a11yMenuProps} />
          <TopbarHeader {...headerProps} />
          <TopbarNav {...navProps} />
          <TopbarLGPD />
          <TopbarGoogleOneTap />
        </>
      )}
    </TopbarContainer>
  )
})

/*
 * This function injects an id attribute to the current open portal.
 * It's required to support customization in the radix-ui popper
 * for our dropdown mobile version, without interfering with other poppers.
 */
export function __identifyRadixDropdownPortal(open: boolean, retry = 5) {
  if (!open || retry <= 0) return
  setTimeout(() => {
    const portal = document.querySelector<HTMLElement>('[data-radix-popper-content-wrapper]')
    if (portal) {
      portal.dataset.fdsDropdownPortal = 'true'
    } else {
      __identifyRadixDropdownPortal(open, retry - 1)
    }
  }, 0)
}

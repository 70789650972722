import classNames from 'classnames'
import {
  cloneElement,
  CSSProperties,
  forwardRef,
  isValidElement,
  LiHTMLAttributes,
  ReactNode,
} from 'react'

import { IconProps, IconSize } from '../icon/types'
import type { TimelineSize } from './timeline'
import styles from './timeline-item.module.scss'
import { useTimeline } from './use-timeline'

export type TimelineItemElement = HTMLLIElement

export interface TimelineItemProps extends LiHTMLAttributes<TimelineItemElement> {
  size?: TimelineSize
  icon?: ReactNode
  className?: string
  highlighted?: boolean
  highlightedDelay?: number
}

const iconSizeMapping: Record<TimelineSize, IconSize> = {
  md: 'sm',
  sm: 'xs',
}

const TimelineItem = forwardRef<TimelineItemElement, TimelineItemProps>(function TimelineItem(
  props,
  forwardedRef
) {
  const {
    className,
    children,
    highlighted = false,
    highlightedDelay,
    icon,
    size: sizeProp,
    ...rest
  } = props
  const { size: sizeRoot } = useTimeline()
  const size = sizeProp ?? sizeRoot

  const classes = classNames(
    styles.root,
    {
      [styles.sizesm]: size === 'sm',
      [styles.highlighted]: !!highlighted,
    },
    className
  )

  const hasIcon = isValidElement<IconProps>(icon)
  const bulletClasses = classNames(styles.bullet, {
    [styles.bulletDot]: !hasIcon,
    [styles.bulletIcon]: hasIcon,
  })

  const iconProps: IconProps = {
    size: size ? iconSizeMapping[size] : undefined,
  }

  return (
    <li
      style={
        {
          ...rest.style,
          ...(highlightedDelay
            ? { '--fds-timeline-item-highlighted-delay': `${highlightedDelay}ms` }
            : {}),
        } as CSSProperties
      }
      {...rest}
      className={classes}
      ref={forwardedRef}
    >
      <span className={bulletClasses}>{hasIcon ? cloneElement(icon, iconProps) : null}</span>
      <div className={styles.content}>{children}</div>
    </li>
  )
})

export default TimelineItem

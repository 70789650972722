import { fontFamilyBase } from '@farol-ds/tokens'
import { Inter } from 'next/font/google'

/**
 * Configure Inter font from Google Fonts with specific weights for Farol Design System
 * This is optimized by Next.js font optimization features.
 */
export const farolFont = Inter({
  subsets: ['latin'],
  weight: ['400', '600', '700'],
  display: 'swap',
})

/**
 * This component is responsible for setting up the base font family for Farol Design System.
 * It's placed in core-next library to avoid direct dependencies between Farol and Next.js,
 * keeping Farol framework-agnostic while still allowing Next.js apps to benefit from font
 * optimization features.
 *
 * @returns A style component that injects the font configuration globally
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextFarolFontProps = {}

export const NextFarolFont = (_: NextFarolFontProps) => {
  return (
    <style jsx global>{`
      :root {
        --fds-font-family-base: ${farolFont.style.fontFamily}, ${fontFamilyBase};
      }
    `}</style>
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStatusComplete(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" fill="currentColor" />
    </svg>
  )
}
export default wrapIcon(SvgStatusComplete)

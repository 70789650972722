import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNews(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.707 5.121a1 1 0 0 0-1.414 0l-.586.586a1 1 0 0 1-1.414 0l-.586-.586A1 1 0 0 0 7 5.828V19c0 .35-.06.687-.17 1H20a1 1 0 0 0 1-1V5.828a1 1 0 0 0-1.707-.707l-.586.586a1 1 0 0 1-1.414 0l-.586-.586a1 1 0 0 0-1.414 0l-.586.586a1 1 0 0 1-1.414 0l-.586-.586ZM5 5.828V6H3a2 2 0 0 0-2 2v11a3 3 0 0 0 2.997 3H20a3 3 0 0 0 3-3V5.828a3 3 0 0 0-5-2.236 3 3 0 0 0-4 0 3 3 0 0 0-4 0 3 3 0 0 0-4.121.115l.707.707-.707-.707A3 3 0 0 0 5 5.828ZM3 8h2v11a1 1 0 1 1-2 0V8Zm10 4h-3a1 1 0 0 0 0 2h3a1 1 0 1 0 0-2Zm3.293 3.707A1 1 0 0 1 16 15v-2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-1a1 1 0 0 1-.707-.293ZM10 8a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNews)

type PathMappings = {
  '/processos/nome': string
  '/nome/pessoa': string
  '/consulta-processual': string
  '/processos/': string
}

export const parsePathToPage = (path: string) => {
  const pathMappings: PathMappings = {
    '/processos/nome': 'Nome',
    '/nome/pessoa': 'CPF',
    '/consulta-processual': 'Consulta Processual',
    '/processos/': 'Processo',
  }

  for (const key of Object.keys(pathMappings) as (keyof PathMappings)[]) {
    if (path.startsWith(key)) {
      return pathMappings[key]
    }
  }

  return 'Outro'
}

export const oneTapEventMetadata = () => {
  return {
    origin: 'farol-ds',
    page: parsePathToPage(window.location.pathname),
  }
}

import { useContext, useId } from 'react'

import { FileContext } from './context'
import type { FileProps } from './file'

export type UseFileProviderProps = FileProps

export interface UseFileProviderReturn {
  id: string
  selected: boolean
  disabled: boolean
}

export function useFileProvider(props: UseFileProviderProps): UseFileProviderReturn {
  const { id: idProp, selected = false, disabled = false } = props

  const uuid = useId()
  const id = idProp ?? uuid

  return {
    id,
    selected,
    disabled,
  }
}

export function useFile() {
  const context = useContext(FileContext)
  return context
}

import { OTHER_REASON, Step } from '../constants'

export const steps = {
  [Step.COMPREHENSION]: {
    title: 'O quanto essa informação foi útil?',
    options: ['1', '2', '3', '4', '5'],
    nextStep: (option?: string) => {
      if (option === '4' || option === '5') {
        return Step.MESSAGE
      }
      return Step.REASON
    },
  },
  [Step.REASON]: {
    title: 'Qual o motivo da nota?',
    nextStep: (option?: string) => (option === OTHER_REASON ? Step.OTHER_REASON : Step.MESSAGE),
  },
  [Step.OTHER_REASON]: {
    title: 'Qual o motivo de sua nota baixa?',
    nextStep: () => Step.MESSAGE,
    placeholder: 'Escreva sua opinião',
  },
  [Step.MESSAGE]: {
    title: 'Agradecemos sua resposta!',
    nextStep: () => null,
  },
}

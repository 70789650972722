import type { BifrostGoogleWindowObject } from '@jusbrasil-web/shared-bifrost'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useContext, useEffect, useState } from 'react'

import { GOOGLE_ONE_TAP_ENABLE_EVENT } from '../../constants'
import { TopbarContext } from '../../context'
import { oneTapEventMetadata } from '../../utils/one-tap-event-metadata'

export type TopbarGoogleOneTapProps = {
  defaultEnabled?: boolean
}

const MAX_ATTEMPTS = 5

export function TopbarGoogleOneTap(props: TopbarGoogleOneTapProps) {
  const { defaultEnabled = false } = props
  const { logged, mount, rootRef } = useContext(TopbarContext)
  const [enabled, setEnabled] = useState(defaultEnabled)
  const [done, setDone] = useState(false)

  useEffect(() => {
    const el = rootRef?.current
    if (!el) return
    function handler() {
      setEnabled(true)
    }
    el.addEventListener(GOOGLE_ONE_TAP_ENABLE_EVENT, handler, true)
    return () => {
      el.removeEventListener(GOOGLE_ONE_TAP_ENABLE_EVENT, handler, true)
    }
  }, [rootRef])

  useEffect(() => {
    if (!enabled || !mount || logged || done) return
    const fetchData = async () => {
      const params = new URLSearchParams({
        app_id: 'web-topbar',
        artifact: window.location.pathname || 'not-defined',
        from_component: 'one-tap',
        visualization: 'not-defined',
        next_url: window.location.href,
      })
      try {
        sendMetrics('UserAccount.OneTapIntention', null, { metadata: oneTapEventMetadata() })
        const response = await fetch(`/cadastro/google/one-tap-fragment?${params.toString()}`)
        if (!response.ok) throw new Error('Failed to fetch Bifrost Google OneTap fragment')
        const data = await response.text()
        if (data) {
          injectDataIntoBody(data)
          setDone(true)
        }
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, [enabled, mount, logged, done])

  useEffect(() => {
    if (!enabled || !done) return
    const open = () => {
      if (!window.BifrostGoogle) {
        throw new Error('BifrostGoogle still not loaded')
      }
      window.BifrostGoogle.openOneTap()
    }
    tryToLoad(open)
  }, [enabled, done])

  return null
}

function injectDataIntoBody(data: string) {
  const tmpDiv = document.createElement('div')
  tmpDiv.innerHTML = data
  const children = Array.from(tmpDiv.children)

  children.forEach((child) => {
    if (/script/i.test(child.tagName)) {
      const scriptEl = document.createElement('script')
      scriptEl.textContent = child.textContent

      Array.from(child.attributes).forEach((attr) => {
        scriptEl.setAttribute(attr.name, attr.value)
      })

      document.body.appendChild(scriptEl)
    } else {
      document.body.appendChild(child.cloneNode(true))
    }
  })
}

function tryToLoad(fn: () => void, attempts = 0) {
  if (attempts > MAX_ATTEMPTS) {
    console.error('Failed to load BifrostGoogle')
    return
  }
  try {
    fn()
  } catch (err) {
    console.log(`[${attempts}] Failed to load BifrostGoogle, trying again in 100ms`)
    setTimeout(() => tryToLoad(fn, attempts + 1), 100)
  }
}

declare global {
  interface Window {
    BifrostGoogle?: BifrostGoogleWindowObject
  }
}

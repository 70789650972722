import classNames from 'classnames'
import { forwardRef } from 'react'

import {
  PrimitiveText,
  PrimitiveTextElement,
  PrimitiveTextProps,
} from '../primitive-text/primitive-text'
import { ensurePropOf } from '../utilities'
import styles from './subtitle.module.scss'

export type SubtitleElement = PrimitiveTextElement
export const SubtitleSizes = ['md'] as const
export type SubtitleSize = (typeof SubtitleSizes)[number]

export interface SubtitleProps extends PrimitiveTextProps {
  size?: SubtitleSize
}

export const Subtitle = forwardRef<PrimitiveTextElement, SubtitleProps>(function Subtitle(
  props,
  forwardedRef
) {
  const { className, size: sizeProp, ...rest } = props

  const size = ensurePropOf<SubtitleSize>(SubtitleSizes, sizeProp, 'md')

  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  return <PrimitiveText {...rest} className={classes} ref={forwardedRef} />
})

import { MenuItem, UserHistoryIcon } from '@farol-ds/react'
import { forwardRef, useContext } from 'react'

import { JUSBRASIL_BASE_URL } from '../../constants'
import { TopbarContext } from '../../context'

export type TopbarUserMenuTakeoverElement = HTMLDivElement
export type TopbarUserMenuTakeoverProps = {
  // pass
}

const FORM_URL = `${JUSBRASIL_BASE_URL}/profile/switch`

export const TopbarUserMenuTakeover = forwardRef<
  TopbarUserMenuTakeoverElement,
  TopbarUserMenuTakeoverProps
>(function TopbarUserMenuTakeover(props, forwardedRef) {
  const { takeoverRelatedProfiles } = useContext(TopbarContext)

  const profiles = takeoverRelatedProfiles?.filter((profile) => profile.isMainProfile) || []

  return (
    <div ref={forwardedRef} data-testid="topbar-user-menu-takeover">
      {profiles.map((profile) => (
        <form action={FORM_URL} method="POST" key={profile.pid}>
          <input type="hidden" name="pid" value={profile.pid} />
          <input
            type="hidden"
            name="url"
            value={JUSBRASIL_BASE_URL.replace('www', profile.username)}
          />

          <MenuItem block type="primary">
            <MenuItem.Content>
              <MenuItem.Leading>
                <UserHistoryIcon />
              </MenuItem.Leading>
              Sair do modo Takeover
            </MenuItem.Content>
          </MenuItem>
        </form>
      ))}
    </div>
  )
})

import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'
import { Radio, RadioProps } from './radio'
import styles from './radio-item.module.scss'

export type RadioItemElement = HTMLLabelElement

export interface RadioItemProps extends Partial<RadioProps> {
  children: ReactNode
  asChild?: boolean
}

const RadioItem = forwardRef<RadioItemElement, RadioItemProps>(function RadioItem(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'label'

  const classes = classNames(
    styles.root,
    {
      [styles.disabled]: rest.disabled,
    },
    className
  )

  return (
    <Component className={classes} ref={forwardedRef}>
      {Children.map(children, (child) => {
        if (!isValidElement<RadioProps>(child)) return child
        if (child.type === Radio) {
          return cloneElement(child, rest)
        }
        return child
      })}
    </Component>
  )
})

export default RadioItem

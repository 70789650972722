import {
  BookmarkIcon,
  Brand,
  BrandJusIa,
  EarthIcon,
  FeedIcon,
  FileSearchIcon,
  HammerIcon,
  InformationIcon,
  LawyerDirectoryIcon,
  LogoutIcon,
  PuzzleIcon,
  QuestionAnswerIcon,
  QuestionIcon,
  SettingsIcon,
  StatisticIcon,
} from '@farol-ds/react'

import { TopbarContextType } from '../../../types'
import { OnlineOfficeIcon } from '../../assets/online-office-icon'
import { TopbarUserMenuCtx, TopbarUserMenuItem, TopbarUserMenuItemSymbol } from './types'

export function getMenuItems(ctx: TopbarContextType, menuCtx: TopbarUserMenuCtx) {
  const { counters, currentUrl } = ctx
  const { baseUrl, userUrl } = menuCtx
  const logoutNextUrl = currentUrl ? `?next_url=${encodeURIComponent(currentUrl)}` : ''

  return {
    pro: {
      title: 'Assine o Jusbrasil',
      clickEvents: ['Assinar Jusbrasil'],
      url: `${baseUrl}/pro?comp=topbar-menu-pro-link&cameFrom=topbar-menu-pro-link`,
      target: '_blank',
      leading: <Brand width={20} height={20} onlySymbol />,
    },
    'pro-doutrina': {
      title: 'Assine o Jusbrasil Doutrina',
      clickEvents: ['Assinar Doutrina'],
      url: `${baseUrl}/assinar-doutrina?fromComponent=topbar-menu-doctrine-link`,
      target: '_blank',
      leading: <Brand width={20} height={20} onlySymbol />,
    },
    messages: {
      title: 'Mensagens',
      clickEvents: ['Mensagens'],
      url: `${baseUrl}/mensagens`,
      leading: <QuestionAnswerIcon />,
      count: counters?.messages,
    },
    notifications: {
      title: 'Notificações',
      clickEvents: ['Notificações'],
      url: `${baseUrl}/notificacoes`,
      leading: <EarthIcon />,
      count: counters?.notifications,
    },
    lawyers: {
      title: 'Encontre um advogado',
      clickEvents: ['Encontre um advogado'],
      url: `${baseUrl}/advogados-online/falar-agora`,
      leading: <LawyerDirectoryIcon />,
    },
    jusbrasilia: {
      title: 'Jus IA',
      clickEvents: ['Jus IA'],
      url: `https://ia.jusbrasil.com.br/`,
      leading: <BrandJusIa height={20} width={20} onlySymbol />,
    },
    search: {
      title: 'Pesquisa jurídica',
      clickEvents: ['Pesquisa jurídica'],
      url: `${baseUrl}/iniciar-pesquisa?user-menu=true`,
      leading: <FileSearchIcon />,
    },
    lawsuits: {
      title: 'Processos',
      clickEvents: ['Processos'],
      url: `${baseUrl}/ir/processos`,
      leading: <HammerIcon />,
    },
    'my-feed': {
      title: 'Meu Feed',
      clickEvents: ['Meu Feed'],
      url: `${baseUrl}/feed`,
      leading: <FeedIcon />,
    },
    'my-documents': {
      title: 'Documentos salvos',
      clickEvents: ['Documentos salvos'],
      url: `${baseUrl}/meus-documentos`,
      leading: <BookmarkIcon />,
    },
    'my-stats': {
      title: 'Estatísticas',
      clickEvents: ['Estatísticas'],
      url: `${userUrl}/estatisticas`,
      leading: <StatisticIcon />,
    },
    'chrome-ext': {
      title: 'Extensão do Jusbrasil para Chrome',
      clickEvents: ['BrowserExtension.InstallExtensionClicked'],
      url: 'https://chrome.google.com/webstore/detail/jusbrasil/gnndbcenekaghpeaglkmmcgidogaejge',
      target: '_blank',
      leading: <PuzzleIcon />,
    },
    'online-office': {
      title: 'Escritório Online',
      clickEvents: ['Escritório Online'],
      url: 'https://escritorioonline.jusbrasil.com.br',
      target: '_blank',
      leading: <OnlineOfficeIcon />,
    },
    help: {
      title: 'Ajuda e suporte',
      clickEvents: ['Ajuda e suporte'],
      url: 'https://suporte.jusbrasil.com.br/hc/pt-br',
      target: '_blank',
      leading: <QuestionIcon />,
    },
    about: {
      title: 'Sobre',
      clickEvents: ['Sobre'],
      url: 'https://sobre.jusbrasil.com.br',
      leading: <InformationIcon />,
    },
    settings: {
      title: 'Configurações',
      clickEvents: ['Configurações'],
      url: `${baseUrl}/configuracoes`,
      leading: <SettingsIcon />,
    },
    logout: {
      title: 'Sair',
      clickEvents: ['Sair da conta'],
      url: `${baseUrl}/logout${logoutNextUrl}`,
      leading: <LogoutIcon />,
    },
  } satisfies Record<string, TopbarUserMenuItem>
}

export type TopbarUserMenuItemID = keyof ReturnType<typeof getMenuItems>
export type TopbarUserMenuItemKey = TopbarUserMenuItemID | TopbarUserMenuItemSymbol

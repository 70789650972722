import * as RPopover from '@radix-ui/react-popover'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { CloseIcon } from '../icon/icons'
import { IconButton, IconButtonElement, IconButtonProps } from '../icon-button/icon-button'
import styles from './popover-content-close.module.scss'
import { usePopover } from './use-popover'

export type PopoverContentCloseElement = IconButtonElement

export type PopoverContentCloseProps = Omit<IconButtonProps, 'children' | 'asChild' | 'label'> & {
  label?: string
}

const PopoverContentClose = forwardRef<PopoverContentCloseElement, PopoverContentCloseProps>(
  function PopoverContentClose(props, forwardedRef) {
    const { className, label = 'Fechar', ...rest } = props
    const { type } = usePopover()
    const classes = classNames(
      styles.root,
      {
        [styles.highlight]: type === 'highlight',
      },
      className
    )
    return (
      <RPopover.Close asChild>
        <IconButton
          {...rest}
          className={classes}
          label={label}
          icon={<CloseIcon />}
          tooltipProps={{ open: false }}
          size="sm"
          ref={forwardedRef}
        />
      </RPopover.Close>
    )
  }
)

export default PopoverContentClose

import { isCommandOrCtrlKeyActive } from '@jusbrasil-web/shared-utils-dom'

const COPY_LENGTH = 20
const LEFT_BUTTON_DOWN = 0
const RIGHT_BUTTON_DOWN = 2

enum Action {
  KeyDown = 'keyDown',
  MouseDown = 'mouseDown',
}

const arraysAreEqual = (a: number[], b: number[]) => {
  if (a === b) {
    return true
  }

  if (a.length !== b.length) {
    return false
  }

  return a.every((value, index) => value === b[index])
}

export class CopyListener {
  private lastAction: Action | undefined
  private lastMouseButtons: number[] = []
  private isCopyAction = false
  private copyActionRightButton = [RIGHT_BUTTON_DOWN, LEFT_BUTTON_DOWN]

  private onMouseDown = (event: MouseEvent) => {
    this.lastAction = Action.MouseDown

    if (this.lastMouseButtons.length > 1) {
      this.lastMouseButtons.shift()
    }

    this.lastMouseButtons.push(event.button)
    this.isCopyAction = this.verifyMouseCopyAction()
  }

  private onKeyDown = (event: KeyboardEvent) => {
    this.lastAction = Action.KeyDown
    this.isCopyAction = this.verifyKeyboardCopyAction(event)
  }

  private verifyMouseCopyAction = () =>
    arraysAreEqual(this.copyActionRightButton, this.lastMouseButtons)

  private verifyKeyboardCopyAction(event: KeyboardEvent) {
    const keys = ['c']
    return keys.includes(event.key) && isCommandOrCtrlKeyActive(event)
  }

  enable() {
    window.addEventListener('mousedown', this.onMouseDown)
    window.addEventListener('keydown', this.onKeyDown)
  }

  disable() {
    window.removeEventListener('mousedown', this.onMouseDown)
    window.removeEventListener('keydown', this.onKeyDown)
  }

  getContextCopy() {
    if (this.lastAction === Action.KeyDown && this.isCopyAction) {
      return 'ctrl+c'
    }

    if (this.lastAction === Action.MouseDown && this.isCopyAction) {
      return 'right button'
    }

    return 'context menu'
  }

  getSelectedLength = () => window?.getSelection()?.toString().length || 0

  getSelectedContentShort = () => window?.getSelection()?.toString()?.slice(0, COPY_LENGTH) || ''

  getLastSelectedContentShort() {
    const len = this.getSelectedLength()
    const startCopy = len - COPY_LENGTH
    if (startCopy > 0) {
      return window?.getSelection()?.toString()?.slice(startCopy, len) || ''
    }
    return ''
  }
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { BodyText } from '../body-text/body-text'
import { renderCaptionChildren } from './shared-helpers'
import sharedStyles from './shared-styles.module.scss'
import SnippetCaptionSeparator from './snippet-caption-separator'

export type SnippetCaptionElement = HTMLDivElement

export type SnippetCaptionProps = HTMLAttributes<SnippetCaptionElement> & {
  asChild?: boolean
}

const SnippetCaption = forwardRef<SnippetCaptionElement, SnippetCaptionProps>(
  function SnippetCaption(props, forwardedRef) {
    const { children: _, className, ...rest } = props

    const classes = classNames(sharedStyles.caption, className)

    return (
      <BodyText {...rest} size="md" className={classes} ref={forwardedRef} asChild>
        <div>{renderCaptionChildren(props, SnippetCaptionSeparator)}</div>
      </BodyText>
    )
  }
)

export default SnippetCaption

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useEffect } from 'react'

import { assignSubComponents } from '../utilities/internal'
import styles from './carousel.module.scss'
import CarouselItem from './carousel-item'
import CarouselList from './carousel-list'
import CarouselPagination from './carousel-pagination'
import { CarouselContext } from './context'
import { useCarousel } from './use-carousel'

export type CarouselElement = HTMLDivElement

export interface CarouselProps extends HTMLAttributes<CarouselElement> {
  children: ReactNode
  className?: string
  onActivePageChange?: (activePageIndex: number, activePageItemIndex: number[]) => void
}

const CarouselRoot = forwardRef<CarouselElement, CarouselProps>(function Carousel(
  props,
  forwardedRef
) {
  const { className, children, onActivePageChange, ...rest } = props

  const classes = classNames(styles.root, {}, className)

  const carousel = useCarousel()

  useEffect(() => {
    if (!onActivePageChange || carousel.pages.length === 0) {
      return
    }
    onActivePageChange(carousel.activePageIndex, carousel.pages[carousel.activePageIndex])
  }, [carousel.activePageIndex, carousel.pages, onActivePageChange])

  return (
    <CarouselContext.Provider value={carousel}>
      <div {...rest} className={classes} ref={forwardedRef}>
        {children}
      </div>
    </CarouselContext.Provider>
  )
})

export const Carousel = assignSubComponents('Carousel', CarouselRoot, {
  List: CarouselList,
  Item: CarouselItem,
  Pagination: CarouselPagination,
})

export * from './carousel-item'
export * from './carousel-list'
export * from './carousel-pagination'

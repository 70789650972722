import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react'

import { TreeViewItemProps } from './tree-view-item'
import styles from './tree-view-list.module.scss'

export type TreeViewListElement = HTMLUListElement

export interface TreeViewListProps
  extends Omit<HTMLAttributes<TreeViewListElement>, 'defaultValue'> {
  children: ReactNode
  disablePadding?: boolean
  className?: string
}

const TreeViewList = forwardRef<TreeViewListElement, TreeViewListProps>(function TreeViewList(
  props,
  forwardedRef
) {
  const { className, children, disablePadding = false, ...rest } = props

  const classes = classNames(
    styles.root,
    {
      [styles.nopadding]: disablePadding,
    },
    className
  )

  return (
    <ul role="tree" tabIndex={-1} {...rest} className={classes} ref={forwardedRef}>
      {Children.map(children, (child, idx) => {
        if (!isValidElement<TreeViewItemProps>(child)) return null
        if (idx === 0) {
          return cloneElement(child, { tabIndex: 0 })
        }
        return child
      })}
    </ul>
  )
})

export default TreeViewList

import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react'

import { IconProps, IconSize } from '../icon/types'
import styles from './detail-icon.module.scss'

export type DetailIconElement = HTMLSpanElement

export interface DetailIconProps extends HTMLAttributes<DetailIconElement> {
  children: ReactNode
  className?: string
}

const iconSize: IconSize = 'sm'

const DetailIcon = forwardRef<DetailIconElement, DetailIconProps>(function DetailIcon(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <span {...rest} data-fds-detail-icon className={classes} ref={forwardedRef}>
      {Children.map(children, (child) => {
        if (isValidElement<IconProps>(child)) {
          return cloneElement(child, { size: iconSize })
        }
        return child
      })}
    </span>
  )
})

export default DetailIcon

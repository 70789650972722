import classNames from 'classnames'
import { forwardRef } from 'react'

import { BodyText, BodyTextElement, BodyTextProps } from '../body-text/body-text'
import { Chip } from '../chip/chip'
import { CollapseText, CollapseTextProps } from '../collapse-text/collapse-text'
import { Quote } from '../quote/quote'
import { Slot, Slottable } from '../utilities'
import styles from './snippet-content.module.scss'

export type SnippetContentElement = BodyTextElement

export type SnippetContentProps = BodyTextProps & {
  quote?: boolean
  label?: string
  truncate?: CollapseTextProps['value']
  onTruncateChange?: CollapseTextProps['onOpenChange']
  collapseTextProps?: Partial<Omit<CollapseTextProps, 'onOpenChange' | 'value'>>
}

const SnippetContent = forwardRef<SnippetContentElement, SnippetContentProps>(
  function SnippetContent(props, forwardedRef) {
    const {
      children,
      quote = false,
      truncate,
      label,
      className,
      asChild,
      onTruncateChange,
      collapseTextProps,
      ...rest
    } = props

    const classes = classNames(styles.root, className)

    const Component = asChild ? Slot : 'div'

    let text = (
      <BodyText {...rest} className={classes} size="md" ref={forwardedRef} asChild>
        <Component>
          {label && (
            <Chip className={styles.chip} size="sm">
              {label}
            </Chip>
          )}
          <Slottable>{children}</Slottable>
        </Component>
      </BodyText>
    )

    if (truncate) {
      text = (
        <CollapseText
          {...collapseTextProps}
          truncateContainerClassName={classNames(
            styles.truncateContainer,
            collapseTextProps?.truncateContainerClassName
          )}
          value={truncate}
          onOpenChange={onTruncateChange}
        >
          {text}
        </CollapseText>
      )
    }

    if (quote) {
      text = <Quote>{text}</Quote>
    }

    return text
  }
)

export default SnippetContent

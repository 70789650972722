import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSubtractRound(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.343 6.343a8 8 0 1 1 11.314 11.314A8 8 0 0 1 6.343 6.343ZM19.071 4.93c-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142 3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142ZM7.757 11a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSubtractRound)

import { Brand, Link } from '@farol-ds/react'
import { forwardRef } from 'react'

import styles from './topbar-brand.module.scss'

export const TopbarBrand = forwardRef(function TopbarBrand() {
  return (
    <Link href="/" aria-label="Voltar para página principal Jusbrasil">
      <Brand onlySymbol className={styles.brandSymbol} />
      <Brand className={styles.brand} />
    </Link>
  )
})

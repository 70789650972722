import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgDrag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm6.5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-5 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-5 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgDrag)

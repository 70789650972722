import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './snippet.module.scss'
import SnippetBody from './snippet-body'
import SnippetCaption from './snippet-caption'
import SnippetCaptionItem from './snippet-caption-item'
import SnippetContainer from './snippet-container'
import SnippetContent from './snippet-content'
import SnippetCover from './snippet-cover'
import SnippetFootnote from './snippet-footnote'
import SnippetFootnoteBody from './snippet-footnote-body'
import SnippetFootnoteCaption from './snippet-footnote-caption'
import SnippetFootnoteCaptionItem from './snippet-footnote-caption-item'
import SnippetFootnoteContainer from './snippet-footnote-container'
import SnippetFootnoteCover from './snippet-footnote-cover'
import SnippetFootnoteTitle from './snippet-footnote-title'
import SnippetHeader from './snippet-header'
import SnippetTags from './snippet-tags'
import SnippetTagsItem from './snippet-tags-item'
import SnippetTail from './snippet-tail'
import SnippetTitle from './snippet-title'

export type SnippetElement = HTMLDivElement

export interface SnippetProps extends HTMLAttributes<SnippetElement> {
  asChild?: boolean
  children?: ReactNode
  className?: string
}

const SnippetRoot = forwardRef<SnippetElement, SnippetProps>(function Snippet(props, forwardedRef) {
  const { className, children, asChild, ...rest } = props

  const classes = classNames(styles.root, {}, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export const Snippet = assignSubComponents('Snippet', SnippetRoot, {
  Container: SnippetContainer,
  Cover: SnippetCover,
  Body: SnippetBody,
  Content: SnippetContent,
  Caption: SnippetCaption,
  CaptionItem: SnippetCaptionItem,
  Tags: SnippetTags,
  TagsItem: SnippetTagsItem,
  Title: SnippetTitle,
  Header: SnippetHeader,
  Tail: SnippetTail,
  Footnote: SnippetFootnote,
  FootnoteContainer: SnippetFootnoteContainer,
  FootnoteCover: SnippetFootnoteCover,
  FootnoteCaption: SnippetFootnoteCaption,
  FootnoteCaptionItem: SnippetFootnoteCaptionItem,
  FootnoteTitle: SnippetFootnoteTitle,
  FootnoteBody: SnippetFootnoteBody,
})

export * from './snippet-body'
export * from './snippet-caption'
export * from './snippet-caption-item'
export * from './snippet-container'
export * from './snippet-content'
export * from './snippet-cover'
export * from './snippet-footnote'
export * from './snippet-footnote-body'
export * from './snippet-footnote-caption'
export * from './snippet-footnote-caption-item'
export * from './snippet-footnote-container'
export * from './snippet-footnote-cover'
export * from './snippet-footnote-title'
export * from './snippet-header'
export * from './snippet-tags'
export * from './snippet-tags-item'
export * from './snippet-tail'
export * from './snippet-title'

import { ProgressLoader, ProgressLoaderProps, ProgressLoaderStateType } from '@farol-ds/react'
import { useRouter } from 'next/router'

export type NextFarolProgressLoaderProps = ProgressLoaderProps

function mapStateToRouter(event: ProgressLoaderStateType) {
  switch (event) {
    case 'start':
      return 'routeChangeStart'
    case 'complete':
      return 'routeChangeComplete'
  }
}

export function NextFarolProgressLoader(props: NextFarolProgressLoaderProps) {
  const router = useRouter()

  return (
    <ProgressLoader
      on={(state, handler) => router.events.on(mapStateToRouter(state), handler)}
      off={(state, handler) => router.events.on(mapStateToRouter(state), handler)}
      placement="top"
      {...props}
    />
  )
}

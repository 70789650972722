const ROOT_DOMAIN = 'jusbrasil.com.br'
const DEFAULT_SUBDOMAIN = 'www'

const fromExternalSource = (url: string): string | null => {
  const domain = new URL(url).hostname || null
  const popularDomains = ['google', 'facebook', 'bing', 'gmail', 'outlook', 'yahoo']
  const parsedUrl = domain
    ? popularDomains.find((popularDomain) => {
        const re = new RegExp(`\\b${popularDomain}\\b`)
        return domain.search(re) > -1
      })
    : null

  return parsedUrl || 'external'
}

const sections: [string, RegExp][] = [
  ['welcome', '/bem-vindo'],
  ['journal_block', '/diarios/documentos'],
  ['legal_case_or_ada_view', '/advogados/painel-profissional'],
  ['lead_form', '/advogados-online/falar-agora'],
  ['lead_form', '/l/advogados-online'],
  ['serp', '/busca'],
  ['publish', '/publicar'],
  ['login', '/login'],
  ['signup', '/cadastro'],
  ['journal', '/diarios'],
  ['news', '/noticias'],
  ['article', '/artigos'],
  ['forms_model', '/modelos-pecas'],
  ['legislation', '/legislacao'],
  ['summary_precedent', '/jurisprudencia/sumulas'],
  ['jurisprudence', '/jurisprudencia'],
  ['topic', '/topicos'],
  ['motion', '/processos/\\d+/peca-.*'],
  ['lawsuit', '/processos'],
  ['pro_page', '/pro'],
  ['lawyer_directory', '/advogados'],
  ['messages', '/mensagens'],
  ['profile_configuration', '/configuracoes'],
  ['my_docs', '/meus-documentos'],
  ['terms_of_use', '/termos-de-uso'],
  ['comments', '/comentarios'],
  ['notifications', '/notificacoes'],
  ['updates_page', '/atualizacoes'],
  ['definitions', '/definicoes'],
  ['home', '/home'],
  ['feed', '/feed'],
].map(([section, path]) => [section, new RegExp(path)])

export const fromSection = (url: string | null): string | null => {
  if (!url) {
    return null
  }

  const hasRootDomain = url.includes(ROOT_DOMAIN)
  const [section] = sections.find(([, regexp]) => hasRootDomain && regexp.test(url)) || [null]

  if (!section && url && hasRootDomain) {
    const urlObj = new URL(url)

    const defaultPath = '/'
    const [rawSubdomain] = urlObj.hostname.split(ROOT_DOMAIN)
    const subdomain = rawSubdomain.substring(0, rawSubdomain.length - 1)

    if (subdomain === 'escritorioonline') {
      return 'online_office'
    }

    if (subdomain === 'advogado-correspondente') {
      return 'ada_page'
    }

    if ((subdomain === DEFAULT_SUBDOMAIN || subdomain === '') && urlObj.pathname === defaultPath) {
      return 'home'
    }

    if (subdomain && subdomain !== DEFAULT_SUBDOMAIN) {
      return 'profile'
    }

    console.warn(`[shared-utils-url] unknown section: ${url}`)
    return 'unknown'
  }

  return section || fromExternalSource(url)
}

export const extractSectionId = (url: string | null): number | null => {
  if (!url) {
    return null
  }

  const re =
    /\/(noticias|artigos|modelos-pecas|jurisprudencia|legislacao|diarios|diarios\/documentos|topicos|processos)\/(\d+)\/.*/
  const matches = url.match(re)
  return matches ? parseInt(matches[2], 10) : null
}

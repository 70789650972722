import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { ButtonProps } from '../button/button'
import styles from './banner-action.module.scss'
import { useBanner } from './use-banner'

export type BannerActionElement = HTMLDivElement

export interface BannerActionProps extends HTMLAttributes<BannerActionElement> {
  children: ReactNode
  className?: string
}

const BannerAction = forwardRef<BannerActionElement, BannerActionProps>(function BannerAction(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { level } = useBanner()
  if (level === 'app') {
    return null
  }

  const classes = classNames(styles.root, className)

  return (
    <div {...rest} data-fds-banner-action className={classes} ref={forwardedRef}>
      {isValidElement<ButtonProps>(children)
        ? cloneElement(children, {
            kind: 'tertiary',
            size: 'md',
            block: true,
          })
        : children}
    </div>
  )
})

export default BannerAction

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStarHalf(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.94 3.434c-.6-1.912-3.28-1.912-3.88 0L8.623 8.003H4.038c-1.983 0-2.745 2.506-1.25 3.68l3.78 2.969-1.408 4.653c-.59 1.838 1.515 3.536 3.166 2.273l.011-.009 3.672-2.893 3.793 2.902c1.654 1.265 3.765-.442 3.163-2.285l-1.52-4.651 3.766-2.958c1.496-1.175.734-3.681-1.249-3.681h-4.585L13.94 3.434ZM12 16.41c.214 0 .428.068.608.205l4.41 3.374c.005.005.01.007.012.01a.058.058 0 0 0 .015-.01.069.069 0 0 0 .024-.03l.002-.008a.12.12 0 0 0-.008-.037l-1.737-5.316a1 1 0 0 1 .333-1.097l4.317-3.39c.014-.01.018-.018.02-.02a.02.02 0 0 0 .002-.006.092.092 0 0 0-.004-.05.104.104 0 0 0-.017-.031.084.084 0 0 0-.015-.001h-5.32a1 1 0 0 1-.953-.7l-1.656-5.269a.106.106 0 0 0-.014-.03A.045.045 0 0 0 12 4v12.41Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStarHalf)

import { IncomingMessage } from 'http'

export function isGoogleBotAgent(req: IncomingMessage) {
  const requestUserAgent = req.headers['user-agent']
  if (!requestUserAgent) {
    return false
  }

  const allowedUserAgents = ['Googlebot', 'Google-InspectionTool']
  return allowedUserAgents.some((userAgent) => requestUserAgent.includes(userAgent))
}

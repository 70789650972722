import { NextApiRequest, NextApiResponse } from 'next'

import { STAGING_HOST } from '../constants'

// sync with `network-graphql-playground` in .juscli
const LOCAL_GRAPHQL = 'http://localhost:4500/graphql'

export async function graphqlMiddleware(req: NextApiRequest, res: NextApiResponse) {
  const host = req.headers.host
  const isStagingHost = host?.includes(STAGING_HOST)
  const isCI = !!process.env.CI

  if (process.env.NODE_ENV !== 'development' && !isCI && !isStagingHost) {
    return res.status(404).send(null)
  }

  const url = process.env.NETWORK_GRAPHQL_PLAYGROUND_URL || LOCAL_GRAPHQL
  const urlWithBatch = req.url?.endsWith('/apollo-batch') ? `${url}/apollo-batch` : url

  const headers: HeadersInit = {
    Cookie: req?.headers.cookie || '',
    'User-Agent': req?.headers['user-agent'] || '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  const cfConnectingIpHeader = req?.headers['cf-connecting-ip']
  if (cfConnectingIpHeader) {
    headers['CF-Connecting-IP'] = Array.isArray(cfConnectingIpHeader)
      ? cfConnectingIpHeader.join('; ')
      : cfConnectingIpHeader
  }

  const serviceAccountAccessToken = process.env.SA_ACCESS_TOKEN
  if (serviceAccountAccessToken) {
    headers.Authorization = `Bearer ${serviceAccountAccessToken}`
  }

  const data = await fetch(urlWithBatch, {
    method: req.method,
    body: JSON.stringify(req.body),
    headers,
  }).then((r) => {
    const setCookies: string[] = []
    r.headers.forEach((value, key) => {
      if (key.toLowerCase() === 'set-cookie') setCookies.push(value)
    })

    res.setHeader('Set-Cookie', setCookies)

    return r.json()
  })

  return res.json(data)
}

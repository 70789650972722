import { useEffect, useRef } from 'react'

import { ExperimentalConfigMap, ExperimentFlag } from './config'

function getAttrName(flag: string): string {
  return `data-fds-flags-${flag}`
}

function getAttrValue(value: unknown): string {
  return value === true ? '1' : String(value)
}

function predicateValidFlagValue(value: unknown): boolean {
  return !!value
}

function getFlagNames(flags: Partial<ExperimentalConfigMap>): Set<ExperimentFlag> {
  return new Set(
    Object.entries(flags)
      .filter(([_, v]) => predicateValidFlagValue(v))
      .map(([flag]) => flag as ExperimentFlag)
  )
}

export function useFlagsAttributes(flags: Partial<ExperimentalConfigMap>) {
  const prevFlags = useRef(getFlagNames(flags))

  useEffect(() => {
    const flagNames = getFlagNames(flags)

    for (const flag of prevFlags.current) {
      if (!flagNames.has(flag)) {
        document.documentElement.removeAttribute(getAttrName(flag))
      }
    }
    for (const flag of flagNames) {
      const value = flags[flag]
      document.documentElement.setAttribute(getAttrName(flag), getAttrValue(value))
    }

    prevFlags.current = flagNames

    return () => {
      for (const flag of flagNames) {
        document.documentElement.removeAttribute(getAttrName(flag))
      }
    }
  }, [flags])
}

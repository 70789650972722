import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgTimer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3a1 1 0 1 0-2 0v1.055A9.001 9.001 0 0 0 12 22a9 9 0 0 0 1-17.945V3Zm-1 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm0 2a1 1 0 0 1 1 1v2.057l1.919-1.37a1 1 0 1 1 1.162 1.627l-3.5 2.5A1 1 0 0 1 11 13V9a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgTimer)

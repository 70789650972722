import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLogout(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v5a1 1 0 1 0 2 0V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-1a1 1 0 1 0-2 0v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5Zm4 7a1 1 0 0 1 1-1 1 1 0 1 0 0-2 3 3 0 1 0 0 6h9.586l-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 1 0-1.414 1.414L18.586 13H9a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLogout)

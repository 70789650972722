import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.293 2.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1-1.414 1.414L13 5.414V15a1 1 0 1 1-2 0V5.414L7.707 8.707a1 1 0 0 1-1.414-1.414l5-5ZM6.028 17.08a1 1 0 1 0-1.627-1.162l-2.085 2.918C1.37 20.162 2.316 22 3.943 22h16.114c1.627 0 2.573-1.839 1.627-3.163L19.6 15.92a1 1 0 1 0-1.627 1.162L20.057 20H3.943l2.085-2.919Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUpload)

import { RefObject, useCallback, useEffect, useRef } from 'react'

import { useKeyPress } from '../utilities'

export type UseTreeViewA11yProps = {
  expandedRef: RefObject<string[]>
}

export type UseTreeViewA11yRefresh = () => void

export type UseTreeViewA11yReturn<List> = [RefObject<List>, UseTreeViewA11yRefresh]

export function useTreeViewA11y<
  List extends HTMLElement,
  Item extends HTMLElement
>(): UseTreeViewA11yReturn<List> {
  const allItems = useRef<Item[]>([])

  const { ref } = useKeyPress<List>([], (event) => {
    if (!ref.current) return
    const currentItem = document.activeElement?.closest<Item>('[role="treeitem"]')

    switch (event.key) {
      case 'ArrowUp': {
        navigateTree<Item>(allItems.current, currentItem, 'up')
        break
      }

      case 'ArrowDown': {
        navigateTree<Item>(allItems.current, currentItem, 'down')
        break
      }

      case 'Home': {
        const topEl = allItems.current[0]
        topEl?.focus()
        break
      }

      case 'End': {
        const endEl = allItems.current[allItems.current.length - 1]
        endEl?.focus()
        break
      }
    }
  })

  // avoid recalculating the list of items every time
  const getNodeList = useCallback((ref: RefObject<List>) => {
    if (!ref.current) return []
    const nodeList = ref.current.querySelectorAll<Item>('[role="treeitem"]')
    // trick to remove items during the animation from the calculation
    return Array.from(nodeList).filter(
      (node) => node.parentElement?.getAttribute('data-state') !== 'closed'
    )
  }, [])

  // pre-calculate the list of items on mount
  useEffect(() => {
    allItems.current = getNodeList(ref)
  }, [allItems, getNodeList, ref])

  // refresh the list of items when the tree is updated
  const onRefresh = useCallback(() => {
    // tick to ensure that the DOM has updated
    setTimeout(() => {
      allItems.current = getNodeList(ref)
    }, 0)
  }, [getNodeList, ref])

  return [ref, onRefresh]
}

function navigateTree<Item extends HTMLElement>(
  list: Item[],
  current: Item | null | undefined,
  direction: 'up' | 'down'
) {
  const currIdx = current ? list.indexOf(current) : -1
  if (currIdx === -1) return
  const nextCurrIdx = currIdx + (direction === 'up' ? -1 : 1)
  list[nextCurrIdx]?.focus()
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgJudicialBranch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.504 3.132a1 1 0 0 1 .992 0l7 4A1 1 0 0 1 19 9v2h2a1 1 0 1 1 0 2h-2v3a1 1 0 1 1-2 0V8.009l-5-2.857-5 2.857V16a1 1 0 1 1-2 0v-3H3a1 1 0 1 1 0-2h2V9a1 1 0 0 1-.496-1.868l7-4ZM3 19a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3Zm8-8a1 1 0 1 1 2 0v5a1 1 0 1 0 2 0v-5a3 3 0 1 0-6 0v5a1 1 0 1 0 2 0v-5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgJudicialBranch)

import classNames from 'classnames'
import { forwardRef } from 'react'

import { Caption, CaptionElement, CaptionProps } from '../caption/caption'
import { renderTitleChildren } from './shared-helpers'
import sharedStyles from './shared-styles.module.scss'

export type SnippetFootnoteTitleElement = CaptionElement

export type SnippetFootnoteTitleProps = CaptionProps

const SnippetFootnoteTitle = forwardRef<SnippetFootnoteTitleElement, SnippetFootnoteTitleProps>(
  function SnippetFootnoteTitle(props, forwardedRef) {
    const { children: _, className, ...rest } = props

    const classes = classNames(sharedStyles.title, className)

    return (
      <Caption {...rest} className={classes} ref={forwardedRef}>
        {renderTitleChildren(props)}
      </Caption>
    )
  }
)

export default SnippetFootnoteTitle

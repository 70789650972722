import type { DropdownMenuProps as RDropdownMenuProps } from '@radix-ui/react-dropdown-menu'
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import { ReactNode } from 'react'

import { assignSubComponents } from '../utilities/internal'
import DropdownMenu from './dropdown-menu'
import DropdownMenuCheckboxItem from './dropdown-menu-checkbox-item'
import DropdownMenuItem from './dropdown-menu-item'
import DropdownMenuRadioGroup from './dropdown-menu-radio-group'
import DropdownMenuRadioItem from './dropdown-menu-radio-item'
import DropdownMenuSeparator from './dropdown-menu-separator'
import DropdownTrigger from './dropdown-trigger'
import { __identifyRadixDropdownPortal } from './utils'

export type DropdownElement = HTMLDivElement

export interface DropdownProps extends RDropdownMenuProps {
  children: ReactNode
}

const DropdownRoot: React.FC<DropdownProps> = (props) => {
  const { children, onOpenChange: onOpenChangeProp, ...rest } = props

  function onOpenChange(open: boolean) {
    __identifyRadixDropdownPortal(open)
    onOpenChangeProp?.(open)
  }

  return (
    <RDropdownMenu.Root {...rest} onOpenChange={onOpenChange}>
      {children}
    </RDropdownMenu.Root>
  )
}

export const Dropdown = assignSubComponents('Dropdown', DropdownRoot, {
  Menu: DropdownMenu,
  MenuCheckboxItem: DropdownMenuCheckboxItem,
  MenuItem: DropdownMenuItem,
  MenuRadioGroup: DropdownMenuRadioGroup,
  MenuRadioItem: DropdownMenuRadioItem,
  MenuSeparator: DropdownMenuSeparator,
  Trigger: DropdownTrigger,
})

export * from './dropdown-menu'
export * from './dropdown-menu-checkbox-item'
export * from './dropdown-menu-item'
export * from './dropdown-menu-radio-group'
export * from './dropdown-menu-radio-item'
export * from './dropdown-menu-separator'
export * from './dropdown-trigger'
export * from './use-dropdown-state'

import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Caption } from '../caption/caption'
import { renderCaptionChildren } from './shared-helpers'
import sharedStyles from './shared-styles.module.scss'
import styles from './snippet-footnote-caption.module.scss'
import SnippetFootnoteCaptionSeparator from './snippet-footnote-caption-separator'

export type SnippetFootnoteCaptionElement = HTMLDivElement

export type SnippetFootnoteCaptionProps = HTMLAttributes<SnippetFootnoteCaptionElement> & {
  asChild?: boolean
}

const SnippetFootnoteCaption = forwardRef<
  SnippetFootnoteCaptionElement,
  SnippetFootnoteCaptionProps
>(function SnippetFootnoteCaption(props, forwardedRef) {
  const { children: _, className, ...rest } = props

  const classes = classNames(sharedStyles.caption, styles.root, className)

  return (
    <Caption {...rest} className={classes} ref={forwardedRef} asChild>
      <div>{renderCaptionChildren(props, SnippetFootnoteCaptionSeparator)}</div>
    </Caption>
  )
})

export default SnippetFootnoteCaption

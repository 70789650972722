import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useLayoutEffect, useRef } from 'react'

import { Slot } from '../utilities'
import styles from './truncate-text.module.scss'

export type TruncateTextValueKind = number | 'unset'
export type TruncateTextValue = TruncateTextValueKind | TruncateTextValueKind[]
export type TruncateTextElement = HTMLDivElement
export const TruncateTextModes = ['single', 'multi'] as const
export type TruncateTextMode = (typeof TruncateTextModes)[number]
export const TruncateTextFades = ['md'] as const
export type TruncateTextFade = (typeof TruncateTextFades)[number]
/**
 * single - truncate with a single line using ellipsis only (responsive is not supported)
 * multi - truncate with multiple lines using webkit line-clamp
 */

export interface TruncateTextProps extends HTMLAttributes<TruncateTextElement> {
  value: TruncateTextValue
  children: ReactNode
  fade?: TruncateTextFade
  asChild?: boolean
  className?: string
  defaultFadeVisible?: boolean
}

const TRANSITION_DELAY = 1000 // see $transition-delay in truncate-text.module.scss

const fadeClassMapping: Record<TruncateTextFade, string> = {
  md: styles.fademd,
}

export const TruncateText = forwardRef<TruncateTextElement, TruncateTextProps>(
  function TruncateText(props, forwardedRef) {
    const {
      className,
      children,
      value: valueProp,
      asChild,
      fade: fadeProp,
      defaultFadeVisible = false,
      ...rest
    } = props

    const mode: TruncateTextMode = valueProp === 1 ? 'single' : 'multi'
    const value = normalizeValue(valueProp ?? [])
    const isValid = value.length > 0
    const fade = mode === 'multi' ? fadeProp : undefined

    const fadeVisible = useRef(isValid && fade && defaultFadeVisible)
    const rootRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
      if (!isValid || !fade || !rootRef.current) return

      const evaluate = () => {
        const el = rootRef.current
        if (!el) return
        const klass = fadeClassMapping[fade]
        const shouldShowFade = el.scrollHeight > el.clientHeight
        fadeVisible.current = shouldShowFade
        el.classList.toggle(klass, shouldShowFade)
      }
      evaluate()
      const resizer = new ResizeObserver(evaluate)
      resizer.observe(rootRef.current)
      return () => resizer.disconnect()
    }, [fade, isValid])

    useLayoutEffect(() => {
      if (!isValid || !fade || !rootRef.current) return
      const timeout = setTimeout(() => {
        if (!rootRef.current) return
        rootRef.current.classList.add(styles.fadeRehydrated)
      }, TRANSITION_DELAY)
      return () => clearTimeout(timeout)
    }, [defaultFadeVisible, fade, isValid])

    const classes = classNames(
      {
        [styles.root]: isValid,
        [styles.single]: isValid && mode === 'single',
        [styles.fade]: isValid && fade,
      },
      fadeVisible.current && fade && fadeClassMapping[fade],
      className
    )

    const truncateProps = isValid ? { style: { ...rest.style, ...mountStyle(value) } } : {}

    const Component = asChild ? Slot : 'div'

    const refs = useComposedRefs(forwardedRef, rootRef)

    return (
      <Component {...rest} {...truncateProps} className={classes} ref={refs}>
        {children}
      </Component>
    )
  }
)

function normalizeValue(truncate: TruncateTextValue): TruncateTextValueKind[] {
  const normalized = Array.isArray(truncate) ? truncate : [truncate]
  if (normalized.length === 0) return []
  return normalized.slice(0, 5)
}

function mountStyle(truncate: TruncateTextValueKind[]): Record<string, TruncateTextValueKind> {
  return truncate.reduce((acc, value, index) => {
    const v = typeof value === 'number' ? value : `'${value}'`
    return { ...acc, [`--t-${index}`]: v }
  }, {})
}

import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, isValidElement, ReactNode } from 'react'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { Slot } from '../utilities/slot/slot'
import { MenuItemContext } from './context'
import styles from './menu-item.module.scss'
import MenuItemContent from './menu-item-content'
import MenuItemHelpText from './menu-item-help-text'
import MenuItemLeading from './menu-item-leading'
import MenuItemTrailing from './menu-item-trailing'
import { useMenuItemProvider } from './use-menu-item'

export type MenuItemElement = HTMLButtonElement
export const MenuItemTypes = ['primary', 'danger'] as const
export type MenuItemType = (typeof MenuItemTypes)[number]
export const MenuItemOrientations = ['vertical', 'horizontal'] as const
export type MenuItemOrientation = (typeof MenuItemOrientations)[number]

export interface MenuItemProps extends Omit<ButtonHTMLAttributes<MenuItemElement>, 'type'> {
  children: ReactNode
  type?: MenuItemType
  disabled?: boolean
  current?: boolean
  block?: boolean
  className?: string
  asChild?: boolean
}

const MenuItemRoot = forwardRef<MenuItemElement, MenuItemProps>(function MenuItem(
  props,
  forwardedRef
) {
  const {
    className,
    type: typeProp,
    current: currentProp,
    block: blockProp,
    disabled,
    children,
    asChild: asChildProp,
    ...rest
  } = props

  const asChild = asChildProp && isValidElement(children)
  const Component = asChild ? Slot : 'button'

  const type = ensurePropOf<MenuItemType>(MenuItemTypes, typeProp, 'primary')
  const block = blockProp ?? true

  const context = useMenuItemProvider({
    ...props,
    type,
    block,
  })
  const { current } = context

  const classes = classNames(
    styles.root,
    {
      [styles[`type${type}`]]: !!type,
      [styles.current]: !!current,
      [styles.disabled]: !!disabled,
      [styles.block]: !!block,
    },
    className
  )

  return (
    <MenuItemContext.Provider value={context}>
      <Component
        {...rest}
        aria-current={current || undefined}
        disabled={!asChild ? disabled : undefined}
        className={classes}
        ref={forwardedRef}
      >
        {children}
      </Component>
    </MenuItemContext.Provider>
  )
})

export const MenuItem = assignSubComponents('MenuItem', MenuItemRoot, {
  Content: MenuItemContent,
  HelpText: MenuItemHelpText,
  Leading: MenuItemLeading,
  Trailing: MenuItemTrailing,
})

export * from './menu-item-content'
export * from './menu-item-help-text'
export * from './menu-item-leading'
export * from './menu-item-trailing'

import { LawsuitSummarizationStep } from '../constants'
import { LAWSUIT_SUMMARIZATION_STEPS } from '../settings'

export const getNextStep = (
  currentStep: LawsuitSummarizationStep,
  loading: boolean,
  summary?: string
): LawsuitSummarizationStep | null => {
  const step = LAWSUIT_SUMMARIZATION_STEPS[currentStep]
  const nextStep = step?.next

  if (!nextStep) {
    return null
  }

  if (Array.isArray(nextStep)) {
    for (const possibleStep of nextStep) {
      const { target, condition = () => true } = possibleStep

      if (condition(loading, summary)) {
        return target
      }
    }
  }

  if (typeof nextStep === 'string') {
    return nextStep
  }

  return null
}

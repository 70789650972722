import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { VisuallyHidden } from '../utilities'
import styles from './file-content.module.scss'
import { useFile } from './use-file'

export type FileContentElement = HTMLDivElement

export interface FileContentProps extends HTMLAttributes<FileContentElement> {
  children: ReactNode
}

const FileContent = forwardRef<FileContentElement, FileContentProps>(function FileContent(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { id, disabled } = useFile()
  const classes = classNames(
    styles.root,
    {
      [styles.disabled]: disabled,
    },
    className
  )

  return (
    <div {...rest} id={id} className={classes} ref={forwardedRef}>
      {children}
      <VisuallyHidden>Download</VisuallyHidden>
    </div>
  )
})

export default FileContent

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUser(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6a2 2 0 1 1 4 0c0 1.162-.585 2.187-1.26 2.974-.259.303-.52.556-.74.751a7.715 7.715 0 0 1-.74-.75C10.584 8.186 10 7.161 10 6Zm3.713 4.861 2.889 1.734A7 7 0 0 1 20 18.597V19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-.403a7 7 0 0 1 3.399-6.002l2.888-1.734a9.539 9.539 0 0 1-.546-.585C8.915 9.313 8 7.838 8 6a4 4 0 1 1 8 0c0 1.838-.915 3.313-1.74 4.276-.186.216-.371.412-.547.585ZM8.428 14.31 12 12.166l3.572 2.144A5 5 0 0 1 18 18.597V19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUser)

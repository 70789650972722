import classNames from 'classnames'
import { forwardRef } from 'react'

import {
  PrimitiveText,
  PrimitiveTextElement,
  PrimitiveTextProps,
} from '../primitive-text/primitive-text'
import { ensurePropOf } from '../utilities'
import styles from './heading.module.scss'

export const HeadingSizes = ['sm', 'md', 'lg', 'xl', '2xl', '3xl'] as const
export type HeadingSize = (typeof HeadingSizes)[number]
export type HeadingElement = PrimitiveTextElement
export interface HeadingProps extends PrimitiveTextProps {
  size?: HeadingSize
}

export const Heading = forwardRef<HeadingElement, HeadingProps>(function Heading(
  props,
  forwardedRef
) {
  const { children, asChild, size: sizeProp, className, ...rest } = props

  const size = ensurePropOf<HeadingSize>(HeadingSizes, sizeProp, 'md')

  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  return (
    <PrimitiveText {...rest} className={classes} ref={forwardedRef} asChild>
      {asChild ? children : <h6>{children}</h6>}
    </PrimitiveText>
  )
})

import { Avatar, Caption, Tooltip } from '@farol-ds/react'
import { forwardRef, useContext } from 'react'

import { JUSBRASIL_BASE_URL } from '../../constants'
import { TopbarContext } from '../../context'
import styles from './topbar-user-menu-content-profile-switch.module.scss'

export type TopbarUserMenuContentProfileSwitchElement = HTMLDivElement
export type TopbarUserMenuContentProfileSwitchProps = {
  // pass
}

const FORM_URL = `${JUSBRASIL_BASE_URL}/profile/switch`

export const TopbarUserMenuContentProfileSwitch = forwardRef<
  TopbarUserMenuContentProfileSwitchElement,
  TopbarUserMenuContentProfileSwitchProps
>(function TopbarUserMenuContentProfileSwitch(props, forwardedRef) {
  const { user } = useContext(TopbarContext)
  return (
    <div
      className={styles.root}
      ref={forwardedRef}
      data-testid="topbar-user-menu-content-profile-switch"
    >
      <Caption>Usar o Jusbrasil como:</Caption>

      <div className={styles.list}>
        {user?.profiles.map((profile) => (
          <form action={FORM_URL} method="POST" key={profile.pid} className={styles.listItem}>
            <input type="hidden" name="pid" value={profile.pid} />
            <input type="hidden" name="url" value={profile.url || ''} />

            <Tooltip label={profile.name}>
              <button type="submit">
                <Avatar src={profile.image || undefined} alt={user.name} />
              </button>
            </Tooltip>
          </form>
        ))}
      </div>
    </div>
  )
})

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgIaSubscribed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.143 3.186c-.433-.913-1.733-.915-2.168-.002L8.453 8.473 3.57 6.899A1.2 1.2 0 0 0 2.05 8.382l2.516 8.493c.151.51.62.859 1.151.859h5.351a1 1 0 0 0 0-2H6.315L4.4 9.267l4.107 1.324a1.2 1.2 0 0 0 1.45-.626l2.1-4.401 2.083 4.398a1.2 1.2 0 0 0 1.452.63l4.077-1.311-1.091 3.413a1 1 0 0 0 1.905.609l1.564-4.894a1.2 1.2 0 0 0-1.51-1.508l-4.89 1.572-2.503-5.287Zm3.01 13.733a2.537 2.537 0 0 1-1.104.648L14 17.863c-.308.086-.308.523 0 .61l1.049.296a2.53 2.53 0 0 1 1.613 1.374c.056.121.103.248.14.379l.295 1.048c.087.308.524.308.61 0l.296-1.048a2.53 2.53 0 0 1 1.375-1.613 2.53 2.53 0 0 1 .378-.14l1.05-.296c.307-.087.307-.524 0-.61l-1.05-.296a2.53 2.53 0 0 1-1.612-1.375 2.537 2.537 0 0 1-.14-.378l-.296-1.049c-.087-.308-.524-.308-.61 0l-.296 1.049-.648 1.105Zm-4.084 2.815a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2h6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgIaSubscribed)

export function getTakeoverStatusFromBrowser(pid?: number) {
  let cacheUserData = { takeover: false }

  // Reference for the key format in ui-topbar project:
  // https://github.com/jusbrasil/ui-topbar/blob/0551d3fe410c2c16b87bbff4c1404f44ab6e1c1f/src/store.js#L88 @diegodovale21
  const itemKey = `topbar-fetchUser-${pid}`

  try {
    const storedData = localStorage.getItem(itemKey)

    if (storedData) {
      cacheUserData = { ...cacheUserData, ...JSON.parse(storedData) }
    }
  } catch (error) {
    console.error(`Error parsing localStorage (${itemKey}):`, error)
  }

  return {
    takeover: cacheUserData.takeover || false,
  }
}

import { SVGProps } from 'react'

import { wrapIcon } from '../icon/wrap-icon'

function SvgSpinner(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21 12a9 9 0 1 0-1.447 4.893l-1.652-1.07A7.032 7.032 0 1 1 19.031 12H21Z" />
    </svg>
  )
}

export const SpinnerIcon = wrapIcon(SvgSpinner, {
  'aria-hidden': false,
  focusable: true,
})

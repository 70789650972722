import * as RAccordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { Slot, Slottable } from '../utilities'
import styles from './accordion-content.module.scss'
import { useAccordion } from './use-accordion'

export type AccordionContentElement = HTMLDivElement

export type AccordionContentProps = React.ComponentProps<typeof RAccordion.Content>

const AccordionContent = forwardRef<AccordionContentElement, AccordionContentProps>(
  function AccordionContent(props, forwardedRef) {
    const { className, children, asChild, ...rest } = props

    const { size } = useAccordion()
    const classes = classNames(
      styles.body,
      {
        [styles[`size${size}`]]: size !== 'md',
      },
      className
    )

    const Component = asChild ? Slot : 'div'

    return (
      <RAccordion.Content {...rest} className={styles.root} ref={forwardedRef}>
        <Slottable>
          <Component className={classes}>{children}</Component>
        </Slottable>
      </RAccordion.Content>
    )
  }
)

export default AccordionContent

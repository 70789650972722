import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'

import { BodyText, BodyTextElement, BodyTextProps } from '../body-text/body-text'
import styles from './detail-value.module.scss'
import { renderChildrenOrLink } from './utils'

export type DetailValueElement = BodyTextElement

export interface DetailValueProps extends BodyTextProps {
  children: ReactNode
  className?: string
  asChild?: boolean
}

const DetailValue = forwardRef<DetailValueElement, DetailValueProps>(function DetailValue(
  props,
  forwardedRef
) {
  const { children, className, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <BodyText
      {...rest}
      data-fds-detail-value
      size="md"
      className={classes}
      ref={forwardedRef}
      asChild={asChild}
    >
      {renderChildrenOrLink(children)}
    </BodyText>
  )
})

export default DetailValue

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, useContext, useEffect, useRef } from 'react'

import { Slot } from '../utilities/slot/slot'
import styles from './collapse.module.scss'
import { CollapseContext } from './context'

export type CollapseContentElement = HTMLDivElement
export type CollapseContentProps = HTMLAttributes<CollapseContentElement> & {
  asChild?: boolean
}

const CollapseContent = forwardRef<CollapseContentElement, CollapseContentProps>(
  function CollapseContent(props, forwardedRef) {
    const { children, className, asChild, ...rest } = props

    const { id, open } = useContext(CollapseContext)
    const ref = useRef<CollapseContentElement>(null)
    const refs = useComposedRefs(ref, forwardedRef)

    useEffect(() => {
      if (!ref.current) return
      ref.current.style.maxHeight = open ? `${ref.current.scrollHeight}px` : '0'
    }, [open])

    const classes = classNames(styles.content, className)

    const Component = asChild ? Slot : 'div'

    return (
      <Component {...rest} className={classes} id={id} aria-hidden={!open} ref={refs}>
        {children}
      </Component>
    )
  }
)

export default CollapseContent

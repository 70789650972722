import classNames from 'classnames'
import { useCallback, useEffect, useRef } from 'react'

import { registry } from './constants'
import { bindEvents, triggerEvent } from './internal'
import styles from './styles.module.scss'
import { FrameBusElement, ListenersBase } from './types'

export type UseFrameBusClientIframeMode = 'MODAL' | 'INLINE'

export type UseFrameBusClientProps<ClientListeners> = {
  id: string
  src: string
  iframeMode?: UseFrameBusClientIframeMode
  listeners?: ClientListeners
  defaultOpen?: boolean
  container?: HTMLElement
  className?: string
  enabled?: boolean
  onConnect?: () => void
  onReconnect?: () => void
  onPopstate?: () => void
}

export function useFrameBusClient<
  HostListeners extends ListenersBase,
  ClientListeners extends ListenersBase = ListenersBase
>(props: UseFrameBusClientProps<ClientListeners>) {
  const {
    id,
    src,
    listeners = {},
    defaultOpen = false,
    enabled = true,
    iframeMode = 'MODAL',
    container: containerProp,
    onConnect,
    onReconnect,
    onPopstate,
    className,
  } = props

  const hostLoadedRef = useRef<boolean>(false)
  const connectedRef = useRef<boolean>(false)
  const openedRef = useRef<boolean>(defaultOpen)
  const iframeRef = useRef<FrameBusElement | null>(null)

  const container = useCallback(() => {
    return containerProp || document.body
  }, [containerProp])

  const connect = () => {
    if (!connectedRef.current) {
      onConnect?.()
    } else if (hostLoadedRef.current) {
      onReconnect?.()
    }
    connectedRef.current = true
  }

  const setOpen = useCallback(
    (iframe: HTMLIFrameElement, nextOpen: boolean) => {
      if (!enabled) return
      openedRef.current = nextOpen
      iframe.dataset.opened = String(nextOpen)

      if (iframeMode === 'MODAL') {
        const c = container()
        if (nextOpen) {
          c.style.overflow = 'hidden'
        } else {
          c.style.removeProperty('overflow')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container, enabled]
  )

  const triggerHost = useCallback(
    <K extends keyof HostListeners>(name: K, detail?: Parameters<HostListeners[K]>[0]) => {
      if (!iframeRef.current) return
      triggerEvent(iframeRef.current, id, name as string, detail)
    },
    [id]
  )

  useEffect(() => {
    hostLoadedRef.current = false
    connectedRef.current = false
  }, [id, src])

  useEffect(() => {
    if (!enabled) return
    const c = container()

    if (!registry[id]) {
      const iframe = document.createElement('iframe')
      iframe.id = id
      iframe.src = src
      iframe.className = classNames(
        styles.root,
        {
          [styles.modal]: iframeMode === 'MODAL',
          [styles.inline]: iframeMode === 'INLINE',
        },
        className
      )
      iframe.dataset.opened = String(openedRef.current)
      iframe.setAttribute('data-testid', 'frame-bus-iframe')
      iframeRef.current = iframe
      registry[id] = {
        element: iframe,
        count: 0,
      }
      c.appendChild(iframe)
    }

    const iframe = registry[id].element
    iframeRef.current = iframe

    registry[id].count += 1

    const unbindInternalListenerEvents = bindEvents(id, iframe, {
      open: () => setOpen(iframe, true),
      close: () => setOpen(iframe, false),
      toggle: (next?: boolean) => setOpen(iframe, next ?? !openedRef.current),
      connect,
      pong: () => {
        if (!enabled) return
        if (connectedRef.current) return
        connect()
      },
    })

    setOpen(iframe, openedRef.current)

    return () => {
      registry[id].count -= 1
      if (registry[id].count === 0) {
        delete registry[id]
        c.removeChild(iframe)
      }
      iframeRef.current = null
      unbindInternalListenerEvents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, id, src, enabled, iframeMode, className])

  useEffect(() => {
    if (!enabled || !iframeRef.current) return
    const unbindUserListenerEvents = bindEvents(id, iframeRef.current, listeners)
    return () => unbindUserListenerEvents()
  }, [enabled, id, listeners])

  useEffect(() => {
    if (!enabled || !onPopstate) return
    window.addEventListener('popstate', onPopstate)
    return () => {
      window.removeEventListener('popstate', onPopstate)
    }
  }, [enabled, onPopstate])

  useEffect(() => {
    if (!enabled) return
    function handle() {
      hostLoadedRef.current = true
    }
    window.addEventListener('load', handle)
    return () => window.removeEventListener('load', handle)
  }, [enabled])

  // ensure connected for multiple clients in the same host
  useEffect(() => {
    if (!enabled) return
    if (connectedRef.current) return
    // no connected yet? check if host is ready
    triggerHost('ping')
  }, [enabled, triggerHost])

  const triggerClient = useCallback(
    <K extends keyof ClientListeners>(name: K, detail?: Parameters<ClientListeners[K]>[0]) => {
      if (!enabled || !iframeRef.current) return
      triggerEvent(iframeRef.current, id, name as string, detail)
    },
    [enabled, id]
  )

  return {
    ref: iframeRef,
    isOpen() {
      return openedRef.current
    },
    open() {
      triggerHost('open')
    },
    close() {
      triggerHost('close')
    },
    toggle(nextOpen?: boolean) {
      triggerHost('toggle', nextOpen)
    },
    trigger<T extends keyof ClientListeners>(name: T, detail?: Parameters<ClientListeners[T]>[0]) {
      triggerClient(name, detail)
    },
    triggerHost,
  }
}

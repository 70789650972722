import * as RDialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { BodyText, BodyTextElement, BodyTextProps } from '../body-text/body-text'
import styles from './modal-header-description.module.scss'

export type ModalHeaderDescriptionElement = BodyTextElement
export type ModalHeaderDescriptionProps = BodyTextProps

const ModalHeaderDescription = forwardRef<
  ModalHeaderDescriptionElement,
  ModalHeaderDescriptionProps
>(function ModalHeaderDescription(props, forwardedRef) {
  const { className, ...rest } = props
  const classes = classNames(styles.root, className)
  return (
    <RDialog.Description asChild>
      <BodyText {...rest} size="md" className={classes} ref={forwardedRef} />
    </RDialog.Description>
  )
})

export default ModalHeaderDescription

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSend(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.902 3.3c-1.678-.817-3.46.906-2.703 2.61L5.906 12l-2.707 6.09c-.758 1.704 1.025 3.427 2.703 2.61L20.089 13.8c1.5-.73 1.5-2.868 0-3.598L5.902 3.3Zm-.875 1.798L19.214 12 5.027 18.902 7.65 13H11a1 1 0 1 0 0-2H7.65L5.027 5.098Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSend)

import { FRAME_BUS_PAGE_PROP } from './constants'

export function withFrameBusProps() {
  return {
    [FRAME_BUS_PAGE_PROP]: true,
  }
}

export function isFrameBusPage(props: Record<string, unknown>) {
  return props[FRAME_BUS_PAGE_PROP] === true
}

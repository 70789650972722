import {
  BodyText,
  Box_unstable as Box,
  Flex_unstable as Flex,
  Heading,
  Information,
  Modal,
  useToast,
} from '@farol-ds/react'
import React, { useEffect, useState } from 'react'

import styles from '../lawsuit-summary-modal.module.scss'
import { LawsuitSummarizationSurvey } from './summarization-survey'

interface SummaryViewProps {
  lawsuitId: string
  isMobile?: boolean
  title: React.ReactNode
  summary?: string
  onSubmitSummarizationSurvey?: (surveyData: any) => void
  onSendEvent: (event: any) => void
}

function getSurveyKey(lawsuitId: string) {
  return `Lawsuit.Survey:${lawsuitId}`
}

export function SummaryView(props: SummaryViewProps) {
  const { lawsuitId, isMobile, title, summary, onSubmitSummarizationSurvey, onSendEvent } = props
  const toast = useToast()

  const hasAnsweredSurvey = localStorage.getItem(getSurveyKey(lawsuitId)) === 'true'
  const [isSurveyFinished, setIsSurveyFinished] = useState<boolean>(hasAnsweredSurvey)

  useEffect(() => {
    onSendEvent('Lawsuit.LawsuitSummaryGeneratingModalSuccess')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinishSurvey = (surveyData: any) => {
    localStorage.setItem(getSurveyKey(lawsuitId), 'true')

    toast('Agradecemos sua resposta')
    setIsSurveyFinished(true)

    if (onSubmitSummarizationSurvey) {
      onSubmitSummarizationSurvey(surveyData)
    }
  }

  if (summary === undefined) {
    return <></>
  }

  const paragraphs = [...summary.matchAll(/<p>(.*?)<\/p>/g)].map((match) => match[1])
  const infoLabel =
    'Texto gerado por inteligência artificial. Consulte uma pessoa advogada para confirmar as informações.'

  return (
    <>
      <Modal.IconClose />
      <Modal.Header>
        <React.Fragment key=".0">
          <Modal.HeaderTitle>{title}</Modal.HeaderTitle>
        </React.Fragment>
      </Modal.Header>
      <Modal.Body data-testid="lawsuit-summary-modal-summary-view-step">
        <Flex gap={'3xs'} direction={'column'}>
          <Box>
            <Heading className={styles.title} size="sm" asChild>
              <h2>Sobre o que é o processo?</h2>
            </Heading>

            <BodyText size="md">{paragraphs[0]}</BodyText>
          </Box>
          <Box>
            <Heading className={styles.title} size="sm" asChild>
              <h2>Detalhes</h2>
            </Heading>

            <BodyText size="md">{paragraphs[1]}</BodyText>
          </Box>

          <div className={styles.summaryGenerationInfoCard}>
            <BodyText size="md">Gerado por inteligência artificial</BodyText>
            <div className={styles.summaryGenerationInfoIcon}>
              <Information label={infoLabel} size="xs" />
            </div>
          </div>

          {!isSurveyFinished && (
            <LawsuitSummarizationSurvey
              onFinish={onFinishSurvey}
              onSendEvent={onSendEvent}
              isMobile={isMobile}
            />
          )}
        </Flex>
      </Modal.Body>
    </>
  )
}

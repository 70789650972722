import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import { cloneElement, isValidElement } from 'react'

import { MenuItemProps } from '../menu-item/menu-item'

export type DropdownMenuItemElement = typeof RDropdownMenu.Item
export type DropdownMenuItemProps = RDropdownMenu.DropdownMenuItemProps

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = (props) => {
  const { children, disabled, ...rest } = props
  return (
    <RDropdownMenu.Item {...rest} disabled={disabled} asChild>
      {isValidElement<MenuItemProps>(children) ? cloneElement(children, { disabled }) : children}
    </RDropdownMenu.Item>
  )
}

export default DropdownMenuItem

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLawyerDirectory(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C13.416 8.186 14 7.161 14 6a2 2 0 0 0-2-2Zm.556 7.831s-.001.001-.556-.831l.555.832a1.002 1.002 0 0 1-.04.025L8.428 14.31A5 5 0 0 0 6 18.597V19a1 1 0 0 0 1 1h3a1 1 0 1 1 0 2H7a3 3 0 0 1-3-3v-.403a7 7 0 0 1 3.399-6.002l2.888-1.734a9.539 9.539 0 0 1-.546-.585C8.915 9.313 8 7.838 8 6a4 4 0 1 1 8 0c0 1.838-.915 3.313-1.74 4.276a9.758 9.758 0 0 1-1.537 1.437 5.759 5.759 0 0 1-.116.083l-.034.024-.012.008-.004.002-.001.001ZM14 16.366a2.166 2.166 0 1 1 4.332 0 2.166 2.166 0 0 1-4.332 0Zm2.166-4.166a4.166 4.166 0 1 0 1.975 7.835l1.672 1.672a1 1 0 0 0 1.414-1.414l-1.603-1.603a4.166 4.166 0 0 0-3.458-6.49Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLawyerDirectory)

import classNames from 'classnames'
import { forwardRef, ReactNode, useContext } from 'react'

import { TopbarContext } from '../../context'
import styles from './topbar-container.module.scss'

export type TopbarContainerElement = HTMLDivElement

export interface TopbarContainerProps {
  children: ReactNode
  className?: string
}

export const TopbarContainer = forwardRef<TopbarContainerElement, TopbarContainerProps>(
  function TopbarContainer(props: TopbarContainerProps, forwardedRef) {
    const { children, className } = props

    const context = useContext(TopbarContext)

    const classes = classNames(
      context.position && styles[`position${context.position}`],
      context.variant && styles[`variant${context.variant}`],
      className
    )

    // If the position is fixed, we need to wrap the container in a div
    // See details in the scss file
    if (context.position === 'fixed' || context.position === 'fixed-mobile-only') {
      return (
        <div className={classes || undefined} ref={forwardedRef}>
          <div
            className={classNames(styles.root, {
              [styles.takeover]: context.takeover,
            })}
          >
            {children}
          </div>
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.root, className, {
          [styles.takeover]: context.takeover,
        })}
        ref={forwardedRef}
      >
        {children}
      </div>
    )
  }
)

import { BifrostWindowObject } from '@jusbrasil-web/shared-bifrost'
import { attachBrowserBackButtonListener } from '@jusbrasil-web/shared-browser-back-button-listener'

//eslint-disable-next-line @typescript-eslint/no-empty-function
export const handleBackButtonListener = (close = () => {}) => {
  attachBrowserBackButtonListener('modal', () => {
    window.Bifrost?.close()
    close()
  })
}

declare global {
  interface Window {
    Bifrost?: BifrostWindowObject
  }
}

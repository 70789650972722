import { durationMedium2 } from '@farol-ds/tokens'
import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { ButtonElement } from '../button/button'
import {
  CollapseControl,
  CollapseControlElement,
  CollapseControlProps,
} from '../collapse-control/collapse-control'
import {
  TruncateText,
  TruncateTextElement,
  TruncateTextProps,
} from '../truncate-text/truncate-text'
import styles from './collapse-text.module.scss'
import { incrementTruncateTextValue } from './helpers'

const TRANSITION_DELAY = 1000 // see $transition-delay in collapse-text.module.scss

export type CollapseTextElement = TruncateTextElement

export type CollapseTextProps = TruncateTextProps & {
  onOpenChange?: (open: boolean, evt: MouseEvent<ButtonElement>) => void
  onTruncateChange?: (truncated: boolean) => void // called when the truncation state changes
  onTruncationMount?: (truncated: boolean) => void // called when the component mounts, passing the initial trunc. state
  rootClassName?: string
  truncateContainerClassName?: string
  defaultControlVisible?: boolean
  collapseControlProps?: Omit<CollapseControlProps, 'open' | 'onClick'>
}

export const CollapseText = forwardRef<CollapseTextElement, CollapseTextProps>(
  function CollapseText(props, forwardedRef) {
    const {
      className,
      onOpenChange,
      onTruncateChange,
      onTruncationMount: onTruncationMountProp,
      rootClassName,
      truncateContainerClassName,
      defaultControlVisible = false,
      value: valueProp,
      collapseControlProps = {},
      ...rest
    } = props

    const value = incrementTruncateTextValue(valueProp, 1)

    const initializedRef = useRef(defaultControlVisible)
    const rehydratedRef = useRef(defaultControlVisible)
    const clientHeightRef = useRef<number>(0)

    const [visible, _setVisible] = useState(defaultControlVisible)
    const [open, setOpen] = useState(false)

    const rootRef = useRef<HTMLDivElement>(null)
    const collapseRef = useRef<CollapseControlElement>(null)
    const truncateContainerRef = useRef<HTMLDivElement>(null) // Truncate Container
    const truncateTextRef = useRef<CollapseTextElement>(null) // Truncate Text
    const truncateTextRefs = useComposedRefs(truncateTextRef, forwardedRef)
    const onTruncationMountCalledRef = useRef(false)

    const setVisible = useCallback(
      (value: boolean) => {
        _setVisible(value)
        onTruncateChange?.(value)
      },
      [onTruncateChange]
    )

    const onTruncationMount = useCallback(
      (value: boolean) => {
        if (!onTruncationMountProp || onTruncationMountCalledRef.current) return
        onTruncationMountProp(value)
        onTruncationMountCalledRef.current = true
      },
      [onTruncationMountProp]
    )

    useEffect(() => {
      const tcEl = truncateContainerRef.current
      const ttEl = truncateTextRef.current
      if (!ttEl || !tcEl) return
      const resizer = new ResizeObserver(() => {
        if (open) return
        // should be truncated?
        const nextVisible = ttEl.scrollHeight > ttEl.clientHeight
        if (nextVisible !== visible) {
          setVisible(nextVisible)
          // [animation] backup unclamped height
          clientHeightRef.current = ttEl.clientHeight
        } else if (!visible) {
          tcEl.style.removeProperty('max-height')
        } else {
          tcEl.style.maxHeight = `${clientHeightRef.current}px`
        }
        onTruncationMount(nextVisible)
      })
      resizer.observe(ttEl)
      return () => {
        resizer.disconnect()
      }
    }, [open, visible, defaultControlVisible, onTruncationMount, setVisible])

    // [animation] set initial height
    useEffect(() => {
      initializedRef.current = true
      const tcEl = truncateContainerRef.current
      const ttEl = truncateTextRef.current
      if (!tcEl || !ttEl) return
      tcEl.style.maxHeight = `${ttEl.clientHeight}px`
      clientHeightRef.current = ttEl.clientHeight
    }, [])

    // mark as rehydrated
    useEffect(() => {
      setTimeout(() => {
        rehydratedRef.current = true
      }, TRANSITION_DELAY)
    }, [])

    const hasTrunc = initializedRef.current ? visible || open : defaultControlVisible
    const hasRehydrated = rehydratedRef.current

    return (
      <div
        className={classNames(
          styles.root,
          {
            [styles.rehydrated]: hasRehydrated,
          },
          rootClassName
        )}
        data-open={open}
        data-testid="collapse-text"
        ref={rootRef}
      >
        <div
          className={classNames(styles.trunc, truncateContainerClassName)}
          ref={truncateContainerRef}
        >
          <TruncateText
            {...rest}
            value={value}
            className={classNames(
              {
                [styles.truncTextUnclamp]: open,
              },
              className
            )}
            ref={truncateTextRefs}
          />
        </div>

        <CollapseControl
          {...collapseControlProps}
          containerClassName={classNames(styles.control, {
            [styles.controlVisible]: hasTrunc,
          })}
          open={open}
          onClick={(evt) => {
            const nextOpen = !open
            const toggle = () => setOpen(nextOpen)

            onOpenChange?.(nextOpen, evt)

            const tcEl = truncateContainerRef.current
            const ttEl = truncateTextRef.current
            if (!tcEl || !ttEl) {
              return toggle()
            }

            if (rootRef.current) {
              rootRef.current.dataset.open = nextOpen.toString()
            }

            if (nextOpen) {
              toggle()
            } else {
              collapseRef.current?.animateToggle(nextOpen)
              setTimeout(toggle, durationMedium2)
            }

            // [NOTE]: @gabrielrtakeda
            // added `maxHeight` element mutation outside of js's single-threaded callback
            // to be executed *after* truncateText 'unclamp' behavior. this way, we can get the
            // "real" truncateText's full-height because the 'unclamped' DOM is ready.
            setTimeout(() => {
              // [animation] set final height
              tcEl.style.maxHeight = `${nextOpen ? ttEl.scrollHeight : clientHeightRef.current}px`
            }, 0)
          }}
          ref={collapseRef}
          data-testid={`collapse-text-control${hasTrunc ? '-truncated' : ''}`}
        />
      </div>
    )
  }
)

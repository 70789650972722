import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNoteMultiple(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5.172a3 3 0 0 1-.879 2.12l-5.828 5.83a3 3 0 0 1-2.121.878H8a2 2 0 0 1-2-2V4Zm14 0v4a1 1 0 0 1-1 1h-5a1 1 0 0 0-1 1v5a1 1 0 0 1-1 1H8V4h12Zm-5 7v3.586L18.586 11H15ZM4 9a1 1 0 0 0-2 0v11a2 2 0 0 0 2 2h11a1 1 0 1 0 0-2H4V9Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNoteMultiple)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgListUnordered(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H4Zm6 0a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H10Zm0 6a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H10Zm-1 7a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1Zm-6-6a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm1 5a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgListUnordered)

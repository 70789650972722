import type { SVGProps } from 'react'

export type BrandElement = SVGSVGElement
export type BrandVersion = (typeof BrandVersions)[number]
export const BrandVersions = ['positive', 'negative'] as const
export interface BrandProps extends SVGProps<BrandElement> {
  onlySymbol?: boolean
  version?: BrandVersion
  className?: string
}

export const withSVGProps = <T extends SVGProps<BrandElement>>(props: T) => {
  const { width, height, ...rest } = props
  return {
    ...rest,
    width: width ?? (height ? undefined : '100%'),
    height,
  }
}

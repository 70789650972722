import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCheckDouble(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.037 7.707a1 1 0 0 0-1.414-1.414l-3.33 3.33a1 1 0 1 0 1.414 1.414l3.33-3.33Zm-13 3.586a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414-1.414l-4-4Zm18.67-3.586a1 1 0 0 0-1.414-1.414L12 14.586l-3.293-3.293a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l9-9Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCheckDouble)

export const borderRadiusXs = 6;
export const borderRadiusSm = 8;
export const borderRadiusMd = 16;
export const borderRadiusLg = 24;
export const borderRadiusPill = 500;
export const borderRadiusCircle = "50%";
export const borderWidthSm = 1;
export const borderWidthMd = 2;
export const borderWidthLg = 4;
export const breakpointXs = 0;
export const breakpointSm = 768;
export const breakpointMd = 1024;
export const breakpointLg = 1280;
export const breakpointXl = 1440;
export const colorBaseLow1 = "#2B3950";
export const colorBaseLow2 = "#455468";
export const colorBaseLow3 = "#5C6F8A";
export const colorBaseLow4 = "#788BA5";
export const colorBaseLowPure = "#0F172A";
export const colorBaseHigh1 = "#F1F5F9";
export const colorBaseHigh2 = "#E2E8F0";
export const colorBaseHigh3 = "#CCD5E1";
export const colorBaseHigh4 = "#B3C0D0";
export const colorBaseHighPure = "#FFFFFF";
export const colorBrandPrimary1 = "#E6F4F0";
export const colorBrandPrimary2 = "#C9E9DF";
export const colorBrandPrimary3 = "#00664F";
export const colorBrandPrimary4 = "#004D3B";
export const colorBrandPrimaryPure = "#007A5F";
export const colorBrandSecondary1 = "#F2F8EC";
export const colorBrandSecondary2 = "#D9EBC6";
export const colorBrandSecondary3 = "#719F41";
export const colorBrandSecondary4 = "#527231";
export const colorBrandSecondaryPure = "#7AB441";
export const colorBrandTertiary1 = "#E7F5FD";
export const colorBrandTertiary2 = "#9BCDFF";
export const colorBrandTertiary3 = "#0077C0";
export const colorBrandTertiary4 = "#005D95";
export const colorBrandTertiaryPure = "#0474E2";
export const colorFeedbackNegative1 = "#FEE2E3";
export const colorFeedbackNegative2 = "#FDBAC5";
export const colorFeedbackNegative3 = "#C20A28";
export const colorFeedbackNegative4 = "#96081F";
export const colorFeedbackNegativePure = "#E41E3F";
export const colorFeedbackPositive1 = "#F3F8ED";
export const colorFeedbackPositive2 = "#DBEACB";
export const colorFeedbackPositive3 = "#669337";
export const colorFeedbackPositive4 = "#4F722A";
export const colorFeedbackPositivePure = "#98CC60";
export const colorFeedbackInfo1 = "#EBF5FF";
export const colorFeedbackInfo2 = "#9BCDFF";
export const colorFeedbackInfo3 = "#0467C9";
export const colorFeedbackInfo4 = "#0353A2";
export const colorFeedbackInfoPure = "#0474E2";
export const colorFeedbackWarning1 = "#FEFAEC";
export const colorFeedbackWarning2 = "#FDF0BB";
export const colorFeedbackWarning3 = "#E3B806";
export const colorFeedbackWarning4 = "#826A03";
export const colorFeedbackWarningPure = "#FBD94C";
export const cBrandTextColorPositive = "#595B63";
export const cBrandTextColorNegative = "#FFFFFF";
export const cOverlayBackground = "#0F172A";
export const extraColorVisited = "#551A8B";
export const extraFocusOutline = "2px solid rgba(4, 116, 226, 0.8)";
export const extraFocusOutlineOffset = 2;
export const durationFast1 = 80;
export const durationFast2 = 160;
export const durationMedium1 = 240;
export const durationMedium2 = 320;
export const durationSlow1 = 480;
export const durationSlow2 = 720;
export const easeProductive = "cubic-bezier(0, 0, 0.3, 1)";
export const easeExpressive = "cubic-bezier(0.4, 0.12, 0.4, 1)";
export const easeLoop = "cubic-bezier(0, 0, 1, 1)";
export const rotateXs = "rotate(45deg)";
export const rotateSm = "rotate(90deg)";
export const rotateMd = "rotate(180deg)";
export const rotateLg = "rotate(270deg)";
export const rotateXl = "rotate(360deg)";
export const scaleXs = "scale(0.92)";
export const scaleSm = "scale(0.96)";
export const scaleMd = "scale(1.04)";
export const scaleLg = "scale(1.08)";
export const scaleXl = "scale(1.16)";
export const opacityLevel1 = 0.8;
export const opacityLevel2 = 0.64;
export const opacityLevel3 = 0.32;
export const opacityLevel4 = 0.16;
export const opacityLevel5 = 0.08;
export const shadowLevel1 = "0 2px 4px 0 rgba(25, 52, 102, 0.04)";
export const shadowLevel2 = "0 2px 8px 0 rgba(25, 52, 102, 0.08)";
export const shadowLevel3 = "0 4px 24px 0 rgba(25, 52, 102, 0.08)";
export const shadowLevel4 = "0 8px 40px 0 rgba(25, 52, 102, 0.08)";
export const shadowLevel5 = "0 16px 56px 0 rgba(25, 52, 102, 0.08)";
export const spacing6xs = 4;
export const spacing5xs = 8;
export const spacing4xs = 12;
export const spacing3xs = 16;
export const spacing2xs = 24;
export const spacingXs = 32;
export const spacingSm = 40;
export const spacingMd = 48;
export const spacingLg = 56;
export const spacingXl = 64;
export const spacing2xl = 80;
export const spacing3xl = 96;
export const spacing4xl = 120;
export const spacing5xl = 160;
export const spacing6xl = 200;
export const fontFamilyBase = "Inter,system-ui,-apple-system,'Segoe UI',Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif";
export const fontFamilyVariant = "Georgia,'Times New Roman',Times,serif";
export const fontSize4xs = 10;
export const fontSize3xs = 12;
export const fontSize2xs = 14;
export const fontSizeXs = 16;
export const fontSizeSm = 18;
export const fontSizeMd = 20;
export const fontSizeLg = 24;
export const fontSizeXl = 28;
export const fontSize2xl = 32;
export const fontSize3xl = 40;
export const fontSize4xl = 48;
export const fontSize5xl = 56;
export const fontSize6xl = 68;
export const fontWeightBold = 700;
export const fontWeightSemibold = 600;
export const fontWeightRegular = 400;
export const letterSpacingSm = -0.4;
export const letterSpacingMd = 0.4;
export const lineHeightXs = 1;
export const lineHeightSm = 1.3;
export const lineHeightMd = 1.5;
export const lineHeightLg = 1.7;
export const colorBaseLow1Dark = "#EFF0F0";
export const colorBaseLow2Dark = "#CFD1D3";
export const colorBaseLow3Dark = "#AFB2B6";
export const colorBaseLow4Dark = "#979BA1";
export const colorBaseLowPureDark = "#FFFFFF";
export const colorBaseHigh1Dark = "#292B2E";
export const colorBaseHigh2Dark = "#333538";
export const colorBaseHigh3Dark = "#3F4246";
export const colorBaseHigh4Dark = "#4B4E53";
export const colorBaseHighPureDark = "#1B1C1E";
export const colorBrandPrimary1Dark = "#00291F";
export const colorBrandPrimary2Dark = "#004233";
export const colorBrandPrimary3Dark = "#00B88F";
export const colorBrandPrimary4Dark = "#00E0AF";
export const colorBrandPrimaryPureDark = "#00A37F";
export const colorBrandSecondary1Dark = "#293919";
export const colorBrandSecondary2Dark = "#4B692B";
export const colorBrandSecondary3Dark = "#99C969";
export const colorBrandSecondary4Dark = "#B5D991";
export const colorBrandSecondaryPureDark = "#8CC256";
export const colorBrandTertiary1Dark = "#012950";
export const colorBrandTertiary2Dark = "#023B73";
export const colorBrandTertiary3Dark = "#4BA4FC";
export const colorBrandTertiary4Dark = "#7ACAFF";
export const colorBrandTertiaryPureDark = "#2390FB";
export const colorFeedbackNegative1Dark = "#3A030C";
export const colorFeedbackNegative2Dark = "#660515";
export const colorFeedbackNegative3Dark = "#FCA1B0";
export const colorFeedbackNegative4Dark = "#FECDD5";
export const colorFeedbackNegativePureDark = "#E94964";
export const colorFeedbackPositive1Dark = "#293C16";
export const colorFeedbackPositive2Dark = "#4B6C28";
export const colorFeedbackPositive3Dark = "#A7D478";
export const colorFeedbackPositive4Dark = "#C2E1A2";
export const colorFeedbackPositivePureDark = "#98CC60";
export const colorFeedbackInfo1Dark = "#012950";
export const colorFeedbackInfo2Dark = "#023B73";
export const colorFeedbackInfo3Dark = "#5FAEFC";
export const colorFeedbackInfo4Dark = "#91C7FD";
export const colorFeedbackInfoPureDark = "#379AFB";
export const colorFeedbackWarning1Dark = "#473A00";
export const colorFeedbackWarning2Dark = "#725D03";
export const colorFeedbackWarning3Dark = "#FCE173";
export const colorFeedbackWarning4Dark = "#FDECA5";
export const colorFeedbackWarningPureDark = "#FBD94B";
export const cBrandTextColorPositiveDark = "#FFFFFF";
export const cBrandTextColorNegativeDark = "#595B63";
export const cOverlayBackgroundDark = "#3F4246";
export const extraColorVisitedDark = "#A666E1";
import { forwardRef } from 'react'

import { BodyTextElement, BodyTextProps } from '../body-text/body-text'
import { Slot } from '../utilities'
import { renderCaptionItemChildren } from './shared-helpers'

export type SnippetCaptionItemElement = BodyTextElement

export type SnippetCaptionItemProps = BodyTextProps

const SnippetCaptionItem = forwardRef<SnippetCaptionItemElement, SnippetCaptionItemProps>(
  function SnippetCaptionItem(props, forwardedRef) {
    const { children: _, asChild, ...rest } = props

    const children = renderCaptionItemChildren(props)

    const Component = asChild ? Slot : 'span'

    return (
      <Component {...rest} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default SnippetCaptionItem

import { useAppRootContext } from '@jusbrasil-web/shared-app-root-provider'
import { useContext } from 'react'

import { USER_SESSION_INTERNAL_DATA } from '../constants'
import { UserSessionContext } from '../context/context'
import { UserSession, WithUserSessionReturn } from '../types'

export function useUserSession(): UserSession {
  const { getPagePropRequired } = useAppRootContext<WithUserSessionReturn>()
  // TODO: remove this line when AppRootProvider replaces it completely. @felipemsantana
  const userSessionContext = useContext(UserSessionContext)
  return getPagePropRequired(USER_SESSION_INTERNAL_DATA) || userSessionContext
}

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

type Parameters = {
  componentClass?: string | null
  componentName?: string | null
  pagePosition?: string | null
  documentId?: string | null
  resourceId?: string | null
  componentRenderedClass?: string | null
  componentRenderedName?: string | null
}

export class GA4CustomComponentRenderedEvent extends GA4Event {
  ga4_component_class?: string | null
  ga4_component_name?: string | null
  ga4_page_position?: string | null
  ga4_document_id?: string | null
  ga4_resource_id?: string | null
  ga4_component_rendered_class?: string | null
  ga4_component_rendered_name?: string | null

  constructor({
    componentClass = null,
    componentName = null,
    pagePosition = null,
    documentId = null,
    resourceId = null,
    componentRenderedClass = null,
    componentRenderedName = null,
  }: Parameters) {
    super({ event: EVENT.CUSTOM_COMPONENT_RENDERED })

    this.ga4_component_class = componentClass
    this.ga4_component_name = componentName
    this.ga4_page_position = pagePosition
    this.ga4_document_id = documentId
    this.ga4_resource_id = resourceId
    this.ga4_component_rendered_class = componentRenderedClass
    this.ga4_component_rendered_name = componentRenderedName
  }
}

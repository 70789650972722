import * as RDialog from '@radix-ui/react-dialog'

export type DrawerTriggerElement = typeof RDialog.DialogTrigger

export type DrawerTriggerProps = RDialog.DialogTriggerProps

const DrawerTrigger: React.FC<DrawerTriggerProps> = (props) => {
  return <RDialog.Trigger asChild {...props} />
}

export default DrawerTrigger

import * as RDialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { Overlay } from '../overlay/overlay'
import commonStyles from './common.module.scss'
import styles from './modal-content.module.scss'
import { useModal } from './use-modal'

export type ModalContentElement = HTMLDivElement
export type ModalContentProps = RDialog.DialogContentProps & {
  containerClassName?: string
}

const ModalContent = forwardRef<ModalContentElement, ModalContentProps>(function ModalContent(
  props,
  forwardedRef
) {
  const { className, children, containerClassName, ...rest } = props

  const { isMount, size, onOpenChange } = useModal()

  const classes = classNames(
    styles.root,
    commonStyles.content,
    {
      [styles.rootVisible]: isMount,
      [styles[`size${size}`]]: size,
    },
    className
  )
  const containerClasses = classNames(
    styles.container,
    {
      [styles.containerVisible]: isMount,
    },
    containerClassName
  )

  function handleOverlayClick() {
    onOpenChange?.(false)
  }

  return (
    <RDialog.Portal>
      <RDialog.Overlay asChild>
        <Overlay visible={isMount} />
      </RDialog.Overlay>

      <RDialog.Content
        aria-describedby={undefined}
        {...rest}
        className={classes}
        ref={forwardedRef}
      >
        <div
          data-aria-hidden="true"
          aria-hidden="true"
          className={styles.overlay}
          onClick={handleOverlayClick}
        />

        <div className={containerClasses}>{children}</div>
      </RDialog.Content>
    </RDialog.Portal>
  )
})

export default ModalContent

import * as RNavigationMenu from '@radix-ui/react-navigation-menu'
import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react'

import { MenuItem, MenuItemProps } from '../menu-item/menu-item'
import { ensurePropOf } from '../utilities'
import styles from './menu.module.scss'

export type MenuElement = HTMLUListElement
export const MenuOrientations = ['vertical', 'horizontal'] as const
export type MenuOrientation = (typeof MenuOrientations)[number]

export interface MenuProps extends HTMLAttributes<MenuElement> {
  children: ReactNode
  orientation?: MenuOrientation
  className?: string
  asChild?: boolean
}

export const Menu = forwardRef<MenuElement, MenuProps>(function Menu(props, forwardedRef) {
  const { className, orientation: orientationProp, asChild, children, ...rest } = props

  const orientation = ensurePropOf<MenuOrientation>(MenuOrientations, orientationProp, 'vertical')

  const classes = classNames(
    styles.root,
    {
      [styles[`orientation${orientation}`]]: !!orientation,
    },
    className
  )

  return (
    <RNavigationMenu.Root orientation={orientation}>
      <RNavigationMenu.List {...rest} asChild={asChild} className={classes} ref={forwardedRef}>
        {Children.map(children, (item) => (
          <RNavigationMenu.Item className={styles.menuItem}>
            <RNavigationMenu.Link asChild>
              {isValidElement<MenuItemProps>(item) && item.type === MenuItem
                ? cloneElement(item, { block: orientation === 'vertical' })
                : item}
            </RNavigationMenu.Link>
          </RNavigationMenu.Item>
        ))}
      </RNavigationMenu.List>
    </RNavigationMenu.Root>
  )
})

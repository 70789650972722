import { isValidElement, ReactNode } from 'react'

import { isValidLinkElement } from '../utilities/internal'

export const MAX_PAGES_TO_SHOW = 7

export function getVisiblePages(
  total: number,
  current: number,
  maxPagesToShow = MAX_PAGES_TO_SHOW
): (number | null)[] {
  const pagesToShow: (number | null)[] = []

  maxPagesToShow = Math.min(total, maxPagesToShow)

  // Handle case where total pages are less than or equal to maxPagesToShow
  if (total <= maxPagesToShow) {
    for (let i = 1; i <= total; i++) {
      pagesToShow.push(i)
    }
    while (pagesToShow.length < maxPagesToShow) {
      pagesToShow.push(null)
    }
    return pagesToShow
  }

  // Always show first and last pages
  pagesToShow.push(1, total)

  // Calculate remaining pages to show
  if (current <= 4) {
    pagesToShow.splice(1, 0, 2, 3, 4, 5, null)
  } else if (current >= total - 3) {
    pagesToShow.splice(1, 0, null, total - 4, total - 3, total - 2, total - 1)
  } else {
    pagesToShow.splice(1, 0, null, current - 1, current, current + 1, null)
  }

  return pagesToShow
}

export function onlyValidAttributes<
  K extends Record<string, unknown>,
  Y extends {
    children?: ReactNode
    asChild?: boolean
  }
>(props: K, baseProps: Y) {
  const { children, asChild: asChildProp } = baseProps
  const asChild = asChildProp && isValidElement(children)
  // is button
  if (asChild && children.type === 'button') {
    const { href: _, 'aria-disabled': __, ...buttonProps } = props
    return buttonProps
  }
  // is anchor
  if (!asChild || (children && isValidLinkElement(children))) {
    const { disabled: _, ...anchorProps } = props
    return anchorProps
  }
  return props
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6v12h12V6H6ZM4 6v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStop)

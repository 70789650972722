import { useContext } from 'react'

import { IS_ALLOWED_ENVIRONMENT } from './constants'
import { AppRootContext } from './context'
import { AppRootContextValue, PageProps } from './types'

export function useAppRootContext<T extends PageProps>(): AppRootContextValue<T> {
  const context = useContext(AppRootContext) as AppRootContextValue<T>
  if (!context.loaded && IS_ALLOWED_ENVIRONMENT) {
    console.warn(
      '[shared-app-root-provider] development only warning: called useAppRootContext without AppRootProvider as a parent node'
    )
  }
  return context
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgAlert(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.383 4.502c1.17-2.004 4.065-2.004 5.234 0l6.966 11.941c1.178 2.02-.279 4.557-2.617 4.557H5.035c-2.339 0-3.796-2.537-2.618-4.557l6.966-11.94ZM12.89 5.51a1.03 1.03 0 0 0-1.78 0L4.146 17.45A1.03 1.03 0 0 0 5.035 19h13.93c.796 0 1.291-.862.89-1.549L12.89 5.51ZM12 9a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgAlert)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPrint(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6ZM8 8H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2v-1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H8Zm1 11v-3h6v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Zm5-8a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPrint)

import { composeRefs, useComposedRefs } from '@radix-ui/react-compose-refs'
import {
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
  ReactNode,
  useContext,
  useImperativeHandle,
} from 'react'

import { InputElement, InputProps } from '../input/input'
import { Search, SearchElement, SearchProps } from '../search/search'
import { useKeyPress } from '../utilities'
import { AutocompleteContext } from './context'

export type AutocompleteTriggerElement = {
  changeValue: AutocompleteTriggerChildrenOnValueChange
  setInputValue(value: string): void
  onMenuChange(open: boolean): void
  input: InputElement | null
}

export interface AutocompleteTriggerProps
  extends Omit<InputHTMLAttributes<AutocompleteTriggerChildrenElement>, 'size'> {
  children: ReactNode
  loading?: boolean
  onValueChange?: AutocompleteTriggerChildrenProps['onValueChange']
  defaultOpenOnFocus?: boolean
  defaultCloseOnBlur?: boolean
}

export type AutocompleteTriggerChildrenElement = InputElement | SearchElement
export type AutocompleteTriggerChildrenProps = Pick<
  InputProps | SearchProps,
  'loading' | 'onValueChange' | 'onBlur' | 'onFocus' | 'role' | 'aria-expanded'
> & { ref: any }
export type AutocompleteTriggerChildrenOnValueChange = NonNullable<
  AutocompleteTriggerChildrenProps['onValueChange']
>

const AutocompleteTrigger = forwardRef<
  AutocompleteTriggerChildrenElement,
  AutocompleteTriggerProps
>(function AutocompleteTrigger(props, forwardedRef) {
  const {
    children,
    loading,
    onValueChange,
    defaultOpenOnFocus = true,
    defaultCloseOnBlur = true,
    ...rest
  } = props

  const ctx = useContext(AutocompleteContext)

  const { ref: inputRef } = useKeyPress<HTMLInputElement>(
    ['Escape', 'Enter', ' ', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],
    (evt) => {
      const currOptionEl = ctx.menuRef.current?.getCurrentOptionElement()
      const actionButtons = currOptionEl?.querySelectorAll<HTMLElement>('[role="button"]')

      // reset all action buttons
      let activeActionButtonIdx = -1
      actionButtons?.forEach((btn, idx) => {
        if (btn.hasAttribute('aria-current')) {
          activeActionButtonIdx = idx
        }
        btn.removeAttribute('aria-current')
      })

      switch (evt.key) {
        case 'Enter': {
          const activeButton = activeActionButtonIdx >= 0 && actionButtons?.[activeActionButtonIdx]
          if (activeButton) {
            evt.preventDefault()
            activeButton.click()
            return
          }
          ctx.menuRef.current?.selectCurrent(evt)
          break
        }

        case 'Escape':
          ctx.menuRef.current?.unselectCurrent()
          ctx.menuRef.current?.setOpen(false)
          break

        case 'ArrowUp':
          evt.preventDefault() // prevent cursor from moving inside of input
          ctx.menuRef.current?.setCurrentUp()
          break

        case 'ArrowDown':
          evt.preventDefault() // prevent cursor from moving inside of input
          ctx.menuRef.current?.setCurrentDown()
          break

        case 'ArrowLeft':
        case 'ArrowRight': {
          if (currOptionEl) {
            evt.preventDefault() // prevent cursor from moving inside of input

            const hasActionButtons = actionButtons && actionButtons.length > 0
            const isLastActionButton =
              hasActionButtons && activeActionButtonIdx === actionButtons.length - 1

            if (evt.key === 'ArrowLeft' || !hasActionButtons || isLastActionButton) {
              ctx.menuRef.current?.unselectCurrent()
              return
            }

            actionButtons[activeActionButtonIdx + 1]?.setAttribute('aria-current', 'true')
          }
          break
        }
      }
    }
  )

  const handler: AutocompleteTriggerElement = {
    get input() {
      return inputRef.current
    },
    changeValue(evt, value) {
      this.setInputValue(value)
      onValueChange?.(evt, value)
    },
    setInputValue(value) {
      if (inputRef.current) {
        inputRef.current.value = value
      }
    },
    onMenuChange(open) {
      const isSearchTrigger = isValidElement<SearchElement>(children) && children.type === Search
      if (isSearchTrigger) {
        // since Search has a wrapper, we need to set aria-expanded on it instead of the input
        const wrapper = inputRef.current?.parentElement
        if (!wrapper) return
        wrapper.setAttribute('aria-expanded', String(open))
      } else {
        inputRef.current?.setAttribute('aria-expanded', String(open))
      }
    },
  }
  useImperativeHandle(ctx.triggerRef, () => handler)

  const refs = useComposedRefs(forwardedRef, inputRef)

  if (!isValidElement<AutocompleteTriggerChildrenProps>(children)) {
    return null
  }

  return cloneElement(children, {
    ...rest,
    loading,
    onValueChange(evt, value) {
      children.props.onValueChange?.(evt, value)
      handler.changeValue(evt, value)
      ctx.menuRef.current?.onValueChange(value)
    },
    onFocus(evt) {
      rest.onFocus?.(evt)
      children.props.onFocus?.(evt)
      if (!defaultOpenOnFocus || ctx.menuRef.current?.open) return
      if (ctx.menuRef.current?.options.length) {
        ctx.menuRef.current.setOpen(true)
      }
    },
    onBlur(evt) {
      rest.onBlur?.(evt)
      children.props.onBlur?.(evt)
      if (!defaultCloseOnBlur || !ctx.menuRef.current?.open) return
      if (
        !(evt.relatedTarget instanceof HTMLElement) ||
        !evt.relatedTarget.closest('[role="listbox"]')
      ) {
        ctx.menuRef.current?.setOpen(false)
      }
    },
    role: 'combobox',
    'aria-expanded': false,
    ref: composeRefs(refs, children.props.ref),
  } satisfies AutocompleteTriggerChildrenProps)
})

export default AutocompleteTrigger

import {
  ABTestConvert,
  ABTestConvertResponse,
  ABTestParticipate,
  ABTestParticipateContext,
  ABTestParticipateResponse,
  ABTestParticipation,
  ABTestParticipationResponse,
} from '../types'

export class ABTestClient {
  baseUrl: string
  timeout: number
  enableLogs: boolean

  constructor(baseUrl: string, timeout: number, enableLogs = false) {
    this.baseUrl = baseUrl
    this.timeout = timeout
    this.enableLogs = enableLogs
  }

  private getArgosRequestOptions(ctx: ABTestParticipateContext | null): RequestInit {
    return {
      headers: {
        Cookie: ctx?.cookie || '',
        'User-Agent': ctx?.userAgent || '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      signal: AbortSignal.timeout(this.timeout),
    }
  }

  private getArgosRequestOptionsByPid(): RequestInit {
    return {
      headers: {
        Accept: 'application/json',
      },
      signal: AbortSignal.timeout(this.timeout),
    }
  }

  private log(method: string, message: string): void {
    if (this.enableLogs) console.log(`[ABTestClient.${method}]: ${message}`)
  }

  private hasValidCookies(ctx: ABTestParticipateContext): boolean {
    return ctx.cookie?.includes('user=') || ctx.cookie?.includes('jdid=') || false
  }

  async participate(
    ctx: ABTestParticipateContext,
    experimentData: ABTestParticipate
  ): Promise<ABTestParticipateResponse> {
    const { controlKey, experimentGroup, experimentName, simulate = false } = experimentData

    if (ctx.forcedExperiment) {
      this.log('participate', 'Returning with forced experiment')
      return ctx.forcedExperiment
    }

    if (!this.hasValidCookies(ctx)) {
      this.log('participate', 'No user or jdid cookies found')
      return {
        experimentGroup: experimentGroup,
        alternative: controlKey,
        experiment: experimentName,
        participating: false,
      }
    }

    try {
      const base = `${this.baseUrl}/participate?experiment_group=${experimentGroup}&experiment=${experimentName}`
      const url = simulate ? `${base}&simulate=${simulate}` : base
      this.log('participate', `fetching ${url}`)

      const response = await fetch(url, this.getArgosRequestOptions(ctx))

      const experimentResult = response.ok
        ? await response.json()
        : {
            alternative: { name: controlKey },
            experiment: { name: experimentName },
            experiment_group: { name: experimentGroup },
            participating: false,
          }

      this.log(
        'participate',
        `experiment result: ${experimentResult.alternative.name} - ${experimentResult.experiment.name} - ${experimentResult.participating}`
      )
      return {
        experimentGroup: experimentResult.experiment_group?.name,
        alternative: experimentResult.alternative.name,
        experiment: experimentResult.experiment.name,
        participating: experimentResult.participating,
      }
    } catch (e) {
      console.error('[ABTestClient.participate]: error fetching experiment:', e)
      return {
        experimentGroup: experimentGroup,
        alternative: controlKey,
        experiment: experimentName,
        participating: false,
      }
    }
  }

  async participation(
    ctx: ABTestParticipateContext,
    experimentData?: ABTestParticipation | undefined
  ): Promise<ABTestParticipationResponse> {
    const {
      groups,
      participatingOnly,
      experiments,
      forceProfileId,
      groupPattern,
      simulate = false,
    } = experimentData || {}

    if (ctx.forcedExperiment) {
      return {
        participation: [
          {
            experiment_group: { name: ctx.forcedExperiment.experimentGroup || '' },
            alternative: { name: ctx.forcedExperiment.alternative },
            experiment: { name: ctx.forcedExperiment.experiment },
            participating: ctx.forcedExperiment.participating,
          },
        ],
      }
    }

    if (!forceProfileId && !this.hasValidCookies(ctx)) {
      return { participation: [] }
    }

    try {
      const baseUrl = `${this.baseUrl}/participation`

      let params: string[] = []
      if (groups) params = [...params, `groups=${groups.join(',')}`]
      if (groupPattern) params = [...params, `groupPattern=${groupPattern}`]
      if (experiments) params = [...params, `experiments=${experiments.join(',')}`]
      if (participatingOnly) params = [...params, `participating_only=${participatingOnly}`]
      if (simulate) params = [...params, `simulate=${simulate}`]
      if (forceProfileId) params = [...params, `client_id=${forceProfileId}`]

      const url = params.length > 0 ? `${baseUrl}?${params.join('&')}` : baseUrl
      const response = await fetch(
        url,
        forceProfileId ? this.getArgosRequestOptionsByPid() : this.getArgosRequestOptions(ctx)
      )

      return response.ok ? await response.json() : { participation: [] }
    } catch (e) {
      console.error('Error fetching experiments from participation endpoint: ', e)
      return { participation: [] }
    }
  }

  async convert(experimentData: ABTestConvert): Promise<ABTestConvertResponse> {
    const { controlKey, experimentGroup, experimentName, simulate = false } = experimentData

    try {
      const base = `${this.baseUrl}/convert?experiment_group=${experimentGroup}&experiment=${experimentName}`
      const url = simulate ? `${base}&simulate=${simulate}` : base
      this.log('convert', `fetching ${url}`)

      const response = await fetch(url, this.getArgosRequestOptions(null))

      const experimentResult = response.ok
        ? await response.json()
        : {
            experiment_group: { name: experimentGroup },
            alternative: { name: controlKey },
            experiment: { name: experimentName },
            participating: false,
          }

      this.log(
        'convert',
        `experiment result: ${experimentResult.alternative.name} - ${experimentResult.experiment.name} - ${experimentResult.participating}`
      )
      return {
        experimentGroup: experimentResult.experiment_group?.name,
        alternative: experimentResult.alternative.name,
        experiment: experimentResult.experiment.name,
        participating: experimentResult.participating,
      }
    } catch (e) {
      console.error('[ABTestClient.convert]: error fetching experiment conversion:', e)
      return {
        experimentGroup: experimentGroup,
        alternative: controlKey,
        experiment: experimentName,
        participating: false,
      }
    }
  }
}

import { useEffect, useRef } from 'react'

import { sanitizeDataLayerInput } from './sanitize-gtm-data-layer'

export interface useGTMResponse {
  push: (input: Record<string, unknown>) => void
}

export function useGTM(): useGTMResponse {
  const dataLayerName = 'dataLayer'

  useEffect(() => {
    window[dataLayerName] ??= []
  }, [dataLayerName])

  function push(input: Record<string, unknown>) {
    if (dataLayerName in window) {
      window[dataLayerName].push(sanitizeDataLayerInput(input))
    }
  }

  const gtm = useRef({ push })

  return gtm.current
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}

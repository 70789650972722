import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFacebook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.333 19.964a2 2 0 0 1-2 2h-3.172a2 2 0 0 1-2-2v-5.887H8a2 2 0 0 1-2-2V9.509a2 2 0 0 1 2-2h.163c.025-1.093.285-2.095.823-2.953.569-.907 1.363-1.52 2.213-1.91 1.62-.744 3.531-.727 5.055-.532A2 2 0 0 1 18 4.098v2.385c0 .614-.277 1.164-.714 1.53a2 2 0 0 1 .663 1.676l-.233 2.569a2 2 0 0 1-1.992 1.819h-.39v5.887Zm-2-10.455V7.766c0-.09.005-.177.013-.257.079-.748.535-1.026 1.55-1.026H16V4.098c-2.791-.357-5.84.138-5.84 3.584v1.827H8v2.568h2.161v7.887h3.172v-7.887h2.391l.234-2.568h-2.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFacebook)

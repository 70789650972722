import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStarActive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.94 3.434c-.6-1.912-3.28-1.912-3.88 0L8.623 8.003H4.038c-1.983 0-2.745 2.506-1.25 3.68l3.78 2.969-1.408 4.653c-.59 1.838 1.515 3.536 3.166 2.273l.006-.005 3.677-2.897 3.793 2.902c1.654 1.265 3.765-.442 3.163-2.285l-1.52-4.651 3.766-2.958c1.496-1.175.734-3.681-1.249-3.681h-4.585L13.94 3.434Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStarActive)

import * as RContextMenu from '@radix-ui/react-context-menu'

import { assignSubComponents } from '../utilities/internal'
import ContextMenuContent from './context-menu-content'
import ContextMenuItem from './context-menu-item'
import ContextMenuTrigger from './context-menu-trigger'

export type ContextMenuElement = HTMLDivElement

export type ContextMenuProps = RContextMenu.ContextMenuProps

const ContextMenuRoot: React.FC<ContextMenuProps> = function ContextMenu(props) {
  const { children } = props
  return <RContextMenu.Root {...props}>{children}</RContextMenu.Root>
}

export const ContextMenu = assignSubComponents('ContextMenu', ContextMenuRoot, {
  Content: ContextMenuContent,
  Item: ContextMenuItem,
  Trigger: ContextMenuTrigger,
})

export * from './context-menu-content'
export * from './context-menu-item'
export * from './context-menu-trigger'

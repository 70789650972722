import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgHammer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.207 2.293A1 1 0 0 0 11.5 3v.672a1 1 0 0 1-.293.707L7.38 8.207a1 1 0 0 1-.707.293H6a1 1 0 0 0-.707 1.707l5.5 5.5A1 1 0 0 0 12.5 15v-.671a1 1 0 0 1 .293-.708L14 12.414l6.293 6.293a1 1 0 1 0 1.414-1.414L15.414 11l1.207-1.207a1 1 0 0 1 .707-.293H18a1 1 0 0 0 .707-1.707l-5.5-5.5Zm-.586 3.5c.215-.215.395-.46.534-.724l2.776 2.776c-.264.14-.509.319-.724.534l-3.828 3.828c-.215.215-.395.46-.534.724L8.07 10.155c.264-.14.509-.319.724-.534l3.828-3.828ZM6 18a2 2 0 0 0-2 2H3a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1v-1h4v1a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2h-1a2 2 0 0 0-2-2H6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgHammer)

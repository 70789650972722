import classNames from 'classnames'
import { Children, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { assignSubComponents } from '../utilities/internal'
import styles from './detail.module.scss'
import DetailIcon from './detail-icon'
import DetailLabel from './detail-label'
import DetailValue from './detail-value'

export type DetailElement = HTMLDivElement

export interface DetailProps extends HTMLAttributes<DetailElement> {
  children: ReactNode
  inline?: boolean
  className?: string
}

function hasIconFromChildren(children: ReactNode): boolean {
  let has = false
  Children.forEach(children, (el) => {
    if (!has && isValidElement(el) && el.type === DetailIcon) {
      has = true
    }
  })
  return has
}

const DetailRoot = forwardRef<DetailElement, DetailProps>(function Detail(props, forwardedRef) {
  const { className, children, inline = false, ...rest } = props

  const classes = classNames(
    styles.root,
    {
      [styles.inline]: inline,
      [styles.iconed]: hasIconFromChildren(children),
    },
    className
  )

  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      {children}
    </div>
  )
})

export const Detail = assignSubComponents('Detail', DetailRoot, {
  Icon: DetailIcon,
  Label: DetailLabel,
  Value: DetailValue,
})

export * from './detail-icon'
export * from './detail-label'
export * from './detail-value'

import { FormControlErrorMessageElement } from '../form-control/form-control-error-message'
import { AlertIcon } from '../icon/icon'
import styles from './search-autocomplete-menu-error-message.module.scss'

export type SearchAutocompleteMenuErrorMessageProps = {
  node: FormControlErrorMessageElement
}

function SearchAutocompleteMenuErrorMessage(props: SearchAutocompleteMenuErrorMessageProps) {
  const { node } = props
  const content = node.innerHTML
  if (!content) return null
  return (
    <div className={styles.root}>
      <AlertIcon size="xs" />
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

export default SearchAutocompleteMenuErrorMessage

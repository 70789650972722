import type { ToastViewportProps as RToastViewportProps } from '@radix-ui/react-toast'
import * as RToast from '@radix-ui/react-toast'
import classNames from 'classnames'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './toast-viewport.module.scss'
import { ToastProviderPlacement, ToastProviderPlacements } from './types'

export type ToastViewportElement = {
  onStoreUpdate: (count: number) => void
}

export interface ToastViewportProps extends RToastViewportProps {
  placement?: ToastProviderPlacement
  containerProps?: React.HTMLAttributes<HTMLDivElement>
}

export const ToastViewport = forwardRef<ToastViewportElement, ToastViewportProps>(
  function ToastViewport(props, forwardedRef) {
    const { className, placement: placementProp, containerProps, ...rest } = props

    const containerRef = useRef<HTMLDivElement>(null)

    const placement = ensurePropOf<ToastProviderPlacement>(
      ToastProviderPlacements,
      placementProp,
      'bottom'
    )

    const classes = classNames(styles.root, className)

    const containerClasses = classNames(styles.container, containerProps?.className, {
      [styles[placement]]: placement,
    })

    useImperativeHandle(forwardedRef, () => ({
      onStoreUpdate(count) {
        if (!containerRef.current) return
        const hasProperty = !!containerRef.current.style.getPropertyValue('pointer-events')
        if (count === 0 && !hasProperty) {
          containerRef.current.style.setProperty('pointer-events', 'none')
        } else if (count > 0 && hasProperty) {
          containerRef.current.style.removeProperty('pointer-events')
        }
      },
    }))

    return (
      <div
        {...containerProps}
        className={containerClasses}
        tabIndex={-1}
        ref={containerRef}
        data-testid="fds-toast-viewport-container"
      >
        <RToast.Viewport hotkey={['F8']} label="Notificações (F8)" {...rest} className={classes} />
      </div>
    )
  }
)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgX(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.364 3H2l7.51 10.013-7.1 8.169h2.408l5.808-6.681 5.01 6.68H22l-7.826-10.434L20.91 3H18.5L13.06 9.26 8.364 3Zm8.181 16.364L5.636 4.818h1.819l10.909 14.546h-1.819Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgX)

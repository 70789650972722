import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Separator } from '../separator/separator'
import commonStyles from './common.module.scss'
import styles from './drawer-header.module.scss'

export type DrawerHeaderElement = HTMLDivElement

export interface DrawerHeaderProps extends HTMLAttributes<DrawerHeaderElement> {
  children?: ReactNode
  separatorClassName?: string
}

const DrawerHeader = forwardRef<DrawerHeaderElement, DrawerHeaderProps>(function DrawerHeader(
  props,
  forwardedRef
) {
  const { className, children, separatorClassName, ...rest } = props
  const classes = classNames(styles.root, commonStyles.header, className)
  return (
    <>
      <div {...rest} className={classes} ref={forwardedRef}>
        {children}
      </div>

      <Separator orientation="horizontal" className={separatorClassName} />
    </>
  )
})

export default DrawerHeader

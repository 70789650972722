import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import { usePress } from '../utilities'
import styles from './search-icon-button.module.scss'

export type SearchIconButtonElement = HTMLButtonElement

export type SearchIconButtonProps = ButtonHTMLAttributes<SearchIconButtonElement>

const SearchIconButton = forwardRef<SearchIconButtonElement, SearchIconButtonProps>(
  function SearchIconButton(props, forwardedRef) {
    const { className, children, onClick, ...rest } = props

    const { ref } = usePress<SearchIconButtonElement>({
      onPress(evt: any) {
        onClick?.(evt)
      },
    })

    const refs = useComposedRefs(forwardedRef, ref)

    const classes = classNames(styles.root, className)

    return (
      <button {...rest} className={classes} ref={refs}>
        {children}
      </button>
    )
  }
)

export default SearchIconButton

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgArrowRightUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7a1 1 0 0 0-1-1H8.5a1 1 0 1 0 0 2h6.086l-8.293 8.293a1 1 0 1 0 1.414 1.414L16 9.414V15.5a1 1 0 1 0 2 0V7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgArrowRightUp)

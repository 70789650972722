import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEmail(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7Zm3-1a1 1 0 0 0-1 1v2.35l7.594 3.375a1 1 0 0 0 .812 0L20 9.35V7a1 1 0 0 0-1-1H5Zm15 5.539-6.782 3.014a3 3 0 0 1-2.436 0L4 11.539V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-5.461Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEmail)

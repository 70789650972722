import { useLazyQuery } from '@apollo/client'
import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { forwardRef, useContext, useEffect, useRef, useState } from 'react'

import { TopbarContext } from '../../context'
import { TopbarContextType, TopbarUIProps } from '../../types'
import { TopbarRoot, TopbarRootElement, TopbarRootProps } from '../../ui'
import { assertTopbarNextContext, TopbarNextContext } from '../next-context'
import { TopbarRootQuery } from './__generated__/query.graphql'
import { getContextFromQuery } from './get-context-from-query'
import { TOPBAR_QUERY } from './query'

export type TopbarNextRootElement = TopbarRootElement

export interface TopbarNextRootProps extends TopbarRootProps, TopbarUIProps {
  fetchOnMount?: boolean
}

export const TopbarNextRoot = forwardRef<TopbarNextRootElement, TopbarNextRootProps>(
  function TopbarNextRoot(props, forwardedRef) {
    const { variant, position, currentNavKey, fetchOnMount = true } = props
    const rootRef = useRef<TopbarRootElement>(null)
    const ctx = useContext(TopbarNextContext)
    const { ctx: initialContext } = ctx

    assertTopbarNextContext(ctx)

    const [context, setContext] = useState<TopbarContextType>({
      ...initialContext,
      variant: variant ?? initialContext.variant,
      position: position ?? initialContext.position,
      currentNavKey: currentNavKey ?? initialContext.currentNavKey,
      rootRef,
    })

    const [fetchQuery] = useLazyQuery<TopbarRootQuery>(TOPBAR_QUERY, {
      onCompleted(data) {
        setContext({
          ...context,
          ...getContextFromQuery(data),
          ready: true,
        })
      },
    })

    useEffect(() => {
      if (context.mount) return
      setContext({ ...context, mount: true })
    }, [context])

    useEffect(() => {
      if (context.ready) return
      if (fetchOnMount) {
        fetchQuery()
      } else {
        setContext({ ...context, ready: true })
      }
    }, [fetchOnMount, fetchQuery, context])

    const refs = useComposedRefs(forwardedRef, rootRef)

    return (
      <TopbarContext.Provider value={context}>
        <TopbarRoot {...props} ref={refs} />
      </TopbarContext.Provider>
    )
  }
)

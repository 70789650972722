import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './detail-label.module.scss'
import { renderChildrenOrLink } from './utils'

export type DetailLabelElement = HTMLHeadingElement

export interface DetailLabelProps extends HTMLAttributes<DetailLabelElement> {
  children: ReactNode
  className?: string
  asChild?: boolean
}

const DetailLabel = forwardRef<DetailLabelElement, DetailLabelProps>(function DetailLabel(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'h3'

  const classes = classNames(styles.root, className)

  return (
    <Component {...rest} data-fds-detail-label className={classes} ref={forwardedRef}>
      {renderChildrenOrLink(children)}
    </Component>
  )
})

export default DetailLabel

import { SVGProps } from 'react'

import { withSVGProps } from '../common'

export interface SvgLogoProps extends SVGProps<SVGSVGElement> {
  onlySymbol: boolean
}

export function SvgJusia(props: SvgLogoProps) {
  const { onlySymbol, ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={onlySymbol ? '0 0 27.2 32' : '0 0 115 32'}
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      {...withSVGProps(rest)}
    >
      <title>Marca Jus IA</title>
      {!onlySymbol && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M31.945 26.046V23.54c1.31.888 2.72 1.337 4.226 1.337 1.507 0 2.674-.373 3.366-1.115.687-.741 1.03-1.962 1.03-3.654V4.8h2.482v15.505q-.001 2.032-.586 3.472c-.394.959-.925 1.674-1.603 2.14q-1.016.7-2.079.994a8.6 8.6 0 0 1-2.29.293q-2.466-.001-4.546-1.154zM48.013 19.945v-9.63h2.482v9.74c0 1.688.38 2.927 1.14 3.708.76.786 1.946 1.177 3.567 1.177 1.62 0 2.78-.39 3.553-1.177q1.154-1.178 1.154-3.707v-9.741h2.482v9.63q-.001 3.465-1.877 5.359-1.877 1.897-5.312 1.896-3.436.002-5.312-1.896-1.876-1.896-1.877-5.36M65.299 14.826q0-1.992 1.502-3.397 1.504-1.407 4.588-1.408 2.198 0 4.396 1.013v2.393q-1.963-1.14-4.126-1.141-3.873 0-3.874 2.544-.001.867.94 1.394.939.532 2.298.88 1.36.352 2.72.838 1.36.493 2.308 1.657c.632.777.948 1.771.948 2.974q-.001 2.166-1.672 3.397-1.67 1.232-4.625 1.23c-1.969 0-3.63-.417-5.183-1.252v-2.544c1.644.977 3.338 1.47 5.083 1.47q3.909-.002 3.91-2.3.001-1.24-.939-1.94t-2.298-1.053a62 62 0 0 1-2.72-.768 5.3 5.3 0 0 1-2.308-1.403q-.948-.986-.948-2.584M85.466 26.685V4.8h3.122v21.885zM92.353 26.685 101.36 4.8h3.072l8.971 21.885h-3.389l-2.427-6.167h-9.419l-2.427 6.167zm6.942-8.987h7.171l-3.586-9.084z"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FC0"
        d="M26.098 0H12.663c-.508 0-.944.374-1.032.883l-.36 2.09s-.004.016-.004.023L8.768 17.52h5.734c.39 0 .746.216.93.565l6.428 12.342s.077.152.102.272c.016.079.023.134.028.178v.162a1 1 0 0 1-.014.1q.006-.02.01-.04l4.788-27.732.366-2.112A1.067 1.067 0 0 0 26.098 0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#3D82DA"
        d="M6.478 31.181v-.004q-.007-.044-.011-.093v-.016c0-.027-.005-.057-.005-.085v-.03q0-.036.003-.074.001-.026.006-.053.002-.027.007-.055.01-.055.02-.113L8.767 17.52H1.049c-.792.002-1.298.862-.924 1.573l6.428 12.342.01.016a1 1 0 0 1-.085-.27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#77B340"
        d="M21.99 31.038c-.041.584-.58.969-1.026.962H7.536c-.175 0-.356-.046-.356-.046-.557-.152-.82-.676-.681-1.296L8.766 17.52H14.5c.39 0 .747.216.931.565l6.428 12.342s.077.152.102.272.03.19.03.24z"
      />
    </svg>
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgContacts(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6a2 2 0 1 1 4 0c0 1.162-.585 2.187-1.26 2.974-.259.303-.52.556-.74.751a7.722 7.722 0 0 1-.74-.75C8.584 8.186 8 7.161 8 6Zm-1.572 8.31L10 12.166l3.572 2.144A5 5 0 0 1 16 18.597V19a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287Zm1.313-4.034c.185.216.37.412.546.585L5.4 12.595A7 7 0 0 0 2 18.597V19a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-.403a7 7 0 0 0-3.399-6.002l-2.889-1.734c.177-.173.362-.369.547-.585C13.085 9.313 14 7.838 14 6a4 4 0 0 0-8 0c0 1.838.915 3.313 1.74 4.276ZM17 6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Zm1 6a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm3 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgContacts)

import classNames from 'classnames'
import { forwardRef } from 'react'

import { BodyText, BodyTextElement, BodyTextProps } from '../body-text/body-text'
import { Slot, Slottable } from '../utilities'
import styles from './accordion-text.module.scss'

export type AccordionTextElement = BodyTextElement

export type AccordionTextProps = Omit<BodyTextProps, 'size'>

const AccordionText = forwardRef<AccordionTextElement, AccordionTextProps>(function AccordionText(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  const Component = asChild ? Slot : 'div'

  return (
    <BodyText {...rest} size="md" className={classes} ref={forwardedRef} asChild>
      <Component>
        <Slottable>{children}</Slottable>
      </Component>
    </BodyText>
  )
})

export default AccordionText

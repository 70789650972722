/* eslint-disable react/jsx-no-target-blank */
import {
  Button,
  Chip,
  Col,
  Container,
  IconButton,
  Row,
  SearchAutocompleteRootElement,
  SearchElement,
  SearchIcon,
} from '@farol-ds/react'
import { SERPAutocomplete } from '@jusbrasil-web/shared-serp-autocomplete'
import { setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { FC, ReactNode, useContext, useEffect, useRef } from 'react'

import { SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION } from '../../constants'
import { TopbarContext } from '../../context'
import { TopbarUserMenuProps } from '../../types'
import { TopbarBrand } from '../topbar-brand'
import { TopbarJusIAButton } from '../topbar-jus-ia-button'
import { TopbarUserMenu } from '../topbar-user-menu'
import { ThemeToggle } from './theme-toggle'
import styles from './topbar-header.module.scss'

export interface TopbarHeaderProps {
  disableSearch?: boolean
  disableCta?: boolean
  userMenuProps?: TopbarUserMenuProps
  searchProps?: { q?: string; section?: string }
  children?: ReactNode
}

export enum SearchIntentionOptionEnum {
  UNKNOWN = 'UNKNOWN',
}

export const TopbarHeader: FC<TopbarHeaderProps> = (props) => {
  const { disableSearch, disableCta, userMenuProps, searchProps, children } = props

  const autocompleteRef = useRef<SearchAutocompleteRootElement>(null)
  const searchInputRef = useRef<SearchElement>(null)

  const { logged, user, takeover } = useContext(TopbarContext)
  const subscribeLink = user?.subscribeLink
  const isJusIA = user?.subscribeLink?.isJusIA

  function handleSearchIconClick() {
    autocompleteRef.current?.setAttribute('data-focused', '')
    searchInputRef.current?.focus()
  }

  function onSearchSubmit(_: React.FormEvent<HTMLFormElement> | null, query: string) {
    const now = new Date()
    const in30Days = new Date(now.setDate(now.getDate() + 30))
    setJusbrasilCookie(null, 'from_component_search', 'Topbar', {
      expires: in30Days,
    })

    const relation = SearchIntentionOptionEnum.UNKNOWN
    const clearedQuery = query.replace(/[^\d]+/g, '')

    if (logged && !user?.cpf && clearedQuery.length === 11) {
      const queryId = Buffer.from(clearedQuery).toString('base64')
      const payload = { query: clearedQuery, queryId, relation }

      sessionStorage.setItem('saveSearchInteraction', JSON.stringify(payload))
    }
  }

  const onLinkClick = (e: React.MouseEvent) => {
    const anchorElement = e.target as HTMLAnchorElement
    sendMetrics('Topbar.LinkClicked', user?.pid?.toString(), {
      title: anchorElement && anchorElement.textContent,
      href: anchorElement && anchorElement.href,
      design_system: 'farol',
    })
  }

  useEffect(() => {
    if (window?.innerWidth >= 1024 && !disableCta && logged && subscribeLink) {
      sendMetrics('Topbar.LinkViewed', user?.pid?.toString(), {
        title: subscribeLink.label,
        href: `${subscribeLink.url}?fromComponent=topbar-subscribe-cta`,
        design_system: 'farol',
      })
    }
  }, [disableCta, logged, subscribeLink, user?.pid])

  useEffect(() => {
    setJusbrasilCookie(
      null,
      SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION.cookie.name,
      isJusIA || false,
      {
        maxAge: SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION.cookie.maxAge,
      }
    )
  }, [isJusIA])

  return (
    <Container asChild>
      <header>
        <Row>
          <Col xs={12} className={styles.root}>
            {children || (
              <>
                {takeover && (
                  <Chip kind="negative" size="md" className={styles.takeover}>
                    Takeover
                  </Chip>
                )}

                <TopbarBrand />

                <span className={styles.separator} />

                {!disableSearch && (
                  <SERPAutocomplete
                    {...searchProps}
                    className={styles.autocompleteRoot}
                    autocompleteProps={{ className: styles.autocomplete }}
                    autocompleteRef={autocompleteRef}
                    searchRef={searchInputRef}
                    pid={user?.pid?.toString()}
                    onSubmit={onSearchSubmit}
                  />
                )}

                <span className={styles.separator} />

                {!disableCta && logged && (
                  <div className={styles.ctaContainer} data-testid="subscribe-cta-link-container">
                    {/* [WARN] we should keep ctaContainer and Button conditions separated, to avoid CLS during data-fetching on client-side. @andersonba */}
                    {subscribeLink &&
                      (isJusIA ? (
                        <TopbarJusIAButton
                          subscribeLink={subscribeLink}
                          onLinkClick={onLinkClick}
                        />
                      ) : (
                        <Button className={styles.userLeftAddon} kind="secondary" asChild>
                          <a
                            href={`${subscribeLink.url}?fromComponent=topbar-subscribe-cta`}
                            target="_blank"
                            data-testid="topbar-subscribe-cta-link"
                            onClick={onLinkClick}
                          >
                            {subscribeLink.label}
                          </a>
                        </Button>
                      ))}
                  </div>
                )}

                <ThemeToggle />

                {!disableSearch && (
                  <IconButton
                    id="topbar-search-icon"
                    className={styles.searchButton}
                    size="md"
                    icon={<SearchIcon />}
                    label="Pesquisar"
                    onClick={handleSearchIconClick}
                  />
                )}

                <TopbarUserMenu {...userMenuProps} />
              </>
            )}
          </Col>
        </Row>
      </header>
    </Container>
  )
}

import type { DialogProps as RDialogProps } from '@radix-ui/react-dialog'
import * as RDialog from '@radix-ui/react-dialog'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { ModalContext } from './context'
import ModalBody from './modal-body'
import ModalClose from './modal-close'
import ModalContent from './modal-content'
import ModalFooter from './modal-footer'
import ModalHeader from './modal-header'
import ModalHeaderBrand from './modal-header-brand'
import ModalHeaderDescription from './modal-header-description'
import ModalHeaderTitle from './modal-header-title'
import ModalIconClose from './modal-icon-close'
import ModalTrigger from './modal-trigger'
import { ModalSize, ModalSizes } from './types'
import { useModalProvider } from './use-modal'

export type ModalElement = typeof RDialog.Root

export interface ModalOnOpenChangeOptions {
  waitAnimation: (callback: () => void) => void
}

export interface ModalProps extends Omit<RDialogProps, 'modal' | 'onOpenChange'> {
  size?: ModalSize
  onOpenChange?: (open: boolean, options: ModalOnOpenChangeOptions) => void
}

const ModalRoot: React.FC<ModalProps> = (props) => {
  const { children, defaultOpen, size: sizeProp, onOpenChange: onOpenChangeProp, ...rest } = props

  const size = ensurePropOf<ModalSize>(ModalSizes, sizeProp, 'md')
  const context = useModalProvider({ ...props, size })

  const { open, onOpenChange } = context

  return (
    <ModalContext.Provider value={context}>
      <RDialog.Root {...rest} modal open={open} onOpenChange={onOpenChange}>
        {children}
      </RDialog.Root>
    </ModalContext.Provider>
  )
}

export const Modal = assignSubComponents('Modal', ModalRoot, {
  Body: ModalBody,
  Close: ModalClose,
  Content: ModalContent,
  Footer: ModalFooter,
  Header: ModalHeader,
  HeaderBrand: ModalHeaderBrand,
  HeaderDescription: ModalHeaderDescription,
  HeaderTitle: ModalHeaderTitle,
  IconClose: ModalIconClose,
  Trigger: ModalTrigger,
})

export * from './modal-body'
export * from './modal-close'
export * from './modal-content'
export * from './modal-footer'
export * from './modal-header'
export * from './modal-header-brand'
export * from './modal-header-description'
export * from './modal-header-title'
export * from './modal-icon-close'
export * from './modal-trigger'
export * from './use-modal'

import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import { useSelectionCardContext } from './context'
import styles from './selection-card-control.module.scss'

export type SelectionCardControlElement = HTMLDivElement

export type SelectionCardControlProps = HTMLAttributes<SelectionCardControlElement>

export default function SelectionCardControl(props: SelectionCardControlProps) {
  const { className, ...rest } = props
  const { orientation } = useSelectionCardContext()
  const classes = classNames(styles.root, styles[orientation], className)
  return <div {...rest} className={classes} />
}

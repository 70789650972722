import { ChevronLeftIcon, IconButton, IconButtonElement } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, MouseEventHandler } from 'react'

import { MULTIPLIER_BASE, ScrollToDirection } from './hooks/use-line-scroll'
import styles from './line-scroll.module.scss'

export type LineScrollArrowLeftElement = HTMLSpanElement
export interface LineScrollArrowLeftProps extends HTMLAttributes<LineScrollArrowLeftElement> {
  onClickButton?: MouseEventHandler<IconButtonElement>
  scrollToDirection: ScrollToDirection
}

export const LineScrollArrowLeft = forwardRef<LineScrollArrowLeftElement, LineScrollArrowLeftProps>(
  function LineScrollArrowLeft(props, forwardedRef) {
    const { className, onClickButton, scrollToDirection, ...rest } = props
    return (
      <span {...rest} className={classNames(styles.arrowLeft, className)} ref={forwardedRef}>
        <IconButton
          size="md"
          kind="plain"
          tooltipProps={{ open: false }}
          icon={<ChevronLeftIcon />}
          label="Mover barra de rolagem para esquerda"
          onClick={(event) => {
            onClickButton?.(event)
            scrollToDirection(-MULTIPLIER_BASE)
          }}
          data-testid="line-scroll-arrow-left"
        />
      </span>
    )
  }
)

import type { NextApiRequestCookies } from 'next/dist/server/api-utils'

export function isLitigantUser(cookies: NextApiRequestCookies) {
  const userIntentions = cookies?.user_intentions || ''
  const userIsVerifiedLawyer = cookies?.user_is_verified_lawyer || ''

  return checkIfUserInTargetSegment(userIntentions, userIsVerifiedLawyer)
}

export function checkIfUserInTargetSegment(userIntentions: string, userIsVerifiedLawyer: string) {
  userIntentions = userIntentions.toUpperCase()
  userIsVerifiedLawyer = userIsVerifiedLawyer.toUpperCase()

  return (
    (userIntentions === '' && userIsVerifiedLawyer === 'TRUE') ||
    (userIntentions === '' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === '' && userIsVerifiedLawyer === '') ||
    (userIntentions === 'CASE_MGMT' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === 'CASE_MGMT' && userIsVerifiedLawyer === '') ||
    (userIntentions === 'PARTY' && userIsVerifiedLawyer === 'TRUE') ||
    (userIntentions === 'PARTY' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === 'PARTY' && userIsVerifiedLawyer === '') ||
    (userIntentions === 'RELATIVE' && userIsVerifiedLawyer === 'TRUE') ||
    (userIntentions === 'RELATIVE' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === 'RELATIVE' && userIsVerifiedLawyer === '') ||
    (userIntentions === 'REPUTATION' && userIsVerifiedLawyer === 'TRUE') ||
    (userIntentions === 'REPUTATION' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === 'REPUTATION' && userIsVerifiedLawyer === '') ||
    (userIntentions === 'OTHER' && userIsVerifiedLawyer === 'FALSE') ||
    (userIntentions === 'OTHER' && userIsVerifiedLawyer === '')
  )
}

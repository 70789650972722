import { validateCpf } from '@jusbrasil-web/shared-utils-validators'

import { Nullable } from '../../../../utils'

interface Spec {
  isValid: (value: Nullable<string>) => boolean
  message: string
}

export type ValidationSpecs = Spec[]

export const validateSpec = (specs: ValidationSpecs, value: string) =>
  specs.find(({ isValid }) => !isValid(value))

export const CPF_VALIDATIONS: ValidationSpecs = [
  { isValid: (value: Nullable<string>) => Boolean(value?.trim()), message: 'O CPF é obrigatório' },
  {
    isValid: (value: Nullable<string>) => validateCpf(value || ''),
    message: 'Insira um CPF válido',
  },
]

export const BIRTHDATE_VALIDATIONS: ValidationSpecs = [
  {
    isValid: (value: Nullable<string>) => Boolean(value?.trim()),
    message: 'A data de nascimento é obrigatória',
  },
  {
    isValid: (value: Nullable<string>) => {
      if (!value?.match(/^\d{4}-\d{2}-\d{2}$/)) return false

      const data = new Date(value || '')
      return !isNaN(data.getTime()) && data >= new Date('1900-01-01') && data <= new Date()
    },
    message: 'Insira uma data válida',
  },
]

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './col.module.scss'

export type ColElement = HTMLDivElement

export type ColNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export interface ColProps extends HTMLAttributes<ColElement> {
  children?: ReactNode
  xs?: ColNumber
  sm?: ColNumber
  md?: ColNumber
  lg?: ColNumber
  xl?: ColNumber
  className?: string
  asChild?: boolean
}

export const Col = forwardRef<ColElement, ColProps>(function Col(props, forwardedRef) {
  const { className, xs, sm, md, lg, xl, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'div'

  const classes = classNames(
    styles.root,
    {
      [styles[`colXs${xs}`]]: xs !== undefined,
      [styles[`colSm${sm}`]]: sm !== undefined,
      [styles[`colMd${md}`]]: md !== undefined,
      [styles[`colLg${lg}`]]: lg !== undefined,
      [styles[`colXl${xl}`]]: xl !== undefined,
    },
    className
  )

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

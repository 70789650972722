import { BodyText, Button, Modal } from '@farol-ds/react'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect } from 'react'

import { EventData } from '../../../utils'

export interface CannotMonitorEntityStepProps {
  bodyText?: string | React.ReactNode
  onBack?: () => void
  onNext?: () => void
  eventProps: {
    viewerPid: number
    entityEntityid: string
  } & EventData
}

export function CannotMonitorEntityStep(props: CannotMonitorEntityStepProps) {
  const { bodyText, eventProps, onNext, onBack = () => null } = props
  const { entityEntityid, viewerPid, ...eventData } = eventProps

  useEffect(() => {
    sendMetrics('UserAccount.RestrictedMonitorViewed', viewerPid?.toString(), {
      from_component: 'MonitorPerson',
      ...eventData,
      metadata: {
        entityId: entityEntityid,
        ...eventData?.metadata,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonClickEvent = (button: string) => {
    sendMetrics('UserAccount.RestrictedMonitorSubmitted', viewerPid?.toString(), {
      ...eventData,
      from_component: 'MonitorPerson',
      metadata: {
        entityId: entityEntityid,
        button,
        ...eventData?.metadata,
      },
    })
  }

  const onNextClick = () => {
    buttonClickEvent('MonitorOwnCPF')
    onNext?.()
  }

  const onBackClick = () => {
    buttonClickEvent('Back')
    onBack()
  }

  return (
    <>
      <Modal.Header>
        <Modal.HeaderTitle>Não é possível acompanhar o CPF de outra pessoa</Modal.HeaderTitle>
      </Modal.Header>
      <Modal.Body data-testid="cannot-monitor-entity-step">
        <BodyText>
          Atualmente, o acompanhamento de CPF está disponível apenas para o titular.
        </BodyText>
        {bodyText}
      </Modal.Body>
      <Modal.Footer>
        {!!onNext && (
          <Button kind="primary" onClick={onNextClick}>
            Quero acompanhar o meu CPF
          </Button>
        )}
        <Button onClick={onBackClick}>Voltar</Button>
      </Modal.Footer>
    </>
  )
}

import { composeRefs } from '@radix-ui/react-compose-refs'
import { Ref, RefObject, useCallback, useContext, useRef, useState } from 'react'
import { SetRequired } from 'type-fest'

import { TabsContext } from './context'
import type { TabsChangeBehavior, TabsProps } from './tabs'
import { TabsTriggerElement, TabsTriggerProps } from './tabs-trigger'

export type UseTabsProviderProps = SetRequired<TabsProps, 'changeBehavior'>

export interface UseTabsProviderReturn {
  tabsRef: RefObject<Record<string, TabsTriggerElement | null>>
  activeTabValue: string | undefined
  onValueChange: TabsProps['onValueChange']
  getTriggerProps(ref: Ref<TabsTriggerElement>, value: string): Partial<TabsTriggerProps>
  changeBehavior: TabsChangeBehavior
}

export function useTabsProvider(props: UseTabsProviderProps): UseTabsProviderReturn {
  const { onValueChange: onValueChangeProp, defaultValue, value: valueProp, changeBehavior } = props
  const [activeTabValue, setActiveTabValue] = useState(valueProp ?? defaultValue)
  const tabsRef = useRef<Record<string, TabsTriggerElement | null>>({})

  function onValueChange(value: string) {
    const tabRef = tabsRef.current[value]
    const tabChangeBehavior = tabRef?.dataset?.changeBehavior as TabsChangeBehavior | undefined

    setActiveTabValue(value)
    onValueChangeProp?.(value)

    if (tabChangeBehavior === 'url-hash') {
      history.pushState(null, '', `#${value}`)
    }
  }

  const getTriggerProps = useCallback<UseTabsProviderReturn['getTriggerProps']>((ref, value) => {
    return {
      ref: composeRefs(ref, (node) => {
        tabsRef.current[value] = node
      }),
      id: undefined,
    }
  }, [])

  return {
    tabsRef,
    changeBehavior,
    activeTabValue: valueProp ?? activeTabValue,
    onValueChange,
    getTriggerProps,
  }
}

export function useTabs() {
  const context = useContext(TabsContext)
  return context
}

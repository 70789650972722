/**
 * Attach a listener to the browser back button, which will call the provided callback (if is exists) when the back button is pressed.
 * This enables to control the behaviour of the browser back button, e.g. to close a modal in mobile view.
 * @param stateName The name of the state to be pushed to the browser history.
 * @param onPopStateCallback The callback to be called when the back button is pressed.
 * @returns void
 */
export function attachBrowserBackButtonListener(
  stateName: string,
  onPopStateCallback?: () => void
): void {
  const hasPushedState = window.history.state?.[stateName]
  if (hasPushedState) {
    return
  }

  const previousState = window.history.state
  window.history.pushState(
    {
      ...previousState,
      [stateName]: true,
    },
    ''
  )

  window.addEventListener('popstate', () => onPopStateCallback?.(), {
    once: true,
  })
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgInbox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6Zm13 6V6a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h4a1 1 0 0 1 1 1v1a1 1 0 1 0 2 0v-1a1 1 0 0 1 1-1h4a1 1 0 0 0 1-1V9Zm0 4.83c-.313.11-.65.17-1 .17h-3a3 3 0 1 1-6 0H6c-.35 0-.687-.06-1-.17V18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4.17Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgInbox)

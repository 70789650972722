import { spacing6xs } from '@farol-ds/tokens'
import * as RSelect from '@radix-ui/react-select'
import { forwardRef } from 'react'

import { assignSubComponents } from '../utilities/internal'
import styles from './select.module.scss'
import SelectItem from './select-item'
import SelectTrigger, { SelectTriggerElement, SelectTriggerProps } from './select-trigger'

export type SelectProps = RSelect.SelectProps & SelectTriggerProps
export type SelectElement = SelectTriggerElement

const SelectRoot = forwardRef<SelectElement, SelectProps>(function Select(props, forwardedRef) {
  const {
    // root (https://www.radix-ui.com/primitives/docs/components/select)
    name,
    value,
    open,
    defaultValue,
    defaultOpen,
    onValueChange,
    onOpenChange,
    disabled,
    required,
    // trigger
    children,
    ...rest
  } = props

  const rootProps = {
    name,
    value,
    open,
    defaultValue,
    defaultOpen,
    onValueChange,
    onOpenChange,
    disabled,
    required,
  }

  return (
    <RSelect.Root {...rootProps}>
      <SelectTrigger disabled={disabled} {...rest} ref={forwardedRef} />
      <RSelect.Portal>
        <RSelect.Content
          position="popper"
          sideOffset={spacing6xs}
          className={styles.selectContent}
          data-testid="viewport"
        >
          <RSelect.Viewport className={styles.selectViewport} data-testid="content">
            {children}
          </RSelect.Viewport>
        </RSelect.Content>
      </RSelect.Portal>
    </RSelect.Root>
  )
})

export const Select = assignSubComponents('Select', SelectRoot, {
  Item: SelectItem,
})

export * from './select-item'

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgDislikeActive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 15a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3 1 1 0 0 1 1 1v10a1 1 0 0 1-1 1Zm5 3a3 3 0 1 0 6 0v-2h3.039a3 3 0 0 0 2.944-3.573l-1.197-6.988A3 3 0 0 0 17.839 3H9a1 1 0 0 0-1 1v10.19a1 1 0 0 0 .622.926C9.398 15.432 10 16.533 10 18Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgDislikeActive)

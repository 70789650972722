import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgInstagram(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM7.38 12a4.621 4.621 0 1 0 9.243 0 4.621 4.621 0 0 0-9.243 0Zm8.346-4.805a1.08 1.08 0 1 0 2.16 0 1.08 1.08 0 0 0-2.16 0Zm-7.361 12.13c-.878-.04-1.355-.185-1.672-.309a2.8 2.8 0 0 1-1.035-.673 2.782 2.782 0 0 1-.673-1.035c-.124-.317-.27-.793-.31-1.67-.043-.95-.052-1.235-.052-3.638 0-2.403.01-2.688.052-3.637.04-.878.187-1.354.31-1.671a2.8 2.8 0 0 1 .673-1.036c.315-.314.615-.51 1.035-.673.317-.123.794-.27 1.672-.31.949-.043 1.234-.052 3.636-.052 2.404 0 2.688.01 3.638.053.877.04 1.353.186 1.671.31.42.162.72.358 1.035.672.315.315.51.615.673 1.036.124.317.27.793.31 1.67.044.95.052 1.235.052 3.638 0 2.403-.008 2.688-.052 3.637-.04.878-.187 1.354-.31 1.671a2.79 2.79 0 0 1-.673 1.035c-.315.315-.615.51-1.035.673-.317.124-.794.27-1.671.31-.95.043-1.234.052-3.638.052-2.402 0-2.687-.009-3.636-.052M8.29 3.055c-.958.043-1.613.195-2.185.418A4.416 4.416 0 0 0 4.511 4.51c-.5.5-.809 1.001-1.038 1.594-.223.572-.375 1.226-.418 2.184C3.01 9.25 3 9.556 3 12s.01 2.75.055 3.71c.043.959.195 1.613.418 2.185A4.4 4.4 0 0 0 4.51 19.49c.5.5 1.002.808 1.594 1.038.573.223 1.227.375 2.185.418.96.044 1.266.055 3.71.055 2.445 0 2.751-.01 3.711-.055.958-.043 1.612-.195 2.185-.418a4.426 4.426 0 0 0 1.594-1.038c.5-.5.808-1.002 1.038-1.594.223-.572.375-1.226.418-2.184.044-.96.054-1.267.054-3.711s-.01-2.75-.054-3.71c-.043-.959-.195-1.613-.418-2.185A4.426 4.426 0 0 0 19.49 4.51c-.5-.5-1.002-.809-1.594-1.038-.573-.223-1.227-.376-2.184-.418-.96-.044-1.267-.055-3.71-.055-2.446 0-2.752.01-3.712.055"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgInstagram)

import { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { LineScrollArrowLeftElement } from '../line-scroll-arrow-left'
import { LineScrollArrowRightElement } from '../line-scroll-arrow-right'
import { LineScrollBoundaryElement } from '../line-scroll-boundary'

export type LineScrollStrategy = 'default' | 'visible-width'

export interface UseLineScrollProps {
  listRef: RefObject<HTMLElement>
  currentItemRef?: RefObject<ReactNode | HTMLElement | null>
  scrollMultiplier?: number
  scrollStrategy?: LineScrollStrategy
}

// TODO: make it possible to configure using args. @gabrielrtakeda
export const MULTIPLIER_BASE = 1

export type ScrollToDirection = (multiplier: number) => void

export interface UseLineScrollReturn {
  scrollToDirection: ScrollToDirection
  boundaryRef: RefObject<LineScrollBoundaryElement>
  arrowLeftRef: RefObject<LineScrollArrowLeftElement>
  arrowRightRef: RefObject<LineScrollArrowRightElement>
  captureTargetRef: (node: HTMLElement | null) => void
}

const MAX_ITEM_WIDTH_FALLBACK = 100
const FADE_ELEMENT_WIDTH = 12
const ARROW_ELEMENT_WIDTH = 48 + FADE_ELEMENT_WIDTH

export function useLineScroll(props: UseLineScrollProps): UseLineScrollReturn {
  const { listRef, currentItemRef, scrollMultiplier = 3, scrollStrategy = 'default' } = props

  const [itemsRefs, setItemsRefs] = useState<HTMLElement[]>([])

  const boundaryRef = useRef<LineScrollBoundaryElement>(null)
  const arrowLeftRef = useRef<LineScrollArrowLeftElement>(null)
  const arrowRightRef = useRef<LineScrollArrowRightElement>(null)
  const maxItemWidthRef = useRef(0)

  const captureTargetRef = useCallback((node: HTMLElement | null) => {
    if (node) setItemsRefs((prev) => (!prev.includes(node) ? [...prev, node] : prev))
  }, [])

  useEffect(() => {
    const element = boundaryRef.current
    const listElement = listRef.current
    if (!element || !listElement || !itemsRefs) return

    // anti-collision: auto-positioning when tab is outside of scrollview
    const detectColision = (el: HTMLElement) => {
      const boundaryRect = element.getBoundingClientRect()
      const contentLeft = (boundaryRect.left || 0) + ARROW_ELEMENT_WIDTH
      const contentRight = (boundaryRect.right || 0) - ARROW_ELEMENT_WIDTH
      const { left, right } = el.getBoundingClientRect()

      // left
      if (contentLeft > left) {
        element.scrollLeft -= contentLeft - left + ARROW_ELEMENT_WIDTH
      }
      // right
      else if (contentRight < right) {
        element.scrollLeft += right - contentRight + ARROW_ELEMENT_WIDTH
      }
    }

    const onClickItem = <K extends Event = MouseEvent | TouchEvent>(e: K) => {
      const item = e.currentTarget as HTMLElement
      detectColision(item)
    }

    // calculate max width of items and enable anti-collision
    itemsRefs.forEach((el) => {
      if (!el) return
      const { width } = el.getBoundingClientRect()
      // it already covered by e2e
      /* istanbul ignore if */
      if (width > maxItemWidthRef.current) {
        maxItemWidthRef.current = width
      }
      el.addEventListener('click', onClickItem)
    })

    // mount arrow button behavior
    const detectVisibleArrows = () => {
      const contentRight = element.getBoundingClientRect().right || 0
      const lastItemRight = element.lastElementChild?.getBoundingClientRect().right || 0
      const leftVisible = element.scrollLeft > 0 ? '1' : '0'
      const rightVisible = Math.round(lastItemRight) > Math.round(contentRight) ? '1' : '0'

      // [perf] avoid re-render unnecessary when scrolling
      if (arrowLeftRef.current && arrowLeftRef.current.dataset.arrowVisible !== leftVisible) {
        arrowLeftRef.current.dataset.arrowVisible = leftVisible
      }
      if (arrowRightRef.current && arrowRightRef.current.dataset.arrowVisible !== rightVisible) {
        arrowRightRef.current.dataset.arrowVisible = rightVisible
      }
    }

    detectVisibleArrows()

    const onRefreshScroll = () => {
      // it already covered by e2e
      /* istanbul ignore next */
      detectVisibleArrows()
    }
    element.addEventListener('scroll', onRefreshScroll)
    window.addEventListener('resize', onRefreshScroll)
    return () => {
      element.removeEventListener('scroll', onRefreshScroll)
      window.removeEventListener('resize', onRefreshScroll)
    }
  }, [listRef, currentItemRef, arrowLeftRef, arrowRightRef, itemsRefs])

  const scrollToDirection = useCallback(
    (multiplier: number) => {
      if (boundaryRef.current) {
        if (scrollStrategy === 'visible-width') {
          const currentScrollPosition = boundaryRef.current.scrollLeft
          const boundaryRefVisibleWidth = boundaryRef.current.getBoundingClientRect().width

          boundaryRef.current.scrollLeft = Math.max(
            currentScrollPosition + multiplier * boundaryRefVisibleWidth,
            0
          )
        } else {
          const itemSize =
            maxItemWidthRef.current ||
            listRef.current?.firstElementChild?.getBoundingClientRect().width ||
            MAX_ITEM_WIDTH_FALLBACK
          const currentItem = Math.round(boundaryRef.current.scrollLeft / itemSize)
          boundaryRef.current.scrollLeft = Math.max(
            (currentItem + multiplier * scrollMultiplier) * itemSize,
            0
          )
        }
      }
    },
    [listRef, boundaryRef, scrollMultiplier, scrollStrategy]
  )

  return {
    boundaryRef,
    arrowLeftRef,
    arrowRightRef,
    scrollToDirection,
    captureTargetRef,
  }
}

import { ChipClickable } from '@farol-ds/react'

import styles from './reason.module.scss'

export type ReasonStepProps = {
  options: string[]
  selectedOption?: string
  onOptionClick: (value: string) => void
}

export const ReasonStep = ({ options, selectedOption, onOptionClick }: ReasonStepProps) => (
  <div className={styles.options}>
    {options.map((option) => (
      <ChipClickable
        size="md"
        key={option}
        selected={selectedOption === option}
        onClick={() => onOptionClick(option)}
      >
        {option}
      </ChipClickable>
    ))}
  </div>
)

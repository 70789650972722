import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCalendarEvent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a1 1 0 0 1 1 1h6a1 1 0 1 1 2 0h1a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H8a3.001 3.001 0 0 1-2.83-2H4a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h5a1 1 0 0 1 1-1Zm7 3v1a1 1 0 1 0 2 0V5h1a1 1 0 0 1 1 1v2H7V6c0-.35-.06-.687-.17-1H9v1a1 1 0 1 0 2 0V5h6ZM4 5a1 1 0 0 0-1 1v2h2V6a1 1 0 0 0-1-1Zm1 5H3v7a1 1 0 0 0 1 1h1v-8Zm16 0H7v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-9Zm-8 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCalendarEvent)

import { ServerResponse } from 'http'
import { Cookie } from 'universal-cookie'

import { buildCookiesContext } from './build-cookies-context'
import { DEFAULT_JUSBRASIL_COOKIE_DOMAIN, DEFAULT_JUSBRASIL_COOKIE_PATH } from './constants'
import { JusbrasilCookieOptions } from './types'

/**
 * @description Creates a new cookie in the Jusbrasil domain.
 * @param response If you are running this function in the server side, send the response object. If you are using the function in the client side, use null.
 * @param name Name of the cookie.
 * @param value Value of the cookie.
 * @param options Additional options for the cookie.
 */
export function setJusbrasilCookie(
  response: ServerResponse | null,
  name: string,
  value: Cookie,
  options?: JusbrasilCookieOptions
): void {
  const cookies = buildCookiesContext({ response })

  return cookies.set(name, value, {
    path: DEFAULT_JUSBRASIL_COOKIE_PATH,
    domain: DEFAULT_JUSBRASIL_COOKIE_DOMAIN,
    ...options,
  })
}

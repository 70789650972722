import {
  KeyboardEvent as RKeyboardEvent,
  MouseEvent as RMouseEvent,
  TouchEvent as RTouchEvent,
  useEffect,
  useRef,
} from 'react'

export type PressEvent<Element> =
  | RMouseEvent<Element, RMouseEvent>
  | RTouchEvent<Element>
  | RKeyboardEvent<Element>

export type PressEventHandler<Element extends HTMLElement> = (event: PressEvent<Element>) => void

export type UsePressProps<Element> = {
  onPress: (event: PressEvent<Element>) => void
}

export type UsePressReturn<Element> = {
  ref: React.RefObject<Element>
}

export const PressKeys: readonly string[] = ['Enter', ' ']
export type PressKey = (typeof PressKeys)[number]

export const usePress = <Element extends HTMLElement>(
  props: UsePressProps<Element>
): UsePressReturn<Element> => {
  const { onPress } = props

  const ref = useRef<Element>(null)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleClick = (event: MouseEvent) => {
      onPress(event as any)
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (PressKeys.includes(event.key)) {
        onPress(event as any)
      }
    }

    element.addEventListener('click', handleClick)
    element.addEventListener('keydown', handleKeyDown)
    return () => {
      element.removeEventListener('click', handleClick)
      element.removeEventListener('keydown', handleKeyDown)
    }
  }, [onPress])

  return { ref }
}

import { ImgHTMLAttributes } from 'react'

export type AvatarElement = HTMLSpanElement
export const AvatarColors = ['primary', 'secondary', 'tertiary'] as const
export type AvatarColor = (typeof AvatarColors)[number]
export const AvatarSizes = ['sm', 'md', 'lg', 'xl', '2xl'] as const
export type AvatarSize = (typeof AvatarSizes)[number]

export interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  children?: string
  size?: AvatarSize
  className?: string
}

import {
  COMPONENT_ORIGIN_EXPIRATION_TIME,
  COMPONENT_ORIGIN_KEY,
  snowpiercerClient,
} from '../constants'
import { StorageObject } from '../types'

function emptyStorageObject(): StorageObject {
  return {
    timestamp: Date.now(),
  }
}

export async function getStorageObjectFromStorage() {
  try {
    const storedValue = await snowpiercerClient.getItem(COMPONENT_ORIGIN_KEY)
    const parsedValue: StorageObject = JSON.parse(storedValue)

    const obj =
      parsedValue instanceof Object && !Array.isArray(parsedValue)
        ? parsedValue
        : emptyStorageObject()

    return obj.timestamp && obj.timestamp + COMPONENT_ORIGIN_EXPIRATION_TIME >= Date.now()
      ? obj
      : emptyStorageObject()
  } catch (ignored) {
    return emptyStorageObject()
  }
}

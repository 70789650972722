import { useContext } from 'react'

import { TimelineContext } from './context'
import type { TimelineProps, TimelineSize } from './timeline'

export type UseTimelineProviderProps = TimelineProps & {
  size: TimelineSize
}

export interface UseTimelineProviderReturn {
  size: TimelineSize
}

export function useTimelineProvider(props: UseTimelineProviderProps): UseTimelineProviderReturn {
  const { size } = props
  return {
    size,
  }
}

export function useTimeline() {
  const context = useContext(TimelineContext)
  return context
}

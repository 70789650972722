import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFileZip(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-9a1 1 0 0 1-1-1V6a1 1 0 0 0-1-1ZM5 3h3a3 3 0 0 1 3 3h8a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17V7h2v9a1 1 0 0 1-1 1h-1Z"
        fill="currentColor"
      />
      <path
        d="M13 9h1a1 1 0 1 1 0 2h-1V9ZM9 12a1 1 0 0 1 1-1h1v2h-1a1 1 0 0 1-1-1ZM13 13h1a1 1 0 1 1 0 2h-1v-2ZM9 16a1 1 0 0 1 1-1h1v2h-1a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFileZip)

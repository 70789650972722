import { forwardRef, LiHTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'

export type ListItemElement = HTMLLIElement

export interface ListItemProps extends LiHTMLAttributes<ListItemElement> {
  children: ReactNode
  asChild?: boolean
}

const ListItem = forwardRef<ListItemElement, ListItemProps>(function ListItem(props, forwardedRef) {
  const { children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'li'

  return (
    <Component {...rest} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default ListItem

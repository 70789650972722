import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import { renderCoverChildren } from './shared-helpers'
import styles from './snippet-footnote-cover.module.scss'

export type SnippetFootnoteCoverElement = HTMLDivElement

export type SnippetFootnoteCoverProps = HTMLAttributes<SnippetFootnoteCoverElement> & {
  asChild?: boolean
}

const SnippetFootnoteCover = forwardRef<SnippetFootnoteCoverElement, SnippetFootnoteCoverProps>(
  function SnippetFootnoteCover(props, forwardedRef) {
    const { children: _, className, asChild, ...rest } = props

    const classes = classNames(styles.root, className)

    const Component = asChild ? Slot : 'div'

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {renderCoverChildren(props, { size: 'xs' })}
      </Component>
    )
  }
)

export default SnippetFootnoteCover

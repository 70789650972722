import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import styles from './snippet-container.module.scss'

export type SnippetContainerElement = HTMLDivElement

export type SnippetContainerProps = HTMLAttributes<SnippetContainerElement> & {
  asChild?: boolean
}

const SnippetContainer = forwardRef<SnippetContainerElement, SnippetContainerProps>(
  function SnippetContainer(props, forwardedRef) {
    const { children, className, asChild, ...rest } = props

    const classes = classNames(styles.root, className)

    const Component = asChild ? Slot : 'div'

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default SnippetContainer

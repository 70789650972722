import { ReactNode } from 'react'

export type UseBadgeProps = {
  showZero: boolean
  count: number
  max: number
}

export type UseBadgeReturn = {
  invisible: boolean
  displayValue: ReactNode
}

export function useBadge(props: UseBadgeProps): UseBadgeReturn {
  const { count, showZero, max } = props
  let invisible = false
  if (count === 0 && !showZero) {
    invisible = true
  }
  const displayValue: ReactNode = count > max ? `${max}+` : count
  return { invisible, displayValue }
}

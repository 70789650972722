import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import { renderCoverChildren } from './shared-helpers'
import styles from './snippet-cover.module.scss'

export type SnippetCoverElement = HTMLDivElement

export type SnippetCoverProps = HTMLAttributes<SnippetCoverElement> & {
  asChild?: boolean
}

const SnippetCover = forwardRef<SnippetCoverElement, SnippetCoverProps>(function SnippetCover(
  props,
  forwardedRef
) {
  const { children: _, className, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {renderCoverChildren(props, { size: 'sm' })}
    </Component>
  )
})

export default SnippetCover

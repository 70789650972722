import * as RAlertDialog from '@radix-ui/react-alert-dialog'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useRef } from 'react'

import { BodyText } from '../../body-text/body-text'
import { Button, ButtonElement, ButtonProps } from '../../button/button'
import { Heading } from '../../heading/heading'
import { Input, InputElement, InputProps } from '../../input/input'
import { Overlay } from '../../overlay/overlay'
import { commonPreventEscapeKeyDownCondition, PreventEscapeKeyDownCondition } from '../common'
import styles from './prompt.module.scss'

export type PromptElement = HTMLDivElement

export interface PromptProps extends Omit<HTMLAttributes<PromptElement>, 'title'> {
  className?: string
  title?: ReactNode
  description?: ReactNode
  inputPlaceholder?: string
  inputProps?: Partial<InputProps>
  confirmLabel?: string
  confirmProps?: Partial<ButtonProps>
  cancelLabel?: string
  cancelProps?: Partial<ButtonProps>
  open?: boolean
  defaultOpen?: boolean
  danger?: boolean
  onOpenChange?: (open: boolean) => void
  onConfirm?: (inputValue: string, evt: React.MouseEvent<ButtonElement>) => void
  onCancel?: ButtonProps['onClick']
  onEscapeKeyDown?: (event: KeyboardEvent) => void
  preventEscapeKeyDownCondition?: PreventEscapeKeyDownCondition<PromptProps>
}

export const promptPreventEscapeKeyDownCondition = commonPreventEscapeKeyDownCondition

export const Prompt = forwardRef<PromptElement, PromptProps>(function Prompt(
  {
    children,
    className,
    title,
    description,
    inputPlaceholder,
    inputProps = {},
    confirmLabel = 'Ok',
    confirmProps = {},
    cancelLabel = 'Cancelar',
    cancelProps = {},
    defaultOpen,
    danger,
    onConfirm,
    onCancel,
    onEscapeKeyDown,
    preventEscapeKeyDownCondition = promptPreventEscapeKeyDownCondition,
    ...rest
  },
  forwardedRef
) {
  const inputRef = useRef<InputElement>(null)

  const classes = classNames(styles.root, className)

  cancelProps.disabled = cancelProps.disabled ?? confirmProps.loading
  inputProps.readOnly = inputProps.readOnly ?? confirmProps.loading

  return (
    <RAlertDialog.Root defaultOpen={defaultOpen} {...rest}>
      <RAlertDialog.Trigger asChild>{children}</RAlertDialog.Trigger>
      <RAlertDialog.Portal>
        <RAlertDialog.Overlay asChild>
          <Overlay />
        </RAlertDialog.Overlay>
        <RAlertDialog.Content
          data-testid="content"
          className={classes}
          onEscapeKeyDown={(evt) => {
            onEscapeKeyDown?.(evt)
            if (preventEscapeKeyDownCondition({ cancelProps })) {
              evt.preventDefault()
            }
          }}
          ref={forwardedRef}
        >
          <RAlertDialog.Title asChild>
            <Heading>{title}</Heading>
          </RAlertDialog.Title>
          <RAlertDialog.Description asChild>
            <BodyText className={styles.description}>{description}</BodyText>
          </RAlertDialog.Description>
          <Input placeholder={inputPlaceholder} type="text" {...inputProps} ref={inputRef} />
          <div className={styles.buttonContainer}>
            <RAlertDialog.Action disabled={confirmProps.disabled || confirmProps.loading} asChild>
              <Button
                {...confirmProps}
                kind={danger ? 'danger' : 'primary'}
                onClick={(evt) => {
                  const inputValue = inputRef.current?.value ?? ''
                  onConfirm?.(inputValue, evt)
                }}
              >
                {confirmLabel}
              </Button>
            </RAlertDialog.Action>
            <RAlertDialog.Cancel asChild>
              <Button
                {...cancelProps}
                kind="tertiary"
                onClick={onCancel}
                className={classNames(styles.buttonCancel, cancelProps.className)}
              >
                {cancelLabel}
              </Button>
            </RAlertDialog.Cancel>
          </div>
        </RAlertDialog.Content>
      </RAlertDialog.Portal>
    </RAlertDialog.Root>
  )
})

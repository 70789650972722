import classNames from 'classnames'
import { forwardRef, LiHTMLAttributes } from 'react'

import styles from './carousel-item.module.scss'

export type CarouselItemElement = HTMLLIElement

export type CarouselItemProps = LiHTMLAttributes<CarouselItemElement> & {
  snapPoint?: 'start' | 'end'
}

const CarouselItem = forwardRef<CarouselItemElement, CarouselItemProps>(function CarouselItem(
  props,
  forwardedRef
) {
  const { children, className, snapPoint, ...rest } = props

  const classes = classNames(
    styles.root,
    {
      [styles.snapStart]: snapPoint === 'start',
      [styles.snapEnd]: snapPoint === 'end',
    },
    className
  )

  return (
    <li {...rest} className={classes} ref={forwardedRef}>
      {children}
    </li>
  )
})

export default CarouselItem

type Properties = {
  orderCode?: string | null
  pid?: number | null
  subscriptionId?: number | null
}

// TODO: could be in some shared-types lib. @felipemsantana
type NonUndefined<T> = NonNullable<T> | (T extends null ? null : never)

export class GA4UserProperties {
  'user.orderCode': NonUndefined<Properties['orderCode']>
  'user.pid': NonUndefined<Properties['pid']>
  'user.subscriptionId': NonUndefined<Properties['subscriptionId']>

  constructor(properties: Properties) {
    const { pid, subscriptionId, orderCode } = properties

    this.storeNonUndefined('user.orderCode', orderCode)
    this.storeNonUndefined('user.pid', pid)
    this.storeNonUndefined('user.subscriptionId', subscriptionId)
  }

  private storeNonUndefined<K extends keyof GA4UserProperties>(
    this: GA4UserProperties,
    key: K,
    value: GA4UserProperties[K] | undefined
  ): void {
    if (value !== undefined) {
      this[key] = value
    }
  }
}

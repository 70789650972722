import * as RAvatar from '@radix-ui/react-avatar'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './avatar.module.scss'
import { AvatarElement, AvatarProps, AvatarSize, AvatarSizes } from './types'
import { useAvatar } from './utils'

export const Avatar = forwardRef<AvatarElement, AvatarProps>(function Avatar(props, forwardedRef) {
  const { children, className, size: sizeProp, color: colorProp, alt, ...rest } = props

  const size = ensurePropOf<AvatarSize>(AvatarSizes, sizeProp, 'md')
  const { letters, color } = useAvatar(props)

  const classes = classNames(
    styles.root,
    {
      [styles[`color${color}`]]: !!color,
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  return (
    <RAvatar.Root className={classes} ref={forwardedRef}>
      <RAvatar.Image className={styles.image} alt={alt} {...rest} />
      <RAvatar.Fallback className={styles.fallback} aria-label={alt} role="img">
        {letters}
      </RAvatar.Fallback>
    </RAvatar.Root>
  )
})

export * from './types'

import { NextWebVitalsMetric } from 'next/app'

export function reportWebVitalsToGA(metric: NextWebVitalsMetric): void {
  const { id, name, label, value } = metric
  const data = {
    event: 'gaEvent',
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    event_action: name,
    event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
    event_interaction: true,
  }

  window.dataLayer ??= []
  window.dataLayer.push(data)
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}

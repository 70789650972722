import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgGift(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a3.5 3.5 0 0 0-3.163 5H5c-.922 0-1.747.416-2.298 1.07A2.988 2.988 0 0 0 2 10v2a1 1 0 0 0 1 1v5.8C3 20.475 4.256 22 6 22h12c1.744 0 3-1.524 3-3.2V13a1 1 0 0 0 1-1v-2a3 3 0 0 0-3-3h-1.337A3.5 3.5 0 0 0 12 3.05 3.49 3.49 0 0 0 9.5 2ZM13 7h1.5A1.5 1.5 0 1 0 13 5.5V7ZM9.5 7H11V5.5A1.5 1.5 0 1 0 9.5 7ZM15 19c0 .35-.06.687-.17 1H18c.465 0 1-.445 1-1.2V13h-4v6Zm-5-9c0-.35-.06-.687-.17-1h3.172A1 1 0 0 1 14 10v1h-4v-1Zm10 1h-4v-1c0-.35-.06-.687-.17-1H19a1 1 0 0 1 1 1v1ZM9 13h4v6a1 1 0 0 1-1 1H8.78c.142-.377.22-.784.22-1.2V13ZM7 9a1 1 0 0 1 1 1v1H4v-1a.999.999 0 0 1 1-1h2Zm0 4H5v5.8c0 .755.535 1.2 1 1.2.465 0 1-.445 1-1.2V13Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgGift)

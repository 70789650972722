import '@farol-ds/react/index.css'
import '../styles.scss'

import { FarolProvider } from '@farol-ds/react'
import { Topbar } from '@jusbrasil/topbar'
import { ApolloProvider } from '@jusbrasil-web/core-apollo'
import { configureReportWebVitals } from '@jusbrasil-web/core-next'
import { NextFarolProvider } from '@jusbrasil-web/core-next/farol'
import { LawsuitAppProps, NetworkErrorBoundary } from '@jusbrasil-web/lawsuit/shared'
import { AppRootProvider } from '@jusbrasil-web/shared-app-root-provider'
import { isFrameBusPage } from '@jusbrasil-web/shared-frame-bus'
import { GTMProvider } from '@jusbrasil-web/shared-gtm'
import { HotjarScript } from '@jusbrasil-web/shared-hotjar-script'

function CustomApp({ Component, pageProps }: LawsuitAppProps) {
  if (pageProps?.httpError) return null

  if (isFrameBusPage(pageProps)) {
    return <Component {...pageProps} />
  }

  return (
    <AppRootProvider pageProps={pageProps}>
      <HotjarScript />

      <NextFarolProvider />

      <FarolProvider experimental={{ flags: pageProps.farolFlags }}>
        <NetworkErrorBoundary>
          <Topbar.Provider pageProps={pageProps}>
            <GTMProvider appId="web-lawsuit" />
            <ApolloProvider pageProps={pageProps}>
              <Component {...pageProps} />
            </ApolloProvider>
          </Topbar.Provider>
        </NetworkErrorBoundary>
      </FarolProvider>
    </AppRootProvider>
  )
}

export const reportWebVitals = configureReportWebVitals()

export default CustomApp

export function setItemSessionStorage(key: string, value: boolean, expirationTime: number) {
  const now = new Date()

  const item = {
    value,
    expiry: expirationTime ? now.getTime() + expirationTime : null,
  }

  sessionStorage.setItem(key, JSON.stringify(item))
}

export function getItemSessionStorage(key: string) {
  const itemStr = sessionStorage.getItem(key)

  if (!itemStr) {
    return null
  }
  const { value, expiry } = JSON.parse(itemStr)

  if (expiry) {
    const now = new Date()

    if (now.getTime() > expiry) {
      sessionStorage.removeItem(key)
      return null
    }
  }

  return value
}

import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'

export type PrimitiveTextElement = HTMLParagraphElement

export interface PrimitiveTextProps extends HTMLAttributes<PrimitiveTextElement> {
  children: ReactNode
  className?: string
  asChild?: boolean
}

export const PrimitiveText = forwardRef<PrimitiveTextElement, PrimitiveTextProps>(
  function PrimitiveText(props, forwardedRef) {
    const { children, asChild, className, ...rest } = props

    const Component = asChild ? Slot : 'p'

    return (
      <Component {...rest} className={className} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEmailOpen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.486 4.414a1 1 0 0 0-.972 0l-7 3.889A1 1 0 0 0 4 9.177V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.177a1 1 0 0 0-.514-.874l-7-3.89Zm-1.943-1.749a3 3 0 0 1 2.914 0l7 3.89A3 3 0 0 1 22 9.176V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9.177a3 3 0 0 1 1.543-2.623l7-3.889Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m2 10.461 9.594 4.264a1 1 0 0 0 .812 0L22 10.461V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8.539Zm2 3.078V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-5.461l-6.782 3.014a3 3 0 0 1-2.436 0L4 13.539Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEmailOpen)

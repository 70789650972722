import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import { ChevronRightIcon } from '../icon/icons'
import { Spinner } from '../spinner/spinner'
import styles from './tree-view-toggler.module.scss'

export type TreeViewTogglerElement = HTMLButtonElement

export interface TreeViewTogglerProps extends ButtonHTMLAttributes<TreeViewTogglerElement> {
  selected?: boolean
  expanded?: boolean
  loading?: boolean
  disabled?: boolean
}

const TreeViewToggler = forwardRef<TreeViewTogglerElement, TreeViewTogglerProps>(
  function TreeViewToggler(props, forwardedRef) {
    const {
      className,
      expanded = false,
      loading = false,
      disabled = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      selected = false,
      ...rest
    } = props

    const classes = classNames(
      styles.root,
      {
        [styles.expanded]: expanded,
        [styles.loading]: loading,
      },
      className
    )

    return (
      <button
        {...rest}
        disabled={loading || disabled}
        className={classes}
        aria-label={expanded ? 'Recolher' : 'Expandir'}
        aria-disabled={loading || disabled}
        ref={forwardedRef}
        data-testid="tree-view-toggler"
      >
        {loading && (
          <span className={styles.spinner}>
            <Spinner size="sm" />
          </span>
        )}
        <ChevronRightIcon size="sm" className={styles.icon} />
      </button>
    )
  }
)

export default TreeViewToggler

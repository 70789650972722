import classNames from 'classnames'
import { forwardRef } from 'react'

import { CaptionElement, CaptionProps } from '../caption/caption'
import sharedStyles from './shared-styles.module.scss'

export type SnippetFootnoteCaptionSeparatorElement = CaptionElement

export type SnippetFootnoteCaptionSeparatorProps = Omit<CaptionProps, 'children'>

const SnippetFootnoteCaptionSeparator = forwardRef<
  SnippetFootnoteCaptionSeparatorElement,
  SnippetFootnoteCaptionSeparatorProps
>(function SnippetFootnoteCaptionSeparator(props, forwardedRef) {
  const { className, ...rest } = props

  const classes = classNames(sharedStyles.captionSeparator, className)

  return <span {...rest} className={classes} ref={forwardedRef} />
})

export default SnippetFootnoteCaptionSeparator

import { DEFAULT_DELAY, LawsuitSummarizationStep, STEPS } from './constants'

type Steps = {
  [key in LawsuitSummarizationStep]: {
    name: (typeof STEPS)[key]
    next:
      | LawsuitSummarizationStep
      | null
      | {
          condition?: (isLoading: boolean, summary?: string) => boolean
          target: LawsuitSummarizationStep
        }[]
    delay?: number
  }
}

export const LAWSUIT_SUMMARIZATION_STEPS: Steps = {
  IDLE: {
    name: STEPS.IDLE,
    next: null,
  },
  GENERATING_SUMMARY: {
    name: STEPS.GENERATING_SUMMARY,
    delay: DEFAULT_DELAY,
    next: [
      {
        condition: (isLoading: boolean, summary?: string) => !isLoading && summary !== undefined,
        target: STEPS.SUCCESS,
      },
      {
        condition: (isLoading: boolean, summary?: string) => !isLoading && summary === undefined,
        target: STEPS.FAIL,
      },
    ],
  },
  SUCCESS: {
    name: STEPS.SUCCESS,
    delay: DEFAULT_DELAY,
    next: [
      {
        condition: (isLoading: boolean, summary?: string) => !isLoading && summary !== undefined,
        target: STEPS.SUMMARY_VIEW,
      },
    ],
  },
  FAIL: {
    name: STEPS.FAIL,
    next: null,
  },
  SUMMARY_VIEW: {
    name: STEPS.SUMMARY_VIEW,
    next: null,
  },
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.364 3.103a2 2 0 0 1 2.46 1.085l1.24 2.788a2 2 0 0 1-.639 2.42l-1.149.85a9.94 9.94 0 0 0 4.745 4.765l.72-1.09a2 2 0 0 1 2.481-.726l2.896 1.287a2 2 0 0 1 1.086 2.46l-.416 1.247c-.59 1.77-2.236 3.312-4.362 3.098C9.716 20.61 3.696 14.59 3.02 7.88c-.214-2.126 1.327-3.772 3.097-4.362l1.247-.416ZM7.997 5l-1.247.416C5.597 5.8 4.915 6.741 5.01 7.68c.58 5.764 5.852 11.037 11.616 11.617.94.094 1.88-.587 2.264-1.74l.416-1.247-2.896-1.287-1.184 1.793a1 1 0 0 1-1.203.378c-3.237-1.282-5.628-3.673-6.91-6.91a1 1 0 0 1 .334-1.173l1.789-1.323L7.996 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPhone)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgRefresh(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.37 6.75a7 7 0 0 1 11.617 4.823l-.28-.28a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l2-2a1 1 0 1 0-1.414-1.414l-.302.302A9 9 0 0 0 6.047 5.25a1 1 0 1 0 1.323 1.5Zm9.26 10.5a7 7 0 0 1-11.617-4.823l.28.28a1 1 0 0 0 1.414-1.414l-2-2a1 1 0 0 0-1.414 0l-2 2a1 1 0 1 0 1.414 1.414l.302-.302a9 9 0 0 0 14.944 6.345 1 1 0 1 0-1.323-1.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgRefresh)

import { SearchIcon, useDebounceCallback } from '@farol-ds/react'
import { Dispatch, SetStateAction } from 'react'

import { AutocompleteOption, ResponseAPI } from '../../utils'

interface UseAutocompleteRequest {
  setOptions: Dispatch<SetStateAction<AutocompleteOption[]>>
  setLoading: Dispatch<SetStateAction<boolean>>
  mountHistoryItems: () => Promise<AutocompleteOption[]>
}

export function useAutocompleteRequest(props: UseAutocompleteRequest) {
  const { setOptions, setLoading, mountHistoryItems } = props

  return useDebounceCallback((t: string) => {
    fetch(`https://autocomplete-br-endpoint.jusbrasil.com.br/v2/_autocomplete?q=${t}`)
      .then((res) => res.json())
      .then(async (res: ResponseAPI) => {
        const data: AutocompleteOption[] = res.suggestions.map((item, idx) => ({
          id: idx,
          label: item.suggestion,
          value: item.suggestion.replace(/<\/?[^>]+(>|$)/g, ''),
          metadata: res.metadata,
          leftIcon: <SearchIcon />,
        }))
        return mountHistoryItems().then((historyItems) => {
          if (historyItems.length === 0) {
            return data
          }
          const items = [...historyItems, ...data]
          const uniqueItems = items.filter(
            (item, idx, self) => self.findIndex((i) => i.value === item.value) === idx
          )
          return uniqueItems
        })
      })
      .then(setOptions)
      .finally(() => setLoading(false))
      .catch((err) => console.error(err))
  }, 250)
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgTag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.707 4.293a6.657 6.657 0 1 0-9.414 9.414l7.586 7.586a3 3 0 0 0 4.242 0l5.172-5.172a3 3 0 0 0 0-4.242l-7.586-7.586Zm-8 1.414a4.657 4.657 0 0 1 6.586 0l7.586 7.586a1 1 0 0 1 0 1.414l-5.172 5.172a1 1 0 0 1-1.414 0l-7.586-7.586a4.657 4.657 0 0 1 0-6.586Zm11 7.586a1 1 0 0 0-1.414 0l-2 2a1 1 0 0 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414ZM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgTag)

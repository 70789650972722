import { ReactNode } from 'react'

import { Slot } from '../utilities'
import { useBanner } from './use-banner'

export interface BannerCloseProps {
  children: ReactNode
}

const BannerClose: React.FC<BannerCloseProps> = (props) => {
  const { onClose } = useBanner()
  return <Slot {...props} onClick={onClose} />
}

export default BannerClose

import { gql, useMutation } from '@apollo/client'

export const REQUEST_CPF_REMOVAL_MUTATION = gql`
  mutation UseRequestCPFRemovalMutation($input: RequestCPFRemovalInput!) {
    requestCpfRemoval(input: $input) {
      clientMutationId
      success
    }
  }
`

interface RequestCPFRemovalArgs {
  pid?: number
  cpf?: string
}

export const useRequestCPFRemoval = (args: RequestCPFRemovalArgs) => {
  const { pid, cpf } = args

  const [requestCPFRemoval] = useMutation(REQUEST_CPF_REMOVAL_MUTATION)

  return (template: string) => {
    const variables = {
      input: {
        oldPid: pid,
        cpf,
        backTo: `${window.location.origin}${window.location.pathname}`,
        template,
      },
    }

    return requestCPFRemoval({ variables })
  }
}

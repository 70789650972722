import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgVerified(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.63 3.888a1.5 1.5 0 0 1 2.74 0 1 1 0 0 0 1.5.402 1.5 1.5 0 0 1 2.373 1.37 1 1 0 0 0 1.097 1.097 1.5 1.5 0 0 1 1.37 2.374 1 1 0 0 0 .402 1.499 1.5 1.5 0 0 1 0 2.74 1 1 0 0 0-.402 1.5 1.5 1.5 0 0 1-1.37 2.373 1 1 0 0 0-1.098 1.097 1.5 1.5 0 0 1-2.373 1.37 1 1 0 0 0-1.499.402 1.5 1.5 0 0 1-2.74 0 1 1 0 0 0-1.5-.402 1.5 1.5 0 0 1-2.373-1.37 1 1 0 0 0-1.097-1.097 1.5 1.5 0 0 1-1.37-2.374 1 1 0 0 0-.402-1.499 1.5 1.5 0 0 1 0-2.74 1 1 0 0 0 .402-1.5 1.5 1.5 0 0 1 1.37-2.373A1 1 0 0 0 6.757 5.66a1.5 1.5 0 0 1 2.374-1.37 1 1 0 0 0 1.499-.402ZM12 1c-1.048 0-1.988.46-2.629 1.19a3.491 3.491 0 0 0-2.871.284 3.491 3.491 0 0 0-1.682 2.344c-.951.19-1.82.774-2.344 1.682l.866.5-.866-.5a3.491 3.491 0 0 0-.285 2.871A3.491 3.491 0 0 0 1 12c0 1.048.46 1.988 1.19 2.629-.311.919-.24 1.963.284 2.871a3.491 3.491 0 0 0 2.344 1.682c.19.951.774 1.82 1.682 2.344a3.491 3.491 0 0 0 2.871.285C10.012 22.539 10.951 23 12 23c1.048 0 1.988-.46 2.629-1.19.919.311 1.963.24 2.871-.284a3.49 3.49 0 0 0 1.682-2.344 3.49 3.49 0 0 0 2.344-1.682 3.491 3.491 0 0 0 .285-2.871A3.491 3.491 0 0 0 23 12c0-1.048-.46-1.988-1.19-2.629.311-.919.24-1.963-.284-2.871a3.491 3.491 0 0 0-2.344-1.682A3.49 3.49 0 0 0 17.5 2.474a3.491 3.491 0 0 0-2.871-.285A3.491 3.491 0 0 0 12 1Zm.894 8.553a1 1 0 0 0-1.788 0l-2 4a1 1 0 1 0 1.788.894l1.017-2.032a.1.1 0 0 1 .178 0l1.017 2.032a1 1 0 1 0 1.788-.894l-2-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgVerified)

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4ClickEventProps {
  componentName: string | null
  componentClass?: string | null
  resourceId?: string | null
  selectedOption?: string | null
}

export class GA4ClickEvent extends GA4Event {
  ga4_component_name: string | null
  ga4_component_class: string | null
  ga4_resource_id: string | null
  ga4_selected_option: string | null

  constructor({
    componentName,
    componentClass = null,
    resourceId = null,
    selectedOption = null,
  }: GA4ClickEventProps) {
    super({ event: EVENT.CLICK })

    this.ga4_component_class = componentClass
    this.ga4_component_name = componentName
    this.ga4_resource_id = resourceId
    this.ga4_selected_option = selectedOption
  }
}

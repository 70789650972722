import { RefObject, useCallback, useEffect, useRef } from 'react'

import { TabsListProps } from './tabs-list'
import { TabsTriggerElement } from './tabs-trigger'
import { useTabs } from './use-tabs'

export type UseTabsListProps = TabsListProps

export interface UseTabsListReturn {
  scrollToDirection: (multiplier: number) => void
  listRef: RefObject<HTMLDivElement>
  arrowLeftRef: RefObject<HTMLSpanElement>
  arrowRightRef: RefObject<HTMLSpanElement>
  underlineRef: RefObject<HTMLSpanElement>
}

const MAX_ITEM_WIDTH_FALLBACK = 100
const FADE_ELEMENT_WIDTH = 12
const ARROW_ELEMENT_WIDTH = 44 + FADE_ELEMENT_WIDTH

export function useTabsList(props: UseTabsListProps): UseTabsListReturn {
  const { scrollMaxItemWidth, scrollMultiplier = 1.5 } = props
  const { onValueChange, activeTabValue, tabsRef } = useTabs()

  const listRef = useRef<HTMLDivElement>(null)
  const arrowLeftRef = useRef<HTMLSpanElement>(null)
  const arrowRightRef = useRef<HTMLSpanElement>(null)
  const underlineRef = useRef<HTMLSpanElement>(null)
  const maxWidthRef = useRef(0)

  useEffect(() => {
    const element = listRef.current
    const underlineEl = underlineRef.current
    const tabsRefEl = tabsRef?.current
    if (!element || !underlineEl || !tabsRefEl) return
    const currentTab = activeTabValue ? tabsRef.current[activeTabValue] : null

    // mount arrow button behavior
    const detectVisibleArrows = () => {
      const contentRight = element.getBoundingClientRect().right || 0
      const lastItemRight = element.lastElementChild?.getBoundingClientRect().right || 0
      const leftVisible = element.scrollLeft > 0 ? '1' : '0'
      const rightVisible = Math.round(lastItemRight) > Math.round(contentRight) ? '1' : '0'
      // [perf] avoid re-render unnecessary when scrolling
      if (
        arrowLeftRef.current &&
        arrowLeftRef.current.dataset.fdsTabsArrowVisible !== leftVisible
      ) {
        arrowLeftRef.current.dataset.fdsTabsArrowVisible = leftVisible
      }
      if (
        arrowRightRef.current &&
        arrowRightRef.current.dataset.fdsTabsArrowVisible !== rightVisible
      ) {
        arrowRightRef.current.dataset.fdsTabsArrowVisible = rightVisible
      }
    }

    // anti-collision: auto-positioning when tab is outside of scrollview
    const detectColision = (el: TabsTriggerElement) => {
      const arrowElementWidth = ARROW_ELEMENT_WIDTH
      const { left, right } = el.getBoundingClientRect()
      // left
      if (element.scrollLeft > 0 && left < arrowElementWidth) {
        element.scrollLeft = left - arrowElementWidth / 2
        return
      }
      // right
      const contentRight = (element.getBoundingClientRect().right || 0) - arrowElementWidth
      if (right > contentRight) {
        element.scrollLeft += right - contentRight + arrowElementWidth / 2
      }
    }

    const onClickTab = <K extends Event = MouseEvent | TouchEvent>(e: K) => {
      const tab = e.currentTarget as TabsTriggerElement
      detectColision(tab)
    }

    // calculate max width of items and enable anti-collision
    Object.values(tabsRefEl).forEach((el) => {
      if (!el) return
      const { width } = el.getBoundingClientRect()
      // it already covered by e2e
      /* istanbul ignore if */
      if (width > maxWidthRef.current) {
        maxWidthRef.current = width
      }
      el.addEventListener('click', onClickTab)
    })

    detectVisibleArrows()

    if (currentTab) {
      detectColision(currentTab)
    }

    const onRefreshScroll = () => {
      // it already covered by e2e
      /* istanbul ignore next */
      detectVisibleArrows()
    }
    element.addEventListener('scroll', onRefreshScroll)
    window.addEventListener('resize', onRefreshScroll)
    return () => {
      element.removeEventListener('scroll', onRefreshScroll)
      window.removeEventListener('resize', onRefreshScroll)

      // remove anti-collision binding
      Object.values(tabsRefEl).forEach((el) => {
        el?.removeEventListener('click', onClickTab)
      })
    }
  }, [activeTabValue, listRef, arrowLeftRef, arrowRightRef, maxWidthRef, tabsRef])

  useEffect(() => {
    const setUnderlinePosition = () => {
      if (!tabsRef?.current || !activeTabValue || !underlineRef?.current) return
      const currentTab = tabsRef.current[activeTabValue]
      if (!currentTab) return
      underlineRef.current.style.left = `${currentTab?.offsetLeft ?? 0}px`
      underlineRef.current.style.width = `${currentTab?.clientWidth ?? 0}px`
    }

    setUnderlinePosition()

    window.addEventListener('resize', setUnderlinePosition)
    return () => {
      window.removeEventListener('resize', setUnderlinePosition)
    }
  }, [activeTabValue, underlineRef, tabsRef])

  useEffect(() => {
    const setTabFromHash = () => {
      if (!tabsRef?.current) return
      const hashTab = document.location.hash.replace(/^#/, '')
      if (hashTab) {
        if (hashTab === activeTabValue) return
        const tabEl = tabsRef.current[hashTab]
        if (tabEl && !('disabled' in tabEl.dataset)) {
          onValueChange?.(hashTab)
        }
      }
    }

    setTabFromHash()

    window.addEventListener('hashchange', setTabFromHash)
    return () => {
      window.removeEventListener('hashchange', setTabFromHash)
    }
  }, [])

  const scrollToDirection = useCallback(
    (multiplier: number) => {
      // it already covered by e2e
      /* istanbul ignore if */
      if (listRef.current) {
        const itemSize =
          scrollMaxItemWidth ||
          maxWidthRef.current ||
          listRef.current.firstElementChild?.getBoundingClientRect().width ||
          MAX_ITEM_WIDTH_FALLBACK
        const currentItem = Math.round(listRef.current.scrollLeft / itemSize)
        const scrollLeft = Math.max((currentItem + multiplier * scrollMultiplier) * itemSize, 0)
        listRef.current.scrollLeft = scrollLeft
      }
    },
    [scrollMultiplier, scrollMaxItemWidth]
  )

  return {
    listRef,
    arrowRightRef,
    arrowLeftRef,
    underlineRef,
    scrollToDirection,
  }
}

import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import { cloneElement, isValidElement } from 'react'

import { MenuItemProps } from '../menu-item/menu-item'

export type DropdownMenuRadioItemElement = typeof RDropdownMenu.Item
export type DropdownMenuRadioItemProps = RDropdownMenu.DropdownMenuRadioItemProps & {
  currentValue?: RDropdownMenu.DropdownMenuRadioItemProps['value']
}

const DropdownMenuRadioItem: React.FC<DropdownMenuRadioItemProps> = (props) => {
  const { children, disabled, value, currentValue, ...rest } = props
  return (
    <RDropdownMenu.RadioItem {...rest} value={value} disabled={disabled} asChild>
      {isValidElement<MenuItemProps>(children)
        ? cloneElement(children, {
            current: value === currentValue,
            disabled,
          })
        : children}
    </RDropdownMenu.RadioItem>
  )
}

export default DropdownMenuRadioItem

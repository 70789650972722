import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgRss(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3a1 1 0 0 0 0 2c8.284 0 15 6.716 15 15a1 1 0 1 0 2 0c0-9.389-7.611-17-17-17Zm-1 8a1 1 0 0 1 1-1c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1-1-1Zm0 7a1 1 0 0 1 1-1 3 3 0 0 1 3 3 1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgRss)

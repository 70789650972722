import { useModalContext } from './context'
import { Steps } from './enums'
import { FLOW_STEPS, FlowStepsBodyText } from './flow-steps'

export const StepRenderer = (props: {
  currentStep: Steps
  onClose: () => void
  stepsCallback?: (isValidatedSuccess: boolean) => void
  flowStepsBodyText?: FlowStepsBodyText
}) => {
  const { onClose, currentStep, stepsCallback, flowStepsBodyText: flowStepsText } = props
  const { sharedData, eventData, viewer } = useModalContext()

  const renderStep = FLOW_STEPS[currentStep]
  return renderStep({
    onClose,
    sharedData,
    eventData,
    stepsCallback,
    flowStepsBodyText: flowStepsText,
    viewer,
  })
}

import { Button } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, useContext } from 'react'

import { TopbarContext } from '../../context'
import { withNextUrl } from '../../utils'
import styles from './topbar-user-menu-unlogged.module.scss'

export type TopbarUserMenuUnloggedElement = HTMLSpanElement

export type TopbarUserMenuUnloggedProps = HTMLAttributes<TopbarUserMenuUnloggedElement>

export const TopbarUserMenuUnlogged = forwardRef<
  TopbarUserMenuUnloggedElement,
  TopbarUserMenuUnloggedProps
>(function TopbarUserMenuUnlogged(props, forwardedRef) {
  const { logged } = useContext(TopbarContext)

  if (logged) return null

  const { className, ...rest } = props
  const classes = classNames(styles.root, className)

  const goToPage = (baseUrl: string) => {
    window.location.assign(withNextUrl(baseUrl, window.document.URL))
  }

  return (
    <span
      {...rest}
      className={classes}
      data-testid="topbar-header-user-menu-unlogged"
      ref={forwardedRef}
    >
      <Button kind="secondary" onClick={() => goToPage('https://www.jusbrasil.com.br/cadastro')}>
        Cadastre-se
      </Button>
      <Button onClick={() => goToPage('https://www.jusbrasil.com.br/login')}>Entrar</Button>
    </span>
  )
})

import * as RDialog from '@radix-ui/react-dialog'

export type DrawerCloseElement = typeof RDialog.DialogClose

export type DrawerCloseProps = RDialog.DialogCloseProps

const DrawerClose: React.FC<DrawerCloseProps> = (props) => {
  return <RDialog.Close asChild {...props} />
}

export default DrawerClose

import * as RSelect from '@radix-ui/react-select'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { useFormControl } from '../form-control/use-form-control'
import { ChevronDownIcon } from '../icon/icons'
import styles from './select-trigger.module.scss'

export type SelectTriggerProps = RSelect.SelectTriggerProps & {
  error?: boolean
  placeholder?: string
}

export type SelectTriggerElement = HTMLButtonElement

const SelectTrigger = forwardRef<SelectTriggerElement, SelectTriggerProps>(function SelectTrigger(
  props,
  forwardedRef
) {
  const {
    children,
    className,
    placeholder = 'Selecionar',
    disabled: disabledProp,
    error: errorProp,
    ...rest
  } = props

  const { error, disabled, id } = useFormControl()

  const hasError = error || errorProp
  const isDisabled = disabled || disabledProp

  const classes = classNames(
    styles.root,
    {
      [styles.error]: hasError,
      [styles.disabled]: isDisabled,
    },
    className
  )

  return (
    <RSelect.Trigger asChild>
      <button
        data-testid="button"
        {...rest}
        disabled={isDisabled}
        className={classes}
        ref={forwardedRef}
        id={id}
      >
        <RSelect.Value placeholder={placeholder} />
        <ChevronDownIcon size="sm" className={styles.icon} />
      </button>
    </RSelect.Trigger>
  )
})

export default SelectTrigger

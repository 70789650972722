import type { ToastProps as RToastProps } from '@radix-ui/react-toast'
import * as RToast from '@radix-ui/react-toast'
import classNames from 'classnames'
import {
  cloneElement,
  CSSProperties,
  forwardRef,
  isValidElement,
  RefObject,
  useImperativeHandle,
  useRef,
} from 'react'

import { AlertIcon, CloseIcon } from '../icon/icon'
import { IconProps } from '../icon/types'
import { ensurePropOf } from '../utilities'
import styles from './toast.module.scss'
import {
  ToastDuration,
  ToastMessage,
  ToastSensitivities,
  ToastSensitivity,
  ToastType,
  ToastTypes,
} from './types'

export type ToastElement = {
  close: () => void
}

export type ToastElementRoot = HTMLLIElement

export interface ToastProps
  extends Pick<RToastProps, 'onOpenChange' | 'open' | 'defaultOpen' | 'forceMount'> {
  children: ToastMessage
  icon?: React.ReactNode
  type?: ToastType
  sensitivity?: ToastSensitivity
  duration?: ToastDuration
  className?: string
  rootRef?: RefObject<ToastElementRoot>
}

const iconProps: IconProps = {
  size: 'sm',
  className: styles.icon,
}

export const Toast = forwardRef<ToastElement, ToastProps>(function Toast(props, forwardedRef) {
  const {
    duration = 5000,
    type: typeProp,
    sensitivity: sensitivityProp,
    className,
    icon,
    children,
    rootRef,
    ...rest
  } = props

  const loadingLayerRef = useRef<HTMLSpanElement>(null)
  const type = ensurePropOf<ToastType>(ToastTypes, typeProp, 'neutral')
  const sensitivity = ensurePropOf<ToastSensitivity>(
    ToastSensitivities,
    sensitivityProp,
    'foreground'
  )

  const classes = classNames(
    styles.root,
    {
      [styles[`type${type}`]]: type !== 'neutral',
    },
    className
  )

  useImperativeHandle(forwardedRef, () => ({
    close() {
      props.onOpenChange?.(false)
    },
  }))

  return (
    <RToast.Root
      {...rest}
      data-fds-toast
      duration={duration}
      className={classes}
      type={sensitivity}
      ref={rootRef}
      onPause={() => {
        if (loadingLayerRef.current) {
          loadingLayerRef.current.style.setProperty('animation-play-state', 'paused')
        }
      }}
      onResume={() => {
        if (loadingLayerRef.current) {
          loadingLayerRef.current.style.removeProperty('animation-play-state')
        }
      }}
    >
      <div className={styles.content}>
        {type === 'negative' ? (
          <AlertIcon {...iconProps} />
        ) : (
          isValidElement<IconProps>(icon) && cloneElement(icon, iconProps)
        )}

        <RToast.Title className={styles.message}>{children}</RToast.Title>
        <RToast.Close asChild>
          <button aria-label="Fechar" className={styles.closeBtn} data-testid="fds-toast-close-btn">
            <CloseIcon size="sm" />
          </button>
        </RToast.Close>
      </div>

      <span
        className={styles.loadingLayer}
        style={{ '--fds-toast-duration': `${duration}ms` } as CSSProperties}
        ref={loadingLayerRef}
      />
    </RToast.Root>
  )
})

export * from './toast-provider'
export * from './types'
export * from './use-toast'

import { gql } from '@apollo/client'
import { BodyText, Button, FormControl, Input, Link, Modal } from '@farol-ds/react'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useIMask } from 'react-imask'

import { EventData } from '../../../utils'
import { PRIVACY_POLICY_URL, TERMS_URL } from '../constants'
import { ValidateIdentityStep__ViewerFragment } from './__generated__/validate-identity.graphql'
import { BIRTHDATE_VALIDATIONS, CPF_VALIDATIONS, validateSpec } from './utils/form-validations'
import styles from './validate-identity.module.scss'

type FormErrorData = {
  cpf?: string
  birthDate?: string
}

export interface ValidateIdentityStepProps {
  onValidate: (cpf: string, birthDate: Date) => void
  viewer: ValidateIdentityStep__ViewerFragment
  eventProps: {
    viewerPid: number
  } & EventData
  initData?: { cpf?: string; birthDate?: Date }
}

export function ValidateIdentityStep(props: ValidateIdentityStepProps) {
  const formRef = useRef<HTMLFormElement>(null)

  const { viewer, eventProps, initData, onValidate } = props

  const { cpf, birthDate } = viewer || {}
  const { ref: cpfRef, unmaskedValue: unmaskedCpf, maskRef } = useIMask({ mask: '000.000.000-00' })
  const { ref: birthDateRef } = useIMask({ mask: '00/00/0000' })

  const { viewerPid, ...eventData } = eventProps

  useEffect(() => {
    sendMetrics('UserAccount.IdValidationViewed', viewerPid.toString(), eventData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (maskRef.current) {
      maskRef.current.value = initData?.cpf || cpf || ''
    }

    if (birthDateRef.current) {
      const defaultValue = initData?.birthDate?.getTime() || birthDate
      const formattedDate = defaultValue ? formatDate(defaultValue) : ''
      birthDateRef.current.value = formattedDate
    }
  }, [maskRef, initData, birthDateRef, cpf, birthDate])

  const [errors, setErrors] = useState<FormErrorData>({})

  function onSubmit(e: FormEvent) {
    e.preventDefault()
    setErrors({})

    const birthDate = birthDateRef.current?.value?.split('/').reverse().join('-')

    const cpfError = validateSpec(CPF_VALIDATIONS, unmaskedCpf)
    const birthDateError = validateSpec(BIRTHDATE_VALIDATIONS, birthDate || '')

    if (cpfError || birthDateError) {
      return setErrors({
        cpf: cpfError?.message,
        birthDate: birthDateError?.message,
      })
    }

    if (!birthDate || !unmaskedCpf) return

    const birthDateObject = new Date(`${birthDate}T00:00:00.000-03:00`)

    sendMetrics('UserAccount.IdValidationSubmitted', viewerPid.toString(), {
      ...eventData,
      metadata: {
        ...eventData?.metadata,
        cpf: unmaskedCpf,
        birthDate: formatDate(birthDateObject.getTime()),
      },
    })

    onValidate(unmaskedCpf, birthDateObject)
  }

  const requestSubmit = () => formRef.current?.requestSubmit()

  return (
    <>
      <Modal.Header>
        <Modal.HeaderTitle>Verifique sua identidade</Modal.HeaderTitle>
        <Modal.HeaderDescription>
          Preencha as seguintes informações para verificarmos a sua identidade.
        </Modal.HeaderDescription>
      </Modal.Header>

      <Modal.Body data-testid="validate-identity-step">
        <form ref={formRef} className={styles.form} onSubmit={onSubmit} noValidate>
          <FormControl required error={!!errors.cpf}>
            <FormControl.Label>CPF</FormControl.Label>
            <Input name="cpf" inputMode="numeric" ref={cpfRef} />
            <FormControl.ErrorMessage>{errors.cpf}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl required error={!!errors.birthDate}>
            <FormControl.Label>Data de nascimento</FormControl.Label>
            <Input type="text" ref={birthDateRef} inputMode="numeric" />
            <FormControl.ErrorMessage>{errors.birthDate}</FormControl.ErrorMessage>
          </FormControl>

          <BodyText size="lg">
            Concordo com os{' '}
            <Link type="secondary" href={TERMS_URL} target="_blank">
              Termos de Uso
            </Link>{' '}
            e a{' '}
            <Link type="secondary" href={PRIVACY_POLICY_URL} target="_blank">
              Política de Privacidade
            </Link>{' '}
            do Jusbrasil.
          </BodyText>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button kind="primary" onClick={requestSubmit}>
          Verificar identidade
        </Button>
      </Modal.Footer>
    </>
  )
}

ValidateIdentityStep.fragments = {
  viewer: gql`
    fragment ValidateIdentityStep__viewer on Profile {
      cpf
      birthDate
    }
  `,
}

import { parseUserCookie } from '@jusbrasil-web/shared-user-session'
import { getCookie } from '@jusbrasil-web/shared-utils-cookies'
import { GetServerSidePropsContext } from 'next'

import { SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION } from '../../constants'
import { TopbarContextType } from '../../types'
import { TOPBAR_NEXT_INTERNAL_KEY } from '../constants'
import { getNavigationExperimentAlternative } from '../experiments/experiments'
import { WithTopbarPropsReturn } from '../types'

export async function withTopbarProps(
  ctx: Pick<GetServerSidePropsContext, 'req' | 'query' | 'res' | 'resolvedUrl'>
): Promise<WithTopbarPropsReturn> {
  const { req } = ctx
  if (!req) {
    console.warn(
      `No request detected, withTopbarProps (@jusbrasil-web/shared-topbar) expects to be used on pages with getServerSideProps, even if setup on custom documents getInitialProps`
    )
    return {}
  }

  const userCookie = parseUserCookie(ctx)
  const logged = userCookie?.pid !== undefined
  const currentUrl = `https://${req.headers.host}${ctx.resolvedUrl}`

  const userIntentionsCookie = req.cookies?.user_intentions ?? ''
  const isInvolvedPartyLawsuit = ['PARTY', 'RELATIVE'].includes(userIntentionsCookie)

  // [FIXME] it's temporary until we rollout the feature flag. @andersonba
  const extraProps: Record<string, unknown> = {}
  if (getCookie(req, 'feature_farol_theme') === 'true') {
    extraProps.farolFlags = { theme: true }
  }

  // topbar ui context
  const context: TopbarContextType = {
    logged,
    currentUrl,
    flags: {
      isInvolvedPartyLawsuit,
      navigationExperimentAlternative: await getNavigationExperimentAlternative(ctx),
      shouldShowJusIABasedOnCurrentSubscription:
        getCookie(req, SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION.cookie.name) === 'true',
    },
  }

  // topbar next context
  return {
    ...extraProps,
    [TOPBAR_NEXT_INTERNAL_KEY]: {
      ctx: context,
      hoc: true,
    },
  }
}

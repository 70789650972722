import { ReactNode, useEffect } from 'react'

import {
  defaultExperimentalProviderProps,
  ExperimentalProvider,
  ExperimentalProviderProps,
} from '../experimental-provider/experimental-provider'
import { FarolTheme, ThemeProvider } from '../theme-provider/theme-provider'
import {
  defaultToastProviderProps,
  ToastProvider,
  ToastProviderProps,
} from '../toast/toast-provider'
import {
  defaultTooltipProviderProps,
  TooltipProvider,
  TooltipProviderProps,
} from '../tooltip/tooltip-provider'

export interface FarolProviderProps {
  children: ReactNode
  theme?: FarolTheme
  tooltip?: Omit<TooltipProviderProps, 'children'>
  toast?: Omit<ToastProviderProps, 'children'>
  experimental?: Omit<ExperimentalProviderProps, 'children'>
}

export function FarolProvider(props: FarolProviderProps): JSX.Element {
  const {
    children,
    theme,
    experimental = defaultExperimentalProviderProps,
    tooltip = defaultTooltipProviderProps,
    toast = defaultToastProviderProps,
  } = props

  useEffect(() => {
    document.documentElement.dataset.fdsReady = '1'
  }, [])

  return (
    <ExperimentalProvider {...experimental}>
      <ThemeProvider theme={theme}>
        <TooltipProvider {...tooltip}>
          <ToastProvider {...toast}>{children}</ToastProvider>
        </TooltipProvider>
      </ThemeProvider>
    </ExperimentalProvider>
  )
}

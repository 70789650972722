import { EVENT_PROPERTIES_STORAGE_KEY, snowpiercerClient } from '../constants'
import { NamespacesStorageObject } from '../types/namespaces-storage-object'
import { getNamespacesObjectFromStorage } from './get-namespace-object-from-storage'
import { runOnBrowser } from './run-on-browser'

export const namespacesStorageObjectPromise = runOnBrowser<Promise<NamespacesStorageObject>>(
  () =>
    getNamespacesObjectFromStorage().then(async (value) => {
      await snowpiercerClient.removeItem(EVENT_PROPERTIES_STORAGE_KEY)
      return value
    }),
  Promise.resolve({})
)

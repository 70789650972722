import classNames from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement, ReactElement, ReactNode } from 'react'

import { Button, ButtonElement, ButtonProps } from '../button/button'
import { IconProps } from '../icon/icon'
import { Tooltip, TooltipProps } from '../tooltip/tooltip'
import { ensurePropOf } from '../utilities'
import styles from './icon-button.module.scss'

export const IconButtonKinds = ['plain', 'secondary', 'tertiary', 'dangerSecondary'] as const
export const IconButtonSizes = ['sm', 'md', 'lg'] as const
export type IconButtonElement = ButtonElement
export type IconButtonKind = (typeof IconButtonKinds)[number]
export type IconButtonSize = (typeof IconButtonSizes)[number]

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
  label: string
  kind?: IconButtonKind
  size?: IconButtonSize
  loading?: boolean
  disabled?: boolean
  tooltipProps?: Partial<Omit<TooltipProps, 'children'>>
  className?: string
} & (
    | {
        icon: ReactNode
        asChild?: never
        children?: never
      }
    | {
        asChild: true
        children: ReactNode
        icon?: never
      }
  )

export const IconButton = forwardRef<IconButtonElement, IconButtonProps>(function IconButton(
  props,
  forwardedRef
) {
  const {
    label,
    className,
    disabled,
    loading,
    icon,
    children,
    tooltipProps,
    kind: kindProp,
    size: sizeProp,
    asChild: asChildProp,
    ...rest
  } = props

  const asChild = asChildProp && isValidElement(children)

  const kind = ensurePropOf<IconButtonKind>(IconButtonKinds, kindProp, 'plain')
  const size = ensurePropOf<IconButtonSize>(IconButtonSizes, sizeProp, 'md')
  const noInteractive = disabled || loading

  const classes = classNames(
    styles.root,
    {
      [styles[`kind${kind}`]]: !!kind,
      [styles[`size${size}`]]: !!size,
      [styles.nointeractive]: noInteractive,
    },
    className
  )

  const iconSize = size === 'sm' ? 'xs' : 'sm'

  return (
    <Tooltip label={label} {...tooltipProps}>
      <Button
        aria-label={label}
        {...rest}
        kind={kind}
        size={size}
        loading={loading}
        disabled={disabled}
        className={classes}
        spinnerClassName={styles.spinner}
        asChild={asChild}
        ref={forwardedRef}
      >
        {asChild
          ? cloneElement(
              Children.only(children) as ReactElement,
              {},
              Children.toArray(renderIcon(children.props.children, iconSize))
            )
          : renderIcon(icon, iconSize)}
      </Button>
    </Tooltip>
  )
})

function renderIcon(comp: ReactNode, size: IconProps['size']) {
  return cloneElement(Children.only(comp) as ReactElement<IconProps>, {
    size,
  })
}

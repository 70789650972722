import { ReactNode } from 'react'

export type ToastMessage = ReactNode
export type ToastDuration = number
export const ToastTypes = ['neutral', 'negative'] as const
export type ToastType = (typeof ToastTypes)[number]
export const ToastSensitivities = ['foreground', 'background'] as const
export type ToastSensitivity = (typeof ToastSensitivities)[number]
export const ToastProviderPlacements = [
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
] as const
export type ToastProviderPlacement = (typeof ToastProviderPlacements)[number]

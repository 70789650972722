import { BodyText, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'

import styles from '../lawsuit-summary-modal.module.scss'
import positiveFeedbackIcon from '../stamps/high-five.svg'

export function PositiveFeedback() {
  return (
    <Modal.Body className={styles.generatingModal} data-testid="lawsuit-summary-modal-success-step">
      <Image
        src={positiveFeedbackIcon}
        alt={'Explicação concluída'}
        width={120}
        height={120}
        priority
      />
      <div>
        <Heading className={styles.title} size="md" asChild>
          <h2>Explicação concluída</h2>
        </Heading>

        <BodyText size="md" className={styles.label}>
          Resumimos o processo para você.
        </BodyText>
      </div>
    </Modal.Body>
  )
}

import { ImageLoader } from 'next/image'

function sanitizeUrl(url: string): string {
  return url.replace(/^(https?:)?(\/\/)?/, '')
}

export const imageLoader: ImageLoader = ({ src, width, quality }) => {
  const sanitizedSrc = sanitizeUrl(src)
  const isRelativePath = sanitizedSrc === src
  if (isRelativePath) {
    if (process.env.NODE_ENV !== 'production') {
      /* ignore warning bellow in development mode (without thumbor)
       * Image with src "my-file.png" has a "loader" property that does not implement width. Please implement it or use the "unoptimized" property instead.
       */
      return `${sanitizedSrc}?w_${width}`
    }
    return sanitizedSrc
  }
  const filters: string[] = ['format(webp)']
  if (quality) {
    filters.push(`quality(${quality})`)
  }
  const filtersStr = filters.length ? `filters:${filters.join(':')}/` : ''
  return `https://thumbs.jusbr.com/${width}x0/${filtersStr}${sanitizedSrc}`
}

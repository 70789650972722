import { GetServerSidePropsContext } from 'next'

import { USER_SESSION_INTERNAL_DATA } from '../constants'
import { parseUserCookie } from '../parse-user-cookie/parse-user-cookie'
import { WithUserSessionOptions, WithUserSessionReturn } from '../types'

export function withUserSession(
  ctx: Pick<GetServerSidePropsContext, 'req'>,
  options: WithUserSessionOptions = {}
): WithUserSessionReturn {
  const parsed = parseUserCookie(ctx)
  const data: Record<string, unknown> = {
    ...parsed,
    ...options,
  }
  for (const key in data) {
    if (data[key] == null) {
      delete data[key]
    }
  }
  return {
    [USER_SESSION_INTERNAL_DATA]: data,
  }
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgWhatsapp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.378 5.616A8.926 8.926 0 0 0 12.038 3c-4.94 0-8.96 4.001-8.963 8.919 0 1.572.412 3.106 1.196 4.459L3 21l4.751-1.24a8.99 8.99 0 0 0 4.283 1.085h.004c4.94 0 8.96-4.001 8.962-8.919a8.844 8.844 0 0 0-2.622-6.31Zm-6.34 13.723h-.003a7.465 7.465 0 0 1-3.792-1.033l-.272-.161-2.82.736.753-2.736-.177-.28a7.37 7.37 0 0 1-1.139-3.946c.002-4.087 3.344-7.413 7.453-7.413 1.99.001 3.86.773 5.266 2.175a7.35 7.35 0 0 1 2.18 5.244c-.002 4.088-3.344 7.414-7.45 7.414Zm4.086-5.552c-.224-.112-1.325-.651-1.53-.726-.206-.074-.355-.111-.504.112-.15.223-.579.725-.71.874-.13.149-.26.167-.485.056-.224-.112-.945-.347-1.8-1.106-.666-.591-1.116-1.321-1.246-1.544-.13-.223-.014-.344.098-.455.1-.1.224-.26.336-.39.112-.13.15-.224.224-.373.074-.148.037-.278-.019-.39-.056-.111-.504-1.208-.69-1.655-.182-.434-.367-.375-.504-.382a9.02 9.02 0 0 0-.43-.008.825.825 0 0 0-.597.279c-.205.223-.783.762-.783 1.859s.802 2.157.914 2.305c.112.15 1.58 2.4 3.826 3.366.534.23.951.366 1.276.47.537.169 1.025.145 1.41.087.431-.064 1.326-.539 1.512-1.06.187-.52.187-.966.13-1.06-.055-.092-.204-.148-.428-.26Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgWhatsapp)

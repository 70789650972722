/**
 * @param context Query context.
 * @returns The sanitized query, if it's a string, or undefined.
 */
export function getSanitizedQuery<T extends { query: string | string[] | undefined }>(
  context: T
): string | undefined {
  const { query } = context

  let result: string | undefined = undefined

  if (typeof query === 'string') {
    result = query.replace(/[^\x20-\x7F\xA1-\xFF]/g, '')
  }

  return result
}

import { BodyText, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useEffect, useState } from 'react'

import { useReplaceCPF } from '../../../hooks'
import validatingImgSrc from '../../../public/identity-validation-modal/validation.svg'
import { useModalContext } from '../context'
import { Steps } from '../enums'
import styles from './steps.module.scss'

interface ReplacingCPFProps {
  validateCallback?: (isValidatedSuccess: boolean) => void
}

export function ReplacingCPF(props: ReplacingCPFProps) {
  const { validateCallback } = props
  const [loading, setLoading] = useState(false)

  const { setCurrentStep, sharedData, viewer, eventData, replacementToken } = useModalContext()
  const replaceCPF = useReplaceCPF(replacementToken)
  const pid = viewer?.pid.toString() || null

  function wait(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
    sendMetrics('UserAccount.OverwriteCPFViewed', pid, {
      ...eventData,
      metadata: {
        cpf: sharedData?.cpf,
        birthDate: formatDate(sharedData?.birthDate?.getTime() || ''),
        ...eventData?.metadata,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedData?.cpf, sharedData?.birthDate, pid])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      replaceCPF()
        .then(async ({ data }) => {
          await wait(3000)
          if (data?.changeCpfAccount?.profile?.isCpfValid) {
            if (validateCallback) validateCallback(true)
            return setCurrentStep(Steps.REPLACEMENT_SUCCESS)
          }
          setCurrentStep(Steps.REPLACEMENT_ERROR)
        })
        .catch((error) => {
          console.error('Error on replace CPF', error)
          setCurrentStep(Steps.REPLACEMENT_ERROR)
        })
    }
  }, [replaceCPF, setCurrentStep, validateCallback, loading])

  return (
    <>
      <Modal.Body className={styles.body} data-testid="replacing-cpf-step">
        <Image
          alt="Verificando identidade..."
          src={validatingImgSrc}
          height={200}
          width={200}
          className={styles.image}
        />
        <Heading size="lg" className={styles.title}>
          Vinculando CPF à conta atual...
        </Heading>
        <BodyText size="lg" className={styles.text}>
          Aguarde enquanto validamos as informações.
        </BodyText>
      </Modal.Body>
    </>
  )
}

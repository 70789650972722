import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import { useSelectionCardContext } from './context'
import styles from './selection-card-body.module.scss'

export type SelectionCardBodyElement = HTMLDivElement

export type SelectionCardBodyProps = HTMLAttributes<SelectionCardBodyElement>

export default function SelectionCardBody(props: SelectionCardBodyProps) {
  const { className, ...rest } = props

  const { orientation, disabled } = useSelectionCardContext()

  const classes = classNames(
    styles.root,
    styles[orientation],
    {
      [styles.disabled]: disabled,
    },
    className
  )

  return <div {...rest} className={classes} />
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStatistic(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a1 1 0 0 1 1 1h6a1 1 0 1 1 2 0h1a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H8a3.001 3.001 0 0 1-2.832-2.007A2.035 2.035 0 0 1 5 20H4a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h5a1 1 0 0 1 1-1Zm7 3v1a1 1 0 1 0 2 0V5h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V6c0-.35-.06-.687-.17-1H9v1a1 1 0 1 0 2 0V5h6ZM3 6a1 1 0 1 1 2 0v12H4a1 1 0 0 1-1-1V6Zm15.707 6.707a1 1 0 0 0-1.414-1.414L15 13.586l-1.793-1.793a1 1 0 0 0-1.414 0l-2.5 2.5a1 1 0 1 0 1.414 1.414l1.793-1.793 1.793 1.793a1 1 0 0 0 1.414 0l3-3Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStatistic)

import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { Chip, ChipProps } from '../chip/chip'
import { ChipClickable, ChipClickableProps } from '../chip-clickable/chip-clickable'
import { Slot } from '../utilities'

export type SnippetTagsItemElement = HTMLSpanElement

export type SnippetTagsItemProps = HTMLAttributes<SnippetTagsItemElement> & {
  asChild?: boolean
}

const SnippetTagsItem = forwardRef<SnippetTagsItemElement, SnippetTagsItemProps>(
  function SnippetTagsItem(props, forwardedRef) {
    const { children, asChild, ...rest } = props

    const Component = asChild ? Slot : 'span'

    return (
      <Component {...rest} ref={forwardedRef}>
        {renderChildren(props)}
      </Component>
    )
  }
)

function renderChildren(props: SnippetTagsItemProps): ReactNode {
  const { children } = props

  if (isValidElement<ChipProps>(children) && children.type === Chip) {
    return cloneElement(children, { size: 'sm', kind: 'tertiary' })
  }

  if (isValidElement<ChipClickableProps>(children) && children.type === ChipClickable) {
    return cloneElement(children, { size: 'md' })
  }

  return children
}

export default SnippetTagsItem

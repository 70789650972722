import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { CheckIcon } from '../icon/icon'
import { Slot, Slottable } from '../utilities'
import styles from './menu-item-content.module.scss'
import { useMenuItem } from './use-menu-item'

export type MenuItemContentElement = HTMLSpanElement

export interface MenuItemContentProps extends HTMLAttributes<MenuItemContentElement> {
  children: ReactNode
  asChild?: boolean
}

const MenuItemContent = forwardRef<MenuItemContentElement, MenuItemContentProps>(
  function MenuItemContent(props, forwardedRef) {
    const { children, className, asChild, ...rest } = props

    const { current, block } = useMenuItem()
    const classes = classNames(styles.root, className)

    const Component = asChild ? Slot : 'span'

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        <Slottable>{children}</Slottable>
        {current && block ? (
          <span className={styles.check} data-fds-menu-item-content-check>
            <CheckIcon size="sm" className={styles.checkIcon} />
          </span>
        ) : null}
      </Component>
    )
  }
)

export default MenuItemContent

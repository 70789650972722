import * as RPopover from '@radix-ui/react-popover'
import { forwardRef } from 'react'

import { assignSubComponents } from '../utilities/internal'
import { PopoverContext } from './context'
import PopoverClose from './popover-close'
import PopoverContent from './popover-content'
import PopoverContentClose from './popover-content-close'
import PopoverContentDescription from './popover-content-description'
import PopoverContentFooter from './popover-content-footer'
import PopoverContentTitle from './popover-content-title'
import PopoverTrigger from './popover-trigger'
import { PopoverProps } from './types'
import { usePopoverProvider } from './use-popover'

export type PopoverElement = HTMLDivElement

const PopoverRoot = forwardRef<PopoverElement, PopoverProps>(function Popover(props, forwardedRef) {
  const { open = undefined, children, ...rest } = props

  const context = usePopoverProvider(props)
  const rootProps = open !== undefined ? { open } : {}

  return (
    <RPopover.Root {...rest} {...rootProps}>
      <PopoverContext.Provider value={context}>{children}</PopoverContext.Provider>
    </RPopover.Root>
  )
})

export const Popover = assignSubComponents('Popover', PopoverRoot, {
  Anchor: RPopover.Anchor,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  ContentClose: PopoverContentClose,
  ContentTitle: PopoverContentTitle,
  ContentDescription: PopoverContentDescription,
  ContentFooter: PopoverContentFooter,
  Close: PopoverClose,
})

export * from './popover-close'
export * from './popover-content'
export * from './popover-content-close'
export * from './popover-content-description'
export * from './popover-content-footer'
export * from './popover-content-title'
export * from './popover-trigger'
export * from './types'

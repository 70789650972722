import { capitalizePhrase } from '@jusbrasil-web/shared-utils-format'

import { Nullable } from '../../types'
import { isNormalizedTitle } from '../is-normalized-title'
import styles from './format-title.module.scss'

export function formatLawsuitTitle(
  title: Nullable<string>,
  number: Nullable<string>,
  showParts = true,
  restrictLawsuit?: boolean | null,
  canViewRestrictLawsuit?: boolean | null
) {
  if (!showParts) return `Processo nº ${number}`

  if (restrictLawsuit && !canViewRestrictLawsuit) return title

  if (!title || !isNormalizedTitle(title)) return `Processo nº ${number}`

  const normalizedTitle = title.toLowerCase()
  const parts: string[] = normalizedTitle.split(' x ').map((t) => capitalizePhrase({ phrase: t }))

  return (
    <>
      {parts[0]}
      <span className={styles.versus}> x </span>
      {parts[1]}
    </>
  )
}

import * as RAccordion from '@radix-ui/react-accordion'

export type AccordionElement = HTMLDivElement

export const AccordionTypes = ['single', 'multiple'] as const
export type AccordionType = (typeof AccordionTypes)[number]

export const AccordionSizes = ['md', 'lg', 'xl'] as const
export type AccordionSize = (typeof AccordionSizes)[number]
export type AccordionProps = React.ComponentProps<typeof RAccordion.Root> & {
  size?: AccordionSize
}

import { composeRefs } from '@radix-ui/react-compose-refs'
import { Ref, useCallback, useContext, useState } from 'react'

import { useMountTransition } from '../utilities'
import type { BannerIconCloseElement, BannerIconCloseProps, BannerProps } from './banner'
import { BannerContext } from './context'

export type UseBannerProviderProps = BannerProps & Required<Pick<BannerProps, 'type' | 'level'>>

export interface UseBannerProviderReturn extends Required<Pick<BannerProps, 'level'>> {
  visible: boolean
  isMount: boolean
  onClose: () => void
  hasIconClose: boolean
  getIconCloseProps(ref: Ref<BannerIconCloseElement>): BannerIconCloseProps
}

const transitionTime = 320

export function useBannerProvider(props: UseBannerProviderProps): UseBannerProviderReturn {
  const { visible: visibleProp, defaultVisible = true, level, onVisibleChange } = props
  const [hasIconClose, setHasIconClose] = useState(false)
  const [visible, setVisible, isMount] = useMountTransition(
    transitionTime,
    defaultVisible,
    'both',
    {
      controlledState: visibleProp,
      // skip initial transition in the first render (avoiding CLS in SSR)
      skipInitialTransition: visibleProp === undefined || visibleProp === defaultVisible,
    }
  )

  const onClose = useCallback<UseBannerProviderReturn['onClose']>(() => {
    setVisible(!visible)
    onVisibleChange?.(!visible, {
      waitAnimation(callback) {
        setTimeout(() => callback(), transitionTime)
      },
    })
  }, [onVisibleChange, setVisible, visible])

  const getIconCloseProps = useCallback<UseBannerProviderReturn['getIconCloseProps']>(
    (ref) => {
      return {
        ref: composeRefs(ref, (node) => {
          setHasIconClose(!!node)
        }),
        id: undefined,
      }
    },
    [setHasIconClose]
  )

  return {
    level,
    visible,
    isMount,
    onClose,
    hasIconClose,
    getIconCloseProps,
  }
}

export function useBanner() {
  const context = useContext(BannerContext)
  return context
}

import classNames from 'classnames'
import {
  ButtonHTMLAttributes,
  cloneElement,
  forwardRef,
  isValidElement,
  ReactNode,
  useDeferredValue,
} from 'react'

import { IconProps } from '../icon/icon'
import { Spinner } from '../spinner/spinner'
import { ensurePropOf } from '../utilities'
import { isValidLinkElement } from '../utilities/internal'
import { Slot, Slottable } from '../utilities/slot/slot'
import styles from './button.module.scss'

export const ButtonKinds = [
  'primary',
  'secondary',
  'tertiary',
  'plain',
  'danger',
  'dangerSecondary',
] as const
export const ButtonSizes = ['sm', 'md', 'lg'] as const
export type ButtonElement = HTMLButtonElement
export type ButtonKind = (typeof ButtonKinds)[number]
export type ButtonSize = (typeof ButtonSizes)[number]

/*
 * Known issues. @andersonba
 * - For custom elements (eg.: div/span)
 *   - Pressing enter/space, onClick is not triggered
 *   - onClick is triggering even with disabled prop
 */

export interface ButtonProps extends ButtonHTMLAttributes<ButtonElement> {
  children: ReactNode
  kind?: ButtonKind
  size?: ButtonSize
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  block?: boolean
  loading?: boolean
  disabled?: boolean
  spinnerClassName?: string
  className?: string
  asChild?: boolean
}

export const Button = forwardRef<ButtonElement, ButtonProps>(function Button(props, forwardedRef) {
  const {
    className,
    children,
    spinnerClassName,
    leftIcon = null,
    rightIcon = null,
    block = false,
    loading = false,
    kind: kindProp,
    size: sizeProp,
    disabled = false,
    type = 'button',
    asChild,
    ...rest
  } = props

  const Component = asChild ? Slot : 'button'

  const kind = ensurePropOf<ButtonKind>(ButtonKinds, kindProp, 'tertiary')
  const size = ensurePropOf<ButtonSize>(ButtonSizes, sizeProp, 'md')

  // to postpone value and to not block form submit needs the useDeferredValue
  // ref: https://jusbrasil.slack.com/archives/C032K2EBBUM/p1733834766814749
  const noInteractive = useDeferredValue(disabled || loading)

  const classes = classNames(
    styles.root,
    {
      [styles[`kind${kind}`]]: !!kind,
      [styles[`size${size}`]]: !!size,
      [styles.disabled]: !!disabled && !loading,
      [styles.block]: !!block,
      [styles.loading]: !!loading,
      [styles.nointeractive]: noInteractive,
    },
    className
  )

  const buttonProps: ButtonHTMLAttributes<HTMLButtonElement> = asChild
    ? {}
    : {
        disabled: noInteractive,
        type,
      }

  const iconProps: Partial<IconProps> = {
    size: size === 'sm' ? 'xs' : 'sm',
  }

  return (
    <Component {...buttonProps} {...rest} className={classes} ref={forwardedRef}>
      {isValidElement<IconProps>(leftIcon) && (
        <span className={styles.leftIcon}>{cloneElement(leftIcon, iconProps)}</span>
      )}

      <Slottable>
        {asChild && isValidElement<HTMLElement>(children)
          ? cloneElement(
              children,
              isValidLinkElement(children)
                ? {}
                : {
                    role: 'button',
                    tabIndex: disabled ? undefined : 0,
                  }
            )
          : children}
      </Slottable>

      {loading ? (
        <span className={classNames(styles.spinner, spinnerClassName)}>
          <Spinner {...iconProps} />
        </span>
      ) : null}

      {isValidElement<IconProps>(rightIcon) && (
        <span className={styles.rightIcon}>{cloneElement(rightIcon, iconProps)}</span>
      )}
    </Component>
  )
})

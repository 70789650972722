import { BodyText, Button, Heading, Link, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useCallback, useEffect, useState } from 'react'

import { useRequestCPFRemoval } from '../../../hooks'
import imageSrc from '../../../public/identity-validation-modal/document-in-use.svg'
import { SUPPORT_CONTACT_URL } from '../constants'
import { useModalContext } from '../context'
import { Steps } from '../enums'
import styles from './steps.module.scss'

export interface CPFInUseProps {
  title?: string
  description?: string
  template?: string
}

export function CPFInUse(props: CPFInUseProps) {
  const { title, description, template = 'generic' } = props
  const [loading, setLoading] = useState(false)
  const { sharedData, setCurrentStep, eventData, viewer } = useModalContext()
  const requestCPFRemoval = useRequestCPFRemoval({
    pid: sharedData.profileInUse?.pid,
    cpf: sharedData.cpf,
  })
  const pid = viewer?.pid.toString() || null

  const getEventData = useCallback(
    (metadata: Record<string, unknown>, data: Record<string, unknown> = {}) => ({
      ...data,
      ...eventData,
      metadata: {
        cpf: sharedData.cpf,
        birthDate: formatDate(sharedData.birthDate?.getTime() || ''),
        ...metadata,
        ...eventData?.metadata,
      },
    }),
    [eventData, sharedData.cpf, sharedData.birthDate]
  )

  useEffect(() => {
    sendMetrics('UserAccount.IdValidationFailedViewed', pid, getEventData({}, { type: 'CPFInUse' }))
  }, [getEventData, pid])

  const handleBack = () => {
    sendMetrics(
      'UserAccount.IdValidationFailedSubmitted',
      pid,
      getEventData({ button: 'ReviewInformation' })
    )
    setCurrentStep(Steps.VALIDATE_IDENTITY)
  }

  const handleContinue = () => {
    setLoading(true)
    sendMetrics(
      'UserAccount.IdValidationFailedSubmitted',
      pid,
      getEventData({ button: 'ChangeAccount' })
    )
    requestCPFRemoval(template)
      .then(() => {
        setCurrentStep(Steps.REMOVAL_CPF_EMAIL)
      })
      .catch((error) => {
        console.error('Error sending removal email', error)
        setCurrentStep(Steps.IDENTITY_ERROR)
      })
  }

  const modalTitle = title ?? 'Já existe um perfil verificado com este CPF'
  const modalDescription =
    description ?? 'Para acessar mais páginas, acesse o site do Jusbrasil com a conta'

  return (
    <>
      <Modal.Body className={styles.body} data-testid="cpf-in-use-step">
        <Image alt={modalTitle} src={imageSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {modalTitle}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          {modalDescription} <strong>{sharedData.profileInUse?.email}</strong> ou cadastre este CPF
          à conta atual.
        </BodyText>
        <BodyText size="lg" className={styles.text}>
          Caso não reconheça essa conta, entre em{' '}
          <Link type="tertiary" href={SUPPORT_CONTACT_URL} target="_blank">
            contato com o suporte
          </Link>
          .
        </BodyText>
      </Modal.Body>
      <Modal.Footer>
        <Button kind="primary" onClick={handleContinue} disabled={loading}>
          Cadastrar CPF à conta atual
        </Button>
        <Button kind="tertiary" onClick={handleBack}>
          Revisar informações
        </Button>
      </Modal.Footer>
    </>
  )
}

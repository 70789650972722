import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgMoney(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm6.628-3.196C9.077 8.3 9.728 8 10.5 8h.5V7a1 1 0 1 1 2 0v1h1.5a1 1 0 1 1 0 2h-4c-.229 0-.327.076-.378.133A.557.557 0 0 0 10 10.5c0 .162.055.291.122.367.051.057.15.133.378.133h3c.771 0 1.423.3 1.872.805.433.486.628 1.107.628 1.695 0 .588-.195 1.209-.627 1.696-.45.505-1.102.804-1.873.804H13v1a1 1 0 1 1-2 0v-1H9.5a1 1 0 0 1 0-2h4c.229 0 .327-.076.378-.133A.557.557 0 0 0 14 13.5a.558.558 0 0 0-.122-.367c-.051-.057-.15-.133-.378-.133h-3c-.771 0-1.423-.3-1.872-.804A2.553 2.553 0 0 1 8 10.5c0-.588.195-1.209.628-1.696Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgMoney)

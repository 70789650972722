import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUserInstitutional(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C13.416 8.186 14 7.161 14 6a2 2 0 0 0-2-2Zm0 7-.555.832-.002-.002-.004-.002-.011-.008-.035-.024a8.44 8.44 0 0 1-.502-.387 9.755 9.755 0 0 1-1.15-1.133C8.915 9.313 8 7.838 8 6a4 4 0 1 1 8 0c0 1.838-.915 3.313-1.74 4.276a9.758 9.758 0 0 1-1.537 1.437 5.759 5.759 0 0 1-.116.083l-.034.024-.012.008-.004.002-.001.001s-.001.001-.556-.831Zm0 0-.555.832c.336.224.774.224 1.11 0L12 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.971 10.451a2 2 0 0 1 2.058 0l3.573 2.144A7 7 0 0 1 20 18.597V19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-.403a7 7 0 0 1 3.399-6.002l3.572-2.144ZM12 12.166 8.428 14.31A5 5 0 0 0 6 18.597V19a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-.403a5 5 0 0 0-2.428-4.287L12 12.166Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.482 11.853a1 1 0 0 1 .568.741c.173 1.019.768 1.907 1.381 2.574.202.219.398.405.569.557.171-.152.367-.338.569-.557.613-.667 1.208-1.555 1.38-2.574a1 1 0 0 1 1.501-.69l1.152.69A7 7 0 0 1 20 18.598V19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-.403a7 7 0 0 1 3.399-6.002l1.151-.691a1 1 0 0 1 .932-.051ZM12 17l-.555.832-.002-.002-.004-.002-.01-.006-.029-.02a8.157 8.157 0 0 1-.429-.326c-.27-.221-.632-.542-1.011-.954-.514-.558-1.088-1.316-1.482-2.243l-.05.03A5 5 0 0 0 6 18.598V19a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-.403a5 5 0 0 0-2.428-4.287l-.05-.03c-.394.926-.968 1.684-1.482 2.243a9.806 9.806 0 0 1-1.342 1.208c-.04.03-.074.053-.098.07l-.03.02-.01.007-.003.002-.001.001s-.001.001-.556-.831Zm0 0 .555.832a1 1 0 0 1-1.11 0L12 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUserInstitutional)

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'
import {
  CheckoutMethod,
  NextPlanId,
  PaymentMethod,
  PlanId,
  SuccessfulAttempt,
  TriggerFeature,
} from '../types'

type Properties = {
  checkoutMethod: CheckoutMethod
  nextPlanId: NextPlanId
  paymentMethod: PaymentMethod
  planId: PlanId
  successfulAttempt: SuccessfulAttempt
  triggerFeature: TriggerFeature
}

export class GA4CheckoutCompleteEvent extends GA4Event {
  ga4_checkout_method: Properties['checkoutMethod']
  ga4_next_plan_id: Properties['nextPlanId']
  ga4_plan_id: Properties['planId']
  ga4_successful_attempt: Properties['successfulAttempt']
  ga4_trigger_feature: Properties['triggerFeature']
  ga4_payment_method: Properties['paymentMethod']

  constructor(properties: Properties) {
    super({ event: EVENT.CHECKOUT_COMPLETE })

    const { checkoutMethod, nextPlanId, planId, successfulAttempt, triggerFeature, paymentMethod } =
      properties

    this.ga4_trigger_feature = triggerFeature
    this.ga4_checkout_method = checkoutMethod
    this.ga4_plan_id = planId
    this.ga4_next_plan_id = nextPlanId
    this.ga4_successful_attempt = successfulAttempt
    this.ga4_payment_method = paymentMethod
  }
}

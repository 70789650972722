import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLightbulb(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a6 6 0 0 0-3.88 10.576c.705.6 1.389 1.421 1.702 2.424H11v-4a1 1 0 1 1 2 0v4h1.178c.313-1.003.997-1.825 1.703-2.424A6 6 0 0 0 12 4Zm2 15h-4a2 2 0 1 0 4 0ZM4 10a8 8 0 1 1 13.175 6.1c-.705.6-1.136 1.289-1.172 1.955a1.805 1.805 0 0 0-.003.096V19a4 4 0 0 1-8 0v-.849c0-.032 0-.064-.003-.096-.036-.666-.467-1.355-1.172-1.954A7.985 7.985 0 0 1 4 10Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLightbulb)

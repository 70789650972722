// ported from original code in ui-topbar project:
// https://github.com/jusbrasil/ui-topbar/blob/0372d13ac4010e97d7048825aed81a5b0f891637/src/tags/lgpd-cookie-terms.js

import { BodyText, Button, Heading, Link, LockIcon, Portal } from '@farol-ds/react'
import {
  destroyJusbrasilCookie,
  getCookie,
  setJusbrasilCookie,
} from '@jusbrasil-web/shared-utils-cookies'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import classNames from 'classnames'
import { HTMLAttributes, useCallback, useContext, useEffect, useState } from 'react'

import { GOOGLE_ONE_TAP_ENABLE_EVENT } from '../../constants'
import { TopbarContext } from '../../context'
import styles from './topbar-lgpd.module.scss'

export type TopbarLGPDProps = HTMLAttributes<HTMLDivElement>

const AUTO_ACCEPT_TIME = 10000 // 10s
const LEGACY_COOKIE_NAME = 'agreed-gdpr'
const COOKIE_NAME = 'br-lgpd-cookie-notice-agreement-v1'

export function TopbarLGPD(props: TopbarLGPDProps) {
  const { className, ...rest } = props
  const { mount, logged, rootRef } = useContext(TopbarContext)
  const [show, setShow] = useState(false)
  const classes = classNames(styles.root, className)

  const enableGoogleOneTap = useCallback(() => {
    const el = rootRef?.current
    if (!el) return
    const event = new CustomEvent(GOOGLE_ONE_TAP_ENABLE_EVENT, { bubbles: true })
    el.dispatchEvent(event)
  }, [rootRef])

  const onAccept = useCallback(() => {
    setShow(false)
    setJusbrasilCookie(null, COOKIE_NAME, new Date().toISOString(), {
      expires: new Date('9999-12-31T23:59:59.999Z'), // infinity
    })
    sendMetrics('Topbar.LGPDCookieNoticeAgreed', null)
    enableGoogleOneTap()
  }, [enableGoogleOneTap])

  useEffect(() => {
    if (!mount) return

    // check original code for more details
    destroyJusbrasilCookie(null, LEGACY_COOKIE_NAME)
    if (window.location.search.includes('force-clear-lgpd')) {
      destroyJusbrasilCookie(null, COOKIE_NAME)
    }

    let time: NodeJS.Timeout
    const agreed = getCookie(null, COOKIE_NAME)
    if (!logged && !agreed) {
      setShow(true)
      sendMetrics('Topbar.LGPDCookieNoticeShown', null)
    } else {
      // to avoid concurrency on rendering order, postpone event
      time = setTimeout(enableGoogleOneTap, 500)
    }
    return () => clearTimeout(time)
  }, [mount, logged, enableGoogleOneTap])

  useEffect(() => {
    if (!show) return
    function autoAccept() {
      const agreed = getCookie(null, COOKIE_NAME)
      if (!agreed) {
        onAccept()
      }
    }
    const time = setTimeout(autoAccept, AUTO_ACCEPT_TIME)
    return () => clearTimeout(time)
  }, [onAccept, show])

  if (!show || !mount) return null

  return (
    <Portal>
      <div data-testid="topbar-lgpd-banner" {...rest} className={classes}>
        <div className={styles.content}>
          <Heading size="sm" className={styles.title} asChild>
            <p>
              <LockIcon size="sm" />
              Sua privacidade
            </p>
          </Heading>

          <BodyText size="md">
            Ao acessar nosso site, você concorda com nossa{' '}
            <Link
              href="https://www.jusbrasil.com.br/politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidade e cookies
            </Link>
            .
          </BodyText>
        </div>

        <Button kind="primary" onClick={onAccept}>
          Continuar e fechar
        </Button>
      </div>
    </Portal>
  )
}

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import {
  ChangeEvent,
  cloneElement,
  forwardRef,
  InputHTMLAttributes,
  isValidElement,
  ReactNode,
  useRef,
  useState,
} from 'react'

import { useFormControl } from '../form-control/use-form-control'
import { EyeIcon, EyeOffIcon } from '../icon/icons'
import { IconProps, IconSize } from '../icon/types'
import { Spinner } from '../spinner/spinner'
import styles from './input.module.scss'

export type InputElement = HTMLInputElement

export interface InputProps extends InputHTMLAttributes<InputElement> {
  onValueChange?: (evt: ChangeEvent<InputElement>, value: string) => void
  error?: boolean
  loading?: boolean
  rightIcon?: ReactNode
  className?: string
}

const iconSize: IconSize = 'sm'

export const Input = forwardRef<InputElement, InputProps>(function Input(props, forwardedRef) {
  const { className, type, rightIcon, error: errorProp, loading, onValueChange, ...rest } = props

  const ref = useRef<InputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const formControl = useFormControl()

  const isPassword = type === 'password'
  const hasCustomRightIcon = !loading && !isPassword && isValidElement<IconProps>(rightIcon)
  const hasIcon = hasCustomRightIcon || isPassword || (loading && rightIcon)
  const hasError = errorProp ?? formControl.error

  const classes = classNames(
    styles.input,
    {
      [styles.error]: hasError,
      [styles.hasIcon]: hasIcon,
    },
    className
  )

  function handlePasswordIconClick() {
    if (ref.current) {
      ref.current.type = ref.current.type === 'text' ? 'password' : 'text'
      setShowPassword(!showPassword)
    }
  }

  return (
    <div className={styles.root}>
      <input
        data-testid="input"
        type={type}
        aria-invalid={hasError || undefined}
        onChange={(evt) => {
          onValueChange?.(evt, evt.target.value)
        }}
        {...formControl.getFieldProps?.()}
        {...rest}
        className={classes}
        ref={useComposedRefs(ref, forwardedRef)}
      />

      {!loading && isPassword ? (
        <span
          data-testid="password-icon-btn"
          role="button"
          tabIndex={0}
          onClick={handlePasswordIconClick}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              handlePasswordIconClick()
            }
          }}
          className={classNames(styles.rightIcon, styles.passwordIcon)}
        >
          {showPassword ? <EyeOffIcon size={iconSize} /> : <EyeIcon size={iconSize} />}
        </span>
      ) : null}

      {hasCustomRightIcon ? (
        <span className={classNames(styles.rightIcon)}>
          {cloneElement(rightIcon, { size: iconSize, focusable: false })}
        </span>
      ) : null}

      {loading ? (
        <span className={classNames(styles.rightIcon, styles.loading)}>
          <Spinner size={iconSize} />
        </span>
      ) : null}
    </div>
  )
})

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './modal-footer.module.scss'

export type ModalFooterElement = HTMLDivElement

export interface ModalFooterProps extends HTMLAttributes<ModalFooterElement> {
  children: ReactNode
}

const ModalFooter = forwardRef<ModalFooterElement, ModalFooterProps>(function ModalFooter(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props
  const classes = classNames(styles.root, className)
  const containerClasses = classNames(styles.container)
  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      <div className={containerClasses}>{children}</div>
    </div>
  )
})

export default ModalFooter

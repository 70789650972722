import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.38 19.984h-.058.077-.018Zm-.184-.003-3.528-.062c-.186-.004-.387-.005-.598-.006-.968-.007-2.17-.014-3.32-.245-1.65-.326-2.615-1.377-3.099-2.545-.383-.926-.476-1.97-.533-2.616l-.012-.134v-.004a25.785 25.785 0 0 1 .252-6.626v-.002c.162-.94.591-1.83 1.35-2.498.755-.663 1.687-.976 2.631-1.036 4.079-.272 8.164-.238 12.166-.113.566.015 1.102.098 1.534.171.828.14 1.567.467 2.172.996.597.521.96 1.148 1.189 1.738.372.962.454 2.074.507 2.784l.017.232v.002a23.53 23.53 0 0 1-.192 5.432c-.106.713-.3 1.744-.911 2.622-.714 1.027-1.808 1.602-3.156 1.694-2.06.145-4.136.266-6.285.228a1.999 1.999 0 0 1-.184-.012Zm8.558-4.83c.253-1.649.309-3.316.177-4.975l-.019-.238c-.123-1.588-.262-3.372-2.209-3.7a8.95 8.95 0 0 0-1.257-.145c-3.978-.125-7.991-.156-11.978.11-1.18.073-1.944.748-2.138 1.877a23.785 23.785 0 0 0-.232 6.11l.006.075c.13 1.428.283 3.097 2.035 3.442.944.189 1.904.197 2.867.204.233.002.467.004.7.008l3.693.065c.006 0 .017 0 .017.009a42.765 42.765 0 0 0 2-.008c1.37-.034 2.73-.118 4.111-.216 1.637-.11 2.025-1.254 2.227-2.619Zm-5.929-3.166-2.065-1.143-.415-.23-.117-.066-2-1.107v5.091l1.916-1.061.084-.046.532-.295 2.066-1.143Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgYoutube)

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { IconProps, TagMultiIcon } from '../icon/icon'
import { Slot, Slottable } from '../utilities'
import styles from './snippet-tags.module.scss'

export type SnippetTagsElement = HTMLDivElement

export type SnippetTagsProps = HTMLAttributes<SnippetTagsElement> & {
  asChild?: boolean
  leftIcon?: ReactNode
}

const SnippetTags = forwardRef<SnippetTagsElement, SnippetTagsProps>(function SnippetTags(
  props,
  forwardedRef
) {
  const { children, leftIcon = <TagMultiIcon />, className, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {isValidElement<IconProps>(leftIcon) &&
        cloneElement(leftIcon, { size: 'sm', className: styles.leftIcon })}

      <Slottable>{children}</Slottable>
    </Component>
  )
})

export default SnippetTags

import { TopbarContextType } from '../../../types'
import { TopbarUserMenuItemKey } from './items'

export function mountItems(ctx: TopbarContextType): TopbarUserMenuItemKey[] {
  const { user, flags, takeover } = ctx
  const items: TopbarUserMenuItemKey[] = []

  if (user) {
    if (takeover) {
      items.push('TAKEOVER', 'SEPARATOR')
    }

    if (user.profiles.length) {
      items.push('PROFILE_SWITCH', 'SEPARATOR')
    }

    if (!user.hasAccessToSomeBundle) {
      items.push('pro', 'SEPARATOR')
    } else if (
      user.hasAccessToSomeBundle &&
      !user.hasDoctrine &&
      !user.boxProfileInfo?.linkedAccount
    ) {
      items.push('pro-doutrina', 'SEPARATOR')
    }

    items.push('jusbrasilia', 'search', 'lawsuits', 'my-feed', 'my-documents')

    if (!flags?.isInvolvedPartyLawsuit) {
      items.push('my-stats')
    }

    items.push('settings', 'SEPARATOR')
  }

  if (user && user.hasAccessToSomeBundle && user.isLawyer) {
    items.push('online-office', 'SEPARATOR')
  }

  if (user) {
    items.push('messages', 'notifications')

    if (user.admin || (user.pid && user.pid % 10 === 0)) {
      items.push('chrome-ext')
    }

    items.push('SEPARATOR')
  }

  if (!user) {
    items.push('lawyers', 'SEPARATOR')
  }

  items.push('help', 'about')

  if (user) {
    items.push('logout')
  }

  return items
}

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4FeedbackGivenEventProps {
  componentName?: string | null
  componentClass: string | null
  resourceId?: string | null
  customInput?: string | null
  feedbackClass?: string | null
  feedbackName?: string | null
  feedbackMethod?: string | null
}

export class GA4FeedbackGivenEvent extends GA4Event {
  ga4_component_name: string | null
  ga4_component_class: string | null
  ga4_resource_id: string | null
  ga4_custom_input: string | null
  ga4_feedback_class: string | null
  ga4_feedback_name: string | null
  ga4_feedback_method: string | null

  constructor({
    componentClass,
    componentName = null,
    resourceId = null,
    customInput = null,
    feedbackClass = null,
    feedbackName = null,
    feedbackMethod = null,
  }: GA4FeedbackGivenEventProps) {
    super({ event: EVENT.FEEDBACK_GIVEN })

    this.ga4_component_class = componentClass
    this.ga4_component_name = componentName
    this.ga4_resource_id = resourceId
    this.ga4_custom_input = customInput
    this.ga4_feedback_name = feedbackName
    this.ga4_feedback_class = feedbackClass
    this.ga4_feedback_method = feedbackMethod
  }
}

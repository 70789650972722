import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4SearchEventProps {
  searchTerm: string
  resultPage: number | null
  totalReturnedResults: number
  answeredByStatic?: boolean | null
  componentName?: string | null
  componentClass?: string | null
  searchMethod?: string | null
}

export class GA4SearchEvent extends GA4Event {
  ga4_search_term: string
  ga4_result_page: number | null
  ga4_answered_by_static: boolean | null
  ga4_total_returned_results: number
  ga4_component_name: string | null
  ga4_component_class: string | null
  ga4_search_method: string | null

  constructor({
    searchTerm,
    resultPage = null,
    totalReturnedResults,
    answeredByStatic = null,
    componentClass = null, // TODO: update componentClass to be a required parameter @rocha
    componentName = null,
    searchMethod = null,
  }: GA4SearchEventProps) {
    super({ event: EVENT.SEARCH })

    this.ga4_search_term = searchTerm
    this.ga4_result_page = resultPage
    this.ga4_total_returned_results = totalReturnedResults
    this.ga4_component_name = componentName
    this.ga4_component_class = componentClass
    this.ga4_answered_by_static = answeredByStatic
    this.ga4_search_method = searchMethod
  }
}

import {
  COOKIE_EXPIRATION_TIME,
  EVENT_PROPERTIES_STORAGE_KEY,
  snowpiercerClient,
} from '../constants'
import { NamespacesStorageObject } from '../types/namespaces-storage-object'
import { newNamespacesStorageObject } from './new-namespaces-storage-object'

export const getNamespacesObjectFromStorage = async () => {
  try {
    const storedValue = await snowpiercerClient.getItem(EVENT_PROPERTIES_STORAGE_KEY)
    const parsedValue: NamespacesStorageObject = JSON.parse(storedValue)

    const obj =
      parsedValue instanceof Object && !Array.isArray(parsedValue)
        ? parsedValue
        : newNamespacesStorageObject()

    return obj.timestamp && obj.timestamp + COOKIE_EXPIRATION_TIME >= Date.now()
      ? obj
      : newNamespacesStorageObject()
  } catch (ignored) {
    return newNamespacesStorageObject()
  }
}

import { gql, MutationFunction, useMutation } from '@apollo/client'
import { Banner, Caption, Card, Heading, List, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect, useMemo } from 'react'

import { useUserOnboarding } from '../../hooks'
import {
  caseManagementSVG,
  legalResearchSVG,
  otherSVG,
  partySVG,
  relativeSVG,
  reputationSVG,
} from '../../public/lawsuit-intentions-modal'
import { LawsuitIntentionsModal_ViewerFragment } from './__generated__/lawsuit-intentions-modal.graphql'
import styles from './lawsuit-intentions-modal.module.scss'

export const USER_INTENTIONS_COOKIE_NAME = 'user_intentions'

export interface LawsuitIntentionsModalProps {
  viewer: LawsuitIntentionsModal_ViewerFragment | null
  fromComponent?: string
}

interface Option {
  title: string
  intention: string
  description: string
  svg: string
}

const OTHER_OPTION: Option = {
  title: 'Outro',
  intention: 'OTHER',
  description: 'Tenho outro objetivo que não está nas opções.',
  svg: otherSVG,
}

const OPTION_LIST: Option[] = [
  {
    title: 'Sou parte em um processo',
    intention: 'PARTY',
    description: 'Saber mais sobre processos meus ou da minha empresa.',
    svg: partySVG,
  },
  {
    title: 'Familiares',
    intention: 'RELATIVE',
    description: 'Encontrar informações sobre um processo de um familiar.',
    svg: relativeSVG,
  },
  {
    title: 'Fundamentação jurídica',
    intention: 'LEGAL_RESEARCH',
    description:
      'Buscar embasamento jurídico para o meu trabalho, pesquisa acadêmica ou parecer consultivo.',
    svg: legalResearchSVG,
  },
  {
    title: 'Histórico jurídico',
    intention: 'REPUTATION',
    description: 'Avaliar o histórico processual de pessoas ou empresas.',
    svg: reputationSVG,
  },
  {
    title: 'Gestão',
    intention: 'CASE_MANAGEMENT',
    description: 'Gerir processos nos quais atuo como pessoa advogada.',
    svg: caseManagementSVG,
  },
]

export const SEND_INTENTIONS_MUTATION = gql`
  mutation SendIntentions($input: EditProfileInput!) {
    editProfile(input: $input) {
      profile {
        id
        intentions
      }
    }
  }
`

const shuffle = (array: Option[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const saveCookie = (intention: string) => {
  const tenYearsInSeconds = 60 * 60 * 24 * 365 * 10

  setJusbrasilCookie(null, USER_INTENTIONS_COOKIE_NAME, intention, {
    maxAge: tenYearsInSeconds,
  })
}

const handleOptionClick = async (
  pid: string,
  intention: string,
  sendIntentions: MutationFunction,
  fromComponent?: string
) => {
  const eventData = {
    intentions: [intention],
    from_component: fromComponent,
  }

  try {
    await sendIntentions({
      variables: {
        input: {
          pid,
          changes: {
            intencoes: [intention],
          },
        },
      },
    })

    saveCookie(intention)
    sendMetrics('UserAccount.IntentionSubmitted', pid, eventData)
  } catch (error) {
    sendMetrics('UserAccount.IntentionSubmittedError', pid, eventData)
  }
}

export const LawsuitIntentionsModal = ({ viewer, fromComponent }: LawsuitIntentionsModalProps) => {
  const [sendIntentions, { error }] = useMutation(SEND_INTENTIONS_MUTATION)
  const pid = viewer?.pid?.toString()

  const optionList = useMemo(() => {
    return [...shuffle(OPTION_LIST), OTHER_OPTION]
  }, [])

  useEffect(() => {
    sendMetrics('UserAccount.IntentionViewed', pid, { from_component: fromComponent })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal size="md" open>
      <Modal.Content>
        <Modal.Header>
          <Modal.HeaderTitle asChild>
            <Heading size="md">Qual o seu objetivo com o Jusbrasil no momento?</Heading>
          </Modal.HeaderTitle>
          <Modal.HeaderDescription>
            Sabendo o que você gostaria de alcançar, podemos melhorar sua experiência.
          </Modal.HeaderDescription>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Banner type="critical" className={styles.error}>
              <Banner.IconClose />
              <Banner.Content>
                <Banner.Title>Algo deu errado, tente novamente!</Banner.Title>
              </Banner.Content>
            </Banner>
          )}
          <List className={styles.list}>
            {optionList.map(({ intention, svg, title, description }, i) => (
              <List.Item className={styles.listItem} key={i}>
                <Card className={styles.card} asChild>
                  <button
                    onClick={() =>
                      handleOptionClick(`${pid}`, intention, sendIntentions, fromComponent)
                    }
                  >
                    <Image className={styles.image} src={svg} alt="" />
                    <Heading className={styles.title} size="sm">
                      {title}
                    </Heading>
                    <Caption>{description}</Caption>
                  </button>
                </Card>
              </List.Item>
            ))}
          </List>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

LawsuitIntentionsModal.fragments = {
  viewer: gql`
    fragment LawsuitIntentionsModal_viewer on Profile {
      pid
      ...useUserOnboarding_viewer
    }
    ${useUserOnboarding.fragments.viewer}
  `,
}

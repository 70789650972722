import { Box_unstable as Box, BoxElement, BoxProps } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './topbar-skeleton.module.scss'

export type TopbarSkeletonElement = BoxElement

export interface TopbarSkeletonProps extends BoxProps {
  animateBg?: boolean
  animateColor?: boolean
  radius?: 'circle' | 'text'
}

export const TopbarSkeleton = forwardRef<TopbarSkeletonElement, TopbarSkeletonProps>(
  function TopbarSkeleton(props, forwardedRef) {
    const { animateBg, animateColor, className, radius, ...rest } = props

    const classes = classNames(
      {
        [styles.bg]: animateBg === true,
        [styles.color]: animateColor === true,
        [styles[`radius${radius}`]]: radius,
      },
      className
    )

    return <Box {...rest} className={classes} ref={forwardedRef} />
  }
)

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useFarolExperimental } from '../experimental-provider/experimental-provider'

const themes = ['light', 'dark'] as const
export type FarolTheme = (typeof themes)[number]

type ThemeContextType = {
  theme: FarolTheme | null
  setTheme: (theme: FarolTheme) => void
}

const LOCAL_STORAGE_KEY = 'fds:theme'
const MEDIA_QUERY = '(prefers-color-scheme: dark)'

const ThemeContext = createContext<ThemeContextType>({
  theme: null,
  setTheme: () => {
    // void
  },
})

export type ThemeProviderProps = {
  theme?: FarolTheme
  children: ReactNode
}

export function ThemeProvider(props: ThemeProviderProps) {
  const { theme: themeProp = undefined, children } = props

  const { flags } = useFarolExperimental()
  const DISABLE_THEME_FEATURE = flags.theme !== true

  const [theme, setThemeState] = useState<FarolTheme | null>(null)

  const setTheme = useCallback((nextTheme: FarolTheme) => {
    setThemeState(nextTheme)
    if (typeof window !== 'undefined') {
      document.documentElement.dataset.fdsTheme = nextTheme
    }
  }, [])

  const setPersistedTheme = useCallback(
    (nextTheme: FarolTheme) => {
      if (themeProp && nextTheme !== themeProp) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            `ThemeProvider: Cannot change theme to '${nextTheme}' because the forced theme prop in FarolProvider is set to '${themeProp}'`
          )
        }
        return
      }

      setTheme(nextTheme)
      if (typeof window !== 'undefined') {
        localStorage.setItem(LOCAL_STORAGE_KEY, nextTheme)
      }
    },
    [setTheme, themeProp]
  )

  useEffect(() => {
    if (DISABLE_THEME_FEATURE) return
    const isDark = document.documentElement.dataset.fdsTheme === 'dark'
    setThemeState(isDark ? 'dark' : 'light')
  }, [DISABLE_THEME_FEATURE])

  useEffect(() => {
    if (DISABLE_THEME_FEATURE) return
    const mediaQuery = window.matchMedia(MEDIA_QUERY)

    const onPrefersColorSchemeChange = (event: MediaQueryListEvent) => {
      // do not "auto change" theme if it was already persisted
      const isPersisted = !!localStorage.getItem(LOCAL_STORAGE_KEY)
      if (isPersisted) {
        return
      }

      setTheme(event.matches ? 'dark' : 'light')
    }

    mediaQuery.addEventListener('change', onPrefersColorSchemeChange)
    return () => {
      mediaQuery.removeEventListener('change', onPrefersColorSchemeChange)
    }
  }, [DISABLE_THEME_FEATURE, setTheme])

  useEffect(() => {
    if (DISABLE_THEME_FEATURE) return

    const onStorageChange = (evt: StorageEvent) => {
      if (evt.key === LOCAL_STORAGE_KEY) {
        setTheme(evt.newValue as FarolTheme)
      }
    }

    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [DISABLE_THEME_FEATURE, setTheme])

  const injectThemeScript = useMemo(
    () =>
      `(function(){
        try{
          var s=null;s=localStorage.getItem('${LOCAL_STORAGE_KEY}');
          var d=window.matchMedia('${MEDIA_QUERY}').matches,t=s||(d?'dark':'light');
          document.documentElement.dataset.fdsTheme=${JSON.stringify(themeProp)}||t;
        }catch(e){}
      })();`.replace(/\n\s*/g, ''),
    [themeProp]
  )

  return (
    <>
      {!DISABLE_THEME_FEATURE && <script dangerouslySetInnerHTML={{ __html: injectThemeScript }} />}

      <ThemeContext.Provider
        value={{
          theme: theme,
          setTheme: setPersistedTheme,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </>
  )
}

export const useTheme = () => {
  const ctx = useContext(ThemeContext)
  return [ctx.theme, ctx.setTheme] as const
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.707 19.707a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L11 16.586V5a1 1 0 1 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 1.414l-6 6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgArrowDown)

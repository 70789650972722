import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgTagMulti(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.293 5.293c2.6-2.6 6.814-2.6 9.414 0l5.586 5.586a3 3 0 0 1 0 4.242l-5.172 5.172a3 3 0 0 1-4.242 0l-5.586-5.586a6.657 6.657 0 0 1 0-9.414Zm8 1.414a4.657 4.657 0 0 0-6.586 6.586l5.586 5.586a1 1 0 0 0 1.414 0l5.172-5.172a1 1 0 0 0 0-1.414l-5.586-5.586Zm4-1a1 1 0 0 1 1.414-1.414l6.586 6.586a3 3 0 0 1 0 4.242l-4.586 4.586a1 1 0 0 1-1.414-1.414l4.586-4.586a1 1 0 0 0 0-1.414l-6.586-6.586Zm-1.586 6.586a1 1 0 0 0-1.414 0l-2 2a1 1 0 1 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414ZM6 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgTagMulti)

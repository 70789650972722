import { Link } from '@farol-ds/react'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'

import accessLimitImgSrc from '../../public/identity-validation-modal/access-limit.svg'
import emailImgSrc from '../../public/identity-validation-modal/email.svg'
import errorImgSrc from '../../public/identity-validation-modal/error.svg'
import successImgSrc from '../../public/identity-validation-modal/success.svg'
import { IdentityValidationModal_ViewerFragment } from './__generated__/identity-validation-modal.graphql'
import { SUPPORT_CONTACT_URL } from './constants'
import { SharedDataInterface } from './context'
import { Steps } from './enums'
import { TopicEventData } from './identity-validation-modal'
import { CPFInUse, ReplacingCPF, StaticStep, ValidateIdentity, ValidatingIdentity } from './steps'

export interface FlowStepsBodyText {
  accessLimitReached?: string
  identitySuccess?: string
}

interface StepFuncProps {
  viewer: IdentityValidationModal_ViewerFragment | null
  onClose?: () => void
  sharedData?: SharedDataInterface
  eventData?: TopicEventData
  stepsCallback?: (isValidatedSuccess: boolean) => void
  flowStepsBodyText?: FlowStepsBodyText
}

type StepFunc = (props: StepFuncProps) => React.ReactNode

export const FLOW_STEPS: Record<Steps, StepFunc> = {
  [Steps.ACCESS_LIMIT_REACHED]: ({ flowStepsBodyText }) => (
    <StaticStep
      testId="access-limit-reached-step"
      imageSrc={accessLimitImgSrc}
      title="Verifique sua identidade"
      bodyText={
        flowStepsBodyText?.accessLimitReached ||
        'Você alcançou o limite de acesso a páginas de pessoas físicas. Verifique a sua identidade para continuar navegando por essas páginas.'
      }
      action={{
        nextStep: Steps.VALIDATE_IDENTITY,
        label: 'Continuar',
      }}
      onLoadEvent={{
        name: 'UserAccount.IdValidationLimitReachedViewed',
      }}
    />
  ),
  [Steps.VALIDATE_IDENTITY]: () => <ValidateIdentity />,
  [Steps.VALIDATING_IDENTITY]: ({ stepsCallback }) => (
    <ValidatingIdentity validateCallback={stepsCallback} />
  ),
  [Steps.IDENTITY_ERROR]: () => (
    <StaticStep
      testId="identity-error-step"
      imageSrc={errorImgSrc}
      title="Identidade não pôde ser verificada"
      bodyText={
        <>
          Por favor, revise suas informações ou entre em{' '}
          <Link href={SUPPORT_CONTACT_URL} target="_blank">
            contato com o suporte
          </Link>
          .
        </>
      }
      action={{
        nextStep: Steps.VALIDATE_IDENTITY,
        label: 'Revisar Informações',
      }}
      onLoadEvent={{
        name: 'UserAccount.IdValidationFailedViewed',
        data: { type: 'CPFInvalid' },
      }}
    />
  ),
  [Steps.IDENTITY_SUCCESS]: ({ onClose, flowStepsBodyText }) => (
    <StaticStep
      testId="identity-success-step"
      imageSrc={successImgSrc}
      title="Identidade verificada"
      bodyText={
        flowStepsBodyText?.identitySuccess ||
        'Agora você pode continuar navegando entre páginas de pessoas físicas.'
      }
      action={{
        onClick: onClose,
        label: 'Continuar',
      }}
      onLoadEvent={{
        name: 'UserAccount.IdValidationSuccessViewed',
      }}
    />
  ),
  [Steps.CPF_IN_USE]: () => <CPFInUse />,
  [Steps.REMOVAL_CPF_EMAIL]: ({ onClose, sharedData, viewer, eventData }) => {
    const stepEvent = (event: string, metadata: Record<string, string>) => {
      const pid = viewer?.pid.toString() || null

      sendMetrics(event, pid, {
        ...eventData,
        metadata: {
          cpf: sharedData?.cpf,
          birthDate: formatDate(sharedData?.birthDate?.getTime() || ''),
          ...eventData?.metadata,
          ...metadata,
        },
      })
    }

    return (
      <StaticStep
        testId="removal-cpf-email-step"
        imageSrc={emailImgSrc}
        title="E-mail de verificação enviado"
        bodyText={
          <>
            Acesse o e-mail que enviamos para <strong>{sharedData?.profileInUse?.email}</strong>{' '}
            para cadastrar o CPF à conta atual.
          </>
        }
        bodyText2={
          <>
            Entre em{' '}
            <Link
              href={SUPPORT_CONTACT_URL}
              target="_blank"
              onClick={() =>
                stepEvent('UserAccount.IdValidationChangeAccountSubmitted', {
                  button: 'SupportHelp',
                })
              }
            >
              contato com nosso suporte
            </Link>{' '}
            caso não reconheça este e-mail.
          </>
        }
        action={{
          onClick: () => {
            stepEvent('UserAccount.IdValidationChangeAccountSubmitted', { button: 'Finish' })
            onClose?.()
          },
          label: 'Concluir',
        }}
        onLoadEvent={{
          name: 'UserAccount.IdValidationChangeAccountViewed',
        }}
      />
    )
  },
  [Steps.REPLACING_CPF]: ({ stepsCallback }) => <ReplacingCPF validateCallback={stepsCallback} />,
  [Steps.REPLACEMENT_SUCCESS]: ({ onClose }) => (
    <StaticStep
      testId="replacement-success-step"
      imageSrc={successImgSrc}
      title="CPF vinculado"
      bodyText="Agora você pode continuar navegando pelo site."
      action={{
        onClick: onClose,
        label: 'Continuar',
      }}
      onLoadEvent={{
        name: 'UserAccount.OverwriteCPFSuccessViewed',
      }}
    />
  ),
  [Steps.REPLACEMENT_ERROR]: () => (
    <StaticStep
      testId="replacement-error-step"
      imageSrc={errorImgSrc}
      title="Erro ao vincular CPF"
      bodyText="Não foi possível completar o fluxo. Tente novamente."
      action={{
        nextStep: Steps.VALIDATE_IDENTITY,
        label: 'Revisar Informações',
      }}
      onLoadEvent={{
        name: 'UserAccount.OverwriteCPFFailedViewed',
      }}
    />
  ),
}

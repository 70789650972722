import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import {
  UseUserOnboarding_TopicFragment,
  UseUserOnboarding_ViewerFragment,
} from './__generated__/use-user-onboarding.graphql'
import { INVOLVED_INTENTIONS, ONBOARDING_STEPS } from './constants'

const getCurrentStep = (
  viewer: UseUserOnboarding_ViewerFragment | null,
  topic: UseUserOnboarding_TopicFragment | null
) => {
  if (!viewer) {
    return null
  }

  const { intentions = [], hasDeclaredOccupation, onboarding } = viewer || {}

  if (!intentions?.length) {
    return ONBOARDING_STEPS.SHOW_INTENTIONS
  }

  if (!hasDeclaredOccupation) {
    return ONBOARDING_STEPS.SHOW_OCCUPATION
  }

  const userHasFeature = !!topic?.canVerifyLawsuit
  const isInvolvedParty = intentions?.length && INVOLVED_INTENTIONS.includes(intentions[0] || '')
  const { verifyLawsuit = 0 } = onboarding || {}

  if (!userHasFeature && isInvolvedParty && verifyLawsuit <= ONBOARDING_STEPS.SHOW_INSTRUCTIONS) {
    return ONBOARDING_STEPS.SHOW_INSTRUCTIONS
  }

  return ONBOARDING_STEPS.COMPLETE
}

export function useUserOnboarding(
  viewer: UseUserOnboarding_ViewerFragment | null,
  topic: UseUserOnboarding_TopicFragment | null
) {
  const currentStep = getCurrentStep(viewer, topic)

  const isOnboardingComplete = useCallback(() => {
    return currentStep === ONBOARDING_STEPS.COMPLETE
  }, [currentStep])

  return useMemo(
    () => ({
      currentStep,
      isOnboardingComplete,
    }),
    [currentStep, isOnboardingComplete]
  )
}

useUserOnboarding.fragments = {
  viewer: gql`
    fragment useUserOnboarding_viewer on Profile {
      id
      intentions
      hasDeclaredOccupation

      onboarding {
        verifyLawsuit: stopStep(onboardingName: "verify-lawsuit")
      }
    }
  `,
  topic: gql`
    fragment useUserOnboarding_topic on Topic {
      canVerifyLawsuit: viewerCanUseFeature(
        featureCode: "update_lawsuit"
        useLastActivityDateLimiter: false
      )
    }
  `,
}

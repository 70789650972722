import * as RTabs from '@radix-ui/react-tabs'
import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'

import { Slottable } from '../utilities'
import type { TabsChangeBehavior } from './tabs'
import styles from './tabs-trigger.module.scss'
import { useTabs } from './use-tabs'

export type TabsTriggerElement = HTMLButtonElement

export interface TabsTriggerProps extends ButtonHTMLAttributes<TabsTriggerElement> {
  value: string
  children: ReactNode
  className?: string
  changeBehavior?: TabsChangeBehavior
  disabled?: boolean
  asChild?: boolean
}

const TabsTrigger = forwardRef<TabsTriggerElement, TabsTriggerProps>(function TabsTrigger(
  props,
  forwardedRef
) {
  const { className, children, value, asChild, changeBehavior: changeBehaviorProp, ...rest } = props

  const { getTriggerProps, changeBehavior: changeBehaviorFromRoot } = useTabs()

  const classes = classNames(styles.root, className)
  const changeBehavior = changeBehaviorProp ?? changeBehaviorFromRoot

  const dataChangeBehavior =
    changeBehavior && changeBehavior !== 'none' ? changeBehavior : undefined

  return (
    <RTabs.Trigger
      ref={forwardedRef}
      {...rest}
      {...getTriggerProps?.(forwardedRef, value)}
      value={value}
      className={classes}
      data-change-behavior={dataChangeBehavior}
      asChild
    >
      {!asChild ? <a href={`#${value}`}>{children}</a> : <Slottable>{children}</Slottable>}
    </RTabs.Trigger>
  )
})

export default TabsTrigger

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStatusPartially(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.071 12.755c1.713.353 2.82-.2 3.929-.755 1.108-.554 2.216-1.108 3.929-.755a4.001 4.001 0 0 0-7.858 1.51ZM6 12a6 6 0 1 1 12 0v.078A6.001 6.001 0 0 1 6 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStatusPartially)

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, ReactElement } from 'react'

import styles from './line-scroll.module.scss'

export type LineScrollBoundaryElement = HTMLDivElement
export type LineScrollBoundaryProps = {
  disableGutters?: boolean
  containerClassName?: string
} & HTMLAttributes<LineScrollBoundaryElement>

export const LineScrollBoundary = forwardRef<LineScrollBoundaryElement, LineScrollBoundaryProps>(
  function LineScrollBoundary(props, forwardedRef) {
    const { disableGutters = false, children, className, containerClassName, ...rest } = props
    const classes = classNames(
      styles.boundary,
      {
        [styles.disableGutters]: disableGutters,
      },
      containerClassName
    )
    return (
      <div className={classes} ref={forwardedRef}>
        {cloneElement(children as ReactElement, {
          ...rest,
          className: classNames(styles.list, className),
        })}
      </div>
    )
  }
)

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities/slot/slot'
import itemStyles from './checkbox-item.module.scss'
import styles from './checkbox-name.module.scss'

export type CheckboxNameElement = HTMLSpanElement

export interface CheckboxNameProps extends HTMLAttributes<CheckboxNameElement> {
  children: ReactNode
  asChild?: boolean
}

const CheckboxName = forwardRef<CheckboxNameElement, CheckboxNameProps>(function CheckboxName(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'span'

  const classes = classNames(styles.root, itemStyles.itemName, className)

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default CheckboxName

import { useContext } from 'react'

import { ensurePropOf } from '../utilities'
import { AccordionContext } from './context'
import { AccordionProps, AccordionSize, AccordionSizes } from './types'

export type UseAccordionProviderProps = AccordionProps

export interface UseAccordionProviderReturn {
  size: AccordionSize
}

export function useAccordionProvider(props: UseAccordionProviderProps): UseAccordionProviderReturn {
  const { size: sizeProp } = props
  const size = ensurePropOf<AccordionSize>(AccordionSizes, sizeProp, 'md')
  return { size }
}

export function useAccordion() {
  const context = useContext(AccordionContext)
  return context
}

import {
  ABTestExperiment,
  ABTestParticipation,
  withABTestExperiment,
} from '@jusbrasil-web/shared-ab-test'
import { GetServerSidePropsContext } from 'next'

export async function fetchExperiments(
  ctx: GetServerSidePropsContext,
  config: ABTestParticipation
): Promise<ABTestExperiment[]> {
  if (!config?.experiments?.length) return []

  const experiments = await withABTestExperiment(ctx, config)
  return experiments.participation.map((exp) => ({
    alternative: exp.alternative?.name || '',
    experiment: exp.experiment?.name || '',
    participating: exp.participating,
    experimentGroup: exp.experiment_group?.name || '',
  }))
}

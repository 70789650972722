import classNames from 'classnames'
import { forwardRef } from 'react'

import { Caption, CaptionElement, CaptionProps } from '../caption/caption'
import styles from './snippet-footnote-body.module.scss'

export type SnippetFootnoteBodyElement = CaptionElement

export type SnippetFootnoteBodyProps = CaptionProps

const SnippetFootnoteBody = forwardRef<SnippetFootnoteBodyElement, SnippetFootnoteBodyProps>(
  function SnippetFootnoteBody(props, forwardedRef) {
    const { children, className, ...rest } = props

    const classes = classNames(styles.root, className)

    return (
      <Caption {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Caption>
    )
  }
)

export default SnippetFootnoteBody

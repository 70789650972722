import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import styles from './snippet-footnote.module.scss'

export type SnippetFootnoteElement = HTMLDivElement

export type SnippetFootnoteProps = HTMLAttributes<SnippetFootnoteElement> & {
  asChild?: boolean
}

const SnippetFootnote = forwardRef<SnippetFootnoteElement, SnippetFootnoteProps>(
  function SnippetFootnote(props, forwardedRef) {
    const { children, className, asChild, ...rest } = props

    const classes = classNames(styles.root, className)

    const Component = asChild ? Slot : 'div'

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default SnippetFootnote

import { gql } from '@apollo/client'
import { RefObject } from 'react'

import { OnMetricsFunction } from '../../types'
import { useListener } from '../use-listener'
import { UseListenerWithViewer_ViewerFragment } from './__generated__/use-listener-with-viewer.graphql'

export function useListenerWithViewer<T extends HTMLElement>(
  viewer: UseListenerWithViewer_ViewerFragment | null,
  onMetrics: OnMetricsFunction
): { ref: RefObject<T> } {
  const userId = typeof viewer?.pid === 'number' ? `${viewer.pid}` : null

  const { ref } = useListener<T>((event) => {
    event.detail.user_id = userId
    onMetrics(event)
  })

  return { ref }
}

useListenerWithViewer.fragments = {
  viewer: gql`
    fragment useListenerWithViewer_viewer on Profile {
      pid
    }
  `,
}

import classNames from 'classnames'
import { forwardRef } from 'react'

import { List, ListElement, ListProps } from '../list/list'
import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './icon-list.module.scss'
import IconListItem from './icon-list-item'

export type IconListElement = ListElement
export const IconListSizes = ['md', 'lg'] as const
export type IconListSize = (typeof IconListSizes)[number]

export interface IconListProps extends Omit<ListProps, 'type'> {
  size?: IconListSize
}

const IconListRoot = forwardRef<IconListElement, IconListProps>(function IconList(
  props,
  forwardedRef
) {
  const { className, size: sizeProp, ...rest } = props

  const size = ensurePropOf<IconListSize>(IconListSizes, sizeProp, 'md')
  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  return <List {...rest} className={classes} ref={forwardedRef} />
})

export const IconList = assignSubComponents('IconList', IconListRoot, {
  Item: IconListItem,
})

export * from './icon-list-item'

import { Caption, ChipClickable } from '@farol-ds/react'
import classNames from 'classnames'

import styles from './comprehension.module.scss'

export type ComprehensionStepProps = {
  options: string[]
  selectedOption?: string
  onOptionClick: (value: string) => void
}

export const ComprehensionStep = ({
  options,
  selectedOption,
  onOptionClick,
}: ComprehensionStepProps) => (
  <div className={classNames(styles.options, styles.comprehension)}>
    {options.map((option) => (
      <div key={option}>
        <ChipClickable
          size="md"
          selected={selectedOption === option}
          onClick={() => onOptionClick(option)}
        >
          {option}
        </ChipClickable>
        {option === '1' || option === '5' ? (
          <Caption className={styles.optionSub} asChild>
            <span>{option === '1' ? 'Nada' : 'Muito'}</span>
          </Caption>
        ) : null}
      </div>
    ))}
  </div>
)

import classNames from 'classnames'
import { useMemo } from 'react'

import { allowedSxProps, breakpoints, classMap } from './config'
import styles from './sx.module.scss'
import { transformPropToClass } from './transform-prop-to-class'
import { ComponentSxProps, SxProp } from './types'

export function useSxProp<K extends { className?: string }>(
  props: ComponentSxProps<K>
): Omit<ComponentSxProps<K>, 'sx'> {
  return useMemo(() => {
    const { sx, ...compProps } = props
    if (!sx) return compProps

    const classes = []
    for (const [propName, propValue] of Object.entries(sx)) {
      if (propValue === undefined || propValue === null) continue
      if (!allowedSxProps.includes(propName)) continue
      classes.push(
        ...transformPropToClass(propValue as string, propName as SxProp, {
          styles,
          breakpoints,
          classMap,
        })
      )
    }

    return {
      ...compProps,
      className: classNames(...classes, compProps.className),
    }
  }, [props])
}

export * from './types'

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './row.module.scss'

export type RowElement = HTMLDivElement

export interface RowProps extends HTMLAttributes<RowElement> {
  children: ReactNode
  className?: string
  asChild?: boolean
}

export const Row = forwardRef<RowElement, RowProps>(function Row(props, forwardedRef) {
  const { className, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'div'

  const classes = classNames(styles.root, className)

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

import { ArgosWindow, EventProperties } from '../types'
import { getNamespaceEventProperties } from './get-namespace-event-properties'
import { namespacesStorageObjectPromise } from './namespaces-storage-object-promise'
import { sourceComponent } from './source-component'

/**
 * @description Function that pushes an event to Argos.
 * @param name Name of the event.
 * @param userId Id of the logged user. Can be null or undefined if the user isn't logged.
 * @param properties The event properties.
 */
export const sendMetrics = async (
  name: string,
  userId: string | null | undefined,
  properties: Record<string, unknown> = {}
) => {
  const win = window as ArgosWindow
  win._argos = win._argos || []

  const { referrer } = document
  const [namespace] = name.split('.')

  const waitEventLoopPromise = new Promise<EventProperties>((resolve) => {
    setTimeout(() => resolve({}))
  })

  const namespacesStorageObject = await Promise.race([
    namespacesStorageObjectPromise,
    waitEventLoopPromise,
  ])

  const propsNamespace = getNamespaceEventProperties(namespacesStorageObject, namespace)

  const mergedProperties: any = {
    ...propsNamespace,
    referrer,
    source: referrer,
    source_component: sourceComponent,
    ...properties,
  }

  if (properties && properties.metadata && propsNamespace && propsNamespace.metadata) {
    mergedProperties.metadata = {
      ...propsNamespace.metadata,
      ...properties.metadata,
    }
  }

  const detail = { name, user_id: userId, properties: mergedProperties }

  win._argos.push(detail)

  if (process.env.NODE_ENV === 'development') {
    console.log('sendMetrics called with', detail)
  }
}

import Script from 'next/script'

const HOTJAR_SCRIPT_ID = 'hotjar-script'
const MAIN_JUSBRASIL_HOTJAR_PROJECT_ID = 2471547

interface HotjarScriptProps {
  hjid?: number
}

export function HotjarScript({ hjid = MAIN_JUSBRASIL_HOTJAR_PROJECT_ID }: HotjarScriptProps) {
  return (
    <Script id={HOTJAR_SCRIPT_ID} strategy="lazyOnload">
      {`
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hjid},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}
    </Script>
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFileSearch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4a1 1 0 0 1 1 1v2a1 1 0 1 0 2 0V5a3 3 0 0 0-3-3H8.828a3 3 0 0 0-2.12.879l-3.83 3.828A3 3 0 0 0 2 8.828V19a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1v-9h4a2 2 0 0 0 2-2V4h5Zm-7 .414L4.414 8H8V4.414ZM16 12a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 9.026 2.966l2.181 2.181a1 1 0 0 1-1.414 1.414l-2.254-2.253A5 5 0 0 1 11 15Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFileSearch)

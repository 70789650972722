import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgBookmark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4a1 1 0 0 0-1 1v14c0 .903 1.102 1.343 1.724.69l3.552-3.73a1 1 0 0 1 1.448 0l3.552 3.73c.622.653 1.724.213 1.724-.69V5a1 1 0 0 0-1-1H7ZM4 5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v14c0 2.708-3.305 4.03-5.172 2.069L12 18.1l-2.828 2.969C7.305 23.03 4 21.709 4 19V5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgBookmark)

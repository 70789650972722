import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import styles from './snippet-header.module.scss'

export type SnippetHeaderElement = HTMLDivElement

export type SnippetHeaderProps = HTMLAttributes<SnippetHeaderElement> & {
  asChild?: boolean
}

const SnippetHeader = forwardRef<SnippetHeaderElement, SnippetHeaderProps>(function SnippetHeader(
  props,
  forwardedRef
) {
  const { children, className, asChild, ...rest } = props

  const classes = classNames(styles.root, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default SnippetHeader

export const SELECTED_CHIP_DELAY = 150
export const HIDE_MESSAGE_DELAY = 90000

export enum Step {
  COMPREHENSION = 'comprehension',
  REASON = 'reason',
  OTHER_REASON = 'other',
  MESSAGE = 'message',
}

export const INITIAL_STEP = Step.COMPREHENSION
export const FINISH_STEP = Step.MESSAGE

export const OTHER_REASON = 'Outro motivo'

export const FEATURE = 'LawsuitSummarization'

export const FROM_COMPONENT = 'LawsuitSummaryGeneratingModal'

export const REASONS = [
  'Faltam detalhes do processo',
  'O resumo está incorreto',
  'Contém muitos termos difíceis',
  'O resumo está desatualizado',
]

import { gql } from '@apollo/client/core'
import { createHash } from 'crypto'

import { UseQualtricsAnswer_LawsuitFragment } from './__generated__/use-qualtrics-answer.graphql'

export type UseQualtricsAnswer = {
  lawsuit: UseQualtricsAnswer_LawsuitFragment | null
}

export type QualtricsAnswerManager = {
  send(eventName: string, metadata: Record<string, string>, surveyId: string): void
}

enum SurveyFields {
  REASON = 'QID2_TEXT',
  COMPREHENSION = 'QID7_TEXT',
  COMMENT = 'QID8_TEXT',
  EVENT_NAME = 'QID10_TEXT',
  LAWSUIT_ID = 'QID11_TEXT',
  NUMBER = 'QID13_TEXT',
  FEATURE = 'QID14_TEXT',
}

export function useQualtricsAnswer(props: UseQualtricsAnswer): QualtricsAnswerManager {
  const { number = '', lawsuitId = '' } = props.lawsuit ?? {}

  return {
    send(eventName, metadata, surveyId) {
      const data: Record<string, string> = {
        ...metadata,
        number: String(number),
        lawsuitId: String(lawsuitId),
      }
      sendQualtricsAnswer(eventName, data, surveyId)
    },
  } satisfies QualtricsAnswerManager
}

export async function sendQualtricsAnswer(
  eventName: string,
  data: Record<string, string>,
  surveyId: string
) {
  if (!data.comment) return

  const body = buildQualtricsPayload(eventName, data)
  const idempotencyKey = generateIdempotencyKey(data, eventName)

  if (process.env.NODE_ENV === 'production') {
    try {
      const response = await fetch(`/web/lawsuit/api/qualtrics/${surveyId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ body, idempotencyKey }),
      })

      if (!response.ok) {
        throw new Error('Error on requisition')
      }
    } catch (error) {
      console.error('Error to send answer to Qualtrics:', error)
    }
  } else {
    console.log(`SENDING ${JSON.stringify(body)} TO QUALTRICS`)
  }
}

function buildQualtricsPayload(eventName: string, data: Record<string, string>) {
  return {
    values: {
      [SurveyFields.REASON]: data.reason,
      [SurveyFields.COMPREHENSION]: data.comprehension,
      [SurveyFields.COMMENT]: data.comment,
      [SurveyFields.EVENT_NAME]: eventName,
      [SurveyFields.LAWSUIT_ID]: data.lawsuitId,
      [SurveyFields.NUMBER]: data.number,
      [SurveyFields.FEATURE]: data.feature,
    },
  }
}

function generateIdempotencyKey(data: Record<string, string>, eventName: string) {
  const rawString = `${data.lawsuitId}-${data.comment}-${eventName}`
  return createHash('sha1').update(rawString).digest('hex')
}

useQualtricsAnswer.fragments = {
  lawsuit: gql`
    fragment UseQualtricsAnswer_lawsuit on CRMLawsuit {
      lawsuitId
      number
    }
  `,
}

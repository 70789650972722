import { useEffect, useState } from 'react'

import { useInterval } from '../../hooks'

export interface TypingProps {
  text: string
  typingDelay?: number
  onFinish?: () => void
  cycle?: boolean
}

export function Typing({ text, typingDelay = 300, cycle = false, onFinish }: TypingProps) {
  const [displayText, setDisplayText] = useState('')
  const [startInterval, stopInterval] = useInterval()

  useEffect(() => {
    let currentIndex = 0

    const updateDisplayText = () => {
      setDisplayText(text.slice(0, currentIndex + 1))
      currentIndex += 1
    }

    const handleTypingCompletion = () => {
      stopInterval()
      onFinish?.()

      if (cycle) {
        resetTyping()
      }
    }

    const resetTyping = () => {
      setTimeout(() => {
        currentIndex = 0
        setDisplayText('')
        startTyping()
      }, typingDelay)
    }

    const startTyping = () => {
      startInterval(() => {
        updateDisplayText()

        if (currentIndex === text.length) {
          handleTypingCompletion()
        }
      }, typingDelay)
    }

    startTyping()

    return () => stopInterval()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  return <>{displayText}</>
}

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4ViewItemListRenderProps {
  item_variant: string
  item_brand: string
  item_name: string | null
  item_id: string | null
}

interface Properties {
  componentClass?: string | null
  componentName?: string | null
  renders?: GA4ViewItemListRenderProps[] | null
}

export class GA4ViewItemListEvent extends GA4Event {
  ga4_component_class?: string | null
  ga4_component_name?: string | null
  ga4_renders?: GA4ViewItemListRenderProps[] | null

  constructor({ renders = null, componentClass = null, componentName = null }: Properties) {
    super({ event: EVENT.VIEW_ITEM_LIST })

    this.ga4_component_class = componentClass
    this.ga4_component_name = componentName
    this.ga4_renders = renders
      ? renders.map((item) => ({
          item_variant: item.item_variant,
          item_brand: item.item_brand,
          item_name: item.item_name,
          item_id: item.item_id,
        }))
      : null
  }
}

import { courtRegExp } from '../../../regexp'
import { capitalize } from '../capitalize'

const defaultLowerCaseWords = [
  'a',
  'à',
  'com',
  'da',
  'das',
  'de',
  'do',
  'dos',
  'e',
  'é',
  'em',
  'na',
  'nas',
  'no',
  'nos',
  'o',
  'os',
  'por',
  'sem',
  'n',
  'nº',
  'se',
  'que',
  'um',
  'uma',
  'para',
  'com',
]

const ufs = [
  'ac',
  'al',
  'ap',
  'am',
  'ba',
  'ce',
  'df',
  'go',
  'es',
  'ma',
  'mt',
  'ms',
  'mg',
  'pa',
  'pb',
  'pr',
  'pe',
  'pi',
  'rj',
  'rn',
  'rs',
  'ro',
  'rr',
  'sp',
  'sc',
  'se',
  'to',
]

const defaultUpperCaseWords = [
  'cnpj',
  'cpf',
  'ltda',
  'qp',
  'tv',
  'mei',
  'me',
  'ei',
  'epp',
  'eireli',
  'sa',
  'arr',
  'ai',
  'oj',
  'ojt',
  ...ufs,
]

const defaultAsIsWords = ['agint', 'aresp', 'airr', 'agrg', 'apl', 'hc']

const pointsRegExp = /[,.;!*/?\s]/g
const closeOpenParenthesesRegExp = /\)\(/g
const singleParenthesesRegExp = /[()]/g

const isCourt = (word: string) => courtRegExp.test(word)
const toLowerCase = (word: string) => word.toLowerCase()

export interface capitalizePhraseProps {
  phrase: string
  asIsWords?: string[]
  upperCaseWords?: string[]
  lowerCaseWords?: string[]
}

export const capitalizePhrase = ({
  phrase,
  asIsWords = [],
  lowerCaseWords = [],
  upperCaseWords = [],
}: capitalizePhraseProps): string => {
  const lowerCaseSet = new Set([...defaultLowerCaseWords, ...lowerCaseWords.map(toLowerCase)])
  const upperCaseSet = new Set([...defaultUpperCaseWords, ...upperCaseWords.map(toLowerCase)])
  const asIsSet = new Set([...defaultAsIsWords, ...asIsWords.map(toLowerCase)])

  const sanitizedPhrase = phrase
    .replace(closeOpenParenthesesRegExp, ' ')
    .replace(singleParenthesesRegExp, '')

  return sanitizedPhrase
    .split(' ')
    .filter((word) => !!word)
    .map((word, index) => {
      const sanitizedWord = word.replace(pointsRegExp, '')
      if (asIsSet.has(sanitizedWord.toLowerCase())) {
        return word
      }

      const lowerCase = word.toLowerCase()
      const sanitizedLowerCase = lowerCase.replace(pointsRegExp, '')
      if (index !== 0 && lowerCaseSet.has(sanitizedLowerCase)) {
        return lowerCase
      }

      return isCourt(sanitizedWord) || upperCaseSet.has(sanitizedLowerCase)
        ? lowerCase.toUpperCase()
        : capitalize(word)
    })
    .join(' ')
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLikeActive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6a3 3 0 1 1 6 0v2h3.039a3 3 0 0 1 2.944 3.573l-1.197 6.988A3 3 0 0 1 17.839 21H9a1 1 0 0 1-1-1V9.81a1 1 0 0 1 .622-.926C9.398 8.568 10 7.467 10 6ZM5 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3 1 1 0 0 0 1-1V10a1 1 0 0 0-1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLikeActive)

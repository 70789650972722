export const removeTriggerFromURL = (triggerName: string) => {
  if (!triggerName || typeof window === 'undefined') {
    return
  }

  const url = new URL(window.location.href)
  const hasTriggerParameter = url.searchParams.has(triggerName)

  if (!hasTriggerParameter) {
    return
  }

  url.searchParams.delete(triggerName)
  window.history.replaceState({}, '', url.toString())
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './list.module.scss'
import ListItem from './list-item'

export type ListElement = HTMLUListElement

export interface ListProps extends HTMLAttributes<ListElement> {
  children: ReactNode
  unstyled?: boolean
  asChild?: boolean
  className?: string
}

const ListRoot = forwardRef<ListElement, ListProps>(function List(props, forwardedRef) {
  const { className, children, asChild, unstyled = true, ...rest } = props

  const classes = classNames(
    {
      [styles.unstyled]: unstyled,
    },
    className
  )

  const Component = asChild ? Slot : 'ul'

  return (
    <Component {...rest} className={classes || undefined} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export const List = assignSubComponents('List', ListRoot, {
  Item: ListItem,
})

export * from './list-item'

export const STEPS = {
  IDLE: 'IDLE',
  GENERATING_SUMMARY: 'GENERATING_SUMMARY',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  SUMMARY_VIEW: 'SUMMARY_VIEW',
} as const

export type LawsuitSummarizationStep = keyof typeof STEPS

export const DEFAULT_DELAY = 1500
export const INITIAL_STEP: LawsuitSummarizationStep = STEPS.IDLE

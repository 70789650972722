import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { Badge, BadgeProps } from '../badge/badge'
import { Chip, ChipProps } from '../chip/chip'
import { IconProps } from '../icon/types'
import styles from './tabs-trigger-trailing.module.scss'

export type TabsTriggerTrailingElement = HTMLSpanElement

export interface TabsTriggerTrailingProps extends HTMLAttributes<TabsTriggerTrailingElement> {
  children: ReactNode
}

const TabsTriggerTrailing = forwardRef<TabsTriggerTrailingElement, TabsTriggerTrailingProps>(
  function TabsTriggerTrailing(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)

    function renderChildren() {
      if (isValidElement<BadgeProps>(children) && children.type === Badge) {
        return cloneElement(children, {
          type: 'tertiary',
          variant: 'label',
        })
      }
      if (isValidElement<ChipProps>(children) && children.type === Chip) {
        return cloneElement(children, {
          kind: 'secondary',
          size: 'sm',
        })
      }
      if (isValidElement<IconProps>(children)) {
        return cloneElement(children, {
          size: 'sm',
        })
      }
      return children
    }

    return (
      <span {...rest} className={classes} ref={forwardedRef}>
        {renderChildren()}
      </span>
    )
  }
)

export default TabsTriggerTrailing

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNotificationOn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 3.799a1 1 0 1 0-1.2-1.6 11.035 11.035 0 0 0-3.484 4.4 1 1 0 1 0 1.832.801A9.036 9.036 0 0 1 6.601 3.8Zm12-1.6a1 1 0 1 0-1.2 1.6A9.035 9.035 0 0 1 20.251 7.4a1 1 0 0 0 1.832-.8 11.035 11.035 0 0 0-3.483-4.4ZM5 11a7.002 7.002 0 0 1 6-6.93V3a1 1 0 1 1 2 0v1.07c3.392.486 6 3.404 6 6.93v3.664c0 .348.103.688.296.977l.536.804A1 1 0 0 1 19 18H5a1 1 0 0 1-.832-1.555l.536-.804c.193-.29.296-.63.296-.977V11Zm8 11a1 1 0 1 0 0-2h-2a1 1 0 0 0 0 2h2ZM12 6a5 5 0 0 0-5 5v3.664c0 .459-.084.911-.245 1.336h10.49A3.76 3.76 0 0 1 17 14.664V11a5 5 0 0 0-5-5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNotificationOn)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgVolumeMute(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5a1 1 0 0 0-1.707-.707L6.586 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2.586l3.707 3.707A1 1 0 0 0 12 19V5ZM7.707 9.707 10 7.414v9.172l-2.293-2.293A1 1 0 0 0 7 14H4v-4h3a1 1 0 0 0 .707-.293Zm8-1.414a1 1 0 1 0-1.414 1.414L16.586 12l-2.293 2.293a1 1 0 0 0 1.414 1.414L18 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L19.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L18 10.586l-2.293-2.293Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgVolumeMute)

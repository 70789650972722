import * as RDialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { Overlay } from '../overlay/overlay'
import commonStyles from './common.module.scss'
import styles from './drawer-content.module.scss'
import { useDrawer } from './use-drawer'

export type DrawerContentElement = HTMLDivElement
export type DrawerContentProps = RDialog.DialogContentProps

const DrawerContent = forwardRef<DrawerContentElement, DrawerContentProps>(function DrawerContent(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { isMount, placement, size } = useDrawer()

  const classes = classNames(
    styles.root,
    commonStyles.content,
    {
      [styles.rootVisible]: isMount,
      [styles[`placement${placement}`]]: placement,
      [styles[`size${size}`]]: size,
    },
    className
  )

  return (
    <RDialog.Portal>
      <RDialog.Overlay asChild>
        <Overlay visible={isMount} />
      </RDialog.Overlay>

      <RDialog.Content
        aria-describedby={undefined}
        {...rest}
        className={classes}
        ref={forwardedRef}
      >
        {children}
      </RDialog.Content>
    </RDialog.Portal>
  )
})

export default DrawerContent

import { createContext } from 'react'

import { TopbarContextType } from '../types'

export type TopbarNextContextType = {
  // context used by UI
  ctx: TopbarContextType
  // determines if the context is configured properly
  provider?: boolean
  hoc?: boolean
}

export const TopbarNextContext = createContext<TopbarNextContextType>({ ctx: {} })

export function assertTopbarNextContext(ctx: TopbarNextContextType): void {
  // don't run in production
  if (process.env.NODE_ENV !== 'development') return

  if (!ctx.provider) {
    throw new Error(
      'Topbar must be used within a <Topbar.Provider>. See the README for more details.'
    )
  }

  if (!ctx.hoc) {
    throw new Error('Topbar must be used withTopbarProps. See the README for more details.')
  }
}

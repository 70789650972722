import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react'

import { Slot, SxProps, useSxProp } from '../utilities'
import { allowedSxProps } from '../utilities/sx/config'

export type BoxElement = HTMLDivElement
export interface BoxProps extends SxProps, Omit<HTMLAttributes<BoxElement>, keyof SxProps> {
  children?: ReactNode
  className?: string
  asChild?: boolean
}

export const Box_unstable = forwardRef<BoxElement, BoxProps>(function Box(props, forwardedRef) {
  const { asChild, children, className, ...rest } = props

  const { boxProps, sxProps } = useMemo(() => {
    const sxProps: Record<string, unknown> = {}
    const boxProps: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(rest)) {
      if (allowedSxProps.includes(key)) {
        sxProps[key] = value
      } else {
        boxProps[key] = value
      }
    }
    return { boxProps, sxProps }
  }, [rest])

  const withSxProps = useSxProp({ sx: sxProps })

  const Component = asChild ? Slot : 'div'
  const classes = classNames(withSxProps.className, className)

  return (
    <Component {...boxProps} {...withSxProps} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export * from './flex'
export * from './spacer'

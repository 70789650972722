import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgWrite(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.992 4.527 2.744 2.745a2 2 0 0 0-2.744-2.745Zm-1.8-1.113a4 4 0 1 1 5.657 5.657l-.364.364a1 1 0 0 1-1.414 0L14.83 5.192a1 1 0 0 1 0-1.414l.364-.364Zm-2.878 2.879a1 1 0 0 1 1.414 0l4.243 4.243c.041.041.078.085.111.132l1.21 1.21a3 3 0 0 1 0 4.243l-2.585 2.586a1 1 0 0 1-1.414-1.414l2.586-2.586a1 1 0 0 0 0-1.414l-.626-.626-7.768 7.768c-1.3 1.3-2.99 1.695-4.254 1.793a9.753 9.753 0 0 1-2.102-.07 5.938 5.938 0 0 1-.141-.024l-.042-.007-.013-.003H2.93l-.002-.001s-.002 0 .195-.98l-.981.195v-.003l-.002-.005-.002-.013-.008-.041a8.406 8.406 0 0 1-.081-.629 9.756 9.756 0 0 1-.012-1.614c.097-1.265.493-2.955 1.793-4.255l8.485-8.485ZM2.14 21.338c.079.395.389.706.784.785l.197-.98-.981.195Zm1.882-1.097a7.71 7.71 0 0 0 1.055-.008c1.033-.079 2.172-.39 2.993-1.212l7.778-7.778-2.828-2.829-7.778 7.778c-.822.822-1.133 1.96-1.213 2.994-.03.397-.025.76-.007 1.055Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgWrite)

import { Dropdown, DropdownProps, DropdownTriggerProps } from '@farol-ds/react'
import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  PointerEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import { __identifyRadixDropdownPortal } from './utils'

export type LineScrollDropdownGuardProps = {
  children: ReactNode
}

export const LineScrollDropdownGuard: React.FC<LineScrollDropdownGuardProps> = (props) => {
  const { children } = props
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen?.((prev) => !prev)

  useEffect(() => {
    __identifyRadixDropdownPortal(open)
  }, [open])

  const mapInnerChildren = (node: ReactNode) => {
    return Children.map(node, (child) => {
      const isValidChild = Children.only(child) && isValidElement<DropdownTriggerProps>(child)
      const isDropdownTrigger = isValidChild && child.type === Dropdown.Trigger
      if (isDropdownTrigger) {
        return cloneElement(child, {
          ...child.props,
          // TODO: fix this after radix-ui bug has being solved. @gabrielrtakeda
          // ref: https://github.com/radix-ui/primitives/issues/1912
          onPointerDown: (event: PointerEvent<HTMLButtonElement>) => {
            // covered by e2e tests due to this `testing-library` bug:
            // ref: https://github.com/testing-library/user-event/issues/1119
            event.preventDefault()

            // keep preventing radix-ui dropdown-menu pointerdown event but call
            // line-scroll `setOpen` to open the dropdown properly for testing purposes.
            if (process.env.NODE_ENV === 'test') toggle()

            child.props.onPointerDown?.(event)
          },
          // switch mouse event from `pointerdown` to `click` to workaround this issue.
          // ref: https://github.com/radix-ui/primitives/issues/1912
          onClick: (event: MouseEvent<HTMLButtonElement>) => {
            toggle()
            child.props.onClick?.(event)
          },
        })
      }
      return child
    })
  }

  const isValidChild = Children.only(children) && isValidElement<DropdownProps>(children)
  const isDropdown = isValidChild && children.type === Dropdown
  if (isDropdown) {
    return Children.map(children, (child) =>
      cloneElement(
        child,
        {
          ...child.props,
          open,
          onOpenChange: (nextOpen: boolean) => {
            setOpen(nextOpen)
            child.props.onOpenChange?.(nextOpen)
          },
        },
        mapInnerChildren(child.props.children)
      )
    )
  }
  return children
}

import type { ToastProviderProps as RToastProviderProps } from '@radix-ui/react-toast'
import * as RToast from '@radix-ui/react-toast'
import { FC, useMemo, useRef } from 'react'

import { ToastContext, ToastContextType } from './context'
import { ToastStore, ToastStoreElement, ToastStoreProps } from './toast-store'
import { ToastViewport, ToastViewportElement, ToastViewportProps } from './toast-viewport'
import { ToastProviderPlacement } from './types'

export const defaultToastProviderProps = {} satisfies Omit<ToastProviderProps, 'children'>

export interface ToastProviderProps extends RToastProviderProps {
  placement?: ToastProviderPlacement
  viewportProps?: Partial<ToastViewportProps>
  storeProps?: Partial<ToastStoreProps>
}

export const ToastProvider: FC<ToastProviderProps> = (props) => {
  const { placement, viewportProps, storeProps, children } = props
  const storeRef = useRef<ToastStoreElement>(null)
  const viewportRef = useRef<ToastViewportElement>(null)

  const context: ToastContextType = useMemo(
    () =>
      (...args) =>
        storeRef.current?.(...args),
    [storeRef]
  )

  return (
    <ToastContext.Provider value={context}>
      <RToast.Provider swipeDirection="right" label="Notificação" {...props}>
        {children}
        <ToastStore {...storeProps} ref={storeRef} viewportRef={viewportRef} />
        <ToastViewport {...viewportProps} placement={placement} ref={viewportRef} />
      </RToast.Provider>
    </ToastContext.Provider>
  )
}

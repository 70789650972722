export function sanitizeDataLayerInput(input: Record<string, unknown>): Record<string, unknown> {
  return Object.entries(input).reduce((accumulator: Record<string, unknown>, [key, value]) => {
    if (value !== undefined) {
      return {
        ...accumulator,
        [key]: value,
      }
    }
    return accumulator
  }, {})
}

import { gql, MutationFunction, useMutation } from '@apollo/client'
import { Banner, Button, Heading, List, Modal } from '@farol-ds/react'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'

import { useUserOnboarding } from '../../hooks'
import { LawsuitOccupationModal_ViewerFragment } from './__generated__/lawsuit-occupation-modal.graphql'
import styles from './lawsuit-occupation-modal.module.scss'

export const UPDATE_OCCUPATION_MUTATION = gql`
  mutation UpdateOccupation($input: EditProfileInput!) {
    editProfile(input: $input) {
      profile {
        id
        profileType
        receiveLeadsDigest
        hasDeclaredOccupation
        occupation {
          entityId
        }
      }
    }
  }
`

export const LAWYER_TOPIC_ID = 291485
export const BACHELOR_IN_LAW_TOPIC_ID = 293507
export const LAW_STUDENT_TOPIC_ID = 296089

export interface LawsuitOccupationModalProps {
  viewer: LawsuitOccupationModal_ViewerFragment | null
}

interface Option {
  title: string
  occupation: string
  metricProperties: { topic_id?: number; topic_title: string }
  mutationChanges: {
    tipoPerfil?: string
    idProfissao?: number
    profissaoDeclarada?: boolean
    receberLegalCaseDigest?: boolean
  }
}

const OPTION_LIST: Option[] = [
  {
    title: 'Advogado',
    occupation: 'lawyer',
    metricProperties: { topic_id: LAWYER_TOPIC_ID, topic_title: 'Advogado' },
    mutationChanges: {
      tipoPerfil: 'ADVOGADO',
      idProfissao: LAWYER_TOPIC_ID,
      profissaoDeclarada: true,
    },
  },
  {
    title: 'Estudante de Direito',
    occupation: 'lawStudent',
    metricProperties: { topic_id: LAW_STUDENT_TOPIC_ID, topic_title: 'Estudante de Direito' },
    mutationChanges: {
      tipoPerfil: 'PESSOAL',
      idProfissao: LAW_STUDENT_TOPIC_ID,
      profissaoDeclarada: true,
      receberLegalCaseDigest: false,
    },
  },
  {
    title: 'Bacharel em Direito',
    occupation: 'bachelor',
    metricProperties: { topic_id: BACHELOR_IN_LAW_TOPIC_ID, topic_title: 'Bacharel em Direito' },
    mutationChanges: {
      tipoPerfil: 'PESSOAL',
      idProfissao: BACHELOR_IN_LAW_TOPIC_ID,
      profissaoDeclarada: true,
      receberLegalCaseDigest: true,
    },
  },
  {
    title: 'Outra profissão',
    occupation: 'other',
    metricProperties: { topic_title: 'Não informou profissão' },
    mutationChanges: {
      profissaoDeclarada: true,
    },
  },
]

const handleOptionClick = async (
  pid: string,
  { mutationChanges, metricProperties }: Option,
  sendOccupation: MutationFunction
) => {
  try {
    await sendOccupation({
      variables: {
        input: {
          pid,
          changes: mutationChanges,
        },
      },
    })

    sendMetrics('Profile.OccupationChanged', pid, metricProperties)
  } catch (error) {
    console.error(error)
  }
}

export function LawsuitOccupationModal({ viewer }: LawsuitOccupationModalProps) {
  const [sendOccupation, { error }] = useMutation(UPDATE_OCCUPATION_MUTATION)

  return (
    <Modal size="md" open>
      <Modal.Content>
        <Modal.Header>
          <Modal.HeaderTitle asChild>
            <Heading size="md">Qual a sua ocupação?</Heading>
          </Modal.HeaderTitle>
          <Modal.HeaderDescription>Mantenha seu perfil atualizado.</Modal.HeaderDescription>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Banner type="critical" className={styles.error}>
              <Banner.IconClose />
              <Banner.Content>
                <Banner.Title>Algo deu errado, tente novamente!</Banner.Title>
              </Banner.Content>
            </Banner>
          )}
          <List className={styles.list}>
            {OPTION_LIST.map((option, i) => (
              <List.Item className={styles.listItem} key={i}>
                <Button
                  kind="tertiary"
                  size="md"
                  block
                  onClick={() => handleOptionClick(`${viewer?.pid}`, option, sendOccupation)}
                >
                  {option.title}
                </Button>
              </List.Item>
            ))}
          </List>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

LawsuitOccupationModal.fragments = {
  viewer: gql`
    fragment LawsuitOccupationModal_viewer on Profile {
      pid
      ...useUserOnboarding_viewer
    }
    ${useUserOnboarding.fragments.viewer}
  `,
}

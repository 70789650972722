import { BodyText } from '@farol-ds/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { useTimeout } from '../../hooks/use-timeout'
import {
  FINISH_STEP,
  HIDE_MESSAGE_DELAY,
  INITIAL_STEP,
  OTHER_REASON,
  REASONS,
  SELECTED_CHIP_DELAY,
  Step,
} from './constants'
import { CommentStep, ComprehensionStep, ReasonStep, steps } from './steps'
import styles from './summarization-survey.module.scss'

export type SurveyData = {
  comprehension?: string
  reason?: string
  comment?: string
}

export type LawsuitSummarizationSurveyProps = {
  initialStep?: Step
  className?: string
  onStepChange?: (step: Step) => void
  onFinish?: (data: SurveyData) => void
  onSendEvent: (event: any) => void
  isMobile?: boolean
}

export const LawsuitSummarizationSurvey = ({
  className,
  initialStep,
  onStepChange,
  onFinish,
  onSendEvent,
  isMobile,
}: LawsuitSummarizationSurveyProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<Step>(initialStep ?? INITIAL_STEP)
  const [surveyData, setSurveyData] = useState<SurveyData>({})
  const [startVisibleTimeout] = useTimeout()
  const [startTransitionTimeout] = useTimeout()

  const getShuffledReasons = () => {
    return [...REASONS].sort(() => Math.random() - 0.5)
  }

  const updateSurveyData = (data: SurveyData) => {
    const newData = { ...surveyData, ...data }
    setSurveyData(newData)
  }

  const handleStepTransition = (nextStep: Step) => {
    startTransitionTimeout(() => setCurrentStep(nextStep), SELECTED_CHIP_DELAY)
    onStepChange?.(nextStep)
  }

  const handleOptionClick = (field: keyof SurveyData, value: string) => {
    updateSurveyData({ [field]: value })
    handleStepTransition(steps[currentStep].nextStep(value) as Step)
  }

  const handleSubmitComment = (comment: string) => {
    updateSurveyData({ comment })
    handleStepTransition(steps[currentStep].nextStep() as Step)
  }

  useEffect(() => {
    if (currentStep === FINISH_STEP && !isFinished) {
      setIsFinished(true)

      if (onFinish !== undefined) {
        onFinish(surveyData)
      }
      startVisibleTimeout(() => setIsVisible(false), HIDE_MESSAGE_DELAY)
      return
    }
    setIsVisible(true)
  }, [currentStep, startVisibleTimeout, isFinished, onFinish, setIsFinished, surveyData])

  if (!isVisible) {
    return null
  }
  return (
    <div className={classNames(styles.survey, className)}>
      <BodyText className={styles.title} size="md" asChild>
        <strong>{steps[currentStep].title}</strong>
      </BodyText>
      {currentStep === Step.COMPREHENSION && (
        <ComprehensionStep
          options={steps[currentStep].options}
          selectedOption={surveyData.comprehension}
          onOptionClick={(value) => {
            handleOptionClick('comprehension', value)
            onSendEvent('Lawsuit.LawsuitSummarizationSurveyComprehensionClick')
          }}
        />
      )}
      {currentStep === Step.REASON && (
        <ReasonStep
          options={[...getShuffledReasons(), OTHER_REASON]}
          selectedOption={surveyData.reason}
          onOptionClick={(value) => {
            handleOptionClick('reason', value)
            onSendEvent('Lawsuit.LawsuitSummarizationSurveyReasonClick')
          }}
        />
      )}
      {currentStep === Step.OTHER_REASON && (
        <CommentStep
          isMobile={isMobile}
          placeholder={steps[currentStep].placeholder}
          onSubmit={handleSubmitComment}
        />
      )}
    </div>
  )
}

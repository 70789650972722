import { BodyText, Button, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect, useState } from 'react'

import successImgSrc from '../../../public/identity-validation-modal/success.svg'
import { EventData } from '../../../utils'
import styles from './validate-identity-success.module.scss'

interface ValidateIdentitySuccessProps {
  title: string
  bodyText: string
  eventProps: {
    viewerPid: number
  } & EventData
  onContinue: (setLoading: (isLoading: boolean) => void) => void
}

export function ValidateIdentitySuccessStep(props: ValidateIdentitySuccessProps) {
  const [loading, setLoading] = useState(false)

  const { title, bodyText, onContinue, eventProps } = props

  useEffect(() => {
    const { viewerPid, ...eventData } = eventProps
    sendMetrics('UserAccount.IdValidationSuccessViewed', viewerPid.toString(), eventData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal.Body className={styles.body} data-testid="validate-identity-success-step">
        <Image alt={title} src={successImgSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {title}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          {bodyText}
        </BodyText>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button kind="primary" loading={loading} onClick={() => onContinue(setLoading)}>
          Continuar
        </Button>
      </Modal.Footer>
    </>
  )
}

export function sample<K>(alt: string | null, items: readonly K[]): K {
  if (!alt) {
    const idx = Math.floor(Math.random() * items.length)
    return items[idx]
  }
  let s = 0
  for (const l of alt.toLowerCase()) {
    s += l.charCodeAt(0)
  }
  const idx = s % items.length
  return items[idx]
}

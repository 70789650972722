import { IncomingMessage } from 'http'
import { Cookie } from 'universal-cookie'

import { buildCookiesContext } from './build-cookies-context'

/**
 *
 * @param request If you are running this function in the server side, send the request object. If you are using the function in the client side, use null.
 * @returns All existing cookies
 */
export const getAllCookies = <K = Record<string, Cookie>>(
  request: IncomingMessage | null = null
): K => {
  const cookies = buildCookiesContext({ request })

  return cookies.getAll<K>()
}

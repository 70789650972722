import { getFirstQueryParam } from '@jusbrasil-web/shared-utils-url'
import { GetServerSidePropsContext } from 'next'
import { DocumentContext } from 'next/document'

import {
  ABTestConvert,
  ABTestConvertResponse,
  ABTestExperiment,
  ABTestParticipate,
  ABTestParticipateResponse,
  ABTestParticipation,
  ABTestParticipationResponse,
} from '../types'
import { ABTestClient } from './ab-test'

export const UNGIVEN_FORCED_GROUP = 'ungiven-forced-group'
export const UNGIVEN_FORCED_ALTERNATIVE = 'ungiven-forced-alternative'
export const UNGIVEN_FORCED_EXPERIMENT = 'ungiven-forced-experiment'

const isProduction = process.env.NODE_ENV === 'production'
const ARGOS_EXTERNAL_URL = 'https://endpoints.jusbrasil.com.br/argos'

export const ARGOS_ENDPOINT = process.env.ARGOS_ENDPOINT || ARGOS_EXTERNAL_URL
export const ARGOS_TIMEOUT = process.env.ARGOS_TIMEOUT || '1000'

const serverAbTestClient = new ABTestClient(ARGOS_ENDPOINT, parseInt(ARGOS_TIMEOUT))
const browserAbTestClient = new ABTestClient('', parseInt(ARGOS_TIMEOUT))

export type DocumentContextWithAB = DocumentContext & {
  req?: {
    cookies: Record<string, string>
  }
}

type WithExperimentResultContext =
  | Pick<GetServerSidePropsContext, 'req' | 'query'>
  | Pick<DocumentContextWithAB, 'req' | 'query'>

type ForcedExperimentProps = {
  forcedExperiment?: ABTestExperiment
  cookie: string
  userAgent: string
  jdid?: string
}

const getForcedExperiment = (ctx: WithExperimentResultContext): ABTestExperiment | undefined => {
  if (ctx.query.experimentGroup && ctx.query.alternative && ctx.query.experiment) {
    return {
      experimentGroup: getFirstQueryParam(ctx.query.experimentGroup),
      alternative: getFirstQueryParam(ctx.query.alternative),
      experiment: getFirstQueryParam(ctx.query.experiment),
      participating: true,
    }
  }

  if (!ctx.req) return undefined

  if (ctx.req.cookies && ctx.req.cookies.feature_force_experiment === 'true') {
    return {
      participating: true,
      experimentGroup: ctx.req.cookies.feature_force_experiment_group || UNGIVEN_FORCED_GROUP,
      alternative: ctx.req.cookies.feature_force_experiment_variation || UNGIVEN_FORCED_ALTERNATIVE,
      experiment: ctx.req.cookies.feature_force_experiment_name || UNGIVEN_FORCED_EXPERIMENT,
    }
  }

  return undefined
}

const getForcedExperimentProps = (ctx: WithExperimentResultContext): ForcedExperimentProps => {
  const forcedExperiment = getForcedExperiment(ctx)
  const cookie = ctx.req?.headers.cookie || ''
  const userAgent = ctx.req?.headers['user-agent'] || ''
  const jdid = ctx.req?.cookies?.jdid || undefined
  return { forcedExperiment, cookie, userAgent, jdid }
}

export function withABTestExperiment(
  ctx: WithExperimentResultContext,
  experimentData: ABTestParticipation
): Promise<ABTestParticipationResponse> {
  if (isProduction && ARGOS_ENDPOINT === ARGOS_EXTERNAL_URL)
    console.warn('External Argos URL used in production')

  if (!ctx.req) return Promise.resolve({ participation: [] })

  const { forcedExperiment, cookie, userAgent, jdid } = getForcedExperimentProps(ctx)

  if (!jdid && !forcedExperiment) return Promise.resolve({ participation: [] })

  return serverAbTestClient.participation({ forcedExperiment, cookie, userAgent }, experimentData)
}

const EMPTY_PARTICIPATE_RESULT: ABTestParticipateResponse = {
  alternative: null,
  experiment: null,
  participating: false,
  experimentGroup: null,
} as const

export function withABTestParticipate(
  ctx: WithExperimentResultContext,
  experimentData: ABTestParticipate
): Promise<ABTestParticipateResponse> {
  if (isProduction && ARGOS_ENDPOINT === ARGOS_EXTERNAL_URL)
    console.warn('External Argos URL used in production')

  if (!ctx.req) {
    return Promise.resolve(EMPTY_PARTICIPATE_RESULT)
  }

  const { forcedExperiment, cookie, userAgent, jdid } = getForcedExperimentProps(ctx)

  if (!jdid && !forcedExperiment) {
    return Promise.resolve(EMPTY_PARTICIPATE_RESULT)
  }

  return serverAbTestClient.participate({ forcedExperiment, cookie, userAgent }, experimentData)
}

export function withABTestConvert(experimentData: ABTestConvert): Promise<ABTestConvertResponse> {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Fetching argos convert with', experimentData)
    return Promise.resolve(EMPTY_PARTICIPATE_RESULT)
  }

  return browserAbTestClient.convert(experimentData)
}

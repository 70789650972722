import { useEffect, useRef } from 'react'

export const useTimeout = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  const clear = () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const start = (action: () => void, delay: number) => {
    clear()
    timeoutRef.current = setTimeout(action, delay)
  }

  useEffect(() => () => clear(), [])

  return [start, clear] as const
}

export default useTimeout

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Caption, CaptionElement } from '../caption/caption'
import styles from './checkbox-description.module.scss'
import itemStyles from './checkbox-item.module.scss'

export type CheckboxDescriptionElement = CaptionElement

export interface CheckboxDescriptionProps extends HTMLAttributes<CheckboxDescriptionElement> {
  children: ReactNode
  className?: string
}

const CheckboxDescription = forwardRef<CheckboxDescriptionElement, CheckboxDescriptionProps>(
  function CheckboxDescription(props, forwardedRef) {
    const { className, children, ...rest } = props

    const classes = classNames(styles.root, itemStyles.itemDescription, className)

    return (
      <Caption ref={forwardedRef} {...rest} className={classes} asChild>
        <span>{children}</span>
      </Caption>
    )
  }
)

export default CheckboxDescription

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7Zm3-1a1 1 0 0 0-1 1v1h16V7a1 1 0 0 0-1-1H5ZM4 17v-7h16v7a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm13-1a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2h1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCard)

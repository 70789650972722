import classNames from 'classnames'
import { forwardRef } from 'react'

import { Heading, HeadingElement, HeadingProps } from '../heading/heading'
import { renderTitleChildren } from './shared-helpers'
import sharedStyles from './shared-styles.module.scss'

export type SnippetTitleElement = HeadingElement

export type SnippetTitleProps = HeadingProps

const SnippetTitle = forwardRef<SnippetTitleElement, SnippetTitleProps>(function SnippetTitle(
  props,
  forwardedRef
) {
  const { children: _, className, ...rest } = props

  const classes = classNames(sharedStyles.title, className)

  return (
    <Heading {...rest} className={classes} size="sm" ref={forwardedRef}>
      {renderTitleChildren(props)}
    </Heading>
  )
})

export default SnippetTitle

import classNames from 'classnames'
import { forwardRef } from 'react'

import { Heading, HeadingProps } from '../heading/heading'
import styles from './drawer-header-title.module.scss'

export type DrawerHeaderTitleElement = HTMLDivElement

export type DrawerHeaderTitleProps = HeadingProps & {
  containerClassName?: string
}

const DrawerHeaderTitle = forwardRef<DrawerHeaderTitleElement, DrawerHeaderTitleProps>(
  function DrawerHeaderTitle(props, forwardedRef) {
    const { containerClassName, ...rest } = props

    const containerClasses = classNames(styles.root, containerClassName)

    return (
      <div className={containerClasses} ref={forwardedRef}>
        <Heading {...rest} size="md" />
      </div>
    )
  }
)

export default DrawerHeaderTitle

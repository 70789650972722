import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEye(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.98 17.75c-2.946 0-6.019-1.825-7.885-5.759 1.904-4.199 5.004-6.116 7.925-6.116 2.92 0 6.006 1.915 7.885 6.111-1.892 3.937-4.979 5.764-7.925 5.764Zm9.762-6.556c-2.122-4.757-5.832-7.32-9.722-7.319-3.888 0-7.61 2.56-9.758 7.315a1.954 1.954 0 0 0 .013 1.632c2.137 4.524 5.85 6.928 9.705 6.928 3.853 0 7.578-2.403 9.74-6.925a1.954 1.954 0 0 0 .022-1.631ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEye)

import * as RContextMenu from '@radix-ui/react-context-menu'
import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './context-menu-content.module.scss'

export type ContextMenuContentElement = HTMLDivElement

export type ContextMenuContentProps = RContextMenu.ContextMenuContentProps

const ContextMenuContent = forwardRef<ContextMenuContentElement, ContextMenuContentProps>(
  function ContextMenuContent(props, forwardedRef) {
    const { children, className, ...rest } = props

    const classes = classNames(styles.root, className)

    return (
      <RContextMenu.Portal>
        <RContextMenu.Content {...rest} className={classes} ref={forwardedRef}>
          {children}
        </RContextMenu.Content>
      </RContextMenu.Portal>
    )
  }
)

export default ContextMenuContent

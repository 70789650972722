import { useContext } from 'react'

import { MenuItemContext } from './context'
import type { MenuItemProps } from './menu-item'

export type UseMenuItemProviderProps = MenuItemProps

export interface UseMenuItemProviderReturn {
  current: boolean | undefined
  block: boolean | undefined
}

export function useMenuItemProvider(props: UseMenuItemProviderProps): UseMenuItemProviderReturn {
  const { current: currentProp, type, block } = props
  // disable "current" prop for "danger" type
  const current = type !== 'danger' ? currentProp : false
  return { current, block }
}

export function useMenuItem() {
  const context = useContext(MenuItemContext)
  return context
}

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, ReactNode } from 'react'

import { IconProps, isValidIconElement } from '../icon/icon'
import styles from './menu-item-leading.module.scss'

export type MenuItemLeadingElement = HTMLSpanElement

export interface MenuItemLeadingProps extends HTMLAttributes<MenuItemLeadingElement> {
  children: ReactNode
}

const iconProps: Partial<IconProps> = {
  size: 'sm',
}

const MenuItemLeading = forwardRef<MenuItemLeadingElement, MenuItemLeadingProps>(
  function MenuItemLeading(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)

    const isIcon = isValidIconElement(children)

    return (
      <span {...rest} className={classes} ref={forwardedRef}>
        {isIcon ? cloneElement(children, iconProps) : children}
      </span>
    )
  }
)

export default MenuItemLeading

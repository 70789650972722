import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.02 5.172a4 4 0 0 0-5.656 0 1 1 0 1 0 1.414 1.414 2 2 0 1 1 2.829 2.828L14.07 12.95a2 2 0 0 1-2.828 0 1 1 0 1 0-1.415 1.414 4 4 0 0 0 5.657 0l3.536-3.536a4 4 0 0 0 0-5.656ZM4.173 18.607a4 4 0 0 0 5.656 0 1 1 0 1 0-1.414-1.414 2 2 0 1 1-2.828-2.829L9.12 10.83a2 2 0 0 1 2.829 0 1 1 0 1 0 1.414-1.415 4 4 0 0 0-5.657 0L4.172 12.95a4 4 0 0 0 0 5.657Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLink)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFeed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v1H7V5Zm0 3h14v11a1 1 0 0 1-1 1H6.83c.11-.313.17-.65.17-1V8ZM5 5a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V7a2 2 0 0 1 2-2h2Zm0 2H3v12a1 1 0 1 0 2 0V7Zm5 7a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Zm6.293 3.707A1 1 0 0 1 16 17v-2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-1a1 1 0 0 1-.707-.293ZM10 10a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFeed)

interface HotjarObject {
  (method: string, ...data: unknown[]): void
  q?: unknown[]
}

// https://help.hotjar.com/hc/en-us/articles/4405109971095
export function hotjarEvent(method: string, ...data: unknown[]): void {
  if (window === undefined) return
  if (window.hj === undefined) {
    const init = () => {
      const q: unknown[] = []
      const hj = (...args: unknown[]) => {
        q.push(args)
      }
      hj.q = q
      return hj
    }
    window.hj = init()
  }
  window.hj?.(method, ...data)
}

declare global {
  interface Window {
    hj?: HotjarObject
  }
}

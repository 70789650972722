import { GetFieldType } from './types'

export function getValue<TData, TPath extends string, TDefault = GetFieldType<TData, TPath>>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce<GetFieldType<TData, TPath>>((value, key) => (value as any)?.[key], data as any)

  return value !== undefined ? value : (defaultValue as TDefault)
}

/* istanbul ignore file */

import {
  Banner,
  Brand,
  Chip,
  ChipClickable,
  Col,
  Container,
  Detail,
  Flex_unstable as Flex, // [FIXME] @andersonba
  Heading,
  Link,
  LinkIcon,
  Menu,
  MenuItem,
  NewTabIcon,
  Row,
  Separator,
  Subtitle,
  Tooltip,
} from '@farol-ds/react'
import { Fragment } from 'react'

export type DevIndexPages = Record<string, string>

export type DevIndexMetadata = Record<string, string>

export type DevIndexShorcuts = Record<string, string>

export type DevIndexBannerWidget = {
  title: string
  description: string
}

export type DevIndexPagesWidget = {
  title: string
}

export type DevIndexMetadataWidget = {
  title: string
}

export type DevIndexShorcutsWidget = {
  title: string
}

export type DevIndexPageProps = {
  title: string
  pages?: DevIndexPages
  subtitle?: string
  metadata?: DevIndexMetadata
  shortcuts?: DevIndexShorcuts
  children?: React.ReactNode
  pagesWidget?: DevIndexPagesWidget
  bannerWidget?: DevIndexBannerWidget
  metadataWidget?: DevIndexMetadataWidget
  shortcutsWidget?: DevIndexShorcutsWidget
  className?: string
}

export const defaultPages: DevIndexPages = {}

export const defaultMetadata: DevIndexMetadata = {}

export const defaultBannerWidget: DevIndexBannerWidget = {
  title: 'Welcome to Index',
  description: `Navigate through the application's pages and resources`,
} as const

export const defaultPagesWidget: DevIndexPagesWidget = {
  title: 'Pages',
}

export const defaultMetadataWidget: DevIndexMetadataWidget = {
  title: 'Information',
}

export const defaultShortcutsWidget: DevIndexShorcutsWidget = {
  title: 'Shortcuts',
}

export const defaultShortcuts: DevIndexShorcuts = {
  'Farol Storybook': 'https://go.jusbr.com/farol/storybook',
  'Team Storybook': 'https://go.jusbr.com/farol/team-storybook',
  'Monorepo Wiki': 'https://go.jusbr.com/web/wiki',
  '#team-dev-x': 'https://go.jusbr.com/slack/dev-x',
}

export function DevIndexPage(props: DevIndexPageProps) {
  const {
    title,
    subtitle,
    children,
    className,
    shortcuts = defaultShortcuts,
    pages = defaultPages,
    metadata = defaultMetadata,
    pagesWidget = defaultPagesWidget,
    bannerWidget = defaultBannerWidget,
    metadataWidget = defaultMetadataWidget,
    shortcutsWidget = defaultShortcutsWidget,
  } = props

  return (
    <Container type="letter" className={className} asChild>
      <Flex direction="column" gap="xs" paddingY="xs">
        <Row>
          <Col xs={12}>
            <Flex direction={['column', 'row']} align="center" gap={[null, '2xs']}>
              <Flex grow={1} direction={['column', 'row']} align="center" gap={[null, '2xs']}>
                <Flex gap="4xs">
                  <Brand onlySymbol width={32} />
                  <Heading size="2xl">{title}</Heading>
                </Flex>
                <Subtitle style={{ textAlign: 'center' }}>{subtitle}</Subtitle>
              </Flex>
              <Tooltip label="Restricted to Development mode only">
                <button>
                  <Chip kind="negative" size="sm">
                    Development only
                  </Chip>
                </button>
              </Tooltip>
            </Flex>
          </Col>
        </Row>

        {bannerWidget && (
          <Row>
            <Col xs={12}>
              <Banner type="discovery">
                <Banner.Content>
                  <Banner.Title>{bannerWidget.title}</Banner.Title>
                  <Banner.Description>{bannerWidget.description}</Banner.Description>
                </Banner.Content>
              </Banner>
            </Col>
          </Row>
        )}

        {pagesWidget && Object.keys(pages).length > 0 && (
          <Row asChild>
            <Flex gap={['xs', null, 0]}>
              <Col xs={12}>
                <Flex direction="column" gap="4xs">
                  <Heading size="md">{pagesWidget.title}</Heading>
                  <Menu>
                    {Object.entries(pages).map(([path, title], idx) => (
                      <Fragment key={idx}>
                        <MenuItem key={path} asChild>
                          <Link href={path} target="_blank">
                            <MenuItem.Content>
                              {title}
                              <MenuItem.Trailing>
                                <NewTabIcon />
                              </MenuItem.Trailing>
                            </MenuItem.Content>
                            <MenuItem.HelpText>{path}</MenuItem.HelpText>
                          </Link>
                        </MenuItem>
                        {idx < Object.keys(pages).length - 1 && <Separator key={`${idx}-sep`} />}
                      </Fragment>
                    ))}
                  </Menu>
                </Flex>
              </Col>
            </Flex>
          </Row>
        )}

        {children}

        {metadataWidget && Object.keys(metadata).length > 0 && (
          <Row>
            <Col xs={12}>
              <Flex direction="column" gap="4xs">
                <Heading size="md">{metadataWidget.title}</Heading>
                {Object.entries(metadata).map(([label, value]) => (
                  <Detail inline key={label}>
                    <Detail.Label>{label}</Detail.Label>
                    <Detail.Value>{value}</Detail.Value>
                  </Detail>
                ))}
              </Flex>
            </Col>
          </Row>
        )}

        {shortcutsWidget && Object.keys(shortcuts).length > 0 && (
          <Row>
            <Col xs={12}>
              <Flex direction="column" gap="4xs">
                <Heading size="md">{shortcutsWidget.title}</Heading>
                <Flex gap="4xs" wrap="wrap">
                  {Object.entries(shortcuts).map(([label, path]) => (
                    <ChipClickable key={path} size="md" leftIcon={<LinkIcon />} asChild>
                      <a href={path} target="_blank" rel="noreferrer">
                        {label}
                      </a>
                    </ChipClickable>
                  ))}
                </Flex>
              </Flex>
            </Col>
          </Row>
        )}
      </Flex>
    </Container>
  )
}

import { gql } from '@apollo/client'
import { Chip } from '@farol-ds/react'

import { LawsuitProgressChip_LawsuitFragment } from './__generated__/lawsuit-progress-chip.graphql'
import { LawsuitProgressStatus } from './enum'
import { getLawsuitProgressStatus } from './get-lawsuit-progress-status'

interface LawsuitProgressChipProps {
  lawsuit: LawsuitProgressChip_LawsuitFragment
  className?: string
  thirdPartySearch?: boolean
}

export function LawsuitProgressChip({
  lawsuit,
  className,
  thirdPartySearch,
}: LawsuitProgressChipProps) {
  const { situation, inferredStatus, lastProviderUpdate, lastActivityDate, distributionDate } =
    lawsuit
  const progress = getLawsuitProgressStatus(
    situation?.normalized || inferredStatus,
    lastProviderUpdate,
    lastActivityDate,
    distributionDate
  )
  const isClosed = progress === LawsuitProgressStatus.CLOSED
  const closedKind = thirdPartySearch ? 'secondary' : 'tertiary'

  if (!progress) return null

  return (
    <Chip
      kind={isClosed ? closedKind : 'warning'}
      size="sm"
      data-testid="lawsuit-progress-chip"
      className={className}
    >
      {progress}
    </Chip>
  )
}

LawsuitProgressChip.fragments = {
  lawsuit: gql`
    fragment LawsuitProgressChip_lawsuit on CRMLawsuit {
      situation {
        normalized
      }
      inferredStatus
      lastProviderUpdate
      lastActivityDate
      distributionDate
    }
  `,
}

import classNames from 'classnames'
import { AnchorHTMLAttributes, forwardRef, ReactNode } from 'react'

import { ensurePropOf, Slot } from '../utilities'
import styles from './link.module.scss'

export type LinkElement = HTMLAnchorElement
export const LinkTypes = ['primary', 'secondary', 'tertiary', 'ghost'] as const
export type LinkType = (typeof LinkTypes)[number]

export interface LinkProps extends AnchorHTMLAttributes<LinkElement> {
  children: ReactNode
  type?: LinkType
  className?: string
  asChild?: boolean
}

export const Link = forwardRef<LinkElement, LinkProps>(function Link(props, forwardedRef) {
  const { className, type: typeProp, children, asChild, ...rest } = props

  const type = ensurePropOf<LinkType>(LinkTypes, typeProp, 'secondary')

  const Component = asChild ? Slot : 'a'

  const classes = classNames(
    styles.root,
    {
      [styles[`type${type}`]]: !!type,
    },
    className
  )

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

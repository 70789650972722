import { composeRefs } from '@radix-ui/react-compose-refs'
import { Ref, useCallback, useContext, useId, useRef, useState } from 'react'

import { FormControlContext } from './context'
import type {
  FormControlDescriptionElement,
  FormControlDescriptionProps,
  FormControlErrorMessageElement,
  FormControlErrorMessageProps,
  FormControlLabelElement,
  FormControlLabelProps,
  FormControlProps,
} from './form-control'

export type UseFormControlProps = Partial<{
  labelProps: Partial<FormControlLabelProps>
  descriptionProps: Partial<FormControlDescriptionProps>
  errorMessageProps: Partial<FormControlErrorMessageProps>
}>

export interface UseFormControlProviderReturn {
  id: string
  labelId: string
  errorId: string
  descriptionId: string
  error?: boolean
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  labelElement?: FormControlLabelElement
  descriptionElement?: FormControlDescriptionElement
  errorMessageElement?: FormControlErrorMessageElement
  getFieldProps(): Partial<{
    id: string
    required: boolean
    disabled: boolean
    readOnly: boolean
    'aria-describedby': string
  }>
  getLabelProps(ref: Ref<FormControlLabelElement>): Partial<FormControlLabelProps>
  getDescriptionProps(ref: Ref<FormControlDescriptionElement>): Partial<FormControlDescriptionProps>
  getErrorMessageProps(
    ref: Ref<FormControlErrorMessageElement>
  ): Partial<FormControlErrorMessageProps>
  setComponentProps(props: UseFormControlProps): void
}

export function useFormControlProvider(
  providerProps: Partial<FormControlProps>
): UseFormControlProviderReturn {
  const { id: idProp, required, error, disabled, readOnly } = providerProps

  const [labelEl, setLabelEl] = useState<FormControlLabelElement | null>(null)
  const [descriptionEl, setDescriptionEl] = useState<FormControlDescriptionElement | null>(null)
  const [errorMessageEl, setErrorMessageEl] = useState<FormControlErrorMessageElement | null>(null)
  const componentPropsRef = useRef<UseFormControlProps>({})

  const uuid = useId()
  const id = idProp || uuid
  const labelId = `${id}-label`
  const descriptionId = `${id}-description`
  const errorId = `${id}-error`

  /* callbacks: components props getter */

  const getFieldProps = useCallback<UseFormControlProviderReturn['getFieldProps']>(() => {
    const ids: string[] = []
    if (labelEl) {
      ids.push(labelId || '')
    }
    if (descriptionEl) {
      ids.push(descriptionId || '')
    }
    if (errorMessageEl) {
      ids.push(errorId || '')
    }
    return {
      id,
      required,
      disabled,
      readOnly,
      'aria-invalid': error || undefined,
      'aria-describedby': ids.join(' ') || undefined,
    }
  }, [
    labelEl,
    descriptionEl,
    errorMessageEl,
    labelId,
    descriptionId,
    errorId,
    readOnly,
    required,
    disabled,
    id,
  ])

  const getLabelProps = useCallback<UseFormControlProviderReturn['getLabelProps']>(
    (ref) => {
      return {
        ...componentPropsRef.current.labelProps,
        id: labelId,
        htmlFor: id,
        ref: composeRefs(ref, setLabelEl),
      }
    },
    [labelId, id, setLabelEl]
  )

  const getDescriptionProps = useCallback<UseFormControlProviderReturn['getDescriptionProps']>(
    (ref) => {
      return {
        ...componentPropsRef.current.descriptionProps,
        id: descriptionId,
        ref: composeRefs(ref, setDescriptionEl),
      }
    },
    [descriptionId, setDescriptionEl]
  )

  const getErrorMessageProps = useCallback<UseFormControlProviderReturn['getErrorMessageProps']>(
    (ref) => {
      return {
        ...componentPropsRef.current.errorMessageProps,
        id: errorId,
        'aria-live': 'polite',
        ref: composeRefs(ref, setErrorMessageEl),
      }
    },
    [errorId, setErrorMessageEl]
  )

  return {
    id,
    labelId,
    descriptionId,
    errorId,
    required,
    error,
    disabled,
    readOnly,
    getFieldProps,
    getLabelProps,
    getDescriptionProps,
    getErrorMessageProps,
    setComponentProps(props) {
      componentPropsRef.current = props
    },
    labelElement: labelEl || undefined,
    descriptionElement: descriptionEl || undefined,
    errorMessageElement: errorMessageEl || undefined,
  }
}

export function useFormControl(props?: UseFormControlProps) {
  const context = useContext(FormControlContext)
  if (props) {
    context.setComponentProps?.(props)
  }
  return context
}

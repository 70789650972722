import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNewDocument(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.607 4.828a2 2 0 1 1 2.828 2.829l-6.364 6.364c-.822.822-1.96 1.133-2.993 1.212-.398.031-.761.026-1.055.008a7.724 7.724 0 0 1 .007-1.055c.08-1.033.39-2.172 1.213-2.994l6.364-6.364ZM7.14 16.338l.98-.196-.196.98a1.001 1.001 0 0 1-.784-.784Zm0-.003v.003l.98-.196c-.196.98-.195.981-.194.981h.002l.004.001.013.003.042.007.14.023a9.752 9.752 0 0 0 2.103.07c1.265-.097 2.955-.492 4.254-1.792l6.364-6.364a4 4 0 0 0-5.657-5.657L8.828 9.778c-1.3 1.3-1.695 2.99-1.792 4.255a9.756 9.756 0 0 0 .07 2.102l.023.14.008.042.002.013.001.005ZM4 9a1 1 0 0 1 1-1h2a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2a1 1 0 1 0-2 0v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNewDocument)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10 1a1 1 0 0 1-1-1V8a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1Zm-1 3a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgWarning)

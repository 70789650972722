import { colorBaseHigh2, colorBrandTertiaryPure, spacing3xs, spacing6xs } from '@farol-ds/tokens'
import * as RPopover from '@radix-ui/react-popover'
import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './popover-content.module.scss'
import { usePopover } from './use-popover'

export type PopoverContentElement = HTMLDivElement

export type PopoverContentProps = RPopover.PopoverContentProps & {
  disablePadding?: boolean
}

const PopoverContent = forwardRef<PopoverContentElement, PopoverContentProps>(
  function PopoverContent(props, forwardedRef) {
    const { children, className, disablePadding = false, ...rest } = props
    const { placement, type } = usePopover()

    const [side = 'top', align = 'center'] = placement.split('-') as [
      RPopover.PopoverContentProps['side'],
      RPopover.PopoverContentProps['align']
    ]

    const classes = classNames(
      styles.root,
      {
        [styles[`type${type}`]]: !!type,
        [styles.disablePadding]: disablePadding,
      },
      className
    )

    return (
      <RPopover.Portal>
        <RPopover.Content
          {...rest}
          collisionPadding={spacing3xs}
          sideOffset={spacing6xs}
          side={side}
          align={align}
          className={classes}
          ref={forwardedRef}
        >
          {children}
          <RPopover.Arrow width={16} height={8} className={styles.arrow} asChild>
            <svg viewBox="0 0 30 10" preserveAspectRatio="none">
              <polygon
                points="0,-1.5 30,-1.5 15,8.5"
                stroke={(type === 'highlight' && colorBrandTertiaryPure) || colorBaseHigh2}
                strokeWidth="1.5"
                strokeLinejoin="round"
                fill="currentColor"
              />
            </svg>
          </RPopover.Arrow>
        </RPopover.Content>
      </RPopover.Portal>
    )
  }
)

export default PopoverContent

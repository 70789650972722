import { Drawer, DrawerElement } from '@farol-ds/react'
import {
  attachBrowserBackButtonListener,
  cleanupHistoryState,
} from '@jusbrasil-web/shared-browser-back-button-listener'
import { forwardRef, useContext, useState } from 'react'

import { TopbarContext } from '../../context'
import { TopbarUserMenuProps } from '../../types'
import { sendUnifiedClickEvent } from '../../utils'
import { TopbarUserMenuContent } from './topbar-user-menu-content'
import { TopbarUserMenuTrigger } from './topbar-user-menu-trigger'
import { TopbarUserMenuUnlogged } from './topbar-user-menu-unlogged'

export type TopbarUserMenuElement = DrawerElement

export const TopbarUserMenu = forwardRef<TopbarUserMenuElement, TopbarUserMenuProps>(
  function TopbarUserMenu(props, forwardedRef) {
    const { mountMenuItems, ...rest } = props

    const [open, setOpen] = useState<boolean>(false)

    const ctx = useContext(TopbarContext)
    const { user } = ctx

    const handleOpen = (opened: boolean) => {
      setOpen(opened)
      const stateName = 'userMenuModalOpen'

      if (opened) {
        sendUnifiedClickEvent('Menu do usuário', user?.pid)
        attachBrowserBackButtonListener(stateName, () => {
          setOpen(false)
        })
      } else {
        cleanupHistoryState(stateName)
      }
    }

    return (
      <>
        <Drawer {...rest} ref={forwardedRef} open={open} onOpenChange={handleOpen}>
          <TopbarUserMenuTrigger />
          <TopbarUserMenuContent mountMenuItems={mountMenuItems} />
        </Drawer>
        <TopbarUserMenuUnlogged />
      </>
    )
  }
)

import { useContext } from 'react'

import { ensurePropOf } from '../utilities'
import { PopoverContext } from './context'
import {
  PopoverPlacement,
  PopoverPlacements,
  PopoverProps,
  PopoverType,
  PopoverTypes,
} from './types'

export type UsePopoverProviderProps = PopoverProps

export interface UsePopoverProviderReturn {
  type: PopoverType
  placement: PopoverPlacement
}

export function usePopoverProvider(props: UsePopoverProviderProps): UsePopoverProviderReturn {
  const { type: typeProp, placement: placementProp } = props
  const type = ensurePropOf<PopoverType>(PopoverTypes, typeProp, 'light')
  const placement = ensurePropOf<PopoverPlacement>(PopoverPlacements, placementProp, 'top')
  return { type, placement }
}

export function usePopover() {
  const context = useContext(PopoverContext)
  return context
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLaptop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3a2 2 0 0 0-2 2v12a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a2 2 0 0 0-2-2H4Zm0 2h16v11H4V5ZM2 19a1 1 0 1 0 0 2h20a1 1 0 1 0 0-2H2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLaptop)

import classNames from 'classnames'
import { forwardRef, Ref } from 'react'

import {
  Autocomplete,
  AutocompleteMenuElement,
  AutocompleteMenuProps,
  AutocompleteOption,
} from '../autocomplete/autocomplete'
import { useFormControl } from '../form-control/use-form-control'
import type { SearchAutocompleteSize, SearchAutocompleteType } from './search-autocomplete'
import styles from './search-autocomplete-menu.module.scss'
import SearchAutocompleteMenuErrorMessage from './search-autocomplete-menu-error-message'

type AutocompleteMenuPropsWithRoot<T extends AutocompleteOption> = AutocompleteMenuProps<T> & {
  type?: SearchAutocompleteType
  size?: SearchAutocompleteSize
}

export type SearchAutocompleteMenuElement<T extends AutocompleteOption = AutocompleteOption> =
  AutocompleteMenuElement<T>
export type SearchAutocompleteMenuProps<T extends AutocompleteOption = AutocompleteOption> =
  AutocompleteMenuPropsWithRoot<T>

function SearchAutocompleteMenuInner<T extends AutocompleteOption>(
  props: SearchAutocompleteMenuProps<T>,
  forwardedRef: Ref<SearchAutocompleteMenuElement<T>>
) {
  const { type, size, listProps, ...rest } = props

  const formControl = useFormControl()
  const listClassName = classNames(listProps?.className, {
    [styles.typePrimary]: type === 'primary',
    [styles.sizeLg]: size === 'lg',
  })

  const { errorMessageElement } = formControl

  return (
    <Autocomplete.Menu
      {...rest}
      listProps={{
        ...listProps,
        className: listClassName,
      }}
      ref={forwardedRef}
    >
      {formControl.error && errorMessageElement ? (
        <SearchAutocompleteMenuErrorMessage node={errorMessageElement} />
      ) : undefined}
    </Autocomplete.Menu>
  )
}

const SearchAutocompleteMenu = forwardRef(SearchAutocompleteMenuInner) as <
  K extends AutocompleteOption
>(
  props: SearchAutocompleteMenuProps<K> & {
    ref?: React.ForwardedRef<SearchAutocompleteMenuElement<K>>
  }
) => ReturnType<typeof SearchAutocompleteMenuInner>

export default SearchAutocompleteMenu

import type { RadioGroupIndicatorProps as RRadioGroupIndicatorProps } from '@radix-ui/react-radio-group'
import * as RRadioGroup from '@radix-ui/react-radio-group'
import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './radio-indicator.module.scss'

export type RadioIndicatorElement = HTMLSpanElement

export type RadioIndicatorProps = RRadioGroupIndicatorProps

const RadioIndicator = forwardRef<RadioIndicatorElement, RadioIndicatorProps>(
  function RadioIndicator(props, forwardedRef) {
    const { className, children, ...rest } = props

    const classes = classNames(styles.root, className)

    return <RRadioGroup.Indicator {...rest} className={classes} ref={forwardedRef} />
  }
)

export default RadioIndicator

import { SVGProps } from 'react'

export const BookCoverMask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={600}
    height={900}
    viewBox="0 0 600 900"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="url(#b)" fillOpacity={0.5} d="M36-95h32v1127H36z" />
      <g filter="url(#c)">
        <path fill="#000" fillOpacity={0.2} d="M8-95h28v1127H8z" />
      </g>
      <g filter="url(#d)">
        <path fill="#fff" fillOpacity={0.2} d="M0-95h7v1127H0z" />
      </g>
    </g>
    <defs>
      <filter
        id="c"
        width={40}
        height={1139}
        x={2}
        y={-101}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_579_24149" stdDeviation={3} />
      </filter>
      <filter
        id="d"
        width={11}
        height={1131}
        x={-2}
        y={-97}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_579_24149" stdDeviation={1} />
      </filter>
      <linearGradient id="b" x1={36} x2={68} y1={536} y2={536} gradientUnits="userSpaceOnUse">
        <stop offset={0.135} stopColor="#fff" />
        <stop offset={0.901} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h600v900H0z" />
      </clipPath>
    </defs>
  </svg>
)

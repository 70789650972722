import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgQuotes(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6a4 4 0 0 0-4 4c0 1.088.2 2.258.54 3.308.37 1.148.954 2.03 1.542 2.637.293.303.597.548.891.725.26.156.63.33 1.027.33a1 1 0 0 0 1-1v-2.126A4.002 4.002 0 0 0 7 6Zm10 0a4 4 0 0 0-4 4c0 1.088.2 2.258.54 3.308.37 1.148.954 2.03 1.542 2.637.293.303.597.548.891.725.26.156.63.33 1.027.33a1 1 0 0 0 1-1v-2.126A4.002 4.002 0 0 0 17 6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgQuotes)

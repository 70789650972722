import {
  TooltipProvider as RTooltipProvider,
  TooltipProviderProps as RTooltipProviderProps,
} from '@radix-ui/react-tooltip'

export const defaultTooltipProviderProps = {
  delayDuration: 500,
} satisfies Omit<TooltipProviderProps, 'children'>

export type TooltipProviderProps = RTooltipProviderProps

export const TooltipProvider = RTooltipProvider

import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'
import { INPUT_METHOD } from '../constants/taxonomy'

export interface GA4InputStartEventProps {
  featureClass: string
  featureName?: string | null
  inputMethod: INPUT_METHOD
}

export class GA4InputStartEvent extends GA4Event {
  ga4_feature_class: GA4InputStartEventProps['featureClass']
  ga4_feature_name: GA4InputStartEventProps['featureName']
  ga4_input_method: GA4InputStartEventProps['inputMethod']

  constructor({ featureClass, featureName = null, inputMethod }: GA4InputStartEventProps) {
    super({ event: EVENT.INPUT_START })

    this.ga4_feature_class = featureClass
    this.ga4_feature_name = featureName
    this.ga4_input_method = inputMethod
  }
}

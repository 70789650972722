import { BodyText, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useEffect, useState } from 'react'

import { useGetProfileByCPF, useValidateCPF } from '../../../hooks'
import validatingImgSrc from '../../../public/identity-validation-modal/validation.svg'
import { useModalContext } from '../context'
import { Steps } from '../enums'
import styles from './steps.module.scss'

interface ValidatingIdentityProps {
  validateCallback?: (isValidatedSuccess: boolean) => void
}

export function ValidatingIdentity(props: ValidatingIdentityProps) {
  const { validateCallback } = props
  const [loading, setLoading] = useState(false)
  const { setCurrentStep, sharedData, setSharedData, viewer, eventData } = useModalContext()
  const validateCPF = useValidateCPF()
  const getProfileByCPF = useGetProfileByCPF()
  const pid = viewer?.pid.toString() || null

  useEffect(() => {
    sendMetrics('UserAccount.ValidatingProfileViewed', pid, {
      ...eventData,
      metadata: {
        ...eventData?.metadata,
        cpf: sharedData?.cpf,
        birthDate: formatDate(sharedData?.birthDate?.getTime() || ''),
      },
    })
  }, [sharedData?.cpf, sharedData?.birthDate, pid, eventData])

  useEffect(() => {
    if (!loading && sharedData.cpf && sharedData.birthDate) {
      setLoading(true)
      validateCPF({ cpf: sharedData.cpf, birthDate: sharedData.birthDate })
        .then(() => {
          if (validateCallback) validateCallback(true)
          setCurrentStep(Steps.IDENTITY_SUCCESS)
        })
        .catch(async (error) => {
          const cpfInUse = error?.graphQLErrors?.some(
            (e: { info: { formattedKey: string } }) => e.info.formattedKey === 'CPFInUse'
          )

          if (cpfInUse && sharedData.cpf) {
            const response = await getProfileByCPF(sharedData.cpf)
            const profile = response?.data?.root?.profileByValidatedCpf

            if (profile) {
              const profileInUse = {
                pid: profile.pid,
                email: profile.user?.obfuscatedEmail,
              }
              setSharedData({ ...sharedData, profileInUse })
              return setCurrentStep(Steps.CPF_IN_USE)
            }

            console.error('Error getting current profile using the cpf')
          }

          setCurrentStep(Steps.IDENTITY_ERROR)
        })
    }
  }, [
    sharedData,
    setCurrentStep,
    validateCPF,
    loading,
    getProfileByCPF,
    setSharedData,
    validateCallback,
  ])

  return (
    <>
      <Modal.Body className={styles.body} data-testid="validating-identity-step">
        <Image
          alt="Verificando identidade..."
          src={validatingImgSrc}
          height={200}
          width={200}
          className={styles.image}
        />
        <Heading size="lg" className={styles.title}>
          Verificando identidade...
        </Heading>
        <BodyText size="lg" className={styles.text}>
          Aguarde enquanto validamos as informações
        </BodyText>
      </Modal.Body>
    </>
  )
}

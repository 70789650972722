export enum SearchPageError {
  lawsuit_not_found = 'lawsuit_not_found',
  name_not_found = 'name_not_found',
  cpf_not_found = 'cpf_not_found',
  lawsuit_unavailable = 'lawsuit_unavailable',
  unfinished_search = 'unfinished_search',
}

export const CPF_REGEX = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/
export const CNJ_YEAR_REGEX = new RegExp(/\.\d{4}\./)
export const BEGIN_YEAR = 1901
export const CURRENT_YEAR = new Date().getFullYear()
export const UTM_SOURCE = 'bounce-lawsuit-search'
export const CPF_PATTERN = '000.000.000-00'
export const CNJ_PATTERN = '0000000-00.0000.0.00.0000'
export const BASE_URL = 'https://www.jusbrasil.com.br'
export const SEARCH_URL = 'https://www.jusbrasil.com.br/consulta-processual/busca'
export const AUTOCOMPLETE_THRESHOLD = 3
export const BOUNCE_SOURCE_TYPE = 'topic'
export const PLACEHOLDER_TEXT = 'Digite um CPF, nome ou número'

export const ERROR_MESSAGES = {
  EMPTY_QUERY: 'Digite um CPF, nome ou número',
  INVALID_CNJ_NUMBER: 'Número inválido. Revise a numeração ou busque por um nome',
  INVALID_CPF_NUMBER: 'CPF inválido. Revise a numeração ou busque por um nome',
}

export const ERROR_TYPES = {
  INVALID_DATE: 'INVALID_DATE',
  INVALID_CPF: 'INVALID_CPF',
}

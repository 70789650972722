import { Option } from './types'

export const ALL_VALUE = ''
export const ALL_NODE_ID = '1'

export const mapOptions = (options: { label: string; value: string }[]): Option[] =>
  options.map((option, index) => {
    return { ...option, nodeId: `${ALL_NODE_ID}.${index}` }
  })

export const getOptionValuesEnum = (options: Option[]): Map<string, string> => {
  const optionValueEnum = new Map<string, string>()
  // default `all` items wrapper initial value support.
  optionValueEnum.set(ALL_VALUE, ALL_NODE_ID)
  optionValueEnum.set(ALL_NODE_ID, ALL_VALUE)
  options.map((option, index) => {
    optionValueEnum.set(option.value, `${ALL_NODE_ID}.${index}`)
    optionValueEnum.set(`${ALL_NODE_ID}.${index}`, option.value)
  })

  return optionValueEnum
}
export const getOptionValues = (options: Option[]) =>
  options.reduce(
    (prev: string[], option) => [...prev, option.value],
    [ALL_VALUE] // initial `all` option value.
  )

const uniq = (arg: string[]) => arg.filter((val, idx, all) => all.indexOf(val) === idx)

//                                                        depth: 0 1 2 ...
// TODO: [refactor] add support for more than the depth 2 — i.e. 0.0.0 — @gabrielrtakeda
export const calculateTreeviewIndeterminatesInDepth2 = (values: string[], rootNodeId: string) => {
  if (values.includes(rootNodeId) || !values.length) return []

  const groups = values.filter((v) => /^\d+\.\d$/.test(v))
  const childs = values.filter((v) => /^\d+\.\d+\.\d$/.test(v))
  const orphans = childs.filter((z) => !groups.some((g) => z.startsWith(g)))
  const indeterminates = uniq(orphans.map((o) => o.match(/^(\d+\.\d+)\.\d+$/)?.[1] || ''))
  return [rootNodeId, ...indeterminates]
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgEyeOff(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.095 11.991c1.433-3.159 3.55-5.027 5.732-5.755 1.924-.643 3.987-.432 5.85.673l-1.645 1.645a4 4 0 0 0-5.478 5.478L6.85 15.736c-1.052-.917-2.003-2.159-2.755-3.745Zm1.338 5.162c-1.242-1.108-2.321-2.56-3.16-4.335a1.942 1.942 0 0 1-.012-1.626C3.877 7.615 6.377 5.28 9.194 4.34c2.655-.887 5.492-.496 7.933 1.12l1.166-1.166a1 1 0 1 1 1.414 1.414l-14 14a1 1 0 0 1-1.414-1.414l1.14-1.14Zm4.635-4.635 2.45-2.45a2 2 0 0 0-2.45 2.45Zm9.42-3.377a1 1 0 0 1 1.372.347c.318.534.613 1.104.884 1.71.23.518.223 1.112-.022 1.624-2.371 4.961-6.626 7.369-10.841 6.862a1 1 0 0 1 .238-1.986c3.209.386 6.714-1.4 8.786-5.712a14.268 14.268 0 0 0-.763-1.474 1 1 0 0 1 .347-1.371Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgEyeOff)

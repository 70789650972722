import { COMPONENT_ORIGIN_KEY, snowpiercerClient } from '../constants'
import { ComponentOrigin } from '../types'
import { getStorageObjectFromStorage } from './get-storage-object-from-storage'

const runOnBrowser = <T>(fn: () => T, defaultValue: T): T => {
  return typeof window === 'undefined' ? defaultValue : fn()
}

export const componentOriginPromise = runOnBrowser<Promise<ComponentOrigin | null>>(
  () =>
    getStorageObjectFromStorage().then(async (value) => {
      await snowpiercerClient.removeItem(COMPONENT_ORIGIN_KEY)
      return value.componentOrigin || null
    }),
  Promise.resolve(null)
)

import { LawsuitCard_LawsuitFragment } from '../../components/lawsuit-card/__generated__/lawsuit-card.graphql'

export function getLawsuitCourt(
  cnj: LawsuitCard_LawsuitFragment['cnjNumber'],
  restrictLawsuit: LawsuitCard_LawsuitFragment['restrictLawsuit'] = null,
  canViewRestrictLawsuit: LawsuitCard_LawsuitFragment['canViewRestrictLawsuit'] = null
) {
  const acronym = cnj?.court?.acronym
  const district = cnj?.source?.district

  if (restrictLawsuit && !canViewRestrictLawsuit) {
    return district
  }

  return [acronym, district].filter(Boolean).join(' · ')
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Separator } from '../separator/separator'
import styles from './drawer-footer.module.scss'

export type DrawerFooterElement = HTMLDivElement

export interface DrawerFooterProps extends HTMLAttributes<DrawerFooterElement> {
  children: ReactNode
  separatorClassName?: string
}

const DrawerFooter = forwardRef<DrawerFooterElement, DrawerFooterProps>(function DrawerFooter(
  props,
  forwardedRef
) {
  const { className, children, separatorClassName, ...rest } = props
  const classes = classNames(styles.root, className)
  return (
    <>
      <Separator orientation="horizontal" className={separatorClassName} />

      <div {...rest} className={classes} ref={forwardedRef}>
        {children}
      </div>
    </>
  )
})

export default DrawerFooter

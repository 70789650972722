import { RefObject } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

export type UseCarouselReturn = {
  scrollRef: RefObject<HTMLUListElement>
  pages: number[][]
  activePageIndex: number
  refresh: () => void
  prev: () => void
  next: () => void
  goTo: (index: number) => void
  isSnapPoint: (index: number) => boolean
}

export function useCarousel(): UseCarouselReturn {
  const carousel = useSnapCarousel()
  return {
    activePageIndex: carousel.activePageIndex,
    pages: carousel.pages,
    scrollRef: carousel.scrollRef as unknown as UseCarouselReturn['scrollRef'],
    refresh() {
      return carousel.refresh()
    },
    prev() {
      return carousel.prev()
    },
    next() {
      return carousel.next()
    },
    goTo(index: number) {
      return carousel.goTo(index)
    },
    isSnapPoint(index: number) {
      return carousel.snapPointIndexes.has(index)
    },
  }
}

/**
 * Cleanup the browser history state, which was pushed by the attachBrowserBackButtonListener function.
 * @param stateName The name of the state to be pushed to the browser history.
 * @returns void
 */
export function cleanupHistoryState(stateName: string): void {
  const hasPushedState = window.history.state?.[stateName]
  if (!hasPushedState) {
    return
  }

  window.history.back()
  window.history.replaceState(
    {
      ...window.history.state,
      [stateName]: false,
    },
    ''
  )
}

import classNames from 'classnames'
import { ChangeEvent, forwardRef, TextareaHTMLAttributes } from 'react'

import { useFormControl } from '../form-control/use-form-control'
import styles from './textarea.module.scss'

export type TextareaElement = HTMLTextAreaElement

export interface TextareaProps extends TextareaHTMLAttributes<TextareaElement> {
  onValueChange?: (evt: ChangeEvent<TextareaElement>, value: string) => void
  error?: boolean
  className?: string
}

export const Textarea = forwardRef<TextareaElement, TextareaProps>(function Textarea(
  props,
  forwardedRef
) {
  const { className, error: errorProp, onValueChange, ...rest } = props

  const formControl = useFormControl()

  const hasError = errorProp ?? formControl.error

  const classes = classNames(
    styles.root,
    {
      [styles.error]: hasError,
    },
    className
  )

  return (
    <textarea
      data-testid="textarea"
      aria-invalid={hasError}
      onChange={(evt) => {
        onValueChange?.(evt, evt.target.value)
      }}
      {...formControl.getFieldProps?.()}
      {...rest}
      className={classes}
      ref={forwardedRef}
    />
  )
})

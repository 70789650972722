import { useEffect, useRef } from 'react'

export interface UseKeyPressOptions {
  event?: 'keyup' | 'keydown'
}

export type UseKeyPressReturn<Element> = {
  ref: React.RefObject<Element>
}

export const useKeyPress = <Element extends HTMLElement>(
  keys: readonly string[],
  callback: (event: KeyboardEvent) => void,
  options?: UseKeyPressOptions
): UseKeyPressReturn<Element> => {
  const { event: eventType = 'keydown' } = options || {}

  const isAny = keys.length === 0
  const ref = useRef<Element>(null)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleKeyPress = (event: KeyboardEvent) => {
      if (isAny || keys.includes(event.key)) {
        callback(event)
      }
    }

    element.addEventListener(eventType, handleKeyPress)
    return () => {
      element.removeEventListener(eventType, handleKeyPress)
    }
  }, [keys])

  return { ref }
}

import { breakpointMd } from '@farol-ds/tokens'
import { composeRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import { Autocomplete, AutocompleteRootElement } from '../autocomplete/autocomplete'
import { Overlay } from '../overlay/overlay'
import { ensurePropOf, Slot } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import styles from './search-autocomplete.module.scss'
import SearchAutocompleteMenu, { SearchAutocompleteMenuProps } from './search-autocomplete-menu'
import SearchAutocompleteTrigger, {
  SearchAutocompleteTriggerProps,
} from './search-autocomplete-trigger'

export type SearchAutocompleteRootElement = HTMLDivElement

export type SearchAutocompleteElement = {
  close: () => void
}

export const SearchAutocompleteTypes = ['primary', 'secondary'] as const
export type SearchAutocompleteType = (typeof SearchAutocompleteTypes)[number]

export const SearchAutocompleteSizes = ['md', 'lg'] as const
export type SearchAutocompleteSize = (typeof SearchAutocompleteSizes)[number]

export interface SearchAutocompleteProps extends HTMLAttributes<SearchAutocompleteRootElement> {
  children: ReactNode
  className?: string
  type?: SearchAutocompleteType
  size?: SearchAutocompleteSize
  onFocusedChange?: (focused: boolean) => void
  rootRef?: React.Ref<SearchAutocompleteRootElement>
}

function detectIsTargetFromMenu(target: EventTarget | null) {
  if (target instanceof HTMLElement) {
    return !!target.closest('[role="listbox"]')
  }
  return false
}

const SearchAutocompleteRoot = forwardRef<SearchAutocompleteElement, SearchAutocompleteProps>(
  function SearchAutocomplete(props, forwardedRef) {
    const {
      className,
      type: typeProp,
      size: sizeProp,
      children,
      onFocusedChange,
      rootRef,
      ...rest
    } = props

    // `undefined` means that's not initialized/interacted
    const [focused, setFocused] = useState<boolean | undefined>()

    const type = ensurePropOf<SearchAutocompleteType>(SearchAutocompleteTypes, typeProp, 'primary')
    const size = ensurePropOf<SearchAutocompleteSize>(SearchAutocompleteSizes, sizeProp, 'md')

    const scrollYRef = useRef<number | undefined>()
    const ref = useRef<SearchAutocompleteRootElement>(null)
    const backIconRef = useRef<HTMLButtonElement>(null)
    const autocompleteRef = useRef<AutocompleteRootElement>(null)

    const classes = classNames(
      styles.root,
      {
        [styles.typePrimary]: type === 'primary',
      },
      className
    )

    useImperativeHandle(forwardedRef, () => ({
      close() {
        setFocused(false)
        autocompleteRef.current?.trigger?.input?.blur()
      },
    }))

    useEffect(() => {
      if (typeof focused === 'boolean') {
        onFocusedChange?.(focused)
        autocompleteRef.current?.menu?.setOpen(focused)
      }
    }, [focused, onFocusedChange])

    /*
     * Auto-fix scroll position when the search field is focused but not visible on the viewport.
     * This is a workaround for the iOS Safari bug that causes the keyboard push the content up.
     * @andersonba
     */
    useEffect(() => {
      const shouldBeFixed = type === 'primary' && window.innerWidth <= breakpointMd
      if (!shouldBeFixed) return

      let time: NodeJS.Timeout
      let retries = 5

      function detectColision() {
        const inputFromTop = autocompleteRef.current?.trigger?.input?.getBoundingClientRect().top
        const isNegative = inputFromTop !== undefined && inputFromTop < 0
        if (isNegative) {
          window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
          retries = 0
        } else {
          retries--
        }
        if (retries <= 0) {
          clearInterval(time)
        }
      }

      if (focused) {
        scrollYRef.current = window.scrollY
        time = setInterval(detectColision, 30)
      } else if (!focused && scrollYRef.current !== undefined) {
        window.scrollTo({ top: scrollYRef.current, left: 0, behavior: 'instant' })
        scrollYRef.current = undefined
      } else {
        scrollYRef.current = undefined
      }

      return () => clearInterval(time)
    }, [type, focused])

    return (
      <RemoveScroll enabled={focused ?? false} allowPinchZoom as={Slot}>
        <div
          {...rest}
          className={classes}
          data-focused={focused ? '' : undefined}
          ref={composeRefs(ref, rootRef)}
        >
          <Autocomplete className={styles.autocompleteRoot} ref={autocompleteRef}>
            {Children.map(children, (child) => {
              if (!isValidElement(child)) return null

              if (child.type === SearchAutocompleteTrigger) {
                return cloneElement(child, {
                  type,
                  size,
                  defaultOpenOnFocus: true,
                  defaultCloseOnBlur: false,
                  onFocus() {
                    setFocused(true)
                  },
                  onBlur(evt) {
                    // do not close if the focus is going to the menu
                    const isTargetMenu = detectIsTargetFromMenu(evt.relatedTarget)
                    if (isTargetMenu && ref.current?.contains(evt.relatedTarget)) return

                    // do not close if the focus is going to the back icon (mobile devices have a back button)
                    const backIconIsVisible = !!backIconRef.current?.offsetParent
                    if (backIconIsVisible) return

                    setFocused(false)
                  },
                  onBackClick(evt) {
                    child.props.onBackClick?.(evt)
                    setFocused(false)
                  },
                  backIconRef,
                  containerClassName: classNames(styles.trigger, child.props.containerClassName),
                  __leftIconClassName: styles.triggerLeftIcon,
                } as SearchAutocompleteTriggerProps)
              }

              if (child.type === SearchAutocompleteMenu) {
                return cloneElement(child, {
                  type,
                  size,
                  onOptionSelect(option) {
                    setFocused(false)
                    child.props.onOptionSelect?.(option)
                  },
                  listProps: {
                    ...child.props.listProps,
                    onTouchStart(evt) {
                      child.props.listProps?.onTouchStart?.()
                      if (detectIsTargetFromMenu(evt.target)) return
                      autocompleteRef.current?.trigger?.input?.blur()
                    },
                  },
                } as Partial<SearchAutocompleteMenuProps>)
              }

              return child
            })}
          </Autocomplete>

          {type === 'primary' && (
            <Overlay disableEnterAnimation visible={focused === true} className={styles.overlay} />
          )}
        </div>
      </RemoveScroll>
    )
  }
)

export const SearchAutocomplete = assignSubComponents(
  'SearchAutocomplete',
  SearchAutocompleteRoot,
  {
    Trigger: SearchAutocompleteTrigger,
    Menu: SearchAutocompleteMenu,
  }
)

export * from './search-autocomplete-menu'
export * from './search-autocomplete-trigger'

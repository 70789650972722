import { useCallback, useContext } from 'react'

import { useMountTransition } from '../utilities'
import { ModalContext } from './context'
import type { ModalProps } from './modal'

export type UseModalProviderProps = ModalProps

export interface UseModalProviderReturn {
  size: ModalProps['size']
  open: boolean
  isMount: boolean
  onOpenChange: (open: boolean) => void
}

const transitionTime = 300

export function useModalProvider(props: UseModalProviderProps): UseModalProviderReturn {
  const { size, open: openProp, defaultOpen, onOpenChange: onOpenChangeProp } = props

  const [open, setOpen, isMount] = useMountTransition(transitionTime, defaultOpen, 'backwards', {
    controlledState: openProp,
  })

  const onOpenChange = useCallback<UseModalProviderReturn['onOpenChange']>(
    (nextOpen: boolean) => {
      setOpen(nextOpen)
      onOpenChangeProp?.(nextOpen, {
        waitAnimation(callback) {
          setTimeout(() => callback(), transitionTime)
        },
      })
    },
    [onOpenChangeProp, setOpen]
  )

  return {
    size,
    open,
    isMount,
    onOpenChange,
  }
}

export function useModal() {
  const context = useContext(ModalContext)
  return context
}

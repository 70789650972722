import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import styles from './selection-card-body-content.module.scss'

export type SelectionCardBodyContentElement = HTMLDivElement

export type SelectionCardBodyContentProps = HTMLAttributes<SelectionCardBodyContentElement>

const SelectionCardBodyContent: React.FC<SelectionCardBodyContentProps> = (props) => {
  const { className, children, ...rest } = props
  const classes = classNames(styles.root, className)

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}

export default SelectionCardBodyContent

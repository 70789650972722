import { FC, SVGProps } from 'react'

export const OnlineOfficeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 18.6a2.968 2.968 0 0 1-1.098-4.07l7-12.04A3.011 3.011 0 0 1 11.5 1.4a2.968 2.968 0 0 1 1.098 4.07l-7 12.04A3.011 3.011 0 0 1 1.5 18.6Z"
      fill="#21A9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 18.6a3.012 3.012 0 0 1-4.098-1.09l-7-12.04A2.969 2.969 0 0 1 8.5 1.4a3.011 3.011 0 0 1 4.098 1.09l7 12.04A2.968 2.968 0 0 1 18.5 18.6Z"
      fill="#0091ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.475 2.307c-.02-.029-.038-.058-.059-.087-.036-.048-.075-.095-.113-.14-.026-.032-.05-.064-.078-.094-.034-.037-.07-.072-.106-.108-.035-.035-.07-.071-.107-.105-.03-.027-.062-.052-.094-.078-.045-.038-.091-.077-.14-.113l-.087-.06a3.025 3.025 0 0 0-.164-.105c-.033-.02-.066-.037-.1-.055a2.891 2.891 0 0 0-.16-.081c-.042-.02-.085-.038-.128-.056a2.876 2.876 0 0 0-.577-.172l-.118-.02A2.882 2.882 0 0 0 10 1a2.858 2.858 0 0 0-.444.033l-.118.02a3.108 3.108 0 0 0-.44.12c-.046.016-.091.034-.137.052a2.985 2.985 0 0 0-.288.137l-.1.055c-.056.033-.11.07-.164.106-.029.02-.058.038-.087.06-.049.035-.095.074-.14.112-.032.026-.064.05-.094.078-.037.034-.072.07-.107.105-.036.036-.072.07-.106.108-.027.03-.052.062-.078.093-.038.046-.077.093-.113.141l-.059.087A2.998 2.998 0 0 0 7.402 5.5L10 10l2.598-4.501a2.999 2.999 0 0 0-.123-3.192Z"
      fill="#007BC9"
    />
  </svg>
)

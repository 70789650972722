import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './banner-title.module.scss'
import { useBanner } from './use-banner'

export type BannerTitleElement = HTMLDivElement

export interface BannerTitleProps extends HTMLAttributes<BannerTitleElement> {
  children: ReactNode
  className?: string
  asChild?: boolean
}

const BannerTitle = forwardRef<BannerTitleElement, BannerTitleProps>(function BannerTitle(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const Component = asChild ? Slot : 'p'

  const { level } = useBanner()

  const classes = classNames(
    styles.root,
    {
      [styles[`level${level}`]]: level !== 'standard',
    },
    className
  )

  return (
    <Component {...rest} data-fds-banner-title className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default BannerTitle

/* eslint-disable dot-notation */
export const JUSBRASIL_PRODUCTION_BASE_URL = 'https://www.jusbrasil.com.br'

const getWindowOrigin = () => typeof window !== 'undefined' && window?.location?.origin

const getAppEnv = () => process.env['NEXT_PUBLIC_APP_ENV'] || process.env['NODE_ENV']

const getNextPublicJusbrasilBaseURL = () => process.env['NEXT_PUBLIC_JUSBRASIL_BASE_URL']

export const getJusbrasilBaseURL = () => {
  const appEnv = getAppEnv()
  return (
    (appEnv &&
      !['production', 'test'].includes(appEnv) &&
      (getWindowOrigin() || getNextPublicJusbrasilBaseURL())) ||
    JUSBRASIL_PRODUCTION_BASE_URL
  )
}

import { forwardRef } from 'react'

import { CaptionElement, CaptionProps } from '../caption/caption'
import { Slot } from '../utilities'
import { renderCaptionItemChildren } from './shared-helpers'

export type SnippetFootnoteCaptionItemElement = CaptionElement

export type SnippetFootnoteCaptionItemProps = CaptionProps

const SnippetFootnoteCaptionItem = forwardRef<
  SnippetFootnoteCaptionItemElement,
  SnippetFootnoteCaptionItemProps
>(function SnippetFootnoteCaptionItem(props, forwardedRef) {
  const { children: _, asChild, ...rest } = props

  const children = renderCaptionItemChildren(props)

  const Component = asChild ? Slot : 'span'

  return (
    <Component {...rest} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export default SnippetFootnoteCaptionItem

import { removeTriggerFromURL } from './remove-trigger-from-url'

export interface HandleTriggerExecutionProps {
  triggerName: string
  onCallback?: () => void
  removeTrigger?: boolean
}

export function handleTriggerExecution({
  triggerName,
  onCallback,
  removeTrigger = true,
}: HandleTriggerExecutionProps): void {
  if (typeof window === 'undefined') return

  const queryParams = new URLSearchParams(window.location.search)
  const hasTrigger = queryParams.has(triggerName)

  if (!hasTrigger) return
  if (onCallback) onCallback()
  if (removeTrigger) removeTriggerFromURL(triggerName)
}

type ReturnWithGenericRequired<K, Y> = K extends never ? never : Y

// [TODO] warn the user with a message in devtools (check "warning" npm package). @andersonba

export function ensurePropOf<P = never, T = NonNullable<P>>(
  acceptable: readonly T[],
  value: T | undefined,
  defaultValue: T
): ReturnWithGenericRequired<P, T> {
  const isValid = value && acceptable.includes(value)
  const fallback = acceptable.includes(defaultValue) ? defaultValue : undefined
  return (isValid ? value : fallback) as ReturnWithGenericRequired<P, T>
}

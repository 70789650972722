import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgBag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.84 4a2 2 0 0 0-2 2v1h4V6a2 2 0 0 0-2-2Zm4 3V6a4 4 0 1 0-8 0v1H5.692a3 3 0 0 0-2.99 2.77l-.693 9A3 3 0 0 0 5 22h13.68a3 3 0 0 0 2.992-3.23l-.692-9A3 3 0 0 0 17.988 7H15.84Zm-2 2v3a1 1 0 1 0 2 0V9h2.148a1 1 0 0 1 .997.923l.693 9A1 1 0 0 1 18.68 20H5a1 1 0 0 1-.998-1.077l.692-9A1 1 0 0 1 5.692 9H7.84v3a1 1 0 1 0 2 0V9h4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgBag)

import { NavigationExperimentVariants } from '../../next/experiments/experiments'
import { TopbarContextType } from '../../types'
import { TopbarNavItem } from './types'

interface NavLinks {
  home: TopbarNavItem
  consultaProcessual: TopbarNavItem
  jurisprudencia: TopbarNavItem
  doutrina: TopbarNavItem
  artigos: TopbarNavItem
  noticias: TopbarNavItem
  diarios: TopbarNavItem
  pecas: TopbarNavItem
  modelosPecas: TopbarNavItem
  legislacao: TopbarNavItem
  advogados: TopbarNavItem
}

export function mountNavItems(
  isLogged?: boolean,
  flags?: TopbarContextType['flags']
): TopbarNavItem[] {
  const {
    navigationExperimentAlternative: navigationExperimentVariant,
    shouldShowJusIABasedOnCurrentSubscription,
  } = flags || {}

  const pageLinks: NavLinks = {
    home: { title: isLogged ? 'Para você' : 'Home', url: '/', key: 'HOME' },
    consultaProcessual: {
      title: 'Consulta processual',
      url: '/consulta-processual',
      key: 'CONSULTA_PROCESSUAL',
    },
    jurisprudencia: { title: 'Jurisprudência', url: '/jurisprudencia', key: 'JURISPRUDENCIA' },
    doutrina: { title: 'Doutrina', url: '/doutrina', key: 'DOUTRINA' },
    artigos: { title: 'Artigos', url: '/artigos', key: 'ARTIGOS' },
    noticias: { title: 'Notícias', url: '/noticias', key: 'NOTICIAS' },
    diarios: { title: 'Diários', url: '/diarios', key: 'DIARIOS' },
    pecas: { title: 'Peças Processuais', url: '/pecas', key: 'PECAS' },
    modelosPecas: { title: 'Modelos', url: '/modelos-pecas', key: 'MODELOS' },
    legislacao: { title: 'Legislação', url: '/legislacao', key: 'LEGISLACAO' },
    advogados: { title: 'Diretório de Advogados', url: '/advogados', key: 'ADVOGADOS' },
  }

  const navigationExperimentLinksA: TopbarNavItem[] = [
    pageLinks.home,
    {
      title: 'Consulta processual',
      submenu: [
        {
          ...pageLinks.consultaProcessual,
          title: 'Consultar um processo',
        },
        pageLinks.advogados,
      ],
      key: 'CONSULTA_PROCESSUAL',
    },
    {
      title: 'Pesquisa jurídica',
      submenu: [
        pageLinks.jurisprudencia,
        pageLinks.pecas,
        pageLinks.modelosPecas,
        pageLinks.doutrina,
        pageLinks.legislacao,
        pageLinks.diarios,
      ],
      key: 'PESQUISA_JURIDICA',
    },
    {
      title: 'Artigos e Notícias',
      submenu: [pageLinks.artigos, pageLinks.noticias],
      key: 'ARTIGOS_E_NOTICIAS',
    },
  ]
  const navigationExperimentLinksB: TopbarNavItem[] = [
    pageLinks.home,
    pageLinks.consultaProcessual,
    {
      title: 'Pesquisa jurídica',
      submenu: [
        pageLinks.jurisprudencia,
        pageLinks.pecas,
        pageLinks.modelosPecas,
        pageLinks.doutrina,
        pageLinks.legislacao,
        pageLinks.artigos,
        pageLinks.noticias,
        pageLinks.diarios,
      ],
      key: 'PESQUISA_JURIDICA',
    },
    pageLinks.advogados,
  ]
  const navigationExperimentLinks = {
    variantA: navigationExperimentLinksA,
    variantB: navigationExperimentLinksB,
  }

  const defaultLinks: TopbarNavItem[] = Object.keys(pageLinks)
    .map((key) => pageLinks[key as keyof NavLinks])
    .filter((item) => !!item)

  if (shouldShowJusIABasedOnCurrentSubscription) {
    const newsIndex = defaultLinks.findIndex((item) => item.key === 'NOTICIAS')
    if (newsIndex !== -1) {
      defaultLinks.splice(newsIndex, 1)
    }
    const jurisprudenceIndex = defaultLinks.findIndex((item) => item.key === 'JURISPRUDENCIA')
    if (jurisprudenceIndex !== -1) {
      defaultLinks.splice(jurisprudenceIndex + 1, 0, {
        url: 'https://ia.jusbrasil.com.br',
        title: 'Jus IA',
        key: 'JUS_IA',
      } as TopbarNavItem)
    }
  }

  return (
    (navigationExperimentVariant &&
      Object.keys(navigationExperimentLinks).includes(navigationExperimentVariant) &&
      navigationExperimentLinks[navigationExperimentVariant as NavigationExperimentVariants]) ||
    defaultLinks
  )
}

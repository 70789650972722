import classNames from 'classnames'
import { forwardRef } from 'react'

import { IconElement, IconProps, IconSize, IconSizes } from '../icon/icon'
import { ensurePropOf } from '../utilities'
import styles from './spinner.module.scss'
import { SpinnerIcon } from './spinner-icon'

export const SpinnerSizes = IconSizes
export type SpinnerElement = IconElement
export type SpinnerSize = IconSize

export interface SpinnerProps extends IconProps {
  size?: SpinnerSize
  label?: string
  className?: string
}

export const Spinner = forwardRef<SpinnerElement, SpinnerProps>(function Spinner(
  props,
  forwardedRef
) {
  const { label = 'Carregando', className, size: sizeProp, ...rest } = props
  const size = ensurePropOf<SpinnerSize>(SpinnerSizes, sizeProp, 'md')
  const classes = classNames(styles.root, className)
  return (
    <SpinnerIcon {...rest} size={size} className={classes} aria-label={label} ref={forwardedRef} />
  )
})

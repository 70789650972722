import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '../icon/icon'
import PaginationButton, { PaginationButtonProps } from '../pagination/pagination-button'
import { assignSubComponents } from '../utilities/internal'
import styles from './pagination-simple.module.scss'

export type PaginationSimpleElement = HTMLDivElement

export interface PaginationSimpleProps extends HTMLAttributes<PaginationSimpleElement> {
  count: number
  page?: number
  defaultPage?: number
  buildPageUrl?: (page: number) => string
  onPageChange?: (page: number) => void
  renderButton?: (
    props: PaginationButtonProps,
    ctx: {
      page: number
      setCurrentPage: (page: number) => void
    }
  ) => ReactNode
}

const defaultBuildPageUrl: PaginationSimpleProps['buildPageUrl'] = (page) => `?p=${page}`

const defaultRenderButton: PaginationSimpleProps['renderButton'] = (props) => (
  <PaginationButton data-testid="pagination-button" asChild {...props}>
    <button />
  </PaginationButton>
)

const PaginationSimpleRoot = forwardRef<PaginationSimpleElement, PaginationSimpleProps>(
  function PaginationSimple(props, forwardedRef) {
    const {
      count,
      page: pageProp,
      defaultPage,
      onPageChange,
      className,
      renderButton = defaultRenderButton,
      buildPageUrl = defaultBuildPageUrl,
      ...rest
    } = props
    const [_currentPage, setCurrentPage] = useState(defaultPage ?? 1)

    const currentPage = Math.max(1, Math.min(pageProp ?? _currentPage, count))

    const handlePageChange = (nextPage: number | null) => {
      if (nextPage === null) return
      setCurrentPage(nextPage)

      if (nextPage !== currentPage) {
        onPageChange?.(nextPage)
      }
    }

    const classes = classNames(styles.root, className)

    return (
      <nav aria-label="paginação" {...rest} className={classes} ref={forwardedRef}>
        <div className={styles.legend}>
          {currentPage} de {count}
        </div>

        <div className={styles.buttons}>
          {renderButton(
            {
              'aria-label': 'página anterior',
              'aria-disabled': currentPage === 1,
              icon: <ChevronLeftIcon />,
              disabled: currentPage === 1,
              href: buildPageUrl(currentPage - 1),
              onClick() {
                if (currentPage === 1) return
                handlePageChange(currentPage - 1)
              },
            },
            {
              page: currentPage - 1,
              setCurrentPage: handlePageChange,
            }
          )}

          {renderButton(
            {
              'aria-label': 'próxima página',
              'aria-disabled': currentPage === count,
              icon: <ChevronRightIcon />,
              disabled: currentPage === count,
              href: buildPageUrl(currentPage + 1),
              onClick() {
                if (currentPage === count) return
                handlePageChange(currentPage + 1)
              },
            },
            {
              page: currentPage + 1,
              setCurrentPage: handlePageChange,
            }
          )}
        </div>
      </nav>
    )
  }
)

export const PaginationSimple = assignSubComponents('PaginationSimple', PaginationSimpleRoot, {
  Button: PaginationButton,
})

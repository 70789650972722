import { assignSubComponents } from '../utilities/internal'
import TreeViewCheckbox from './tree-view-checkbox'
import TreeViewItem from './tree-view-item'
import TreeViewLinkItem from './tree-view-link'
import TreeViewList from './tree-view-list'
import TreeViewRoot from './tree-view-root'

export const TreeView = assignSubComponents('TreeView', TreeViewRoot, {
  Item: TreeViewItem,
  List: TreeViewList,
  // variants
  Checkbox: TreeViewCheckbox,
  Link: TreeViewLinkItem,
})

export * from './tree-view-checkbox'
export * from './tree-view-item'
export * from './tree-view-link'
export * from './tree-view-list'

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { ChevronDownIcon, IconProps } from '../icon/icon'
import { Spinner } from '../spinner/spinner'
import { TruncateText } from '../truncate-text/truncate-text'
import { ensurePropOf, Slot, Slottable } from '../utilities'
import { isValidLinkElement } from '../utilities/internal'
import styles from './chip-clickable.module.scss'

export type ChipClickableElement = HTMLDivElement
export const ChipClickableSizes = ['md', 'lg'] as const
export type ChipClickableSize = (typeof ChipClickableSizes)[number]

type WithUnstableTruncateProps =
  | { unstable_truncate: true; title: string }
  | { unstable_truncate?: false }

export type ChipClickableProps = HTMLAttributes<ChipClickableElement> & {
  children: ReactNode
  size?: ChipClickableSize
  disabled?: boolean
  loading?: boolean
  leftIcon?: ReactNode
  chevron?: boolean
  chevronUp?: boolean
  selected?: boolean
  className?: string
  asChild?: boolean
} & WithUnstableTruncateProps

const iconProps: Partial<IconProps> = {
  size: 'sm',
}

export const ChipClickable = forwardRef<ChipClickableElement, ChipClickableProps>(
  function ChipClickable(props, forwardedRef) {
    const {
      className,
      leftIcon = null,
      disabled = false,
      loading = false,
      selected = false,
      chevronUp = false,
      chevron = false,
      children,
      size: sizeProp,
      asChild: asChildProp,
      unstable_truncate: _,
      ...rest
    } = props

    const asChild = asChildProp && isValidElement(children)
    const isLink = asChild && isValidLinkElement(children)

    const Component = asChild ? Slot : 'span'

    const size = ensurePropOf<ChipClickableSize>(ChipClickableSizes, sizeProp, 'lg')

    const classes = classNames(
      styles.root,
      {
        [styles[`size${size}`]]: size !== 'lg',
        [styles.selected]: selected,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      },
      className
    )

    const loadingProps = loading ? { 'aria-selected': true } : {}
    const buttonProps = !isLink ? { role: 'button', tabIndex: 0 } : {}

    return (
      <Component
        ref={forwardedRef}
        {...loadingProps}
        {...buttonProps}
        {...rest}
        className={classes}
      >
        {isValidElement<IconProps>(leftIcon) ? (
          <span className={styles.leftIcon}>{cloneElement(leftIcon, iconProps)}</span>
        ) : null}

        <Slottable>{renderChildren(props)}</Slottable>

        {loading ? (
          <span className={styles.spinner}>
            <Spinner size="sm" />
          </span>
        ) : null}

        {chevron && (
          <ChevronDownIcon
            size="sm"
            className={classNames(styles.chevron, chevronUp && styles.chevronUp)}
          />
        )}
      </Component>
    )
  }
)

function renderChildren(props: ChipClickableProps): ReactNode {
  const { asChild, children } = props
  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return cloneElement(children, {}, renderLabel(children.props.children, props))
  }
  return renderLabel(children, props)
}

function renderLabel(childrenProp: ReactNode, props: ChipClickableProps): ReactNode {
  const { unstable_truncate = false } = props

  const children = unstable_truncate ? (
    <TruncateText value={1}>{childrenProp}</TruncateText>
  ) : (
    childrenProp
  )

  if (!props.loading) return children
  return <span className={styles.label}>{children}</span>
}

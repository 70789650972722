import * as RSelect from '@radix-ui/react-select'

import { MenuItem } from '../menu-item/menu-item'

export type SelectItemElement = HTMLDivElement
export type SelectItemProps = RSelect.SelectItemProps

const SelectItem: React.FC<SelectItemProps> = ({ children, ...props }) => {
  return (
    <RSelect.Item {...props} asChild>
      <MenuItem>
        <RSelect.ItemText>{children}</RSelect.ItemText>
      </MenuItem>
    </RSelect.Item>
  )
}

export default SelectItem

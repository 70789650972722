import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'

import { Separator } from '../separator/separator'
import styles from './dropdown-menu-separator.module.scss'

export type DropdownMenuSeparatorElement = typeof RDropdownMenu.Item
export type DropdownMenuSeparatorProps = RDropdownMenu.DropdownMenuSeparatorProps

const DropdownMenuSeparator: React.FC<DropdownMenuSeparatorProps> = (props) => {
  const { children, ...rest } = props
  return (
    <RDropdownMenu.Separator {...rest} asChild>
      <Separator orientation="horizontal" className={styles.root} />
    </RDropdownMenu.Separator>
  )
}

export default DropdownMenuSeparator

import { GetServerSideProps, GetServerSidePropsResult, PreviewData } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { getPath, isGooglebotAgent, logOrThrowErrorForInvalidPath } from './utils'

export const withPrometheusMetricsGetServerSideProps = <
  // using the exact same type (any) as GetServerSideProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Props extends { [key: string]: any },
  Params extends ParsedUrlQuery,
  Preview extends PreviewData
>(
  filename: string,
  getServerSideProps: GetServerSideProps<Props, Params, Preview>,
  logRequest = false
): GetServerSideProps<Props, Params, Preview> => {
  if (process.env.NEXT_RUNTIME !== 'nodejs') {
    return getServerSideProps
  }

  const path = getPath('server/pages', filename)
  if (path === null) {
    logOrThrowErrorForInvalidPath(
      'withPrometheusMetricsGetServerSideProps should be used only in server page routes'
    )
    return getServerSideProps
  }

  return async (ctx) => {
    const start = process.hrtime()
    let getServersidePropsError: unknown | null = null
    let result: GetServerSidePropsResult<Props> = { notFound: true }
    try {
      result = await getServerSideProps(ctx)
    } catch (error) {
      getServersidePropsError = error
    }

    const { req, res } = ctx
    const end = process.hrtime(start)
    const duration = end[0] + end[1] / 1e9

    const getStatusCode = () => {
      if (getServersidePropsError) {
        return 500
      }
      if ('redirect' in result) {
        if ('statusCode' in result.redirect) {
          return result.redirect.statusCode
        } else if ('permanent' in result.redirect) {
          return result.redirect.permanent ? 308 : 307
        }
      }
      if ('notFound' in result) {
        return 404
      }
      return res.statusCode
    }

    const labels = {
      method: req.method,
      path,
      code: getStatusCode(),
      is_googlebot_user_agent: isGooglebotAgent(req.headers).toString(),
    }

    if (process.env.NEXT_RUNTIME === 'nodejs') {
      const { PrometheusMetrics } = await import('../register')
      PrometheusMetrics.instance.registerMetrics(labels, duration)
    }

    if (logRequest) {
      const durationMs = Math.round(duration * 1000)
      const data = {
        time: new Date().toISOString(),
        severity: 'INFO',
        message: `${req.method} ${req.url} ${getStatusCode()} - ${durationMs} ms`,
      }
      console.log(JSON.stringify(data))
    }

    if (getServersidePropsError) {
      throw getServersidePropsError
    }
    return result
  }
}

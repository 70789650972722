import { sendMetrics } from './functions'
import { EventDetail } from './types'

const isProduction = process.env.NODE_ENV === 'production'
let initialized = false
export const EVENT_NAME = 'jusbrasil-web-events'

export function attachArgosListener(): void {
  if (typeof window !== 'undefined' && !initialized) {
    initialized = true
    document.body.addEventListener(EVENT_NAME, (event: Event) => {
      const customEvent = event as CustomEvent<EventDetail>
      const { name, user_id, properties } = customEvent.detail
      sendMetrics(name, user_id, properties)
    })
  }
}

const buildMetricEvent = (
  name: string,
  userId: string | null | undefined,
  properties: Record<string, unknown> | undefined
): CustomEvent<EventDetail> => {
  const detail = { name, properties, user_id: userId }
  return new CustomEvent(EVENT_NAME, { detail, bubbles: true })
}

export const dispatchMetricEvent = (
  elementRef: HTMLElement,
  event: CustomEvent<EventDetail>
): void => {
  elementRef.dispatchEvent(event)
}

export const handleMetricEvent = (
  name: string,
  userId: string | null | undefined,
  properties: Record<string, unknown> | undefined,
  elementRef: HTMLElement | null | undefined
): void => {
  const event = buildMetricEvent(name, userId, properties)

  if (elementRef) {
    dispatchMetricEvent(elementRef, event)
  } else if (!isProduction) {
    console.warn(
      '[handleMetricEvent] an event was dispatched without "ref" attached, check if it is missing',
      event.detail
    )
  }
}

import { GetServerSidePropsContext } from 'next'

import { NO_EXPERIMENT_RESULT } from '../constants'
import { ABTestExperiment, ABTestParticipate, ABTestParticipation } from '../types'
import { withABTestExperiment } from './with-ab-test-experiment'

interface CustomExperimentAndGroup {
  groups: string[]
  experiments: string[]
}

export const fetchParticipatingByPattern = async (
  ctx: Pick<GetServerSidePropsContext, 'query' | 'req'>,
  experimentData: ABTestParticipation
): Promise<ABTestExperiment[]> => {
  if (!experimentData.groupPattern) return []

  const response = await withABTestExperiment(ctx, experimentData)
  const { participation } = response || {}

  if (participation?.length > 0) {
    const participatingExperiments = participation.filter(
      ({ participating, experiment }) => experiment?.name && participating
    )

    return participatingExperiments.map(
      ({ experiment, alternative, participating, experiment_group }) => ({
        experiment: experiment?.name || '',
        alternative: alternative?.name || '',
        participating: participating,
        experimentGroup: experiment_group?.name || null,
      })
    )
  }

  return []
}

export const fetchParticipating = async (
  ctx: Pick<GetServerSidePropsContext, 'query' | 'req'>,
  activeExperiments: ABTestParticipate[],
  experimentData?: Omit<ABTestParticipation, 'groups' | 'experiments'>
): Promise<ABTestExperiment[]> => {
  if (activeExperiments.length === 0) return [NO_EXPERIMENT_RESULT]

  const { groups, experiments } = activeExperiments.reduce(
    (obj: CustomExperimentAndGroup, { experimentGroup, experimentName }) => ({
      groups: obj.groups.includes(experimentGroup)
        ? [...obj.groups]
        : [...obj.groups, experimentGroup],
      experiments: obj.experiments.includes(experimentName)
        ? [...obj.experiments]
        : [...obj.experiments, experimentName],
    }),
    { groups: [], experiments: [] }
  )

  const response = await withABTestExperiment(ctx, {
    ...experimentData,
    groups,
    experiments,
  })

  const { participation } = response || {}

  if (participation?.length > 0) {
    const participatingExperiments = participation.filter(
      ({ participating, experiment }) =>
        experiment?.name && participating && experiments.includes(experiment.name)
    )

    return participatingExperiments.map(
      ({ experiment, alternative, participating, experiment_group }) => ({
        experiment: experiment?.name || '',
        alternative: alternative?.name || '',
        participating: participating,
        experimentGroup: experiment_group?.name || null,
      })
    )
  }

  return [NO_EXPERIMENT_RESULT]
}

import * as RDialog from '@radix-ui/react-dialog'

export type ModalTriggerElement = typeof RDialog.DialogTrigger

export type ModalTriggerProps = RDialog.DialogTriggerProps

const ModalTrigger: React.FC<ModalTriggerProps> = (props) => {
  return <RDialog.Trigger asChild {...props} />
}

export default ModalTrigger

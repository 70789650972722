import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgMoreHorizontalcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm10 2a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm8 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgMoreHorizontalcon)

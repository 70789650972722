import { assignSubComponents } from '@farol-ds/react/utilities/internal'

import { LineScrollArrowLeft } from './line-scroll-arrow-left'
import { LineScrollArrowRight } from './line-scroll-arrow-right'
import { LineScrollBoundary } from './line-scroll-boundary'
import { LineScrollDropdownGuard } from './line-scroll-dropdown-guard'
import { LineScrollRoot } from './line-scroll-root'

export const LineScroll = assignSubComponents('LineScroll', LineScrollRoot, {
  Boundary: LineScrollBoundary,
  ArrowLeft: LineScrollArrowLeft,
  ArrowRight: LineScrollArrowRight,
  DropdownGuard: LineScrollDropdownGuard,
})

import classNames from 'classnames'
import { forwardRef, Fragment, HTMLAttributes, ReactNode, useMemo, useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '../icon/icons'
import { assignSubComponents } from '../utilities/internal'
import styles from './pagination.module.scss'
import PaginationButton, { PaginationButtonProps } from './pagination-button'
import PaginationItem, { PaginationItemProps } from './pagination-item'
import { getVisiblePages } from './utils'

export type PaginationElement = HTMLDivElement

export interface PaginationProps extends HTMLAttributes<PaginationElement> {
  count: number
  page?: number
  defaultPage?: number
  buildPageUrl?: (page: number) => string
  onPageChange?: (page: number) => void
  renderItem?: (
    props: PaginationItemProps,
    ctx: {
      page: number | null
      setCurrentPage: (page: number) => void
    }
  ) => ReactNode
  renderButton?: (
    props: PaginationButtonProps,
    ctx: {
      page: number
      setCurrentPage: (page: number) => void
    }
  ) => ReactNode
}

const defaultBuildPageUrl: PaginationProps['buildPageUrl'] = (page) => `?p=${page}`

const defaultRenderItem: PaginationProps['renderItem'] = (props) => (
  <PaginationItem data-testid="pagination-item" {...props} />
)
const defaultRenderButton: PaginationProps['renderButton'] = (props) => (
  <PaginationButton data-testid="pagination-button" {...props} />
)

const PaginationRoot = forwardRef<PaginationElement, PaginationProps>(function Pagination(
  props,
  forwardedRef
) {
  const {
    count,
    page: pageProp,
    defaultPage,
    onPageChange,
    className,
    renderItem = defaultRenderItem,
    renderButton = defaultRenderButton,
    buildPageUrl = defaultBuildPageUrl,
    ...rest
  } = props
  const [_currentPage, setCurrentPage] = useState(defaultPage ?? 1)

  const currentPage = Math.max(1, Math.min(pageProp ?? _currentPage, count))
  const pageNumbers = useMemo(() => getVisiblePages(count, currentPage), [count, currentPage])

  const handlePageChange = (nextPage: number | null) => {
    if (nextPage === null) return
    setCurrentPage(nextPage)

    if (nextPage !== currentPage) {
      onPageChange?.(nextPage)
    }
  }

  const classes = classNames(styles.root, className)

  return (
    <nav aria-label="paginação" {...rest} className={classes} ref={forwardedRef}>
      {renderButton(
        {
          'aria-label': 'página anterior',
          'aria-disabled': currentPage === 1,
          icon: <ChevronLeftIcon />,
          disabled: currentPage === 1,
          href: buildPageUrl(currentPage - 1),
          onClick() {
            if (currentPage === 1) return
            handlePageChange(currentPage - 1)
          },
        },
        {
          page: currentPage - 1,
          setCurrentPage: handlePageChange,
        }
      )}

      <div className={styles.numbers}>
        {pageNumbers.map((pageNumber, idx) => (
          <Fragment key={idx}>
            {renderItem(
              {
                number: pageNumber,
                current: pageNumber === currentPage,
                truncation: pageNumber === null,
                href: pageNumber ? buildPageUrl(pageNumber) : undefined,
                onClick() {
                  handlePageChange(pageNumber)
                },
              },
              {
                page: pageNumber,
                setCurrentPage: handlePageChange,
              }
            )}
          </Fragment>
        ))}
      </div>

      <div className={styles.legend}>
        Página {currentPage} de {count}
      </div>

      {renderButton(
        {
          'aria-label': 'próxima página',
          'aria-disabled': currentPage === count,
          icon: <ChevronRightIcon />,
          disabled: currentPage === count,
          href: buildPageUrl(currentPage + 1),
          onClick() {
            if (currentPage === count) return
            handlePageChange(currentPage + 1)
          },
        },
        {
          page: currentPage + 1,
          setCurrentPage: handlePageChange,
        }
      )}
    </nav>
  )
})

export const Pagination = assignSubComponents('Pagination', PaginationRoot, {
  Item: PaginationItem,
  Button: PaginationButton,
})

export * from './pagination-button'
export * from './pagination-item'

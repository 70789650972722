import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgBookmarkActive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2a3 3 0 0 0-3 3v14c0 2.708 3.305 4.03 5.172 2.069L12 18.1l2.828 2.969C16.695 23.03 20 21.709 20 19V5a3 3 0 0 0-3-3H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgBookmarkActive)

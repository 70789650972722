import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCopy(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5Zm1-3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3h-8ZM5 8a1 1 0 0 0-2 0v11a3 3 0 0 0 3 3h9a1 1 0 1 0 0-2H6a1 1 0 0 1-1-1V8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCopy)

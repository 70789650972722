import { BodyText, Button, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { useEffect } from 'react'

import styles from '../lawsuit-summary-modal.module.scss'
import negativeFeedbackIcon from '../stamps/error.svg'

interface NegativeFeedbackProps {
  errorButtonHandler: () => void
  onSendEvent: () => void
}

export function NegativeFeedback(props: NegativeFeedbackProps) {
  const { errorButtonHandler, onSendEvent } = props

  useEffect(() => {
    onSendEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal.IconClose />
      <Modal.Body className={styles.generatingModal}>
        <Image
          src={negativeFeedbackIcon}
          alt={'Erro ao gerar resumo'}
          width={120}
          height={120}
          priority
        />

        <div>
          <Heading className={styles.title} size="md" asChild>
            <h2>Erro ao gerar resumo</h2>
          </Heading>

          <BodyText size="md" className={styles.label}>
            Tivemos um problema ao resumir o processo. Tente novamente em instantes.
          </BodyText>
        </div>

        <Button kind="secondary" onClick={errorButtonHandler}>
          Tentar Novamente
        </Button>
      </Modal.Body>
    </>
  )
}

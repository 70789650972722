import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUnlock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-6Zm3-1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H7Z"
        fill="currentColor"
      />
      <path d="M11 14a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6a4 4 0 1 1 8 0v4a1 1 0 1 1-2 0V6a2 2 0 1 0-4 0 1 1 0 0 1-2 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUnlock)

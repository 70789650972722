import * as RPopover from '@radix-ui/react-popover'

export type PopoverTriggerElement = typeof RPopover.PopoverTrigger

export type PopoverTriggerProps = RPopover.PopoverTriggerProps

const PopoverTrigger: React.FC<PopoverTriggerProps> = (props) => {
  return <RPopover.Trigger asChild {...props} />
}

export default PopoverTrigger

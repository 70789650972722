import { DocumentNode, OperationVariables, useLazyQuery } from '@apollo/client'

import { networkToastDispatcher } from '../components/network-error-boundary'
import { get } from '../utils/get'

type PageInfo =
  | {
      readonly __typename?: 'PageInfo' | undefined
      readonly hasNextPage: boolean
      readonly endCursor: string | null
    }
  | undefined

export function getEndCursor<QueryType>(
  data: QueryType,
  pageInfo: PageInfo,
  nextPageInfoPath: string
): string | null | undefined {
  if (get(data, nextPageInfoPath)) {
    return get<QueryType, string>(
      data,
      `${nextPageInfoPath}.endCursor`
    ) as NonNullable<PageInfo>['endCursor']
  }

  return pageInfo?.endCursor
}

export function hasNextPage<QueryType>(
  data: QueryType,
  pageInfo: PageInfo,
  nextPageInfoPath: string
): () => boolean {
  return (): boolean => {
    if (get(data, nextPageInfoPath)) {
      return !!get<QueryType, string>(data, `${nextPageInfoPath}.hasNextPage`)
    }

    return !!pageInfo?.hasNextPage
  }
}

export function usePagination<QueryType, Variables extends OperationVariables>(
  query: DocumentNode,
  variables: Variables,
  pageInfo: PageInfo,
  nextPageInfoPath: string,
  enableToastOnNetworkError = false
) {
  const [fetchData, { loading, data, error, fetchMore }] = useLazyQuery<QueryType>(query, {
    initialFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      if (enableToastOnNetworkError) {
        networkToastDispatcher()
      }
    },
  })

  const handleLoadMore = async (): Promise<void> => {
    const endCursor = getEndCursor(data, pageInfo, nextPageInfoPath)

    if (!endCursor) {
      return
    }

    if (!data) {
      fetchData({ variables })
    } else {
      fetchMore({
        variables: { ...variables, after: endCursor },
      })
    }
  }

  return {
    data,
    loading,
    hasError: !!error,
    hasNextPage: hasNextPage<QueryType | undefined>(data, pageInfo, nextPageInfoPath),
    handleLoadMore,
  }
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgMicOff(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6a4 4 0 0 1 7.464-2 1 1 0 0 1-1.731 1A2 2 0 0 0 10 6v4a1 1 0 1 1-2 0V6Zm8 6V9.414l3.707-3.707a1 1 0 0 0-1.414-1.414l-11.95 11.95-2.05 2.05a1 1 0 1 0 1.414 1.414l1.387-1.387A7.965 7.965 0 0 0 11 19.938V21a1 1 0 1 0 2 0v-1.062A8.001 8.001 0 0 0 20 12a1 1 0 1 0-2 0 6 6 0 0 1-9.477 4.89l1.445-1.444A4 4 0 0 0 16 12ZM6 12a1 1 0 1 0-2 0 8 8 0 0 0 .331 2.285 1 1 0 1 0 1.917-.57A6.002 6.002 0 0 1 6 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgMicOff)

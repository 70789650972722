import { forwardRef, memo, useId } from 'react'

import { Radio, RadioElement, RadioProps } from '../radio/radio'
import {
  SelectionCard,
  SelectionCardBodyContentDescriptionProps,
  SelectionCardBodyContentProps,
  SelectionCardBodyContentTitleProps,
  SelectionCardBodyIconProps,
  SelectionCardElement,
  SelectionCardOrientation,
  SelectionCardOrientations,
  SelectionCardProps,
} from '../selection-card/selection-card'
import { assignSubComponents } from '../utilities/internal'
import { useRadioCardGroupContext } from './context'
import RadioCardGroup from './radio-card-group'

export type RadioCardElement = RadioElement

export const RadioCardOrientations = SelectionCardOrientations
export type RadioCardOrientation = SelectionCardOrientation

export type RadioCardProps = RadioProps & {
  children: React.ReactNode
  orientation?: RadioCardOrientation
  controlHidden?: boolean
  radioClassName?: string
  rootProps?: Partial<
    Omit<SelectionCardProps, 'checked' | 'defaultChecked' | 'onCheckedChange' | 'orientation'>
  >
  rootRef?: React.Ref<SelectionCardElement>
}

const RadioCardRoot = forwardRef<RadioCardElement, RadioCardProps>(function RadioCard(
  props,
  forwardedRef
) {
  const {
    id: idProp,
    className,
    children,
    orientation,
    disabled,
    rootProps,
    controlHidden,
    value,
    radioClassName,
    rootRef,
    ...rest
  } = props

  const _id = useId()
  const id = idProp ?? _id

  const ctx = useRadioCardGroupContext()

  function handleKeyDown(e: React.KeyboardEvent<SelectionCardElement>) {
    if (e.key === 'Enter' || e.key === ' ') {
      ctx.setValue(value)
    }
  }

  return (
    <SelectionCard
      {...rootProps}
      orientation={orientation}
      checked={value === ctx.value}
      disabled={disabled}
      className={className}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      ref={rootRef}
      asChild
    >
      <label htmlFor={id}>
        <SelectionCard.Body>{children}</SelectionCard.Body>
        <SelectionCard.Control hidden={controlHidden}>
          <Radio
            {...rest}
            disabled={disabled}
            value={value}
            id={id}
            tabIndex={-1}
            className={radioClassName}
            ref={forwardedRef}
          />
        </SelectionCard.Control>
      </label>
    </SelectionCard>
  )
})

export type RadioCardIconProps = SelectionCardBodyIconProps
const RadioCardIcon = memo(function RadioCardIcon(props: RadioCardIconProps) {
  return <SelectionCard.BodyIcon {...props} />
})

export type RadioCardContentProps = SelectionCardBodyContentProps
const RadioCardContent = memo(function RadioCardContent(props: RadioCardContentProps) {
  return <SelectionCard.BodyContent {...props} />
})

export type RadioCardContentTitleProps = SelectionCardBodyContentTitleProps
const RadioCardContentTitle = memo(function RadioCardContentTitle(
  props: RadioCardContentTitleProps
) {
  return <SelectionCard.BodyContentTitle {...props} />
})

export type RadioCardContentDescriptionProps = SelectionCardBodyContentDescriptionProps
const RadioCardContentDescription = memo(function RadioCardContentDescription(
  props: RadioCardContentDescriptionProps
) {
  return <SelectionCard.BodyContentDescription {...props} />
})

export const RadioCard = assignSubComponents('RadioCard', RadioCardRoot, {
  Group: RadioCardGroup,
  Icon: RadioCardIcon,
  Content: RadioCardContent,
  ContentTitle: RadioCardContentTitle,
  ContentDescription: RadioCardContentDescription,
})

export type * from './radio-card-group'

import type { PopoverProps as RPopoverProps } from '@radix-ui/react-popover'
import { ReactNode } from 'react'

export const PopoverTypes = ['light', 'highlight'] as const

export type PopoverType = (typeof PopoverTypes)[number]

export const PopoverPlacements = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
] as const

export type PopoverPlacement = (typeof PopoverPlacements)[number]

export interface PopoverProps extends RPopoverProps {
  children: ReactNode
  type?: PopoverType
  placement?: PopoverPlacement
}

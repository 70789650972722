import { TruncateTextValue } from '../truncate-text/truncate-text'

export function incrementTruncateTextValue(
  value: TruncateTextValue,
  inc: number
): TruncateTextValue {
  if (Array.isArray(value)) {
    return value.map((v) => (typeof v === 'number' ? v + inc : v))
  } else if (typeof value === 'number') {
    return value + inc
  }
  return value
}

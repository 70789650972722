export const GOOGLE_ONE_TAP_ENABLE_EVENT = 'googleOneTapEnable'
export const JUSBRASIL_BASE_URL = 'https://www.jusbrasil.com.br'

export const ONE_DAY_IN_SECONDS = 24 * 60 * 60
export const ONE_WEEK_IN_SECONDS = 7 * ONE_DAY_IN_SECONDS
export const ONE_YEAR_IN_SECONDS = 365 * ONE_DAY_IN_SECONDS

export const FEATURE_FORCE_EXPERIMENT_COOKIE = 'feature_force_experiment'
export const FEATURE_FORCE_EXPERIMENT_GROUP_COOKIE = 'feature_force_experiment_group'
export const FEATURE_FORCE_EXPERIMENT_NAME_COOKIE = 'feature_force_experiment_name'
export const FEATURE_FORCE_EXPERIMENT_VARIATION_COOKIE = 'feature_force_experiment_variation'
export const SHOW_JUSIA_ON_TOPBAR_BASED_ON_SUBSCRIPTION = {
  cookie: {
    name: 'show_jusia_on_topbar_based_on_subscription',
    maxAge: 60000 * 60 * 24, // 1 day
  },
}

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, useEffect, useRef } from 'react'

import { ensurePropOf } from '../utilities'
import styles from './progress-loader.module.scss'

export const ProgressLoaderPlacements = ['none', 'top', 'bottom'] as const
export type ProgressLoaderPlacement = (typeof ProgressLoaderPlacements)[number]
export type ProgressLoaderElement = HTMLDivElement
export type ProgressLoaderStateType = 'start' | 'complete'

export interface ProgressLoaderProps extends HTMLAttributes<ProgressLoaderElement> {
  placement?: ProgressLoaderPlacement
  visible?: boolean
  on?: (state: ProgressLoaderStateType, callback: () => void) => void
  off?: (state: ProgressLoaderStateType, callback: () => void) => void
  className?: string
  progressProps?: HTMLAttributes<HTMLDivElement>
}

export const ProgressLoader = forwardRef<ProgressLoaderElement, ProgressLoaderProps>(
  function ProgressLoader(props, forwardedRef) {
    const { className, visible, on, off, progressProps, placement: placementProp, ...rest } = props

    const placement = ensurePropOf<ProgressLoaderPlacement>(
      ProgressLoaderPlacements,
      placementProp,
      'none'
    )

    const ref = useRef<ProgressLoaderElement>(null)
    const refs = useComposedRefs(forwardedRef, ref)

    function toggle(active: boolean) {
      const el = ref.current
      if (!el) return
      el.classList.toggle(styles.active, active)
      el.setAttribute('aria-hidden', String(!active))
    }

    useEffect(() => {
      const handleStart = () => toggle(true)
      const handleComplete = () => toggle(false)

      on?.('start', handleStart)
      on?.('complete', handleComplete)

      return () => {
        off?.('start', handleStart)
        off?.('complete', handleComplete)
      }
    }, [off, on])

    useEffect(() => {
      if (visible === undefined) return
      toggle(visible)
    }, [visible, placement])

    const classes = classNames(
      styles.root,
      {
        [styles.top]: placement === 'top',
        [styles.bottom]: placement === 'bottom',
      },
      className
    )
    const progressClasses = classNames(styles.progress, progressProps?.className)

    return (
      <div
        role="progressbar"
        aria-label="Carregando..."
        aria-hidden
        className={classes}
        {...rest}
        ref={refs}
      >
        <div {...progressProps} className={progressClasses} />
      </div>
    )
  }
)

/**
 * @param iframeUrl The URL of the iframe that will be rendered.
 * @returns The iframe URL with the current window query params.
 */
export function mountIframeUrlWithCurrentURLParams(iframeUrl: string): string {
  if (typeof window === 'undefined') return iframeUrl

  const {
    location: { origin },
  } = window
  const composedURL = new URL(iframeUrl, origin)
  const referrer = location.href
  const currentURL = new URL(referrer)
  currentURL.searchParams.forEach((value, key) => {
    composedURL.searchParams.set(key, value)
  })

  composedURL.searchParams.set('referrer', referrer)

  return composedURL.toString()
}

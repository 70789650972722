import { ReactElement } from 'react'

const KNOWN_LINK_NAMES = ['Link', 'NextLink']

export function isValidLinkElement<E extends HTMLAnchorElement>(
  el: ReactElement
): el is ReactElement<E> {
  return (
    el.type === 'a' ||
    ('href' in el.props && !!el.props.href) ||
    (typeof el.type !== 'string' && KNOWN_LINK_NAMES.includes(el.type.name))
  )
}

import classNames from 'classnames'
import {
  AnchorHTMLAttributes,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'

import { IconProps } from '../icon/types'
import { Slot, Slottable } from '../utilities'
import styles from './pagination-button.module.scss'
import { onlyValidAttributes } from './utils'

export type PaginationButtonElement = HTMLAnchorElement

export type PaginationButtonProps = AnchorHTMLAttributes<PaginationButtonElement> & {
  icon?: ReactNode
  disabled?: boolean
  href?: string
  className?: string
  asChild?: boolean
}

const PaginationButton = forwardRef<PaginationButtonElement, PaginationButtonProps>(
  function PaginationButton(props, forwardedRef) {
    const { className, icon, asChild: asChildProp, children, ...rest } = props

    const asChild = asChildProp && isValidElement(children)
    const Component = asChild ? Slot : 'a'

    const classes = classNames(
      styles.root,
      {
        [styles.disabled]: !!props.disabled,
      },
      className
    )

    const validAttributes = onlyValidAttributes(rest, props)

    return (
      <Component {...validAttributes} className={classes} ref={forwardedRef}>
        <Slottable>
          {asChild ? cloneElement(Children.only(children), {}, renderIcon(icon)) : renderIcon(icon)}
        </Slottable>
      </Component>
    )
  }
)

function renderIcon(node: ReactNode) {
  return cloneElement(Children.only(node) as ReactElement<IconProps>, {
    size: 'sm',
  })
}

export default PaginationButton

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgDarkMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 7a8 8 0 0 0 11.955 6.956C20.474 19.03 16.2 23 11 23 5.477 23 1 18.523 1 13c0-5.2 3.97-9.474 9.044-9.955A7.963 7.963 0 0 0 9 7Zm-6 6a8 8 0 0 0 8 8 8.006 8.006 0 0 0 6.957-4.045c-.316.03-.636.045-.957.045-5.523 0-10-4.477-10-10 0-.321.015-.64.045-.957A8.006 8.006 0 0 0 3 13Zm14.164-9.709L18 3.5v1l-.836.209a2 2 0 0 0-1.455 1.455L15.5 7h-1l-.209-.836a2 2 0 0 0-1.455-1.455L12 4.5v-1l.836-.209a2 2 0 0 0 1.455-1.455L14.5 1h1l.209.836a2 2 0 0 0 1.455 1.455Zm5 5L23 8.5v1l-.836.209a2 2 0 0 0-1.455 1.455L20.5 12h-1l-.209-.836a2 2 0 0 0-1.455-1.455L17 9.5v-1l.836-.209a2 2 0 0 0 1.455-1.455L19.5 6h1l.209.836a2 2 0 0 0 1.455 1.455Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgDarkMode)

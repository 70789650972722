import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT, INPUT_METHOD } from '../constants'

export interface GA4InputSubmitEventProps {
  featureClass: string
  featureName?: string | null
  inputMethod: INPUT_METHOD
  successfulAttempt: boolean
}

export class GA4InputSubmitEvent extends GA4Event {
  ga4_feature_class: GA4InputSubmitEventProps['featureClass']
  ga4_feature_name: GA4InputSubmitEventProps['featureName']
  ga4_input_method: GA4InputSubmitEventProps['inputMethod']
  ga4_successful_attempt: GA4InputSubmitEventProps['successfulAttempt']

  constructor({
    featureClass,
    featureName = null,
    inputMethod,
    successfulAttempt,
  }: GA4InputSubmitEventProps) {
    super({ event: EVENT.INPUT_SUBMIT })

    this.ga4_feature_class = featureClass
    this.ga4_feature_name = featureName
    this.ga4_input_method = inputMethod
    this.ga4_successful_attempt = successfulAttempt
  }
}

import { BodyText, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { useEffect } from 'react'

import styles from '../lawsuit-summary-modal.module.scss'
import documentsIcon from '../stamps/movements.svg'

export function GeneratingSummary({ onSendEvent }: { onSendEvent: () => void }) {
  useEffect(() => {
    onSendEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Modal.Body
      className={styles.generatingModal}
      data-testid="lawsuit-summary-modal-generating-step"
    >
      <Image src={documentsIcon} alt={'Gerando resumo'} width={120} height={120} priority />
      <div>
        <Heading className={styles.title} size="md" asChild>
          <h2>Analisando informações</h2>
        </Heading>

        <BodyText size="md" className={styles.label}>
          A explicação está quase pronta.
        </BodyText>
      </div>
    </Modal.Body>
  )
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFolder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h5a3 3 0 0 1 3 3v1h6a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Zm2.83 16H19a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v8c0 .35-.06.687-.17 1ZM11 7H9a3 3 0 0 0-3 3v8a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFolder)

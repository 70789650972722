import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import styles from './popover-content-footer.module.scss'

export type PopoverContentFooterElement = HTMLDivElement

export type PopoverContentFooterProps = HTMLAttributes<PopoverContentFooterElement> & {
  children: ReactNode
  asChild?: boolean
}

const PopoverContentFooter = forwardRef<PopoverContentFooterElement, PopoverContentFooterProps>(
  function PopoverContentFooter(props, forwardedRef) {
    const { className, asChild, children, ...rest } = props
    const classes = classNames(styles.root, className)
    const Component = asChild ? Slot : 'div'
    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default PopoverContentFooter

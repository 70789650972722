import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgComputer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h7v2H8a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-3v-2h7a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Zm8 13h8V4H4v11h8Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgComputer)

import { ApolloProvider as ApolloProviderLib } from '@apollo/client'
import { ApolloClientOptions } from '@jusbrasil-web/shared-apollo-client'
import { useAppRootContext } from '@jusbrasil-web/shared-app-root-provider'

import { APOLLO_INTERNAL_CSR_URI, APOLLO_INTERNAL_STATE } from '../constants'
import { ApolloProviderProps, AppPropsWithApollo } from '../types'
import { useApollo } from '../use-apollo'

export function ApolloProvider(props: ApolloProviderProps) {
  const { children, pageProps } = props
  const { getPageProp } = useAppRootContext<AppPropsWithApollo>()
  const csrUri = getPageProp(APOLLO_INTERNAL_CSR_URI) || pageProps[APOLLO_INTERNAL_CSR_URI]
  const state = getPageProp(APOLLO_INTERNAL_STATE) || pageProps[APOLLO_INTERNAL_STATE]
  const options: ApolloClientOptions = csrUri ? { csrUri } : {}
  const client = useApollo(state, options)

  return <ApolloProviderLib client={client}>{children}</ApolloProviderLib>
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUserHistory(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C10.416 7.186 11 6.161 11 5a2 2 0 0 0-2-2ZM7.287 9.861 4.4 11.595A7 7 0 0 0 1 17.597V18a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H4a1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287l4.084-2.45a5.236 5.236 0 0 0 .055-.037l.005-.003.035-.024.116-.083a9.754 9.754 0 0 0 1.537-1.437C12.084 8.313 13 6.838 13 5a4 4 0 0 0-8 0c0 1.838.915 3.313 1.74 4.276.186.216.372.412.547.585ZM16.367 12a1 1 0 0 1-1.574 1.207l-1.5-1.5a1 1 0 0 1 0-1.414l1.5-1.5A1 1 0 0 1 16.366 10H17a6 6 0 1 1-5.822 4.546 1 1 0 0 1 1.94.483A4 4 0 1 0 17 12h-.634Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUserHistory)

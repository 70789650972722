/**
 * @param referrer Referrer URL.
 * @returns the `q` value from query string or an empty string.
 */
export function getQueryFromReferrer(referrer: URL | string | null | undefined): string {
  if (!referrer) return ''
  try {
    let url: URL | null = null
    if (!(referrer instanceof URL)) {
      url = new URL(referrer)
    } else {
      url = referrer
    }
    return url?.searchParams.get('q') || ''
  } catch {
    return ''
  }
}

import { gql } from '@apollo/client'

export enum ONBOARDING_STEPS {
  SHOW_INTENTIONS,
  SHOW_OCCUPATION,
  SHOW_INSTRUCTIONS,
  COMPLETE,
}

export const ONBOARDING_NAME = 'verify-lawsuit'
export const INVOLVED_INTENTIONS = ['PARTY', 'RELATIVE']
export const USER_ONBOARDING_STOP_STEP_MUTATION = gql`
  mutation userOnboardingMutation($input: PutOnboardingStopStepInput!) {
    putOnboardingStopStep(input: $input) {
      onboarding {
        id
        verifyLawsuitStep: stopStep(onboardingName: "verify-lawsuit")
      }
    }
  }
`

export function evt(action: string, id?: string): string {
  return `frame-bus:${id}:${action}`
}

export function bindEvents(
  id: string | undefined,
  el: HTMLIFrameElement,
  listeners: Record<string, (detail?: any) => void>
) {
  const entries = Object.entries(listeners).map(
    ([name, callback]) =>
      [
        name,
        (e: Event) => {
          if ('detail' in e) {
            callback(e.detail)
          } else {
            callback()
          }
        },
      ] as const
  )

  for (const [name, callback] of entries) {
    el.addEventListener(evt(name, id), callback)
  }

  return () => {
    for (const [name, callback] of entries) {
      el.removeEventListener(evt(name, id), callback)
    }
  }
}

export function triggerEvent(el: HTMLIFrameElement, id: string, name: string, detail: unknown) {
  el.dispatchEvent(
    new CustomEvent(evt(name as string, id), {
      detail,
      bubbles: true,
      cancelable: true,
    })
  )
}

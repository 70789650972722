import classNames from 'classnames'
import { createRef, forwardRef, RefObject, useMemo, useRef, useState } from 'react'

import { LinkElement, LinkProps as TLinkProps } from '../link/link'
import styles from './tree-view-link.module.scss'
import TreeView, { TreeViewElement, TreeViewProps } from './tree-view-root'

type LinkProps = Omit<TLinkProps, 'children'>
export type TreeViewLinkValueType = string | null
export type TreeViewLinkElement = TreeViewElement
export type TreeViewLinkProps = TreeViewProps<TreeViewLinkValueType, LinkProps>

const TreeViewLink = forwardRef<TreeViewLinkElement, TreeViewLinkProps>(function TreeViewLink(
  props,
  forwardedRef
) {
  const { defaultSelected = null, ...rest } = props
  const linkRefs = useRef<Record<string, RefObject<LinkElement>>>({})
  const [selected, setSelected] = useState<TreeViewLinkValueType>(defaultSelected)

  function isSelected(nodeId: string): boolean {
    return selected === nodeId
  }

  function onSelectedChange(nodeId: string): void {
    setSelected(nodeId === selected ? null : nodeId)
    props.onSelectedChange?.(nodeId, selected)
  }

  const createNodeRef = useMemo(() => {
    return (nodeId: string) => {
      if (linkRefs.current[nodeId]) {
        return linkRefs.current[nodeId]
      }
      const ref = createRef<LinkElement>()
      linkRefs.current[nodeId] = ref
      return ref
    }
  }, [])

  return (
    <TreeView<TreeViewLinkValueType, LinkProps>
      {...rest}
      isSelected={isSelected}
      onSelectedChange={onSelectedChange}
      getItemProps={(nodeId, prevProps) => {
        const ref = createNodeRef(nodeId)
        const selected = isSelected(nodeId)
        return {
          type: selected ? 'primary' : 'tertiary',
          onClick(event) {
            onSelectedChange(nodeId)
            prevProps?.onClick?.(event)
          },
          className: classNames(prevProps.className, styles.item, {
            [styles.itemSelected]: selected,
          }),
          ref,
        }
      }}
      getTogglerProps={(nodeId) => ({
        className: isSelected(nodeId) ? styles.togglerSelected : undefined,
      })}
      ref={forwardedRef}
    />
  )
})

export default TreeViewLink

import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import styles from './selection-card-body-content-title.module.scss'

export type SelectionCardBodyContentTitleElement = HTMLDivElement

export type SelectionCardBodyContentTitleProps =
  HTMLAttributes<SelectionCardBodyContentTitleElement>

const SelectionCardBodyContentTitle: React.FC<SelectionCardBodyContentTitleProps> = (props) => {
  const { className, children, ...rest } = props
  const classes = classNames(styles.root, className)

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}

export default SelectionCardBodyContentTitle

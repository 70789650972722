import { ChevronRightIcon, IconButton, IconButtonElement } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, MouseEventHandler } from 'react'

import { MULTIPLIER_BASE, ScrollToDirection } from './hooks/use-line-scroll'
import styles from './line-scroll.module.scss'

export type LineScrollArrowRightElement = HTMLSpanElement
export interface LineScrollArrowRightProps extends HTMLAttributes<LineScrollArrowRightElement> {
  onClickButton?: MouseEventHandler<IconButtonElement>
  scrollToDirection: ScrollToDirection
}

export const LineScrollArrowRight = forwardRef<
  LineScrollArrowRightElement,
  LineScrollArrowRightProps
>(function LineScrollArrowRight(props, forwardedRef) {
  const { className, onClickButton, scrollToDirection, ...rest } = props
  return (
    <span {...rest} className={classNames(styles.arrowRight, className)} ref={forwardedRef}>
      <IconButton
        size="md"
        kind="plain"
        tooltipProps={{ open: false }}
        icon={<ChevronRightIcon />}
        label="Mover barra de rolagem para direita"
        onClick={(event) => {
          onClickButton?.(event)
          scrollToDirection(MULTIPLIER_BASE)
        }}
        data-testid="line-scroll-arrow-right"
      />
    </span>
  )
})

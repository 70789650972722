import * as RTabs from '@radix-ui/react-tabs'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { TabsContext } from './context'
import TabsList from './tabs-list'
import TabsTrigger from './tabs-trigger'
import TabsTriggerLeading from './tabs-trigger-leading'
import TabsTriggerTrailing from './tabs-trigger-trailing'
import { useTabsProvider } from './use-tabs'

export type TabsElement = HTMLDivElement
export const TabsActivationModes = ['manual', 'automatic'] as const
export type TabsActivationMode = (typeof TabsActivationModes)[number]
export const TabsChangeBehaviors = ['url-hash', 'none'] as const
export type TabsChangeBehavior = (typeof TabsChangeBehaviors)[number]

export interface TabsProps extends HTMLAttributes<TabsElement> {
  children: ReactNode
  className?: string
  value?: string
  defaultValue?: string
  onValueChange?: (value: string) => void
  activationMode?: TabsActivationMode
  changeBehavior?: TabsChangeBehavior
}

const TabsRoot = forwardRef<TabsElement, TabsProps>(function Tabs(props, forwardedRef) {
  const {
    children,
    activationMode: activationModeProp,
    changeBehavior: changeBehaviorProp,
    ...rest
  } = props

  const activationMode = ensurePropOf<TabsActivationMode>(
    TabsActivationModes,
    activationModeProp,
    'manual'
  )
  const changeBehavior = ensurePropOf<TabsChangeBehavior>(
    TabsChangeBehaviors,
    changeBehaviorProp,
    'url-hash'
  )

  const context = useTabsProvider({
    ...props,
    changeBehavior,
  })

  return (
    <RTabs.Root
      {...rest}
      value={context.activeTabValue}
      onValueChange={context.onValueChange}
      activationMode={activationMode}
      orientation="horizontal"
      dir="ltr"
      ref={forwardedRef}
    >
      <TabsContext.Provider value={context}>{children}</TabsContext.Provider>
    </RTabs.Root>
  )
})

export const Tabs = assignSubComponents('Tabs', TabsRoot, {
  List: TabsList,
  Trigger: TabsTrigger,
  TriggerLeading: TabsTriggerLeading,
  TriggerTrailing: TabsTriggerTrailing,
  Content: RTabs.Content,
})

export * from './tabs-list'
export * from './tabs-trigger'
export * from './tabs-trigger-leading'
export * from './tabs-trigger-trailing'

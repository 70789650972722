import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { ensurePropOf, Slot } from '../utilities'
import styles from './container.module.scss'

export type ContainerElement = HTMLDivElement
export const ContainerTypes = ['default', 'letter', 'fluid'] as const
export type ContainerType = (typeof ContainerTypes)[number]
export type ContainerSpacingName = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export const ContainerSpacingValues = ['default', 'full'] as const
export type ContainerSpacingValue = (typeof ContainerSpacingValues)[number]
export type ContainerSpacing = Partial<Record<ContainerSpacingName, ContainerSpacingValue>>

export interface ContainerProps extends HTMLAttributes<ContainerElement> {
  children: ReactNode
  type?: ContainerType
  className?: string
  asChild?: boolean
  unstable_spacing?: ContainerSpacing
}
export const Container = forwardRef<ContainerElement, ContainerProps>(function Container(
  props,
  forwardedRef
) {
  const { type: typeProp, unstable_spacing: spacingProp, className, asChild, ...rest } = props

  const Component = asChild ? Slot : 'div'

  const type = ensurePropOf<ContainerType>(ContainerTypes, typeProp, 'default')
  const spacing = spacingProp && ensureSpacingProp(spacingProp)

  const classes = classNames(
    styles.root,
    spacing && withSpacingStyles(spacing),
    {
      [styles[`type${type}`]]: type !== 'default',
    },
    className
  )

  return <Component {...rest} className={classes} ref={forwardedRef} />
})

function ensureSpacingProp(spacing: ContainerSpacing): ContainerSpacing {
  return Object.entries(spacing || {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: ensurePropOf<ContainerSpacingValue>(ContainerSpacingValues, value, 'default'),
    }),
    {}
  )
}

function withSpacingStyles(spacing: ContainerSpacing): Record<string, string> {
  return Object.entries(spacing).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [styles[`spacing${key}${value}`]]: value !== 'default',
    }),
    {}
  )
}

import classNames from 'classnames'
import { forwardRef } from 'react'

import { CloseIcon } from '../icon/icon'
import { IconButton, IconButtonElement, IconButtonProps } from '../icon-button/icon-button'
import commonStyles from './common.module.scss'
import ModalClose from './modal-close'
import styles from './modal-icon-close.module.scss'

export type ModalIconCloseElement = IconButtonElement

export type ModalIconCloseProps = Partial<IconButtonProps>

const ModalIconClose = forwardRef<ModalIconCloseElement, ModalIconCloseProps>(
  function ModalIconClose(props, forwardedRef) {
    const { label = 'Fechar', icon = <CloseIcon />, asChild, className, children, ...rest } = props

    const classes = classNames(styles.root, commonStyles.iconClose, className)

    return (
      <ModalClose>
        <IconButton
          label={label}
          icon={icon}
          {...rest}
          size="md"
          className={classes}
          tooltipProps={{ open: false }}
          ref={forwardedRef}
        />
      </ModalClose>
    )
  }
)

export default ModalIconClose

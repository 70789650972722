import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgArrowLeftDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17a1 1 0 0 0 1 1h8.5a1 1 0 1 0 0-2H9.414l8.293-8.293a1 1 0 0 0-1.414-1.414L8 14.586V8.5a1 1 0 1 0-2 0V17Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgArrowLeftDown)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgHeartActive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.674 3.34c2.092-.772 4.6-.243 6.326 2.006 1.726-2.249 4.234-2.778 6.326-2.006C20.398 4.104 22 6.123 22 8.75c0 3.093-1.661 5.688-3.491 7.616-1.84 1.94-3.961 3.323-5.13 4.011a2.705 2.705 0 0 1-2.759 0c-1.168-.688-3.288-2.072-5.128-4.01C3.662 14.436 2 11.842 2 8.75c0-2.627 1.602-4.646 3.674-5.41Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgHeartActive)

import classNames from 'classnames'
import { forwardRef } from 'react'

import {
  PrimitiveText,
  PrimitiveTextElement,
  PrimitiveTextProps,
} from '../primitive-text/primitive-text'
import { ensurePropOf } from '../utilities'
import styles from './body-text.module.scss'

export const BodyTextSizes = ['md', 'lg'] as const
export type BodyTextSize = (typeof BodyTextSizes)[number]
export type BodyTextElement = PrimitiveTextElement
export interface BodyTextProps extends PrimitiveTextProps {
  size?: BodyTextSize
}

export const BodyText = forwardRef<BodyTextElement, BodyTextProps>(function BodyText(
  props,
  forwardedRef
) {
  const { className, size: sizeProp, ...rest } = props

  const size = ensurePropOf<BodyTextSize>(BodyTextSizes, sizeProp, 'md')

  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  return <PrimitiveText {...rest} className={classes} ref={forwardedRef} />
})

import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { AutocompleteListElement } from './autocomplete-list'
import { AutocompleteMenuProps } from './autocomplete-menu'
import { AutocompleteContext } from './context'
import { AutocompleteOption } from './types'

export type UseAutocompleteMenuProps<T extends AutocompleteOption> = AutocompleteMenuProps<T> & {
  listRef: RefObject<AutocompleteListElement>
  forceOpen?: boolean
}

export type UseAutocompleteMenuReturn<T extends AutocompleteOption> = {
  open: boolean
  setOpen(open: boolean): void
  options: T[]
  currentOption: T | null
  selectCurrent(evt: unknown): void
  unselectCurrent(): void
  setCurrentUp(): void
  setCurrentDown(): void
  setCurrentTop(): void
  setCurrentBottom(): void
  getCurrentOptionElement(): Element | null
  onValueChange(value: string): void
  selectOption(evt: unknown, option: T): void
  removeOption(evt: unknown, option: T): void
}

export function useAutocompleteMenu<T extends AutocompleteOption>(
  props: UseAutocompleteMenuProps<T>
): UseAutocompleteMenuReturn<T> {
  const {
    options: initialOptions,
    listRef,
    forceOpen,
    defaultOpen,
    filterOptions,
    onOptionSelect,
    onOptionRemove,
    onOpenChange,
    onCurrentOptionChange,
  } = props

  const ctx = useContext(AutocompleteContext)

  const [open, setOpen] = useState(defaultOpen ?? false)
  const [value, setValue] = useState<string | null>(null)
  const removedOptions = useRef<T[]>([])

  // [perf] using refs to avoid re-rendering
  const currOptIdx = useRef<number | null>(null)
  const prevTriggerValue = useRef<string | null>(null)
  const prevOpenState = useRef<boolean | null>(defaultOpen ?? false)

  function getListChildren() {
    if (!listRef.current) return []
    return Array.from(listRef.current.children).filter((el) => !el.hasAttribute('data-removed'))
  }

  const ctrl: UseAutocompleteMenuReturn<T> = {
    open,

    setOpen(open: boolean) {
      setOpen(open)
    },

    get currentOption() {
      return currOptIdx.current !== null ? this.options[currOptIdx.current] : null
    },

    get options() {
      const optionsWithoutRemoved = removedOptions.current.length
        ? initialOptions.filter((option) => !removedOptions.current.includes(option))
        : initialOptions
      const filteredOptions = filterOptions
        ? filterOptions(optionsWithoutRemoved, value || '')
        : optionsWithoutRemoved
      return filteredOptions
    },

    getCurrentOptionElement() {
      if (currOptIdx.current === null || !listRef.current) return null
      return getListChildren()[currOptIdx.current] || null
    },

    selectCurrent(evt) {
      const currOpt = currOptIdx.current !== null && this.options[currOptIdx.current]
      if (currOpt) {
        this.selectOption(evt, currOpt)
      }
      currOptIdx.current = null
    },

    unselectCurrent() {
      currOptIdx.current = null
      ctx.triggerRef.current?.setInputValue(prevTriggerValue.current ?? '')
      onCurrentOptionChange?.(null)
      refreshDOM()
    },

    setCurrentUp() {
      if (currOptIdx.current === null) {
        // if open, select the last option, otherwise,
        // just open the menu without selecting previous option
        currOptIdx.current = open ? this.options.length - 1 : null
      } else {
        currOptIdx.current = currOptIdx.current > 0 ? currOptIdx.current - 1 : null
      }
      this.setOpen(true)
      refreshDOM()
      onCurrentOptionChange?.(this.currentOption)
    },

    setCurrentDown() {
      if (currOptIdx.current === null) {
        // if open, select the first option, otherwise,
        // just open the menu without selecting next option
        currOptIdx.current = open ? 0 : null
      } else {
        currOptIdx.current =
          currOptIdx.current < this.options.length - 1 ? currOptIdx.current + 1 : null
      }
      this.setOpen(true)
      refreshDOM()
      onCurrentOptionChange?.(this.currentOption)
    },

    setCurrentTop() {
      currOptIdx.current = 0
      this.setOpen(true)
      refreshDOM()
      onCurrentOptionChange?.(this.currentOption)
    },

    setCurrentBottom() {
      currOptIdx.current = this.options.length - 1
      this.setOpen(true)
      refreshDOM()
      onCurrentOptionChange?.(this.currentOption)
    },

    selectOption(evt, option) {
      this.setOpen(false)
      prevTriggerValue.current = option.value
      if (ctx.triggerRef.current) {
        ctx.triggerRef.current.changeValue(evt as any, option.value)
      }
      onOptionSelect?.(option)
    },

    removeOption(evt, option) {
      if (evt instanceof Event) {
        const el = (evt.currentTarget as Element)?.closest('[role="option"]')
        if (el instanceof Element) {
          // do not remove DOM element due to react fiber reconciliation, so, just mark it as removed
          el.setAttribute('data-hidden', 'true')
        }
      }

      currOptIdx.current = null
      onOptionRemove?.(option)
      removedOptions.current = [...removedOptions.current, option]
      refreshDOM()
      onCurrentOptionChange?.(this.currentOption)
    },

    onValueChange(value) {
      currOptIdx.current = null
      prevTriggerValue.current = value
      setValue(value)
      if (!forceOpen) {
        this.setOpen(value !== '')
      }
    },
  }

  // used to update DOM when needed
  const refreshDOM = useCallback(
    (opts = { fromTyping: false }) => {
      const { fromTyping } = opts

      if (!listRef.current) return

      // unselect all options
      const listChildren = getListChildren()
      listChildren.forEach((child) => child.removeAttribute('aria-selected'))

      // select current option and scroll to it
      if (currOptIdx.current !== null) {
        const optionEl = listChildren[currOptIdx.current]
        optionEl?.setAttribute('aria-selected', 'true')
        optionEl?.scrollIntoView?.({ block: 'nearest' })
      } else {
        // scroll to top
        listChildren[0]?.scrollIntoView?.({ block: 'nearest' })
      }

      // update trigger value
      if (ctx.triggerRef.current) {
        if (currOptIdx.current !== null && !fromTyping) {
          const option = ctrl.options[currOptIdx.current]
          // check if option is not removed
          if (option) {
            ctx.triggerRef.current.setInputValue(option.value)
          }
        } else if (!fromTyping) {
          ctx.triggerRef.current.setInputValue(prevTriggerValue.current ?? '')
        }
      }
    },
    [ctx.triggerRef, listRef, ctrl.options]
  )

  // refresh DOM
  useEffect(() => {
    if (open) refreshDOM({ fromTyping: true })
  }, [open, value, removedOptions, refreshDOM])

  // call onOpenChange when open changes
  useEffect(() => {
    const isOpen = open && (forceOpen || ctrl.options.length > 0)
    if (prevOpenState.current !== isOpen) {
      onOpenChange?.(isOpen)
      prevOpenState.current = isOpen
      ctx.triggerRef.current?.onMenuChange(isOpen)
    }
  }, [forceOpen, open, value, props.options])

  // pre-populate prevTriggerValue with defaultValue of trigger
  useEffect(() => {
    prevTriggerValue.current = value || null
  }, [])

  return ctrl
}

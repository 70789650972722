import * as RAccordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { ChevronDownIcon } from '../icon/icons'
import { IconSize } from '../icon/types'
import styles from './accordion-header.module.scss'
import { AccordionSize } from './types'
import { useAccordion } from './use-accordion'

export type AccordionHeaderElement = HTMLButtonElement

export type AccordionHeaderProps = React.ComponentProps<typeof RAccordion.Header>

const chevronSizeMapping: Record<AccordionSize, IconSize> = {
  md: 'sm',
  lg: 'md',
  xl: 'md',
}

const AccordionHeader = forwardRef<AccordionHeaderElement, AccordionHeaderProps>(
  function AccordionHeader(props, forwardedRef) {
    const { className, children, ...rest } = props

    const { size } = useAccordion()

    const classes = classNames(
      styles.root,
      {
        [styles[`size${size}`]]: size !== 'md',
      },
      className
    )

    const chevronSize = chevronSizeMapping[size]

    return (
      <RAccordion.Header {...rest} className={classes} asChild>
        <div>
          <RAccordion.Trigger className={styles.trigger} ref={forwardedRef}>
            <div className={styles.content}>{children}</div>
            <ChevronDownIcon className={styles.chevron} size={chevronSize} aria-hidden />
          </RAccordion.Trigger>
        </div>
      </RAccordion.Header>
    )
  }
)

export default AccordionHeader

import classNames from 'classnames'
import { forwardRef, useContext } from 'react'

import {
  PaginationSimple,
  PaginationSimpleElement,
  PaginationSimpleProps,
} from '../pagination-simple/pagination-simple'
import styles from './carousel-pagination.module.scss'
import { CarouselContext } from './context'

export type CarouselPaginationElement = PaginationSimpleElement

export type CarouselPaginationProps = Omit<PaginationSimpleProps, 'count'>

const CarouselPagination = forwardRef<CarouselPaginationElement, CarouselPaginationProps>(
  function CarouselPagination(props, forwardedRef) {
    const { children, className, ...rest } = props
    const classes = classNames(styles.root, className)

    const ctx = useContext(CarouselContext)

    return (
      <PaginationSimple
        className={classes}
        {...rest}
        count={ctx.pages.length}
        page={ctx.activePageIndex + 1}
        onPageChange={(page) => {
          const isNext = page > ctx.activePageIndex
          if (isNext) {
            ctx.next()
          } else {
            ctx.prev()
          }
          rest.onPageChange?.(page)
        }}
        ref={forwardedRef}
      />
    )
  }
)

export default CarouselPagination

import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './brand.module.scss'
import { BrandElement, BrandProps } from './common'
import { SvgJusSolucoes as Logo } from './svg/jus-solucoes'

export const BrandJusSolucoes = forwardRef<BrandElement, BrandProps>(function BrandJusSolucoes(
  props,
  forwardedRef
) {
  const { className, onlySymbol = false, version = 'positive', ...rest } = props

  const classes = classNames(
    styles.root,
    {
      [styles.positive]: version === 'positive',
      [styles.negative]: version === 'negative',
    },
    className
  )

  return (
    <Logo onlySymbol={onlySymbol} role="img" {...rest} className={classes} ref={forwardedRef} />
  )
})

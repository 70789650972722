export const filterOptions = [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Artigos e Notícias',
    value: 'artigos-noticias',
  },
  {
    label: 'Jurisprudência',
    value: 'jurisprudencia',
  },
  {
    label: 'Diários Oficiais',
    value: 'diarios',
  },
  {
    label: 'Modelos',
    value: 'modelos-pecas',
  },
  {
    label: 'Peças Processuais',
    value: 'pecas',
  },
  {
    label: 'Legislação',
    value: 'legislacao',
  },
  {
    label: 'Consulta Processual',
    value: 'consulta-processual',
  },
  {
    label: 'Doutrina',
    value: 'doutrina',
  },
]

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgUserMulti(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5a2 2 0 0 1 2.667-1.886 1 1 0 1 0 .666-1.886A4 4 0 0 0 5 5c0 1.838.915 3.313 1.74 4.276.186.216.372.412.547.585L4.4 11.595A7 7 0 0 0 1 17.597V18a3 3 0 0 0 3 3 1 1 0 1 0 0-2 1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287l4.086-2.453a1 1 0 0 0 .041-1.689l-.015-.01a6.403 6.403 0 0 1-.368-.285 7.757 7.757 0 0 1-.913-.899C7.585 7.187 7 6.162 7 5Zm8 0a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C16.416 9.186 17 8.161 17 7a2 2 0 0 0-2-2Zm4.602 8.595-2.89-1.734c.177-.173.362-.369.547-.585C18.085 10.313 19 8.838 19 7a4 4 0 0 0-8 0c0 1.838.915 3.313 1.74 4.276.186.216.371.412.547.585l-2.889 1.734A7 7 0 0 0 7 19.597V20a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-.403a7 7 0 0 0-3.398-6.002ZM15 13.166l-3.572 2.144A5 5 0 0 0 9 19.597V20a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-.403a5 5 0 0 0-2.427-4.287L15 13.166Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgUserMulti)

import { gql } from '@apollo/client'
import { Topbar, TopbarCurrentNavKey, TopbarPosition, TopbarUserMenuProps } from '@jusbrasil/topbar'
import { useEffect, useState } from 'react'

import { LawsuitTopbar_ViewerFragment } from './__generated__/lawsuit-topbar.graphql'
import { getTakeoverStatusFromBrowser } from './get-takeover-status-from-browser'

interface LawsuitTopbarProps {
  isLitigantUser: boolean
  isPaidCampaign?: boolean
  currentNavKey?: TopbarCurrentNavKey
  hideNavbar?: boolean
  hideSearchbar?: boolean
  position?: TopbarPosition
  isSubscriber: boolean
  hasMultipleProfiles: boolean
  viewer: LawsuitTopbar_ViewerFragment | null
}

export function LawsuitTopbar({
  isLitigantUser,
  isPaidCampaign,
  currentNavKey,
  hideNavbar,
  hideSearchbar,
  position = 'fixed-mobile-only',
  isSubscriber,
  hasMultipleProfiles,
  viewer,
}: LawsuitTopbarProps) {
  const { shouldHideNavbar, shouldHideSearchbar } = handleTopbarOptions(
    isPaidCampaign,
    hideNavbar,
    hideSearchbar
  )
  const [isTakeover, setTakeover] = useState(false)

  useEffect(() => {
    const { takeover } = getTakeoverStatusFromBrowser(viewer?.pid)
    setTakeover(takeover)
  }, [isTakeover, viewer?.pid])

  return (
    <Topbar
      currentNavKey={currentNavKey}
      position={position}
      variant={shouldHideNavbar ? 'slim' : 'default'}
    >
      <Topbar.A11yMenu />
      <Topbar.Header
        disableSearch={shouldHideSearchbar}
        userMenuProps={getUserMenuProps(
          isLitigantUser,
          isSubscriber,
          hasMultipleProfiles,
          isTakeover
        )}
      />
      <Topbar.Nav />
      <Topbar.LGPD />
      <Topbar.GoogleOneTap defaultEnabled />
    </Topbar>
  )
}

interface LawsuitTopbarOptions {
  shouldHideNavbar: boolean
  shouldHideSearchbar: boolean
}

export function handleTopbarOptions(
  isPaidCampaign: boolean | undefined,
  hideNavbar: boolean | undefined,
  hideSearchbar: boolean | undefined
): LawsuitTopbarOptions {
  if (isPaidCampaign) {
    return {
      shouldHideNavbar: true,
      shouldHideSearchbar: true,
    }
  }

  return {
    shouldHideNavbar: !!hideNavbar,
    shouldHideSearchbar: !!hideSearchbar,
  }
}

export function getUserMenuProps(
  isLitigantUser: boolean,
  isSubscriber: boolean,
  hasMultipleProfiles: boolean,
  isTakeover: boolean | undefined
): TopbarUserMenuProps | undefined {
  if (!isLitigantUser) return

  const additionalProItem = !isSubscriber ? ['pro', 'SEPARATOR'] : []
  const additionalTakeoverItem = isTakeover ? ['TAKEOVER', 'SEPARATOR'] : []
  const additionalProfileSwitch = hasMultipleProfiles ? ['PROFILE_SWITCH', 'SEPARATOR'] : []

  return {
    mountMenuItems: () => {
      return [
        ...additionalTakeoverItem,
        ...additionalProfileSwitch,
        ...additionalProItem,
        'lawsuits',
        'SEPARATOR',
        'help',
        'about',
        'settings',
        'logout',
      ]
    },
  } as TopbarUserMenuProps
}

LawsuitTopbar.fragments = {
  viewer: gql`
    fragment LawsuitTopbar_viewer on Profile {
      pid
    }
  `,
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './file-help-text.module.scss'
import { useFile } from './use-file'

export type FileHelpTextElement = HTMLParagraphElement

export interface FileHelpTextProps extends HTMLAttributes<FileHelpTextElement> {
  children: ReactNode
}

const FileHelpText = forwardRef<FileHelpTextElement, FileHelpTextProps>(function FileHelpText(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { selected } = useFile()
  const classes = classNames(
    styles.root,
    {
      [styles.selected]: selected,
    },
    className
  )

  return (
    <p {...rest} className={classes} ref={forwardedRef}>
      {children}
    </p>
  )
})

export default FileHelpText

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement } from 'react'

import { IconProps, IconSize } from '../icon/types'
import styles from './accordion-icon.module.scss'
import { AccordionSize } from './types'
import { useAccordion } from './use-accordion'

export type AccordionIconElement = HTMLSpanElement
export type AccordionIconProps = HTMLAttributes<AccordionIconElement>

const iconSizeMapping: Record<AccordionSize, IconSize> = {
  md: 'xs',
  lg: 'sm',
  xl: 'md',
}

const AccordionIcon = forwardRef<AccordionIconElement, AccordionIconProps>(function AccordionIcon(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const { size } = useAccordion()

  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: size === 'xl',
    },
    className
  )

  const iconSize = iconSizeMapping[size]

  return (
    <span {...rest} className={classes} ref={forwardedRef} aria-hidden>
      {isValidElement<IconProps>(children) ? cloneElement(children, { size: iconSize }) : children}
    </span>
  )
})

export default AccordionIcon

import { BodyText, Button, Heading, Link, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useCallback, useEffect, useState } from 'react'

import { useRequestCPFRemoval } from '../../../hooks'
import imageSrc from '../../../public/identity-validation-modal/document-in-use.svg'
import { EventData } from '../../../utils'
import { SUPPORT_CONTACT_URL } from '../constants'
import styles from './cpf-in-use.module.scss'

export interface CPFInUseStepProps {
  title?: string
  description?: string
  template?: string
  cpf: string
  birthDate: Date
  profileInUse: { pid: number; email: string }
  eventProps: {
    viewerPid: number
  } & EventData
  onSuccess: () => void
  onBack: () => void
  onError: () => void
}

export function CPFInUseStep(props: CPFInUseStepProps) {
  const {
    title,
    description,
    template = 'generic',
    profileInUse,
    cpf,
    birthDate,
    eventProps,
    onSuccess,
    onBack,
    onError,
  } = props
  const { viewerPid, ...eventData } = eventProps
  const pid = viewerPid?.toString() || null

  const [loading, setLoading] = useState(false)

  const requestCPFRemoval = useRequestCPFRemoval({ pid: profileInUse.pid, cpf })

  const getEventData = useCallback(
    (metadata: Record<string, unknown>, data: Record<string, unknown> = {}) => ({
      ...data,
      ...eventData,
      metadata: {
        cpf,
        birthDate: formatDate(birthDate?.getTime() || ''),
        ...metadata,
        ...eventData?.metadata,
      },
    }),
    [eventData, cpf, birthDate]
  )

  useEffect(() => {
    sendMetrics('UserAccount.IdValidationFailedViewed', pid, getEventData({}, { type: 'CPFInUse' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = () => {
    const eventName = 'UserAccount.IdValidationFailedSubmitted'
    sendMetrics(eventName, pid, getEventData({ button: 'ReviewInformation' }))

    onBack()
  }

  const handleContinue = () => {
    setLoading(true)
    const eventName = 'UserAccount.IdValidationFailedSubmitted'
    sendMetrics(eventName, pid, getEventData({ button: 'ChangeAccount' }))

    requestCPFRemoval(template)
      .then(() => onSuccess())
      .catch((error) => {
        console.error('Error sending removal email', error)
        onError()
      })
  }

  const modalTitle = title ?? 'Já existe um perfil verificado com este CPF'
  const modalDescription =
    description ?? 'Para acessar mais páginas, acesse o site do Jusbrasil com a conta'

  return (
    <>
      <Modal.Body className={styles.body} data-testid="cpf-in-use-step">
        <Image alt={modalTitle} src={imageSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {modalTitle}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          {modalDescription} <strong>{profileInUse?.email}</strong> ou cadastre este CPF à conta
          atual.
        </BodyText>
        <BodyText size="lg" className={styles.text}>
          Caso não reconheça essa conta, entre em{' '}
          <Link type="tertiary" href={SUPPORT_CONTACT_URL} target="_blank">
            contato com o suporte
          </Link>
          .
        </BodyText>
      </Modal.Body>
      <Modal.Footer>
        <Button kind="primary" onClick={handleContinue} disabled={loading}>
          Cadastrar CPF à conta atual
        </Button>
        <Button kind="tertiary" onClick={handleBack}>
          Revisar informações
        </Button>
      </Modal.Footer>
    </>
  )
}

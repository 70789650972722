import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import styles from './accordion-help-text.module.scss'
import { useAccordion } from './use-accordion'

export type AccordionHelpTextElement = HTMLSpanElement

export type AccordionHelpTextProps = HTMLAttributes<AccordionHelpTextElement> & {
  asChild?: boolean
}

const AccordionHelpText = forwardRef<AccordionHelpTextElement, AccordionHelpTextProps>(
  function AccordionHelpText(props, forwardedRef) {
    const { className, children, ...rest } = props

    const { size } = useAccordion()
    const classes = classNames(
      styles.root,
      {
        [styles[`size${size}`]]: size !== 'md',
      },
      className
    )

    return (
      <span {...rest} className={classes} ref={forwardedRef}>
        {children}
      </span>
    )
  }
)

export default AccordionHelpText

import { capitalize } from '@jusbrasil-web/shared-utils-format'

export const capitalizeFullName = (fullName: string) => {
  const prepositionsToIgnore = ['da', 'das', 'de', 'do', 'dos', 'e']
  return fullName
    .split(' ')
    .map((n) => {
      if (prepositionsToIgnore.includes(n.toLocaleLowerCase())) return n.toLocaleLowerCase()
      return capitalize(n)
    })
    .join(' ')
}

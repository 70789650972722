import { createContext, useContext } from 'react'

export type RadioCardGroupContextValue = {
  value?: string
  setValue: (value: string) => void
}

export const RadioCardGroupContext = createContext<RadioCardGroupContextValue>({
  setValue() {
    throw new Error('RadioCardGroupContext: setValue is not implemented')
  },
})

export const useRadioCardGroupContext = () => {
  return useContext(RadioCardGroupContext)
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCoupon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5h8a1 1 0 0 1 1 1v2a4 4 0 0 0 0 8v2a1 1 0 0 1-1 1h-8a1 1 0 0 0-2 0H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4a1 1 0 0 0 2 0ZM9.977 21H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v2.2a1.8 1.8 0 0 1-1.8 1.8H20a2 2 0 1 0 0 4h.2a1.8 1.8 0 0 1 1.8 1.8V18a3 3 0 0 1-3 3h-8.977a.869.869 0 0 1-.046 0ZM11 9a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0V9Zm0 5a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0v-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCoupon)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgIaSkillLegalResearch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.461 4a6.538 6.538 0 0 0-5.009 2.322 1 1 0 0 1-1.527-1.29A8.538 8.538 0 0 1 10.46 2C15.172 2 19 5.8 19 10.5a8.438 8.438 0 0 1-1.828 5.257l4.535 4.536a1 1 0 0 1-1.414 1.414l-4.538-4.538A8.528 8.528 0 0 1 10.461 19a8.602 8.602 0 0 1-1.928-.218 1 1 0 0 1 .449-1.949c.474.11.97.167 1.48.167C14.076 17 17 14.085 17 10.5S14.077 4 10.461 4Zm1.8 7.204.35-1.066a.825.825 0 0 1 .527-.527l1.066-.35a.275.275 0 0 0 0-.522l-1.066-.35a.825.825 0 0 1-.527-.527l-.35-1.066a.275.275 0 0 0-.522 0l-.35 1.066a.825.825 0 0 1-.527.527l-1.066.35a.275.275 0 0 0 0 .522l1.066.35c.25.082.445.277.527.527l.35 1.066c.082.252.44.252.522 0Zm-8.91.72c.22-.132.422-.29.602-.47l.803-1.37.366-1.298c.108-.381.648-.381.756 0l.366 1.299a3.14 3.14 0 0 0 2.171 2.17l1.299.367c.381.108.381.648 0 .756l-1.299.366a3.149 3.149 0 0 0-1.839 1.404 3.13 3.13 0 0 0-.332.767l-.366 1.299c-.108.381-.648.381-.756 0l-.366-1.299a3.14 3.14 0 0 0-2.171-2.17l-1.299-.367c-.381-.108-.381-.648 0-.756l1.299-.366a3.149 3.149 0 0 0 .767-.332Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgIaSkillLegalResearch)

import { gql, useLazyQuery } from '@apollo/client'

import { ProfileByValidatedCpfQuery } from './__generated__/use-get-profile-by-cpf.graphql'

export const PROFILE_BY_CPF_ROOT_QUERY = gql`
  query profileByValidatedCpf($cpf: String!) {
    root {
      profileByValidatedCpf(cpf: $cpf) {
        pid
        user {
          obfuscatedEmail(obfuscationLevel: "partial")
        }
      }
    }
  }
`

export function useGetProfileByCPF() {
  const [profileByValidatedCpf] =
    useLazyQuery<ProfileByValidatedCpfQuery>(PROFILE_BY_CPF_ROOT_QUERY)

  return (cpf: string) => {
    const variables = { cpf }

    return profileByValidatedCpf({ variables })
  }
}

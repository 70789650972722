import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgClock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.366 5.512c-.43-.363-.497-1.003-.117-1.418a13.086 13.086 0 0 1 2.21-1.926c.478-.33 1.123-.154 1.413.347.263.456.104 1.034-.327 1.336-.645.451-1.24.97-1.774 1.545-.37.398-.99.467-1.405.116ZM21.75 4.094c.38.415.312 1.055-.117 1.418-.414.351-1.036.282-1.405-.116a11.071 11.071 0 0 0-1.774-1.545c-.431-.302-.59-.88-.327-1.336.29-.501.935-.676 1.412-.347.808.558 1.55 1.205 2.211 1.926ZM12 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm4.739.653A8.958 8.958 0 0 1 12 22a8.958 8.958 0 0 1-4.739-1.347l-1.054 1.054a1 1 0 0 1-1.414-1.414l.886-.886a9 9 0 1 1 12.642 0l.886.886a1 1 0 0 1-1.414 1.414l-1.054-1.054ZM12 8a1 1 0 0 1 1 1v2.057l1.919-1.37a1 1 0 1 1 1.162 1.627l-3.5 2.5A1 1 0 0 1 11 13V9a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgClock)

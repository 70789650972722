import classNames from 'classnames'
import { forwardRef, HTMLAttributes, useEffect, useRef } from 'react'

import styles from './overlay.module.scss'

export type OverlayElement = HTMLDivElement

export interface OverlayProps extends HTMLAttributes<OverlayElement> {
  children?: never
  className?: string
  visible?: boolean
  disableEnterAnimation?: boolean
}

export const Overlay = forwardRef<OverlayElement, OverlayProps>(function Overlay(
  props,
  forwardedRef
) {
  const { className, visible = true, disableEnterAnimation = false, ...rest } = props

  const rendered = useRef(false)

  // used to prevent re-rendering animation on re-mount (e.g. when changing visible prop)
  useEffect(() => {
    rendered.current = true
  }, [])

  const classes = classNames(
    styles.root,
    {
      [styles.visible]: visible,
      [styles.enter]: !disableEnterAnimation && !rendered.current,
    },
    className
  )

  return (
    <div
      data-aria-hidden="true"
      aria-hidden="true"
      {...rest}
      className={classes}
      ref={forwardedRef}
    />
  )
})

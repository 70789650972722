import classNames from 'classnames'
import {
  AnchorHTMLAttributes,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  ReactNode,
} from 'react'

import { Slot, Slottable } from '../utilities'
import styles from './pagination-item.module.scss'
import { onlyValidAttributes } from './utils'

export type PaginationItemElement = HTMLAnchorElement

export type PaginationItemProps = AnchorHTMLAttributes<PaginationItemElement> & {
  number: number | null
  current?: boolean
  truncation?: boolean
  asChild?: boolean
}

const PaginationItem = forwardRef<PaginationItemElement, PaginationItemProps>(
  function PaginationItem(props, forwardedRef) {
    const {
      asChild: asChildProp = false,
      current = false,
      truncation = false,
      number,
      children,
      ...rest
    } = props

    const asChild = asChildProp && isValidElement<PaginationItemProps>(children)
    const Component = asChild ? Slot : 'a'

    const classes = classNames(styles.root, {
      [styles.current]: current,
      [styles.truncation]: truncation,
    })

    if (truncation || number === null) {
      return <span className={classes}>...</span>
    }

    const validAttributes = onlyValidAttributes(rest, props)

    return (
      <Component
        {...validAttributes}
        aria-current={current || undefined}
        className={classes}
        ref={forwardedRef}
      >
        <Slottable>
          {asChild
            ? cloneElement(Children.only(children), {}, renderNumber(number))
            : renderNumber(number)}
        </Slottable>
      </Component>
    )
  }
)

function renderNumber(number: number): ReactNode {
  return <span className={styles.number}>{number}</span>
}

export default PaginationItem

import { createContext } from 'react'

import { IS_ALLOWED_ENVIRONMENT } from './constants'
import { AppRootContextValue } from './types'

export const AppRootContext = createContext<AppRootContextValue>({
  getPageProp(key) {
    if (IS_ALLOWED_ENVIRONMENT) {
      console.warn(
        `[shared-app-root-provider] development only warning: getPageProp called with key ${key} but it's not implemented, add missing AppRootProvider`
      )
    }
  },
  getPagePropRequired(key) {
    if (IS_ALLOWED_ENVIRONMENT) {
      console.warn(
        `[shared-app-root-provider] development only warning: getPagePropRequired called with key ${key} but it's not implemented, add missing AppRootProvider`
      )
    }
  },
  loaded: false,
})

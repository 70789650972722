import { createContext } from 'react'

import { TreeViewTogglerProps } from './tree-view-toggler'

export type TreeViewContextType<ItemProps> = {
  expanded: string[]
  expand(nodeId: string): void
  collapse(nodeId: string): void
  select(nodeId: string): void
  isSelected(nodeId: string): boolean
  isVisualSelected(nodeId: string): boolean
  getItemProps(nodeId: string, prevProps: ItemProps): ItemProps
  getTogglerProps(nodeId: string, prevProps: TreeViewTogglerProps): TreeViewTogglerProps
}

export const TreeViewContext = createContext<TreeViewContextType<unknown>>(
  {} as TreeViewContextType<unknown>
)

import { sendMetrics } from '@jusbrasil-web/shared-utils-events'

import { SearchPageError } from '..'
import { Args, AutocompleteOption, EventContext, EventData } from '../types'

export const errorToEventName = (error: string) => {
  switch (error) {
    case SearchPageError.lawsuit_not_found:
    case SearchPageError.lawsuit_unavailable:
    case SearchPageError.unfinished_search:
      return 'LawsuitErrorCNJ'
    case SearchPageError.name_not_found:
      return 'LawsuitErrorName'
    case SearchPageError.cpf_not_found:
      return 'LawsuitErrorPerson'
    default:
      return ''
  }
}

export const notFoundEventData = (error: string) => {
  switch (error) {
    case SearchPageError.lawsuit_not_found:
    case SearchPageError.lawsuit_unavailable:
    case SearchPageError.unfinished_search:
      return {
        search_type: 'CNJ',
        error_type: 'CNJ',
      }
    case SearchPageError.name_not_found:
      return {
        search_type: 'NameOrOrganization',
        error_type: 'NAME',
      }
    case SearchPageError.cpf_not_found:
      return {
        search_type: 'CPF',
        error_type: 'CPF',
      }
    default:
      return {}
  }
}

export function handleSuggestionEvent(
  eventName: string,
  userId: string | null,
  query: string,
  index: number,
  metadata?: Record<string, string>,
  fromComponent?: string
) {
  const props = {
    click_position: index,
    click_suggestion: query,
    artifact: 'consulta-processual',
    is_pasted: false,
    from_component: fromComponent,
    user_id: userId,
    metadata: JSON.stringify(metadata),
  }
  sendMetrics(`Autocomplete.${eventName}`, userId, props)
}

export const registerEvent = (
  eventName: string,
  pid: string | null,
  eventData: EventData,
  args: Args = {}
) => {
  sendMetrics(eventName, pid, {
    ...eventData,
    ...args,
    metadata: { ...eventData?.metadata, ...args?.metadata },
  })
}

export const sendSuggestionEvent = (
  option: AutocompleteOption,
  selectedIndex: number,
  pid: string | null,
  fromComponent: string
) => {
  const eventName = option.history ? 'LocalSuggestionClicked' : 'SuggestionClicked'
  handleSuggestionEvent(eventName, pid, option.value, selectedIndex, option.metadata, fromComponent)
}

export const registerSearchViewedEvent = (
  query: string,
  eventDocument: string | null,
  hasResults: boolean,
  fromComponent: string,
  pid: string | null,
  eventContext: EventContext
) => {
  const sanitizedQuery = query.replace(/[_.-]/g, '').trim()
  const referrer = eventContext?.referrer

  const metricsProps = {
    from_component: fromComponent,
    app_id: eventContext?.appId,
    dvce_ismobile: eventContext?.deviceType === 'mobile' || false,
    dvce_type: eventContext?.deviceType,
    num_results: 1,
    sanitized_query: sanitizedQuery,
    referrer,
    source: referrer,
    query,
    documents: eventDocument ? [eventDocument] : [],
    hasResults,
    section: 'consulta-processual',
    metadata: eventContext?.metadata || {},
  }

  sendMetrics('Search.Viewed', pid, metricsProps)
}

import {
  Avatar,
  Badge,
  ChevronDownIcon,
  Drawer,
  DrawerTriggerElement,
  IconButton,
  UserIcon,
  VisuallyHidden,
} from '@farol-ds/react'
import { forwardRef, useContext, useMemo } from 'react'

import { TopbarContext } from '../../context'
import { TopbarSkeleton } from '../topbar-skeleton'
import styles from './topbar-user-menu-trigger.module.scss'

export type TopbarUserMenuTriggerElement = DrawerTriggerElement

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TopbarUserMenuTriggerProps {}

export const TopbarUserMenuTrigger = forwardRef<
  TopbarUserMenuTriggerElement,
  TopbarUserMenuTriggerProps
>(function TopbarUserMenuTrigger(props, forwardedRef) {
  const { logged, user, counters, ready } = useContext(TopbarContext)

  const counterTotal = useMemo(() => {
    if (!counters) return 0
    return Object.values(counters).reduce((acc, cur) => acc + cur, 0)
  }, [counters])

  if (!ready && logged) {
    return (
      <Drawer.Trigger data-testid="topbar-header-user-menu-trigger">
        <TopbarSkeleton
          animateColor
          display="flex"
          alignItems="center"
          marginLeft="4xs"
          className={styles.skelAvatar}
        >
          <TopbarSkeleton animateBg radius="circle" style={{ height: 32, width: 32 }} />
          <ChevronDownIcon size="sm" />
        </TopbarSkeleton>
      </Drawer.Trigger>
    )
  }

  return (
    <Drawer.Trigger data-testid="topbar-header-user-menu-trigger">
      {user ? (
        <button
          className={styles.userMenu}
          aria-labelledby={`topbar-user-label${counterTotal > 0 ? ' topbar-user-badge' : ''}`}
        >
          <VisuallyHidden id="topbar-user-label">Minha conta</VisuallyHidden>
          <Badge
            variant="dot"
            type="secondary"
            overlap="circular"
            count={counterTotal}
            aria-label="notificações não lidas"
            id="topbar-user-badge"
          >
            <Avatar size="lg" alt={user.name} src={user.image || undefined} />
          </Badge>
          <ChevronDownIcon size="sm" />
        </button>
      ) : (
        <IconButton
          className={styles.anonyMenu}
          size="md"
          icon={<UserIcon />}
          label="Minha conta"
        />
      )}
    </Drawer.Trigger>
  )
})

import { HTMLAttributes } from 'react'

export type QuoteCiteProps = HTMLAttributes<HTMLElement>

const QuoteCite: React.FC<QuoteCiteProps> = (props) => {
  const { children, ...rest } = props
  return <cite {...rest}>{children}</cite>
}

export default QuoteCite

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './form-control-error-message.module.scss'
import { useFormControl } from './use-form-control'

export type FormControlErrorMessageElement = HTMLParagraphElement

export interface FormControlErrorMessageProps
  extends HTMLAttributes<FormControlErrorMessageElement> {
  children: ReactNode
  className?: string
}

const FormControlErrorMessage = forwardRef<
  FormControlErrorMessageElement,
  FormControlErrorMessageProps
>(function FormControlErrorMessage(props, forwardedRef) {
  const { className, children, ...rest } = props
  const formControl = useFormControl()

  if (!children || !formControl?.error) return null

  const propsFromFormControl = formControl.getErrorMessageProps?.(forwardedRef)
  const classes = classNames(styles.root, propsFromFormControl?.className, className)

  return (
    <p ref={forwardedRef} {...propsFromFormControl} {...rest} className={classes}>
      {children}
    </p>
  )
})

export default FormControlErrorMessage

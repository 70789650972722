import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgWord(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5a1 1 0 0 1 1-1h5v4a2 2 0 0 0 2 2h4v9a1 1 0 0 1-1 1H7.83c.11-.313.17-.65.17-1V5ZM6 19a1 1 0 1 1-2 0v-3h2v3Zm-2-5h2V5a3 3 0 0 1 3-3h6.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 22 8.828V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-3a2 2 0 0 1 2-2Zm15.586-6L16 4.414V8h3.586Zm-9.351 6.173.504 2.524a1.62 1.62 0 0 0 2.736.828L14 17l.525.525a1.621 1.621 0 0 0 2.736-.828l.504-2.524a.98.98 0 1 0-1.923-.385l-.387 1.938-.408-.344a1.624 1.624 0 0 0-2.094 0l-.408.344-.387-1.938a.98.98 0 0 0-1.923.385Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgWord)

import { gql } from '@apollo/client'

const profileSwitchFragment = gql`
  fragment ProfileSwitchFragment on Profile {
    id
    pid
    fullname
    username
    cpf
    avatar: thumborAvatar {
      url: thumbUrl(size: "48x48")
    }
  }
`

export const TOPBAR_QUERY = gql`
  query TopbarRoot {
    root {
      viewer: me {
        ...ProfileSwitchFragment
        user {
          isAdmin
          mainProfile {
            ...ProfileSwitchFragment
          }
          profiles {
            edges {
              node {
                ...ProfileSwitchFragment
              }
            }
          }
        }
        hasAccessToSomeBundle
        hasDoctrine
        isLawyer
        subscribeLink {
          label
          url
          isJusIA
        }
        boxProfileInfo {
          linkedAccount
        }
      }
    }
  }

  ${profileSwitchFragment}
`

import classNames from 'classnames'
import { forwardRef } from 'react'

import { CloseIcon } from '../icon/icon'
import { IconButton, IconButtonElement, IconButtonProps } from '../icon-button/icon-button'
import commonStyles from './common.module.scss'
import DrawerClose, { DrawerCloseProps } from './drawer-close'
import styles from './drawer-icon-close.module.scss'

export type DrawerIconCloseElement = IconButtonElement

export type DrawerIconCloseProps = Partial<IconButtonProps> & {
  drawerCloseProps?: DrawerCloseProps
}

const DrawerIconClose = forwardRef<DrawerIconCloseElement, DrawerIconCloseProps>(
  function DrawerIconClose(props, forwardedRef) {
    const {
      label = 'Fechar',
      icon = <CloseIcon />,
      className,
      asChild: _,
      children: __,
      drawerCloseProps,
      ...rest
    } = props

    const classes = classNames(styles.root, commonStyles.iconClose, className)

    return (
      <DrawerClose {...drawerCloseProps}>
        <IconButton
          label={label}
          icon={icon}
          {...rest}
          size="md"
          className={classes}
          tooltipProps={{ open: false }}
          ref={forwardedRef}
        />
      </DrawerClose>
    )
  }
)

export default DrawerIconClose

import { BodyText, Button, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { formatDate } from '@jusbrasil-web/shared-utils-format'
import { useEffect, useMemo } from 'react'

import { useModalContext } from '../context'
import { Steps } from '../enums'
import styles from './steps.module.scss'

export interface StaticStepProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageSrc: any
  title: string
  bodyText: string | React.ReactNode
  bodyText2?: string | React.ReactNode
  action?: {
    onClick?: () => void
    disabled?: boolean
    nextStep?: Steps
    label: string
  }
  closeButton?: {
    onClick?: () => void
    label: string
  }
  testId?: string
  onLoadEvent?: {
    name: string
    metadata?: Record<string, unknown>
    data?: Record<string, unknown>
  }
}
export function StaticStep(props: StaticStepProps) {
  const { action, closeButton, imageSrc, title, bodyText, bodyText2, testId, onLoadEvent } = props
  const { setCurrentStep, sharedData, eventData: eventContextData, viewer } = useModalContext()
  const pid = viewer?.pid.toString() || null

  const identityMetadata = useMemo(
    () =>
      sharedData?.cpf
        ? {
            cpf: sharedData.cpf,
            birthDate: formatDate(sharedData.birthDate?.getTime() || ''),
          }
        : {},
    [sharedData?.cpf, sharedData?.birthDate]
  )

  const eventData = useMemo(
    () => ({
      ...onLoadEvent?.data,
      ...eventContextData,
      metadata: {
        ...identityMetadata,
        ...onLoadEvent?.metadata,
        ...eventContextData?.metadata,
      },
    }),
    [onLoadEvent?.data, onLoadEvent?.metadata, eventContextData, identityMetadata]
  )

  useEffect(() => {
    if (onLoadEvent) sendMetrics(onLoadEvent.name, pid, eventData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoadEvent?.name, pid])

  return (
    <>
      <Modal.Body className={styles.body} data-testid={testId}>
        <Image alt={title} src={imageSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {title}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          {bodyText}
        </BodyText>
        {bodyText2 && (
          <BodyText size="lg" className={styles.text}>
            {bodyText2}
          </BodyText>
        )}
      </Modal.Body>
      {!!action && (
        <Modal.Footer className={closeButton ? styles.modalFooter : ''}>
          <Button
            kind="primary"
            disabled={action.disabled}
            onClick={() => {
              if (action.onClick) action.onClick()
              if (action.nextStep) setCurrentStep(action.nextStep)
            }}
          >
            {action.label}
          </Button>

          {!!closeButton && (
            <Button
              onClick={() => {
                if (closeButton.onClick) closeButton.onClick()
              }}
            >
              {closeButton.label}
            </Button>
          )}
        </Modal.Footer>
      )}
    </>
  )
}

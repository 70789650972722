import * as RPortal from '@radix-ui/react-portal'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

export type PortalElement = HTMLDivElement

export interface PortalProps extends HTMLAttributes<PortalElement> {
  children: ReactNode
  asChild?: boolean
  container?: HTMLElement | null
}

export const Portal = forwardRef<PortalElement, PortalProps>(function Portal(props, forwardedRef) {
  return <RPortal.Root {...props} ref={forwardedRef} />
})

import { ComponentType, SVGProps } from 'react'

export const IconSizes = ['xs', 'sm', 'md', 'lg'] as const
export type IconElement = SVGSVGElement
export type IconSize = (typeof IconSizes)[number]
export type IconComponent = ComponentType<IconProps>

export interface IconProps extends SVGProps<IconElement> {
  size?: IconSize
  className?: string
}

export type IconComponentType = {
  (props: IconProps): JSX.Element
  displayName: string
  __wrappedIcon: true
}

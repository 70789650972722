import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNotification(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.07V3a1 1 0 1 0-2 0v1.07A7.002 7.002 0 0 0 5 11v3.664c0 .348-.103.688-.296.977l-.536.804A1 1 0 0 0 5 18h14a1 1 0 0 0 .832-1.555l-.536-.804a1.76 1.76 0 0 1-.296-.977V11a7.002 7.002 0 0 0-6-6.93ZM14 21a1 1 0 0 1-1 1h-2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1ZM7 11a5 5 0 1 1 10 0v3.664c0 .459.084.911.245 1.336H6.755c.161-.425.245-.877.245-1.336V11Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNotification)

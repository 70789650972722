import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgFire(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.275 2.038A1 1 0 0 0 11 3c0 1.89-1.217 2.846-3.003 4.248l-.115.09C6.117 8.727 4 10.49 4 14a8 8 0 1 0 16 0 1 1 0 0 0-1.848-.53 7.712 7.712 0 0 1-.365.512c-.243.314-.571.694-.938 1.009-.38.326-.719.509-.981.556-.188.035-.485-.078-.66-.254a.288.288 0 0 1-.088-.234c.015-.149.101-.396.332-.742.935-1.398 1.608-2.713 1.89-4.058.288-1.375.153-2.714-.413-4.13-.459-1.146-1.296-2.067-2.141-2.735-.843-.667-1.772-1.144-2.513-1.356Zm3.95 15.477c.291-.053.566-.149.823-.27A6 6 0 0 1 6 14c0-2.49 1.383-3.727 3.118-5.09l.297-.231c1.314-1.024 2.873-2.237 3.4-4.221.24.141.487.31.732.503.67.53 1.236 1.188 1.524 1.91.434 1.083.513 2.022.313 2.977-.206.984-.722 2.051-1.595 3.358-.318.476-.598 1.042-.659 1.654a2.232 2.232 0 0 0 .663 1.846c.729.729 1.59.962 2.433.808Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgFire)

import { BodyText, Button, Heading, Link, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'
import { useEffect } from 'react'

import errorImgSrc from '../../../public/identity-validation-modal/error.svg'
import { EventData } from '../../../utils'
import { SUPPORT_CONTACT_URL } from '../constants'
import styles from './validate-identity-error.module.scss'

interface ValidateIdentityErrorStepProps {
  eventProps: {
    viewerPid: number
  } & EventData
  onContinue: () => void
}

export function ValidateIdentityErrorStep(props: ValidateIdentityErrorStepProps) {
  const { onContinue, eventProps } = props

  useEffect(() => {
    const { viewerPid, ...eventData } = eventProps
    sendMetrics('UserAccount.IdValidationFailedViewed', viewerPid.toString(), {
      ...eventData,
      type: 'CPFInvalid',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = 'Identidade não pôde ser verificada'

  return (
    <>
      <Modal.Body className={styles.body} data-testid="validate-identity-error-step">
        <Image alt={title} src={errorImgSrc} height={200} width={200} className={styles.image} />
        <Heading size="lg" className={styles.title}>
          {title}
        </Heading>
        <BodyText size="lg" className={styles.text}>
          Por favor, revise suas informações ou entre em{' '}
          <Link href={SUPPORT_CONTACT_URL} target="_blank">
            contato com o suporte
          </Link>
          .
        </BodyText>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button kind="primary" onClick={onContinue}>
          Revisar Informações
        </Button>
      </Modal.Footer>
    </>
  )
}

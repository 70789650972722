import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgShield(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.627 2.22a1 1 0 0 0-1.254 0C9.92 3.39 8.303 3.883 5.958 3.98 4.9 4.027 4 4.884 4 6v7.456c0 1.859.914 3.738 2.257 5.239 1.352 1.51 3.223 2.734 5.305 3.231.288.07.588.07.876 0 2.082-.497 3.953-1.72 5.305-3.231 1.343-1.5 2.257-3.38 2.257-5.239V6c0-1.117-.9-1.974-1.958-2.02-2.344-.098-3.96-.59-5.416-1.76ZM6.042 5.98c2.334-.1 4.236-.563 5.958-1.736 1.722 1.173 3.624 1.637 5.958 1.735.017 0 .03.008.038.016A.03.03 0 0 1 18 6v7.456c0 1.224-.623 2.649-1.747 3.905-1.11 1.239-2.625 2.218-4.253 2.614-1.628-.396-3.144-1.375-4.253-2.614C6.623 16.105 6 14.68 6 13.456V6l.004-.005a.057.057 0 0 1 .038-.016ZM6 6Zm12 0Zm-5.106 3.553a1 1 0 0 0-1.789 0l-2 4a1 1 0 1 0 1.79.894L12 12.237l1.105 2.21a1 1 0 0 0 1.79-.894l-2-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgShield)

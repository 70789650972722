import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9V4H6a1 1 0 0 0-1 1v11.17c.313-.11.65-.17 1-.17h13V4h-2v5a1 1 0 0 1-1.6.8L14 8.75 12.6 9.8A1 1 0 0 1 11 9Zm4-5h-2v3l.4-.3a1 1 0 0 1 1.2 0l.4.3V4Zm6 13v4a1 1 0 0 1-1 1H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h13a2 2 0 0 1 2 2v13Zm-2 1H6a1 1 0 1 0 0 2h13v-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgBook)

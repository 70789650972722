import * as RContextMenu from '@radix-ui/react-context-menu'
import { forwardRef } from 'react'

export type ContextMenuTriggerElement = HTMLSpanElement

export type ContextMenuTriggerProps = RContextMenu.ContextMenuTriggerProps

const ContextMenuTrigger = forwardRef<ContextMenuTriggerElement, ContextMenuTriggerProps>(
  function ContextMenuTrigger(props, forwardedRef) {
    const { children, ...rest } = props

    return (
      <RContextMenu.Trigger {...rest} ref={forwardedRef}>
        {children}
      </RContextMenu.Trigger>
    )
  }
)

export default ContextMenuTrigger

import { cleanupHistoryState } from '@jusbrasil-web/shared-browser-back-button-listener'

export const handleOnCloseCallback = (modalName = 'modal') => {
  const hasHistory = window.history.state && window.history.state[modalName] === true

  if (!hasHistory) {
    return
  }
  cleanupHistoryState(modalName)
}

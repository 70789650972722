import { dateTimeFormatter } from '../date-time-formatter/date-time-formatter'

export const formatDate = (date: string | number): string => {
  /**
   * WARNING:
   * This is added because, in some cases,
   * when calling this function inside a function outside
   * of the main scope, it will be able to receive an
   * undefined without throwing an error.
   *
   * Since this is an util that should be
   * widely used, making this failsafe validation
   * will help ensure less errors in our applications.
   */
  if (!date) {
    return 'Invalid Date'
  }

  return dateTimeFormatter.format(+date)
}

/**
 * @returns a 'YYYY-MM-DD HH:MM:SS' date string
 *
 * @param {string | number} date - A required param that will be used as argument for Date constructor
 */
export const formatDateTime = (date: string | number): string => {
  if (!date) {
    return 'Invalid Date'
  }

  // Convert from UTC Time to GMT-3
  const d = new Date(
    new Intl.DateTimeFormat('en', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(+date)
  )

  const day = normalize(d.getDate())
  const month = normalize(d.getMonth() + 1)
  const year = d.getFullYear()
  const hours = normalize(d.getHours())
  const minutes = normalize(d.getMinutes())
  const seconds = normalize(d.getSeconds())
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function normalize(value: number): string {
  return `0${value}`.slice(-2)
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgNote(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8.172a3 3 0 0 0 2.12-.879l6.83-6.828A3 3 0 0 0 22 12.172V4a2 2 0 0 0-2-2H4Zm16 9V4H4v16h7a1 1 0 0 0 1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 0 1-1Zm-6 7.586V14h4.586L14 18.586Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgNote)

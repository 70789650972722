import classNames from 'classnames'
import { HTMLAttributes } from 'react'

import styles from './line-scroll.module.scss'

export type LineScrollRootElement = HTMLDivElement
export type LineScrollProps = HTMLAttributes<LineScrollRootElement>

export function LineScrollRoot(props: LineScrollProps) {
  const { children, className, ...rest } = props
  const classes = classNames(styles.root, className)
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './banner-content.module.scss'

export type BannerContentElement = HTMLDivElement

export interface BannerContentProps extends HTMLAttributes<BannerContentElement> {
  children: ReactNode
  className?: string
}

const BannerContent = forwardRef<BannerContentElement, BannerContentProps>(function BannerContent(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      {children}
    </div>
  )
})

export default BannerContent

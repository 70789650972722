import classNames from 'classnames'
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useRef,
} from 'react'

import { Slot, Slottable } from '../utilities'
import { AutocompleteMenuElement } from './autocomplete-menu'
import styles from './autocomplete-root.module.scss'
import { AutocompleteTriggerElement } from './autocomplete-trigger'
import { AutocompleteContext, AutocompleteContextValue } from './context'

export type AutocompleteRootContainerElement = HTMLDivElement

export type AutocompleteRootElement = {
  trigger: AutocompleteTriggerElement | null
  menu: AutocompleteMenuElement<never> | null
}

export interface AutocompleteRootProps extends HTMLAttributes<AutocompleteRootContainerElement> {
  children: ReactNode
  asChild?: boolean
  containerRef?: RefObject<AutocompleteRootContainerElement>
}

const AutocompleteRoot = forwardRef<AutocompleteRootElement, AutocompleteRootProps>(
  function AutocompleteRoot(props, forwardedRef) {
    const { children, asChild, className, containerRef, ...rest } = props
    const Component = asChild ? Slot : 'div'
    const classes = classNames(styles.root, className)

    const menuRef = useRef<AutocompleteMenuElement<never>>(null)
    const triggerRef = useRef<AutocompleteTriggerElement>(null)

    const context: AutocompleteContextValue = {
      triggerRef,
      menuRef,
    }

    useImperativeHandle(forwardedRef, () => ({
      get trigger() {
        return triggerRef.current
      },
      get menu() {
        return menuRef.current
      },
    }))

    return (
      <AutocompleteContext.Provider value={context}>
        <Component {...rest} className={classes} ref={containerRef}>
          <Slottable>{children}</Slottable>
        </Component>
      </AutocompleteContext.Provider>
    )
  }
)

export default AutocompleteRoot

import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'

export type DropdownTriggerElement = typeof RDropdownMenu.Trigger
export type DropdownTriggerProps = RDropdownMenu.DropdownMenuTriggerProps

const DropdownTrigger: React.FC<DropdownTriggerProps> = (props) => {
  return <RDropdownMenu.Trigger asChild {...props} />
}

export default DropdownTrigger

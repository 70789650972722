import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgShare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.617 4.076a1 1 0 0 1 1.09.217l7 7a1 1 0 0 1 0 1.414l-7 7A1 1 0 0 1 12 19v-3H8a3 3 0 0 0-3 3 1 1 0 1 1-2 0v-4a7 7 0 0 1 7-7h2V5a1 1 0 0 1 .617-.924ZM5 15c.836-.628 1.874-1 3-1h5a1 1 0 0 1 1 1v1.586L18.586 12 14 7.414V9a1 1 0 0 1-1 1h-3a5 5 0 0 0-5 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgShare)

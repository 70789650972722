import { isValidElement } from 'react'

import { IconComponentType, IconProps } from './types'

export function isValidIconElement(
  element: React.ReactNode
): element is React.ReactElement<IconProps> {
  if (!isValidElement(element)) {
    return false
  }
  if (!element.type) {
    return false
  }
  return !!(element.type as IconComponentType).__wrappedIcon
}

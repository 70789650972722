import { createContext, useContext, useState } from 'react'

import { Nullable } from '../../utils'
import { IdentityValidationModal_ViewerFragment } from './__generated__/identity-validation-modal.graphql'
import { Steps } from './enums'
import { TopicEventData } from './identity-validation-modal'

export interface ModalContextProps {
  setCurrentStep: (currentStep: Steps) => void
  children: React.ReactNode
  replacementToken: Nullable<string>
  eventData: TopicEventData
  viewer: IdentityValidationModal_ViewerFragment | null
}

export interface SharedDataInterface {
  cpf?: string
  birthDate?: Date
  profileInUse?: {
    pid: number
    email: string
  }
}

export interface ContextDataInterface {
  setCurrentStep: (currentStep: Steps) => void
  sharedData: SharedDataInterface
  setSharedData: (data: Record<string, unknown>) => void
  replacementToken: Nullable<string>
  eventData: TopicEventData
  viewer: IdentityValidationModal_ViewerFragment | null
}

const ModalContext = createContext<Nullable<ContextDataInterface>>(null)

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a ModalContextProvider')
  }
  return context
}

export function ModalContextProvider(props: ModalContextProps) {
  const { setCurrentStep, children, replacementToken, viewer, eventData } = props
  const [sharedData, setSharedData] = useState<SharedDataInterface>({})

  const contextData = {
    setCurrentStep,
    sharedData,
    setSharedData,
    replacementToken,
    viewer,
    eventData,
  }

  return <ModalContext.Provider value={contextData}>{children}</ModalContext.Provider>
}

import { assignSubComponents } from '@farol-ds/react/utilities/internal'

import { TopbarContext } from './context'
import { TopbarNextProvider, TopbarNextRoot, TopbarNextRootProps } from './next'
import {
  TopbarA11YMenu,
  TopbarBrand,
  TopbarGoogleOneTap,
  TopbarHeader,
  TopbarLGPD,
  TopbarNav,
  TopbarRoot,
  TopbarUserMenu,
} from './ui'

export type TopbarProps = TopbarNextRootProps

export const Topbar = assignSubComponents('Topbar', TopbarNextRoot, {
  // next (including Root above)
  Provider: TopbarNextProvider,
  // ui
  Context: TopbarContext,
  Root: TopbarRoot,
  A11yMenu: TopbarA11YMenu,
  GoogleOneTap: TopbarGoogleOneTap,
  LGPD: TopbarLGPD,
  Brand: TopbarBrand,
  Header: TopbarHeader,
  Nav: TopbarNav,
  UserMenu: TopbarUserMenu,
})

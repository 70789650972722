export function truncateSelectedContent() {
  if (!window) {
    return ''
  }

  let content = window.getSelection()?.toString()
  if (!content) {
    return ''
  }

  content = content.replace(/[\r\n]{2,}/g, '\n')
  if (content.length > 240) {
    content = `${content.substring(0, 120)}...${content.substring(content.length - 120)}`
  }

  return content
}

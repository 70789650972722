import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgCloseSidebar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2V5Zm2 0v14h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H9ZM5 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H5Zm10.6 6.2a1 1 0 0 1 .2 1.4L14.75 12l1.05 1.4a1 1 0 0 1-1.6 1.2l-1.5-2a1 1 0 0 1 0-1.2l1.5-2a1 1 0 0 1 1.4-.2Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgCloseSidebar)

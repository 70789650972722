import { destroyJusbrasilCookie, getCookie } from '@jusbrasil-web/shared-utils-cookies'

import { EVENT_SOURCE_COMPONENT_COOKIE_NAME } from '../constants'
import { runOnBrowser } from './run-on-browser'

export const sourceComponent = runOnBrowser(() => {
  const value = getCookie(null, EVENT_SOURCE_COMPONENT_COOKIE_NAME)
  destroyJusbrasilCookie(null, EVENT_SOURCE_COMPONENT_COOKIE_NAME)

  return value
}, undefined)

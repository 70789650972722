import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { ensurePropOf, Slot, Slottable } from '../utilities'
import styles from './book-cover.module.scss'
import { BookCoverMask } from './book-cover-mask'

export const BookCoverSizes = ['xs', 'sm', 'md', 'lg', '5xl'] as const
export type BookCoverSize = (typeof BookCoverSizes)[number]
export type BookCoverElement = HTMLDivElement

export interface BookCoverProps extends HTMLAttributes<BookCoverElement> {
  children: ReactNode
  size?: BookCoverSize
  className?: string
  asChild?: boolean
}

export const BookCover = forwardRef<BookCoverElement, BookCoverProps>(function BookCover(
  props,
  forwardedRef
) {
  const { children, size: sizeProp, className, asChild, ...rest } = props

  const size = ensurePropOf<BookCoverSize>(BookCoverSizes, sizeProp, 'md')

  const classes = classNames(
    styles.root,
    {
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      <BookCoverMask className={styles.mask} />
      <Slottable>{renderChildren(props)}</Slottable>
    </Component>
  )
})

function renderChildren(props: BookCoverProps) {
  const { asChild, children } = props

  if (asChild && isValidElement<HTMLAttributes<HTMLElement>>(children)) {
    return cloneElement(children, {}, applyChildrenStyle(children.props.children))
  }
  return applyChildrenStyle(children)
}

function applyChildrenStyle(child: ReactNode) {
  if (!isValidElement<HTMLAttributes<HTMLElement>>(child)) {
    return child
  }
  return cloneElement(child, {
    key: 'child',
    className: classNames(styles.image, child.props.className),
  })
}

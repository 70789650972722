import { forwardRef } from 'react'

import { Brand, BrandElement, BrandProps } from '../brand/brand'
import styles from './modal-header-brand.module.scss'

export type ModalHeaderBrandElement = BrandElement

export type ModalHeaderBrandProps = BrandProps

const ModalHeaderBrand = forwardRef<ModalHeaderBrandElement, ModalHeaderBrandProps>(
  function ModalHeaderBrand(props, forwardedRef) {
    return (
      <div className={styles.root}>
        <Brand {...props} width={28} onlySymbol ref={forwardedRef} />
      </div>
    )
  }
)

export default ModalHeaderBrand

import { useMemo } from 'react'

import { AvatarColor, AvatarColors, AvatarProps } from '../types'
import { initials } from './initials'
import { sample } from './sample'

export interface UseAvatarResult {
  letters: string | null
  color: AvatarColor
}

export function useAvatar(props: AvatarProps): UseAvatarResult {
  return useMemo(() => {
    const letters = props.children?.slice(0, 2) || initials(props.alt)
    const color = sample(letters, AvatarColors)
    if (props.size === 'sm') {
      return { letters: letters?.[0] || null, color }
    }
    return { letters, color }
  }, [props])
}

import type { ConfigurePlanModalRootContainerProps } from '@jusbrasil-rc/configure-plan-modal'
import { useFrameBusClient } from '@jusbrasil-web/shared-frame-bus'
import { useCallback, useEffect, useRef, useState } from 'react'

export const FRAME_BUS_ID = 'configure-plan-modal'
export const FRAME_BUS_URL = '/web/lawsuit/iframe-configure-plan-modal'

export const useConfigurePlanModal = () => {
  const [enabled, setEnabled] = useState(false)
  const [connected, setConnected] = useState(false)
  const lastPropsRef = useRef<ConfigurePlanModalRootContainerProps | null>()

  const frameBusId = FRAME_BUS_ID
  const frameUrl = FRAME_BUS_URL

  const frame = useFrameBusClient<{
    setProps(props: ConfigurePlanModalRootContainerProps): void
  }>({
    id: frameBusId,
    src: frameUrl,
    enabled,
    defaultOpen: true,
    onConnect() {
      setConnected(true)
    },
    onReconnect() {
      frame.close()
    },
    onPopstate() {
      frame.close()
    },
    listeners: {
      onClose() {
        lastPropsRef.current = null
      },
    },
  })

  const openModal = useCallback(() => {
    if (!enabled) return
    if (!lastPropsRef.current) return
    if (connected) {
      frame.triggerHost('setProps', lastPropsRef.current)
      frame.open()
    }
  }, [frame, enabled, connected])

  useEffect(openModal, [openModal])

  return {
    loading: enabled && !connected,
    open(props: ConfigurePlanModalRootContainerProps) {
      lastPropsRef.current = props
      if (connected) {
        openModal()
      } else if (!enabled) {
        setEnabled(true)
      }
    },
  }
}

import { gql } from '@apollo/client'
import { Modal, VisuallyHidden } from '@farol-ds/react'
import { handleTriggerExecution } from '@jusbrasil-web/shared-utils-url'
import { useEffect, useState } from 'react'

import { IdentityValidationModal_ViewerFragment } from './__generated__/identity-validation-modal.graphql'
import { ModalContextProvider } from './context'
import { Steps } from './enums'
import { FlowStepsBodyText } from './flow-steps'
import styles from './identity-validation-modal.module.scss'
import { StepRenderer } from './step-renderer'

const CONTINUE_REPLACING_FLOW = 'continue-validate-flow'

export interface TopicEventData {
  app_id?: string
  artifact?: string
  detected_entity_type?: string
  from_component?: string
  dvce_ismobile?: boolean
  dvce_type?: string
  referrer?: string
  source?: string
  topic_id?: string
  dwell_time_ms?: number
  metadata?: Record<string, unknown>
}

interface IdentityValidationModalProps {
  viewer: IdentityValidationModal_ViewerFragment | null
  isOpen: boolean
  eventData: TopicEventData
  currentUrl?: string
  validateCallback?: (isValidateSuccess: boolean) => void
  flowStepsBodyText?: FlowStepsBodyText
  onOpenChange?: (isOpen: boolean) => void
}

export function IdentityValidationModal(props: IdentityValidationModalProps) {
  const {
    flowStepsBodyText: flowStepsText,
    currentUrl,
    isOpen,
    eventData,
    viewer,
    validateCallback,
    onOpenChange,
  } = props

  const onClose = () => {
    onOpenChange?.(false)
    currentStep === Steps.REPLACEMENT_SUCCESS && setIsReplacingFlow(false)
  }

  const url = currentUrl && new URL(currentUrl)
  const replacementToken = url && new URLSearchParams(url.search).get(CONTINUE_REPLACING_FLOW)

  const initialStep = replacementToken ? Steps.REPLACING_CPF : Steps.ACCESS_LIMIT_REACHED
  const [currentStep, setCurrentStep] = useState<Steps>(initialStep)
  const [isReplacingFlow, setIsReplacingFlow] = useState(currentStep === Steps.REPLACING_CPF)

  useEffect(() => {
    if (replacementToken) {
      handleTriggerExecution({ triggerName: CONTINUE_REPLACING_FLOW })
    }
  }, [replacementToken])

  const isModalOpen = isOpen || isReplacingFlow
  if (!isModalOpen || !viewer) return null

  return (
    <Modal open={isModalOpen} onOpenChange={onOpenChange}>
      <VisuallyHidden>
        <Modal.Header>
          <Modal.HeaderTitle>Identity Validation</Modal.HeaderTitle>
        </Modal.Header>
      </VisuallyHidden>
      <Modal.Content className={styles.modalContent}>
        <ModalContextProvider
          setCurrentStep={setCurrentStep}
          replacementToken={replacementToken}
          eventData={eventData}
          viewer={viewer}
        >
          <Modal.IconClose onClick={onClose} data-testid="close-button" />
          <StepRenderer
            onClose={onClose}
            currentStep={currentStep}
            stepsCallback={validateCallback}
            flowStepsBodyText={flowStepsText}
          />
        </ModalContextProvider>
      </Modal.Content>
    </Modal>
  )
}

IdentityValidationModal.fragments = {
  viewer: gql`
    fragment IdentityValidationModal_viewer on Profile {
      pid
      cpf
      birthDate
    }
  `,
}

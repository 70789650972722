import { forwardRef } from 'react'

import { HomeIcon } from '../icon/icon'
import { Link, LinkProps } from '../link/link'
import BreadcrumbItem from './breadcrumb-item'

export type BreadcrumbHomeItemElement = HTMLAnchorElement
export type BreadcrumbHomeItemProps = Partial<LinkProps>

const BreadcrumbHomeItem = forwardRef<BreadcrumbHomeItemElement, BreadcrumbHomeItemProps>(
  function BreadcrumbHomeItem(props, forwardedRef) {
    return (
      <BreadcrumbItem disableArrowIcon>
        <Link href="/" aria-label="Página inicial" {...props} ref={forwardedRef}>
          <HomeIcon size="sm" />
        </Link>
      </BreadcrumbItem>
    )
  }
)

export default BreadcrumbHomeItem

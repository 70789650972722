import { EventProperties } from '../types'
import { NamespacesStorageObject } from '../types/namespaces-storage-object'

export const getNamespaceEventProperties = (
  namespacesStorageObject: NamespacesStorageObject,
  namespace: string
): EventProperties => {
  const namespaces = namespacesStorageObject.namespaces || {}

  return namespaces[namespace] || {}
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgReply(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.383 17.924a1 1 0 0 1-1.09-.217l-6-6a1 1 0 0 1 0-1.414l6-6A1 1 0 0 1 10 5v3h5a7 7 0 0 1 7 7v2a1 1 0 1 1-2 0c0-1.2-1.267-3-5-3h-5v3a1 1 0 0 1-.617.924Zm10.262-4.78A5.002 5.002 0 0 0 15 10H9a1 1 0 0 1-1-1V7.414L4.414 11 8 14.586V13a1 1 0 0 1 1-1h6c1.88 0 3.462.427 4.645 1.145Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgReply)

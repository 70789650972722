import { Badge, Button, ChipClickable, Modal } from '@farol-ds/react'
import { useEffect, useRef, useState } from 'react'

import styles from './checkbox-filter-modal.module.scss'
import { TreeViewCheckboxFilter } from './treeview-checkbox-filter'
import { Option } from './types'
import { ALL_VALUE, getOptionValues, getOptionValuesEnum } from './utils'

interface CheckboxFilterModalProps {
  className?: string
  label: string
  modalTitle: string
  selectedItems: string[]
  searchPlaceholder: string
  options: Option[]
  onFilter: (selctedItems: string[]) => void
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function CheckboxFilterModal(props: CheckboxFilterModalProps) {
  const {
    className,
    options,
    label,
    modalTitle,
    searchPlaceholder,
    selectedItems: initialValues,
    onSearchChange = () => null,
    onFilter,
  } = props

  const closeFromApply = useRef(false)

  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>(initialValues || [ALL_VALUE])
  const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0)

  const optionValues = getOptionValues(options)
  const optionValuesEnum = getOptionValuesEnum(options)

  const hasRoot = selectedItems.some((value) => value === ALL_VALUE)
  const initialValue = hasRoot ? optionValues : selectedItems

  useEffect(() => {
    if (!open && !closeFromApply.current) {
      setSelectedItems(initialValues)
      setSelectedItemsCount(initialValues.some((v) => v === ALL_VALUE) ? 0 : initialValues.length)
    }
  }, [open, initialValues])

  function onSelectedChange(nodeId: string, selected: string[]): void {
    const isAll = selected.length === optionValues.length
    const mappedSelectedItems = selected.map((value) => optionValuesEnum.get(value) || '')
    const selectedItems = isAll ? mappedSelectedItems : mappedSelectedItems.filter(Boolean)

    const hasAll = selectedItems.some((v) => v === ALL_VALUE)

    setSelectedItems(hasAll ? [ALL_VALUE] : selectedItems)
    setSelectedItemsCount(hasAll ? 0 : selectedItems.length)
  }

  function onFilterApply() {
    closeFromApply.current = true

    setSelectedItems(selectedItems)

    setOpen(false)

    onFilter(selectedItems.filter(Boolean))
  }

  return (
    <Modal
      open={open}
      onOpenChange={(nextOpen) => {
        setOpen(nextOpen)
        closeFromApply.current = false
      }}
    >
      <Modal.Trigger>
        <ChipClickable
          selected={selectedItems.filter(Boolean).length > 0}
          chevron
          data-testid="checkbox-filter-modal-trigger"
        >
          {label}
          <Badge
            className={styles.badge}
            count={selectedItemsCount}
            overlap="rectangular"
            variant="label"
          />
        </ChipClickable>
      </Modal.Trigger>

      <Modal.Content>
        <Modal.IconClose />
        <Modal.Header>
          <Modal.HeaderTitle>{modalTitle}</Modal.HeaderTitle>
        </Modal.Header>
        <Modal.Body>
          <TreeViewCheckboxFilter
            onSearchChange={onSearchChange}
            initialValue={initialValue.map((value) => optionValuesEnum.get(value) || ALL_VALUE)}
            placeholder={searchPlaceholder}
            className={className}
            options={options}
            onSelectedChange={onSelectedChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button kind="primary" onClick={onFilterApply} data-testid="filter-button">
            Filtrar
          </Button>
          <Modal.Close>
            <Button>Cancelar</Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

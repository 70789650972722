import classNames from 'classnames'
import { forwardRef, memo, useId, useState } from 'react'

import { Checkbox, CheckboxElement, CheckboxProps } from '../checkbox/checkbox'
import {
  SelectionCard,
  SelectionCardBodyContentDescriptionProps,
  SelectionCardBodyContentProps,
  SelectionCardBodyContentTitleProps,
  SelectionCardBodyIconProps,
  SelectionCardElement,
  SelectionCardOrientation,
  SelectionCardOrientations,
  SelectionCardProps,
} from '../selection-card/selection-card'
import { assignSubComponents } from '../utilities/internal'
import styles from './checkbox-card.module.scss'

export type CheckboxCardElement = CheckboxElement

export const CheckboxCardOrientations = SelectionCardOrientations
export type CheckboxCardOrientation = SelectionCardOrientation

export type CheckboxCardProps = CheckboxProps & {
  children: React.ReactNode
  orientation?: CheckboxCardOrientation
  controlHidden?: boolean
  checkboxClassName?: string
  rootProps?: Partial<
    Omit<SelectionCardProps, 'checked' | 'defaultChecked' | 'onCheckedChange' | 'orientation'>
  >
  rootRef?: React.Ref<SelectionCardElement>
}

/* IMPORTANT: This specific implementation is required to work around a Radix-UI bug
 * Issue: https://github.com/radix-ui/primitives/issues/3192
 * - The memo AND component recreation on each render are both required
 * - This should be removed once the Radix-UI issue is fixed
 */
function CheckboxWrapper(props: CheckboxProps & { ref: React.Ref<CheckboxElement> }) {
  const MemoizedCheckbox = memo(Checkbox)
  return <MemoizedCheckbox {...props} />
}

const CheckboxCardRoot = forwardRef<CheckboxCardElement, CheckboxCardProps>(function CheckboxCard(
  props,
  forwardedRef
) {
  const {
    id: idProp,
    className,
    checked: checkedProp,
    onCheckedChange,
    defaultChecked,
    indeterminate,
    orientation,
    disabled,
    rootProps,
    rootRef,
    controlHidden,
    children,
    checkboxClassName,
    ...rest
  } = props

  const _id = useId()
  const id = idProp ?? _id
  const [_checked, setChecked] = useState(checkedProp ?? defaultChecked)
  const checked = checkedProp ?? _checked

  function handleCheckedChange() {
    setChecked((checked) => {
      onCheckedChange?.(!checked)
      return !checked
    })
  }

  function handleKeyDown(e: React.KeyboardEvent<SelectionCardElement>) {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCheckedChange()
    }
  }

  return (
    <SelectionCard
      {...rootProps}
      orientation={orientation}
      checked={checked !== undefined && checked !== false}
      disabled={disabled}
      className={className}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      ref={rootRef}
      asChild
    >
      <label htmlFor={id}>
        <SelectionCard.Body>{children}</SelectionCard.Body>
        <SelectionCard.Control hidden={controlHidden}>
          <CheckboxWrapper
            {...rest}
            id={id}
            className={classNames(styles.checkbox, checkboxClassName)}
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onCheckedChange={handleCheckedChange}
            tabIndex={-1}
            ref={forwardedRef}
          />
        </SelectionCard.Control>
      </label>
    </SelectionCard>
  )
})

export type CheckboxCardIconProps = SelectionCardBodyIconProps
const CheckboxCardIcon = memo(function CheckboxCardIcon(props: CheckboxCardIconProps) {
  return <SelectionCard.BodyIcon {...props} />
})

export type CheckboxCardContentProps = SelectionCardBodyContentProps
const CheckboxCardContent = memo(function CheckboxCardContent(props: CheckboxCardContentProps) {
  return <SelectionCard.BodyContent {...props} />
})

export type CheckboxCardContentTitleProps = SelectionCardBodyContentTitleProps
const CheckboxCardContentTitle = memo(function CheckboxCardContentTitle(
  props: CheckboxCardContentTitleProps
) {
  return <SelectionCard.BodyContentTitle {...props} />
})

export type CheckboxCardContentDescriptionProps = SelectionCardBodyContentDescriptionProps
const CheckboxCardContentDescription = memo(function CheckboxCardContentDescription(
  props: CheckboxCardContentDescriptionProps
) {
  return <SelectionCard.BodyContentDescription {...props} />
})

export const CheckboxCard = assignSubComponents('CheckboxCard', CheckboxCardRoot, {
  Icon: CheckboxCardIcon,
  Content: CheckboxCardContent,
  ContentTitle: CheckboxCardContentTitle,
  ContentDescription: CheckboxCardContentDescription,
})

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './drawer-body.module.scss'

export type DrawerBodyElement = HTMLDivElement

export interface DrawerBodyProps extends HTMLAttributes<DrawerBodyElement> {
  children: ReactNode
}

const DrawerBody = forwardRef<DrawerBodyElement, DrawerBodyProps>(function DrawerBody(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props
  const classes = classNames(styles.root, className)
  return (
    <div {...rest} className={classes} ref={forwardedRef}>
      {children}
    </div>
  )
})

export default DrawerBody

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPause(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7a1 1 0 0 0-2 0v10a1 1 0 1 0 2 0V7Zm8 0a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0V7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPause)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPlaceholder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m2 11 4.5-8 4.5 8H2Zm3.42-2L6.5 7.08 7.58 9H5.42Zm9.393 8.029 2.158-2.085 2.084 2.157-2.157 2.085-2.085-2.157Zm2.037 4.985-4.865-5.034 5.034-4.864 4.865 5.033-5.034 4.865ZM5 16v3h3v-3H5Zm-2-2v7h7v-7H3Zm16-7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm2 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPlaceholder)

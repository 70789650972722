import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgRepeat(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.707 3.707a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414L6.414 7H17a1 1 0 0 1 1 1v3a1 1 0 1 0 2 0V8a3 3 0 0 0-3-3H6.414l1.293-1.293ZM6 13a1 1 0 1 0-2 0v3a3 3 0 0 0 3 3h10.586l-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 1.414L17.586 17H7a1 1 0 0 1-1-1v-3Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgRepeat)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPix(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.795 11.13.063.06.015.013c.276.241.609.362.94.362a1.432 1.432 0 0 0 1.02-.435l3.055-3.043c.18-.18.413-.323.664-.422a2.248 2.248 0 0 1 .791-.158H18.8l2.328 2.32c1.164 1.159 1.164 3.115 0 4.274l-2.328 2.32h-1.456a2.269 2.269 0 0 1-.688-.12 2.079 2.079 0 0 1-.767-.46l-3.056-3.044a1.492 1.492 0 0 0-1.018-.434 1.395 1.395 0 0 0-.463.068 1.265 1.265 0 0 0-.556.366l-2.983 2.971a1.895 1.895 0 0 1-.643.414 2.221 2.221 0 0 1-.812.166H5.193L2.937 14.1C1.7 12.942 1.7 11.058 2.864 9.826L5.12 7.58h1.237l.072.001a2.192 2.192 0 0 1 .922.248c.173.09.33.2.46.33l2.984 2.971ZM8.539 7.58l2.91 3.043a.56.56 0 0 0 .365.162.573.573 0 0 0 .436-.162l3.056-3.043.04-.038a2.927 2.927 0 0 1 2.071-.831h.581L14.215 2.87c-1.164-1.16-3.129-1.16-4.293 0L6.066 6.71h.363a2.925 2.925 0 0 1 2.11.87Zm3.33 1.023 2.238-2.228c.202-.202.418-.381.644-.539L13.01 4.07c-.502-.495-1.39-.493-1.89.004l-1.877 1.87c.172.13.337.274.495.431l.015.015 2.116 2.213Zm6.13 8.76h-.51c-.049 0-.097-.002-.146-.004a2.91 2.91 0 0 1-1.964-.866l-3.056-3.044a.574.574 0 0 0-.8 0L8.539 16.42a3.058 3.058 0 0 1-.519.417 2.925 2.925 0 0 1-1.59.453h-.364l3.856 3.84c1.164 1.16 3.129 1.16 4.293 0l3.783-3.768Zm-8.755.693 1.877 1.87c.501.499 1.393.499 1.894 0l1.743-1.736a4.83 4.83 0 0 1-.579-.493l-2.256-2.247-2.184 2.175a4.83 4.83 0 0 1-.495.43ZM5.822 9.28h.535a.504.504 0 0 1 .259.087l2.607 2.597-2.607 2.596-.017.011a.504.504 0 0 1-.242.076h-.462L4.118 12.88 4.1 12.86c-.503-.471-.558-1.262-.015-1.852l1.737-1.73Zm.79 5.284Zm11.484.156h-.753a.506.506 0 0 1-.259-.087l-2.68-2.67 2.68-2.668a.49.49 0 0 1 .096-.05.505.505 0 0 1 .163-.038h.753l1.831 1.824c.497.495.497 1.37 0 1.866l-1.83 1.823Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPix)

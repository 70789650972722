import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgJudge(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a2 2 0 0 0-2 2c0 1.162.585 2.187 1.26 2.974.259.303.52.556.74.751.22-.195.481-.448.74-.75C13.416 8.186 14 7.161 14 6a2 2 0 0 0-2-2Zm2.055 7.168-.39-.26c.19-.185.392-.396.594-.632C15.085 9.313 16 7.838 16 6a4 4 0 0 0-8 0c0 1.838.915 3.313 1.74 4.276.186.216.371.412.547.585L7.4 12.595A7 7 0 0 0 4 18.597V19a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H7a1 1 0 0 1-1-1v-.403a5 5 0 0 1 2.428-4.287l3.544-2.127.973.649a1 1 0 1 0 1.11-1.664Zm3.221 2.125a1 1 0 0 0-1.414 0l-3.57 3.569a1 1 0 0 0 0 1.414l2.242 2.241a1 1 0 0 0 1.414 0l1.078-1.077 2.267 2.267a1 1 0 0 0 1.414-1.414l-2.267-2.267 1.077-1.078a1 1 0 0 0 0-1.414l-2.24-2.241Zm-2.035 5.103-.827-.827 2.155-2.155.827.827-2.155 2.155Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgJudge)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgStar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.06 3.434c.6-1.912 3.28-1.912 3.88 0l1.437 4.569h4.585c1.983 0 2.745 2.506 1.25 3.68l-3.768 2.959 1.52 4.651c.603 1.843-1.508 3.55-3.162 2.285l-3.793-2.902-3.672 2.893-.01.009c-1.652 1.263-3.756-.435-3.167-2.273l1.409-4.653-3.78-2.968c-1.496-1.175-.734-3.681 1.249-3.681h4.585l1.437-4.569Zm1.921.57s-.007.009-.013.03L10.31 9.303a1 1 0 0 1-.954.7H4.038l-.015.001a.102.102 0 0 0-.017.032.092.092 0 0 0-.004.05.02.02 0 0 0 .003.006c0 .001.005.008.019.019L8.34 13.5a1 1 0 0 1 .34 1.076l-1.61 5.317-.006.02a.117.117 0 0 0-.008.037l.002.009a.07.07 0 0 0 .025.03c.006.004.01.007.014.008a.153.153 0 0 0 .01-.006l4.273-3.368a1 1 0 0 1 1.227-.009l4.41 3.374c.005.005.01.007.012.01a.07.07 0 0 0 .015-.01.069.069 0 0 0 .024-.03l.002-.008c0-.003 0-.015-.008-.037l-1.737-5.316a1 1 0 0 1 .333-1.097l4.317-3.39c.014-.01.018-.018.02-.02a.018.018 0 0 0 .002-.006.092.092 0 0 0-.004-.05.104.104 0 0 0-.017-.031.084.084 0 0 0-.015-.001h-5.32a1 1 0 0 1-.953-.7l-1.656-5.269c-.007-.021-.013-.03-.014-.03A.045.045 0 0 0 12 4a.046.046 0 0 0-.019.003Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgStar)

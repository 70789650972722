import { useGTM } from '@jusbrasil-web/shared-gtm'
import { useCallback } from 'react'

import { GA4Event } from './GA4Event'
import { GA4UserProperties } from './GA4UserProperties'

export function useGA4Dispatcher() {
  const gtm = useGTM()

  const sendGA4Event = useCallback((event: GA4Event) => gtm.push({ ...event }), [gtm])
  const setGA4UserProperties = useCallback(
    (userProperties: GA4UserProperties) => gtm.push({ ...userProperties }),
    [gtm]
  )

  return {
    sendGA4Event,
    setGA4UserProperties,
  }
}

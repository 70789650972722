import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import { Children, cloneElement, isValidElement } from 'react'

import styles from './dropdown-menu-radio-group.module.scss'
import { DropdownMenuRadioItemProps } from './dropdown-menu-radio-item'

export type DropdownMenuRadioGroupElement = typeof RDropdownMenu.Item
export type DropdownMenuRadioGroupProps = RDropdownMenu.DropdownMenuRadioGroupProps

const DropdownMenuRadioGroup: React.FC<DropdownMenuRadioGroupProps> = (props) => {
  const { children, value, ...rest } = props
  return (
    <RDropdownMenu.RadioGroup {...rest} value={value} asChild>
      <div className={styles.root}>
        {Children.map(children, (child) => {
          if (isValidElement<DropdownMenuRadioItemProps>(child)) {
            return cloneElement(child, { currentValue: value })
          }
          return child
        })}
      </div>
    </RDropdownMenu.RadioGroup>
  )
}

export default DropdownMenuRadioGroup

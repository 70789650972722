import { GA4Event } from '@jusbrasil-web/shared-ga4'

import { EVENT } from '../constants'

export interface GA4ContentGenerateEventProps {
  featureClass: string
  featureName?: string | null
  generativeMethod?: string | null
}

export class GA4ContentGenerateEvent extends GA4Event {
  ga4_feature_class: GA4ContentGenerateEventProps['featureClass']
  ga4_feature_name: GA4ContentGenerateEventProps['featureName']
  ga4_generative_method: GA4ContentGenerateEventProps['generativeMethod']

  constructor({
    featureClass,
    featureName = null,
    generativeMethod = null,
  }: GA4ContentGenerateEventProps) {
    super({ event: EVENT.CONTENT_GENERATE })

    this.ga4_feature_class = featureClass
    this.ga4_feature_name = featureName
    this.ga4_generative_method = generativeMethod
  }
}

import classNames from 'classnames'
import {
  Children,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'

import { Button } from '../button/button'
import { ArrowLeftIcon } from '../icon/icons'
import { LinkProps } from '../link/link'
import { assignSubComponents } from '../utilities/internal'
import styles from './breadcrumb.module.scss'
import BreadcrumbHomeItem from './breadcrumb-home-item'
import BreadcrumbItem, { BreadcrumbItemProps } from './breadcrumb-item'

export type BreadcrumbElement = HTMLOListElement

export interface BreadcrumbProps extends HTMLAttributes<BreadcrumbElement> {
  children: ReactNode
  className?: string
}

const MIN_CHILDREN_ITEMS = 3

const BreadcrumbRoot = forwardRef<BreadcrumbElement, BreadcrumbProps>(function Breadcrumb(
  props,
  forwardedRef
) {
  const {
    children,
    className,
    'aria-label': ariaLabel = 'Caminho da página atual',
    ...rest
  } = props

  const classes = classNames(styles.root, className)

  const childrenArr = Children.toArray(children)
  if (childrenArr.length < MIN_CHILDREN_ITEMS) {
    throw new Error(`Breadcrumb must have at least ${MIN_CHILDREN_ITEMS} children`)
  }
  const ancestorMenuItem = childrenArr[childrenArr.length - 2] as ReactElement<BreadcrumbItemProps>
  const ancestorLink = isValidElement<LinkProps>(ancestorMenuItem.props.children)
    ? ancestorMenuItem.props.children
    : null

  return (
    <nav {...rest} aria-label={ariaLabel} className={classes} ref={forwardedRef}>
      <ol className={styles.list}>{children}</ol>

      {ancestorLink?.props?.children && (
        <Button kind="plain" leftIcon={<ArrowLeftIcon />} className={styles.ancestorBtn} asChild>
          <a {...ancestorLink.props}>{ancestorLink.props.children}</a>
        </Button>
      )}
    </nav>
  )
})

export const Breadcrumb = assignSubComponents('Breadcrumb', BreadcrumbRoot, {
  HomeItem: BreadcrumbHomeItem,
  Item: BreadcrumbItem,
})

export * from './breadcrumb-home-item'
export * from './breadcrumb-item'

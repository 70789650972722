import { useToast } from '@farol-ds/react'
import { Component, ReactNode, useEffect } from 'react'

import { SHOW_NETWORK_ERROR_TOAST_EVENT_NAME } from './network-toast-dispatcher'

const ERROR_TIMEOUT = 3000
export const SYSTEM_ERROR_MESSAGE = 'Ocorreu um erro no sistema. Tente novamente mais tarde.'
export const NETWORK_ERROR_MESSAGE =
  'Estamos restabelecendo a conexão com o servidor. Tente novamente, em breve.'

type NetworkErrorBoundaryProps = {
  children: ReactNode
}

type NetworkErrorBoundaryState = {
  errorMessage: string | null
}
export class NetworkErrorBoundary extends Component<
  NetworkErrorBoundaryProps,
  NetworkErrorBoundaryState
> {
  constructor(props: NetworkErrorBoundaryProps) {
    super(props)
    this.state = { errorMessage: null }
    this.openNetworkAlert = this.openNetworkAlert.bind(this)
  }

  static getDerivedStateFromError(error: { message: string }) {
    const hasNetworkError = /\b(?:chunks?|internet|network)\b/i.test(error.message)
    const errorMessage = hasNetworkError ? NETWORK_ERROR_MESSAGE : SYSTEM_ERROR_MESSAGE
    return { errorMessage }
  }

  componentDidUpdate(
    _: Readonly<NetworkErrorBoundaryProps>,
    prevState: Readonly<NetworkErrorBoundaryState>
  ) {
    if (!prevState.errorMessage && this.state.errorMessage) {
      setTimeout(() => {
        this.closeAlert()
      }, ERROR_TIMEOUT)
    }
  }

  componentDidMount() {
    document.addEventListener(SHOW_NETWORK_ERROR_TOAST_EVENT_NAME, this.openNetworkAlert)
  }

  componentWillUnmount() {
    document.removeEventListener(SHOW_NETWORK_ERROR_TOAST_EVENT_NAME, this.openNetworkAlert)
  }

  openNetworkAlert() {
    this.setState({ errorMessage: NETWORK_ERROR_MESSAGE })
  }

  closeAlert() {
    this.setState({ errorMessage: null })
  }

  render(): ReactNode {
    const { children } = this.props
    const { errorMessage } = this.state

    return (
      <>
        {children}
        {errorMessage && <ToastCaller message={errorMessage} />}
      </>
    )
  }
}

function ToastCaller({ message }: { message: string }) {
  const toast = useToast()

  useEffect(() => {
    toast(message, {
      type: 'negative',
      id: 'network-error-boundary-toast',
      duration: ERROR_TIMEOUT,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

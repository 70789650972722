import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgVolumeDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5a1 1 0 0 0-1.707-.707L6.586 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2.586l3.707 3.707A1 1 0 0 0 12 19V5ZM7.707 9.707 10 7.414v9.172l-2.293-2.293A1 1 0 0 0 7 14H4v-4h3a1 1 0 0 0 .707-.293Zm8.536-1.95a1 1 0 0 0-1.414 1.414A3.984 3.984 0 0 1 16 12a3.984 3.984 0 0 1-1.171 2.828 1 1 0 0 0 1.414 1.415A5.985 5.985 0 0 0 18 12a5.985 5.985 0 0 0-1.757-4.243Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgVolumeDown)

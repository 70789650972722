import * as RDialog from '@radix-ui/react-dialog'

export type ModalCloseElement = typeof RDialog.DialogClose

export type ModalCloseProps = RDialog.DialogCloseProps

const ModalClose: React.FC<ModalCloseProps> = (props) => {
  return <RDialog.Close asChild {...props} />
}

export default ModalClose

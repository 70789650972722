import classNames from 'classnames'
import { forwardRef } from 'react'

import { Heading, HeadingElement, HeadingProps } from '../heading/heading'
import styles from './popover-content-title.module.scss'
import { usePopover } from './use-popover'

export type PopoverContentTitleElement = HeadingElement
export type PopoverContentTitleProps = HeadingProps

const PopoverContentTitle = forwardRef<PopoverContentTitleElement, PopoverContentTitleProps>(
  function PopoverContentTitle(props, forwardedRef) {
    const { className, ...rest } = props
    const { type } = usePopover()
    const classes = classNames(
      styles.root,
      {
        [styles.highlight]: type === 'highlight',
      },
      className
    )
    return <Heading {...rest} size="sm" className={classes} ref={forwardedRef} />
  }
)

export default PopoverContentTitle

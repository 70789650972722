import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgSearchBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4 16c-.952 0-1.4.61-1.4 1s.448 1 1.4 1H9a1 1 0 1 1 0 2H5.4c-1.696 0-3.394-1.176-3.4-2.991V5a3 3 0 0 1 3-3h9a2 2 0 0 1 2 2v3a1 1 0 1 1-2 0V4H5a1 1 0 0 0-1 1v9.17c.313-.11.65-.17 1-.17h3a1 1 0 1 1 0 2H5.4ZM6 7a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm7 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 2.54 9.308l2.253 2.253a1 1 0 0 0 1.414-1.414l-2.181-2.181A5 5 0 0 0 16 10Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgSearchBook)

const ACCENTS_MAP: { [key: string]: RegExp } = {
  a: /[áàãâä]/g,
  e: /[éèêë]/g,
  i: /[íìîï]/g,
  o: /[óòõôö]/g,
  u: /[úùûü]/g,
  c: /[ç]/g,
}

/**
 * Slugify a string
 *
 * @param str - The string to slugify
 * @returns The slugified string
 * @example
 *
 * slugify('João Alberto da Silveira') // 'joao-alberto-da-silveira'
 * slugify('Maria Ciça Oratório') // 'maria-cica-oratorio'
 * slugify('Ícaro AugÜsto') // 'icaro-augusto'
 * slugify('C6 Bank Investimentos LTDA.') // 'c6-bank-investimentos-ltda'
 **/
export function slugify(str: string): string {
  str = str.toLowerCase().trim()

  for (const letter in ACCENTS_MAP) {
    str = str.replace(ACCENTS_MAP[letter], letter)
  }

  return str
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

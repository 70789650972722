import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLocation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4a4 4 0 0 0-4 4c0 2.178 1.098 4.212 2.293 5.765A16.813 16.813 0 0 0 12 15.661l.086-.083a16.813 16.813 0 0 0 1.621-1.813C14.902 12.212 16 10.178 16 8a4 4 0 0 0-4-4Zm-.625 13.78L12 17l-.625.78ZM12 17l.625.78a1 1 0 0 1-1.25 0l-.002-.001-.005-.004-.015-.012-.014-.012-.035-.029a16.107 16.107 0 0 1-.78-.706 18.827 18.827 0 0 1-1.817-2.031C7.402 13.288 6 10.822 6 8a6 6 0 1 1 12 0c0 2.822-1.402 5.288-2.707 6.985a18.822 18.822 0 0 1-2.421 2.587 10.808 10.808 0 0 1-.225.191l-.015.012-.005.004h-.001c0 .001-.001.002-.626-.779Zm-5.205-1.314a1 1 0 0 1 .233 1.395L4.943 20h14.114l-2.085-2.919a1 1 0 1 1 1.627-1.162l2.085 2.918c.946 1.324 0 3.163-1.627 3.163H4.943c-1.627 0-2.573-1.839-1.627-3.163L5.4 15.92a1 1 0 0 1 1.394-.233ZM12 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLocation)

import { cloneElement, isValidElement, ReactNode } from 'react'

import { Link, LinkProps } from '../link/link'

export function renderChildrenOrLink(children: ReactNode): ReactNode {
  // is link <a>
  if (isValidElement<LinkProps>(children) && children.type === Link) {
    return cloneElement(children, { type: 'primary' })
  }

  // is unknown tag but link as child <h3><a>
  if (isValidElement<{ children: ReactNode }>(children) && children.props.children) {
    return cloneElement(children, {}, renderChildrenOrLink(children.props.children))
  }

  return children
}

import * as RTabs from '@radix-ui/react-tabs'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '../icon/icon'
import { IconButton, IconButtonProps } from '../icon-button/icon-button'
import styles from './tabs-list.module.scss'
import { useTabsList } from './use-tabs-list'

export type TabsListElement = HTMLDivElement

export interface TabsListProps extends HTMLAttributes<TabsListElement> {
  children: ReactNode
  scrollMaxItemWidth?: number
  scrollMultiplier?: number
  className?: string
}

const TabsList = forwardRef<TabsListElement, TabsListProps>(function TabsList(props, forwardedRef) {
  const { className, children, scrollMaxItemWidth, scrollMultiplier = 1.5, ...rest } = props

  const { listRef, arrowLeftRef, arrowRightRef, underlineRef, scrollToDirection } = useTabsList({
    ...props,
    scrollMultiplier,
  })

  const containerClasses = classNames(styles.root)
  const classes = classNames(styles.content, className)
  const iconButtonProps: Partial<Omit<IconButtonProps, 'asChild' | 'children'>> = {
    size: 'md',
    kind: 'plain',
    tooltipProps: { open: false },
  }

  return (
    <div className={containerClasses} ref={forwardedRef}>
      <span className={styles.arrowLeft} ref={arrowLeftRef}>
        <IconButton
          {...iconButtonProps}
          icon={<ChevronLeftIcon />}
          label="Mover barra de rolagem para esquerda"
          onClick={() => scrollToDirection(-1)}
          data-testid="tabs-arrow-left"
        />
      </span>

      <RTabs.List {...rest} loop className={classes} ref={listRef}>
        <span data-fds-tabs-underline className={styles.underline} ref={underlineRef} />
        {children}
      </RTabs.List>

      <span className={styles.arrowRight} ref={arrowRightRef}>
        <IconButton
          {...iconButtonProps}
          icon={<ChevronRightIcon />}
          label="Mover barra de rolagem para direita"
          onClick={() => scrollToDirection(1)}
          data-testid="tabs-arrow-right"
        />
      </span>
    </div>
  )
})

export default TabsList

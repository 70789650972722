import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgHeart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.366 5.217C5.053 5.7 4 6.989 4 8.75c0 2.358 1.273 4.48 2.942 6.24 1.66 1.748 3.603 3.021 4.694 3.664.229.136.5.136.728 0 1.091-.643 3.035-1.916 4.694-3.665C18.727 13.23 20 11.108 20 8.75c0-1.761-1.053-3.05-2.366-3.533-1.282-.473-2.874-.2-4.065 1.37l-.772 1.017a1 1 0 0 1-1.594 0l-.772-1.017.797-.605-.797.605c-1.19-1.57-2.783-1.843-4.065-1.37ZM12 5.346C10.274 3.097 7.766 2.569 5.674 3.34 3.602 4.104 2 6.123 2 8.75c0 3.093 1.661 5.688 3.492 7.616 1.84 1.94 3.96 3.323 5.128 4.012a2.705 2.705 0 0 0 2.76 0c1.168-.689 3.288-2.073 5.129-4.012C20.339 14.438 22 11.843 22 8.75c0-2.627-1.602-4.646-3.674-5.41-2.092-.771-4.6-.243-6.326 2.006Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgHeart)

import classNames from 'classnames'
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  OlHTMLAttributes,
  ReactNode,
} from 'react'

import { ensurePropOf } from '../utilities'
import { assignSubComponents } from '../utilities/internal'
import { TimelineContext } from './context'
import styles from './timeline.module.scss'
import TimelineItem from './timeline-item'
import { useTimelineProvider } from './use-timeline'

export type TimelineElement = HTMLOListElement
export const TimelineSizes = ['sm', 'md'] as const
export type TimelineSize = (typeof TimelineSizes)[number]

export interface TimelineProps extends OlHTMLAttributes<TimelineElement> {
  children: ReactNode
  size?: TimelineSize
  endless?: boolean
  className?: string
}

const TimelineRoot = forwardRef<TimelineElement, TimelineProps>(function Timeline(
  props,
  forwardedRef
) {
  const { className, children, size: sizeProp, endless, ...rest } = props

  const size = ensurePropOf<TimelineSize>(TimelineSizes, sizeProp, 'md')
  const context = useTimelineProvider({ ...props, size })

  const classes = classNames(
    styles.root,
    {
      [styles.endless]: endless,
      [styles[`size${size}`]]: size,
    },
    className
  )

  return (
    <TimelineContext.Provider value={context}>
      <ol {...rest} className={classes} ref={forwardedRef}>
        {Children.map(children, (child) => {
          if (isValidElement<{ className?: string }>(child) && child.type !== TimelineItem) {
            return cloneElement(child, {
              className: classNames(child.props.className, styles.unknownItem),
            })
          }
          return child
        })}
      </ol>
    </TimelineContext.Provider>
  )
})

export const Timeline = assignSubComponents('Timeline', TimelineRoot, {
  Item: TimelineItem,
})

export * from './timeline-item'

import { forwardRef } from 'react'

import { type BoxElement, type BoxProps, Box_unstable as Box } from './box'

export type FlexElement = BoxElement
export type FlexProps = Omit<
  BoxProps,
  | 'display'
  | 'alignItems'
  | 'flexBasis'
  | 'flexDirection'
  | 'flexGrow'
  | 'justifyContent'
  | 'flexShrink'
  | 'flexWrap'
> & {
  align?: BoxProps['alignItems']
  basis?: BoxProps['flexBasis']
  direction?: BoxProps['flexDirection']
  grow?: BoxProps['flexGrow']
  justify?: BoxProps['justifyContent']
  shrink?: BoxProps['flexShrink']
  wrap?: BoxProps['flexWrap']
}

export const Flex_unstable = forwardRef<FlexElement, FlexProps>(function Flex(props, forwardedRef) {
  const { align, basis, direction, grow, justify, shrink, wrap, ...rest } = props
  return (
    <Box
      {...rest}
      alignItems={align}
      flexBasis={basis}
      flexDirection={direction}
      flexGrow={grow}
      justifyContent={justify}
      flexShrink={shrink}
      flexWrap={wrap}
      display="flex"
      ref={forwardedRef}
    />
  )
})

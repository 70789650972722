import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgLinkedin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8.691 3.038.002.001c.804.758 1.168 1.749 1.168 2.783 0 .4-.059.786-.175 1.151h3.271c.307 0 .597.069.856.192a5.72 5.72 0 0 1 2.249-.44c1.677 0 3.19.583 4.332 1.832C21.573 9.845 22 11.563 22 13.374v5.916a2 2 0 0 1-2 2h-3.429a2 2 0 0 1-1.807-1.142 2 2 0 0 1-1.806 1.142H9.528a2 2 0 0 1-.95-.24c-.282.153-.606.24-.95.24H4.201a2 2 0 0 1-2-2V8.973c0-.434.138-.836.373-1.164A3.758 3.758 0 0 1 2 5.776c0-1.042.39-2.007 1.18-2.74m1.263 3.937c.026.029.054.057.082.085.35.34.808.51 1.375.51h.015c.587 0 1.06-.17 1.42-.51a1.624 1.624 0 0 0 .525-1.236c.001-.545-.18-.988-.54-1.328C6.97 4.164 6.512 4 5.946 4c-.577 0-1.045.167-1.405.502-.36.335-.541.76-.541 1.274 0 .473.148.872.443 1.197Zm-.242 2V19.29h3.428V8.973H4.201Zm5.328 0c.016.254.026.92.03 2v1.073a961.342 961.342 0 0 1-.03 7.244h3.428v-5.761c0-.36.042-.638.124-.834a2.24 2.24 0 0 1 .656-.888c.224-.184.49-.298.8-.342.05-.007.1-.012.152-.016h.014c.045-.004.092-.005.139-.005 1.153 0 1.73.777 1.73 2.332v5.514H20v-5.915c0-1.524-.36-2.68-1.081-3.468-.72-.787-1.673-1.181-2.857-1.181-.395 0-.764.05-1.107.151a3.325 3.325 0 0 0-1.318.748c-.249.229-.475.5-.68.815V8.973H9.53Zm3.413 1.498h.015v-.03l-.015.03ZM3.18 3.036C3.973 2.3 4.957 2 5.946 2c.979 0 1.96.298 2.745 1.038"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgLinkedin)

import { v4 as uuidv4 } from 'uuid'

import { EventContext } from '../types'

export const getQueryId = (eventContext: EventContext): string => {
  if (!eventContext || !eventContext.additionalData) {
    return uuidv4()
  }

  const { additionalData } = eventContext
  const queryId = additionalData?.queryId || uuidv4()
  return queryId || uuidv4()
}

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { IncomingMessage } from 'http'

import { GRAPHQL_URL, inMemoryCacheConfig, NEXT_PUBLIC_GRAPHQL_URL } from '../constants'

declare module 'http' {
  interface IncomingHttpHeaders {
    'cf-connecting-ip'?: string
    'cf-verified-bot'?: string
  }
}

export type ApolloClientOptions = {
  csrUri?: string
  ssrUri?: string
}

const isServer = typeof window === 'undefined'
const useBatch = !isServer

const clientHeadersOnlyToForward = ['cf-connecting-ip'] as const
const headersToForward = [
  'cf-verified-bot',
  'cookie',
  'user-agent',
  ...(isServer ? [] : clientHeadersOnlyToForward),
] as const

const getApolloUrl = (options: ApolloClientOptions) => {
  if (isServer) {
    return options.ssrUri || GRAPHQL_URL
  }

  if (useBatch) {
    return `${options.csrUri || NEXT_PUBLIC_GRAPHQL_URL}/apollo-batch`
  }

  return options.csrUri || NEXT_PUBLIC_GRAPHQL_URL
}

export function createApolloClient(req?: IncomingMessage, options: ApolloClientOptions = {}) {
  const forwardedHeaders: HeadersInit = {}
  headersToForward.forEach((headerName) => {
    const headerValue = req?.headers[headerName]
    if (headerValue) {
      forwardedHeaders[headerName] = headerValue
    }
  })

  const LinkImpl = useBatch ? BatchHttpLink : HttpLink
  const batchOpts: BatchHttpLink.Options = useBatch ? { batchMax: 10, batchInterval: 10 } : {}

  return new ApolloClient({
    ssrMode: isServer,
    link: new LinkImpl({
      ...batchOpts,
      uri: getApolloUrl(options),
      credentials: 'include',
      headers: {
        ...forwardedHeaders,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }),
    cache: new InMemoryCache(inMemoryCacheConfig),
  })
}

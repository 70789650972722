import type { RadioGroupItemProps as RRadioGroupItemProps } from '@radix-ui/react-radio-group'
import * as RRadioGroup from '@radix-ui/react-radio-group'
import classNames from 'classnames'
import { forwardRef } from 'react'

import { assignSubComponents } from '../utilities/internal'
import styles from './radio.module.scss'
import RadioContent from './radio-content'
import RadioDescription from './radio-description'
import RadioGroup from './radio-group'
import RadioIndicator from './radio-indicator'
import RadioItem from './radio-item'
import RadioName from './radio-name'

export type RadioElement = HTMLButtonElement

export type RadioProps = RRadioGroupItemProps

const RadioRoot = forwardRef<RadioElement, RadioProps>(function Radio(props, forwardedRef) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <RRadioGroup.Item {...rest} className={classes} ref={forwardedRef}>
      <RadioIndicator />
      {children}
    </RRadioGroup.Item>
  )
})

export const Radio = assignSubComponents('Radio', RadioRoot, {
  Group: RadioGroup,
  Indicator: RadioIndicator,
  Item: RadioItem,
  Name: RadioName,
  Description: RadioDescription,
  Content: RadioContent,
})

export * from './radio-content'
export * from './radio-description'
export * from './radio-group'
export * from './radio-indicator'
export * from './radio-item'
export * from './radio-name'

import { createContext, ReactNode, RefObject } from 'react'

import { ToastElement } from './toast'
import { ToastDuration, ToastMessage, ToastSensitivity, ToastType } from './types'

export type ToastPushOptions = {
  id?: string
  icon?: ReactNode
  duration?: ToastDuration
  type?: ToastType
  sensitivity?: ToastSensitivity
  ref?: RefObject<ToastElement>
}

export type ToastContextType = (message: ToastMessage, options?: ToastPushOptions) => void

export const contextFallback: ToastContextType = () => {
  throw new Error('ToastContext not initialized')
}

export const ToastContext = createContext<ToastContextType>(contextFallback)

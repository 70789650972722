import * as RAccordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { forwardRef } from 'react'

import styles from './accordion-item.module.scss'

export type AccordionItemElement = HTMLDivElement

export type AccordionItemProps = React.ComponentProps<typeof RAccordion.Item>

const AccordionItem = forwardRef<AccordionItemElement, AccordionItemProps>(function AccordionItem(
  props,
  forwardedRef
) {
  const { className, children, ...rest } = props

  const classes = classNames(styles.root, className)

  return (
    <RAccordion.Item {...rest} className={classes} ref={forwardedRef}>
      {children}
    </RAccordion.Item>
  )
})

export default AccordionItem

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import { IconProps } from '../icon/icon'
import { TruncateText } from '../truncate-text/truncate-text'
import { ensurePropOf } from '../utilities'
import { Slot, Slottable } from '../utilities/slot/slot'
import styles from './chip.module.scss'

export type ChipElement = HTMLSpanElement
export const ChipKinds = [
  'secondary',
  'tertiary',
  'info',
  'negative',
  'warning',
  'positive',
] as const
export type ChipKind = (typeof ChipKinds)[number]
export const ChipSizes = ['sm', 'md'] as const
export type ChipSize = (typeof ChipSizes)[number]

type WithUnstableTruncateProps =
  | { unstable_truncate: true; title: string }
  | { unstable_truncate?: false }

export type ChipProps = HTMLAttributes<ChipElement> & {
  children: ReactNode
  kind?: ChipKind
  size?: ChipSize
  leftIcon?: ReactNode
  className?: string
  asChild?: boolean
} & WithUnstableTruncateProps

export const Chip = forwardRef<ChipElement, ChipProps>(function Chip(props, forwardedRef) {
  const {
    className,
    children: childrenProp,
    kind: kindProp,
    size: sizeProp,
    leftIcon = null,
    unstable_truncate = false,
    asChild,
    ...rest
  } = props

  const Component = asChild ? Slot : 'span'

  const kind = ensurePropOf<ChipKind>(ChipKinds, kindProp, 'tertiary')
  const size = ensurePropOf<ChipSize>(ChipSizes, sizeProp, 'md')

  const classes = classNames(
    styles.root,
    {
      [styles[`kind${kind}`]]: !!kind,
      [styles[`size${size}`]]: !!size,
    },
    className
  )

  const iconProps: Partial<IconProps> = {
    size: size === 'md' ? 'sm' : 'xs',
  }

  const children = unstable_truncate ? (
    <TruncateText value={1}>{childrenProp}</TruncateText>
  ) : (
    childrenProp
  )

  return (
    <Component ref={forwardedRef} {...rest} className={classes}>
      {isValidElement<IconProps>(leftIcon) ? (
        <span className={styles.leftIcon}>{cloneElement(leftIcon, iconProps)}</span>
      ) : null}

      {asChild ? <Slottable>{children}</Slottable> : children}
    </Component>
  )
})

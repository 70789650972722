import classNames from 'classnames'
import { ChangeEvent, forwardRef, MouseEvent, useState } from 'react'

import { ButtonElement } from '../button/button'
import { AddIcon, SubtractIcon } from '../icon/icons'
import { IconButton, IconButtonProps } from '../icon-button/icon-button'
import { Input, InputElement, InputProps } from '../input/input'
import styles from './quantity.module.scss'

export type QuantityElement = HTMLInputElement
export type QuantityValueChangeEvent = ChangeEvent<InputElement> | MouseEvent<ButtonElement>

export interface QuantityProps extends Omit<InputProps, 'size' | 'rightIcon' | 'onValueChange'> {
  onValueChange?: (evt: QuantityValueChangeEvent, value: number) => void
  value?: number
  defaultValue?: number
  minValue?: number
  maxValue?: number
}

const iconBtnProps: Omit<IconButtonProps, 'icon' | 'asChild' | 'children' | 'label'> = {
  kind: 'tertiary',
  size: 'md',
}

export const Quantity = forwardRef<QuantityElement, QuantityProps>(function Quantity(
  props,
  forwardedRef
) {
  const { className, defaultValue = 0, minValue, maxValue, disabled, ...rest } = props
  const [value, _setValue] = useState(defaultValue)

  const disabledSub = minValue !== undefined && value <= minValue
  const disabledAdd = maxValue !== undefined && value >= maxValue

  function setValue(evt: QuantityValueChangeEvent, next: number) {
    if (
      (minValue !== undefined && next < minValue) ||
      (maxValue !== undefined && next > maxValue)
    ) {
      return
    }
    _setValue(next)
    props.onValueChange?.(evt, next)
  }

  const classes = classNames(styles.root, {}, className)

  return (
    <span className={classes}>
      <IconButton
        data-testid="quantity-icon-button-sub"
        onClick={(evt) => setValue(evt, value - 1)}
        icon={<SubtractIcon />}
        {...iconBtnProps}
        disabled={disabled || disabledSub}
        label="remover um"
      />
      <Input
        data-testid="quantity-input"
        {...rest}
        className={styles.input}
        value={value}
        disabled={disabled}
        onValueChange={(evt, next) => {
          const number = Number(next)
          if (!isNaN(number)) {
            setValue(evt, number)
          }
        }}
        inputMode="numeric"
        ref={forwardedRef}
      />
      <IconButton
        data-testid="quantity-icon-button-add"
        onClick={(evt) => setValue(evt, value + 1)}
        icon={<AddIcon />}
        {...iconBtnProps}
        disabled={disabled || disabledAdd}
        label="adicionar um"
      />
    </span>
  )
})

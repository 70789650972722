import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { Slot } from '../utilities'
import styles from './accordion-title.module.scss'
import { useAccordion } from './use-accordion'

export type AccordionTitleElement = HTMLSpanElement

export type AccordionTitleProps = HTMLAttributes<AccordionTitleElement> & {
  asChild?: boolean
}

const AccordionTitle = forwardRef<AccordionTitleElement, AccordionTitleProps>(
  function AccordionTitle(props, forwardedRef) {
    const { asChild, className, children, ...rest } = props

    const { size } = useAccordion()
    const classes = classNames(
      styles.root,
      {
        [styles[`size${size}`]]: size !== 'md',
      },
      className
    )

    const Component = asChild ? Slot : 'span'

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        {children}
      </Component>
    )
  }
)

export default AccordionTitle

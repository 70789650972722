import { getAllCookies } from '@jusbrasil-web/shared-utils-cookies'
import { GetServerSidePropsContext } from 'next'

import { UserSession } from '../types'

// TODO: validate decoded user with tornado-auth. @felipemsantana

export function parseUserCookie(ctx: Pick<GetServerSidePropsContext, 'req'>): UserSession {
  const cookies = getAllCookies(ctx.req)
  let session: UserSession = {}

  try {
    const [part] = cookies.user?.split('|') || ['']
    session = JSON.parse(Buffer.from(part, 'base64').toString('utf-8'))
  } catch {
    // ignored
  }

  if (cookies.jdid != null) {
    session ??= {}
    session.jdid = cookies.jdid
  }

  return session
}

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgHome(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.555 4.168a1 1 0 0 0-1.11 0L5 8.465V6a1 1 0 0 0-2 0v3.798l-.555.37A1 1 0 0 0 3 12v8a1 1 0 1 0 2 0v-9.131l7-4.667 7 4.666V20a1 1 0 1 0 2 0v-8a1 1 0 0 0 .555-1.832l-9-6ZM11 14a1 1 0 1 1 2 0v6a1 1 0 1 0 2 0v-6a3 3 0 1 0-6 0v6a1 1 0 1 0 2 0v-6Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgHome)

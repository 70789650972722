import { useUserSession } from '@jusbrasil-web/shared-user-session'
import { useRef } from 'react'

import { GTMDataLayer, GTMDataLayerCustomValues } from '../../types'

export function useGTMDataLayer(customValues: GTMDataLayerCustomValues): GTMDataLayer {
  const dataLayer: GTMDataLayer = {
    environment: process.env.NODE_ENV,
    enable_google_analytics: true,
    cookie_domain: 'jusbrasil.com.br',
    argos_enabled: true,
    disable_react_relay: true,
  }

  const session = useUserSession()
  const optionalValues = Object.entries({
    ...customValues,
    'user.device_id': session?.jdid,
    'user.pid': session?.pid,
    'experiment.name': session?.experiment?.name,
    'experiment.variation': session?.experiment?.alternative,
  })

  optionalValues.forEach(([key, value]) => {
    if (value !== undefined) {
      dataLayer[key] = value
    }
  })

  return useRef(dataLayer).current
}

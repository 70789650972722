import { HistoryIcon } from '@farol-ds/react'
import { HistoryStorage, SnowpiercerStorage } from '@jusbrasil-web/shared-history-storage'
import { useCallback, useMemo } from 'react'

import { AutocompleteOption } from '../../utils'

export const useHistoryItems = () => {
  const history = useMemo(() => new HistoryStorage(new SnowpiercerStorage()), [])

  const mountHistoryItems = useCallback(async () => {
    return history.all().then((items) => {
      const data: AutocompleteOption[] = items.map((item) => ({
        id: (item.id + 1) * -1, // negative ids for history items (just to avoid conflicts)
        label: item.text,
        value: item.raw,
        leftIcon: <HistoryIcon />,
        history: true,
        metadata: {},
      }))
      data.reverse()
      return data
    })
  }, [history])

  return { mountHistoryItems, history }
}

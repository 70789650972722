import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgDislike(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18a1 1 0 1 0 2 0v-3a1 1 0 0 1 1-1h4.039a1 1 0 0 0 .975-1.223l-1.197-6.984A1 1 0 0 0 17.84 5H8v8c1.388 0 2.428.715 3.082 1.695.634.951.918 2.157.918 3.305Zm-6-5V5H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h1ZM7 3H5a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h3c.612 0 1.072.285 1.418.805.366.549.582 1.343.582 2.195a3 3 0 1 0 6 0v-2h3.039a3 3 0 0 0 2.944-3.573l-1.197-6.988A3 3 0 0 0 17.839 3H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgDislike)

import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgPushpin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9.292V4a1 1 0 0 1 0-2h10a1 1 0 1 1 0 2v5.292a2 2 0 0 0 .211.894l.96 1.92A2 2 0 0 1 16.38 15H13v6a1 1 0 1 1-2 0v-6H7.618a2 2 0 0 1-1.789-2.894l.96-1.92a2 2 0 0 0 .21-.894ZM15 4H9v5.292a4 4 0 0 1-.422 1.789L7.618 13h8.764l-.96-1.92A4 4 0 0 1 15 9.293V4Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgPushpin)

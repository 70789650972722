import { gql, useMutation } from '@apollo/client'

export const OVERWRITE_CPF_ACCOUNT_MUTATION = gql`
  mutation UseReplaceCpfMutation($input: ChangeCPFAccountInput!) {
    changeCpfAccount(input: $input) {
      clientMutationId
      profile {
        id
        isCpfValid
        cpf
        birthDate
      }
    }
  }
`

export const useReplaceCPF = (replacementToken: string | null | undefined) => {
  const [changeCpfAccount] = useMutation(OVERWRITE_CPF_ACCOUNT_MUTATION)

  if (!replacementToken) return () => Promise.reject('No replacement token found')

  const variables = {
    input: {
      token: replacementToken,
    },
  }

  return () => changeCpfAccount({ variables, fetchPolicy: 'network-only' })
}

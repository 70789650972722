import {
  Avatar,
  Badge,
  BodyText,
  Button,
  Drawer,
  DrawerContentElement,
  Link,
  Menu,
  MenuItem,
  Separator,
  Spinner,
} from '@farol-ds/react'
import { pluralize } from '@jusbrasil-web/shared-utils-format'
import { forwardRef, lazy, Suspense, useContext } from 'react'

import { TopbarContext } from '../../context'
import { sendUnifiedClickEvent } from '../../utils'
import { withNextUrl } from '../../utils/next-url'
import { TopbarSkeleton } from '../topbar-skeleton'
import { useMenuItems, UseMenuItemsMountFn } from './menu-items'
import styles from './topbar-user-menu-content.module.scss'
import { TopbarUserMenuContentProfileSwitch } from './topbar-user-menu-content-profile-switch'
import { TopbarUserMenuTakeover } from './topbar-user-menu-takeover'

const TopbarUserFeatureFlagMenuLazy = lazy(() =>
  import('./topbar-user-feature-flag-menu').then((m) => ({ default: m.TopbarUserFeatureFlagMenu }))
)

const TopbarForceExperimentMenuLazy = lazy(() =>
  import('./topbar-force-experiment-menu').then((m) => ({ default: m.TopbarForceExperimentMenu }))
)

export type TopbarUserMenuContentElement = DrawerContentElement

export interface TopbarUserMenuContentProps {
  mountMenuItems?: UseMenuItemsMountFn
}

export const TopbarUserMenuContent = forwardRef<
  TopbarUserMenuContentElement,
  TopbarUserMenuContentProps
>(function TopbarUserMenuContent(props, forwardedRef) {
  const { mountMenuItems } = props

  const ctx = useContext(TopbarContext)
  const menuItems = useMenuItems(ctx, mountMenuItems)
  const { logged, user, ready, currentUrl } = ctx

  return (
    <Drawer.Content ref={forwardedRef} data-testid="topbar-header-user-menu-content">
      <Drawer.IconClose />

      <Drawer.Header>
        <Drawer.HeaderTitle>Minha conta</Drawer.HeaderTitle>
      </Drawer.Header>

      <Drawer.Body className={styles.body}>
        {logged && (
          <div className={styles.userInfo}>
            {ready && user && (
              <>
                <Avatar src={user.image || undefined} alt={user.name} size="xl" />
                <div className={styles.userInfoText}>
                  <BodyText size="lg" className={styles.userInfoName} asChild>
                    <strong>{user.name}</strong>
                  </BodyText>
                  <Link
                    onClick={() => {
                      sendUnifiedClickEvent('Ver meu perfil', user?.pid)
                    }}
                    type="primary"
                    href={`https://${user.username}.jusbrasil.com.br`}
                    className={styles.userInfoLink}
                  >
                    Acessar meu perfil
                  </Link>
                </div>
              </>
            )}

            {!ready && (
              <>
                <TopbarSkeleton animateBg radius="circle" style={{ height: 48, width: 48 }} />
                <TopbarSkeleton
                  marginLeft="3xs"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  style={{ height: 51 }}
                >
                  <TopbarSkeleton radius="text" animateBg style={{ width: 160, height: 16 }} />
                  <TopbarSkeleton
                    animateBg
                    radius="text"
                    marginTop="3xs"
                    style={{ width: 124, height: 12 }}
                  />
                </TopbarSkeleton>
              </>
            )}
          </div>
        )}

        {ready && !user && (
          <div className={styles.anonyButtons}>
            <Button kind="secondary" block asChild>
              <a href={withNextUrl('https://www.jusbrasil.com.br/cadastro', currentUrl)}>
                Cadastre-se
              </a>
            </Button>
            <Button block asChild>
              <a href={withNextUrl('https://www.jusbrasil.com.br/login', currentUrl)}>Entrar</a>
            </Button>
          </div>
        )}

        <Menu className={styles.menu}>
          <Separator className={styles.separator} />

          {menuItems.map((item, idx) => {
            if (typeof item === 'string') {
              switch (item) {
                case 'TAKEOVER':
                  return <TopbarUserMenuTakeover key={`takeover-${idx}`} />
                case 'PROFILE_SWITCH':
                  return <TopbarUserMenuContentProfileSwitch key={`profile-switch-${idx}`} />

                case 'SEPARATOR':
                  return <Separator className={styles.separator} key={`sep-${idx}`} />

                default:
                  return null
              }
            }

            return (
              <MenuItem key={item.url} asChild>
                <a
                  href={item.url}
                  target={item.target}
                  onClick={(evt) => {
                    if (item.clickEvents) {
                      item.clickEvents.forEach((clickEvent) => {
                        sendUnifiedClickEvent(clickEvent, user?.pid)
                      })
                    }
                    item.onClick?.(evt)
                  }}
                >
                  <MenuItem.Content>
                    {item.leading ? <MenuItem.Leading>{item.leading}</MenuItem.Leading> : null}
                    {item.title}
                    {item.count ? (
                      <MenuItem.Trailing>
                        <Badge
                          type="secondary"
                          count={item.count}
                          aria-label={`${item.count} ${pluralize(
                            item.count,
                            'não lida',
                            'não lidas'
                          )}`}
                        />
                      </MenuItem.Trailing>
                    ) : null}
                  </MenuItem.Content>
                </a>
              </MenuItem>
            )
          })}
        </Menu>

        {user?.admin && (
          <Suspense fallback={<Spinner />}>
            <Separator className={styles.separator} />
            <TopbarUserFeatureFlagMenuLazy />
            <Separator className={styles.separator} />
            <TopbarForceExperimentMenuLazy />
          </Suspense>
        )}
      </Drawer.Body>
    </Drawer.Content>
  )
})

import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { Slot } from '../utilities'
import { richTextStyles as styles } from './styles'

export type RichTextElement = HTMLDivElement

export interface RichTextProps extends HTMLAttributes<RichTextElement> {
  children?: ReactNode
  className?: string
  asChild?: boolean
}

export const RichText = forwardRef<RichTextElement, RichTextProps>(function RichText(
  props,
  forwardedRef
) {
  const { className, children, asChild, ...rest } = props

  const classes = classNames(styles.richtext, className)

  const Component = asChild ? Slot : 'div'

  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      {children}
    </Component>
  )
})

export * from './styles'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { forwardRef, HTMLAttributes, ReactNode, Ref, useRef } from 'react'

import { TreeViewContext, TreeViewContextType } from './context'
import { TreeViewItemElement } from './tree-view-item'
import TreeViewList, { TreeViewListElement } from './tree-view-list'
import { TreeViewTogglerProps } from './tree-view-toggler'
import { useTreeViewA11y } from './use-tree-view-a11y'

export type TreeViewElement = TreeViewListElement

export interface TreeViewProps<ValueType = unknown, ItemProps = unknown>
  extends Omit<HTMLAttributes<TreeViewElement>, 'defaultValue' | 'defaultChecked'> {
  children: ReactNode
  className?: string
  // tree props
  defaultSelected?: ValueType
  onSelectedChange?: (nodeId: string, value: ValueType) => void
  defaultExpanded?: string[]
  onExpandedChange?: (expanded: string[]) => void
  // used to extend the component
  isSelected?(nodeId: string): boolean
  isVisualSelected?(nodeId: string): boolean
  getItemProps?(nodeId: string, prevProps: ItemProps): ItemProps
  getTogglerProps?(nodeId: string, prevProps: TreeViewTogglerProps): TreeViewTogglerProps
}

function TreeViewRootInner<ValueType = unknown, ItemProps = unknown>(
  props: TreeViewProps<ValueType, ItemProps>,
  forwardedRef: Ref<TreeViewElement>
) {
  const {
    children,
    defaultExpanded,
    defaultSelected: _, // do not remove
    onSelectedChange,
    isSelected,
    isVisualSelected,
    onExpandedChange,
    getItemProps,
    getTogglerProps,
    ...rest
  } = props

  const ref = useRef<TreeViewElement>(null)
  const expandedRef = useRef<string[]>(defaultExpanded ?? [])
  const [keyRef, onRefreshList] = useTreeViewA11y<TreeViewListElement, TreeViewItemElement>()

  const refs = useComposedRefs(forwardedRef, ref, keyRef)

  const context: TreeViewContextType<ItemProps> = {
    expanded: expandedRef.current,
    expand(nodeId) {
      expandedRef.current.push(nodeId)
      onExpandedChange?.(expandedRef.current)
      onRefreshList()
    },
    collapse(nodeId) {
      expandedRef.current.splice(expandedRef.current.indexOf(nodeId), 1)
      onExpandedChange?.(expandedRef.current)
      onRefreshList()
    },
    select(nodeId) {
      onSelectedChange?.(nodeId, undefined as ValueType)
    },
    isSelected(nodeId) {
      return isSelected?.(nodeId) || false
    },
    isVisualSelected(nodeId) {
      return isVisualSelected?.(nodeId) ?? isSelected?.(nodeId) ?? false
    },
    getItemProps(nodeId, prevProps) {
      return getItemProps?.(nodeId, prevProps) || ({} as ItemProps)
    },
    getTogglerProps(nodeId, prevProps) {
      return getTogglerProps?.(nodeId, prevProps) || {}
    },
  }

  return (
    <TreeViewContext.Provider value={context}>
      <TreeViewList disablePadding {...rest} ref={refs}>
        {children}
      </TreeViewList>
    </TreeViewContext.Provider>
  )
}

const TreeViewRoot = forwardRef(TreeViewRootInner) as <K, Y>(
  props: TreeViewProps<K, Y> & { ref?: React.ForwardedRef<TreeViewElement> }
) => ReturnType<typeof TreeViewRootInner>

export default TreeViewRoot

import { capitalize } from '@jusbrasil-web/shared-utils-format'

import { Nullable } from '../types'

export function formatKind(subject: Nullable<string>, kind: Nullable<string>) {
  const formattedKind = kind && (kind.toLowerCase() === 'indeterminado' ? '' : capitalize(kind))

  if (!!formattedKind && !!subject) return `${formattedKind} · ${subject}`

  if (subject) return subject

  return formattedKind
}

import { gql } from '@apollo/client'
import { Button, Card, Modal, VisuallyHidden } from '@farol-ds/react'

import { ModalRelatedLawsuit_LawsuitFragment } from './__generated__/modal-related-lawsuit.graphql'
import styles from './lawsuit-card-third-party.module.scss'

interface ModalRelatedLawsuitProps {
  lawsuit: ModalRelatedLawsuit_LawsuitFragment
  formattedTitle: string
  modalEvent?: (
    eventName: string,
    lawsuitNumber: string,
    nature?: string,
    lawsuitKind?: string
  ) => void
}

export function ModalRelatedLawsuit({
  lawsuit,
  formattedTitle,
  modalEvent,
}: ModalRelatedLawsuitProps) {
  const { leanRelatedLawsuits } = lawsuit
  const lawsuitSubTitle =
    leanRelatedLawsuits &&
    `${leanRelatedLawsuits?.length} processo${
      leanRelatedLawsuits?.length > 1 ? 's' : ''
    } complementar${leanRelatedLawsuits?.length > 1 ? 'es' : ''}`

  return (
    <div className={styles.relatedLawsuitButton} data-testid="lawsuit-card-related-lawsuit-button">
      <Modal>
        <Modal.Trigger>
          <Button
            kind="plain"
            size="md"
            onClick={() =>
              modalEvent?.(
                'RelatedLawsuitModalViewed',
                lawsuit?.number || '',
                undefined,
                lawsuit?.classifiedKindByNumber || undefined
              )
            }
          >
            Exibir
          </Button>
        </Modal.Trigger>
        <Modal.Content>
          <VisuallyHidden>
            <Modal.Header>
              <Modal.HeaderTitle>Processos complementares</Modal.HeaderTitle>
            </Modal.Header>
          </VisuallyHidden>
          <Modal.IconClose />
          <Modal.Header>
            <p className={styles.modalTitle}>{formattedTitle}</p>
            <p className={styles.modalDescription}>{lawsuitSubTitle}</p>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            {leanRelatedLawsuits?.map((item, index: number) => (
              <Card className={styles.modalCardContent} key={index} asChild>
                <a
                  href={item?.lawsuit?.url || '#'}
                  onClick={() =>
                    modalEvent?.(
                      'RelatedLawsuitModalLawsuitClicked',
                      item?.lawsuit?.cnjNumber?.number || '',
                      item?.lawsuit?.nature || undefined,
                      item?.lawsuit?.classifiedKindByNumber || undefined
                    )
                  }
                >
                  <div className={styles.modalLawsuitInfo}>
                    <p className={styles.modalLawsuitNumber}>{item?.lawsuit?.cnjNumber?.number}</p>
                    <p className={styles.modalLawsuitTitle}>{item?.lawsuit?.nature}</p>
                  </div>

                  <Button kind="plain" size="md">
                    Ver mais
                  </Button>
                </a>
              </Card>
            ))}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </div>
  )
}

ModalRelatedLawsuit.fragments = {
  lawsuit: gql`
    fragment ModalRelatedLawsuit_lawsuit on CRMLawsuit {
      number
      classifiedKindByNumber
      leanRelatedLawsuits {
        number
        lawsuit {
          nature
          url
          classifiedKindByNumber
          cnjNumber {
            number
          }
        }
      }
    }
  `,
}

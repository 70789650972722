import { RefObject, useRef } from 'react'

import { useIsomorphicLayoutEffect } from '../../functions'
import { EventDetail, OnMetricsFunction } from '../../types'
import { EVENT_NAME } from '../../utils'

export function useListener<T extends HTMLElement>(
  onMetrics: OnMetricsFunction
): { ref: RefObject<T> } {
  const ref = useRef<T>(null)

  // must use "useLayoutEffect" hook to add listener before all dispatches
  useIsomorphicLayoutEffect(() => {
    const listener = (event: Event) => {
      onMetrics(event as CustomEvent<EventDetail>)
    }

    ref.current?.addEventListener(EVENT_NAME, listener)

    return () => {
      ref.current?.removeEventListener(EVENT_NAME, listener)
    }
  }, [onMetrics])

  return { ref }
}

import { TopbarContextType, TopbarUser } from '../../types'
import { ProfileSwitchFragmentFragment, TopbarRootQuery } from './__generated__/query.graphql'

type Viewer = TopbarRootQuery['root']['viewer']

function buildProfile(data: ProfileSwitchFragmentFragment) {
  // friendly name fallback for UI
  const name = data.fullname || 'Usuário anônimo'
  // corner-case and how we treat today, forwarding to https://null.jusbrasil.com.br (not-found)
  const username = data.username || 'null'
  // just to minimize the corner-case's pain, in UI we're checking the url value
  const url = data.username ? `https://${username}.jusbrasil.com.br` : null
  const cpf = data.cpf || null
  return {
    name,
    username,
    url,
    cpf,
    pid: data.pid,
    image: data.avatar?.url || null,
  }
}

function buildProfileList(viewer: Viewer) {
  if (!viewer) return []
  const profiles = (viewer?.user?.profiles?.edges || [])
    .filter(
      // filter out the current user profile
      (e): e is { node: ProfileSwitchFragmentFragment } => {
        if (!e?.node?.pid) return false
        return e.node.pid !== viewer.pid
      }
    )
    .map((edge) => edge.node)

  const { mainProfile } = viewer.user || {}
  if (mainProfile && mainProfile.pid !== viewer.pid) {
    profiles.push(mainProfile)
  }

  return profiles.map(buildProfile)
}

function getUserFromViewer(viewer: Viewer): TopbarUser {
  if (!viewer) return null
  const { subscribeLink, boxProfileInfo } = viewer
  return {
    ...buildProfile(viewer),
    profiles: buildProfileList(viewer),
    admin: viewer?.user?.isAdmin || null,
    hasAccessToSomeBundle: viewer?.hasAccessToSomeBundle,
    hasDoctrine: viewer?.hasDoctrine,
    isLawyer: viewer?.isLawyer,
    cpf: viewer?.cpf || null,
    subscribeLink,
    boxProfileInfo: boxProfileInfo
      ? { linkedAccount: boxProfileInfo.linkedAccount || false }
      : null,
  }
}

export function getContextFromQuery(query: TopbarRootQuery): TopbarContextType {
  const { viewer } = query.root

  const user = getUserFromViewer(viewer)

  let cacheUserData = { related: [], takeover: false }
  // Reference for the key format in ui-topbar project:
  // https://github.com/jusbrasil/ui-topbar/blob/0551d3fe410c2c16b87bbff4c1404f44ab6e1c1f/src/store.js#L88 @diegodovale21
  const itemKey = `topbar-fetchUser-${user?.pid}`

  try {
    const storedData = localStorage.getItem(itemKey)
    if (storedData) {
      cacheUserData = { ...cacheUserData, ...JSON.parse(storedData) }
    }
  } catch (error) {
    console.error(`Error parsing localStorage (${itemKey}):`, error)
  }

  return {
    logged: !!user,
    user,
    takeover: cacheUserData.takeover || false,
    takeoverRelatedProfiles: cacheUserData.related || [],
  }
}

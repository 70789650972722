import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './form-control-description.module.scss'
import { useFormControl } from './use-form-control'

export type FormControlDescriptionElement = HTMLParagraphElement

export interface FormControlDescriptionProps extends HTMLAttributes<FormControlDescriptionElement> {
  children: ReactNode
  className?: string
}

const FormControlDescription = forwardRef<
  FormControlDescriptionElement,
  FormControlDescriptionProps
>(function FormControlDescription(props, forwardedRef) {
  const { className, children, ...rest } = props

  const formControl = useFormControl()
  const propsFromFormControl = formControl.getDescriptionProps?.(forwardedRef)
  const classes = classNames(styles.root, propsFromFormControl?.className, className)

  return (
    <p ref={forwardedRef} {...propsFromFormControl} {...rest} className={classes}>
      {children}
    </p>
  )
})

export default FormControlDescription

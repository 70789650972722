import classNames from 'classnames'
import { forwardRef } from 'react'

import {
  PrimitiveText,
  PrimitiveTextElement,
  PrimitiveTextProps,
} from '../primitive-text/primitive-text'
import styles from './caption.module.scss'

export type CaptionElement = PrimitiveTextElement
export type CaptionProps = PrimitiveTextProps

export const Caption = forwardRef<CaptionElement, CaptionProps>(function Caption(
  props,
  forwardedRef
) {
  const { className } = props
  return (
    <PrimitiveText {...props} className={classNames(styles.root, className)} ref={forwardedRef} />
  )
})

import { COMPONENT_ORIGIN_KEY, snowpiercerClient } from '../constants'
import { ComponentOrigin, StorageObject } from '../types'

export async function storeComponentOriginForNextPage(
  componentOrigin: ComponentOrigin
): Promise<void> {
  const snowpiercerItem: StorageObject = {
    timestamp: Date.now(),
    componentOrigin,
  }

  await snowpiercerClient.setItem(COMPONENT_ORIGIN_KEY, JSON.stringify(snowpiercerItem))
}

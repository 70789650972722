import classNames from 'classnames'
import { cloneElement, forwardRef, isValidElement, LiHTMLAttributes } from 'react'

import { ArrowRightIcon } from '../icon/icons'
import { LinkProps } from '../link/link'
import styles from './breadcrumb-item.module.scss'

export type BreadcrumbItemElement = HTMLLIElement

export interface BreadcrumbItemProps extends LiHTMLAttributes<BreadcrumbItemElement> {
  disableArrowIcon?: boolean
  className?: string
}

const BreadcrumbItem = forwardRef<BreadcrumbItemElement, BreadcrumbItemProps>(
  function BreadcrumbItem(props, forwardedRef) {
    const { className, children, disableArrowIcon = false, ...rest } = props

    const classes = classNames(styles.root, className)

    const linkProps: Partial<LinkProps> = {
      type: 'primary',
    }

    return (
      <li {...rest} className={classes} ref={forwardedRef}>
        {!disableArrowIcon ? <ArrowRightIcon size="xs" className={styles.arrowIcon} /> : null}

        {isValidElement<LinkProps>(children) ? cloneElement(children, linkProps) : children}
      </li>
    )
  }
)

export default BreadcrumbItem

import { ABTestParticipate, fetchParticipating } from '@jusbrasil-web/shared-ab-test'
import { setJusbrasilCookie } from '@jusbrasil-web/shared-utils-cookies'
import { GetServerSidePropsContext } from 'next'

export type NavigationExperimentVariants = 'variantA' | 'variantB'
export type NavigationExperimentAlternatives = NavigationExperimentVariants | 'control' | null
export interface ABTestParticipateWithAlternatives extends ABTestParticipate {
  variants: Record<string, NavigationExperimentVariants>
  cookie: {
    name: string
    maxAge: number
  }
}
const ONE_DAY_IN_SECONDS = 24 * 60 * 60
export const navigationExperiment: ABTestParticipateWithAlternatives = {
  experimentGroup: 'topbar-by-device-id',
  experimentName: 'navigation',
  controlKey: 'control',
  variants: {
    variantA: 'variantA',
    variantB: 'variantB',
  },
  cookie: {
    name: 'topbar_navigation_experiment_alternative',
    maxAge: ONE_DAY_IN_SECONDS,
  },
}

export async function getNavigationExperimentAlternative(
  ctx: Pick<GetServerSidePropsContext, 'req' | 'query' | 'res'>
) {
  let navigationExperimentAlternative = null
  try {
    navigationExperimentAlternative = (ctx.req.cookies?.[navigationExperiment.cookie.name] ||
      null) as NavigationExperimentAlternatives
    if (!navigationExperimentAlternative) {
      const participations = await fetchParticipating(ctx, [navigationExperiment])
      const [participation] = participations
      if (participation && participation.participating && participation.alternative) {
        navigationExperimentAlternative =
          participation.alternative as NavigationExperimentAlternatives
      }
      setJusbrasilCookie(
        ctx.res,
        navigationExperiment.cookie.name,
        navigationExperimentAlternative || 'not-participating',
        {
          maxAge: navigationExperiment.cookie.maxAge,
        }
      )
    }
  } catch (err) {
    console.error('getNavigationExperimentAlternative error:', err)
  }

  return navigationExperimentAlternative
}

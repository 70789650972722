import { gql, useMutation } from '@apollo/client'

export const VALIDATE_CPF_MUTATION = gql`
  mutation ValidateCpf($input: ValidateCPFInput!) {
    validateCPF(input: $input) {
      profile {
        id
        pid
        isCpfValid
      }
    }
  }
`

interface ValidateCPFProps {
  cpf: string
  birthDate: Date
  documentData?: string
  documentName?: string
  matchValidationFirst?: boolean
}

export const useValidateCPF = () => {
  const [validateCPF] = useMutation(VALIDATE_CPF_MUTATION)

  return async ({
    cpf,
    birthDate,
    documentData,
    documentName,
    matchValidationFirst = true,
  }: ValidateCPFProps) => {
    const documentVariables = documentData && documentName ? { documentData, documentName } : {}
    const variables = {
      input: {
        cpf,
        birthdate: birthDate.getTime(),
        matchValidationFirst,
        ...documentVariables,
      },
    }

    return validateCPF({
      variables,
      fetchPolicy: 'network-only',
    }).then(({ data }) => data)
  }
}

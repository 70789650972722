import classNames from 'classnames'
import {
  cloneElement,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactEventHandler,
  ReactNode,
  useId,
} from 'react'

import { CloseIcon } from '../icon/icons'
import { TruncateText } from '../truncate-text/truncate-text'
import { Slot, Slottable, VisuallyHidden } from '../utilities'
import styles from './chip-removable.module.scss'

export type ChipRemovableElement = HTMLSpanElement

type WithUnstableTruncateProps =
  | { unstable_truncate: true; title: string }
  | { unstable_truncate?: false }

export type ChipRemovableProps = HTMLAttributes<ChipRemovableElement> & {
  children: ReactNode
  disabled?: boolean
  className?: string
  asChild?: boolean
  onRemove?: ReactEventHandler
} & WithUnstableTruncateProps

export const ChipRemovable = forwardRef<ChipRemovableElement, ChipRemovableProps>(
  function ChipRemovable(props, forwardedRef) {
    const {
      className,
      disabled = false,
      children: _,
      onRemove,
      id: idProp,
      asChild,
      unstable_truncate: __,
      title: ___, // we should remove from rest
      ...rest
    } = props

    const uuid = useId()
    const id = idProp || uuid
    const Component = asChild ? Slot : 'span'

    const classes = classNames(
      styles.root,
      {
        [styles.disabled]: disabled,
      },
      className
    )

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        <Slottable>{renderChildren(props, id)}</Slottable>

        <span
          className={styles.closeBtn}
          role="button"
          tabIndex={0}
          aria-labelledby={`${id}p ${id}l`}
          onClick={onRemove}
          onKeyDown={onRemove}
        >
          <CloseIcon size="sm" />
        </span>

        <VisuallyHidden id={`${id}p`}>Remover</VisuallyHidden>
      </Component>
    )
  }
)

function renderChildren(props: ChipRemovableProps, id: string): ReactNode {
  const { asChild, children, unstable_truncate = false } = props
  if (asChild && isValidElement<{ children?: ReactNode }>(children)) {
    return cloneElement(children, {}, renderLabel(children.props.children, id, props))
  }
  const label = renderLabel(children, id, props)
  return unstable_truncate ? (
    <TruncateText value={1} key={id} asChild>
      {label}
    </TruncateText>
  ) : (
    label
  )
}

function renderLabel(children: ReactNode, id: string, props: ChipRemovableProps): ReactNode {
  const { title } = props
  return (
    <span title={title} className={styles.label} id={`${id}l`} key={id}>
      {children}
    </span>
  )
}

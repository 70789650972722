import { gql, useLazyQuery } from '@apollo/client'

import { EntityUrlByCpfQuery } from './__generated__/use-get-entity-url-by-cpf.graphql'

export const ENTITY_URL_BY_CPF_ROOT_QUERY = gql`
  query entityUrlByCpf($cpf: String!) {
    root {
      partyEntityUrl(identificationNumber: $cpf, identificationType: "CPF")
    }
  }
`

export function useGetEntityUrlByCPF() {
  const [entityUrlByCpf] = useLazyQuery<EntityUrlByCpfQuery>(ENTITY_URL_BY_CPF_ROOT_QUERY)

  return (cpf: string) => {
    const variables = { cpf }

    return entityUrlByCpf({ variables })
  }
}

import { serialize } from 'cookie'
import { IncomingMessage, ServerResponse } from 'http'
import Cookies, { CookieChangeOptions } from 'universal-cookie'

type BuildCookiesContext = ({
  request,
  response,
}:
  | {
      request: IncomingMessage | null
      response?: never
    }
  | {
      request?: never
      response: ServerResponse | null
    }) => Cookies

/**
 *
 * @param options
 * @param options.response If you are writing cookies in the server side, send the response object. If you are using the function in the client side, use null.
 * @param options.request If you are reading cookies in the server side, send the request object. If you are using the function in the client side, use null.
 * @returns New cookies object with the right context.
 */
export const buildCookiesContext: BuildCookiesContext = ({ response, request = response?.req }) => {
  const headersCookie = request?.headers?.cookie
  const cookies = new Cookies(headersCookie)

  if (response) {
    cookies.addChangeListener((change: CookieChangeOptions) => {
      if (!response.headersSent) {
        const currentHeader: number | string | string[] | undefined =
          response.getHeader('Set-Cookie')
        const cookies: string[] = (
          Array.isArray(currentHeader) ? currentHeader : [currentHeader]
        ).filter((value): value is string => typeof value === 'string')

        const { name, value, options } = change
        const serialized = serialize(name, value ?? '', options)
        response.setHeader('Set-Cookie', [...cookies, serialized])
      }
    })
  }

  return cookies
}

import classNames from 'classnames'
import { cloneElement, forwardRef, HTMLAttributes, isValidElement, ReactNode } from 'react'

import {
  DocumentIcon,
  DownloadIcon,
  IconComponent,
  IconProps,
  PdfIcon,
  WordIcon,
} from '../icon/icon'
import { Spinner } from '../spinner/spinner'
import { ensurePropOf } from '../utilities'
import { assignSubComponents, isValidLinkElement } from '../utilities/internal'
import { Slot, Slottable } from '../utilities/slot/slot'
import { FileContext } from './context'
import styles from './file.module.scss'
import FileContent from './file-content'
import FileHelpText from './file-help-text'
import FileLabel from './file-label'
import { useFileProvider } from './use-file'

export type FileElement = HTMLDivElement
export const FileVariants = ['neutral', 'pdf', 'doc'] as const
export type FileVariant = (typeof FileVariants)[number]

export interface FileProps extends HTMLAttributes<FileElement> {
  children: ReactNode
  loading?: boolean
  variant?: FileVariant
  selected?: boolean
  rightIcon?: ReactNode
  disabled?: boolean
  asChild?: boolean
}

const fileIconMapping: Record<FileVariant, IconComponent> = {
  neutral: DocumentIcon,
  pdf: PdfIcon,
  doc: WordIcon,
}

const FileRoot = forwardRef<FileElement, FileProps>(function File(props, forwardedRef) {
  const {
    className,
    children,
    selected,
    rightIcon = <DownloadIcon />,
    loading: loadingProp,
    disabled: disabledProp,
    variant: variantProp,
    asChild: asChildProp,
    ...rest
  } = props

  const context = useFileProvider(props)

  const asChild = asChildProp && isValidElement(children)
  const isLink = asChild && isValidLinkElement(children)
  const Component = asChild ? Slot : 'div'

  const variant = ensurePropOf<FileVariant>(FileVariants, variantProp, 'neutral')
  const clickable = !!props.onClick || isLink
  const Icon = fileIconMapping[variant]

  const isButtonRole = clickable && !isLink
  const disabled = loadingProp || disabledProp

  const classes = classNames(
    styles.root,
    {
      [styles[`variant${variant}`]]: !!variant,
      [styles.clickable]: clickable,
      [styles.selected]: selected,
      [styles.nointeractive]: disabled,
      [styles.disabled]: disabledProp ? disabled : false,
    },
    className
  )

  const iconProps: Partial<IconProps> = { size: 'sm' }

  return (
    <FileContext.Provider value={context}>
      <Component
        aria-disabled={disabled || undefined}
        aria-labelledby={context.id}
        ref={forwardedRef}
        role={isButtonRole ? 'button' : undefined}
        tabIndex={isButtonRole ? 0 : undefined}
        {...rest}
        className={classes}
      >
        <Icon className={styles.icon} {...iconProps} />

        <Slottable>{children}</Slottable>

        {loadingProp ? (
          <Spinner className={styles.rightIcon} {...iconProps} />
        ) : (
          isValidElement<IconProps>(rightIcon) &&
          cloneElement(rightIcon, { className: styles.rightIcon, ...iconProps })
        )}
      </Component>
    </FileContext.Provider>
  )
})

export const File = assignSubComponents('File', FileRoot, {
  Content: FileContent,
  HelpText: FileHelpText,
  Label: FileLabel,
})

export * from './file-content'
export * from './file-help-text'
export * from './file-label'

import { useCallback, useContext } from 'react'

import { useMountTransition } from '../utilities'
import { DrawerContext } from './context'
import type { DrawerProps } from './drawer'

export type UseDrawerProviderProps = DrawerProps

export interface UseDrawerProviderReturn {
  size: DrawerProps['size']
  placement: DrawerProps['placement']
  open: boolean
  isMount: boolean
  onOpenChange: (open: boolean) => void
}

const transitionTime = 300

export function useDrawerProvider(props: UseDrawerProviderProps): UseDrawerProviderReturn {
  const { size, placement, defaultOpen, open: openProp, onOpenChange: onOpenChangeProp } = props

  const [open, setOpen, isMount] = useMountTransition(transitionTime, defaultOpen, 'backwards', {
    controlledState: openProp,
  })

  const onOpenChange = useCallback<UseDrawerProviderReturn['onOpenChange']>(
    (open: boolean) => {
      setOpen(open)
      onOpenChangeProp?.(open, {
        waitAnimation(callback) {
          setTimeout(() => callback(), transitionTime)
        },
      })
    },
    [onOpenChangeProp, setOpen]
  )

  return {
    size,
    placement,
    open,
    isMount,
    onOpenChange,
  }
}

export function useDrawer() {
  const context = useContext(DrawerContext)
  return context
}

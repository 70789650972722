import { SVGProps } from 'react'

import { wrapIcon } from '../wrap-icon'

function SvgRedo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.008 9a1 1 0 0 0-1-1h-9.5c-1.955 0-3.464.564-4.598 1.48-1.119.905-1.8 2.095-2.217 3.24-.416 1.146-.584 2.29-.651 3.165a14.597 14.597 0 0 0-.038 1.46l.003.111V20a1 1 0 1 0 2 0v-2.5l-.001-.117c-.012-.527-.051-2.28.567-3.979.333-.916.839-1.758 1.595-2.368.741-.6 1.794-1.036 3.34-1.036h9.5a1 1 0 0 0 1-1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3 3.293a1 1 0 0 0 0 1.414L18.595 9 14.3 13.293a1 1 0 0 0 1.414 1.414l5-5a1 1 0 0 0 0-1.414l-5-5a1 1 0 0 0-1.414 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default wrapIcon(SvgRedo)
